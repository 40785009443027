import React from "react";
import { Row, Col, Label, Button, Form, FormGroup, Input } from "reactstrap";
import TooltipItem from "../../components/Tooltip/Tooltip";
import helpIcon from "../../assets/img/question-mark.svg";
import Select from "react-select";
import { variantTypes, colorOptions } from "../../utils/constants";

class ProductForm extends React.Component {
  state = {};
  discardInput = (e) => {
    return;
  };
  render() {
    const {
      productId,
      petStyleCode,
      variants,
      defaultVariantKey,
      primaryCatFields,
      esIndexStatus,
      categoryRefTreeIdentifierLabel,
      brandName,
    } = this.props.form;

    const { handleChange, handleChangeSelect, addSku } = this.props;

    const variantData = variants ? variants[defaultVariantKey] : null;

    let catArray = [];
    if (categoryRefTreeIdentifierLabel) {
      catArray = JSON.parse(categoryRefTreeIdentifierLabel);
    }

    const selectedColorString =
      primaryCatFields &&
      Object.keys(primaryCatFields).map((x) => {
        const obj = primaryCatFields[x];
        return x === "COLOUR" && obj.value;
      });

    const selectedColorObj = colorOptions.find((c) => {
      return c.value === (selectedColorString && selectedColorString[0]);
    });

    const selectedColor = selectedColorObj && selectedColorObj.color;

    //console.log(selectedColorString, selectedColorObj, selectedColor);

    let packagingDimensionsL, packagingDimensionsB, packagingDimensionsH;
    const dimensions = variantData?.packagingDimensions && variantData.packagingDimensions.split("x");
    if (dimensions) {
      packagingDimensionsL = dimensions[0];
      packagingDimensionsB = dimensions[1];
      packagingDimensionsH = dimensions[2];
    }

    return (
      <div className="sku-form-box">
        <Form autoComplete="off" className="form-horizontal">
          <p className="text-right">
            *
            {esIndexStatus === "INDEXED" ? (
              <span className="text-success">Product Indexed</span>
            ) : (
              <span className="text-danger">Not Indexed</span>
            )}
          </p>
          {productId ? (
            <FormGroup row>
              <Label for="title" sm={3}>
                Product ID<span className="star">*</span>
              </Label>
              <Col sm={9}>
                <Input type="text" disabled defaultValue={productId || ""} />
              </Col>
            </FormGroup>
          ) : null}

          {catArray ? (
            <FormGroup row>
              <Label for="title" sm={3}>
                Category <span className="star">*</span>
              </Label>
              <Col sm={9}>
                <Input type="textarea" onChange={() => null} disabled value={catArray.join(" / ") || ""} />
              </Col>
            </FormGroup>
          ) : null}

          <FormGroup row>
            <Label for="title" sm={3}>
              Brand<span className="star">*</span>
            </Label>
            <Col sm={9}>
              <Input type="text" onChange={() => null} disabled value={brandName || ""} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="variant" sm={3}>
              Pet Style Code <span className="star">*</span>
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                name="petStyleCode"
                placeholder="Enter pet style code"
                value={petStyleCode || ""}
                onChange={productId ? this.discardInput : handleChange}
                disabled={productId}
              />
            </Col>
          </FormGroup>

          {variantData ? (
            <React.Fragment>
              <FormGroup row>
                <Label for="eancode" sm={3}>
                  Ean Code
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="eanCode"
                    data-object="variant"
                    placeholder="Enter ean code"
                    value={variantData.eanCode || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              {variantData.productVariantId ? (
                <FormGroup row>
                  <Label for="title" sm={3}>
                    Variant ID<span className="star">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input type="text" disabled value={variantData.productVariantId} />
                  </Col>
                </FormGroup>
              ) : null}

              <FormGroup row>
                <Label for="title" sm={3}>
                  Title<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    data-object="variant"
                    type="text"
                    name="title"
                    placeholder="Enter title"
                    value={variantData.title || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variantType" sm={3}>
                  Variant Type
                  <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Select
                    isSearchable={false}
                    isDisabled={productId}
                    options={variantTypes}
                    placeholder="Select Variant Type"
                    value={variantTypes.filter((option) => option.value === variantData.variantType)}
                    onChange={(opt) => handleChangeSelect("variantType", opt)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Variant<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="variant"
                    placeholder="Enter variant"
                    value={variantData.variant || ""}
                    disabled={productId}
                  />
                </Col>
              </FormGroup>

              {primaryCatFields
                ? Object.keys(primaryCatFields).map((index) => {
                    const attribute = primaryCatFields[index];

                    const options =
                      attribute.applicableOptions &&
                      attribute.applicableOptions.length &&
                      attribute.applicableOptions.map((idx) => ({
                        value: idx,
                        label: idx,
                        type: "primaryCatFields",
                      }));

                    const helpTxt =
                      attribute.helpText &&
                      attribute.helpText.length > 0 &&
                      attribute.helpText.map((text, indx) => {
                        return (
                          <p key={"ttext-" + indx} className="tool-list">
                            {text}
                          </p>
                        );
                      });

                    const inputProps = {
                      type: "text",
                      ...(attribute.type === "Textarea" && {
                        type: "textarea",
                      }),
                      ...(attribute.type === "Integer" && {
                        step: 1,
                        type: "number",
                        min: 0,
                      }),
                      ...((attribute.type === "Float" || attribute.type === "Long" || attribute.type === "Double") && {
                        step: 0.01,
                        type: "number",
                        min: 0,
                      }),
                    };

                    return (
                      <React.Fragment key={index}>
                        <FormGroup row>
                          <Label for="variant" sm={3}>
                            {attribute.name.toLowerCase()}{" "}
                            {attribute.mandatory ? <span className="star">*</span> : null}
                            {attribute.helpText && attribute.helpText.length > 0 ? (
                              <span className="help-icon">
                                <img alt="help" src={helpIcon} id={"Tooltip-att-" + attribute.name.replace(" ", "_")} />
                                <TooltipItem
                                  key={"tkey-" + attribute.name}
                                  placement="right"
                                  text={helpTxt}
                                  id={"att-" + attribute.name.replace(" ", "_")}
                                />
                              </span>
                            ) : null}
                          </Label>
                          <Col sm={9}>
                            {attribute.applicableOptions && attribute.applicableOptions.length > 0 ? (
                              <Select
                                readOnly
                                options={options}
                                isSearchable={false}
                                placeholder={
                                  attribute.placeholder
                                    ? attribute.placeholder
                                    : "Select " + attribute.name.toLowerCase()
                                }
                                value={options.filter((option) => {
                                  return option.value === attribute.value;
                                })}
                              />
                            ) : (
                              <Input
                                readOnly
                                {...inputProps}
                                name={index}
                                data-object="primaryCatFields"
                                placeholder={"Enter " + attribute.name.toLowerCase()}
                                value={attribute.value || ""}
                              />
                            )}
                          </Col>
                        </FormGroup>
                        {selectedColor ? (
                          <FormGroup row key={"pallate" + index}>
                            <Label for="pallete" sm={3}></Label>
                            <Col sm={9}>
                              <div
                                style={{
                                  height: 20,
                                  width: "100%",
                                  backgroundColor: "#" + selectedColor,
                                }}
                              ></div>
                            </Col>
                          </FormGroup>
                        ) : null}
                      </React.Fragment>
                    );
                  }, this)
                : null}

              <FormGroup row>
                <Label for="description" sm={3}>
                  Description<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="description"
                    data-object="variant"
                    placeholder="Enter Description"
                    value={variantData.description || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Warranty <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="warranty"
                    data-object="variant"
                    placeholder="Enter warranty"
                    value={variantData.warranty || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Package Dimension (LxBxH) (Cm)*<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Row>
                    <Col sm={4}>
                      {" "}
                      <Input
                        type="number"
                        min="0"
                        data-object="dimension"
                        data-dimension={`${packagingDimensionsL}x${packagingDimensionsB}x${packagingDimensionsH}`}
                        name="packagingDimensionsL"
                        placeholder="Length"
                        value={packagingDimensionsL || ""}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm={4}>
                      {" "}
                      <Input
                        type="number"
                        min="0"
                        data-object="dimension"
                        data-dimension={`${packagingDimensionsL}x${packagingDimensionsB}x${packagingDimensionsH}`}
                        name="packagingDimensionsB"
                        placeholder="Breadth"
                        value={packagingDimensionsB || ""}
                        onChange={handleChange}
                      />
                    </Col>

                    <Col sm={4}>
                      {" "}
                      <Input
                        type="number"
                        min="0"
                        data-object="dimension"
                        data-dimension={`${packagingDimensionsL}x${packagingDimensionsB}x${packagingDimensionsH}`}
                        name="packagingDimensionsH"
                        placeholder="Width"
                        value={packagingDimensionsH || ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
                {/* <Label for="variant" sm={3}></Label>{" "}
                <Col sm={9}>
                  <Input
                    type="text"
                    data-object="variant"
                    name="packagingDimensions"
                    placeholder="Enter package dimension"
                    value={variantData.packagingDimensions || ""}
                    onChange={handleChange}
                  />
                </Col> */}
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Package Weight (kg) <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="number"
                    name="packagingWeightKg"
                    data-object="variant"
                    placeholder="Enter package weight in KG"
                    value={variantData.packagingWeightKg || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              {variantData.attributes
                ? Object.keys(variantData.attributes).map((index) => {
                    const attribute = variantData.attributes[index];
                    const options =
                      attribute.applicableOptions &&
                      attribute.applicableOptions.length &&
                      attribute.applicableOptions.map((idx) => ({
                        value: idx,
                        label: idx,
                        type: "attribute",
                      }));

                    const helpTxt =
                      attribute.helpText &&
                      attribute.helpText.length > 0 &&
                      attribute.helpText.map((text, indx) => {
                        return (
                          <p key={"ttext-" + indx} className="tool-list">
                            {text}
                          </p>
                        );
                      });

                    const inputProps = {
                      type: "text",
                      ...(attribute.type === "Textarea" && {
                        type: "textarea",
                      }),
                      ...(attribute.type === "Integer" && {
                        step: 1,
                        type: "number",
                        min: 0,
                      }),
                      ...((attribute.type === "Float" || attribute.type === "Long" || attribute.type === "Double") && {
                        step: 0.01,
                        type: "number",
                        min: 0,
                      }),
                    };

                    return (
                      <FormGroup row key={index}>
                        <Label for="variant" sm={3}>
                          {attribute.name.toLowerCase()} {attribute.mandatory ? <span className="star">*</span> : null}
                          {attribute.helpText && attribute.helpText.length > 0 ? (
                            <span className="help-icon">
                              <img alt="help" src={helpIcon} id={"Tooltip-att-" + attribute.name.replace(" ", "_")} />
                              <TooltipItem
                                key={"tkey-" + attribute.name}
                                placement="right"
                                text={helpTxt}
                                id={"att-" + attribute.name.replace(" ", "_")}
                              />
                            </span>
                          ) : null}
                        </Label>
                        <Col sm={9}>
                          {attribute.applicableOptions && attribute.applicableOptions.length > 0 ? (
                            <Select
                              options={options}
                              isSearchable={false}
                              placeholder={
                                attribute.placeholder ? attribute.placeholder : "Select " + attribute.name.toLowerCase()
                              }
                              value={options.filter((option) => {
                                return option.value === attribute.value;
                              })}
                              onChange={(opt) => handleChangeSelect(index, opt)}
                            />
                          ) : (
                            <Input
                              {...inputProps}
                              name={index}
                              data-object="attributes"
                              placeholder={"Enter " + attribute.name.toLowerCase()}
                              value={attribute.value || ""}
                              onChange={this.props.handleChange}
                            />
                          )}
                        </Col>
                      </FormGroup>
                    );
                  }, this)
                : null}

              <FormGroup row>
                <Label for="description1" sm={3}>
                  Description 1
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="description1"
                    data-object="variant"
                    placeholder="Enter Description 1"
                    value={variantData.description1 || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="description2" sm={3}>
                  Description 2
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="description2"
                    data-object="variant"
                    placeholder="Enter Description 2"
                    value={variantData.description2 || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="description3" sm={3}>
                  Description 3
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="description3"
                    data-object="variant"
                    placeholder="Enter Description 3"
                    value={variantData.description3 || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            </React.Fragment>
          ) : null}

          <FormGroup row>
            <Label for="updatebutton" sm={3} />
            <Col sm={9}>
              <Button block color="primary" size="md" onClick={addSku}>
                {productId ? "Update" : "Add"} Product
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default ProductForm;
