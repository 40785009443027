import React from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardBody,
  CardFooter,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";

class PreRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      values,
      mobileState,
      handleChange,
      saveAndContinue,
      isLoading,
      isEnabled
    } = this.props;
    return (
      <>
        <CardBody>
          <Form autoComplete="off" onSubmit={saveAndContinue}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>+91</InputGroupText>
                </InputGroupAddon>
                <Input
                  className={mobileState}
                  type="text"
                  name="mobile"
                  placeholder="Mobile Number"
                  maxLength="10"
                  onChange={handleChange("mobile")}
                  value={values.mobile}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </CardBody>

        <CardFooter className="text-center">
          <Button
            type="button"
            block
            color="primary"
            size="md"
            onClick={saveAndContinue}
            disabled={isLoading || !isEnabled}
          >
            Register
          </Button>
          <div className="clearfix" />
          <div className="text-center">
            <p className="pt-3 ">
              Already have an account? &nbsp;
              <Link to="/login" className="link footer-link">
                Sign In
              </Link>
            </p>
          </div>
        </CardFooter>
      </>
    );
  }
}

export default PreRegistration;
