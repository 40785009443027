import React, { Component } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import pdf from "../assets/img/pdf.svg";

class IZoom extends Component {
  render() {
    const { src, style, className, alt } = this.props;

    const fileExt = src.substr(-3);

    return fileExt === "pdf" ? (
      <a className="d-inline pdf-icon" href={src} target="_blank" rel="noopener noreferrer">
        <img src={pdf} alt="alt" style={{ maxHeight: 80 }} />
      </a>
    ) : (
      <Zoom closeText="Close" zoomMargin={40}>
        <img
          src={src}
          alt={alt ? alt : ""}
          className={className ? className : "img--fluid img--thumbnail"}
          style={style ? { ...style } : {}}
        />
      </Zoom>
    );
  }
}

export default IZoom;
