import { SAVE_STATES, SAVE_CITIES } from "../actions/actionTypes";

const initialState = {
  states: null,
  cities: null,
};
const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_STATES:
      return {
        ...state,
        states: action.data,
      };
    case SAVE_CITIES:
      return {
        ...state,
        cities: action.data,
      };
    default:
      return state;
  }
};

export default locationReducer;
