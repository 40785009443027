import { SAVE_RESPONSE_MESSAGE, CLEAR_RESPONSE_MESSAGE, CLEAR_RESPONSE_MESSAGES } from "./actionTypes";

export const saveResponseMessage = (screen, data) => {
  return async (dispatch) => {
    dispatch(saveMessage(screen, data));
  };
};

export const saveMessage = (screen, data) => {
  return {
    type: SAVE_RESPONSE_MESSAGE,
    data: data,
    objectType: screen,
  };
};

export const clearMessage = (screen) => {
  return {
    type: CLEAR_RESPONSE_MESSAGE,
    objectType: screen,
  };
};

export const clearMessages = () => {
  return {
    type: CLEAR_RESPONSE_MESSAGES,
  };
};
