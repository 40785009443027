import { startUILoading, stopUILoading } from "./uiActions";
import { setResponseValues, resetResponseValues } from "./responseHandlerActions";
import { clearMessage } from "./responseMessageActions";
import {
  REGISTER_API_URL,
  REFRESH_TOKEN,
  VERIFY_AUTH_CODE_API_URL,
  LOGIN_API_URL,
  FORGOT_PASSWORD_API_URL,
  FORGOT_PASSWORD_VERIFY_API_URL,
} from "../../utils/constants/apiUrls";

import { SUCCESS, FAILURE, defaultParams } from "../../utils/constants";

import { USER_LOGOUT, SAVE_AUTH_INFO, REMOVE_AUTH_INFO, UPDATE_AUTH_TOKEN_INFO } from "./actionTypes";

import axios from "axios";

/** Supplier Registration (Generate Auth Code)*/
export const registerUser = (authData) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    let config = { headers: { "Content-Type": "application/json" } };

    try {
      const reqParam = { ...authData, ...defaultParams() };
      const response = await axios.post(REGISTER_API_URL, reqParam, config);
      console.log("Auth token generated");
      dispatch(setResponseValues(SUCCESS, response.data, null));
    } catch (error) {
      console.log("Auth token generation failed");
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Supplier Registration (Verify Auth Code) */
export const verifyAuthCode = (authData) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    let config = { headers: { "Content-Type": "application/json" } };

    try {
      const reqParam = { ...authData, ...defaultParams() };
      const response = await axios.post(VERIFY_AUTH_CODE_API_URL, reqParam, config);
      dispatch(setResponseValues(SUCCESS, response.data, null));
      console.log("Verify Auth token Success");
      let authDataRes = {
        userId: response.data.user.userId,
        parentUserId: response.data.user.parentUserId,
        mobileNumber: response.data.user.mobileNumber,
        userType: response.data.user.userType,
        userRole: response.data.user.userRole,
        deviceId: response.data.user.deviceId,
        deviceToken: response.data.user.deviceToken,
        token: response.data.auth.token,
        refreshToken: response.data.auth.refreshToken,
        expiresIn: response.data.auth.expires,
      };
      dispatch(authStoreUserInfo(authDataRes));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Forgot Password (Generate Auth Code) */
export const forgotPassword = (authData) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    let config = { headers: { "Content-Type": "application/json" } };
    try {
      const reqParam = { ...authData, ...defaultParams() };
      const response = await axios.post(FORGOT_PASSWORD_API_URL, reqParam, config);
      console.log("Forgot Password Success Executed");
      dispatch(setResponseValues(SUCCESS, response.data, null));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Forgot Password (Verify Auth Code) */
export const forgotPasswordVerify = (authData) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    let config = { headers: { "Content-Type": "application/json" } };

    try {
      const reqParam = { ...authData, ...defaultParams() };
      const response = await axios.post(FORGOT_PASSWORD_VERIFY_API_URL, reqParam, config);

      dispatch(setResponseValues(SUCCESS, response.data, null));
      console.log("Forgot Password Verify Success Executed");
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Login User (With mobile no and password) */
export const loginUser = (authData) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage("commonerror"));
    const config = { headers: { "Content-Type": "application/json" } };
    try {
      const reqParam = { ...authData, ...defaultParams() };
      const response = await axios.post(LOGIN_API_URL, reqParam, config);
      dispatch(setResponseValues(SUCCESS, response.data, null));

      const authDataRes = {
        userId: response.data.user.userId,
        parentUserId: response.data.user.parentUserId,
        mobileNumber: response.data.user.mobileNumber,
        userType: response.data.user.userType,
        userRole: response.data.user.userRole,
        deviceId: response.data.user.deviceId,
        deviceToken: response.data.user.deviceToken,
        token: response.data.auth.token,
        refreshToken: response.data.auth.refreshToken,
        expiresIn: response.data.auth.expires,
      };

      dispatch(authStoreUserInfo(authDataRes));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Generate Auth data to save in Redux */
export const authStoreUserInfo = (authData) => {
  return (dispatch) => {
    /* Generate expiry time from no of seconds received from server (say 3600 seconds) */
    const now = new Date();
    const expiryDate = now.getTime() + authData.expiresIn * 1000;
    const data = { ...authData, expiryDate: expiryDate };
    dispatch(saveUserInfo(data));
  };
};

/** Save Auth data to Redux Store */
export const saveUserInfo = (data) => {
  return {
    type: SAVE_AUTH_INFO,
    userId: data.userId,
    parentUserId: data.parentUserId,
    mobileNumber: data.mobileNumber,
    userType: data.userType,
    userRole: data.userRole,
    deviceId: data.deviceId,
    deviceToken: data.deviceToken,
    token: data.token,
    refreshToken: data.refreshToken,
    expiryDate: data.expiryDate,
  };
};

/** Update token info in Redux after refreshing */
export const updateTokenInfo = (data) => {
  return {
    type: UPDATE_AUTH_TOKEN_INFO,
    token: data.token,
    refreshToken: data.refreshToken,
    expiryDate: data.expiryDate,
  };
};

export const userLogout = () => {
  return {
    type: USER_LOGOUT,
  };
};

export const authRemoveToken = () => {
  return {
    type: REMOVE_AUTH_INFO,
  };
};

export const authAutoSignIn = () => {
  return async (dispatch) => {
    try {
      const token = await dispatch(authGetToken());
      console.log("Auto login success", token);
    } catch (error) {
      console.log("Auto login failed");
    }
  };
};

/** Get request headers to make API request */
export const getRequestHeaders = () => {
  return async (dispatch) => {
    try {
      const token = await dispatch(authGetToken());
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      return { headers };
    } catch {
      return { headers: {} };
    }
  };
};

/** Get Auth token from Redux Store to make API requests
 * This function fetches token from store and returns it
 * If the token is expired it refreshes the token, store it in redux and return it
 */
export const authGetToken = () => {
  return async (dispatch, getState) => {
    const { token, refreshToken, userId, userType, userRole, expiryDate } = getState().auth;

    const currentDate = new Date();
    const tokenExpiryDate = new Date(expiryDate);

    if (token && expiryDate && tokenExpiryDate >= currentDate) {
      return token;
    } else {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          refreshtoken: refreshToken,
        },
      };

      const reqData = {
        userId,
        userType,
        userRole,
        ...defaultParams(),
      };

      try {
        const response = await axios.post(REFRESH_TOKEN, reqData, config);

        console.log("Refresh Token Success Executed");
        const now = new Date();
        const expiresIn = response.data.auth.expires;
        const token = response.data.auth.token;
        const refToken = response.data.auth.refreshToken;
        const expiryDate = now.getTime() + expiresIn * 1000;
        const data = {
          token: token,
          refreshToken: refToken,
          expiryDate: expiryDate,
        };
        dispatch(updateTokenInfo(data));
        return token;
      } catch (error) {
        /*const data = { token: null, refreshToken: null, expiryDate: null };
                                        dispatch(updateTokenInfo(data));*/

        console.log("Refresh Token Failure Executed ");

        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */

          console.log("Error response", error.response);
          if (error.response.status === "401" || error.response.status === "403") {
            const data = { token: null, refreshToken: null, expiryDate: null };
            dispatch(updateTokenInfo(data));
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log("Response was received with Error", error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Other error ", error.message);
        }
      }
    }
  };
};
