import { SAVE_CATEGORIES } from "../actions/actionTypes";

const initialState = {
  categories: null,
  lastUpdated: new Date().getTime(),
};
const skuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CATEGORIES:
      return {
        ...state,
        categories: action.data,
        lastUpdated: new Date().getTime(),
      };

    default:
      return state;
  }
};

export default skuReducer;
