import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Form,
  FormGroup,
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { loginUser } from "../../../datautils/actions/authActions";
import { resetResponseValues } from "../../../datautils/actions/responseHandlerActions";
import InfoArea from "../../../components/InfoArea/InfoArea";
import ErrorBlock from "../../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../../components/SuccessBlock/SuccessBlock";
import HomeHeader from "../../../components/Headers/HomeHeader";
import { isUserLoggedIn } from "../../../utils/helpers/HelperFunctions";
import { getUrlParams } from "../../../utils/helpers/HelperFunctions";
import { SUPPLIER_BASE, ADMIN_BASE } from "../../../utils/constants/clientUrls";
import Footer from "../../../views/Common/Footer";

import ico1 from "../../../assets/img/laptop2@3x.png";
import ico2 from "../../../assets/img/screen3@3x.png";
import ico3 from "../../../assets/img/speaker2@3x.png";
import ico4 from "../../../assets/img/coin@3x.png";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      mobileState: "",
      parentMobileNumber: "",
      parentMobileState: "",
      password: "",
      passwordState: "",
      userType: "SUPPLIER",
      hasError: false,
      errors: { mobile: "", password: "" },
      associate: false,
    };
  }
  componentDidMount() {
    this.props.resetResponseValues();
    const params = getUrlParams(this.props.location.search);
    if (params["associate"] === "1") {
      this.setState({ associate: true });
    }

    if (isUserLoggedIn()) {
      if (this.props.userType === "SUPPLIER") {
        this.props.history.push(SUPPLIER_BASE);
      } else {
        this.props.history.push(ADMIN_BASE);
      }
    }
  }

  typeNumber(e) {
    let numberRex = new RegExp("^[0-9]+$");
    let mobileState = this.state.mobileState;
    let mobile = e.target.value;
    if (numberRex.test(mobile) && mobile.length === 10) {
      mobileState = "is-valid";
    } else {
      mobileState = "is-invalid";
    }
    this.setState({ mobile, mobileState });
  }

  typeParentNumber(e) {
    let numberRex = new RegExp("^[0-9]+$");
    let parentMobileState = this.state.parentMobileState;
    let parentMobileNumber = e.target.value;
    if (numberRex.test(parentMobileNumber) && parentMobileNumber.length === 10) {
      parentMobileState = "is-valid";
    } else {
      parentMobileState = "is-invalid";
    }
    this.setState({ parentMobileNumber, parentMobileState });
  }

  rangeMinL(e) {
    let password = this.state.password;
    let passwordState = this.state.password;
    password = e.target.value;
    if (password.length > 5) {
      passwordState = "is-valid";
    } else {
      passwordState = "is-invalid";
    }
    this.setState({ password, passwordState });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeUserType = (e) => {
    this.setState({ userType: e.target.value });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState({ [obj]: opt.value });
    console.log(`Option selected:`, opt.value);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { userType, mobile, password, associate, parentMobileNumber } = this.state;

    const reqData = {
      userType,
      mobileCountryCode: "+91",
      mobileNumber: mobile,
      password,
    };

    if (associate) {
      reqData["parentMobileCountryCode"] = "+91";
      reqData["parentMobileNumber"] = parentMobileNumber;
    }

    this.props.loginUser(reqData);
  };

  componentDidUpdate(prevProps) {
    if (isUserLoggedIn()) {
      if (this.props.userType === "SUPPLIER") {
        this.props.history.push(SUPPLIER_BASE);
      } else {
        this.props.history.push(ADMIN_BASE);
      }
    }
  }

  render() {
    const { responseVal } = this.props;
    const {
      mobileState,
      mobile,
      passwordState,
      password,
      associate,
      parentMobileNumber,
      parentMobileState,
    } = this.state;

    let isEnabled = mobileState === "is-valid" && passwordState === "is-valid";
    if (associate && parentMobileState !== "is-valid") {
      isEnabled = false;
    }

    return (
      <div>
        <HomeHeader {...this.props} />
        <div className="full-page--content">
          <div className="login-page">
            <Container>
              <Row className="justify-content-center">
                <Col lg={4} className="ml-auto mr-auto order-lg-2">
                  <Form onSubmit={this.handleSubmit} autoComplete="off">
                    <Card className="card-login">
                      <CardHeader className="text-center">
                        <CardTitle tag="h4">{associate ? "Associate " : "Supplier "} Login</CardTitle>
                        {responseVal.status === "failure" ? (
                          <ErrorBlock errors={responseVal.errors} position="alert-relative" />
                        ) : null}

                        {responseVal.status === "success" ? (
                          <SuccessBlock msg={responseVal.data} position="alert-relative" />
                        ) : null}
                      </CardHeader>
                      <CardBody>
                        {associate && (
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>+91</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className={parentMobileState}
                                type="text"
                                name="mobile"
                                placeholder="Parent Mobile Number"
                                maxLength="10"
                                value={parentMobileNumber}
                                onChange={(e) => this.typeParentNumber(e)}
                              />
                            </InputGroup>
                          </FormGroup>
                        )}
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>+91</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className={mobileState}
                              type="text"
                              name="mobile"
                              placeholder="Mobile Number"
                              maxLength="10"
                              value={mobile}
                              onChange={(e) => this.typeNumber(e)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Input
                            className={passwordState}
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => this.rangeMinL(e)}
                          />
                        </FormGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          color="primary"
                          size="lg"
                          className="mb-3"
                          disabled={this.props.isLoading || !isEnabled}
                        >
                          Login
                        </Button>
                        {associate ? (
                          <p className="text-center">If you forgot your password ask your admin to reset it</p>
                        ) : (
                          <>
                            <p className="text-center">
                              <Link to="/forgot" className="link footer-link">
                                Forgot Password?
                              </Link>
                            </p>
                            <div className="text-center ">
                              <p className="">
                                Don't have an account? &nbsp;
                                <Link to="/register" className="link footer-link">
                                  Sign Up
                                </Link>
                              </p>
                            </div>
                          </>
                        )}
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
                <Col lg={8} className="order-lg-1">
                  <h1>Become a seller and sell your products on Slickpals</h1>
                  <Row>
                    <Col sm={6}>
                      <InfoArea
                        img={ico1}
                        icon="now-ui-icons users_circle-08"
                        iconColor="neutral"
                        title="Sign Up"
                        titleColor="info"
                        description="After signup complete your KYC"
                      />
                    </Col>
                    <Col>
                      {" "}
                      <InfoArea
                        img={ico2}
                        icon="now-ui-icons shopping_tag-content"
                        iconColor="neutral"
                        title="List your products"
                        titleColor="info"
                        description="List your products on the plaltform"
                      />
                    </Col>

                    <Col sm={6}>
                      {" "}
                      <InfoArea
                        img={ico3}
                        icon="now-ui-icons objects_spaceship"
                        iconColor="neutral"
                        title="Build your market"
                        titleColor="info"
                        description="Grow you business with us"
                      />
                    </Col>

                    <Col sm={6}>
                      {" "}
                      <InfoArea
                        img={ico4}
                        icon="now-ui-icons business_money-coins"
                        iconColor="neutral"
                        title="Sell your products"
                        titleColor="info"
                        description="Increase your revenue"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    userType: state.auth.userType,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (reqData) => dispatch(loginUser(reqData)),
    resetResponseValues: () => dispatch(resetResponseValues()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
