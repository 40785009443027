import React from "react";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import { clearMessage } from "../../datautils/actions/responseMessageActions";
//import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class MessageBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  componentDidUpdate() {
    const { responseMessages, screenName, position } = this.props;

    if (
      position !== "relative" &&
      responseMessages &&
      screenName &&
      responseMessages[screenName]
    ) {
      this.timeout = setTimeout(() => {
        this.props.clearMessage(screenName);
      }, 3000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onDismiss = () => {
    //this.setState({ visible: false });
    this.props.clearMessage(this.props.screenName);
  };

  render() {
    const {
      responseMessages,
      screenName,
      successMessage,
      position,
    } = this.props;

    const msgs = [];
    let color = "success";

    if (responseMessages && screenName && responseMessages[screenName]) {
      const responseMessage = responseMessages[screenName];

      if (responseMessage.hasErrors) {
        color = "danger";
        if (
          responseMessage.fvErrorMap &&
          Object.keys(responseMessage.fvErrorMap).length > 0
        ) {
          Object.keys(responseMessage.fvErrorMap).map((key) => {
            const m = responseMessage.fvErrorMap[key].message;
            return msgs.push(m);
          });
        } else if (responseMessage.userDisplayMsg) {
          msgs.push(responseMessage.userDisplayMsg);
        } else if (
          responseMessage.error &&
          responseMessage.error.detailMessage
        ) {
          msgs.push(responseMessage.error.detailMessage);
        } else {
          msgs.push("Something went wrong, try again");
        }
      } else if (responseMessage.error) {
        color = "danger";
        msgs.push(responseMessage.error);
      } else if (!responseMessage.hasErrors) {
        if (responseMessage.userDisplayMsg) {
          msgs.push(responseMessage.userDisplayMsg);
        } else {
          msgs.push(successMessage || "Updated successfully");
        }
      }
    }

    return msgs.length > 0 ? (
      // <div className="abc">
      //   <ToastContainer />
      //   {color === "success"
      //     ? toast.success(msgs.join("\n"), {
      //         position: "top-center",
      //         autoClose: false,
      //         hideProgressBar: true,
      //         closeOnClick: false,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //       })
      //     : toast.error(msgs.join("\n"), {
      //         position: "top-center",
      //         autoClose: false,
      //         hideProgressBar: true,
      //         closeOnClick: false,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //       })}
      // </div>

      <Alert
        color={color}
        isOpen={this.state.visible}
        toggle={this.onDismiss}
        className={position ? position : ""}
      >
        <div>{msgs.join("\n")}</div>
      </Alert>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    responseMessages: state.responseMessages.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearMessage: (screenName) => dispatch(clearMessage(screenName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBlock);
