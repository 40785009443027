import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../utils/constants";
import HomeHeader from "../../components/Headers/HomeHeader";
import Footer from "../../views/Common/Footer";
import Contact from "../../components/StaticPages/Contact";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.body.classList.add("colo");
  }

  render() {
    return (
      <div>
        <HomeHeader {...this.props} />
        <Helmet>
          <title>Contact Us - {APP_NAME}</title>
        </Helmet>
        <div className="full-page--content">
          <div className="not-found-page video">
            <Container>
              <Row>
                <Col sm={12} className="d-flex h-100">
                  <Contact />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContactPage;
