export const APP_NAME = "Slickpals";
export const APP_URL = "https://www.slickpals.com";
export const WEB_URL = "https://www.slickpals.com";
export const APP_CODE = "SP";
export const DEVICE_ID = window.btoa(Date.now());
export const DEVICE_TYPE = "BROWSER";
export const SUCCESS = "success";
export const FAILURE = "failure";
export const OTP_TIMER = 20;
export const PER_PAGE = 20;

export const defaultParams = () => {
  return {
    appCode: APP_CODE,
    deviceId: DEVICE_ID,
    deviceType: DEVICE_TYPE,
  };
};

export const userRoles = [
  { userType: "ADMIN", value: "ADMIN", label: "ADMIN" },
  { userType: "OPS", value: "OPS_FINANCE", label: "FINANCE" },
  {
    userType: "OPS",
    value: "OPS_CATALOG_MANAGER",
    label: "CATALOG MANAGER",
  },
  { userType: "OPS", value: "OPS_ORDER_MANAGER", label: "ORDER MANAGER" },
  { userType: "OPS", value: "OPS_CUSTOMER_CARE", label: "CUSTOMER CARE" },
];

export const supplierUserRoles = [
  { userType: "SUPPLIER", value: "SUPPLIER_ADMIN", label: "ADMIN" },
  {
    userType: "SUPPLIER",
    value: "SUPPLIER_MANAGER",
    label: "MANAGER",
  },
  {
    userType: "SUPPLIER",
    value: "SUPPLIER_EMPLOYEE",
    label: "EMPLOYEE",
  },
];

export const opsUserTypes = [
  { value: "OPS", label: "OPS" },
  { value: "ADMIN", label: "ADMIN" },
];

export const deliveryPartners = [
  { value: "Blue_Dart_Express_Ltd", label: "Blue Dart Express Ltd" },
  { value: "DHL_Express_India_Pvt_Ltd", label: "DHL Express India Pvt Ltd" },
];

export const trackingOptions = [
  { value: "RECEIVED", label: "RECEIVED" },
  { value: "DISPATCHED", label: "DISPATCHED" },
  { value: "OUT_FOR_DELIVERY", label: "OUT FOR DELIVERY" },
  { value: "DELIVERED", label: "DELIVERED" },
];

export const businessTypes = [
  { value: "", label: "SELECT BUSINESS TYPE" },
  { value: "SOLE_PROPRIETORSHIP", label: "SOLE PROPRIETORSHIP" },
  { value: "PARTNERSHIP", label: "PARTNERSHIP" },
  { value: "PRIVATE_LIMITED_COMPANY", label: "PRIVATE LIMITED COMPANY" },
  { value: "PUBLIC_LIMITED_COMPANY", label: "PUBLIC LIMITED COMPANY" },
  { value: "LIMITED_LIMITED_COMPANY", label: "LIMITED LIMITED COMPANY" },
];

export const userGroupTypes = [
  { value: "SUPPLIER_GROUP", label: "SUPPLIER GROUP" },
  { value: "CUSTOMER_GROUP", label: "CUSTOMER GROUP" },
  { value: "ADMIN_GROUP", label: "ADMIN GROUP" },
  { value: "ALL", label: "ALL GROUPS" },
];

export const catTrendsDisplayStatusTypes = [
  { value: "SPONSORED", label: "SPONSORED" },
  { value: "FEATURED", label: "FEATURED" },
  { value: "GENERAL", label: "GENERAL" },
];

export const fulfillmentTypes = [
  { value: "SUPPLIER_ONLY", label: "SUPPLIER ONLY" },
  { value: "PLATFORM_PROVIDER", label: "PLATFORM PROVIDER" },
];

export const platformTypes = [
  { value: "WEB", label: "WEB" },
  { value: "ANDROID", label: "ANDROID" },
  { value: "IOS", label: "IOS" },
  { value: "MOBILE", label: "ALL MOBILE" },
];

export const campaignTypes = [
  { value: "BANNER", label: "BANNER" },
  // { value: "DEFAULT", label: "DEFAULT" },
];

export const entityAggregationTypes = [
  { value: "EXCLUSIVE", label: "EXCLUSIVE" },
  { value: "DEFAULT", label: "DEFAULT" },
];

export const genderOptions = [
  { value: "MALE", label: "MALE" },
  { value: "FEMALE", label: "FEMALE" },
  { value: "OTHER", label: "OTHER" },
];

export const genderTitleOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Miss", label: "Miss" },
  { value: "Mrs", label: "Mrs" },
];

export const orderRequestStatusOptions = [
  { value: "REQUESTED", label: "REQUESTED" },
  { value: "CLOSED", label: "CLOSED" },
];

export const couponTypes = [{ value: "PROMOTION", label: "PROMOTION" }];

export const inventoryTypes = [
  { value: "IMMEDIATE", label: "IMMEDIATE" },
  { value: "ON_REQUEST", label: "ON REQUEST" },
];

export const menuTypes = [
  { value: "MOBILE", label: "MOBILE MENUS" },
  // { value: "IOS", label: "IOS MENUS" },
  // { value: "ANDROID", label: "ANDROID MENUS" },
  { value: "WEB", label: "WEB MENUS" },
];

export const variantTypes = [
  { value: "NONE", label: "NOT APPLICABLE" },
  { value: "SIZE", label: "SIZE" },
  { value: "COLOUR", label: "COLOUR" },
];

export const orderTypes = [
  { label: "IN LOT ONLY", value: "YES" },
  { label: "DEFAULT", value: "NO" },
];

export const bargainOptions = [
  { value: "NO", label: "NO" },
  { value: "YES", label: "YES" },
];

export const stockActions = [
  {
    label: "Add",
    value: "add",
  },
  {
    label: "Remove",
    value: "remov",
  },
];

export const sellerDicountTypes = [
  { value: "PERCENTAGE", label: "PERCENTAGE" },
  { value: "ABSOLUTE_VALUE", label: "ABSOLUTE VALUE" },
  { value: "NONE", label: "NONE" },
];

export const unitOfMeasures = [
  { value: "PIECE", label: "Piece (pc)" },
  { value: "LENGTH_IN_METER", label: "Meter (m)" },
  { value: "LENGTH_IN_CENTIMETER", label: "Centimeter (cm)" },
  { value: "WEIGHT_IN_KG", label: "Kilogram (kg)" },
  { value: "WEIGHT_IN_GRAM", label: "Gram (g)" },
  { value: "LENGTH_IN_FEET", label: "Feet (ft)" },
  { value: "VOLUME_IN_LITER", label: "Liter (l)" },
  { value: "VOLUME_IN_ML", label: "MilliLiter (ml)" },
];

export const userTypes = [
  { value: "ADMIN", label: "ADMIN" },
  { value: "OPS", label: "OPS" },
];

export const requestTypes = [
  { value: "NEW_BRAND", label: "NEW BRAND" },
  { value: "OTHERS", label: "OTHERS" },
];

export const documentIdentifierNumberMapping = {
  PAN: "PAN Number",
  PAN_INFO: "PAN Number",
  SIGNATURE_SAMPLE: "Signature Sample",
  BOARD_RESOLUTION_INFO: "Board Resolution Number",
  LEGAL_ENTITY_NAME: "CIN Number",
  LEGAL_ENTITY_CHEQUE_INFO: "Check Number",
  BUSINESS_REGISTERED_ADDRESS: "Registered Address",
  COMPANY_INCORPORATION_CERTIFICATE: "Certificate Number",
  COMPANY_INCORPORATION_CERTIFICATE_INFO: "Certificate Number",
};

export const orderFilters = [
  { label: "PROCESSING", value: "1" },
  { label: "ON HOLD", value: "11" },
  { label: "CREATED", value: "21" },
  { label: "APPROVED", value: "31" },
  { label: "REJECTED", value: "41" },
  { label: "PACKED", value: "51" },
  { label: "SHIPPED", value: "61" },
  { label: "CANCELLED", value: "71" },
  { label: "DELIVERY CANCELLED", value: "72" },
  { label: "DELIVERED", value: "81" },
  { label: "RETURN REQUEST", value: "91" },
  { label: "RETURN COMPLETE", value: "96" },
  { label: "CLOSED", value: "99" },
  { label: "ALL", value: "" },
];

export const orderActions = {
  CUSTOMER: {
    ORDER_CREATED: ["ORDER_CANCELLED"],
    ORDER_DELIVERY_CANCELLED: [],
    ORDER_RETURNED_REQUEST_APPROVED: ["ORDER_RETURNED_REQUEST_CANCELLED"],
    ORDER_REJECTED: [],
    ORDER_PACKED: ["ORDER_CANCELLED"],
    ORDER_SHIPPED: ["ORDER_DELIVERY_CANCELLED"],
    ORDER_RETURNED_REQUEST_COMPLETE: [],
    ORDER_DELIVERED: ["ORDER_RETURNED_REQUESTED"],
    ORDER_RETURNED_REQUEST_SHIPPED: [],
    ORDER_ON_HOLD: ["ORDER_CANCELLED"],
    ORDER_RETURNED_REQUEST_CANCELLED: ["ORDER_RETURNED_REQUESTED"],
    ORDER_CANCELLED: [],
    ORDER_RETURNED_REQUEST_REJECTED: ["ORDER_RETURNED_REQUESTED"],
    ORDER_RETURNED_REQUESTED: ["ORDER_RETURNED_REQUEST_CANCELLED"],
    ORDER_PROCESSING: ["ORDER_CANCELLED"],
    ORDER_APPROVED: ["ORDER_CANCELLED"],
    ORDER_CLOSED: [],
  },
  ADMIN: {
    ORDER_CREATED: ["ORDER_APPROVED", "ORDER_REJECTED"],
    ORDER_DELIVERY_CANCELLED: [],
    ORDER_RETURNED_REQUEST_APPROVED: ["ORDER_RETURNED_REQUEST_REJECTED", "ORDER_RETURNED_REQUEST_SHIPPED"],
    ORDER_REJECTED: ["ORDER_APPROVED"],
    ORDER_PACKED: ["ORDER_REJECTED", "ORDER_SHIPPED"],
    ORDER_SHIPPED: ["ORDER_DELIVERED", "ORDER_DELIVERY_CANCELLED"],
    ORDER_RETURNED_REQUEST_COMPLETE: ["ORDER_CLOSED"],
    ORDER_DELIVERED: ["ORDER_RETURNED_REQUESTED", "ORDER_CLOSED"],
    ORDER_RETURNED_REQUEST_SHIPPED: ["ORDER_RETURNED_REQUEST_COMPLETE"],
    ORDER_ON_HOLD: ["ORDER_CREATED", "ORDER_REJECTED"],
    ORDER_RETURNED_REQUEST_CANCELLED: ["ORDER_RETURNED_REQUESTED"],
    ORDER_CANCELLED: [],
    ORDER_RETURNED_REQUEST_REJECTED: ["ORDER_RETURNED_REQUESTED"],
    ORDER_RETURNED_REQUESTED: ["ORDER_RETURNED_REQUEST_APPROVED", "ORDER_RETURNED_REQUEST_REJECTED"],
    ORDER_PROCESSING: ["ORDER_ON_HOLD", "ORDER_CREATED", "ORDER_REJECTED"],
    ORDER_APPROVED: ["ORDER_REJECTED", "ORDER_PACKED"],
    ORDER_CLOSED: [],
  },
  SUPPLIER: {
    ORDER_CREATED: ["ORDER_APPROVED", "ORDER_REJECTED"],
    ORDER_DELIVERY_CANCELLED: [],
    ORDER_RETURNED_REQUEST_APPROVED: ["ORDER_RETURNED_REQUEST_REJECTED", "ORDER_RETURNED_REQUEST_SHIPPED"],
    ORDER_REJECTED: [],
    ORDER_PACKED: ["ORDER_SHIPPED"],
    ORDER_SHIPPED: ["ORDER_DELIVERY_CANCELLED"],
    ORDER_RETURNED_REQUEST_COMPLETE: [],
    ORDER_DELIVERED: [],
    ORDER_RETURNED_REQUEST_SHIPPED: ["ORDER_RETURNED_REQUEST_COMPLETE"],
    ORDER_ON_HOLD: [],
    ORDER_RETURNED_REQUEST_CANCELLED: [],
    ORDER_CANCELLED: [],
    ORDER_RETURNED_REQUEST_REJECTED: [],
    ORDER_RETURNED_REQUESTED: ["ORDER_RETURNED_REQUEST_APPROVED", "ORDER_RETURNED_REQUEST_REJECTED"],
    ORDER_PROCESSING: [],
    ORDER_APPROVED: ["ORDER_REJECTED", "ORDER_PACKED"],
    ORDER_CLOSED: [],
  },
};

export const pickUpAddressChangeOptions = {
  ADMIN: {
    ORDER_CREATED: true,
    ORDER_DELIVERY_CANCELLED: false,
    ORDER_RETURNED_REQUEST_APPROVED: false,
    ORDER_REJECTED: false,
    ORDER_PACKED: true,
    ORDER_SHIPPED: false,
    ORDER_RETURNED_REQUEST_COMPLETE: false,
    ORDER_DELIVERED: false,
    ORDER_RETURNED_REQUEST_SHIPPED: false,
    ORDER_ON_HOLD: false,
    ORDER_RETURNED_REQUEST_CANCELLED: false,
    ORDER_CANCELLED: false,
    ORDER_RETURNED_REQUEST_REJECTED: false,
    ORDER_RETURNED_REQUESTED: false,
    ORDER_PROCESSING: false,
    ORDER_APPROVED: true,
    ORDER_CLOSED: false,
  },
  SUPPLIER: {
    ORDER_CREATED: true,
    ORDER_DELIVERY_CANCELLED: false,
    ORDER_RETURNED_REQUEST_APPROVED: false,
    ORDER_REJECTED: false,
    ORDER_PACKED: true,
    ORDER_SHIPPED: false,
    ORDER_RETURNED_REQUEST_COMPLETE: false,
    ORDER_DELIVERED: false,
    ORDER_RETURNED_REQUEST_SHIPPED: false,
    ORDER_ON_HOLD: false,
    ORDER_RETURNED_REQUEST_CANCELLED: false,
    ORDER_CANCELLED: false,
    ORDER_RETURNED_REQUEST_REJECTED: false,
    ORDER_RETURNED_REQUESTED: false,
    ORDER_PROCESSING: false,
    ORDER_APPROVED: true,
    ORDER_CLOSED: false,
  },
};

export const returnAddressChangeOptions = {
  ADMIN: {
    ORDER_CREATED: false,
    ORDER_DELIVERY_CANCELLED: false,
    ORDER_RETURNED_REQUEST_APPROVED: true,
    ORDER_REJECTED: false,
    ORDER_PACKED: false,
    ORDER_SHIPPED: false,
    ORDER_RETURNED_REQUEST_COMPLETE: false,
    ORDER_DELIVERED: false,
    ORDER_RETURNED_REQUEST_SHIPPED: false,
    ORDER_ON_HOLD: false,
    ORDER_RETURNED_REQUEST_CANCELLED: false,
    ORDER_CANCELLED: false,
    ORDER_RETURNED_REQUEST_REJECTED: false,
    ORDER_RETURNED_REQUESTED: true,
    ORDER_PROCESSING: false,
    ORDER_APPROVED: false,
    ORDER_CLOSED: false,
  },
  SUPPLIER: {
    ORDER_CREATED: false,
    ORDER_DELIVERY_CANCELLED: false,
    ORDER_RETURNED_REQUEST_APPROVED: true,
    ORDER_REJECTED: false,
    ORDER_PACKED: false,
    ORDER_SHIPPED: false,
    ORDER_RETURNED_REQUEST_COMPLETE: false,
    ORDER_DELIVERED: false,
    ORDER_RETURNED_REQUEST_SHIPPED: false,
    ORDER_ON_HOLD: false,
    ORDER_RETURNED_REQUEST_CANCELLED: false,
    ORDER_CANCELLED: false,
    ORDER_RETURNED_REQUEST_REJECTED: false,
    ORDER_RETURNED_REQUESTED: true,
    ORDER_PROCESSING: false,
    ORDER_APPROVED: false,
    ORDER_CLOSED: false,
  },
};

export const shippingInfoChangeOptions = {
  ADMIN: {
    ORDER_CREATED: true,
    ORDER_DELIVERY_CANCELLED: true,
    ORDER_RETURNED_REQUEST_APPROVED: true,
    ORDER_REJECTED: true,
    ORDER_PACKED: true,
    ORDER_SHIPPED: true,
    ORDER_RETURNED_REQUEST_COMPLETE: true,
    ORDER_DELIVERED: true,
    ORDER_RETURNED_REQUEST_SHIPPED: true,
    ORDER_ON_HOLD: true,
    ORDER_RETURNED_REQUEST_CANCELLED: true,
    ORDER_CANCELLED: true,
    ORDER_RETURNED_REQUEST_REJECTED: true,
    ORDER_RETURNED_REQUESTED: true,
    ORDER_PROCESSING: true,
    ORDER_APPROVED: true,
    ORDER_CLOSED: true,
  },
  SUPPLIER: {
    ORDER_CREATED: true,
    ORDER_DELIVERY_CANCELLED: true,
    ORDER_RETURNED_REQUEST_APPROVED: true,
    ORDER_REJECTED: true,
    ORDER_PACKED: true,
    ORDER_SHIPPED: true,
    ORDER_RETURNED_REQUEST_COMPLETE: true,
    ORDER_DELIVERED: true,
    ORDER_RETURNED_REQUEST_SHIPPED: true,
    ORDER_ON_HOLD: true,
    ORDER_RETURNED_REQUEST_CANCELLED: true,
    ORDER_CANCELLED: true,
    ORDER_RETURNED_REQUEST_REJECTED: true,
    ORDER_RETURNED_REQUESTED: true,
    ORDER_PROCESSING: true,
    ORDER_APPROVED: true,
    ORDER_CLOSED: true,
  },
};

export const deliveryChargeTypes = [
  { label: "NONE", value: "NONE" },
  { label: "PER PIECE", value: "PER_PIECE" },
  { label: "PER LOT", value: "PER_LOT" },
  { label: "PER ORDER", value: "PER_ORDER" },
];

export const sampleFulfillmentTypes = [
  { label: "NONE", value: "NONE" },
  { label: "ON REQUEST", value: "ON_REQUEST" },
  { label: "OPEN", value: "OPEN" },
];

export const sampleStatusTypes = [
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "INACTIVE", value: "INACTIVE" },
];

export const sampleRequestStatusTypes = [
  { label: "ACCEPT", value: "ACCEPTED" },
  { label: "REJECT", value: "REJECTED" },
];

export const customOfferStatusTypes = [
  { label: "ACCEPT", value: "SUPPLIER_QUOTE" },
  { label: "REJECT", value: "SUPPLIER_REJECTED" },
];

export const variantOptions = [
  { label: "NONE", value: "NONE", id: 0 },
  { label: "SIZE", value: "SIZE", id: 1 },
  { label: "COLOUR", value: "COLOUR", id: 2 },
  { label: "STORAGE", value: "STORAGE", id: 3 },
  { label: "RAM", value: "RAM", id: 4 },
];

export const colorOptions = [
  { value: "Alice Blue", label: "Alice Blue", color: "F0F8FF" },
  { value: "Antique White", label: "Antique White", color: "FAEBD7" },
  { value: "Aqua", label: "Aqua", color: "00FFFF" },
  { value: "Aquamarine", label: "Aquamarine", color: "7FFFD4" },
  { value: "Azure", label: "Azure", color: "F0FFFF" },
  { value: "Beige", label: "Beige", color: "F5F5DC" },
  { value: "Bisque", label: "Bisque", color: "FFE4C4" },
  { value: "Black", label: "Black", color: "000000" },
  { value: "Blanched Almond", label: "Blanched Almond", color: "FFEBCD" },
  { value: "Blue", label: "Blue", color: "0000FF" },
  { value: "Blue Violet", label: "Blue Violet", color: "8A2BE2" },
  { value: "Brown", label: "Brown", color: "A52A2A" },
  { value: "Burly Wood", label: "Burly Wood", color: "DEB887" },
  { value: "Cadet Blue", label: "Cadet Blue", color: "5F9EA0" },
  { value: "Chartreuse", label: "Chartreuse", color: "7FFF00" },
  { value: "Chocolate", label: "Chocolate", color: "D2691E" },
  { value: "Coral", label: "Coral", color: "FF7F50" },
  { value: "Cornflower Blue", label: "Cornflower Blue", color: "6495ED" },
  { value: "Cornsilk", label: "Cornsilk", color: "FFF8DC" },
  { value: "Crimson", label: "Crimson", color: "DC143C" },
  { value: "Cyan", label: "Cyan", color: "00FFFF" },
  { value: "Dark Blue", label: "Dark Blue", color: "00008B" },
  { value: "Dark Cyan", label: "Dark Cyan", color: "008B8B" },
  { value: "Dark Goldenrod", label: "Dark Goldenrod", color: "B8860B" },
  { value: "Dark Gray", label: "Dark Gray", color: "A9A9A9" },
  { value: "Dark Green", label: "Dark Green", color: "006400" },
  { value: "Dark Grey", label: "Dark Grey", color: "A9A9A9" },
  { value: "Dark Khaki", label: "Dark Khaki", color: "BDB76B" },
  { value: "Dark Magenta", label: "Dark Magenta", color: "8B008B" },
  { value: "Dark Olive Green", label: "Dark Olive Green", color: "556B2F" },
  { value: "Dark Orange", label: "Dark Orange", color: "FF8C00" },
  { value: "Dark Orchid", label: "Dark Orchid", color: "9932CC" },
  { value: "Dark Red", label: "Dark Red", color: "8B0000" },
  { value: "Dark Salmon", label: "Dark Salmon", color: "E9967A" },
  { value: "Dark Sea Green", label: "Dark Sea Green", color: "8FBC8F" },
  { value: "Dark Slate Blue", label: "Dark Slate Blue", color: "483D8B" },
  { value: "Dark Slate Gray", label: "Dark Slate Gray", color: "2F4F4F" },
  { value: "Dark Slate Grey", label: "Dark Slate Grey", color: "2F4F4F" },
  { value: "Dark Turquoise", label: "Dark Turquoise", color: "00CED1" },
  { value: "Dark Violet", label: "Dark Violet", color: "9400D3" },
  { value: "Deep Pink", label: "Deep Pink", color: "FF1493" },
  { value: "Deep Sky Blue", label: "Deep Sky Blue", color: "00BFFF" },
  { value: "Dim Gray", label: "Dim Gray", color: "696969" },
  { value: "Dim Grey", label: "Dim Grey", color: "696969" },
  { value: "Dodger Blue", label: "Dodger Blue", color: "1E90FF" },
  { value: "Fire Brick", label: "Fire Brick", color: "B22222" },
  { value: "Floral White", label: "Floral White", color: "FFFAF0" },
  { value: "Forest Green", label: "Forest Green", color: "228B22" },
  { value: "Fuchsia", label: "Fuchsia", color: "FF00FF" },
  { value: "Gainsboro", label: "Gainsboro", color: "DCDCDC" },
  { value: "Ghost White", label: "Ghost White", color: "F8F8FF" },
  { value: "Gold", label: "Gold", color: "FFD700" },
  { value: "Goldenrod", label: "Goldenrod", color: "DAA520" },
  { value: "Gray", label: "Gray", color: "808080" },
  { value: "Green", label: "Green", color: "008000" },
  { value: "Green Yellow", label: "Green Yellow", color: "ADFF2F" },
  { value: "Grey", label: "Grey", color: "808080" },
  { value: "Honeydew", label: "Honeydew", color: "F0FFF0" },
  { value: "Hot Pink", label: "Hot Pink", color: "FF69B4" },
  { value: "Indian Red", label: "Indian Red", color: "CD5C5C" },
  { value: "Indigo", label: "Indigo", color: "4B0082" },
  { value: "Ivory", label: "Ivory", color: "FFFFF0" },
  { value: "Khaki", label: "Khaki", color: "F0E68C" },
  { value: "Lavender", label: "Lavender", color: "E6E6FA" },
  { value: "Lavender Blush", label: "Lavender Blush", color: "FFF0F5" },
  { value: "Lawn Green", label: "Lawn Green", color: "7CFC00" },
  { value: "Lemon Chiffon", label: "Lemon Chiffon", color: "FFFACD" },
  { value: "Light Blue", label: "Light Blue", color: "ADD8E6" },
  { value: "Light Coral", label: "Light Coral", color: "F08080" },
  { value: "Light Cyan", label: "Light Cyan", color: "E0FFFF" },
  {
    value: "Light Goldenrod Yellow",
    label: "Light Goldenrod Yellow",
    color: "FAFAD2",
  },
  { value: "Light Gray", label: "Light Gray", color: "D3D3D3" },
  { value: "Light Green", label: "Light Green", color: "90EE90" },
  { value: "Light Grey", label: "Light Grey", color: "D3D3D3" },
  { value: "Light Pink", label: "Light Pink", color: "FFB6C1" },
  { value: "Light Salmon", label: "Light Salmon", color: "FFA07A" },
  { value: "Light Sea Green", label: "Light Sea Green", color: "20B2AA" },
  { value: "Light Sky Blue", label: "Light Sky Blue", color: "87CEFA" },
  { value: "Light Slate Gray", label: "Light Slate Gray", color: "778899" },
  { value: "Light Slate Grey", label: "Light Slate Grey", color: "778899" },
  { value: "Light Steel Blue", label: "Light Steel Blue", color: "B0C4DE" },
  { value: "Light Yellow", label: "Light Yellow", color: "FFFFE0" },
  { value: "Lime", label: "Lime", color: "00FF00" },
  { value: "Lime Green", label: "Lime Green", color: "32CD32" },
  { value: "Linen", label: "Linen", color: "FAF0E6" },
  { value: "Magenta", label: "Magenta", color: "FF00FF" },
  { value: "Maroon", label: "Maroon", color: "800000" },
  { value: "Medium Aquamarine", label: "Medium Aquamarine", color: "66CDAA" },
  { value: "Medium Blue", label: "Medium Blue", color: "0000CD" },
  { value: "Medium Orchid", label: "Medium Orchid", color: "BA55D3" },
  { value: "Medium Purple", label: "Medium Purple", color: "9370DB" },
  { value: "Medium Sea Green", label: "Medium Sea Green", color: "3CB371" },
  { value: "Medium Slate Blue", label: "Medium Slate Blue", color: "7B68EE" },
  {
    value: "Medium Spring Green",
    label: "Medium Spring Green",
    color: "00FA9A",
  },
  { value: "Medium Turquoise", label: "Medium Turquoise", color: "48D1CC" },
  { value: "Medium Violet Red", label: "Medium Violet Red", color: "C71585" },
  { value: "Midnight Blue", label: "Midnight Blue", color: "191970" },
  { value: "Mint Cream", label: "Mint Cream", color: "F5FFFA" },
  { value: "Misty Rose", label: "Misty Rose", color: "FFE4E1" },
  { value: "Moccasin", label: "Moccasin", color: "FFE4B5" },
  { value: "Navajo White", label: "Navajo White", color: "FFDEAD" },
  { value: "Navy", label: "Navy", color: "000080" },
  { value: "Old Lace", label: "Old Lace", color: "FDF5E6" },
  { value: "Olive", label: "Olive", color: "808000" },
  { value: "Olive Drab", label: "Olive Drab", color: "6B8E23" },
  { value: "Orange", label: "Orange", color: "FFA500" },
  { value: "Orange Red", label: "Orange Red", color: "FF4500" },
  { value: "Orchid", label: "Orchid", color: "DA70D6" },
  { value: "Pale Goldenrod", label: "Pale Goldenrod", color: "EEE8AA" },
  { value: "Pale Green", label: "Pale Green", color: "98FB98" },
  { value: "Pale Turquoise", label: "Pale Turquoise", color: "AFEEEE" },
  { value: "Pale Violet Red", label: "Pale Violet Red", color: "DB7093" },
  { value: "Papaya Whip", label: "Papaya Whip", color: "FFEFD5" },
  { value: "Peach Puff", label: "Peach Puff", color: "FFDAB9" },
  { value: "Peru", label: "Peru", color: "CD853F" },
  { value: "Pink", label: "Pink", color: "FFC0CB" },
  { value: "Plum", label: "Plum", color: "DDA0DD" },
  { value: "Powder Blue", label: "Powder Blue", color: "B0E0E6" },
  { value: "Purple", label: "Purple", color: "800080" },
  { value: "Rebecca Purple", label: "Rebecca Purple", color: "663399" },
  { value: "Red", label: "Red", color: "FF0000" },
  { value: "Rosy Brown", label: "Rosy Brown", color: "BC8F8F" },
  { value: "Royal Blue", label: "Royal Blue", color: "4169E1" },
  { value: "Saddle Brown", label: "Saddle Brown", color: "8B4513" },
  { value: "Salmon", label: "Salmon", color: "FA8072" },
  { value: "Sandy Brown", label: "Sandy Brown", color: "F4A460" },
  { value: "Sea Green", label: "Sea Green", color: "2E8B57" },
  { value: "Seashell", label: "Seashell", color: "FFF5EE" },
  { value: "Sienna", label: "Sienna", color: "A0522D" },
  { value: "Silver", label: "Silver", color: "C0C0C0" },
  { value: "Sky Blue", label: "Sky Blue", color: "87CEEB" },
  { value: "Slate Blue", label: "Slate Blue", color: "6A5ACD" },
  { value: "Slate Gray", label: "Slate Gray", color: "708090" },
  { value: "Slate Grey", label: "Slate Grey", color: "708090" },
  { value: "Snow", label: "Snow", color: "FFFAFA" },
  { value: "Spring Green", label: "Spring Green", color: "00FF7F" },
  { value: "Steel Blue", label: "Steel Blue", color: "4682B4" },
  { value: "Tan", label: "Tan", color: "D2B48C" },
  { value: "Teal", label: "Teal", color: "008080" },
  { value: "Thistle", label: "Thistle", color: "D8BFD8" },
  { value: "Tomato", label: "Tomato", color: "FF6347" },
  { value: "Turquoise", label: "Turquoise", color: "40E0D0" },
  { value: "Violet", label: "Violet", color: "EE82EE" },
  { value: "Wheat", label: "Wheat", color: "F5DEB3" },
  { value: "White", label: "White", color: "FFFFFF" },
  { value: "White Smoke", label: "White Smoke", color: "F5F5F5" },
  { value: "Yellow", label: "Yellow", color: "FFFF00" },
  { value: "Yellow Green", label: "Yellow Green", color: "9ACD32" },
  { value: "Others", label: "Others", color: "" },
];

export const mobileVerifyStateObj = () => {
  return {
    mobileNumber: {
      value: "",
      valid: true,
      validationRules: {
        isMobileNumber: true,
      },
      touched: true,
    },
    countryCode: {
      value: "+91",
      valid: true,
      validationRules: {
        notEmpty: true,
      },
      touched: true,
    },
    verificationCode: {
      value: "",
      valid: false,
      validationRules: {
        minLength: 4,
        maxLength: 6,
      },
      touched: false,
    },
  };
};
export const mobileSubmitStateObj = () => {
  return {
    mobileNumber: {
      value: "",
      valid: false,
      validationRules: {
        isMobileNumber: true,
      },
      touched: false,
    },
    countryCode: {
      value: "+91",
      valid: true,
      validationRules: {
        notEmpty: true,
      },
      touched: true,
    },
  };
};
