import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
//import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
//import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import Attributes from "./Attributes";

class AttributeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdated: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {
    // const { isUpdated } = this.state;
    const { isLoading } = this.props;

    return (
      <div>
        <PanelHeader />
        <div className="content mt-2 ">
          {/* {responseVal.status === "failure" ? (
            <ErrorBlock errors={responseVal.errors} />
          ) : null}

          {responseVal.status === "success" && isUpdated ? (
            <SuccessBlock
              msg={{
                userDisplayMsg: responseVal.data
              }}
            />
          ) : null} */}

          <Col xs={12}>
            <MessageBlock screenName="category" />
          </Col>

          <React.Fragment>
            <Row>
              <Col md={12}>
                <h5 className="mt-2">Create or Update Attributes</h5>
              </Col>

              <Col md={6}>
                <div className="bg-white p-2" style={{ minHeight: 585 }}>
                  <Attributes
                    handleAttribute={() => {}}
                    existingAttributes={{}}
                    standAlone="true"
                  />
                </div>
              </Col>
            </Row>

            {isLoading ? <div className="loader" /> : null}
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AttributeList);
