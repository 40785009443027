import React from "react";
import { Row, Col, FormGroup, Card, CardBody, Button, Table, ButtonToolbar, Label, Form, Input } from "reactstrap";

import { connect } from "react-redux";
import { searchRequest, addUpdateRequest } from "../../datautils/actions/adminActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { formatDateTime } from "../../utils/helpers/HelperFunctions";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { sortIcon } from "../../utils/constants/icons";
import { resetResponseValues, resetObjectValues } from "../../datautils/actions/responseHandlerActions";
import { PER_PAGE, requestTypes } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import { SUPPLIER_REQUEST_VIEW } from "../../utils/constants/clientUrls";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import { uploadImages } from "../../datautils/actions/skuActions";
import Select from "react-select";
import Dropzone from "../../components/Dropzone/Dropzone";

class Requests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "0",
      currentFilter: "requestedTicketStatus",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "requestId",
      ascending: false,
      toggle: false,
      isUpdated: false,
      requestType: "NEW_BRAND",
      subject: "",
      details: "",
      files: [],
      id: 0,
    };
  }

  toggleBox = () => {
    this.setState({ toggle: !this.state.toggle, isUpdated: false });
  };

  componentDidMount() {
    this.searchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { responseVal } = this.props;
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchData();
    }

    if (
      responseVal.dObjectData.requestDetail &&
      responseVal.dObjectData.requestDetail.data &&
      prevState.lastUpdated !== responseVal.dObjectData.requestDetail.data.updatedOn
    ) {
      const data = responseVal.dObjectData.requestDetail.data;
      this.setState(
        {
          lastUpdated: data.updatedOn,
          files: [],
          subject: "",
          details: "",
          toggle: false,
        },
        () => this.searchData()
      );
    }
  }

  handleChangePage = (id) => {
    this.props.history.push(SUPPLIER_REQUEST_VIEW + id);
  };

  searchButton = () => {
    this.setState({ pageNumber: 1 }, () => this.searchData());
  };

  handleChangeSelect = (obj, opt) => {
    this.setState((prevState) => ({
      ...prevState,
      [obj]: opt.value,
    }));
  };

  sortButton = (field, order) => {
    this.setState({ sortField: field, ascending: order }, () => this.searchData());
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState({ pageNumber: 1 }, () => this.searchData());
    }
  };

  onDrop = (acceptedFiles) => {
    this.setState({ files: [...this.state.files, ...acceptedFiles] });
    acceptedFiles.map((acceptedFile) => {
      return this.props.uploadImages(acceptedFile);
    });
  };

  createBox = (i) => {
    this.props.resetResponseValues();
    this.props.resetObjectValues("requestDetail");

    this.setState({
      toggle: i === 1 ? false : true,
      id: 0,
      files: [],
      subject: "",
      details: "",
      isUpdated: false,
      existingImages: {},
    });
  };

  searchData = () => {
    const { globalSearchKeyword, currentFilter, currentTab, sortField, ascending, pageNumber, pageSize } = this.state;

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [
        {
          key: currentFilter,
          operation: "EQUAL_TO",
          value: currentTab,
        },
      ],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.props.searchRequest(reqData);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  createRequest = (e) => {
    e.preventDefault();

    let mediasToPost = {};
    if (this.props.responseVal && this.props.responseVal.media && this.props.responseVal.media.length > 0) {
      this.props.responseVal.media.forEach((fileId) => {
        mediasToPost[fileId] = { fileId: fileId, status: "ACTIVE" };
      });
      console.log("mediasToPost", mediasToPost);
    }
    this.setState({ isUpdated: true });

    const reqParam = {
      subject: this.state.subject,
      details: this.state.details,
      requestType: this.state.requestType,
      medias: mediasToPost,
    };

    this.props.addUpdateRequest({ requestQueueVO: reqParam });
  };

  filterData = (e) => {
    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
        currentFilter: e.target.dataset.filter,
      },
      () => this.searchData()
    );
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.requestList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  render() {
    const { responseVal } = this.props;
    const { isUpdated, globalSearchKeyword, subject, details, requestType, files, toggle } = this.state;
    const dataLoaded = responseVal.dObjectData?.requestList?.data?.requestQueues;

    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}

              {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="6">
                  {toggle ? (
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb pt-0">
                        <li className="breadcrumb-item">
                          <span onClick={() => this.createBox(1)}>&laquo; Back to Tickets</span>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          Create Tickets
                        </li>
                      </ol>
                    </nav>
                  ) : (
                    <ButtonToolbar>
                      <Button
                        size="sm"
                        value="1"
                        data-filter="requestedTicketStatus"
                        onClick={this.filterData}
                        color={
                          this.state.currentTab === "1" && this.state.currentFilter === "requestedTicketStatus"
                            ? "primary"
                            : ""
                        }
                      >
                        ONHOLD
                      </Button>
                      <Button
                        size="sm"
                        value="2"
                        data-filter="requestedTicketStatus"
                        onClick={this.filterData}
                        color={
                          this.state.currentTab === "2" && this.state.currentFilter === "requestedTicketStatus"
                            ? "primary"
                            : ""
                        }
                      >
                        CLOSED
                      </Button>
                      <Button
                        size="sm"
                        value="0"
                        data-filter="requestedTicketStatus"
                        onClick={this.filterData}
                        color={
                          this.state.currentTab === "0" && this.state.currentFilter === "requestedTicketStatus"
                            ? "primary"
                            : ""
                        }
                      >
                        OPEN
                      </Button>
                      <Button
                        size="sm"
                        value=""
                        data-filter="requestedTicketStatus"
                        onClick={this.filterData}
                        color={
                          this.state.currentTab === "" && this.state.currentFilter === "requestedTicketStatus"
                            ? "primary"
                            : ""
                        }
                      >
                        ALL
                      </Button>
                    </ButtonToolbar>
                  )}
                </Col>
                <Col xs="6">
                  {!toggle ? (
                    <Button size="sm" color="success" className="pull-right" onClick={() => this.createBox(0)}>
                      Create New Ticket
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {toggle ? (
              <Col xs="12">
                <div className="bg-white p-4 card-default mb-4">
                  <Row>
                    <span className="close-box" onClick={() => this.createBox(1)}>
                      <i className="now-ui-icons ui-1_simple-remove" />
                    </span>
                    <Col md="7">
                      <Form autoComplete="off" className="form--horizontal mt-3">
                        <FormGroup row>
                          <Label for="variant" sm={3}>
                            Request Type <span className="star">*</span>
                          </Label>
                          <Col sm={9}>
                            <Select
                              name="requestType"
                              options={requestTypes}
                              value={requestTypes.filter((option) => option.value === requestType)}
                              onChange={(opt) => this.handleChangeSelect("requestType", opt)}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="title" sm={3}>
                            Subject <span className="star">*</span>
                          </Label>
                          <Col sm={9}>
                            <Input
                              type="text"
                              name="subject"
                              placeholder="Subject"
                              value={subject || ""}
                              onChange={this.handleChange}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="title" sm={3}>
                            Description <span className="star">*</span>
                          </Label>
                          <Col sm={9}>
                            <Input
                              type="textarea"
                              name="details"
                              placeholder="Add a brief description of ticket"
                              value={details || ""}
                              onChange={this.handleChange}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="button" sm={3} />
                          <Col sm={9}>
                            <Button
                              size="md"
                              color="primary"
                              onClick={this.createRequest}
                              disabled={!subject || !details}
                            >
                              Create Ticket
                            </Button>
                          </Col>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col md="5" className="mt-3">
                      {" "}
                      <Dropzone onFilesAdded={(opt) => this.onDrop(opt)} />
                      {files ? (
                        <Row className="mt-2 equal">
                          {files.length > 0 &&
                            files.map((acceptedFile) => (
                              <Col sm="6" className="" key={acceptedFile.name}>
                                <img
                                  alt={acceptedFile.name}
                                  src={URL.createObjectURL(acceptedFile)}
                                  className="img-thumbnail rounded"
                                />
                                <p className="text-center">{acceptedFile.name}</p>
                              </Col>
                            ))}
                        </Row>
                      ) : null}
                      {this.state.existingImages ? (
                        <Row className="mt-2 equal">
                          {Object.keys(this.state.existingImages).map((index) => {
                            return (
                              <Col md="4" key={index}>
                                <div className="bg-white p-2 mb-3">
                                  {" "}
                                  <img
                                    alt=""
                                    src={getMediaPath(
                                      this.state.existingImages[index].fileId,
                                      this.state.existingImages[index].fileExt
                                    )}
                                  />
                                  {this.state.existingImages[index].status === "ACTIVE" ? (
                                    <Button
                                      size="sm"
                                      className="btn-block"
                                      color="success"
                                      onClick={this.enableDisableImage(index, "INACTIVE")}
                                    >
                                      Deactivate
                                    </Button>
                                  ) : (
                                    <Button
                                      size="sm"
                                      className="btn-block"
                                      color="danger"
                                      onClick={this.enableDisableImage(index, "ACTIVE")}
                                    >
                                      Activate
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      ) : null}
                    </Col>
                  </Row>{" "}
                </div>
              </Col>
            ) : null}
            <Col xs={12}>
              <React.Fragment>
                <Card>
                  <CardBody className="listings">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search tickets..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button color="primary" onClick={this.searchButton}>
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="8">{dataLoaded ? this.renderPagination() : ""}</Col>
                    </Row>
                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>
                          <th onClick={() => this.sortButton("id", this.state.ascending ? false : true)}>
                            Ticket ID <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>
                          <th>Subject </th>
                          <th onClick={() => this.sortButton("requestType", this.state.ascending ? false : true)}>
                            Ticket Type <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>
                          <th>Approval Status</th>
                          <th>Ticket Status</th>
                          <th>Created On</th>
                          <th>Updated On</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLoaded ? (
                          dataLoaded.length ? (
                            dataLoaded.map((row, index) => {
                              const cname =
                                row.requestTicketStatus === "OPEN"
                                  ? "success"
                                  : row.requestTicketStatus === "CLOSED"
                                  ? "danger"
                                  : row.requestTicketStatus === "ONHOLD"
                                  ? "warning"
                                  : "primary";
                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    {this.state.pageNumber * this.state.pageSize + (index + 1) - this.state.pageSize}
                                  </td>
                                  <td onClick={() => this.handleChangePage(row.requestId)} className="cursor">
                                    {row.requestId}
                                  </td>
                                  <td onClick={() => this.handleChangePage(row.requestId)} className="cursor">
                                    {row.subject}
                                  </td>
                                  <td>{row.requestType}</td>

                                  <td>{row.approvalStatus}</td>
                                  <td>{row.requestTicketStatus}</td>
                                  <td>{formatDateTime(row.createdOn)}</td>
                                  <td>{formatDateTime(row.updatedOn)}</td>
                                  <td className="text-right">
                                    <Button
                                      className={`btn-simple btn-light btn-${cname}`}
                                      size="xs"
                                      onClick={() => this.handleChangePage(row.requestId)}
                                    >
                                      View
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="10" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="10" />
                          </tr>
                        )}
                      </tbody>
                    </Table>{" "}
                    {this.props.isLoading ? <div className="loader" /> : ""}
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    resetObjectValues: (object) => dispatch(resetObjectValues(object)),
    searchRequest: (reqData) => dispatch(searchRequest(reqData)),
    uploadImages: (acceptedFile) => dispatch(uploadImages(acceptedFile)),
    addUpdateRequest: (reqData) => dispatch(addUpdateRequest(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
