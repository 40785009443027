import React from "react";
import { Row, Col, FormGroup, Button, Label, Form, Input, Nav, NavItem, NavLink } from "reactstrap";

import { connect } from "react-redux";
import {
  addNewCampaign,
  fetchCampaign,
  updateCampaign,
  addUpdateCampaignMedia,
  activateDeactivateCampaign,
} from "../../datautils/actions/adminActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";

import { ADMIN_CAMPAIGN_VIEW, ADMIN_CAMPAIGN_LIST } from "../../utils/constants/clientUrls";

import GoBack from "../../components/GoBack/GoBack";
import { PER_PAGE, platformTypes, campaignTypes, entityAggregationTypes } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import Select from "react-select";
import Dropzone from "../../components/Dropzone/Dropzone";
import { uploadImages, uploadAllImages } from "../../datautils/actions/skuActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  resetResponseValues,
  resetObjectValues,
  removeMediaObject,
} from "../../datautils/actions/responseHandlerActions";
import { getMediaPath, getMediaUrl } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";
import CropperModal from "../../components/ImageCropper/CropperModal";
import uniqid from "uniqid";

class Campaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "1",
      currentFilter: "status",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "name",
      ascending: false,
      toggle: false,
      name: "",
      campaignType: "BANNER",
      entityAggregationType: "DEFAULT",
      platformType: "MOBILE",
      files: {},
      code: "",
      description: "",
      startDateTime: "",
      endDateTime: "",
      id: 0,
      existingImages: {},
      tags: "",
      show: false,
      activeTab: "1",
    };
  }
  componentDidMount() {
    const { id, code } = this.props.match.params;
    console.log(this.props.history);
    this.setState({ id: id === "new" ? 0 : id, code });
    if (id === "new") {
    } else {
      this.props.fetchCampaign({ id });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { responseVal } = this.props;

    const campaignData = responseVal.dObjectData?.campaign?.data?.campaign;

    if (campaignData && (prevState.lastUpdated !== campaignData.updatedOn || prevState.id !== campaignData.id)) {
      const data = campaignData;

      let start = new Date(data.startDateTime);
      let end = new Date(data.endDateTime);

      const tags = data?.tags?.join(",");

      this.setState({
        id: data.id,
        name: data.name,
        code: data.code,
        status: data.status,
        campaignType: data.campaignType,
        entityAggregationType: data.entityAggregationType,
        platformType: data.platformType,
        startDateTime: start,
        endDateTime: end,
        description: data.description,
        existingImages: data.medias,
        lastUpdated: data.updatedOn,
        files: {},
        tags,
      });
    }
  }

  toggleBox = () => {
    this.setState({ toggle: !this.state.toggle, isUpdated: false });
  };

  removeFromState = (row) => (e) => {
    const filteredArray = this.state.files.filter((element) => element.name !== row.name);
    this.setState({ files: [...filteredArray] });
    this.props.removeMediaObject(row.name);
  };

  enableDisableImage = (key, status) => (e) => {
    const reqParam = {
      [key]: { fileId: key, status },
    };

    this.props.addUpdateCampaignMedia({
      campaign: {
        id: this.state.id,
        code: this.state.code,
        status,
        medias: reqParam,
      },
    });

    this.setState({ isUpdated: true });
  };

  enableDisableCampaign = (status) => (e) => {
    this.props.activateDeactivateCampaign({
      campaign: {
        id: this.state.id,
        code: this.state.code,
        status,
      },
    });

    this.setState({ isUpdated: true });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  formatDate = (date) => {
    const now = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const first = now.substr(0, now.length - 3);
    const last = now.substr(-3);
    return first + last.replace(":", "");
  };

  handleChangeStartDate = (date) => {
    this.setState({ startDateTime: date });
  };

  handleChangeEndDate = (date) => {
    this.setState({ endDateTime: date });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState((prevState) => ({
      ...prevState,
      [obj]: opt.value,
    }));
  };

  createBox = (i) => (e) => {
    this.props.resetResponseValues();
    this.props.resetObjectValues("campaign");

    this.setState({
      toggle: i === 1 ? false : true,
      name: "",
      campaignType: "DEFAULT",
      entityAggregationType: "DEFAULT",
      platformType: "MOBILE",
      files: [],
      code: "",
      description: "",
      startDateTime: "",
      endDateTime: "",
      tags: "",
      id: 0,
      isUpdated: false,
      existingImages: {},
    });
  };

  handleChangePage = (id, code) => {
    const { history } = this.props;
    history.push({ pathname: ADMIN_CAMPAIGN_VIEW + id + "/" + code, state: { prevPath: ADMIN_CAMPAIGN_LIST } });
  };

  handleEditPage = (input) => (e) => {
    console.log("Edit mode", input);
    this.setState({ toggle: true, isUpdated: false, files: [] }, () => this.props.fetchCampaign({ id: input }));

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  onDrop = (acceptedFiles) => {
    const newObj = {};
    acceptedFiles.forEach((file) => {
      newObj[uniqid()] = { original: file, base64: null, cropped: null, uploaded: null };
    });

    this.setState({ ...this.state, show: true, files: { ...this.state.files, ...newObj } });

    // this.setState({ files: [...this.state.files, ...acceptedFiles] });

    // acceptedFiles.forEach((acceptedFile) => {
    //   this.props.uploadImages(acceptedFile);
    // });
  };

  addNewCampaign = async () => {
    const {
      campaignType,
      entityAggregationType,
      platformType,
      code,
      name,
      description,
      startDateTime,
      endDateTime,
      tags,
      files,
    } = this.state;

    const tagsArray = tags.split(",").map((element) => element.trim());

    const medias = {};
    if (Object.keys(files).length > 0) {
      const fileIds = await this.props.uploadAllImages(files);
      fileIds?.forEach((fileId) => {
        medias[fileId] = { fileId, status: "ACTIVE" };
      });
    }

    const reqParam = {
      campaignType,
      entityAggregationType,
      platformType,
      code,
      name,
      description,
      status: "INACTIVE",
      startDateTime: this.formatDate(startDateTime),
      endDateTime: this.formatDate(endDateTime),
      medias,
      tags: tagsArray,
    };
    this.props.addNewCampaign({ campaign: reqParam });
    this.setState({ isUpdated: true });
  };

  updateCampaign = async (e) => {
    e.preventDefault();
    const {
      campaignType,
      entityAggregationType,
      platformType,
      code,
      name,
      description,
      startDateTime,
      endDateTime,
      id,
      status,
      tags,
      files,
    } = this.state;

    const tagsArray = tags.split(",").map((element) => element.trim());

    const medias = {};
    if (Object.keys(files).length > 0) {
      const fileIds = await this.props.uploadAllImages(files);
      fileIds?.forEach((fileId) => {
        medias[fileId] = { fileId, status: "ACTIVE" };
      });
    }

    const reqParam = {
      id,
      status,
      campaignType,
      entityAggregationType,
      platformType,
      code,
      name,
      description,
      startDateTime: this.formatDate(startDateTime),
      endDateTime: this.formatDate(endDateTime),
      medias,
      tags: tagsArray,
    };
    this.props.updateCampaign({ campaign: reqParam });
    this.setState({ isUpdated: true });
  };

  /** Save uploaded media to SKU */
  saveMediaWith = (mediaList) => {
    const medias = {};

    mediaList?.forEach((fileId) => {
      medias[fileId] = { fileId, status: "ACTIVE" };
    });

    const {
      campaignType,
      entityAggregationType,
      platformType,
      code,
      name,
      description,
      startDateTime,
      endDateTime,
      id,
      status,
      tags,
    } = this.state;

    const tagsArray = tags.split(",").map((element) => element.trim());

    const reqParam = {
      id,
      status,
      campaignType,
      entityAggregationType,
      platformType,
      code,
      name,
      description,
      startDateTime: this.formatDate(startDateTime),
      endDateTime: this.formatDate(endDateTime),
      medias,
      tags: tagsArray,
    };
    this.props.updateCampaign({ campaign: reqParam });
    this.setState({ isUpdated: true });
  };

  /** Upload cropped image to server and attach with Product */
  uploadCroppedImage = async () => {
    try {
      const fileIds = await this.props.uploadAllImages(this.state.files);
      this.saveMediaWith(fileIds);
    } catch (error) {
      console.log("uploadCroppedImage", error);
    }
  };

  setShow = (show) => {
    this.setState({ ...this.state, show: !show });
  };

  setFiles = (files) => {
    console.log("files", files);
    this.setState({ ...this.state, files: { ...files } });
  };

  render() {
    const { responseVal, isLoading } = this.props;
    const {
      isUpdated,

      name,
      platformType,
      campaignType,
      entityAggregationType,
      code,
      description,
      files,
      startDateTime,
      endDateTime,
      id,
      existingImages,
      tags,
      show,
    } = this.state;

    const totalFiles = Object.keys(files).length;
    const totalCropped = Object.keys(files).filter((key) => files[key].cropped);

    return (
      <div>
        <PanelHeader />
        {show && (
          <CropperModal
            show={show}
            setShow={(show) => this.setShow(show)}
            files={files}
            setFiles={(files) => this.setFiles(files)}
            aspect={5 / 3}
          />
        )}
        <div className="content mt-3">
          <Row>
            <Col xs="8">
              <GoBack text={id > 0 ? "Update Campaign" : "Create Campaign"} history={this.props.history} />
            </Col>

            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" && <ErrorBlock errors={responseVal.errors} />}
              {responseVal.status === "success" && isUpdated && <SuccessBlock msg={responseVal.data} />}
            </Col>

            {id > 0 ? (
              <Col xs={12}>
                <Nav tabs>
                  <NavItem style={{ marginLeft: -1 }}>
                    <NavLink className={`${this.state.activeTab === "1" ? "active" : ""}`}>CAMPAIGN INFO</NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink onClick={() => this.handleChangePage(id, code)}>CAMPAIGN MAPPING</NavLink>
                  </NavItem>
                </Nav>
              </Col>
            ) : null}

            <Col xs="12">
              <div className="bg-white p-4 card-default mb-4">
                <Row>
                  <Col md="6">
                    <Form autoComplete="off" className="form--horizontal mt-3">
                      {id > 0 ? (
                        <FormGroup row>
                          <Label for="title" sm={4}>
                            Campaign Status
                          </Label>
                          <Col sm={8}>
                            <h5 className="d-flex justify-content-between">
                              <small className="mb-0">{this.state.status}</small>
                              {responseVal?.dObjectData?.campaign ? (
                                <>
                                  {this.state.status === "ACTIVE" ? (
                                    <Button
                                      size="sm"
                                      className="mb-0 mt-0"
                                      color="success"
                                      onClick={this.enableDisableCampaign("INACTIVE")}
                                    >
                                      Deactivate Campaign
                                    </Button>
                                  ) : (
                                    <Button
                                      size="sm"
                                      className="mb-0 mt-0"
                                      color="danger"
                                      onClick={this.enableDisableCampaign("ACTIVE")}
                                    >
                                      Activate Campaign
                                    </Button>
                                  )}
                                </>
                              ) : null}
                            </h5>
                          </Col>
                        </FormGroup>
                      ) : null}
                      <FormGroup row>
                        <Label for="title" sm={4}>
                          Campaign Name <span className="star">*</span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="name"
                            placeholder="Campaign name"
                            onChange={this.handleChange}
                            value={name || ""}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="variant" sm={4}>
                          Campaign Platform <span className="star">*</span>
                        </Label>
                        <Col sm={8}>
                          <Select
                            isSearchable={false}
                            name="platformType"
                            options={platformTypes}
                            value={platformTypes.filter((option) => option.value === platformType)}
                            onChange={(opt) => this.handleChangeSelect("platformType", opt)}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="variant" sm={4}>
                          Campaign Type <span className="star">*</span>
                        </Label>
                        <Col sm={8}>
                          <Select
                            isSearchable={false}
                            name="campaignType"
                            options={campaignTypes}
                            value={campaignTypes.filter((option) => option.value === campaignType)}
                            onChange={(opt) => this.handleChangeSelect("campaignType", opt)}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="variant" sm={4}>
                          Entity Aggregation Type <span className="star">*</span>
                        </Label>
                        <Col sm={8}>
                          <Select
                            isSearchable={false}
                            name="entityAggregationType"
                            options={entityAggregationTypes}
                            value={entityAggregationTypes.filter((option) => option.value === entityAggregationType)}
                            onChange={(opt) => this.handleChangeSelect("entityAggregationType", opt)}
                          />
                          <p className="mt-1">
                            <small>
                              DEFAULT - All associated product with selected entity (brand, product, supplier, campaign,
                              category) will be part of the campaign
                            </small>
                            <br />
                            <small>
                              EXCLUSIVE - All product except the selected entity (brand, product, supplier, campaign,
                              category) will be part of the campaign
                            </small>
                          </p>
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="title" sm={4}>
                          Campaign Code<span className="star">*</span>
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="code"
                            disabled={id}
                            placeholder="Campaign code"
                            value={code || ""}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="title" sm={4}>
                          Start Date <span className="star">*</span>
                        </Label>
                        <Col sm={8}>
                          <DatePicker
                            className="form-control"
                            placeholderText="Click to select start date"
                            showTimeSelect
                            selected={startDateTime}
                            onChange={this.handleChangeStartDate}
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                            timeCaption="Time"
                            minDate={new Date()}
                            withPortal
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="title" sm={4}>
                          End Date <span className="star">*</span>
                        </Label>
                        <Col sm={8}>
                          <DatePicker
                            className="form-control"
                            placeholderText="Click to select end date"
                            showTimeSelect
                            selected={endDateTime}
                            onChange={this.handleChangeEndDate}
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                            timeCaption="Time"
                            minDate={startDateTime}
                            withPortal
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="title" sm={4}>
                          Description
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="textarea"
                            name="description"
                            placeholder="Campaign description"
                            value={description || ""}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="title" sm={4}>
                          Tags
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="textarea"
                            name="tags"
                            placeholder="Campaign tags or keywords for search separated by comma ,"
                            value={tags || ""}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="button" sm={4} />
                        <Col sm={8}>
                          {id > 0 ? (
                            <Button
                              size="md"
                              className="btn-block"
                              color="primary"
                              onClick={this.updateCampaign}
                              disabled={!name || !code || !startDateTime || !endDateTime}
                            >
                              Update Campaign
                            </Button>
                          ) : (
                            <Button
                              size="md"
                              className="btn-block"
                              color="primary"
                              onClick={this.addNewCampaign}
                              disabled={!name || !code || !startDateTime || !endDateTime}
                            >
                              Create New Campaign
                            </Button>
                          )}
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col md="6 mt-3">
                    <Dropzone onFilesAdded={(opt) => this.onDrop(opt)} />

                    <Row>
                      <Col sm={12}>
                        <Row className="p-2">
                          <div className="p-2">
                            {Object.keys(files).map((key) => {
                              const file = files[key];
                              return file.cropped ? (
                                <div key={`cropped-${key}`}>
                                  <ImageZoom
                                    src={file.cropped}
                                    alt={file.cropped}
                                    className="img-thumbnail rounded"
                                    style={{ maxHeight: 100 }}
                                  />
                                </div>
                              ) : null;
                            })}
                          </div>
                        </Row>
                      </Col>

                      <Col sm={12}>
                        {Object.keys(files).length > 0 ? (
                          <Button size="sm" color="light" onClick={() => this.setShow(false)}>
                            Edit Images
                          </Button>
                        ) : null}

                        {id && totalFiles > 0 && totalFiles === totalCropped?.length ? (
                          <Button size={"sm"} color="success" onClick={() => this.uploadCroppedImage()}>
                            Save Images
                          </Button>
                        ) : null}
                      </Col>
                    </Row>

                    {existingImages && Object.keys(existingImages).length > 0 ? (
                      <div className="price-invent" style={{ padding: 0, minHeight: "auto" }}>
                        <div className="card-header">Available Images</div>
                        <div className="card-body">
                          <Row className="d-flex">
                            {Object.keys(existingImages).map((index) => {
                              return (
                                <div className="m-2 ml-3" key={index}>
                                  <div className="bg-white p-2 text-center">
                                    <ImageZoom
                                      src={getMediaPath(existingImages[index].fileId, existingImages[index].fileExt)}
                                      alt=""
                                      style={{ maxHeight: 50 }}
                                    />
                                    {existingImages[index].status === "ACTIVE" ? (
                                      <Button
                                        className="btn-xs btn-block"
                                        color="success"
                                        onClick={this.enableDisableImage(index, "INACTIVE")}
                                      >
                                        Deactivate
                                      </Button>
                                    ) : (
                                      <Button
                                        className="btn-xs btn-block"
                                        color="danger"
                                        onClick={this.enableDisableImage(index, "ACTIVE")}
                                      >
                                        Activate
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </Row>
                        </div>
                      </div>
                    ) : null}

                    {existingImages && Object.keys(existingImages).length > 0 ? (
                      <div className="price-invent mt-3" style={{ padding: 0, minHeight: "auto" }}>
                        <div className="card-header">Converted Images</div>
                        <div className="card-body">
                          <Row className="d-flex">
                            {existingImages &&
                              Object.keys(existingImages).map((index) => {
                                const path = getMediaUrl({
                                  fileId: existingImages[index].fileId,
                                  fileExt: existingImages[index].fileExt,
                                  width: 500,
                                  height: 300,
                                });

                                return (
                                  <div className="m-2 ml-3" key={`---${index}`}>
                                    <div className="bg-white p-2 text-center">
                                      <ImageZoom src={path} alt="" style={{ maxHeight: 80 }} />
                                      <a
                                        className="btn btn-xs btn-primary btn-block align-items-center justify-content-center d-flex"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={path}
                                      >
                                        Converted 500x300
                                      </a>
                                    </div>
                                  </div>
                                );
                              })}
                          </Row>
                        </div>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Col>

            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    resetObjectValues: (object) => dispatch(resetObjectValues(object)),

    uploadImages: (acceptedFile) => dispatch(uploadImages(acceptedFile)),
    addNewCampaign: (reqData) => dispatch(addNewCampaign(reqData)),
    fetchCampaign: (reqData) => dispatch(fetchCampaign(reqData)),
    updateCampaign: (reqData) => dispatch(updateCampaign(reqData)),
    removeMediaObject: (reqParam) => dispatch(removeMediaObject(reqParam)),
    activateDeactivateCampaign: (reqData) => dispatch(activateDeactivateCampaign(reqData)),
    addUpdateCampaignMedia: (reqParam) => dispatch(addUpdateCampaignMedia(reqParam)),
    uploadAllImages: (files) => dispatch(uploadAllImages(files)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
