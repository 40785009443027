//import { createBrowserHistory } from "history";
import { startUILoading, stopUILoading } from "./uiActions";
import {
  setResponseValues,
  resetResponseValues,
  storeMediaList,
  storeMediaObject,
  clearMediaList,
  clearMediaObject,
  resetObjectValues,
} from "./responseHandlerActions";

import axios from "axios";
import {
  GET_CATEGORIES,
  FETCH_ALL_BRANDS,
  GET_SKU_FORM,
  ADD_UPDATE_SKU,
  SEARCH_SKU,
  SEARCH_SUPPLIER_SKUS,
  ADD_UPDATE_SKU_MEDIA,
  FETCH_SKU,
  DELETE_SKU_MEDIA,
  ADMIN_UPDATE_SKU,
  ADMIN_UPDATE_MEDIA,
  ADMIN_FETCH_SKU,
  UPDATE_SKU_PRICE,
  UPDATE_SKU_INVENTORY,
  ADMIN_UPDATE_PRODUCT_MEDIA,
  ACTIVE_DEACTIVE_SKU,
  ADMIN_PRODUCT_STATUS,
  ADMIN_UPDATE_PRODUCT,
  SEARCH_BRAND,
  SUPPLIER_UPDATE_SKU_SAMPLE,
  UPDATE_STATUS_SKU_MEDIA,
  SUPPLIER_SIMILAR_SKU,
  ADMIN_SIMILAR_SKU,
  SEARCH_MASTER_SKU,
} from "../../utils/constants/apiUrls";
import { ADMIN_SKU_VIEW, SUPPLIER_SKU_VIEW } from "../../utils/constants/clientUrls";
import { SUCCESS, FAILURE } from "../../utils/constants";
import { SAVE_CATEGORIES } from "./actionTypes";
import { saveResponseMessage, clearMessage } from "./responseMessageActions";

import { getRequestHeaders } from "./authActions";
import { uploadMedia, uploadBase64Media } from "./mediaActions";
//const history = createBrowserHistory();
const skuResponseMessageKey = "sku";
const productResponseMessageKey = "product";

export const getCategories = (reqParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const defaultParam = {};

      const postData = { ...reqParam, ...defaultParam };

      axios
        .post(GET_CATEGORIES, postData, config)
        .then((response) => {
          console.log("Get categories Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(storeCategories(response.data));
        })
        .catch(function (error) {
          console.log("Get categories Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const getBrandList = (reqParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const defaultParam = {};

      const postData = { ...reqParam, ...defaultParam };

      axios
        .post(FETCH_ALL_BRANDS, postData, config)
        .then((response) => {
          console.log("Get brands Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "brands"));
        })
        .catch(function (error) {
          console.log("Get brands Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const searchBrand = (reqParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };

      const postData = { ...reqParam, ...defaultParam };

      //const URL = SEARCH_BRAND + reqParam.globalSearchKeyword;

      axios
        .post(SEARCH_BRAND, postData, config)
        .then((response) => {
          console.log("SEARCH_BRAND brands Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "brands"));
        })
        .catch(function (error) {
          console.log("Get brands Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

/** Fetch sku form to fill */

export const getSkuForm = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(resetObjectValues("skuForm"));
    dispatch(clearMessage(skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };

      const URL = GET_SKU_FORM + "?catRefTree=" + postData.iRefId + "&brandCode=" + postData.brandCode;

      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "skuForm"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(skuResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Check if the sku is existing */
export const checkSkuForm = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());

    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };

      const response = await axios.post(SEARCH_MASTER_SKU, postData, config);
      return response.data;
      // return {
      //   title: "Cyan Clog",
      //   productVariantId: "1299",
      //   variantType: "SIZE",
      //   variant: "L",
      //   eanCode: "",
      //   productMasterId: "1143",
      //   preferredSupplierUserId: "5",
      //   preferredSkuId: null,
      //   description: "hell clogs",
      //   description1: "",
      //   description2: "",
      //   description3: "",
      //   status: "ACTIVE",
      //   warranty: "1 yr",
      //   unitOfMeasure: "PIECE",
      //   additionalInfo: "",
      //   packagingWeightKg: "1",
      //   packagingDimensions: "100x30x400",
      //   attributes: {},
      //   suppliers: { "5": { verified: false } },
      //   skuIds: { "10": true },
      //   medias: [
      //     {
      //       mediaId: "12",
      //       fileId: "112_1_1_s_i_i3_fnue7byoro",
      //       fileExt: "jpg",
      //       status: "ACTIVE",
      //       primary: true,
      //       icon: false,
      //     },
      //   ],
      //   defaultFileId: "112_1_1_s_i_i3_fnue7byoro",
      //   defaultFileExt: "jpg",
      // };
    } catch (error) {
      console.log("Error in fetching master sku");
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add or Update SKU/Product info (Used by both admin and Supplier) */
export const addUpdateSku = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(reqParam.isProduct ? productResponseMessageKey : skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;

      const defaultParam = {
        userId,
        userType,
        supplierUserId: userId,
      };
      const postData = { ...defaultParam, ...reqParam };
      let URL = userType === "SUPPLIER" ? ADD_UPDATE_SKU : ADMIN_UPDATE_SKU;

      if (reqParam.isProduct) {
        URL = ADMIN_UPDATE_PRODUCT;
      }

      /** Added on 26 Dec 2019 (New API for SKU image section to mark as completed) */
      if (reqParam.completedType && reqParam.completedType === "skuMediaUploadedFinal") {
        URL = UPDATE_STATUS_SKU_MEDIA;
      }
      /********************** */

      const response = await axios.post(URL, postData, config);

      if (reqParam.isProduct) {
        const responseData = response.data.product ? response.data.product : response.data;

        dispatch(setResponseValues(SUCCESS, responseData, null, "productInfo"));
      } else {
        const responseData = response.data.sku ? response.data.sku : response.data;

        dispatch(setResponseValues(SUCCESS, responseData, null, "skuForm"));
      }
      /** Clear error from common reducer if any */
      dispatch(
        saveResponseMessage(reqParam.isProduct ? productResponseMessageKey : skuResponseMessageKey, {
          hasErrors: false,
        })
      );

      if (reqParam.sku && !reqParam.sku.skuId) {
        const newSkuId = response.data?.data?.sku?.skuId;
        if (newSkuId) {
          const redirect = userType === "SUPPLIER" ? SUPPLIER_SKU_VIEW : ADMIN_SKU_VIEW;
          //history.push(redirect + newSkuId);
          window.location.href = redirect + newSkuId;
        }
      }
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(
          saveResponseMessage(
            reqParam.isProduct ? productResponseMessageKey : skuResponseMessageKey,
            error.response.data
          )
        );
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Change status of SKU/Product (Used by both admin and Supplier) */
export const markAdminSku = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(reqParam.isProduct ? productResponseMessageKey : skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...defaultParam, ...reqParam };

      let URL = userType === "SUPPLIER" ? ADD_UPDATE_SKU : ADMIN_UPDATE_SKU;

      if (reqParam.isProduct) {
        URL = ADMIN_PRODUCT_STATUS;
      }

      const response = await axios.post(URL, postData, config);

      const responseData = response.data.sku ? response.data.sku : response.data;

      reqParam.isProduct
        ? dispatch(setResponseValues(SUCCESS, responseData, null, "productInfo"))
        : dispatch(setResponseValues(SUCCESS, responseData, null, "skuForm"));

      /** Clear error from common reducer if any */
      dispatch(
        saveResponseMessage(reqParam.isProduct ? productResponseMessageKey : skuResponseMessageKey, {
          hasErrors: false,
        })
      );
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(
          saveResponseMessage(
            reqParam.isProduct ? productResponseMessageKey : skuResponseMessageKey,
            error.response.data
          )
        );
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Activate or Deactivate SKU (Used by both admin and Supplier) */
export const activeDeactiveSku = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;

      const defaultParam = {
        userId,
        userType,
      };
      const postData = { ...defaultParam, ...reqParam };

      const URL = userType === "SUPPLIER" ? ACTIVE_DEACTIVE_SKU : ADMIN_UPDATE_SKU;

      const response = await axios.post(URL, postData, config);
      const responseData = response.data.sku ? response.data.sku : response.data;

      dispatch(setResponseValues(SUCCESS, responseData, null, "skuForm"));
      /** Clear error from common reducer if any */
      dispatch(saveResponseMessage(skuResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(skuResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Search sku list */
export const searchSku = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;

      const defaultParam = {
        userId,
        userType,
      };
      const postData = { ...defaultParam, ...reqParam };

      const response = await axios.post(SEARCH_SKU, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "skuList"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(skuResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Fetch SKU details */
export const fetchSku = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(resetObjectValues("skuForm"));

    dispatch(clearMessage(skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;

      const defaultParam = { userId, userType };

      const postData = { ...reqParam, ...defaultParam };

      const MID_URL = userType === "SUPPLIER" ? FETCH_SKU : ADMIN_FETCH_SKU;
      const URL = MID_URL + reqParam.skuId;

      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "skuForm"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(skuResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Save images (Accept/Reject) */
export const saveImages = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(reqParam.isProductImage ? productResponseMessageKey : skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;

      const defaultParam = {
        userId,
        userType,
      };
      const postData = { ...defaultParam, ...reqParam };
      const URL = reqParam.isProductImage ? ADMIN_UPDATE_PRODUCT_MEDIA : ADD_UPDATE_SKU_MEDIA;
      const response = await axios.post(URL, postData, config);
      const responseData = response.data.sku ? response.data.sku : response.data;

      dispatch(clearMediaList());
      dispatch(clearMediaObject());
      reqParam && reqParam.isProductImage
        ? dispatch(setResponseValues(SUCCESS, response.data, null, "productInfo"))
        : dispatch(setResponseValues(SUCCESS, responseData, null, "skuForm"));
      /** Clear error from common reducer if any */
      dispatch(
        saveResponseMessage(reqParam.isProductImage ? productResponseMessageKey : skuResponseMessageKey, {
          hasErrors: false,
        })
      );
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(
          saveResponseMessage(
            reqParam.isProductImage ? productResponseMessageKey : skuResponseMessageKey,
            error.response.data
          )
        );
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Delete SKU or Product Image (Admin and Supplier) */
export const deleteImages = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(reqParam.isProductImage ? productResponseMessageKey : skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;

      const defaultParam = {
        userId,
        userType,
      };

      const postData = { ...defaultParam, sku: reqParam };
      const response = await axios.post(DELETE_SKU_MEDIA, postData, config);

      const responseData = response.data.sku ? response.data.sku : response.data;

      dispatch(setResponseValues(SUCCESS, responseData, null, "skuForm"));

      /** Clear error from common reducer if any */
      dispatch(
        saveResponseMessage(reqParam.isProductImage ? productResponseMessageKey : skuResponseMessageKey, {
          hasErrors: false,
        })
      );
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(
          saveResponseMessage(
            reqParam.isProductImage ? productResponseMessageKey : skuResponseMessageKey,
            error.response.data
          )
        );
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update SKU or Product Media by admin */
export const markAdminSkuMedia = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(reqParam.isProductImage ? productResponseMessageKey : skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;

      const defaultParam = {
        userId,
        userType,
      };
      const postData = { ...defaultParam, ...reqParam };

      const URL = reqParam?.isProductImage ? ADMIN_UPDATE_PRODUCT_MEDIA : ADMIN_UPDATE_MEDIA;

      const response = await axios.post(URL, postData, config);
      const responseData = response.data.sku ? response.data.sku : response.data;

      reqParam && reqParam.isProductImage
        ? dispatch(setResponseValues(SUCCESS, response.data, null, "productInfo"))
        : dispatch(setResponseValues(SUCCESS, responseData, null, "skuForm"));

      /** Clear error from common reducer if any */
      dispatch(
        saveResponseMessage(reqParam.isProductImage ? productResponseMessageKey : skuResponseMessageKey, {
          hasErrors: false,
        })
      );
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(
          saveResponseMessage(
            reqParam.isProductImage ? productResponseMessageKey : skuResponseMessageKey,
            error.response.data
          )
        );
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update SKU Price */
export const updateSkuPrice = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const { userType, userId } = getState().auth;
      const defaultParam = { userId, userType };
      const postData = { ...defaultParam, ...reqParam };

      const response = await axios.post(UPDATE_SKU_PRICE, postData, config);
      const responseData = response.data.sku ? response.data.sku : response.data;

      dispatch(setResponseValues(SUCCESS, responseData, null, "skuForm"));

      /** Clear error from common reducer if any */
      dispatch(saveResponseMessage(skuResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(skuResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update SKU sample Info */
export const updateSkuSample = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;
      const defaultParam = { userId, userType };
      const postData = { ...reqParam, ...defaultParam };

      const response = await axios.post(SUPPLIER_UPDATE_SKU_SAMPLE, postData, config);

      const responseData = response.data.sku ? response.data.sku : response.data;

      dispatch(setResponseValues(SUCCESS, responseData, null, "skuForm"));
      /** Clear error from common reducer if any */
      dispatch(saveResponseMessage(skuResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(skuResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update SKU Inventory */
export const updateSkuInventory = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;
      const defaultParam = { userId, userType };
      const postData = { ...reqParam, ...defaultParam };

      const response = await axios.post(UPDATE_SKU_INVENTORY, postData, config);

      const responseData = response.data.sku ? response.data.sku : response.data;

      dispatch(setResponseValues(SUCCESS, responseData, null, "skuForm"));
      /** Clear error from common reducer if any */
      dispatch(saveResponseMessage(skuResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(skuResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Upload Images of SKU */
export const uploadImages = (file) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    try {
      const fileId = await dispatch(uploadMedia(file, {}));
      dispatch(storeMediaList(fileId));
      dispatch(storeMediaObject({ [file.name]: fileId }));
    } catch (error) {
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/* Upload All images */
export const uploadAllImages = (allFiles) => {
  return async (dispatch) => {
    dispatch(startUILoading());

    try {
      const promises = [];
      Object.keys(allFiles).forEach((key) => {
        if (!allFiles[key].uploaded) {
          promises.push(dispatch(uploadBaseImages(allFiles[key].cropped)));
        }
      });
      return await Promise.all([...promises]);
    } catch (error) {
      console.log("promise uploading error");
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Upload Base 64 Images */
export const uploadBaseImages = (file) => {
  return async (dispatch) => {
    try {
      return await dispatch(uploadBase64Media(file, {}));
    } catch (error) {
    } finally {
    }
  };
};

/** Fetch Similar SKU List */
export const fetchSimilarSku = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(resetObjectValues("similarSkus"));
    dispatch(clearMessage(skuResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;

      const defaultParam = { userId, userType };
      const postData = { ...reqParam, ...defaultParam };

      let URL = userType === "SUPPLIER" ? SUPPLIER_SIMILAR_SKU : ADMIN_SIMILAR_SKU;
      URL = URL + reqParam.skuId;
      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "similarSkus"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      // if (error?.response?.data) {
      //   dispatch(
      //     saveResponseMessage(skuResponseMessageKey, error.response.data)
      //   );
      // }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Search Supplier sku list for attachment in order requests */
export const searchSupplierSku = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;

      const defaultParam = {
        userId,
        userType,
      };
      const postData = { ...defaultParam, ...reqParam };

      const response = await axios.post(SEARCH_SUPPLIER_SKUS, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "skuList"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Save categories list in redux store */
export const storeCategories = (data) => {
  return {
    type: SAVE_CATEGORIES,
    data: data.data.categories,
  };
};
