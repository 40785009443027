import React from "react";
import { Row, Col, FormGroup, Card, CardBody, Button, Table, ButtonToolbar, Label, Form, Input } from "reactstrap";

import { connect } from "react-redux";
import {
  getCouponList,
  addNewCoupon,
  fetchCoupon,
  updateCoupon,
  addUpdateCouponMedia,
  activateDeactivateCoupon,
} from "../../datautils/actions/adminActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";

import { ADMIN_COUPON_VIEW } from "../../utils/constants/clientUrls";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { sortIcon } from "../../utils/constants/icons";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import { PER_PAGE, platformTypes, couponTypes } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import Select from "react-select";
import Dropzone from "../../components/Dropzone/Dropzone";
import { uploadImages } from "../../datautils/actions/skuActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { resetResponseValues, resetObjectValues } from "../../datautils/actions/responseHandlerActions";
import ImageZoom from "../../components/ImageZoom";

class Coupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "1",
      currentFilter: "status",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "name",
      ascending: false,
      toggle: false,

      couponType: "PROMOTION",
      platformType: "MOBILE",
      files: [],
      code: "",
      description: "",
      startDateTime: "",
      endDateTime: "",
      id: 0,
      existingImages: {},
    };
  }
  componentDidMount() {
    this.searchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { responseVal } = this.props;

    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchData();
    }

    const couponData = responseVal.dObjectData?.coupon?.data?.coupon;

    if (couponData && prevState.lastUpdated !== couponData.updatedOn) {
      const data = couponData;

      let start = new Date(data.startDateTime);
      let end = new Date(data.endDateTime);

      this.setState({
        id: data.id,

        code: data.code,
        status: data.status,
        couponType: data.couponType,
        platformType: data.platformType,
        startDateTime: start,
        endDateTime: end,
        description: data.description,
        existingImages: data.medias,
        lastUpdated: data.updatedOn,
      });
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.searchData()
      );
    }
  };

  toggleBox = () => {
    this.setState({ toggle: !this.state.toggle, isUpdated: false });
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchData()
    );
  };

  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.searchData()
    );
  };
  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  enableDisableImage = (key, status) => (e) => {
    const reqParam = {
      [key]: { fileId: key, status },
    };

    this.props.addUpdateCouponMedia({
      campaign: {
        id: this.state.id,
        code: this.state.code,
        status,
        medias: reqParam,
      },
    });

    this.setState({ isUpdated: true });
  };

  enableDisableCoupon = (status) => (e) => {
    this.props.activateDeactivateCoupon({
      coupon: {
        id: this.state.id,
        code: this.state.code,
        status,
      },
    });

    this.setState({ isUpdated: true });
  };

  searchData = () => {
    const { globalSearchKeyword, currentFilter, currentTab, sortField, ascending, pageNumber, pageSize } = this.state;

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [
        {
          key: currentFilter,
          operation: "EQUAL_TO",
          value: currentTab,
        },
      ],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.setState({
      id: 0,
      toggle: false,
      isUpdated: false,
      existingImages: {},
    });
    this.props.resetObjectValues("coupon");
    this.props.getCouponList(reqData);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  formatDate = (date) => {
    const now = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const first = now.substr(0, now.length - 3);
    const last = now.substr(-3);
    return first + last.replace(":", "");
  };

  handleChangeStartDate = (date) => {
    this.setState({ startDateTime: date });
  };

  handleChangeEndDate = (date) => {
    this.setState({ endDateTime: date });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState((prevState) => ({
      ...prevState,
      [obj]: opt.value,
    }));
  };

  createBox = (i) => (e) => {
    this.props.resetResponseValues();
    this.props.resetObjectValues("coupon");

    this.setState({
      toggle: i === 1 ? false : true,

      couponType: "PROMOTION",
      platformType: "MOBILE",
      files: [],
      code: "",
      description: "",
      startDateTime: "",
      endDateTime: "",
      id: 0,
      isUpdated: false,
      existingImages: {},
    });

    if (i === 1) {
      this.searchData();
    }
  };
  handleChangePage = (id, code) => (e) => {
    this.props.history.push(ADMIN_COUPON_VIEW + id + "/" + code);
  };

  handleEditPage = (input) => (e) => {
    console.log("Edit mode", input);
    this.setState({ toggle: true, isUpdated: false }, () => this.props.fetchCoupon({ id: input }));

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  onDrop = (acceptedFiles) => {
    this.setState({ files: [...this.state.files, ...acceptedFiles] });

    acceptedFiles.map((acceptedFile) => {
      return this.props.uploadImages(acceptedFile);
    });
  };

  filterData = (e) => {
    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
        currentFilter: e.target.dataset.filter,
      },
      () => this.searchData()
    );
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.couponList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  addNewCoupon = () => {
    const { responseVal } = this.props;

    const { couponType, platformType, code, description, startDateTime, endDateTime } = this.state;

    let mediasToPost = {};
    if (responseVal.media && responseVal.media.length > 0) {
      responseVal.media.forEach((fileId) => {
        mediasToPost[fileId] = { fileId: fileId, status: "ACTIVE" };
      });
    }

    const reqParam = {
      couponType,
      platformType,
      code,
      description,
      status: "INACTIVE",
      startDateTime: this.formatDate(startDateTime),
      endDateTime: this.formatDate(endDateTime),
      medias: mediasToPost,
    };
    this.props.addNewCoupon({ coupon: reqParam });
    this.setState({ isUpdated: true });
  };

  updateCoupon = (e) => {
    e.preventDefault();
    const { responseVal } = this.props;

    const { id, couponType, platformType, code, description, status, startDateTime, endDateTime } = this.state;

    let mediasToPost = {};
    if (responseVal.media && responseVal.media.length > 0) {
      responseVal.media.forEach((fileId) => {
        mediasToPost[fileId] = { fileId: fileId };
      });
    }

    const reqParam = {
      id,
      couponType,
      platformType,
      code,
      description,
      status,
      startDateTime: this.formatDate(startDateTime),
      endDateTime: this.formatDate(endDateTime),
      medias: mediasToPost,
    };

    this.props.updateCoupon({ coupon: reqParam });
    this.setState({ isUpdated: true });
  };

  render() {
    const { responseVal, isLoading } = this.props;
    const {
      isUpdated,
      globalSearchKeyword,
      platformType,
      couponType,
      code,
      description,
      files,
      startDateTime,
      endDateTime,
      currentTab,
      existingImages,
      ascending,
      id,
      toggle,
      status,
      pageNumber,
      pageSize,
    } = this.state;

    const dataLoaded =
      responseVal.dObjectData.couponList &&
      responseVal.dObjectData.couponList.data &&
      responseVal.dObjectData.couponList.data.coupons
        ? responseVal.dObjectData.couponList.data.coupons
        : false;

    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}

              {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="8">
                  {toggle ? (
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb pt-0">
                        <li className="breadcrumb-item">
                          <span onClick={this.createBox(1)}>&laquo; Back to Coupons</span>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          {code ? code : "Create Coupon"}
                        </li>
                      </ol>
                    </nav>
                  ) : (
                    <ButtonToolbar>
                      {" "}
                      <Button
                        size="sm"
                        value="1"
                        data-filter="status"
                        onClick={this.filterData}
                        color={currentTab === "1" ? "primary" : ""}
                      >
                        ACTIVE
                      </Button>
                      <Button
                        size="sm"
                        value="0"
                        data-filter="status"
                        onClick={this.filterData}
                        color={currentTab === "0" ? "primary" : ""}
                      >
                        INACTIVE
                      </Button>
                      <Button
                        size="sm"
                        value=""
                        data-filter="status"
                        onClick={this.filterData}
                        color={currentTab === "" ? "primary" : ""}
                      >
                        ALL
                      </Button>
                    </ButtonToolbar>
                  )}
                </Col>
                <Col xs="4">
                  {!id && !toggle ? (
                    <Button size="sm" color="success" className="pull-right" onClick={this.createBox(0)}>
                      Create New Coupon
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {toggle ? (
              <Col xs="12">
                <div className="bg-white p-4 card-default mb-4">
                  <Row>
                    <span className="close-box" onClick={this.createBox(1)}>
                      <i className="now-ui-icons ui-1_simple-remove" />
                    </span>
                    <Col md="7">
                      <Form autoComplete="off" className="form--horizontal">
                        {id ? (
                          <FormGroup row>
                            <Label for="title" sm={4}>
                              Coupon Status
                            </Label>
                            <Col sm={8}>
                              <h5>
                                {status === "ACTIVE" ? (
                                  <Button
                                    size="sm"
                                    className="mt-0 mb-0"
                                    color="success"
                                    onClick={this.enableDisableCoupon("INACTIVE")}
                                  >
                                    Deactivate Coupon
                                  </Button>
                                ) : (
                                  <Button
                                    size="sm"
                                    className="mt-0 mb-0"
                                    color="danger"
                                    onClick={this.enableDisableCoupon("ACTIVE")}
                                  >
                                    Activate Coupon
                                  </Button>
                                )}
                              </h5>
                            </Col>
                          </FormGroup>
                        ) : (
                          <FormGroup row>&nbsp;</FormGroup>
                        )}

                        <FormGroup row>
                          <Label for="variant" sm={4}>
                            Coupon Platform <span className="star">*</span>
                          </Label>
                          <Col sm={8}>
                            <Select
                              isSearchable={false}
                              name="platformType"
                              options={platformTypes}
                              value={platformTypes.filter((option) => option.value === platformType)}
                              onChange={(opt) => this.handleChangeSelect("platformType", opt)}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="variant" sm={4}>
                            Coupon Type <span className="star">*</span>
                          </Label>
                          <Col sm={8}>
                            <Select
                              isSearchable={false}
                              name="couponType"
                              options={couponTypes}
                              value={couponTypes.filter((option) => option.value === couponType)}
                              onChange={(opt) => this.handleChangeSelect("couponType", opt)}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="title" sm={4}>
                            Coupon Code<span className="star">*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              name="code"
                              disabled={id}
                              placeholder="Coupon code"
                              value={code || ""}
                              onChange={this.handleChange}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="title" sm={4}>
                            Start Date<span className="star">*</span>
                          </Label>
                          <Col sm={8}>
                            <DatePicker
                              className="form-control"
                              placeholderText="Click to select start date"
                              showTimeSelect
                              selected={startDateTime}
                              onChange={this.handleChangeStartDate}
                              timeFormat="HH:mm"
                              timeIntervals={60}
                              dateFormat="yyyy-MM-dd HH:mm:ss"
                              timeCaption="Time"
                              minDate={new Date()}
                              withPortal
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="title" sm={4}>
                            End Date<span className="star">*</span>
                          </Label>
                          <Col sm={8}>
                            <DatePicker
                              className="form-control"
                              placeholderText="Click to select end date"
                              showTimeSelect
                              selected={endDateTime}
                              onChange={this.handleChangeEndDate}
                              timeFormat="HH:mm"
                              timeIntervals={60}
                              dateFormat="yyyy-MM-dd HH:mm:ss"
                              timeCaption="Time"
                              minDate={startDateTime}
                              withPortal
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="title" sm={4}>
                            Description
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="textarea"
                              name="description"
                              placeholder="Coupon description"
                              value={description || ""}
                              onChange={this.handleChange}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="button" sm={4} />
                          <Col sm={8}>
                            {id ? (
                              <Button
                                size="md"
                                color="primary"
                                onClick={this.updateCoupon}
                                disabled={!code || !startDateTime || !endDateTime}
                              >
                                Update Coupon
                              </Button>
                            ) : (
                              <Button
                                size="md"
                                color="primary"
                                onClick={this.addNewCoupon}
                                disabled={!code || !startDateTime || !endDateTime}
                              >
                                Create New Coupon
                              </Button>
                            )}
                          </Col>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col md="5 d-none">
                      {" "}
                      <Dropzone onFilesAdded={(opt) => this.onDrop(opt)} />
                      {files ? (
                        <Row className="mt-2 equal">
                          {files.length > 0 &&
                            files.map((acceptedFile) => (
                              <Col sm="6" className="" key={acceptedFile.name}>
                                <img
                                  alt={acceptedFile.name}
                                  src={URL.createObjectURL(acceptedFile)}
                                  className="img-thumbnail rounded"
                                />
                                <p className="text-center">{acceptedFile.name}</p>
                              </Col>
                            ))}
                        </Row>
                      ) : null}
                      {existingImages ? (
                        <Row className="mt-2 equal">
                          {Object.keys(existingImages).map((index) => {
                            return (
                              <Col md="4" key={index}>
                                <div className="bg-white p-2 mb-3">
                                  {" "}
                                  <ImageZoom
                                    src={getMediaPath(existingImages[index].fileId, existingImages[index].fileExt)}
                                    alt=""
                                  />
                                  {existingImages[index].status === "ACTIVE" ? (
                                    <Button
                                      size="sm"
                                      className="btn-block"
                                      color="success"
                                      onClick={this.enableDisableImage(index, "INACTIVE")}
                                    >
                                      Deactivate
                                    </Button>
                                  ) : (
                                    <Button
                                      size="sm"
                                      className="btn-block"
                                      color="danger"
                                      onClick={this.enableDisableImage(index, "ACTIVE")}
                                    >
                                      Activate
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              </Col>
            ) : null}

            <Col xs={12}>
              <Card>
                <CardBody className="listings">
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <div className="has-search">
                          <div className="input-group">
                            <input
                              type="search"
                              value={globalSearchKeyword}
                              name="globalSearchKeyword"
                              className="form-control"
                              placeholder="Search Coupons..."
                              onChange={this.searchHandler}
                              onKeyDown={this.handleKeyDown}
                              style={{ background: "#f5f5f5" }}
                              autoComplete="off"
                            />
                            <div className="input-group-append">
                              <Button color="primary" onClick={this.searchButton}>
                                <i className="now-ui-icons ui-1_zoom-bold" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="8">{dataLoaded ? this.renderPagination() : ""}</Col>
                  </Row>
                  <Table responsive className="table-striped table-bordered">
                    <thead>
                      <tr className="text-primary">
                        <th className="text-center">#</th>
                        <th onClick={this.sortButton("platformType", ascending ? false : true)}>
                          Platform Type
                          <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th onClick={this.sortButton("couponType", ascending ? false : true)}>
                          Coupon Type
                          <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th onClick={this.sortButton("code", ascending ? false : true)}>
                          Coupon Code
                          <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th>Status</th>
                        <th onClick={this.sortButton("startDateTime", ascending ? false : true)}>
                          Start Date
                          <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th onClick={this.sortButton("endDateTime", ascending ? false : true)}>
                          End Date
                          <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th>Description</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataLoaded ? (
                        dataLoaded.length ? (
                          dataLoaded.map((row, index) => {
                            const cname =
                              row.status === "ACTIVE"
                                ? "success"
                                : row.status === "INACTIVE"
                                ? "danger"
                                : row.status === "ONHOLD"
                                ? "warning"
                                : "primary";
                            return (
                              <tr key={index}>
                                <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>
                                <td>{row.platformType}</td>
                                <td>{row.couponType}</td>
                                <td onClick={this.handleChangePage(row.id, row.code)}>{row.code}</td>
                                <td>{row.status}</td>
                                <td>{moment(row.startDateTime).format("MMM DD YYYY, hh:mm a")}</td>
                                <td>{moment(row.endDateTime).format("MMM DD YYYY, hh:mm a")}</td>
                                <td>{row.description}</td>
                                <td className="text-right">
                                  <Button
                                    className={`btn-simple btn-light btn-primary`}
                                    size="xs"
                                    onClick={this.handleEditPage(row.id)}
                                  >
                                    Edit
                                  </Button>{" "}
                                  <Button
                                    className={`btn-simple btn-light btn-${cname}`}
                                    size="xs"
                                    onClick={this.handleChangePage(row.id, row.code)}
                                  >
                                    View
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="9" className="no-data">
                              No data available
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan="9" className="no-data" />
                        </tr>
                      )}
                    </tbody>
                  </Table>{" "}
                </CardBody>
              </Card>

              {isLoading ? <div className="loader" /> : null}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    resetObjectValues: (object) => dispatch(resetObjectValues(object)),
    getCouponList: (reqData) => dispatch(getCouponList(reqData)),
    uploadImages: (acceptedFile) => dispatch(uploadImages(acceptedFile)),
    addNewCoupon: (reqData) => dispatch(addNewCoupon(reqData)),
    fetchCoupon: (reqData) => dispatch(fetchCoupon(reqData)),
    updateCoupon: (reqData) => dispatch(updateCoupon(reqData)),
    addUpdateCouponMedia: (reqParam) => dispatch(addUpdateCouponMedia(reqParam)),
    activateDeactivateCoupon: (reqData) => dispatch(activateDeactivateCoupon(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);
