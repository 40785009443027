import Dashboard from "../../views/Supplier/Dashboard";
import Profile from "../../views/Supplier/Profile";
import Password from "../../views/Supplier/Password";
import Kyc from "../../views/Supplier/Profile/Kyc";
import Address from "../../views/Supplier/Profile/Address";
import Addresses from "../../views/Supplier/Profile/Addresses";
import AddSku from "../../views/Supplier/AddSku";
import SkuList from "../../views/Supplier/SkuList";
import UpdateSku from "../../views/Supplier/UpdateSku";
import Requests from "../../views/Supplier/RequestList";
import Request from "../../views/Supplier/Request";
import Orders from "../../views/Admin/OrderList";
import Order from "../../views/Supplier/Order";
import SampleList from "../../views/Supplier/SampleList";
import Sample from "../../views/Supplier/Sample";
import CustomOfferList from "../../views/Supplier/CustomOfferList";
import CustomOffer from "../../views/Supplier/CustomOffer";
import BillBookList from "../../views/Supplier/BillBookList";
import BillBookDetail from "../../views/Supplier/BillBookDetail";
import SupplierUserList from "../../views/Supplier/SupplierUserList";
import SupplierUser from "../../views/Supplier/SupplierUser";
import UserActivity from "../../views/Admin/UserActivity";

import OrderRequestList from "../../views/Supplier/OrderRequestList";
import OrderRequest from "../../views/Supplier/OrderRequest";

import { SUPPLIER_BASE } from "../../utils/constants/clientUrls.js";

let dashRoutes = [
  {
    path: SUPPLIER_BASE,
    name: "Dashboard",
    icon: "design_app",
    component: Dashboard,
    componentName: "supplierDashboard",
  },
  {
    path: SUPPLIER_BASE + "/profile",
    name: "Profile",
    icon: "design_app",
    component: Profile,
    componentName: "supplierProfile",
  },
  {
    path: SUPPLIER_BASE + "/profile/password",
    name: "Password",
    icon: "design_app",
    component: Password,
    componentName: "supplierPassword",
  },
  {
    path: SUPPLIER_BASE + "/profile/kyc",
    name: "Kyc",
    icon: "design_app",
    component: Kyc,
    componentName: "supplierKyc",
  },
  {
    path: SUPPLIER_BASE + "/profile/address",
    name: "Address",
    icon: "design_app",
    component: Addresses,
    componentName: "supplierAddress",
  },
  // {
  //   path: SUPPLIER_BASE + "/profile/addresses",
  //   name: "Addresses",
  //   icon: "design_app",
  //   component: Addresses,
  //   componentName: "supplierAddress",
  // },
  {
    path: SUPPLIER_BASE + "/profile/contact",
    name: "Contact",
    icon: "design_app",
    component: Address,
    componentName: "supplierAddress",
  },
  {
    path: SUPPLIER_BASE + "/password",
    name: "Password",
    icon: "design_app",
    component: Password,
    componentName: "supplierPassword",
  },
  {
    path: SUPPLIER_BASE + "/sku/new",
    name: "Add Sku",
    icon: "design_app",
    component: AddSku,
    componentName: "supplierAddSku",
  },
  {
    path: SUPPLIER_BASE + "/skus",
    name: "Sku List",
    icon: "design_app",
    component: SkuList,
    componentName: "supplierSkuList",
  },
  {
    path: SUPPLIER_BASE + "/sku/:id",
    name: "Update Sku",
    icon: "design_app",
    component: UpdateSku,
    componentName: "supplierUpdateSku",
  },
  {
    path: SUPPLIER_BASE + "/requests",
    name: "Tickets",
    icon: "design_app",
    component: Requests,
    componentName: "supplierRequests",
  },
  {
    path: SUPPLIER_BASE + "/request/:id",
    name: "Ticket",
    icon: "design_app",
    component: Request,
    componentName: "supplierRequests",
  },
  {
    path: SUPPLIER_BASE + "/orders",
    name: "All Orders",
    icon: "design_app",
    component: Orders,
    componentName: "supplierOrders",
  },
  {
    path: SUPPLIER_BASE + "/order/:id",
    name: "Order",
    icon: "design_app",
    component: Order,
    componentName: "supplierOrders",
  },
  {
    path: SUPPLIER_BASE + "/samples",
    name: "Sample Requests",
    icon: "design_app",
    component: SampleList,
    componentName: "supplierSamples",
  },
  {
    path: SUPPLIER_BASE + "/sample/:id",
    name: "Sample Request",
    icon: "design_app",
    component: Sample,
    componentName: "supplierSamples",
  },
  {
    path: SUPPLIER_BASE + "/customoffers",
    name: "Bargain Requests",
    icon: "design_app",
    component: CustomOfferList,
    componentName: "supplierCustomOffers",
  },
  {
    path: SUPPLIER_BASE + "/customoffer/:id",
    name: "Bargain Request",
    icon: "design_app",
    component: CustomOffer,
    componentName: "supplierCustomOffers",
  },
  {
    path: SUPPLIER_BASE + "/billbook",
    name: "Bill Book",
    icon: "design_app",
    component: BillBookList,
    componentName: "supplierBillBook",
  },
  {
    path: SUPPLIER_BASE + "/billbook/:id",
    name: "Bill Detail",
    icon: "design_app",
    component: BillBookDetail,
    componentName: "supplierBillBook",
  },
  {
    path: SUPPLIER_BASE + "/users",
    name: "User List",
    icon: "design_app",
    component: SupplierUserList,
    componentName: "supplierUserList",
  },
  {
    path: SUPPLIER_BASE + "/users/:id",
    name: "User Detail",
    icon: "design_app",
    component: SupplierUser,
    componentName: "supplierUserList",
  },
  {
    path: SUPPLIER_BASE + "/activity/:id",
    name: "User Activity",
    icon: "design_app",
    component: UserActivity,
    componentName: "supplierUserList",
  },
  {
    path: SUPPLIER_BASE + "/order-requests",
    name: "Order Requests",
    icon: "design_app",
    component: OrderRequestList,
    componentName: "supplierOrderRequestList",
  },
  {
    path: SUPPLIER_BASE + "/order-request/:id",
    name: "User Activity",
    icon: "design_app",
    component: OrderRequest,
    componentName: "supplierOrderRequestList",
  },
];
export default dashRoutes;
