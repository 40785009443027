import React from "react";
import { store } from "../../config/store/index";
import { DEVICE_TYPE } from "../../utils/constants/index";
import { routeMap } from "../../utils/constants/roles";
import { MEDIA_CONVERTGET_API, MEDIA_GET_API } from "../../utils/constants/apiUrls";
import { routeAllowed } from "../../utils/constants/clientUrls";

import moment from "moment";
import { format } from "date-fns";

export const isUserLoggedIn = () => {
  const token = store.getState().auth.token;
  //const expiryDate = store.getState().auth.expiryDate;
  //const currentDate = new Date();
  //const tokenExpiryDate = new Date(expiryDate);

  //console.log("islogin", token && token.length > 10);
  return token && token.length > 10;
};

export const isValidRoute = (componentName, userType, userRole) => {
  const currentComponent = routeMap[componentName];
  let retVal = false;

  if (currentComponent) {
    currentComponent.forEach(function (arrayItem) {
      if (arrayItem.userType === userType && arrayItem.userRole === userRole) {
        retVal = true;
      }
    });
  }
  return retVal;
};

export const getFilteredAuthData = (authData) => {
  const filtered = {
    ...authData,
    deviceType: DEVICE_TYPE,
    token: undefined,
    refreshToken: undefined,
    expiryDate: undefined,
  };

  return filtered;
  //return Object.keys(filtered).forEach(key => filtered[key] === undefined && delete filtered[key])
};

export const getMediaPath = (fileId, fileExt) => {
  const mediaPath = MEDIA_GET_API + fileId + "." + fileExt;
  return mediaPath;
};

export const getMediaUrl = (obj) => {
  let URL = null;
  if (obj.fileId && obj.fileExt) {
    if (obj.height || obj.width) {
      URL = `${MEDIA_CONVERTGET_API}${obj.fileId}.${obj.fileExt}?width=${obj.width}&height=${obj.height}`;
    } else {
      URL = MEDIA_GET_API + obj.fileId + "." + obj.fileExt;
    }
  }

  return URL;
};

export const validTextColour = (stringToTest) => {
  //Alter the following conditions according to your need.
  if (stringToTest === "") {
    return false;
  }
  if (stringToTest === "inherit") {
    return false;
  }
  if (stringToTest === "transparent") {
    return false;
  }

  let image = document.createElement("img");
  image.style.color = "rgb(0, 0, 0)";
  image.style.color = stringToTest;
  if (image.style.color !== "rgb(0, 0, 0)") {
    return true;
  }
  image.style.color = "rgb(255, 255, 255)";
  image.style.color = stringToTest;
  return image.style.color !== "rgb(255, 255, 255)";
};

export const formatOptionLabel = ({ label, color }) => (
  <div className="format-label">
    <div style={{ flex: 1 }}>{label}</div>
    <div className="color-bar" style={{ background: "#" + color }}></div>
  </div>
);

export const getUrlParams = (search) => {
  const params = {};
  const hashes = search?.slice(search.indexOf("?") + 1).split("&");
  hashes?.map((hash) => {
    const [key, val] = hash.split("=");
    return (params[key] = decodeURIComponent(val));
  });
  return params;
};

export const formatDate = (date) => {
  const now = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const first = now.substr(0, now.length - 3);
  const last = now.substr(-3);
  return first + last.replace(":", "");
};

export const isRouteAllowed = (urlRoute) => {
  const { userType, userRole } = store.getState().auth;

  if (userType === "ADMIN") {
    return true;
  }

  const string = userType + "_" + userRole;

  const allowedRoute = routeAllowed[urlRoute];
  return allowedRoute?.includes(string) ? true : false;
};

export const formatDateTime = (date) => {
  return date ? format(new Date(date), "E, dd MMM’ yy, hh:mm a") : "";
};

// document.getElementById("testField").addEventListener("input", function() {
//     document.getElementById("result").textContent = validTextColour(this.value);
// });
