import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import { connect } from "react-redux";
import { getAddress, updateAddress } from "../../../datautils/actions/userActions";

import { getStates, getCities } from "../../../datautils/actions/locationActions";
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import ErrorBlock from "../../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../../components/SuccessBlock/SuccessBlock";
import AddressBlock from "../../../components/Profile/AddressBlock";
import ImageUpload from "../../../components/CustomUpload/ImageUpload";
import TooltipItem from "../../../components/Tooltip/Tooltip";
import { resetResponseValues } from "../../../datautils/actions/responseHandlerActions";
import GoBack from "../../../components/GoBack/GoBack";
import Select from "react-select";

class Addresses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMode: false,
      apiDataFetched: false,
      apiData: null,
      modal: false,
      modal2: false,
      selectedBlock: null,
      addressUpdated: false,
      documentId: "",

      title: "",
      firstName: "",
      lastName: "",
      dob: "",
      email: "",
      gender: "",
      designation: "",
      phone: "",
      line1Address: "",
      line2Address: "",
      localityName: "",
      stateId: "",
      cityId: "",
      landmark: "",
      pincode: "",

      isUpdated: false,
      currentModule: "address",
      selectedAddress: null,

      file: null,
      gstFileId: "",
      gstFileExtension: "",
      gstNumber: "",
      gstAdditionalInfo: "",
      gstNumberState: "",

      notes: "",
    };

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
  }
  toggle2() {
    this.setState({
      modal2: !this.state.modal2,
      notes: "",
      addressUpdated: false,
    });
  }

  toggle() {
    const { selectedAddress, modal, viewMode } = this.state;

    let currentDoc = selectedAddress;

    if (currentDoc) {
      this.setState(
        (prevState) => ({
          addressUpdated: false,
          modal: !prevState.modal,
          documentId: currentDoc.id,
          line1Address: currentDoc.line1Address,
          line2Address: currentDoc.line2Address,
          localityName: currentDoc.localityName,
          cityId: currentDoc.cityId,
          landmark: currentDoc.landmark,
          pincode: currentDoc.pincode,
          stateId: currentDoc.stateId,
          gstNumber: currentDoc.gstNumber,
          gstAdditionalInfo: currentDoc.gstAdditionalInfo,
          gstFileId: currentDoc.gstFileId,
          gstFileExtension: currentDoc.gstFileExtension,
        }),
        () => (!modal && !viewMode ? currentDoc.stateId && this.props.getCities(currentDoc.stateId) : null)
      );
      this.setState({ isUpdated: false });
    } else {
      this.setState((prevState) => ({
        modal: !prevState.modal,
        addressUpdated: false,
        line1Address: "",
        line2Address: "",
        localityName: "",
        stateId: "",
        cityId: "",
        landmark: "",
        pincode: "",
        documentId: "",
        gstNumber: "",
        gstAdditionalInfo: "",
        gstFileId: "",
        gstFileExtension: "",
      }));
    }
    this.props.resetResponseValues();
  }
  handleChangeNotes = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleChangeSelect = (obj, opt) => {
    console.log(obj, opt);
    if (obj === "stateId") {
      this.setState({
        stateId: opt.id,
        cityId: "",
        isUpdated: false,
        addressUpdated: false,
      });
      this.props.getCities(opt.id);
    } else if (obj === "cityId") {
      this.setState({
        cityId: opt.id,
      });
    } else {
      this.setState({ [obj]: opt.value });
    }
  };

  handleChangeStartDate = (date) => {
    this.setState({ dob: date });
  };

  setValue = (selectedAddress) => {
    this.setState(
      {
        selectedAddress,
        selectedBlock: selectedAddress.addressType,
        viewMode: false,
      },
      () => this.toggle()
    );
  };

  setDelete = (selectedAddress) => {
    console.log("SEL", selectedAddress);
    this.setState(
      {
        selectedAddress,
      },
      () => this.toggle2()
    );
  };

  setValueView = (selectedAddress) => {
    this.setState(
      {
        selectedAddress,
        selectedBlock: selectedAddress.addressType,
        viewMode: true,
      },
      () => this.toggle()
    );
  };

  componentDidMount() {
    this.props.resetResponseValues();
    const path = this.props.location.pathname.split("/");
    const currentModule = path[path.length - 1];
    this.setState({ currentModule });

    const authData = {
      userId: this.props.userId,
    };

    this.props.getAddress(authData);
    this.props.getStates();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { profileFetched, addressUpdated, modal, modal2 } = this.state;
    const { responseVal, profile } = this.props;

    if (!profile.newSupplier && !profileFetched) {
      this.setState({
        profileData: profile,
        profileFetched: true,
      });
    }

    if (responseVal.status === "success" && addressUpdated && (modal || modal2)) {
      this.setState({ modal: false, modal2: false });
    }
  }

  handleChange = (input) => (event) => {
    if (input === "phone") {
      let phoneState = this.state.phoneState;
      if (isNaN(event.target.value)) {
        phoneState = "Enter only numeric as phone number ";
      } else {
        phoneState = "";
      }
      this.setState({ [event.target.name]: event.target.value, phoneState });
    } else if (input === "documentIdentitierNo") {
      let documentIdentitierNo = event.target.value;
      let documentIdentitierNoState = this.state.documentIdentitierNoState;
      if (documentIdentitierNo.length > 1) {
        documentIdentitierNoState = "is-valid";
      } else {
        documentIdentitierNoState = "is-invalid";
      }
      this.setState({ documentIdentitierNo, documentIdentitierNoState });
    } else if (input === "documentAdditionalInfo") {
      let documentAdditionalInfo = event.target.value;
      let documentAdditionalInfoState = this.state.documentAdditionalInfoState;

      if (documentAdditionalInfo.length > 5) {
        documentAdditionalInfoState = "is-valid";
      } else {
        documentAdditionalInfoState = "is-invalid";
      }
      this.setState({ documentAdditionalInfo, documentAdditionalInfoState });
    } else if (input === "state") {
      this.setState({
        stateId: event.target.value,
        cityId: "",
        isUpdated: false,
        addressUpdated: false,
      });
      this.props.getCities(event.target.value);
    } else if (input === "pincode") {
      this.setState({ [event.target.name]: event.target.value.replace(/\D/, "") });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }

    //console.log(this.state);
  };

  deleteAddress = (e) => {
    e.preventDefault();

    const { selectedAddress } = this.state;

    const authData = {
      ...selectedAddress,

      actionType: "DELETE",
      userId: this.props.userId,
      userType: "SUPPLIER",
    };

    this.props.updateAddress(authData, null);
    this.setState({ addressUpdated: true, isUpdated: true });
  };

  updateAddress = (e) => {
    e.preventDefault();

    const {
      selectedBlock,
      documentId,
      line1Address,
      line2Address,
      localityName,
      cityId,
      landmark,
      pincode,
      file,

      gstNumber,
      gstAdditionalInfo,
      gstFileId,
      gstFileExtension,
    } = this.state;

    const authData = {
      addressType: selectedBlock,
      id: documentId,
      line1Address,
      line2Address,
      localityName,
      cityId,
      landmark,
      pincode,
      userId: this.props.userId,
      userType: "SUPPLIER",

      gstNumber,
      gstAdditionalInfo,
      gstFileId,
      gstFileExtension,
    };

    this.props.updateAddress(authData, file);
    this.setState({ addressUpdated: true, isUpdated: true });
  };

  handleUpload = (file) => {
    if (file) {
      this.setState({ file: file, fileState: "is-valid" });
    } else {
      this.setState({ file: null, fileState: "is-invalid" });
    }
  };

  renderModal = () => {
    const {
      viewMode,
      selectedBlock,
      selectedAddress,
      line1Address,
      line1AddressState,
      line2Address,
      line2AddressState,
      localityName,
      localityNameState,
      landmark,
      landmarkState,
      pincode,
      pincodeState,

      stateId,
      cityId,

      gstNumber,
      gstAdditionalInfo,
      gstNumberState,

      gstFileId,
      gstFileExtension,
    } = this.state;

    const { locations } = this.props;

    let isEnabled = true;

    let formData = "";

    if (viewMode) {
      formData = (
        <AddressBlock
          userType={this.props.userType}
          type={selectedBlock}
          content={selectedAddress}
          updateAddress={this.updateAddress}
        />
      );
    } else {
      isEnabled = line1Address && stateId && cityId && pincode;

      const existingImage = gstFileId && gstFileExtension ? gstFileId + "." + gstFileExtension : null;

      formData = (
        <React.Fragment>
          <Form autoComplete="off">
            <FormGroup row>
              <Label for="line1Address" sm={3}>
                Line1 Address<span className="star">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  className={line1AddressState}
                  type="text"
                  name="line1Address"
                  placeholder="Line1 Address"
                  onChange={this.handleChange("line1Address")}
                  value={line1Address}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="line2Address" sm={3}>
                Line2 Address
              </Label>
              <Col sm={9}>
                <Input
                  className={line2AddressState}
                  type="text"
                  name="line2Address"
                  placeholder="Line2 Address"
                  onChange={this.handleChange("line2Address")}
                  value={line2Address}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="localityName" sm={3}>
                Locality Name
              </Label>
              <Col sm={9}>
                <Input
                  className={localityNameState}
                  type="text"
                  name="localityName"
                  placeholder="Locality Name"
                  onChange={this.handleChange("localityName")}
                  value={localityName}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="state" sm={3}>
                State<span className="star">*</span>
              </Label>
              <Col sm={9}>
                <Select
                  placeholder="Select State"
                  isSearchable={true}
                  className="react-select"
                  name="state"
                  options={locations?.states}
                  value={locations?.states?.filter((option) => option.id === stateId)}
                  onChange={(opt) => this.handleChangeSelect("stateId", opt)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="city" sm={3}>
                City <span className="star">*</span>
              </Label>
              <Col sm={9}>
                <Select
                  placeholder="Select City"
                  isSearchable={true}
                  className="react-select"
                  name="state"
                  options={locations?.cities}
                  value={locations?.cities?.filter((option) => option.id === cityId)}
                  onChange={(opt) => this.handleChangeSelect("cityId", opt)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="landmark" sm={3}>
                Landmark
              </Label>
              <Col sm={9}>
                <Input
                  className={landmarkState}
                  type="text"
                  name="landmark"
                  placeholder="Landmark"
                  onChange={this.handleChange("landmark")}
                  value={landmark}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="pincode" sm={3}>
                Pincode<span className="star">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  className={pincodeState}
                  type="text"
                  maxLength="6"
                  name="pincode"
                  placeholder="Pincode"
                  onChange={this.handleChange("pincode")}
                  value={pincode}
                />
              </Col>
            </FormGroup>

            {selectedBlock === "PICKUP_ADDRESS" ? (
              <div
                style={{
                  border: "2px solid #ccc",
                  padding: "10px 30px",
                  marginBottom: "30px",
                }}
              >
                <Row>
                  <Label md={12} className="mb-0">
                    GST No <span className="star">*</span> (Current State GST Number)
                  </Label>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Input
                        className={gstNumberState}
                        type="text"
                        name="gstNumber"
                        placeholder="GST No"
                        onChange={this.handleChange("gstNumber")}
                        value={gstNumber}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Label md={12} className="mb-0">
                    Notes
                  </Label>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="gstAdditionalInfo"
                        placeholder="Enter notes"
                        onChange={this.handleChange("gstAdditionalInfo")}
                        value={gstAdditionalInfo}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <ImageUpload handleUpload={this.handleUpload} existingImage={existingImage} />
              </div>
            ) : null}
          </Form>

          <Row>
            <Col md={3}></Col>
            <Col md={selectedBlock === "PICKUP_ADDRESS" ? 12 : 9}>
              <Button
                color="primary"
                className="btn-block"
                onClick={this.updateAddress}
                disabled={this.props.isLoading || !isEnabled}
              >
                Save Address
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    }

    return (
      <Modal
        backdrop="static"
        centered={true}
        isOpen={this.state.modal}
        toggle={this.toggle}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggle}>
          {this.state.selectedBlock && this.state.selectedBlock.replace(/_/g, " ")}
        </ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">{formData}</div>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  };

  renderModal2 = () => {
    const { notes, modal2, selectedAddress } = this.state;

    return (
      <Modal backdrop="static" centered={true} isOpen={modal2} toggle={this.toggle2}>
        <ModalHeader toggle={this.toggle2}>Delete Address</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    <p>{selectedAddress.fullAddress}</p>

                    <Form autoComplete="off">
                      <FormGroup>
                        <Input
                          type="textarea"
                          placeholder="Add notes/reason"
                          onChange={this.handleChangeNotes("notes")}
                          value={notes}
                        />
                      </FormGroup>
                    </Form>

                    <Button
                      color="danger"
                      className="btn-block"
                      onClick={this.deleteAddress}
                      disabled={this.props.isLoading || notes?.replace(/\s+/g, "")?.length < 1}
                    >
                      Delete Address
                    </Button>
                    <p className="text-danger text-center">Be careful, Address will be deleted permanently</p>
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { profileFetched, isUpdated, currentModule, modal, modal2 } = this.state;
    const { profile, responseVal, userType } = this.props;

    const workflow = profile?.workflow;
    let profileWorkflow = {};

    workflow &&
      Object.keys(workflow.workflowStatusMap).forEach((index) => {
        const object = workflow.workflowStatusMap[index];
        return Object.keys(object).forEach((index2) => {
          const object2 = object[index2];
          object2.subSections &&
            Object.keys(object2.subSections).forEach((index3) => {
              const object3 = object2.subSections[index3];
              profileWorkflow[index3] = object3;
            });
        });
      });

    return (
      <div>
        <PanelHeader />
        <div className="content mt-3">
          {modal ? this.renderModal() : null}
          {modal2 ? this.renderModal2() : null}

          {responseVal.status === "failure" ? <ErrorBlock errors={responseVal.errors} /> : null}
          {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}

          <Row>
            <Col sm="12">
              <GoBack text={currentModule.toUpperCase() + " Detail"} />
            </Col>

            {profileFetched && !profile.newSupplier && profile.address ? (
              <React.Fragment>
                {profile.address.addressDetailListMap &&
                  Object.keys(profile.address.addressDetailListMap).map((index) => {
                    const topLevel = profile.address.addressDetailListMap[index];

                    return (
                      <React.Fragment key={index}>
                        <div className="col-sm-12">
                          <h6>{index.replace(/_/g, " ").toLowerCase()}ES</h6>
                        </div>
                        <div className="col-sm-12">
                          <Row>
                            {topLevel &&
                              topLevel.map((lowLevel) => {
                                return (
                                  <Col lg={3} sm={6} key={`top-${lowLevel.id}`} className="d-flex psmall">
                                    <Card className="card-stats">
                                      <CardBody className="card-flex">
                                        <div className="row">
                                          <div className="col">
                                            <div className="mt-0 mb-0">
                                              <>
                                                <h5 className="text-capitalize">
                                                  <span
                                                    className={`status ${lowLevel.workflowStatus}`}
                                                    id={"Tooltip-x" + lowLevel.id}
                                                  >
                                                    <i className="now-ui-icons ui-1_check" />
                                                  </span>

                                                  {lowLevel.addressType?.replace(/_/g, " ").toLowerCase()}
                                                </h5>
                                                <TooltipItem
                                                  placement="right"
                                                  text={lowLevel.workflowStatus}
                                                  id={"x" + lowLevel.id}
                                                />
                                              </>

                                              {lowLevel.workflowStatus === "SUBMITTED" ||
                                              lowLevel.workflowStatus === "REJECTED" ||
                                              lowLevel.workflowStatus === "ON_HOLD" ? (
                                                <div
                                                  className="admin-action p-2 mt-2"
                                                  style={{ background: "#f5f5f5" }}
                                                >
                                                  <div className="mt-0 mb-0 d-flex justify-content-between">
                                                    <p className="mb-0 text-muted">Current Status</p>
                                                    <p className="mb-0 mt-0">
                                                      <span className={`text-${lowLevel.workflowStatus}`}>
                                                        {lowLevel.workflowStatus?.replace(/_/g, "")}
                                                      </span>
                                                    </p>
                                                  </div>

                                                  <div className="mt-1 mb-0 d-flex justify-content-between">
                                                    <p className="mb-0 text-muted">Status Notes</p>
                                                    <p className="mb-0 mt-0">
                                                      <span>{lowLevel.workflowStatusMessage}</span>
                                                    </p>
                                                  </div>
                                                  {lowLevel.adminWorkflowMessage ? (
                                                    <div className="mt-2 mb-0">
                                                      <p className="mb-0 text-muted">Admin Notes</p>
                                                      <p className="mt-0 mb-0">{lowLevel.adminWorkflowMessage}</p>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : null}

                                              <p className="mt-3 white-space-pre">
                                                Address Status:{" "}
                                                <span className={`text-${lowLevel.status}`}>{lowLevel.status}</span>
                                              </p>
                                              {lowLevel.gstNumber ? (
                                                <p className="white-space-pre">
                                                  GST: <strong>{lowLevel.gstNumber}</strong>
                                                </p>
                                              ) : null}

                                              <p className="white--space-pre">{lowLevel.fullAddress}</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12">
                                            {userType === "SUPPLIER" &&
                                            lowLevel.addressType !== "REGISTERED_ADDRESS" &&
                                            lowLevel.status !== "ACTIVE" ? (
                                              <Button
                                                color="danger"
                                                size="xs"
                                                className="mt-0 btn-simple btn-danger"
                                                onClick={() => {
                                                  this.setDelete(lowLevel);
                                                }}
                                              >
                                                Delete
                                              </Button>
                                            ) : null}

                                            <div className="float-right">
                                              <Button
                                                color="secondary"
                                                size="xs"
                                                className="mt-0 btn-simple btn-primary"
                                                onClick={() => {
                                                  this.setValue(lowLevel);
                                                }}
                                              >
                                                Edit
                                              </Button>
                                              {lowLevel.currentState !== "INCOMPLETE" ? (
                                                <Button
                                                  color="secondary"
                                                  size="xs"
                                                  className="mt-0 btn-simple btn-primary"
                                                  onClick={() => {
                                                    this.setValueView(lowLevel);
                                                  }}
                                                >
                                                  View
                                                </Button>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                );
                              })}
                            {index !== "REGISTERED_ADDRESS" || topLevel.length < 1 ? (
                              <Col lg={3} sm={6} className="d-flex">
                                <Card className="card-stats">
                                  <CardBody
                                    style={{
                                      display: "flex",
                                      height: "100%",
                                    }}
                                    onClick={() => {
                                      this.setValue({
                                        addressType: index,
                                      });
                                    }}
                                  >
                                    <div className="add-new-address">
                                      <p>
                                        <i
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "40px",
                                          }}
                                          className="now-ui-icons ui-1_simple-add"
                                        ></i>
                                      </p>
                                      <p>
                                        ADD NEW
                                        <br /> {index?.replace(/_/g, " ")}
                                      </p>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            ) : null}

            {this.props.isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    profile: state.profile,
    userId: state.auth.userId,
    userType: state.auth.userType,
    locations: state.location,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAddress: (authData, file) => dispatch(updateAddress(authData, file)),
    getAddress: (authData) => dispatch(getAddress(authData)),
    getStates: () => dispatch(getStates()),
    getCities: (stateId) => dispatch(getCities(stateId)),
    resetResponseValues: () => dispatch(resetResponseValues()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
