import React from "react";
import { Button, Row, Col } from "reactstrap";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";
import pdfIcon from "../../assets/img/pdf.svg";

class BankBlock extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { content } = this.props;

    const logo = content && getMediaPath(content.cancelledChequeFileFileId, content.cancelledChequeFileFileExt);

    return content && content.accountNumber ? (
      <React.Fragment>
        <Row>
          <div className="col-12">
            {content.workflowStatus === "SUBMITTED" ||
            content.workflowStatus === "REJECTED" ||
            content.workflowStatus === "ON_HOLD" ? (
              <div className="admin-action p-2 mt-2" style={{ background: "#f5f5f5" }}>
                <div className="mt-0 mb-0 d-flex justify-content-between">
                  <p className="mb-0 text-muted">Current Status</p>
                  <p className="mb-0 mt-0">
                    <span className={`text-${content.workflowStatus}`}>
                      {content.workflowStatus?.replace(/_/g, "")}
                    </span>
                  </p>
                </div>

                <div className="mt-1 mb-0 d-flex justify-content-between">
                  <p className="mb-0 text-muted">Status Notes</p>
                  <p className="mb-0 mt-0">
                    <span>{content.workflowStatusMessage}</span>
                  </p>
                </div>
                {content.adminWorkflowMessage ? (
                  <div className="mt-2 mb-0">
                    <p className="mb-0 text-muted">Admin Notes</p>
                    <p className="mt-0 mb-0">{content.adminWorkflowMessage}</p>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <Col sm={8}>
            {this.props.updateBank &&
            this.props.userType &&
            (this.props.userType === "ADMIN" || this.props.userType === "OPS") ? (
              <div className="border-btm">
                <Button
                  size="xs"
                  className="btn-simple btn-light btn-success"
                  onClick={this.props.updateBank("ACCEPT")}
                >
                  Accept
                </Button>

                <Button
                  size="xs"
                  className="btn-simple btn-light btn-warning"
                  onClick={this.props.updateBank("ONHOLD")}
                >
                  OnHold
                </Button>

                <Button size="xs" className="btn-simple btn-light btn-danger" onClick={this.props.updateBank("REJECT")}>
                  Reject
                </Button>
              </div>
            ) : null}

            <div className="mt-2 mb-0">
              <p className="mb-0 text-muted">Account Status</p>
              <p className="mt-0 mb-0">
                <span className={`text-${content.status}`}>{`${content.status}`}</span>
              </p>
            </div>

            <div className="mt-2 mb-0">
              <p className="mb-0 text-muted">Account Number</p>
              <p className="mt-0">{`${content.accountNumber}` || "N/A"}</p>
            </div>

            <div className="mt-2 mb-0">
              <p className="mb-0 text-muted">Account Name</p>
              <p className="mt-0">{content.accountName || "N/A"}</p>
            </div>

            <div className="mt-0 mb-0">
              <p className="mb-0 text-muted">Bank Address</p>
              <p className="mt-0">
                {content.bankName}, {content.branchName}, {content.branchAddress}, {content.city}
              </p>
            </div>
            <div className="mt-2 mb-0">
              <p className="mb-0 text-muted">IFSC</p>
              <p className="mt-0">{content.ifscCode}</p>
            </div>
            <div className="mt-2 mb-0">
              <p className="mb-0 text-muted">MICR</p>
              <p className="mt-0">{content.micrCode}</p>
            </div>

            <div className="mt-2 mb-0">
              <p className="mb-0 text-muted">Notes</p>
              <p className="mt-0">{content.branchAddInfo || "N/A"}</p>
            </div>

            {/* <div className="mt-2 mb-0">
              <p className="mb-0 text-muted">Admin Notes</p>
              <p className="mt-0">{content.notes}</p>
            </div> */}
          </Col>
          <Col sm={4}>
            <p className="mb-2 mt-2 text-muted">Cancelled Cheque</p>
            <div className="mt-3 mb-0">
              <div className="mt-0">
                {content.fileExt === "pdf" ? (
                  <a href={logo} target="_blank" rel="noopener noreferrer">
                    <img src={pdfIcon} alt="alt" style={{ maxHeight: 100 }} />
                  </a>
                ) : (
                  <ImageZoom src={logo} alt="" />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    ) : null;
  }
}

export default BankBlock;
