import {
  SAVE_AUTH_INFO,
  REMOVE_AUTH_INFO,
  UPDATE_AUTH_TOKEN_INFO,
  CREATE_OR_UPDATE_USER_INFO,
} from "../actions/actionTypes";

const initialState = {
  userId: null,
  parentUserId: null,
  mobileNumber: null,
  userType: null,
  userRole: null,
  deviceId: null,
  deviceToken: null,
  token: null,
  refreshToken: null,
  expiryDate: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_AUTH_INFO:
      return {
        ...state,
        userId: action.userId,
        parentUserId: action.parentUserId,
        mobileNumber: action.mobileNumber,
        userType: action.userType,
        userRole: action.userRole,
        deviceId: action.deviceId,
        deviceToken: action.deviceToken,
        token: action.token,
        refreshToken: action.refreshToken,
        expiryDate: action.expiryDate,
      };
    case REMOVE_AUTH_INFO:
      return {
        ...state,
        userId: null,
        parentUserId: null,
        mobileNumber: null,
        userType: null,
        userRole: null,
        deviceId: null,
        deviceToken: null,
        token: null,
        refreshToken: null,
        expiryDate: null,
      };
    case UPDATE_AUTH_TOKEN_INFO:
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        expiryDate: action.expiryDate,
      };
    case CREATE_OR_UPDATE_USER_INFO:
      return {
        ...state,
        userId: action.userId,
        mobileNumber: action.mobileNumber,
        userType: action.userType,
      };
    default:
      return state;
  }
};

export default authReducer;
