import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import { connect } from "react-redux";
import { getAddress, updateAddress, getContact, updateContact } from "../../../datautils/actions/userActions";
import Select from "react-select";
import { getStates, getCities } from "../../../datautils/actions/locationActions";
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import ErrorBlock from "../../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../../components/SuccessBlock/SuccessBlock";
import AddressBlock from "../../../components/Profile/AddressBlock";
import ContactBlock from "../../../components/Profile/ContactBlock";

import { genderOptions, genderTitleOptions } from "../../../utils/constants/index";
import { resetResponseValues } from "../../../datautils/actions/responseHandlerActions";
import GoBack from "../../../components/GoBack/GoBack";
// import { format } from "date-fns";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

class Address extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMode: false,
      apiDataFetched: false,
      apiData: null,
      modal: false,
      selectedBlock: null,
      addressUpdated: false,
      documentId: "",

      title: "",
      firstName: "",
      lastName: "",
      dob: "",
      email: "",
      gender: "",
      designation: "",
      phone: "",

      line1Address: "",
      line2Address: "",
      localityName: "",
      stateId: "",
      cityId: "",
      landmark: "",
      pincode: "",

      isUpdated: false,
      currentModule: "address",
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.props.resetResponseValues();
    const path = this.props.location.pathname.split("/");
    const currentModule = path[path.length - 1];
    this.setState({ currentModule });

    const authData = {
      userId: this.props.userId,
    };

    if (currentModule === "address") {
      this.props.getAddress(authData);
      this.props.getStates();
    } else if (currentModule === "contact") {
      this.props.getContact(authData);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedBlock, profileFetched, addressUpdated, modal } = this.state;
    const { responseVal, profile } = this.props;

    if (!profile.newSupplier && !profileFetched) {
      this.setState({
        profileData: profile,
        profileFetched: true,
      });
    }

    const isContact = [
      "FINANCE_CONTACT",
      "PRIMARY_CONTACT",
      "OPERATION_CONTACT",
      "AUTHORIZED_SIGNATORY_CONTACT",
    ].includes(selectedBlock);

    if (responseVal.status === "success" && modal && (isContact || addressUpdated)) {
      this.setState({ modal: false });
    }
  }

  toggle() {
    const { selectedBlock, modal, viewMode } = this.state;
    const { profile } = this.props;

    const documentType = selectedBlock?.replace(/_INFO/gi, "");

    let currentDoc = "";

    if (profile?.address?.addressDetailsMap && documentType in profile.address.addressDetailsMap) {
      currentDoc = profile.address.addressDetailsMap[documentType];
    } else if (profile?.contact?.contactDetailsMap && documentType in profile.contact.contactDetailsMap) {
      currentDoc = profile.contact.contactDetailsMap[documentType];
    }

    const isContact = [
      "FINANCE_CONTACT",
      "PRIMARY_CONTACT",
      "OPERATION_CONTACT",
      "AUTHORIZED_SIGNATORY_CONTACT",
    ].includes(selectedBlock);

    if (currentDoc) {
      if (isContact) {
        this.setState((prevState) => ({
          modal: !prevState.modal,

          title: currentDoc.title,
          firstName: currentDoc.firstName,
          lastName: currentDoc.lastName,
          dob: currentDoc.dob ? new Date(currentDoc.dob) : "",
          email: currentDoc.email,
          gender: currentDoc.gender,
          designation: currentDoc.designation,
          phone: currentDoc.phone,

          documentId: currentDoc.id,
        }));
      } else {
        this.setState(
          (prevState) => ({
            addressUpdated: false,
            modal: !prevState.modal,
            documentId: currentDoc.id,
            line1Address: currentDoc.line1Address,
            line2Address: currentDoc.line2Address,
            localityName: currentDoc.localityName,
            cityId: currentDoc.cityId,
            landmark: currentDoc.landmark,
            pincode: currentDoc.pincode,
            stateId: currentDoc.stateId,
          }),
          () => (!modal && !viewMode ? this.props.getCities(currentDoc.stateId) : null)
        );
        this.setState({ isUpdated: false });
      }
    } else {
      this.setState((prevState) => ({
        modal: !prevState.modal,
        addressUpdated: false,
        line1Address: "",
        line2Address: "",
        localityName: "",
        stateId: "",
        cityId: "",
        landmark: "",
        pincode: "",

        documentId: "",
        title: "",
        firstName: "",
        lastName: "",
        dob: "",
        email: "",
        gender: "",
        designation: "",
        phone: "",
        isUpdated: false,
      }));
    }
    this.props.resetResponseValues();
  }

  handleChangeSelect = (obj, opt) => {
    this.setState({ [obj]: opt.value });
  };

  handleChangeStartDate = (date) => {
    this.setState({ dob: date });
  };

  setValue = (selectedBlock) => {
    this.setState({ selectedBlock, viewMode: false }, () => this.toggle());
  };

  setValueView = (selectedBlock) => {
    this.setState({ selectedBlock, viewMode: true }, () => this.toggle());
  };

  handleChange = (input) => (event) => {
    if (input === "phone") {
      let phoneState = this.state.phoneState;
      if (isNaN(event.target.value)) {
        phoneState = "Enter only numeric as phone number ";
      } else {
        phoneState = "";
      }
      this.setState({ [event.target.name]: event.target.value, phoneState });
    } else if (input === "documentIdentitierNo") {
      let documentIdentitierNo = event.target.value;
      let documentIdentitierNoState = this.state.documentIdentitierNoState;
      if (documentIdentitierNo.length > 1) {
        documentIdentitierNoState = "is-valid";
      } else {
        documentIdentitierNoState = "is-invalid";
      }
      this.setState({ documentIdentitierNo, documentIdentitierNoState });
    } else if (input === "documentAdditionalInfo") {
      let documentAdditionalInfo = event.target.value;
      let documentAdditionalInfoState = this.state.documentAdditionalInfoState;

      if (documentAdditionalInfo.length > 5) {
        documentAdditionalInfoState = "is-valid";
      } else {
        documentAdditionalInfoState = "is-invalid";
      }
      this.setState({ documentAdditionalInfo, documentAdditionalInfoState });
    } else if (input === "state") {
      this.setState({
        stateId: event.target.value,
        cityId: "",
        isUpdated: false,
        addressUpdated: false,
      });
      this.props.getCities(event.target.value);
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }

    //console.log(this.state);
  };

  updateAddress = (e) => {
    e.preventDefault();

    const {
      selectedBlock,
      documentId,
      line1Address,
      line2Address,
      localityName,
      cityId,
      landmark,
      pincode,
    } = this.state;

    const authData = {
      addressType: selectedBlock,
      id: documentId,
      line1Address,
      line2Address,
      localityName,
      cityId,
      landmark,
      pincode,
      userId: this.props.userId,
      userType: "SUPPLIER",
    };

    this.props.updateAddress(authData);
    this.setState({ addressUpdated: true, isUpdated: true });
  };

  updateContact = (e) => {
    e.preventDefault();

    const {
      selectedBlock,
      documentId,
      title,
      firstName,
      lastName,
      dob,
      email,
      gender,
      designation,
      phone,
    } = this.state;

    const authData = {
      contactType: selectedBlock,
      id: documentId,
      title,
      firstName,
      lastName,
      dob,
      email,
      gender,
      designation,
      phone,
      userId: this.props.userId,
      userType: "SUPPLIER",
    };

    this.props.updateContact(authData);
    this.setState({ isUpdated: true });
  };

  renderModal = () => {
    const {
      viewMode,
      selectedBlock,

      line1Address,
      line1AddressState,
      line2Address,
      line2AddressState,
      localityName,
      localityNameState,

      landmark,
      landmarkState,
      pincode,
      pincodeState,

      title,
      firstName,
      lastName,
      // dob,
      email,
      gender,
      designation,
      phone,
      firstNameState,
      lastNameState,
      emailState,

      designationState,
      phoneState,
    } = this.state;

    const { profile, locations } = this.props;

    let isEnabled = true;
    let formData = "";

    const isContact = [
      "FINANCE_CONTACT",
      "PRIMARY_CONTACT",
      "OPERATION_CONTACT",
      "AUTHORIZED_SIGNATORY_CONTACT",
    ].includes(selectedBlock);

    if (isContact) {
      isEnabled = title && firstName && phone && gender;

      if (viewMode) {
        formData = <ContactBlock type={selectedBlock} content={profile.contact} />;
      } else {
        formData = (
          <React.Fragment>
            <Form autoComplete="off">
              <FormGroup row>
                <Label for="title" sm={3}>
                  Title <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Select
                    placeholder="Select Title"
                    isSearchable={false}
                    className="react-select"
                    name="title"
                    options={genderTitleOptions}
                    value={genderTitleOptions.filter((option) => option.value === title)}
                    onChange={(opt) => this.handleChangeSelect("title", opt)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="firstName" sm={3}>
                  First Name<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={firstNameState}
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    onChange={this.handleChange("firstName")}
                    value={firstName}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="lastName" sm={3}>
                  Last Name
                </Label>
                <Col sm={9}>
                  <Input
                    className={lastNameState}
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    onChange={this.handleChange("lastName")}
                    value={lastName}
                  />
                </Col>
              </FormGroup>

              {/* <FormGroup row>
                <Label for="dob" sm={3}>
                  Date of Birth
                </Label>
                <Col sm={9}>
                  
                  <DatePicker
                    className="form-control"
                    placeholderText="Click to select DOB"
                    showMonthDropdown
                    showYearDropdown
                    peekNextMonth
                    dropdownMode="select"
                    selected={dob}
                    onChange={this.handleChangeStartDate}
                    dateFormat="yyyy-MM-dd"
                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                    withPortal
                  />
                </Col>
              </FormGroup> */}

              <FormGroup row>
                <Label for="email" sm={3}>
                  Email
                </Label>
                <Col sm={9}>
                  <Input
                    className={emailState}
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={this.handleChange("email")}
                    value={email}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="gender" sm={3}>
                  Gender<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Select
                    placeholder="Select Gender"
                    isSearchable={false}
                    className="react-select"
                    name="gender"
                    options={genderOptions}
                    value={genderOptions.filter((option) => option.value === gender)}
                    onChange={(opt) => this.handleChangeSelect("gender", opt)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="designation" sm={3}>
                  Designation
                </Label>
                <Col sm={9}>
                  <Input
                    className={designationState}
                    type="text"
                    name="designation"
                    placeholder="Designation"
                    onChange={this.handleChange("designation")}
                    value={designation}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="phone" sm={3}>
                  Phone<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={phoneState}
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    onChange={this.handleChange("phone")}
                    value={phone}
                  />
                  {phoneState && phoneState.length > 0 ? <p className="text-danger mb-0">{phoneState}</p> : null}
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col sm={3}></Col>
                <Col sm={9}>
                  <Button
                    color="primary"
                    className="btn-block"
                    onClick={this.updateContact}
                    disabled={
                      this.props.isLoading || !isEnabled || (phoneState && phoneState.length > 0 ? true : false)
                    }
                  >
                    Save Contact
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </React.Fragment>
        );
      }
    } else {
      if (viewMode) {
        formData = <AddressBlock type={selectedBlock} content={profile.address} />;
      } else {
        formData = (
          <React.Fragment>
            <Form autoComplete="off">
              <FormGroup row>
                <Label for="line1Address" sm={3}>
                  Line1 Address<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={line1AddressState}
                    type="text"
                    name="line1Address"
                    placeholder="Line1 Address"
                    onChange={this.handleChange("line1Address")}
                    value={line1Address}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="line2Address" sm={3}>
                  Line2 Address
                </Label>
                <Col sm={9}>
                  <Input
                    className={line2AddressState}
                    type="text"
                    name="line2Address"
                    placeholder="Line2 Address"
                    onChange={this.handleChange("line2Address")}
                    value={line2Address}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="localityName" sm={3}>
                  Locality Name
                </Label>
                <Col sm={9}>
                  <Input
                    className={localityNameState}
                    type="text"
                    name="localityName"
                    placeholder="Locality Name"
                    onChange={this.handleChange("localityName")}
                    value={localityName}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="state" sm={3}>
                  State<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input type="select" name="state" value={this.state.stateId} onChange={this.handleChange("state")}>
                    <option key="state-0" value="0">
                      Select State
                    </option>
                    {locations.states &&
                      locations.states.map((value, index) => {
                        return (
                          <option key={index} value={value.id}>
                            {value.name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="city" sm={3}>
                  City <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input type="select" name="cityId" value={this.state.cityId} onChange={this.handleChange("cityId")}>
                    <option key="city-0" value="0">
                      Select City
                    </option>
                    {locations.cities &&
                      locations.cities.map((value, index) => {
                        return (
                          <option key={index} value={value.id}>
                            {value.name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="landmark" sm={3}>
                  Landmark
                </Label>
                <Col sm={9}>
                  <Input
                    className={landmarkState}
                    type="text"
                    name="landmark"
                    placeholder="Landmark"
                    onChange={this.handleChange("landmark")}
                    value={landmark}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="pincode" sm={3}>
                  Pincode<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={pincodeState}
                    type="number"
                    name="pincode"
                    max="999999"
                    placeholder="Pincode"
                    onChange={this.handleChange("pincode")}
                    value={pincode}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col sm={3}></Col>
                <Col sm={9}>
                  <Button
                    color="primary"
                    className="btn-block"
                    onClick={this.updateAddress}
                    disabled={this.props.isLoading || !isEnabled}
                  >
                    Save Address
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </React.Fragment>
        );
      }
    }

    return (
      <Modal backdrop="static" centered isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
        <ModalHeader toggle={this.toggle}>{this.state.selectedBlock.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">{formData}</div>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { profileFetched, isUpdated, currentModule } = this.state;
    const { profile, responseVal } = this.props;

    /*const isEnabled =
      businessNameState === "is-valid" &&
      businessDescriptionState === "is-valid";*/

    return (
      <div>
        <PanelHeader />
        <div className="content mt-3">
          {this.state.modal ? this.renderModal() : null}
          {responseVal.status === "failure" ? <ErrorBlock errors={responseVal.errors} /> : null}

          {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}
          <Row>
            <Col sm="12">
              <GoBack text={currentModule.toUpperCase() + " Detail"} />
            </Col>
            {profileFetched ? (
              !profile.newSupplier && profile.workflow ? (
                <React.Fragment>
                  {Object.keys(profile.workflow.workflowStatusMap).map((index) => {
                    const topLevel = profile.workflow.workflowStatusMap[index];

                    return (
                      <React.Fragment key={index}>
                        {Object.keys(topLevel).map((index) => {
                          const midLevel = topLevel[index];
                          const midLevelArray = midLevel.subSections;
                          return index === currentModule.toUpperCase() ? (
                            <React.Fragment key={index}>
                              {Object.keys(midLevelArray).map((index) => {
                                const lowLevel = midLevelArray[index];

                                const doc = profile?.contact?.contactDetailsMap[index];

                                return (
                                  <Col md={3} sm={6} xs={12} key={`top-${index}`} className="d-flex psmall">
                                    <Card className="card-stats">
                                      <CardBody>
                                        <div className="row">
                                          <div className="col">
                                            <h6>{index.replace(/_/g, " ").toLowerCase()}</h6>

                                            <div className="mt-3 mb-0">
                                              {doc ? (
                                                <Row className="border-btm mb-2">
                                                  <Col>
                                                    <p className="mb-0">{`${doc.title} ${doc.firstName} ${doc.lastName}, ${doc.gender}`}</p>
                                                    <p className="mb-0">{`Contact: ${doc.phone}`}</p>
                                                    <p>{`Email: ${doc.email || "N/A"}`}</p>
                                                    <p>{`Designation: ${doc.designation || "N/A"}`}</p>
                                                  </Col>
                                                </Row>
                                              ) : null}

                                              <p className="mb-0 text-muted">
                                                <small
                                                  className={
                                                    lowLevel.currentState === "INCOMPLETE"
                                                      ? "text-danger"
                                                      : lowLevel.currentState === "SUBMITTED"
                                                      ? "text-warning"
                                                      : "text-success"
                                                  }
                                                >
                                                  {lowLevel.currentState}
                                                </small>
                                              </p>
                                              <p className="mt-0">{lowLevel.statusMessage}</p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-12">
                                            <Button
                                              color="secondary"
                                              size="xs"
                                              className="mt-0 btn-simple btn-primary"
                                              onClick={() => {
                                                this.setValue(index);
                                              }}
                                            >
                                              Edit
                                            </Button>

                                            {/* {lowLevel.currentState !== "INCOMPLETE" ? (
                                              <Button
                                                color="secondary"
                                                size="xs"
                                                className="mt-0 btn-simple btn-primary"
                                                onClick={() => {
                                                  this.setValueView(index);
                                                }}
                                              >
                                                View
                                              </Button>
                                            ) : null} */}
                                          </div>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                );
                              })}{" "}
                            </React.Fragment>
                          ) : (
                            ""
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              ) : null
            ) : null}
            {this.props.isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    profile: state.profile,
    userId: state.auth.userId,
    locations: state.location,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAddress: (authData) => dispatch(updateAddress(authData)),
    updateContact: (authData) => dispatch(updateContact(authData)),
    getContact: (authData) => dispatch(getContact(authData)),
    getAddress: (authData) => dispatch(getAddress(authData)),
    getStates: () => dispatch(getStates()),
    getCities: (stateId) => dispatch(getCities(stateId)),
    resetResponseValues: () => dispatch(resetResponseValues()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
