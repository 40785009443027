import React from "react";
import about from "../../assets/img/about.svg";
import howto from "../../assets/img/how-to.svg";
import { Col, Row } from "reactstrap";
function About() {
  return (
    <div className="static-page">
      <Row>
        <Col sm={5}>
          <img src={about} alt="" />
        </Col>
        <Col sm={6} className="ml-auto">
          <div className="align-self-center">
            <h1 style={{ fontSize: 23, marginTop: 80 }}>About Us</h1>
            <p>
              We are a startup providing solid ecommerce solution to wholesale
              industry
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="ml-auto">
          <div className="align-self-center">
            <h1 style={{ fontSize: 23, marginTop: 80 }}>How we do it</h1>
            <p>
              We have a strong AI systems which calculates trending items in the
              industry and displays suggestion to buy and sell
            </p>
          </div>
        </Col>
        <Col sm={5}>
          <img src={howto} alt="" />
        </Col>
      </Row>
    </div>
  );
}
export default About;
