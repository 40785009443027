import React from "react";
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  FormGroup,
  Input,
  Form,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import Dropzone from "../../components/Dropzone/Dropzone";
import GoBack from "../../components/GoBack/GoBack";
import { uploadImages, uploadAllImages } from "../../datautils/actions/skuActions";
import { menuTypes } from "../../utils/constants";

import {
  getCategoryLevel,
  getMegaMenuTree,
  searchMenu,
  addMenu,
  mapCategoryMenu,
  fetchMenuAttachments,
  activateDeactivateMenu,
  addUpdateMenuMedia,
} from "../../datautils/actions/categoryActions";

import { getMediaPath, getFilteredAuthData } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";

import {
  resetResponseValues,
  resetObjectValues,
  removeMediaObject,
  clearMediaObject,
} from "../../datautils/actions/responseHandlerActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import CropperModal from "../../components/ImageCropper/CropperModal";
import uniqid from "uniqid";

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: "1",
      selectedCatId: "",
      activeTab: "1",
      id: "",

      platform: "MOBILE",
      show: false,
      files: {},
      chain: [],
      currentObject: null,

      isUpdated: false,
      chainMenu: [],
      modal: false,
      modal2: false,
      menu: "",
      selectedMenuId: "",
      currentMenu: null,

      entityMapList: {
        PRODUCT: [],
        SUPPLIER: [],
        BRAND: [],
        CAMPAIGN: [],
        CATEGORY_IDENTIFIER: [],
      },
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggle2 = (requestData) => {
    if (this.state.modal2) {
      this.setState({
        modal2: false,
        notes: "",
        currentTitle: "",
        currentAction: "",
        currentActionType: "",
      });
    } else {
      this.setState({
        modal2: true,
        notes: "",
        currentTitle: requestData.title,
        currentAction: requestData.action,
        currentActionType: requestData.actionType,

        isUpdated: false,
      });
      this.props.resetResponseValues();
    }
  };

  handleChangeNotes = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    this.props.resetResponseValues();
    this.props.resetObjectValues("megaMenu");
    const { platform } = this.props.match.params;
    if (platform) {
      this.setState({ platform });
    }
    this.props.getMegaMenuTree({ platform: platform || this.state.platform });
  }

  componentWillUnmount() {
    this.props.clearMediaObject();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { responseVal } = this.props;
    const { modal, modal2, isUpdated, currentMenu, menu2Id, menu3Id, menu4Id } = this.state;

    if (responseVal.status === "success" && isUpdated && (modal || modal2)) {
      this.setState({ modal: false, modal2: false });
    }

    if (responseVal.dObjectData?.menuAttachments?.lastUpdated !== prevState.lastUpdated) {
      this.setState({
        menu: "",
        entityMapList: {
          PRODUCT: [],
          SUPPLIER: [],
          BRAND: [],
          CAMPAIGN: [],
          CATEGORY_IDENTIFIER: [],
        },
        lastUpdated: responseVal.dObjectData?.menuAttachments?.lastUpdated,
      });
    }

    if (prevProps.responseVal.dObjectData?.megaMenu?.lastUpdated !== responseVal.dObjectData?.megaMenu?.lastUpdated) {
      const megaMenu = responseVal.dObjectData?.megaMenu;

      let stateObj = {};
      if (megaMenu && megaMenu.data && megaMenu.data.megaMenus) {
        megaMenu.data.megaMenus.forEach((mega) => {
          if (mega.id === menu2Id) {
            stateObj["menu2"] = mega.subs;
            if (currentMenu && mega.id === currentMenu.id) {
              stateObj["currentMenu"] = mega;
            } else {
              mega.subs.forEach((mega2) => {
                if (mega2.id === menu3Id) {
                  stateObj["menu3"] = mega2.subs;
                  if (currentMenu && mega2.id === currentMenu.id) {
                    stateObj["currentMenu"] = mega2;
                  } else {
                    mega2.subs.forEach((mega3) => {
                      if (mega3.id === menu4Id) {
                        stateObj["menu4"] = mega3.subs;
                        if (currentMenu && mega3.id === currentMenu.id) {
                          stateObj["currentMenu"] = mega3;
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }

      this.setState({
        ...stateObj,
        files: [],
        lastUpdated: megaMenu.lastUpdated,
      });
    }
  }

  enableDisableImage = (key, status) => (e) => {
    e.preventDefault();
    const { currentMenu, platform } = this.state;
    const mediasToPost = {};

    Object.keys(currentMenu.medias).forEach((index) => {
      const obj = currentMenu.medias[index];

      let isPrimary = obj.primary;
      let newStatus = obj.status;
      let isIcon = obj.icon;

      if (status === "PRIMARY") {
        isPrimary = key === index ? true : false;
        newStatus = key === index ? "ACTIVE" : obj.status;
      } else if (status === "ICON") {
        isPrimary = obj.primary;
        newStatus = key === index ? "ACTIVE" : obj.status;
        isIcon = key === index ? true : false;
      } else {
        newStatus = key === index ? status : obj.status;
      }

      mediasToPost[index] = {
        fileId: index,
        status: newStatus,
        primary: isPrimary,
        //icon: status === "ICON" ? true : false,
        icon: isIcon,
      };
    });

    const reqData = {
      iRefId: currentMenu.iRefId,
      medias: mediasToPost,
      platform,
    };

    this.props.addUpdateMenuMedia({ ...reqData });
    this.setState({ isUpdated: true });
  };

  handleChangeSelect = (obj, opt) => {
    // this.setState(prevState => ({
    //   ...prevState,
    //   [obj]: opt.value
    // }));
    window.location.href = "/admin/menu/" + opt.value.toLowerCase();
  };

  removeFromState = (row) => (e) => {
    let filteredArray = this.state.files.filter((element) => element.name !== row.name);
    this.setState({ files: [...filteredArray] });
    this.props.removeMediaObject(row.name);
  };

  handleModal = (level, current) => (e) => {
    /** Open new category popup  */
    if (e.target.dataset.level) {
      this.toggle();
    }
    const step = "menu" + (level + 1);
    const stepId = step + "Id";

    /** Clean the chain if intermediate category selected */
    let obj = { selectedMenuId: "" };
    if (level > 0) {
      for (let i = level + 1; i <= 6; i++) {
        let innerStep = "menu" + i;
        let innerStepId = "menu" + i + "Id";
        obj[innerStep] = "";
        obj[innerStepId] = "";
      }
    }
    /** ** ** ** ** ** ** **  */

    let chainArray = [...this.state.chainMenu];

    chainArray = chainArray.slice(0, level - 1);
    if (current.name) {
      chainArray.push(current.name);
    }

    this.setState(
      {
        ...obj,
        chainMenu: chainArray,
        selectedMenuId: current.iRefId,
        [stepId]: current.id,
        [step]: current.subs,
        currentMenu: current,
        isUpdated: false,
        menu: "",
      },
      () => this.props.resetObjectValues("menuAttachments")
    );
  };

  fetchMappings = (e) => {
    const { currentMenu, platform } = this.state;
    this.props.fetchMenuAttachments({ iRefId: currentMenu.iRefId, platform });
    this.setState({ isUpdated: false });
  };

  handleClick = (level, current) => (e) => {
    const stepId = "level" + (level + 1) + "Id";
    let obj = {};

    /** Reset next cat level */
    if (level > 0) {
      for (let i = level + 1; i <= 6; i++) {
        let innerStepId = "level" + i + "Id";
        obj[innerStepId] = "";
        this.props.resetObjectValues("categoryLevel_" + i);
      }
    }
    /** ** ** ** ** ** ** **  */

    let chainArray = [...this.state.chain];

    chainArray = chainArray.slice(0, level - 1);
    if (current.name) {
      chainArray.push(current.name);
    }

    this.setState({
      ...obj,
      chain: chainArray,
      selectedCatId: current.leaf ? current.iRefId : "",
      [stepId]: current.id,
      currentObject: current,
      isUpdated: false,
    });

    this.props.getCategoryLevel({ iRefId: current.iRefId, level });
  };

  onDrop = (acceptedFiles) => {
    const newObj = {};
    acceptedFiles.forEach((file) => {
      newObj[uniqid()] = { original: file, base64: null, cropped: null, uploaded: null };
    });

    this.setState({ ...this.state, show: true, files: { ...this.state.files, ...newObj } });

    // this.setState({ files: [...this.state.files, ...acceptedFiles] });

    // acceptedFiles.map((acceptedFile) => {
    //   return this.props.uploadImages(acceptedFile);
    // });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const { menu, platform } = this.state;
      this.props.searchMenu({ menu, platform });
    }
  };

  searchMenu = (e) => {
    const { menu, platform } = this.state;
    this.props.searchMenu({ menu, platform });
  };

  handleMenu = (input) => (e) => {
    this.setState({ menu: input });
  };

  chooseMenu = (e) => {
    this.setState({
      chainMenu: [...this.state.chainMenu, this.state.menu],
      modal: false,
      isUpdated: false,
    });
  };

  addMenu = () => {
    const { platform, chainMenu } = this.state;
    const authData = getFilteredAuthData(this.props.authData);
    const menuTree = chainMenu.join("||");

    const reqData = {
      menuTree,
      ...authData,
      platform,
    };

    this.props.addMenu(reqData);
    this.setState({ isUpdated: true });
  };

  handleAttachment = (object) => (e) => {
    const { entityMapList } = this.state;

    let existingArray = entityMapList[object.entityType];

    const index = existingArray.findIndex((k) => k.entityIdentifierFieldValue === object.entityIdentifierFieldValue);

    if (index === -1) {
      existingArray.push(object);
      existingArray.reverse();
    }

    this.setState(
      {
        currentEntityType: object.entityType,
        latestEntry: object.entityIdentifierFieldValue,
        hasMapList: true,
        entityMapList: {
          ...entityMapList,
          // [object.entityType]: existingArray
          [object.entityType]: [object],
        },
      },
      () => this.mapAttachment()
    );
  };

  mapAttachment = () => {
    const { currentMenu, entityMapList, platform } = this.state;
    const reqData = {
      iRefId: currentMenu.iRefId,
      entityMapList,
      platform,
    };

    this.props.mapCategoryMenu(reqData);
    this.setState({ isUpdated: true });
  };

  activateDeactivateMapping = (entityMapList) => (e) => {
    const { currentMenu, platform } = this.state;

    const reqData = {
      iRefId: currentMenu.iRefId,
      entityMapList,
      action: "activation",
      platform,
    };

    this.props.mapCategoryMenu(reqData);
    this.setState({ isUpdated: true });
  };

  activateDeactivateMenu = (e) => {
    const { modal2, currentMenu, notes, platform } = this.state;
    if (modal2) {
      const reqParam = {
        iRefId: currentMenu.iRefId,
        notes,
        action: e.target.dataset.action,
        platform,
      };
      this.props.activateDeactivateMenu(reqParam);
    } else {
      this.toggle2({
        actionType: e.target.dataset.action,
        action: "activateDeactivateMenu",
        title: e.target.dataset.action + " Menu",
      });
    }
    this.setState({ isUpdated: true });
  };

  tabActions = (e) => {
    this.props.resetResponseValues();
    if (e.target.dataset.action === "refresh") {
      this.props.getMegaMenuTree({ platform: this.state.platform });
    }

    if (e.target.dataset.value === "2") {
      this.props.getCategoryLevel({});
    }

    this.setState({
      currentTab: e.target.dataset.value,
      mainTitle: e.target.dataset.title,

      // menu2: "",
      // menu3: "",
      // menu4: "",
      // menu2Id: "",
      // menu3Id: "",
      // menu4Id: "",
      // chainMenu: [],
      // currentMenu: null,
      // menu: "",
      // selectedMenuId: "",

      isUpdated: false,
      files: [],
    });
  };

  renderModal = () => {
    const { modal, menu } = this.state;
    const { responseVal } = this.props;

    const menus = responseVal.dObjectData.searchMenu?.data;

    return (
      <Modal backdrop="static" isOpen={modal} toggle={this.toggle} className="modal-dialog-centered">
        <ModalHeader toggle={this.toggle}>Search Menu</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    <FormGroup>
                      <div className="has-search">
                        <div className="input-group">
                          <Input
                            type="search"
                            placeholder="Search menu name"
                            onChange={this.handleChange("menu")}
                            value={menu}
                            onKeyDown={this.handleKeyDown}
                          />
                          <div className="input-group-append">
                            <Button color="primary" onClick={this.searchMenu} disabled={menu.length < 1}>
                              <i className="now-ui-icons ui-1_zoom-bold" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </FormGroup>

                    <div style={{ minHeight: 300 }}>
                      {menus && menus.total > 0 ? (
                        <ul className="brand-suggest">
                          {menus.megaMenus.map((searchedItem) => {
                            return (
                              <li key={"cats-" + searchedItem.id} onClick={this.handleMenu(searchedItem.name)}>
                                {searchedItem.name}
                              </li>
                            );
                          })}
                        </ul>
                      ) : null}
                    </div>
                    <FormGroup>
                      <Button className="btn-block btn-primary" onClick={this.chooseMenu} disabled={menu.length < 1}>
                        create new menu <strong>{menu}</strong>
                      </Button>
                    </FormGroup>
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  renderModal2 = () => {
    const { notes, currentAction, currentActionType, currentTitle } = this.state;

    return (
      <Modal backdrop="static" isOpen={this.state.modal2} toggle={this.toggle2}>
        <ModalHeader toggle={this.toggle2}>{currentTitle.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <Form>
                    <FormGroup className="mb-5">
                      <Input
                        type="textarea"
                        placeholder="Add notes/reason"
                        onChange={this.handleChangeNotes("notes")}
                        value={notes}
                      />
                    </FormGroup>

                    {this.renderSwitch(currentActionType, currentAction)}
                  </Form>
                </div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  renderSwitch(currentActionType, action) {
    const { notes, currentAttrKey } = this.state;

    if (action === "enableDisableImage") {
      switch (currentActionType) {
        case "ACTIVE":
          return (
            <Button
              className="btn-block btn-success"
              data-action="ACTIVE"
              onClick={this.enableDisableImage(currentAttrKey, "ACTIVE")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Activate
            </Button>
          );

        case "INACTIVE":
          return (
            <Button
              className="btn-block btn-danger"
              data-action="INACTIVE"
              onClick={this.enableDisableImage(currentAttrKey, "INACTIVE")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Deactivate
            </Button>
          );

        default:
          return null;
      }
    } else if (action === "activateDeactivateMenu") {
      switch (currentActionType) {
        case "ACTIVATE":
          return (
            <Button
              className="btn-block btn-success"
              data-action="ACTIVATE"
              onClick={this.activateDeactivateMenu}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Activate
            </Button>
          );

        case "DEACTIVATE":
          return (
            <Button
              className="btn-block btn-danger"
              data-action="DEACTIVATE"
              onClick={this.activateDeactivateMenu}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Deactivate
            </Button>
          );

        default:
          return null;
      }
    }
  }

  /** Save uploaded media to Menu */
  saveMediaWith = (mediaList) => {
    const { platform, currentMenu } = this.state;

    const medias = {};

    mediaList?.forEach((fileId) => {
      medias[fileId] = { fileId, status: "INACTIVE", primary: false };
    });

    if (Object.keys(medias).length > 0) {
      const reqData = {
        iRefId: currentMenu.iRefId,
        medias,
        platform,
      };
      this.props.addUpdateMenuMedia({ ...reqData });
      this.setState({ isUpdated: true });
    }
  };

  /** Upload cropped image to server and attach with Product */
  uploadCroppedImage = async () => {
    try {
      const fileIds = await this.props.uploadAllImages(this.state.files);
      this.saveMediaWith(fileIds);
    } catch (error) {
      console.log("uploadCroppedImage", error);
    }
  };

  setShow = (show) => {
    this.setState({ ...this.state, show: !show });
  };

  setFiles = (files) => {
    console.log("files", files);
    this.setState({ ...this.state, files: { ...files } });
  };

  render() {
    const {
      chain,
      selectedCatId,
      // isUpdated,
      files,
      id,
      currentTab,
      chainMenu,
      modal,
      modal2,
      menu,
      menu2,
      menu3,
      menu2Id,
      menu3Id,
      menu4Id,
      currentMenu,
      currentObject,
      selectedMenuId,
      platform,
      show,
    } = this.state;
    const { responseVal } = this.props;

    const megaMenu = responseVal.dObjectData?.megaMenu?.data?.megaMenus;
    const level1 = responseVal.dObjectData?.categoryLevel_0?.data?.categories;
    const level2 = responseVal.dObjectData?.categoryLevel_1?.data?.categories;
    const level3 = responseVal.dObjectData?.categoryLevel_2?.data?.categories;
    const level4 = responseVal.dObjectData?.categoryLevel_3?.data?.categories;
    const level5 = responseVal.dObjectData?.categoryLevel_4?.data?.categories;
    const level6 = responseVal.dObjectData?.categoryLevel_5?.data?.categories;
    const attachedAttributes = responseVal.dObjectData?.menuAttachments?.data;

    const totalFiles = Object.keys(files).length;
    const totalCropped = Object.keys(files).filter((key) => files[key].cropped);

    return (
      <div>
        <PanelHeader />
        {modal && this.renderModal()}
        {modal2 && this.renderModal2()}

        {show && (
          <CropperModal
            show={show}
            setShow={(show) => this.setShow(show)}
            files={files}
            setFiles={(files) => this.setFiles(files)}
            aspect={5 / 3}
            cropOptions={[
              { aspect: 5 / 3, text: "Crop for Banner" },
              { aspect: 1, text: "Crop for Icon" },
            ]}
          />
        )}

        <div className="content mt-3 ">
          <Col xs={12}>
            <MessageBlock screenName="category" />
          </Col>

          <Row className="equal">
            <Col md={12} sm={12}>
              <GoBack text={id > 0 ? "Update Menu" : "Mega Menu Manager"} />
              <ul className="tabs">
                {" "}
                <li style={{ width: 200, paddingLeft: 0 }}>
                  <Select
                    isSearchable={false}
                    options={menuTypes}
                    placeholder="Select Platform"
                    value={menuTypes.filter((option) => option.value === platform.toUpperCase())}
                    onChange={(opt) => this.handleChangeSelect("platform", opt)}
                  />
                </li>
                <li
                  data-title="Add new menu"
                  data-value="1"
                  onClick={this.tabActions}
                  className={currentTab === "1" ? "bg-secondary text-white" : ""}
                >
                  Menu Actions
                </li>
                <li
                  data-title="Add menu mapping"
                  data-value="2"
                  onClick={this.tabActions}
                  className={currentTab === "2" ? "bg-secondary text-white" : ""}
                >
                  Menu Mapping
                </li>
                <li
                  data-title="Activate or Deactivate particular menu"
                  data-value="3"
                  onClick={this.tabActions}
                  className={currentTab === "3" ? "bg-secondary text-white d-none" : "d-none"}
                >
                  Activate/Deactivate
                </li>
                <li
                  data-title="Add Menu Image"
                  data-value="4"
                  onClick={this.tabActions}
                  className={currentTab === "4" ? "bg-secondary text-white" : ""}
                >
                  Menu Images
                </li>
                <li className="pull-right">
                  <i
                    title="Refresh Menu"
                    className="now-ui-icons arrows-1_refresh-69"
                    data-action="refresh"
                    data-title={this.state.mainTitle}
                    data-value={this.state.currentTab}
                    onClick={this.tabActions}
                  />
                </li>
              </ul>
              <>
                <p>
                  * Notes - <span className="text-success">Green (Active)</span>,{" "}
                  <span className="text-danger">Red (Inactive)</span>
                </p>
                <Row className="mb-0">
                  <Col md={2} sm={3} xs={6}>
                    <ul className="add-cat mega-menu">
                      {currentTab === "1" ? (
                        <li>
                          <Button size="sm" color="primary" data-level="1" onClick={this.handleModal(1, {})}>
                            Add menu here
                          </Button>
                        </li>
                      ) : null}
                      {megaMenu
                        ? Object.keys(megaMenu).map(function (keyName) {
                            const inner = megaMenu[keyName];

                            return (
                              <li
                                key={"one-" + inner.id}
                                onClick={this.handleModal(1, inner)}
                                className={this.state.menu2Id === inner.id ? "active" : ""}
                              >
                                <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                  {inner.name}
                                </span>
                              </li>
                            );
                          }, this)
                        : null}
                    </ul>
                  </Col>
                  {menu2Id ? (
                    <Col md={2} sm={3} xs={6}>
                      <ul className="add-cat left-arrow mega-menu">
                        {currentTab === "1" ? (
                          <li>
                            <Button size="sm" color="primary" data-level="2" onClick={this.handleModal(2, {})}>
                              Add menu here
                            </Button>
                          </li>
                        ) : null}
                        {menu2 &&
                          Object.keys(menu2).map(function (keyName, keyIndex) {
                            const inner = menu2[keyName];
                            return (
                              <li
                                key={"menu2" + inner.id}
                                data-value={inner.name}
                                onClick={this.handleModal(2, inner)}
                                className={menu3Id === inner.id ? "active" : ""}
                              >
                                <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                  {inner.name}
                                </span>
                              </li>
                            );
                          }, this)}
                      </ul>
                    </Col>
                  ) : null}
                  {menu3Id ? (
                    <Col md={2} sm={3} xs={6}>
                      <ul className="add-cat left-arrow mega-menu">
                        {currentTab === "1" ? (
                          <li>
                            <Button size="sm" color="primary" data-level="3" onClick={this.handleModal(3, {})}>
                              Add menu here
                            </Button>
                          </li>
                        ) : null}
                        {menu3 &&
                          Object.keys(menu3).map(function (keyName, keyIndex) {
                            const inner = menu3[keyName];
                            return (
                              <li
                                key={"menu3" + inner.id}
                                data-value={inner.name}
                                onClick={this.handleModal(3, inner)}
                                className={menu4Id === inner.id ? "active" : ""}
                              >
                                <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                  {inner.name}
                                </span>
                              </li>
                            );
                          }, this)}
                      </ul>
                    </Col>
                  ) : null}
                </Row>
              </>

              {currentTab === "2" && chainMenu.length ? (
                <React.Fragment>
                  <h5 className="mt-4">Select category tree to attach to mega menu</h5>
                  <Row className="mb-4">
                    <Col md={2} sm={3} xs={6}>
                      <ul className="add-cat">
                        {level1
                          ? Object.keys(level1).map(function (keyName) {
                              const inner = level1[keyName];
                              return (
                                <li
                                  key={"one-" + inner.id}
                                  onClick={this.handleClick(1, inner)}
                                  className={this.state.level2Id === inner.id ? "active" : ""}
                                >
                                  <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                    {inner.name}
                                  </span>
                                </li>
                              );
                            }, this)
                          : null}
                      </ul>
                    </Col>
                    {level2 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat left-arrow">
                          {Object.keys(level2).map(function (keyName) {
                            const inner = level2[keyName];
                            return (
                              <li
                                key={inner.id}
                                onClick={this.handleClick(2, inner)}
                                className={this.state.level3Id === inner.id ? "active" : ""}
                              >
                                <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                  {inner.name}
                                </span>
                              </li>
                            );
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level3 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level3).map(function (keyName) {
                            const inner = level3[keyName];
                            return (
                              <li
                                key={inner.id}
                                onClick={this.handleClick(3, inner)}
                                className={this.state.level4Id === inner.id ? "active" : ""}
                              >
                                <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                  {inner.name}
                                </span>
                              </li>
                            );
                          }, this)}{" "}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level4 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level4).map(function (keyName) {
                            const inner = level4[keyName];
                            return (
                              <li
                                key={inner.id}
                                onClick={this.handleClick(4, inner)}
                                className={this.state.level5Id === inner.id ? "active" : ""}
                              >
                                <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                  {inner.name}
                                </span>
                              </li>
                            );
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level5 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level5).map(function (keyName) {
                            const inner = level5[keyName];
                            return (
                              <li
                                className={
                                  selectedCatId === inner.iRefId || this.state.level6Id === inner.id ? "active" : ""
                                }
                                key={inner.id}
                                onClick={this.handleClick(5, inner)}
                              >
                                <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                  {inner.name}
                                </span>
                              </li>
                            );
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level6 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level6).map(function (keyName) {
                            const inner = level6[keyName];
                            return (
                              <li
                                className={selectedCatId === inner.iRefId ? "active" : ""}
                                key={inner.id}
                                onClick={this.handleClick(6, inner)}
                              >
                                <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                  {inner.name}
                                </span>
                              </li>
                            );
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}
                  </Row>
                </React.Fragment>
              ) : null}

              <Row className="mt-4">
                <Col md="6" sm="6">
                  <Row>
                    <Col sm="12">
                      <p className="text-success">
                        <strong className="selected-tree">
                          SELECTED MENU
                          {currentMenu && currentMenu.status ? (
                            <span className={currentMenu.status.toLowerCase()}>
                              &nbsp; (Status : &nbsp;
                              {currentMenu.status.toLowerCase()})
                            </span>
                          ) : null}
                        </strong>
                      </p>
                      <Breadcrumb className="mapping">
                        {chainMenu.length > 0
                          ? chainMenu.map((item, index) => {
                              return <BreadcrumbItem key={"chainMenu-" + index}>{item}</BreadcrumbItem>;
                            })
                          : null}
                      </Breadcrumb>

                      {chainMenu.length > 0 &&
                      menu.length > 0 &&
                      !modal &&
                      currentTab === "1" &&
                      chainMenu.includes(menu) ? (
                        <Button size="sm" color="danger" onClick={() => this.addMenu} disabled={!platform}>
                          Generate final menu tree
                        </Button>
                      ) : null}

                      {currentTab === "12" && chainMenu.length === 1 ? (
                        <p>
                          Cant activate/deactive this level, this is added in another table and need a separate api to
                          do this
                        </p>
                      ) : null}

                      {currentTab === "1" && chainMenu.length && currentMenu && currentMenu.status && !menu ? (
                        <>
                          {currentMenu.status === "ACTIVE" ? (
                            <Button
                              size="sm"
                              color="danger"
                              data-action="DEACTIVATE"
                              onClick={this.activateDeactivateMenu}
                            >
                              Deactivate
                            </Button>
                          ) : (
                            <Button
                              size="sm"
                              color="success"
                              data-action="ACTIVATE"
                              onClick={this.activateDeactivateMenu}
                            >
                              Activate
                            </Button>
                          )}
                        </>
                      ) : null}
                    </Col>

                    <Col sm="12">
                      {currentTab === "2" && chainMenu.length > 0 && chain.length > 0 ? (
                        <>
                          <p className="text-success">
                            <strong>SELECTED CATEGORY</strong>
                          </p>
                          <Breadcrumb className="mapping">
                            {chain.map((item, index) => {
                              return <BreadcrumbItem key={"breads-" + index}>{item}</BreadcrumbItem>;
                            })}
                          </Breadcrumb>

                          <Button
                            size="sm"
                            color="danger"
                            onClick={this.handleAttachment({
                              //entityLabel: chain.slice(-1)[0],
                              entityLabel: chain.join(" - "),
                              entityType: "CATEGORY_IDENTIFIER",
                              entityIdentifierField: "identifier",
                              entityIdentifierFieldValue: currentObject.iRefId,
                              status: "ACTIVE",
                              weight: 2,
                            })}
                          >
                            Map above category to menu
                          </Button>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <Col md="6" sm="6">
                  {currentMenu && currentTab === "2" && chainMenu.length && !attachedAttributes ? (
                    <Button color="primary" className="float-right" style={{ margin: 0 }} onClick={this.fetchMappings}>
                      Fetch Existing Mapping
                    </Button>
                  ) : null}

                  {attachedAttributes && currentTab === "2" ? (
                    <>
                      <div className="card-header">
                        {" "}
                        Existing Mappings of menu [ {chainMenu && chainMenu.length ? chainMenu.join(" / ") : null} ]
                      </div>
                      <ul className="brand-suggest auto p-2">
                        {Object.keys(attachedAttributes.entityMapList).map(function (keyName, keyIndex) {
                          const rows = attachedAttributes.entityMapList[keyName];
                          return (
                            <React.Fragment key={`exister-${keyIndex}`}>
                              <li className="head">{keyName}</li>
                              {rows.map((row, i) => {
                                return (
                                  <li key={"atts-" + i}>
                                    {row.entityLabel} ({row.entityIdentifierFieldValue})
                                    {row.status === "ACTIVE" ? (
                                      <span
                                        className="badge badge-success pull-right"
                                        onClick={this.activateDeactivateMapping({
                                          [row.entityType]: [
                                            {
                                              entityType: row.entityType,
                                              entityIdentifierField: row.entityIdentifierField,
                                              entityIdentifierFieldValue: row.entityIdentifierFieldValue,
                                              status: "INACTIVE",
                                              weight: 2,
                                            },
                                          ],
                                        })}
                                      >
                                        Disable
                                      </span>
                                    ) : (
                                      <span
                                        className="badge badge-danger pull-right"
                                        onClick={this.activateDeactivateMapping({
                                          [row.entityType]: [
                                            {
                                              entityType: row.entityType,
                                              entityIdentifierField: row.entityIdentifierField,
                                              entityIdentifierFieldValue: row.entityIdentifierFieldValue,
                                              status: "ACTIVE",
                                              weight: 2,
                                            },
                                          ],
                                        })}
                                      >
                                        Enable
                                      </span>
                                    )}
                                  </li>
                                );
                              })}
                            </React.Fragment>
                          );
                        }, this)}
                      </ul>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Col>
                {selectedMenuId && currentTab === "4" ? (
                  <React.Fragment>
                    <h5 className="row mt-3">Mega Menu Images</h5>

                    <Row className="product-images">
                      <Col sm="12">
                        <div className="mt-3">
                          <Row className="equal">
                            <Col sm={5}>
                              <Dropzone onFilesAdded={(opt) => this.onDrop(opt)} />

                              <Row>
                                <Col sm={12}>
                                  <Row className="d-flex mt-2 p-1">
                                    {Object.keys(files).map((key) => {
                                      const file = files[key];
                                      return file.cropped ? (
                                        <div className="p-2" key={`cropped-${key}`}>
                                          <ImageZoom
                                            src={file.cropped}
                                            alt={file.cropped}
                                            className="img-thumbnail rounded"
                                            style={{ maxHeight: 100 }}
                                          />
                                        </div>
                                      ) : null;
                                    })}
                                  </Row>
                                </Col>

                                <Col sm={12}>
                                  {Object.keys(files).length > 0 ? (
                                    <Button size="sm" color="light" onClick={() => this.setShow(false)}>
                                      Edit Images
                                    </Button>
                                  ) : null}

                                  {totalFiles > 0 && totalFiles === totalCropped?.length ? (
                                    <Button size={"sm"} color="success" onClick={() => this.uploadCroppedImage()}>
                                      Save Images
                                    </Button>
                                  ) : null}
                                </Col>
                              </Row>

                              {files ? (
                                <>
                                  <Row className="mt-3 equal">
                                    {files.length > 0 &&
                                      files.map((acceptedFile) => (
                                        <Col sm="6" className="" key={acceptedFile.name}>
                                          <img
                                            alt={acceptedFile.name}
                                            src={URL.createObjectURL(acceptedFile)}
                                            className="img-thumbnail rounded"
                                          />
                                          <p className="text-center">{acceptedFile.name}</p>
                                          <button
                                            onClick={this.removeFromState(acceptedFile)}
                                            type="button"
                                            className="close img-remove"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </Col>
                                      ))}
                                  </Row>
                                  <Row>
                                    {files.length > 0 && currentMenu.iRefId ? (
                                      <Button color="primary" size="md" onClick={this.addUpdateImages}>
                                        Update Images
                                      </Button>
                                    ) : null}
                                  </Row>
                                </>
                              ) : null}
                            </Col>

                            <Col sm="7">
                              <Row className="equal">
                                <Col md="6">
                                  <div className="bg-white p-2 mb-3 text-center" style={{ minHeight: 150 }}>
                                    <p className="mb-2">Current Active Image</p>
                                    {currentMenu &&
                                      currentMenu.medias &&
                                      Object.keys(currentMenu.medias).map((index) => {
                                        return currentMenu.medias[index].primary ? (
                                          <ImageZoom
                                            key={`index1-${index}`}
                                            style={{ height: 100 }}
                                            src={getMediaPath(
                                              currentMenu.medias[index].fileId,
                                              currentMenu.medias[index].fileExt
                                            )}
                                            alt={""}
                                          />
                                        ) : null;
                                      })}
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="bg-white p-2 mb-3 text-center" style={{ minHeight: 150 }}>
                                    <p className="mb-2">Current Active Icon</p>

                                    {Object.keys(currentMenu.medias).map((index) => {
                                      return currentMenu.medias[index].icon ? (
                                        <ImageZoom
                                          key={`index2-${index}`}
                                          style={{ height: 100 }}
                                          src={getMediaPath(
                                            currentMenu.medias[index].fileId,
                                            currentMenu.medias[index].fileExt
                                          )}
                                          alt={""}
                                        />
                                      ) : null;
                                    })}
                                  </div>
                                </Col>
                              </Row>

                              {currentMenu && currentMenu.medias ? (
                                <div className="mt-3 equal">
                                  <div className="price-invent mb-3" style={{ padding: 0, minHeight: "auto" }}>
                                    <div className="card-header">Available Images</div>
                                    <div className="card-body">
                                      <Row>
                                        {Object.keys(currentMenu.medias).map((index) => {
                                          return (
                                            <Col md="4" key={index}>
                                              <div className="bg-light p-2 mb-3 text-center">
                                                <ImageZoom
                                                  src={getMediaPath(
                                                    currentMenu.medias[index].fileId,
                                                    currentMenu.medias[index].fileExt
                                                  )}
                                                  alt={""}
                                                  style={{ maxHeight: 100 }}
                                                />
                                                {currentMenu.medias[index].status === "ACTIVE" ? (
                                                  <Button
                                                    size="sm"
                                                    className="btn-block"
                                                    color="success"
                                                    onClick={this.enableDisableImage(index, "INACTIVE")}
                                                    disabled={
                                                      currentMenu.medias[index].primary ||
                                                      currentMenu.medias[index].icon
                                                    }
                                                  >
                                                    Deactivate
                                                  </Button>
                                                ) : (
                                                  <Button
                                                    size="sm"
                                                    className="btn-block"
                                                    color="danger"
                                                    onClick={this.enableDisableImage(index, "ACTIVE")}
                                                  >
                                                    Activate
                                                  </Button>
                                                )}
                                                <Button
                                                  size="sm"
                                                  className="btn-block"
                                                  color="info"
                                                  data-type="primary"
                                                  onClick={this.enableDisableImage(index, "PRIMARY")}
                                                  disabled={currentMenu.medias[index].primary}
                                                >
                                                  {currentMenu.medias[index].primary
                                                    ? "Current Banner"
                                                    : "Set as Banner"}
                                                </Button>
                                                <Button
                                                  size="sm"
                                                  className="btn-block"
                                                  color="info"
                                                  data-type="primary"
                                                  onClick={this.enableDisableImage(index, "ICON")}
                                                  disabled={currentMenu.medias[index].icon}
                                                >
                                                  {currentMenu.medias[index].icon ? "Current Menu Icon" : "Set as Icon"}
                                                </Button>
                                              </div>
                                            </Col>
                                          );
                                        })}
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : null}
                {this.props.isLoading ? <div className="loader" /> : null}
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    userId: state.auth.userId,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activateDeactivateMenu: (reqParam) => dispatch(activateDeactivateMenu(reqParam)),
    uploadImages: (acceptedFile) => dispatch(uploadImages(acceptedFile)),
    getCategoryLevel: (reqParam) => dispatch(getCategoryLevel(reqParam)),
    resetObjectValues: (reqParam) => dispatch(resetObjectValues(reqParam)),
    removeMediaObject: (reqParam) => dispatch(removeMediaObject(reqParam)),
    resetResponseValues: () => dispatch(resetResponseValues()),
    getMegaMenuTree: (reqParam) => dispatch(getMegaMenuTree(reqParam)),
    searchMenu: (reqParam) => dispatch(searchMenu(reqParam)),
    addMenu: (reqParam) => dispatch(addMenu(reqParam)),
    mapCategoryMenu: (reqParam) => dispatch(mapCategoryMenu(reqParam)),
    fetchMenuAttachments: (reqParam) => dispatch(fetchMenuAttachments(reqParam)),
    addUpdateMenuMedia: (reqParam) => dispatch(addUpdateMenuMedia(reqParam)),
    clearMediaObject: () => dispatch(clearMediaObject()),
    uploadAllImages: (files) => dispatch(uploadAllImages(files)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
