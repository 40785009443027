export const routeMap = {
  adminList: [{ userType: "ADMIN", userRole: "ADMIN" }],

  adminDashboard: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
  ],
  adminPassword: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminSuppliers: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminSuppliersProfile: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminSkuList: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminUpdateSku: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminProductList: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminUpdateProduct: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminCategories: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminCampaigns: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminCoupons: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminBrands: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminRequests: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminOrders: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminCustomers: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminTrending: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminMenu: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminSamples: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminCustomOffers: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminBillBook: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminOrderRequestList: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],
  adminTempSkuList: [
    { userType: "ADMIN", userRole: "ADMIN" },
    { userType: "OPS", userRole: "ADMIN" },
    { userType: "OPS", userRole: "OPS_FINANCE" },
    { userType: "OPS", userRole: "OPS_CATALOG_MANAGER" },
    { userType: "OPS", userRole: "OPS_ORDER_MANAGER" },
    { userType: "OPS", userRole: "OPS_CUSTOMER_CARE" },
  ],

  supplierDashboard: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierProfile: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierPassword: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierKyc: [{ userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" }],
  supplierAddress: [{ userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" }],
  supplierAddSku: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierSkuList: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierUpdateSku: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierRequests: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierOrders: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierSamples: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierCustomOffers: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierBillBook: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
  supplierUserList: [{ userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" }],

  supplierOrderRequestList: [
    { userType: "SUPPLIER", userRole: "SUPPLIER_ADMIN" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_MANAGER" },
    { userType: "SUPPLIER", userRole: "SUPPLIER_EMPLOYEE" },
  ],
};
