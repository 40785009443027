import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  CustomInput,
} from "reactstrap";
import { connect } from "react-redux";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import {
  couponEntityMapping,
  fetchCampaign,
  fetchCoupon,
  categoryTreeSearch,
  fetchProduct,
  getBrandDetail,
} from "../../datautils/actions/adminActions";
import { getCategories } from "../../datautils/actions/skuActions";
import { getProfile } from "../../datautils/actions/userActions";
import moment from "moment";
import Product from "./Attachments/Product";
import Supplier from "./Attachments/Supplier";
import Brand from "./Attachments/Brand";
import Camp from "./Attachments/Camp";

class Coupon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      level1: "",
      level2: "",
      level3: "",
      level4: "",
      level5: "",
      level6: "",
      category: "",
      selectedCatId: "",
      lastCatId: "",
      chain: [],

      id: 0,
      code: "",
      currentTab: "2",
      isUpdated: false,
      mainTitle: "Attach brand",
      entityMapList: {
        PRODUCT: [],
        SUPPLIER: [],
        BRAND: [],
        CAMPAIGN: [],
        CATEGORY_IDENTIFIER: [],
      },
      hasMapList: false,
      currentEntityType: "",
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  componentDidMount() {
    const { id, code } = this.props.match.params;
    this.setState({ id, code });
    this.props.fetchCoupon({ id });
  }

  componentDidUpdate(prevProps, prevState) {
    const { categories, responseVal } = this.props;

    if (categories && categories.categories && !this.state.level1) {
      this.setState({
        level1: categories.categories,
        lastUpdated: categories.lastUpdated,
      });
    }

    if (prevState.lastUpdated !== categories.lastUpdated) {
      this.setState({
        level1: categories.categories,
        lastUpdated: categories.lastUpdated,
        category: "",
        modal: false,
        modal2: false,
      });
    }

    if (
      prevProps.responseVal.dObjectData &&
      prevProps.responseVal.dObjectData.coupon &&
      prevProps.responseVal.dObjectData.coupon.lastUpdated !==
        responseVal.dObjectData.coupon.lastUpdated
    ) {
      this.setState({
        entityMapList: {
          PRODUCT: [],
          SUPPLIER: [],
          BRAND: [],
          CAMPAIGN: [],
          CATEGORY_IDENTIFIER: [],
        },
        hasMapList: false,
      });
    }

    if (this.state.latestEntry) {
      setTimeout(() => {
        this.setState({ latestEntry: "" });
      }, 1000);
    }
  }

  handleAttachment = (object) => (e) => {
    object = { ...object, include: true };
    const { entityMapList } = this.state;

    let existingArray = entityMapList[object.entityType];

    const index = existingArray.findIndex(
      (k) => k.entityIdentifierFieldValue === object.entityIdentifierFieldValue
    );

    if (index === -1) {
      existingArray.push(object);
      existingArray.reverse();
    }

    this.setState({
      currentEntityType: object.entityType,
      latestEntry: object.entityIdentifierFieldValue,
      hasMapList: true,
      entityMapList: {
        ...entityMapList,
        [object.entityType]: existingArray,
      },
    });
  };

  attachToCoupon = () => {
    this.props.couponEntityMapping({
      coupon: {
        id: this.state.id,
        code: this.state.code,
        entityMapList: this.state.entityMapList,
      },
    });
    this.setState({
      isUpdated: true,
    });
  };

  tabActions = (e) => {
    this.props.resetResponseValues();

    this.setState({
      currentTab: e.target.dataset.value,
      mainTitle: e.target.dataset.title,
      isUpdated: false,
    });

    if (e.target.dataset.value === "1") {
      this.props.getCategories({});
    }
  };

  handleCheckbox = (object) => (e) => {
    const { entityMapList } = this.state;
    let checked = e.target.checked;
    let existingArray = entityMapList[object.entityType].map((el) =>
      el.entityIdentifierFieldValue === object.entityIdentifierFieldValue
        ? { ...el, include: checked }
        : el
    );

    console.log("roww", object);
    console.log("befo", existingArray);

    this.setState(
      {
        entityMapList: { ...entityMapList, [object.entityType]: existingArray },
      },
      () => console.log("state", this.state)
    );
  };

  handleCheckboxUpdate = (object) => (e) => {
    let checked = e.target.checked;
    let existingArray = [];
    console.log("exu", object);

    existingArray[object.entityType] = [{ ...object, include: checked }];
    console.log("arr", existingArray);
    this.props.couponEntityMapping({
      coupon: {
        id: this.state.id,
        code: this.state.code,
        entityMapList: { ...existingArray },
      },
    });
  };

  handleClick = (level, subs) => (e) => {
    const currentObject = { ...subs };
    if (currentObject.subs) delete currentObject.subs;

    if (e.target.dataset.level) {
      this.toggle();
    }

    let array = [...this.state.chain];
    let index = level - 1;

    array = array.slice(0, index);

    if (e.target.dataset.value) {
      array.push(e.target.dataset.value);
    }

    this.setState({ chain: array });

    const step = "level" + (level + 1);
    const stepId = step + "Id";
    if (level > 0) {
      let obj = { selectedCatId: "" };

      for (let i = level + 1; i <= 6; i++) {
        let innerStep = "level" + i;
        let innerStepId = "level" + i + "Id";
        obj[innerStep] = "";
        obj[innerStepId] = "";
        this.setState(obj);
      }
    }

    if (subs.subs && subs.subs.length > 0) {
      this.setState({
        [step]: subs.subs,
        [stepId]: subs.id,
        lastCatId: subs.id,
        currentObject,
      });
    } else {
      this.setState({
        selectedCatId: subs.catRef,
        [stepId]: subs.id,
        lastCatId: subs.id,
        currentObject,
      });
    }
  };

  activateDeactivateMapping = (entityMapList) => (e) => {
    const reqParam = {
      coupon: {
        id: this.state.id,
        code: this.state.code,
        entityMapList: entityMapList,
      },
    };
    this.props.couponEntityMapping(reqParam);

    this.setState({ isUpdated: true });
  };

  viewDetails = (row) => (e) => {
    this.setState({ isUpdated: false, currentEntityType: row.entityType }, () =>
      this.toggle()
    );
    if (row.entityType === "CATEGORY_IDENTIFIER") {
      this.props.categoryTreeSearch({ iRefId: row.entityIdentifierFieldValue });
    } else if (row.entityType === "PRODUCT") {
      this.props.fetchProduct({ productId: row.entityIdentifierFieldValue });
    } else if (row.entityType === "BRAND") {
      this.props.getBrandDetail({ id: row.entityIdentifierFieldValue });
    } else if (row.entityType === "SUPPLIER") {
      this.props.getProfile({ supplierUserId: row.entityIdentifierFieldValue });
    } else if (row.entityType === "CAMPAIGN") {
      this.props.fetchCampaign({
        id: row.entityIdentifierFieldValue,
        isDetailView: true,
      });
    }
  };

  removeFromState = (row) => (e) => {
    const { entityMapList } = this.state;
    let filteredArray = entityMapList[row.entityType].filter(
      (element) =>
        element.entityIdentifierFieldValue !== row.entityIdentifierFieldValue
    );
    this.setState({
      entityMapList: {
        ...entityMapList,
        [row.entityType]: filteredArray,
      },
    });
  };

  renderModal = () => {
    const { responseVal, profile } = this.props;
    const { currentEntityType } = this.state;
    const data = responseVal.dObjectData;

    return (
      <Modal
        backdrop="static"
        isOpen={this.state.modal}
        toggle={this.toggle}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.toggle}>
          View {currentEntityType} Details
        </ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    {currentEntityType === "CATEGORY_IDENTIFIER"
                      ? data.categoryTreeName &&
                        data.categoryTreeName.data &&
                        data.categoryTreeName.data.map((cat, index) => {
                          return (
                            <p
                              key={`tree-name-${index}`}
                              style={{
                                paddingLeft: index * 50 + "px",
                                marginBottom: 0,
                              }}
                            >
                              {cat}
                            </p>
                          );
                        })
                      : null}

                    {currentEntityType === "BRAND" &&
                    data.brandDetail &&
                    data.brandDetail.data ? (
                      <h5>{data.brandDetail.data.name}</h5>
                    ) : null}

                    {currentEntityType === "PRODUCT" &&
                    data.productInfo &&
                    data.productInfo.data &&
                    data.productInfo.data.product ? (
                      <>
                        <h5>
                          <strong>{data.productInfo.data.product.title}</strong>
                        </h5>

                        <p>
                          <strong>Category Name:</strong>{" "}
                          {data.productInfo.data.product.categoryName}
                        </p>
                        <p>
                          <strong>Brand Name:</strong>{" "}
                          {data.productInfo.data.product.brandName}
                        </p>
                        <p>
                          <img
                            alt=""
                            className="img-fluid"
                            src={getMediaPath(
                              data.productInfo.data.product.defaultFileId,
                              data.productInfo.data.product.defaultFileExt
                            )}
                          />
                        </p>
                      </>
                    ) : null}

                    {currentEntityType === "SUPPLIER" && profile.profileInfo ? (
                      <>
                        <h5>
                          <strong>{profile.profileInfo.businessName}</strong>
                        </h5>

                        <p>
                          <strong>Business Type: </strong>
                          {profile.profileInfo.businessType}
                        </p>

                        <p>{profile.profileInfo.businessDescription}</p>
                      </>
                    ) : null}

                    {currentEntityType === "CAMPAIGN" &&
                    data.campaignDetails &&
                    data.campaignDetails.data &&
                    data.campaignDetails.data.campaign ? (
                      <>
                        <h5>
                          <strong>
                            {data.campaignDetails.data.campaign.name}
                          </strong>
                        </h5>
                        <p>
                          <strong>Start Date:</strong>{" "}
                          {moment(
                            data.campaignDetails.data.campaign.startDateTime
                          ).format("MMM DD YYYY, hh:mm a")}
                        </p>
                        <p>
                          <strong>End Date:</strong>{" "}
                          {moment(
                            data.campaignDetails.data.campaign.endDateTime
                          ).format("MMM DD YYYY, hh:mm a")}
                        </p>
                        <p>
                          <strong>Campaign Code:</strong>{" "}
                          {data.campaignDetails.data.campaign.code}
                        </p>

                        <p>
                          <strong>Campaign Status:</strong>{" "}
                          {data.campaignDetails.data.campaign.status}
                        </p>

                        <p>
                          <strong>Platform Type:</strong>{" "}
                          {data.campaignDetails.data.campaign.platformType}
                        </p>

                        <p>
                          <strong>Description:</strong>{" "}
                          {data.campaignDetails.data.campaign.description}
                        </p>
                      </>
                    ) : null}
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const {
      currentTab,
      isUpdated,
      entityMapList,
      hasMapList,
      level1,
      level2,
      level3,
      level4,
      level5,
      level6,
      level2Id,
      level3Id,
      level4Id,
      level5Id,
      level6Id,
      modal,
      chain,
      currentObject,
    } = this.state;

    const { responseVal } = this.props;

    const attachedAttributes =
      responseVal.dObjectData.coupon &&
      responseVal.dObjectData.coupon.data &&
      responseVal.dObjectData.coupon.data.coupon
        ? responseVal.dObjectData.coupon.data.coupon
        : null;

    return (
      <div>
        <PanelHeader>Coupon</PanelHeader>
        {modal ? this.renderModal() : null}
        <div className="content mt-2">
          {responseVal.status === "failure" ? (
            <ErrorBlock errors={responseVal.errors} />
          ) : null}

          {(responseVal.status === "success" ||
            responseVal.status === "SUCCESS") &&
          isUpdated ? (
            <SuccessBlock
              msg={{
                userDisplayMsg: "Updated successfully",
              }}
            />
          ) : null}

          <ul className="tabs">
            {" "}
            <li
              data-title="Attach brand"
              data-value="2"
              onClick={this.tabActions}
              className={currentTab === "2" ? "bg-secondary text-white" : ""}
            >
              Attach brand
            </li>
            <li
              data-title="Attach product"
              data-value="3"
              onClick={this.tabActions}
              className={currentTab === "3" ? "bg-secondary text-white" : ""}
            >
              Attach product
            </li>
            <li
              data-title="Attach supplier"
              data-value="4"
              onClick={this.tabActions}
              className={currentTab === "4" ? "bg-secondary text-white" : ""}
            >
              Attach supplier
            </li>
            <li
              data-title="Attach campaign"
              data-value="5"
              onClick={this.tabActions}
              className={currentTab === "5" ? "bg-secondary text-white" : ""}
            >
              Attach campaign
            </li>
            <li
              data-title="Attach category"
              data-value="1"
              onClick={this.tabActions}
              className={currentTab === "1" ? "bg-secondary text-white" : ""}
            >
              Attach category
            </li>
          </ul>

          <React.Fragment>
            {/* <h5 className="mb-3">{mainTitle}</h5> */}

            {currentTab === "1" ? (
              <React.Fragment>
                <p className="mb-0">Select category tree to proceed</p>
                <Row className="mt-1 mb-3 cat-box">
                  <Col md={2} sm={3} xs={6}>
                    <ul className="add-cat">
                      {level1
                        ? Object.keys(level1).map(function (keyName, keyIndex) {
                            const inner = level1[keyName];
                            return (
                              <li
                                key={inner.id}
                                data-value={inner.name}
                                onClick={this.handleClick(1, inner)}
                                className={
                                  level2Id === inner.id ? "active" : ""
                                }
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)
                        : null}
                    </ul>
                  </Col>
                  {level2Id ? (
                    <Col md={2} sm={3} xs={6}>
                      <ul className="add-cat left-arrow">
                        {level2 &&
                          Object.keys(level2).map(function (keyName, keyIndex) {
                            const inner = level2[keyName];
                            return (
                              <li
                                key={inner.id}
                                data-value={inner.name}
                                onClick={this.handleClick(2, inner)}
                                className={
                                  level3Id === inner.id ? "active" : ""
                                }
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)}
                      </ul>
                    </Col>
                  ) : null}{" "}
                  {level3Id ? (
                    <Col md={2} sm={3} xs={6}>
                      <ul className="add-cat">
                        {level3 &&
                          Object.keys(level3).map(function (keyName, keyIndex) {
                            const inner = level3[keyName];
                            return (
                              <li
                                key={inner.id}
                                data-value={inner.name}
                                onClick={this.handleClick(3, inner)}
                                className={
                                  level4Id === inner.id ? "active" : ""
                                }
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)}{" "}
                      </ul>
                    </Col>
                  ) : null}{" "}
                  {level4Id ? (
                    <Col md={2} sm={3} xs={6}>
                      <ul className="add-cat">
                        {level4 &&
                          Object.keys(level4).map(function (keyName, keyIndex) {
                            const inner = level4[keyName];
                            return (
                              <li
                                key={inner.id}
                                data-value={inner.name}
                                onClick={this.handleClick(4, inner)}
                                className={
                                  level5Id === inner.id ? "active" : ""
                                }
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)}
                      </ul>
                    </Col>
                  ) : null}{" "}
                  {level5Id ? (
                    <Col md={2} sm={3} xs={6}>
                      <ul className="add-cat">
                        {level5 &&
                          Object.keys(level5).map(function (keyName, keyIndex) {
                            const inner = level5[keyName];
                            return (
                              <li
                                className={
                                  this.state.selectedCatId === inner.catRef ||
                                  level6Id === inner.id
                                    ? "active"
                                    : ""
                                }
                                key={inner.id}
                                data-value={inner.name}
                                onClick={this.handleClick(5, inner)}
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)}
                      </ul>
                    </Col>
                  ) : null}{" "}
                  {level6Id ? (
                    <Col md={2} sm={3} xs={6}>
                      <ul className="add-cat">
                        {level6 &&
                          Object.keys(level6).map(function (keyName, keyIndex) {
                            const inner = level6[keyName];
                            return (
                              <li
                                className={
                                  this.state.selectedCatId === inner.catRef
                                    ? "active"
                                    : ""
                                }
                                key={inner.id}
                                data-value={inner.name}
                                onClick={this.handleClick(6, inner)}
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)}
                      </ul>
                    </Col>
                  ) : null}{" "}
                </Row>
              </React.Fragment>
            ) : null}

            {currentTab ? (
              <Row>
                <Col md="6">
                  <div className="new-items">
                    <h5>New items to attach</h5>
                    {entityMapList && hasMapList ? (
                      <>
                        <ul className="brand-suggest auto">
                          {Object.keys(entityMapList).map((index) => {
                            const rows = entityMapList[index];

                            return (
                              <React.Fragment key={`topper-${index}`}>
                                {rows.length ? (
                                  <li className="head no-bdr">{index}</li>
                                ) : null}

                                {rows.map((row, index) => {
                                  return (
                                    <li
                                      key={`new-${index}`}
                                      className={
                                        this.state.currentEntityType ===
                                          row.entityType &&
                                        this.state.latestEntry ===
                                          row.entityIdentifierFieldValue
                                          ? "highlight"
                                          : ""
                                      }
                                    >
                                      <span className="pull-left mr-3">
                                        <CustomInput
                                          style={{ display: "inline" }}
                                          type="switch"
                                          id={`custom-${row.entityIdentifierFieldValue}`}
                                          label="include"
                                          defaultChecked="true"
                                          onClick={this.handleCheckbox(row)}
                                        />
                                      </span>
                                      {row.entityLabel} (
                                      {row.entityIdentifierFieldValue})
                                      <span
                                        className="pull-right"
                                        onClick={this.removeFromState(row)}
                                      >
                                        <i className="now-ui-icons ui-1_simple-remove" />
                                      </span>
                                    </li>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                        </ul>
                        <div className="text-center">
                          <Button
                            size="md"
                            color="danger"
                            className="pull--right"
                            onClick={this.attachToCoupon}
                          >
                            Add above items to coupon
                          </Button>
                        </div>

                        <div className="clearfix" />
                      </>
                    ) : (
                      <p>
                        New entities will be available here when you select
                        items from right side box
                      </p>
                    )}
                  </div>

                  <div
                    className="bg-white p-2  mb-3"
                    style={{ minHeight: 500 }}
                  >
                    <h5 className="mb-0">Existing attached items</h5>
                    {attachedAttributes ? (
                      <ul className="brand-suggest auto">
                        {Object.keys(attachedAttributes.entityMapList).map(
                          function (keyName, keyIndex) {
                            const rows =
                              attachedAttributes.entityMapList[keyName];
                            return (
                              <React.Fragment key={`exister-${keyIndex}`}>
                                <li className="head">{keyName}</li>
                                {rows.map((row, i) => {
                                  return (
                                    <li key={"atts-" + i}>
                                      <span className="pull-left mr-3">
                                        <CustomInput
                                          style={{ display: "inline" }}
                                          type="switch"
                                          id={`custom-existing-${row.entityType}-${row.entityIdentifierFieldValue}`}
                                          label="include"
                                          defaultChecked={row.include}
                                          onClick={this.handleCheckboxUpdate(
                                            row
                                          )}
                                        />
                                      </span>
                                      {row.entityLabel} (
                                      {row.entityIdentifierFieldValue})
                                      <span
                                        className="badge badge-primary pull-right"
                                        onClick={this.viewDetails(row)}
                                      >
                                        View
                                      </span>
                                      {row.status === "ACTIVE" ? (
                                        <span
                                          className="badge badge-success pull-right"
                                          onClick={this.activateDeactivateMapping(
                                            {
                                              [row.entityType]: [
                                                {
                                                  entityType: row.entityType,
                                                  entityIdentifierField:
                                                    row.entityIdentifierField,
                                                  entityIdentifierFieldValue:
                                                    row.entityIdentifierFieldValue,
                                                  status: "INACTIVE",
                                                  weight: 2,
                                                },
                                              ],
                                            }
                                          )}
                                        >
                                          Disable
                                        </span>
                                      ) : (
                                        <span
                                          className="badge badge-danger pull-right"
                                          onClick={this.activateDeactivateMapping(
                                            {
                                              [row.entityType]: [
                                                {
                                                  entityType: row.entityType,
                                                  entityIdentifierField:
                                                    row.entityIdentifierField,
                                                  entityIdentifierFieldValue:
                                                    row.entityIdentifierFieldValue,
                                                  status: "ACTIVE",
                                                  weight: 2,
                                                },
                                              ],
                                            }
                                          )}
                                        >
                                          Enable
                                        </span>
                                      )}
                                    </li>
                                  );
                                })}
                              </React.Fragment>
                            );
                          },
                          this
                        )}
                      </ul>
                    ) : null}
                  </div>
                </Col>
                <Col md="6">
                  <div className="bg-white p-2" style={{ minHeight: 585 }}>
                    {currentTab === "1" ? (
                      <div className="attributes-form-box">
                        <h5>Selected Category</h5>
                        <Row>
                          <Col>
                            <div className="selected-chain campaign-page">
                              <Breadcrumb>
                                {chain.length > 0
                                  ? chain.map((item, index) => {
                                      return (
                                        <BreadcrumbItem key={"bread-" + index}>
                                          {item}
                                        </BreadcrumbItem>
                                      );
                                    })
                                  : null}
                              </Breadcrumb>
                              {chain.length > 0 ? (
                                <div className=" text-center">
                                  <Button
                                    color="success"
                                    onClick={this.handleAttachment({
                                      entityLabel: chain.slice(-1)[0],
                                      entityType: "CATEGORY_IDENTIFIER",
                                      entityIdentifierField: "identifier",
                                      entityIdentifierFieldValue:
                                        currentObject.iRefId,
                                      status: "ACTIVE",
                                      weight: 2,
                                    })}
                                  >
                                    Select above category tree
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : null}

                    {currentTab === "2" ? (
                      <Brand handleAttachment={this.handleAttachment} />
                    ) : null}

                    {currentTab === "3" ? (
                      <Product handleAttachment={this.handleAttachment} />
                    ) : null}

                    {currentTab === "4" ? (
                      <Supplier handleAttachment={this.handleAttachment} />
                    ) : null}

                    {currentTab === "5" ? (
                      <Camp handleAttachment={this.handleAttachment} />
                    ) : null}
                  </div>
                </Col>
              </Row>
            ) : null}
            {this.props.isLoading ? <div className="loader" /> : null}
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    profile: state.profile,
    categories: state.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    getCategories: (reqParam) => dispatch(getCategories(reqParam)),
    fetchCoupon: (reqData) => dispatch(fetchCoupon(reqData)),
    fetchCampaign: (reqData) => dispatch(fetchCampaign(reqData)),
    fetchProduct: (reqData) => dispatch(fetchProduct(reqData)),
    getProfile: (reqData) => dispatch(getProfile(reqData)),
    getBrandDetail: (reqData) => dispatch(getBrandDetail(reqData)),
    couponEntityMapping: (reqParam) => dispatch(couponEntityMapping(reqParam)),
    categoryTreeSearch: (reqParam) => dispatch(categoryTreeSearch(reqParam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
