import {
  SAVE_PROFILE,
  SAVE_WORKFLOW,
  SAVE_KYC,
  UPDATE_KYC,
  SAVE_BANK,
  UPDATE_BANK,
  SAVE_ADDRESS,
  SAVE_CONTACT,
  UPDATE_ADDRESS,
  UPDATE_CONTACT,
  CLEAR_STORE,
  CLEAR_PROFILE,
} from "../actions/actionTypes";

const initialState = {
  newSupplier: null,
  profileInfo: null,
  workflow: null,
  kyc: null,
  bank: null,
  address: null,
  contact: null,
};
const responseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PROFILE:
      return {
        ...state,
        newSupplier: action.data.newSupplier,
        profileInfo: action.data.profileInfo ? action.data.profileInfo : state.profileInfo,
        workflow: action.data.workflow,
      };
    case CLEAR_PROFILE:
      return {
        newSupplier: null,
        profileInfo: null,
        workflow: null,
        kyc: null,
        bank: null,
        address: null,
        contact: null,
      };
    case CLEAR_STORE:
      return {
        ...state,
        [action.data.documentType]: null,
      };
    case SAVE_WORKFLOW:
      return {
        ...state,
        workflow: action.data.workflow,
      };
    case SAVE_BANK:
      return {
        ...state,
        bank: action.data,
      };
    case UPDATE_BANK:
      return {
        ...state,
        bank: action.data,
        workflow: action.workflow,
      };
    case SAVE_KYC:
      return {
        ...state,
        newSupplier: action.data.newSupplier ? action.data.newSupplier : null,
        kyc: action.data.kyc ? action.data.kyc : { kycDetailsMap: {} },
      };
    case UPDATE_KYC:
      return {
        ...state,
        kyc: {
          kycDetailsMap: {
            ...state.kyc.kycDetailsMap,
            [action.documentType]: action.data,
          },
        },
        workflow: action.workflow,
      };

    case SAVE_ADDRESS:
      return {
        ...state,
        address: action.data.address ? action.data.address : { addressDetailListMap: {} },
        workflow: action.data.workflow,
      };

    case UPDATE_ADDRESS:
      return {
        ...state,
        address: {
          addressDetailListMap: {
            ...state.address.addressDetailListMap,
            [action.documentType]: [...state.address.addressDetailListMap[action.documentType], action.data],
          },
        },
        workflow: action.workflow,
      };
    case SAVE_CONTACT:
      return {
        ...state,
        contact: action.data.contact ? action.data.contact : { contactDetailsMap: {} },
        workflow: action.data.workflow,
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        contact: {
          contactDetailsMap: {
            ...state.contact.contactDetailsMap,
            [action.documentType]: action.data,
          },
        },
        workflow: action.workflow,
      };
    default:
      return state;
  }
};

export default responseReducer;
