import { startUILoading, stopUILoading } from "./uiActions";
import {
  setResponseValues,
  resetResponseValues,
  resetObjectValues,
  clearMediaList,
  clearMediaObject,
} from "./responseHandlerActions";

import axios from "axios";
import {
  SUPPLIERS_LIST_API_URL,
  ADMIN_UPDATE_SUPPLIER,
  ADMIN_UPDATE_BANK,
  ADMIN_UPDATE_KYC,
  ADMIN_UPDATE_ADDRESS,
  ADMIN_ACTIVATE_SUPPLIER,
  ADMIN_VERIFY_SUPPLIER,
  ADMIN_SEARCH_PRODUCT,
  ADMIN_FETCH_PRODUCT,
  ADMIN_SEARCH_CAMPAIGN,
  ADMIN_CREATE_CAMPAIGN,
  ADMIN_FETCH_CAMPAIGN_BY_ID,
  ADMIN_UPDATE_CAMPAIGN,
  ADMIN_UPDATE_CAMPAIGN_MEDIA,
  ADMIN_UPDATE_CAMPAIGN_STATUS,
  ADMIN_CAMPAIGN_ENTITY_MAPPING,
  ADMIN_CATEGORY_TREE_SEARCH,
  SEARCH_BRAND,
  FETCH_BRANDS,
  ADD_BRAND,
  ADMIN_UPDATE_BRAND_MEDIA,
  ADMIN_SEARCH_COUPON,
  ADMIN_CREATE_COUPON,
  ADMIN_FETCH_COUPON_BY_ID,
  ADMIN_UPDATE_COUPON,
  ADMIN_UPDATE_COUPON_MEDIA,
  ADMIN_UPDATE_COUPON_STATUS,
  ADMIN_COUPON_ENTITY_MAPPING,
  SEARCH_REQUEST,
  FETCH_REQUEST,
  CREATE_REQUEST,
  ADD_UPDATE_REQUEST_MEDIA,
  ADMIN_MARK_DEFAULT_PRODUCT,
  ADMIN_PUBLISH_PRODUCT,
  SEARCH_TRENDS,
  ADD_UPDATE_TRENDS,
  FETCH_TRENDS,
  UPDATE_TRENDS_STATUS,
  MARK_DEFAULT_VARIANT_SKU,
  ADMIN_SIMILAR_PRODUCT,
  UPDATE_CUSTOMER_STATUS,
  CREATE_ADMIN,
  LIST_ADMIN,
  FETCH_ADMIN,
  UPDATE_ADMIN_CHANGE_PASSWORD,
  UPDATE_ADMIN_ACTIVATE,
  UPDATE_ADMIN_DEACTIVATE,
  CREATE_USER,
  LIST_USER,
  FETCH_USER,
  UPDATE_USER_CHANGE_PASSWORD,
  UPDATE_USER_ACTIVATE,
  UPDATE_USER_DEACTIVATE,
} from "../../utils/constants/apiUrls";

import { SUCCESS, FAILURE, defaultParams } from "../../utils/constants";
import { getRequestHeaders } from "./authActions";
import { saveResponseMessage, clearMessage } from "./responseMessageActions";

import {
  getKyc,
  getBankInfo,
  getProfile,
  getAddress,
  getContact,
  storeProfileInfo,
  updateBankKyc,
  updateKycInfo,
  //updateAddressInfo,
} from "../../datautils/actions/userActions";

const productResponseMessageKey = "product";
const customerResponseMessageKey = "customer";
const adminResponseMessageKey = "admin";

export const getSuppliersList = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    try {
      const config = await dispatch(getRequestHeaders());
      const URL = SUPPLIERS_LIST_API_URL;

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...reqParam, ...defaultParam };

      const response = await axios.post(URL, postData, config);

      console.log("Get Suppliers List Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "supplierList"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const getSuppliersProfile = (reqParam) => {
  return (dispatch, getState) => {
    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };
    const postData = { ...reqParam, ...defaultParam };

    Promise.all([
      dispatch(getProfile(postData)),
      dispatch(getKyc(postData)),
      dispatch(getBankInfo(postData)),
      dispatch(getContact(postData)),
      dispatch(getAddress(postData)),
    ]).then(() => {});
  };
};

export const updateSupplier = (authData) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    try {
      const config = await dispatch(getRequestHeaders());
      const URL = ADMIN_UPDATE_SUPPLIER;

      const defaultParam = {
        mobileNumber: getState().auth.mobileNumber,
        mobileCountryCode: "+91",
      };

      const postData = { ...authData, ...defaultParam };
      const response = await axios.post(URL, postData, config);

      console.log("Update profile Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null));
      dispatch(storeProfileInfo(response.data));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const updateBank = (authData) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    try {
      const config = await dispatch(getRequestHeaders());
      const URL = ADMIN_UPDATE_BANK;
      const defaultParam = {
        mobileNumber: getState().auth.mobileNumber,
        mobileCountryCode: "+91",
      };

      const postData = { ...authData, ...defaultParam };
      const response = await axios.post(URL, postData, config);

      console.log("Update bank Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null));
      dispatch(updateBankKyc(response.data));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const updateKyc = (authData, file) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = ADMIN_UPDATE_KYC;

      const defaultParam = {
        mobileNumber: getState().auth.mobileNumber,
        mobileCountryCode: "+91",
      };

      const postData = { ...authData, ...defaultParam };

      axios
        .post(URL, postData, config)
        .then((response) => {
          console.log("Update bank Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(updateKycInfo(authData.documentType, response.data));
        })
        .catch(function (error) {
          console.log("Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const updateAddress = (authData) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = ADMIN_UPDATE_ADDRESS;

      const defaultParam = {
        mobileNumber: getState().auth.mobileNumber,
        mobileCountryCode: "+91",
      };

      const postData = { ...authData, ...defaultParam };

      axios
        .post(URL, postData, config)
        .then((response) => {
          console.log("Update address Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          //dispatch(updateAddressInfo(authData.addressType, response.data));
          dispatch(getAddress({ supplierUserId: authData.supplierUserId }));
        })
        .catch(function (error) {
          console.log("Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

/** Activate/Deactivate/Verify/Unverify supplier */

export const activateSupplier = (authData) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = authData.verifySupplier
        ? ADMIN_VERIFY_SUPPLIER + "/" + authData.status + "/supplier"
        : ADMIN_ACTIVATE_SUPPLIER + "/" + authData.status + "/supplier";

      const defaultParam = {
        mobileNumber: getState().auth.mobileNumber,
        mobileCountryCode: "+91",
      };

      const postData = { ...authData, ...defaultParam };

      axios
        .post(URL, postData, config)
        .then((response) => {
          console.log("ACtivate profile Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(storeProfileInfo(response.data));
        })
        .catch(function (error) {
          console.log("ACtivate Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

/** Fetch product detail */
export const fetchProduct = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(resetObjectValues("skuForm"));
    dispatch(resetObjectValues("productInfo"));
    dispatch(clearMessage(productResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...reqParam, ...defaultParam };

      let URL = ADMIN_FETCH_PRODUCT + reqParam.productId;

      if (reqParam.variantId) {
        URL = URL + "/varaint/" + reqParam.variantId;
      }

      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "productInfo"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(productResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Search product (List of products) */
export const searchProduct = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(productResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...reqParam, ...defaultParam };
      const response = await axios.post(ADMIN_SEARCH_PRODUCT, postData, config);

      console.log("search product list Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "productList"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(productResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update product from admin */
export const updateProduct = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(productResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const param = reqParam.status === "PUBLISH" ? "true" : "false";
      const URL =
        reqParam.status === "PUBLISH" || reqParam.status === "UNPUBLISH"
          ? ADMIN_PUBLISH_PRODUCT + param
          : ADMIN_MARK_DEFAULT_PRODUCT;

      const postData = { ...reqParam, ...defaultParam };
      const response = await axios.post(URL, postData, config);

      console.log("update product Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "productInfo"));
      /** Clear error from common reducer if any */
      dispatch(saveResponseMessage(productResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(productResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const categoryTreeSearch = (requestParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = ADMIN_CATEGORY_TREE_SEARCH + requestParam.iRefId;

      const defaultData = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };

      const authData = { ...requestParam, ...defaultData };

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("Get categoryTreeName Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "categoryTreeName"));
        })
        .catch(function (error) {
          console.log("Get categoryTreeName List Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const getBrandDetail = (requestParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = FETCH_BRANDS + "id?term=" + requestParam.id;

      const defaultData = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };

      const authData = { ...requestParam, ...defaultData };

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("Get getBrandDetail Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "brandDetail"));
        })
        .catch(function (error) {
          console.log("Get getBrandDetail List Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

/////////// Campaign Module Actions ///////////
export const getCampaignList = (requestParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = ADMIN_SEARCH_CAMPAIGN;

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const authData = { ...requestParam, ...defaultParam };

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("Get campaignList List Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "campaignList"));
        })
        .catch(function (error) {
          console.log("Get campaignList List Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const addNewCampaign = (requestParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = ADMIN_CREATE_CAMPAIGN;

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const authData = { ...requestParam, ...defaultParam };

      axios
        .post(URL, authData, config)
        .then((response) => {
          dispatch(setResponseValues(SUCCESS, response.data, null, "campaign"));
          dispatch(clearMediaList());
          console.log("add campaign Success Executed", response.data);
        })
        .catch(function (error) {
          console.log("add campaign Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const fetchCampaign = (requestParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(getRequestHeaders()).then((config) => {
      //console.log("tos", requestParam);

      const URL = ADMIN_FETCH_CAMPAIGN_BY_ID + requestParam.id;

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const authData = { ...requestParam, ...defaultParam };

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("fetch campaign Success Executed", response.data);
          if (requestParam.isDetailView) {
            dispatch(setResponseValues(SUCCESS, response.data, null, "campaignDetails"));
          } else {
            dispatch(setResponseValues(SUCCESS, response.data, null, "campaign"));
          }
        })
        .catch(function (error) {
          console.log("fetch campaign Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const updateCampaign = (requestParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = ADMIN_UPDATE_CAMPAIGN;

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const authData = { ...requestParam, ...defaultParam };

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("update campaign Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "campaign"));
          dispatch(clearMediaList());
        })
        .catch(function (error) {
          console.log("update campaign Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const addUpdateCampaignMedia = (reqParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...defaultParam, ...reqParam };
      console.log("cong", config);
      const URL = ADMIN_UPDATE_CAMPAIGN_MEDIA;
      axios
        .post(URL, postData, config)
        .then((response) => {
          dispatch(setResponseValues(SUCCESS, response.data, null, "campaign"));
          dispatch(clearMediaList());
          dispatch(clearMediaObject());
          console.log("campaign ADD Media activate/deactivate Success Executed", response.data);
        })
        .catch(function (error) {
          console.log("Category ADD Media activate/deactivate Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const activateDeactivateCampaign = (reqParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...reqParam, ...defaultParam };

      const URL = ADMIN_UPDATE_CAMPAIGN_STATUS;

      axios
        .post(URL, postData, config)
        .then((response) => {
          console.log("Category activate Deactivate Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "campaign"));
        })
        .catch(function (error) {
          console.log("Category activate Deactivate Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const campaignEntityMapping = (reqParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...reqParam, ...defaultParam };

      const URL = ADMIN_CAMPAIGN_ENTITY_MAPPING;

      axios
        .post(URL, postData, config)
        .then((response) => {
          console.log("campaignEntityMapping Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "campaign"));
        })
        .catch(function (error) {
          console.log("campaignEntityMapping Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

/////////// Coupon Module Actions ///////////

export const getCouponList = (requestParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = ADMIN_SEARCH_COUPON;

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const authData = { ...requestParam, ...defaultParam };

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("Get Coupon List Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "couponList"));
        })
        .catch(function (error) {
          console.log("Get Coupon List Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const addNewCoupon = (requestParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = ADMIN_CREATE_COUPON;

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const authData = { ...requestParam, ...defaultParam };

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("add Coupon Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "coupon"));
        })
        .catch(function (error) {
          console.log("add Coupon Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const fetchCoupon = (requestParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(getRequestHeaders()).then((config) => {
      const URL = ADMIN_FETCH_COUPON_BY_ID + requestParam.id;

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const authData = { ...requestParam, ...defaultParam };

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("fetch Coupon Success Executed", response.data);
          if (requestParam.isDetailView) {
            dispatch(setResponseValues(SUCCESS, response.data, null, "couponDetails"));
          } else {
            dispatch(setResponseValues(SUCCESS, response.data, null, "coupon"));
          }
        })
        .catch(function (error) {
          console.log("fetch campaign Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const updateCoupon = (requestParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = ADMIN_UPDATE_COUPON;

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const authData = { ...requestParam, ...defaultParam };

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("update Coupon Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "coupon"));
        })
        .catch(function (error) {
          console.log("update Coupon Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const addUpdateCouponMedia = (reqParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...defaultParam, ...reqParam };
      console.log("cong", config);
      const URL = ADMIN_UPDATE_COUPON_MEDIA;
      axios
        .post(URL, postData, config)
        .then((response) => {
          console.log("Coupon ADD Media/activate/deactivate Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(clearMediaList());
          dispatch(clearMediaObject());
        })
        .catch(function (error) {
          console.log("Coupon activate Catch", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const activateDeactivateCoupon = (reqParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...reqParam, ...defaultParam };

      const URL = ADMIN_UPDATE_COUPON_STATUS;

      axios
        .post(URL, postData, config)
        .then((response) => {
          console.log("Category activate Deactivate Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "coupon"));
        })
        .catch(function (error) {
          console.log("Coupon activate Deactivate Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const couponEntityMapping = (reqParam) => {
  return (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...reqParam, ...defaultParam };

      const URL = ADMIN_COUPON_ENTITY_MAPPING;

      axios
        .post(URL, postData, config)
        .then((response) => {
          console.log("Coupon EntityMapping Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null, "coupon"));
        })
        .catch(function (error) {
          console.log("Coupon EntityMapping Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

/////////// BRAND Module Actions ///////////

/** Listing of brands in admin console /brands */
export const searchBrand = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    const postData = { ...reqParam };
    try {
      const config = await dispatch(getRequestHeaders());
      const response = await axios.post(SEARCH_BRAND, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "brandList"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add/update brands in admin console /brands */
export const addUpdateBrand = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };
    const postData = { ...defaultParam, ...reqParam };
    try {
      const config = await dispatch(getRequestHeaders());
      const response = await axios.post(ADD_BRAND, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "brandDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Activate/deactivate brand media in admin console /brands */
export const addUpdateBrandMedia = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };
    const postData = { ...defaultParam, ...reqParam };
    try {
      const config = await dispatch(getRequestHeaders());
      const response = await axios.post(ADMIN_UPDATE_BRAND_MEDIA, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "brandDetail"));
      dispatch(clearMediaList());
      dispatch(clearMediaObject());
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/////////// REQUEST Module Actions ///////////

/** Listing of requests in admin console /requests */
export const searchRequest = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };
    const postData = { ...defaultParam, ...reqParam };

    try {
      const config = await dispatch(getRequestHeaders());
      const response = await axios.post(SEARCH_REQUEST, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "requestList"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Get single request detail /request/{id} */
export const getRequestDetail = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };
    const postData = { ...defaultParam, ...reqParam };
    const URL = FETCH_REQUEST + "id?term=" + reqParam.id;

    try {
      const config = await dispatch(getRequestHeaders());
      const response = await axios.post(URL, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "requestDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update request, post comment, activate, close, open actions  */
export const addUpdateRequest = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };
    const postData = { ...defaultParam, ...reqParam };

    try {
      const config = await dispatch(getRequestHeaders());
      const response = await axios.post(CREATE_REQUEST, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "requestDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const addUpdateRequestMedia = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    try {
      const config = await dispatch(getRequestHeaders());

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...defaultParam, ...reqParam };
      const response = await axios.post(ADD_UPDATE_REQUEST_MEDIA, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "requestDetail"));
      dispatch(clearMediaList());
      dispatch(clearMediaObject());
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/////////// Trending Module Actions ///////////

/** Listing of trends in admin console /requests */
export const searchTrend = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };
    const postData = { ...defaultParam, ...reqParam };

    try {
      const config = await dispatch(getRequestHeaders());
      const response = await axios.post(SEARCH_TRENDS, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "trendList"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const fetchTrend = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    try {
      const config = await dispatch(getRequestHeaders());

      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...defaultParam, ...reqParam };
      const response = await axios.post(FETCH_TRENDS + reqParam.trendId, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "trendDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const addUpdateTrend = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
        supplierUserId: getState().auth.userId,
      };

      const URL = reqParam.type === "status" ? UPDATE_TRENDS_STATUS : ADD_UPDATE_TRENDS;

      const postData = { ...defaultParam, ...reqParam };
      const response = await axios.post(URL, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "trendDetail"));
      dispatch(clearMediaList());
      dispatch(clearMediaObject());
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update SKU and mark as default from admin */
export const markDefaultSKU = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(productResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const URL = MARK_DEFAULT_VARIANT_SKU;

      const postData = { ...reqParam, ...defaultParam };
      const response = await axios.post(URL, postData, config);

      console.log("mark default sku Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "productInfo"));
      /** Clear error from common reducer if any */
      dispatch(saveResponseMessage(productResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(productResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Fetch similar product list */
export const fetchSimilarProduct = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(resetObjectValues("similarProducts"));
    dispatch(clearMessage(productResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());
      const { userType, userId } = getState().auth;
      const defaultParam = { userType, userId };

      const postData = { ...reqParam, ...defaultParam };

      let URL = ADMIN_SIMILAR_PRODUCT + reqParam.productId;

      if (reqParam.variantId) {
        URL = URL + "/variant/" + reqParam.variantId;
      }

      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "similarProducts"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      // if (error?.response?.data) {
      //   dispatch(
      //     saveResponseMessage(productResponseMessageKey, error.response.data)
      //   );
      // }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

//////////////// Customer APIS ////////////////

/** Update customer status from admin (ACTIVATE/DEACTIVATE, VERIFY, UNVERIFY) */

export const updateCustomerStatus = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(customerResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};

      const URL = UPDATE_CUSTOMER_STATUS + reqParam.type + "/" + reqParam.status + "/customer";

      const postData = { ...defaultParam, ...reqParam };
      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "customerDetail"));
      /** Clear error from common reducer if any */
      dispatch(saveResponseMessage(customerResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(customerResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

//////////////// ADMIN OPS APIS ////////////////

/** List Admin from admin */
export const listAdmin = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(adminResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const { userId, userType } = getState().auth;
      const defaultParam = { userId, userType, ...defaultParams() };

      let URL = userType === "ADMIN" || userType === "OPS" ? LIST_ADMIN : LIST_USER;

      const postData = { ...defaultParam, ...reqParam };
      const response = await axios.post(URL, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "adminList"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(adminResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Detail Admin from admin */
export const detailAdmin = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(adminResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const { userType } = getState().auth;
      const defaultParam = { ...defaultParams() };

      let URL = (userType === "ADMIN" || userType === "OPS" ? FETCH_ADMIN : FETCH_USER) + reqParam.userId;

      const postData = { ...defaultParam, ...reqParam };
      const response = await axios.post(URL, postData, config);
      dispatch(setResponseValues(SUCCESS, response.data, null, "adminDetail"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(adminResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Create Admin from admin */
export const createAdmin = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(adminResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());

      const { userType } = getState().auth;

      const defaultParam = { ...defaultParams() };

      let URL = userType === "ADMIN" || userType === "OPS" ? CREATE_ADMIN : CREATE_USER;

      const postData = { ...defaultParam, ...reqParam };
      await axios.post(URL, postData, config);

      /** Clear error from common reducer if any */
      dispatch(saveResponseMessage(adminResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(adminResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update Admin status from admin (ACTIVATE/DEACTIVATE/CHANGEPASSWORD) */
export const updateAdmin = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(adminResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const { userType } = getState().auth;

      const defaultParam = { ...defaultParams() };

      let URL = null;

      if (reqParam.type === "activate") {
        URL = userType === "ADMIN" || userType === "OPS" ? UPDATE_ADMIN_ACTIVATE : UPDATE_USER_ACTIVATE;
      } else if (reqParam.type === "deactivate") {
        URL = userType === "ADMIN" || userType === "OPS" ? UPDATE_ADMIN_DEACTIVATE : UPDATE_USER_DEACTIVATE;
      } else if (reqParam.type === "changepassword") {
        URL = userType === "ADMIN" || userType === "OPS" ? UPDATE_ADMIN_CHANGE_PASSWORD : UPDATE_USER_CHANGE_PASSWORD;
      }

      const postData = { ...defaultParam, ...reqParam };
      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "adminDetail"));
      /** Clear error from common reducer if any */
      dispatch(saveResponseMessage(adminResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(adminResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};
