import React from "react";

class OtpCountdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      running: false
    };
  }

  componentDidMount() {
    const strSeconds = parseInt(this.props.time, 10);
    this.setState({ count: strSeconds, running: true });
  }

  componentWillUnmount() {
    this.handleStop();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.running !== prevState.running) {
      switch (this.state.running) {
        case true:
          this.handleStart();
          break;
        default:
          console.log(this.state);
      }
    }

    if (this.state.count === 0 && this.state.running) {
      //console.log("stopped");
      this.handleStop();
    }
  }

  handleStart() {
    this.timer = setInterval(() => {
      const newCount = this.state.count - 1;
      this.setState({ count: newCount >= 0 ? newCount : 0 });
    }, 1000);
  }

  handleStop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.setState({ running: false });
      this.props.onStop();
    }
  }

  handleReset() {
    this.setState({ count: 0 });
  }

  handleCountdown(seconds) {
    this.setState({
      count: seconds,
      running: true
    });
  }

  format(time) {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
    return minutes + ":" + seconds;
  }

  render() {
    const { count, running } = this.state;
    return running ? <span>{this.format(count)}</span> : "";
  }
}
export default OtpCountdown;
