import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import uniqid from "uniqid";
import Dropzone from "../../components/Dropzone/Dropzone";

import { uploadBaseImages } from "../../datautils/actions/skuActions";
import CropperModal from "./CropperModal";

const ImageCropper = (props) => {
  const [files, setFiles] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [show, setShow] = useState(false);

  const onDrop = (acceptedFiles) => {
    const newObj = {};
    acceptedFiles.forEach((file) => {
      newObj[uniqid()] = { original: file, base64: null, cropped: null, uploaded: null };
    });
    setFiles({ ...files, ...newObj });
    setShow(true);
  };

  const totalFiles = Object.keys(files).length;
  const totalCropped = Object.keys(files).filter((f) => f.cropped);

  return (
    <>
      {show && (
        <CropperModal
          aspect={3 / 5}
          show={show}
          setShow={(show) => setShow(!show)}
          files={files}
          setFiles={(files) => setFiles(files)}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={(files) => setUploadedFiles([...uploadedFiles, ...files])}
        />
      )}
      <Row>
        <Col xs="12">
          <div className="bg-white p-4 card-default mb-4">
            <Row>
              <Col md="5 mt-3">
                <Dropzone onFilesAdded={(opt) => onDrop(opt)} />
              </Col>
            </Row>

            <Row style={{ padding: 10, minHeight: 100 }}>
              {Object.keys(files).map((key) => {
                const file = files[key];
                return file.cropped ? (
                  <div key={`cropped-${key}`}>
                    <img alt="" className="img-fluid m-2 border" src={file.cropped} style={{ maxHeight: 100 }} />
                  </div>
                ) : null;
              })}
            </Row>

            {Object.keys(files).length > 0 ? (
              <Button size={"sm"} color="primary" onClick={() => setShow(true)}>
                Edit Images
              </Button>
            ) : null}

            {totalFiles > 0 && totalFiles === totalCropped?.length ? (
              <Button size={"sm"} color="primary" onClick={() => setShow(true)}>
                Save Images
              </Button>
            ) : null}

            {uploadedFiles?.length ? (
              <>
                <p className="mb-0 mt-4">Files uploaded on server</p>
                <Row style={{ padding: 10, minHeight: 100 }}>
                  {Object.keys(files).map((key) => {
                    const file = files[key];
                    return file.uploaded ? (
                      <div key={`fileid-${key}`}>
                        <img alt="" className="img-fluid m-2 border" src={file.cropped} style={{ maxHeight: 100 }} />
                      </div>
                    ) : null;
                  })}
                </Row>
              </>
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadBaseImages: (acceptedFile) => dispatch(uploadBaseImages(acceptedFile)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropper);
