import { startUILoading, stopUILoading } from "./uiActions";
import { setResponseValues, resetResponseValues } from "./responseHandlerActions";

import axios from "axios";
import {
  CHANGE_PASSWORD_API_URL,
  SUPPLIER_GET_PROFILE_API_URL,
  SUPPLIER_UPDATE_PROFILE_API_URL,
  SUPPLIER_GET_KYC_API_URL,
  SUPPLIER_UPDATE_KYC_API_URL,
  SUPPLIER_GET_BANK_INFO_API_URL,
  SUPPLIER_UPDATE_BANK_INFO_API_URL,
  SUPPLIER_GET_ADDRESS_API_URL,
  SUPPLIER_UPDATE_ADDRESS_API_URL,
  SUPPLIER_GET_CONTACT_API_URL,
  SUPPLIER_UPDATE_CONTACT_API_URL,
  SUPPLIER_GET_PROFILE_BY_USER_ID,
} from "../../utils/constants/apiUrls";

import { SUCCESS, FAILURE } from "../../utils/constants";
import {
  SAVE_PROFILE,
  SAVE_KYC,
  UPDATE_KYC,
  SAVE_BANK,
  UPDATE_BANK,
  SAVE_ADDRESS,
  UPDATE_ADDRESS,
  SAVE_CONTACT,
  UPDATE_CONTACT,
  CLEAR_STORE,
  CLEAR_PROFILE,
} from "./actionTypes";

import { getRequestHeaders, userLogout } from "./authActions";
import { uploadMedia } from "./mediaActions";
import { store } from "../../config/store/index";

export const changePassword = (authData) => {
  return (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(getRequestHeaders()).then((config) => {
      axios
        .post(CHANGE_PASSWORD_API_URL, authData, config)
        .then((response) => {
          console.log("Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(userLogout());
        })
        .catch(function (error) {
          console.log("Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const getProfile = (authData) => {
  return (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      let URL = "";
      if (authData.sUserId) {
        URL = SUPPLIER_GET_PROFILE_BY_USER_ID + authData.sUserId;
      } else if (authData.supplierUserId) {
        URL = SUPPLIER_GET_PROFILE_API_URL + authData.supplierUserId;
      } else {
        URL = SUPPLIER_GET_PROFILE_API_URL + authData.userId;
      }
      URL = URL + "?type=FULL";
      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("Get Profile Success Executed", response.data);
          if (authData.sUserId) {
            dispatch(setResponseValues(SUCCESS, response.data, null, "supplierDetail"));
          } else {
            dispatch(setResponseValues(SUCCESS, response.data, null));
            dispatch(storeProfileInfo(response.data));
          }

          const supplierUid = response.data?.data?.profileInfo?.userId;

          if (supplierUid && authData.fetchPickupAddress) {
            dispatch(getAddress({ supplierUserId: supplierUid }));
          }
        })
        .catch(function (error) {
          console.log("Get Profile Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const updateProfile = (authData) => {
  return (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      const URL = SUPPLIER_UPDATE_PROFILE_API_URL;

      const defaultParam = {
        mobileNumber: store.getState().auth.mobileNumber,
        mobileCountryCode: "+91",
      };

      const postData = { ...authData, ...defaultParam };

      axios
        .post(URL, postData, config)
        .then((response) => {
          console.log("Update profile Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(storeProfileInfo(response.data));
        })
        .catch(function (error) {
          console.log("Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const getKyc = (authData) => {
  return (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearStore("kyc"));

    dispatch(getRequestHeaders()).then((config) => {
      let URL = SUPPLIER_GET_KYC_API_URL;

      authData.supplierUserId ? (URL = URL + authData.supplierUserId) : (URL = URL + authData.userId);

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("Get KYC Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(storeKycInfo(response.data));
        })
        .catch(function (error) {
          console.log("Get Kyc Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const updateKyc = (authData, file) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    try {
      const fileId = (!file && authData.fileId) || (await dispatch(uploadMedia(file, {})));

      try {
        const config = await dispatch(getRequestHeaders());

        authData.fileId = fileId;

        const URL = SUPPLIER_UPDATE_KYC_API_URL;

        const response = await axios.post(URL, authData, config);

        dispatch(setResponseValues(SUCCESS, response.data, null));
        dispatch(updateKycInfo(authData.documentType, response.data));
      } catch (error) {
        dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
      } finally {
        dispatch(stopUILoading());
      }
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const getBankInfo = (authData) => {
  return (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearStore("bank"));

    dispatch(getRequestHeaders()).then((config) => {
      let URL = SUPPLIER_GET_BANK_INFO_API_URL;

      authData.supplierUserId ? (URL = URL + authData.supplierUserId) : (URL = URL + authData.userId);

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("Bank Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(storeBankInfo(response.data));
        })
        .catch(function (error) {
          console.log("Bank Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Bank Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const updateBankInfo = (authData, file) => {
  return (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      dispatch(uploadMedia(file))
        .then((fileId) => {
          fileId
            ? (authData.cancelledChequeFileFileId = fileId)
            : (authData.cancelledChequeFileFileId = authData.fileId);

          const URL = SUPPLIER_UPDATE_BANK_INFO_API_URL;

          axios
            .post(URL, authData, config)
            .then((response) => {
              dispatch(setResponseValues(SUCCESS, response.data, null));
              dispatch(updateBankKyc(response.data));
            })
            .catch(function (error) {
              console.log("Catch Executed", error);
              if (error.response) {
                dispatch(setResponseValues(FAILURE, null, [error.response.data]));
              } else {
                dispatch(setResponseValues(FAILURE, null, [{ message: "Something went wrong, please try again!" }]));
              }
            })
            .then(function () {
              // always executed
              console.log("Final Executed");
              dispatch(stopUILoading());
            });
        })
        .catch(function (error) {
          console.log("Catch Executed Uploading file", error);
        });
    });
  };
};

export const getAddress = (authData) => {
  return (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearStore("address"));

    dispatch(getRequestHeaders()).then((config) => {
      let URL = SUPPLIER_GET_ADDRESS_API_URL;

      authData.supplierUserId ? (URL = URL + authData.supplierUserId) : (URL = URL + authData.userId);

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("Get getAddress Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(storeAddressInfo(response.data));
        })
        .catch(function (error) {
          console.log("Get getAddress Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const updateAddress = (authData, file) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    try {
      const fileId = (await dispatch(uploadMedia(file))) || authData.gstFileId;
      authData.gstFileId = fileId;
      const config = await dispatch(getRequestHeaders());
      const response = await axios.post(SUPPLIER_UPDATE_ADDRESS_API_URL, authData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null));
      //dispatch(updateAddressInfo(authData.addressType, response.data));
      dispatch(getAddress({ userId: authData.userId }));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const getContact = (authData) => {
  return (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearStore("contact"));

    dispatch(getRequestHeaders()).then((config) => {
      let URL = SUPPLIER_GET_CONTACT_API_URL;

      authData.supplierUserId ? (URL = URL + authData.supplierUserId) : (URL = URL + authData.userId);

      axios
        .post(URL, authData, config)
        .then((response) => {
          console.log("Get getContact Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(storeContactInfo(response.data));
        })
        .catch(function (error) {
          console.log("Get getContact Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const updateContact = (authData) => {
  return (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    dispatch(getRequestHeaders()).then((config) => {
      axios
        .post(SUPPLIER_UPDATE_CONTACT_API_URL, authData, config)
        .then((response) => {
          console.log("Success Executed", response.data);
          dispatch(setResponseValues(SUCCESS, response.data, null));
          dispatch(updateContactInfo(authData.contactType, response.data));
        })
        .catch(function (error) {
          console.log("Catch Executed", error);
          dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
        })
        .then(function () {
          // always executed
          console.log("Final Executed");
          dispatch(stopUILoading());
        });
    });
  };
};

export const storeProfileInfo = (data) => {
  return {
    type: SAVE_PROFILE,
    data: data.data,
  };
};
export const clearProfile = (data) => {
  return {
    type: CLEAR_PROFILE,
  };
};

export const clearStore = (data) => {
  return {
    type: CLEAR_STORE,
    data: data,
  };
};

export const storeKycInfo = (data) => {
  return {
    type: SAVE_KYC,
    data: data.data,
  };
};

export const updateKycInfo = (documentType, data) => {
  return {
    type: UPDATE_KYC,
    documentType: documentType,
    data: data.data.kycDetailVO,
    workflow: data.data.workflow,
  };
};

export const storeBankInfo = (data) => {
  return {
    type: SAVE_BANK,
    data: data.data.bankAccountVO,
  };
};
export const updateBankKyc = (data) => {
  return {
    type: UPDATE_BANK,
    data: data.data.bankAccountVO,
    workflow: data.data.workflow,
  };
};

export const storeAddressInfo = (data) => {
  return {
    type: SAVE_ADDRESS,
    data: data.data,
  };
};
export const updateAddressInfo = (documentType, data) => {
  return {
    type: UPDATE_ADDRESS,
    documentType: documentType,
    data: data.data.addressVO,
    workflow: data.data.workflow,
  };
};

export const storeContactInfo = (data) => {
  return {
    type: SAVE_CONTACT,
    data: data.data,
  };
};

export const updateContactInfo = (documentType, data) => {
  return {
    type: UPDATE_CONTACT,
    documentType: documentType,
    data: data.data.contactVO,
    workflow: data.data.workflow,
  };
};
