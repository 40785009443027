import {
  SAVE_ORDER_REQUEST_LIST,
  SAVE_ORDER_REQUEST,
  CLEAR_ALL_ORDER_REQUESTS,
  CLEAR_ORDER_REQUEST,
  SAVE_ORDER_REQUEST_REPLY,
  CLEAR_ORDER_REQUEST_REPLY,
} from "../actions/actionTypes";
import axios from "axios";

import {
  CREATE_UPDATE_ORDER_REQUEST,
  SEARCH_ORDER_REQUEST_ADMIN,
  FETCH_ORDER_REQUEST_ADMIN,
  REPLY_ORDER_REQUEST,
  SEARCH_ORDER_REQUEST_SUPPLIER,
  FETCH_ORDER_REQUEST_SUPPLIER,
  FETCH_ORDER_REQUEST_REPLY,
  ADD_ORDER_REQUEST_REPLY_MESSAGE,
  ORDER_REQUEST_PROPOSE_SKU,
  ORDER_REQUEST_CREATE_BARGAIN,
  ORDER_REQUEST_ADD_SUPPLIER,
  ORDER_REQUEST_REMOVE_SUPPLIER,
  UPDATE_ORDER_REQUEST_STATUS,
  UPDATE_ORDER_REQUEST_REPLY_STATUS,
} from "../../utils/constants/apiUrls";

import { startUILoading, stopUILoading } from "./uiActions";
import { getRequestHeaders } from "./authActions";
import { uploadMedia } from "./mediaActions";
import { saveResponseMessage, clearMessage } from "./responseMessageActions";

/** Listing of order requests in app  */
export const searchOrderRequest = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));
    const postData = { ...reqParam };
    try {
      const config = await dispatch(getRequestHeaders());

      const { userType } = getState().auth;

      const URL = userType === "SUPPLIER" ? SEARCH_ORDER_REQUEST_SUPPLIER : SEARCH_ORDER_REQUEST_ADMIN;
      const response = await axios.post(URL, postData, config);

      dispatch(saveAllRequests(response.data));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Get single order request detail */
export const fetchOrderRequest = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));
    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };
    const postData = { ...defaultParam, ...reqParam };

    const URL =
      (userType === "SUPPLIER" ? FETCH_ORDER_REQUEST_SUPPLIER : FETCH_ORDER_REQUEST_ADMIN) + reqParam.requestId;

    try {
      const config = await dispatch(getRequestHeaders());
      const response = await axios.post(URL, postData, config);

      dispatch(saveRequest(response.data.data));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update request, post comment, activate, close, open actions  */
export const addUpdateOrderRequest = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));

    try {
      const config = await dispatch(getRequestHeaders());

      let media = [];

      async function processArray(fileData) {
        await Promise.all(
          fileData.map(async (item) => {
            const fileId = await dispatch(uploadMedia(item));
            if (fileId) {
              media.push({ fileId: fileId, icon: false });
            }
          })
        );
      }

      if (reqParam.fileData && reqParam.fileData.length) {
        await processArray(reqParam.fileData);
      }

      const paramToSend = {
        demand: { ...reqParam, fileData: null, medias: [...media] },
      };

      let URL = CREATE_UPDATE_ORDER_REQUEST;
      if (reqParam.action === "updateStatus") {
        URL = UPDATE_ORDER_REQUEST_STATUS;
      }

      await axios.post(URL, paramToSend, config);

      dispatch(saveResponseMessage("orderrequest", { hasErrors: false }));

      if (reqParam.requestId) {
        dispatch(fetchOrderRequest({ requestId: reqParam.requestId }));
      }
    } catch (error) {
      console.log("ERROR in creating order request", error);
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Get single order request detail */
export const replyOrderRequest = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));
    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };

    const URL = REPLY_ORDER_REQUEST;

    try {
      const config = await dispatch(getRequestHeaders());

      let media = [];

      async function processArray(fileData) {
        await Promise.all(
          fileData.map(async (item) => {
            const fileId = await dispatch(uploadMedia(item, { messageKey: "orderrequest" }));
            if (fileId) {
              media.push({ fileId: fileId, icon: false });
            }
          })
        );
      }

      if (reqParam.fileData && reqParam.fileData.length) {
        await processArray(reqParam.fileData);
      }

      const paramToSend = {
        ...defaultParam,
        reply: { ...reqParam, fileData: null, medias: [...media] },
      };

      await axios.post(URL, paramToSend, config);

      dispatch(fetchOrderRequest({ requestId: reqParam.demandId }));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Get single proposal detail */
export const fetchOrderRequestProposal = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));
    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };
    const postData = { ...defaultParam, ...reqParam };
    const URL = FETCH_ORDER_REQUEST_REPLY + reqParam.requestId + "/" + reqParam.replyId;

    try {
      const config = await dispatch(getRequestHeaders());
      const response = await axios.post(URL, postData, config);

      dispatch(saveRequestReply(response.data));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add message to single proposal */
export const addOrderRequestReply = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));
    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };

    const URL = ADD_ORDER_REQUEST_REPLY_MESSAGE;

    try {
      const config = await dispatch(getRequestHeaders());

      let media = {};

      async function processArray(fileData) {
        await Promise.all(
          fileData.map(async (item) => {
            const fileId = await dispatch(uploadMedia(item));
            if (fileId) {
              media[fileId] = { fileId: fileId, icon: false };
            }
          })
        );
      }

      if (reqParam.fileData && reqParam.fileData.length) {
        await processArray(reqParam.fileData);
      }

      const paramToSend = {
        ...defaultParam,
        reply: {
          ...reqParam,
          userComment: { ...reqParam.userComment, medias: { ...media } },
          fileData: null,
        },
      };

      await axios.post(URL, paramToSend, config);

      // dispatch(
      //   fetchOrderRequestProposal({
      //     requestId: reqParam.demandId,
      //     replyId: reqParam.replyId,
      //   })
      // );

      dispatch(
        fetchOrderRequest({
          requestId: reqParam.demandId,
          replyId: reqParam.replyId,
        })
      );
    } catch (error) {
      console.log("EEE", error);
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update single proposal */
export const updateOrderRequestProposal = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));
    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };
    const postData = { ...defaultParam, ...reqParam };

    const URL = UPDATE_ORDER_REQUEST_REPLY_STATUS;

    try {
      const config = await dispatch(getRequestHeaders());
      await axios.post(URL, postData, config);

      if (reqParam.reply) {
        dispatch(
          fetchOrderRequest({
            requestId: reqParam.reply.demandId,
            replyId: reqParam.reply.replyId,
          })
        );
      }
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Propose SKU to single proposal */
export const orderRequestProposeSku = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));
    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };

    const URL = ORDER_REQUEST_PROPOSE_SKU;

    try {
      const config = await dispatch(getRequestHeaders());

      let paramToSend = {
        ...defaultParam,
        ...reqParam,
      };

      await axios.post(URL, paramToSend, config);

      dispatch(
        fetchOrderRequest({
          requestId: reqParam.reply.demandId,
          replyId: reqParam.reply.replyId,
        })
      );
      dispatch(
        saveResponseMessage("orderrequest", {
          hasErrors: false,
          userDisplayMsg: "Product suggestion sent",
        })
      );
    } catch (error) {
      console.log("ERROR", error);
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Attach supplier to single proposal */
export const orderRequestAttachSupplier = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));
    const { userId, userType } = getState().auth;
    const defaultParam = { userId, userType };

    const URL = reqParam.action === "add" ? ORDER_REQUEST_ADD_SUPPLIER : ORDER_REQUEST_REMOVE_SUPPLIER;

    try {
      const config = await dispatch(getRequestHeaders());

      let paramToSend = {
        ...defaultParam,
        ...reqParam,
      };

      const response = await axios.post(URL, paramToSend, config);
      if (reqParam.demand) {
        dispatch(
          fetchOrderRequest({
            requestId: reqParam.demand.demandId,
            replyId: reqParam.demand.replyId,
          })
        );
      }
      const userDisplayMsg = reqParam.action === "add" ? "Proposal sent" : "Proposal withdrawn";
      dispatch(
        saveResponseMessage("orderrequest", {
          hasErrors: false,
          userDisplayMsg,
        })
      );
      return response.data;
    } catch (error) {
      console.log("ERROR", error);
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const addUpdateBargainOffer = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("orderrequest"));
    try {
      const config = await dispatch(getRequestHeaders());

      const { userId, userType } = getState().auth;

      const defaultParam = {
        userId,
        userType,
        ...reqParam,
      };

      const response = await axios.post(ORDER_REQUEST_CREATE_BARGAIN, defaultParam, config);
      dispatch(
        saveResponseMessage("orderrequest", {
          hasErrors: false,
          userDisplayMsg: "Bargain created",
        })
      );
      return response.data ? true : false;
    } catch (error) {
      console.log("ERROR", error);
      if (error?.response?.data) {
        dispatch(saveResponseMessage("orderrequest", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const saveAllRequests = (data) => {
  return {
    type: SAVE_ORDER_REQUEST_LIST,
    data: data.data,
  };
};

export const saveRequest = (data) => {
  return {
    type: SAVE_ORDER_REQUEST,
    data: data,
  };
};

export const saveRequestReply = (data) => {
  return {
    type: SAVE_ORDER_REQUEST_REPLY,
    data: data,
  };
};

export const clearAllRequests = () => {
  return {
    type: CLEAR_ALL_ORDER_REQUESTS,
  };
};

export const clearRequest = () => {
  return {
    type: CLEAR_ORDER_REQUEST,
  };
};

export const clearRequestReply = () => {
  return {
    type: CLEAR_ORDER_REQUEST_REPLY,
  };
};
