import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { store } from "../../config/store/index";
import axios from "axios";
import { osName, osVersion, browserName, browserVersion, getUA } from "react-device-detect";
import packageJson from "../../../package.json";
import authReducer from "./authReducer";
import uiReducer from "./uiReducer";
import responseReducer from "./responseHandlerReducer";
import userReducer from "./userReducer";
import skuReducer from "./skuReducer";
import locationReducer from "./locationReducer";
import sampleReducer from "./sampleRequestsReducer";
import customReducer from "./customRequestsReducer";
import responseMessageReducer from "./responseMessageReducer";
import billBookReducer from "./billBookReducer";
import statsReducer from "./statsReducer";
import orderRequestReducer from "./orderRequestReducer";
import tempSkuReducer from "./tempSkuReducer";

import { userLogout } from "../actions/authActions";
import { saveResponseMessage } from "../actions/responseMessageActions";

axios.interceptors.request.use((config) => {
  let cparam = [];
  cparam.push("apptype##web");
  cparam.push("platform##web");
  try {
    cparam.push(`platformversion##${osVersion}`);
    cparam.push(`resolution##${window.innerWidth}x${window.innerHeight}`);
    cparam.push(`mobilebrand##${browserName}" "${browserVersion}`);
    cparam.push(`mobilemodel##${osName}`);
    cparam.push(`deviceid##${getUA}`);
    cparam.push(`clientversion##${packageJson.version}`);
    cparam.push(`timezone##${Intl.DateTimeFormat().resolvedOptions().timeZone}`);
  } catch (error) {}
  config.headers["cparam"] = cparam.join("::");
  config.timeout = 1000 * 30;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("Inside Interceptor", error);
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch(userLogout());
        return Promise.reject(error);
      } else if (error.response.status === 403 || error.response.status === 412) {
        return Promise.reject(error);
      } else {
        store.dispatch(
          saveResponseMessage("commonerror", {
            hasErrors: true,
            userDisplayMsg: "Something went wrong, Try again",
          })
        );
        return Promise.reject(error);
      }
    } else {
      store.dispatch(
        saveResponseMessage("commonerror", {
          hasErrors: true,
          userDisplayMsg: "Something went wrong, Try again",
        })
      );
      return Promise.reject(error);
    }
  }
);

const appReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  resHandlerVal: responseReducer,
  profile: userReducer,
  location: locationReducer,
  categories: skuReducer,
  sampleItems: sampleReducer,
  customOfferItems: customReducer,
  responseMessages: responseMessageReducer,
  billBookItems: billBookReducer,
  statsItems: statsReducer,
  orderRequestItems: orderRequestReducer,
  tempSkuItems: tempSkuReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    state = undefined;
    window.location.href = "/";
  }
  return appReducer(state, action);
};

export default rootReducer;
