import React from "react";
import { Row, Col, Card, CardBody, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody } from "reactstrap";

import { connect } from "react-redux";

import { updateAdmin, detailAdmin } from "../../datautils/actions/adminActions";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import { fetchUserActivity, saveUserActivity } from "../../datautils/actions/statsActions";
import moment from "moment";

import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { PER_PAGE } from "../../utils/constants/index";
import MessageBlock from "../../components/MessageBlock/MessageBlock";

import Activities from "../../components/Activities";

class SupplierUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal2: false,
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "userId",
      ascending: false,
      id: 0,
      notes: "",
      currentAction: "",
      currentActionType: "",
      currentTitle: "",
      userId: null,
      userObject: null,
      password: "",
    };
  }

  componentDidMount() {
    this.props.resetResponseValues();
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    const { responseVal } = this.props;
    const { isUpdated, modal2, userId } = this.state;

    if (this.state.id !== id) {
      this.setState({ id, isUpdated: false });
      this.props.detailAdmin({ userId: id });
    }

    const profileInfo =
      responseVal.dObjectData && responseVal.dObjectData.adminDetail && responseVal.dObjectData.adminDetail.user;

    if (!userId && profileInfo) {
      this.setState({ userId: profileInfo.userId, userObject: profileInfo });
    }

    if (responseVal.status === "success" && isUpdated && modal2) {
      this.setState({ modal2: false });
    }
  }

  componentWillUnmount() {
    this.props.saveUserActivity(null);
  }

  toggle2 = (requestData) => {
    if (this.state.modal2) {
      this.setState({
        modal2: false,
        notes: "",
        currentTitle: "",
        currentAction: "",
        currentActionType: "",
        password: "",
      });
    } else {
      this.setState({
        modal2: true,
        notes: "",
        currentTitle: requestData.title,
        currentAction: requestData.action,
        currentActionType: requestData.actionType,
        isUpdated: false,
      });
      this.props.resetResponseValues();
    }
  };

  handleChangeNotes = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  fetchActivity = (p) => {
    const reqData = {
      userId: this.state.userId,
      paginationOptions: {
        pageNumber: p,
        pageSize: PER_PAGE,
        sortOptions: [{ sortField: "createdOn", ascending: false }],
      },
    };
    this.props.fetchUserActivity({ ...reqData });
  };

  enableDisable = (e) => {
    const { notes, modal2, id, password, userObject } = this.state;

    if (modal2) {
      const reqData = {
        ...userObject,
        type: e.target.dataset.type,
        status: e.target.value,
        userId: id,
        notes,
        password,
      };
      this.props.updateAdmin(reqData);
      this.setState({ isUpdated: true });
    } else {
      this.toggle2({
        actionType: e.target.dataset.type,
        action: e.target.value,
        title: e.target.value + " User",
      });
    }
  };

  renderModal2 = () => {
    const { notes, currentAction, currentActionType, currentTitle, password } = this.state;

    return (
      <Modal backdrop="static" centered isOpen={this.state.modal2} toggle={this.toggle2}>
        <ModalHeader toggle={this.toggle2}>{currentTitle.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    <Form autoComplete="off">
                      {currentActionType === "changepassword" && (
                        <FormGroup>
                          <Input
                            type="text"
                            placeholder="Enter new password"
                            onChange={this.handleChangeNotes("password")}
                            value={password}
                          />
                        </FormGroup>
                      )}

                      <FormGroup>
                        <Input
                          type="textarea"
                          placeholder="Add notes/reason"
                          onChange={this.handleChangeNotes("notes")}
                          value={notes}
                        />
                      </FormGroup>
                    </Form>

                    {this.renderSwitch(currentActionType, currentAction)}
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  renderSwitch(currentActionType, currentAction) {
    switch (currentActionType) {
      case "activate":
      case "deactivate":
        return (
          <Button
            className="btn-block btn-primary"
            data-type={currentActionType}
            value={currentAction}
            onClick={this.enableDisable}
            disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
          >
            {currentAction} User
          </Button>
        );
      case "changepassword":
        return (
          <Button
            className="btn-block btn-primary"
            data-type={currentActionType}
            value={currentAction}
            onClick={this.enableDisable}
            disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
          >
            Update Password
          </Button>
        );
      default:
        return null;
    }
  }

  render() {
    const { responseVal, isLoading, userActivity } = this.props;
    const { modal2 } = this.state;

    const dataLoaded =
      responseVal.dObjectData && responseVal.dObjectData.adminDetail && responseVal.dObjectData.adminDetail.user;

    const userActivities = userActivity;

    return (
      <div>
        <PanelHeader />
        {modal2 ? this.renderModal2() : null}
        <div className="content mt-3">
          <Row>
            <Col xs={12}>
              <MessageBlock screenName="admin" />
            </Col>

            <Col xs={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-0 pt-0">
                  <li className="breadcrumb-item">
                    <span onClick={() => this.props.history.goBack()}>&laquo; Go Back</span>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    User Details
                  </li>
                </ol>
              </nav>
              <Row>
                <Col sm={12}>
                  {dataLoaded ? (
                    <>
                      <Card className="card--default">
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <h5 className="float-left">
                                {" "}
                                {dataLoaded.title} {dataLoaded.firstName} {dataLoaded.lastName}
                              </h5>
                              <span className="float-right"></span>
                              <div className="clearfix" />
                              <p style={{ borderBottom: "2px solid #eee" }}>
                                <span className="item-ord">
                                  Registered on {moment(dataLoaded.dateCreated).format("DD MMM' YY, hh:mm a")}{" "}
                                </span>
                                <span className="pipe" />
                                <span className="item-ord">
                                  Updated on {moment(dataLoaded.dateModified).format("DD MMM' YY, hh:mm a")}{" "}
                                </span>
                                <span className="pipe" />

                                <span className="item-ord">User# {dataLoaded.userId}</span>
                              </p>
                            </Col>

                            <Col sm={6}>
                              <dl className="row">
                                <dt className="col-sm-3">Name</dt>
                                <dd className="col-sm-9">
                                  {dataLoaded.title} {dataLoaded.firstName} {dataLoaded.lastName} &nbsp;
                                </dd>
                                <dt className="col-sm-3">Gender</dt>
                                <dd className="col-sm-9">
                                  {dataLoaded.gender === "M"
                                    ? "Male"
                                    : dataLoaded.gender === "F"
                                    ? "Female"
                                    : dataLoaded.gender}{" "}
                                  &nbsp;
                                </dd>
                                <dt className="col-sm-3">Mobile</dt>
                                <dd className="col-sm-9">{dataLoaded.mobileNumber}</dd>
                                <dt className="col-sm-3">Email</dt>
                                <dd className="col-sm-9">{dataLoaded.email || "N/A"}</dd>
                              </dl>
                            </Col>
                            <Col
                              sm={3}
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="mb-4">
                                <span>Active: </span>
                                <span>
                                  <span className={"text-" + (dataLoaded.active ? "success" : "danger")}>
                                    {dataLoaded.active ? "YES" : "NO"}
                                  </span>
                                </span>
                              </div>

                              {dataLoaded.active ? (
                                <Button
                                  size="xs"
                                  value="DEACTIVATE"
                                  data-type="deactivate"
                                  className="btn-simple btn-success mt-0 float-right"
                                  onClick={this.enableDisable}
                                >
                                  Deactivate User
                                </Button>
                              ) : (
                                <Button
                                  size="xs"
                                  value="ACTIVATE"
                                  data-type="activate"
                                  className="btn-simple btn-danger mt-0 float-right"
                                  onClick={this.enableDisable}
                                >
                                  Activate User
                                </Button>
                              )}
                            </Col>
                            <Col
                              sm={3}
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="mb-4">
                                <span>&nbsp;</span>
                                <span>
                                  <span className={"text-" + (dataLoaded.active ? "success" : "danger")}>&nbsp;</span>
                                </span>
                              </div>
                              <Button
                                size="xs"
                                value="CHANGE_PASSWORD"
                                data-type="changepassword"
                                className="btn-simple btn-danger mt-0 float-right"
                                onClick={this.enableDisable}
                              >
                                Change Password
                              </Button>
                              {/* <div className="mb-4">
                                <span>Verified: </span>
                                <span>
                                  {dataLoaded.verified ? (
                                    <span className="text-success">YES</span>
                                  ) : (
                                    <span className="text-danger">NO</span>
                                  )}
                                </span>
                              </div>
                              {dataLoaded.verified ? (
                                <Button
                                  size="xs"
                                  value="UNVERIFY"
                                  data-type="v"
                                  className="btn-simple btn-success mt-0 float-right"
                                  onClick={this.enableDisable}
                                >
                                  Unverify User
                                </Button>
                              ) : (
                                <Button
                                  size="xs"
                                  value="VERIFY"
                                  data-type="v"
                                  className="btn-simple btn-danger mt-0 float-right"
                                  onClick={this.enableDisable}
                                >
                                  Verify User
                                </Button>
                              )} */}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody className="listings">
                          <h5 className="float-left">User Activities</h5>
                          <Button
                            size="xs"
                            className="btn-simple btn-danger mt-0 float-right"
                            onClick={() => this.fetchActivity(1)}
                          >
                            Fetch User Activity
                          </Button>

                          {userActivities ? (
                            <Activities userActivities={userActivities} fetchActivity={(p) => this.fetchActivity(p)} />
                          ) : null}
                        </CardBody>
                      </Card>
                    </>
                  ) : null}
                </Col>
                <Col sm={4}></Col>
              </Row>
            </Col>
            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    userActivity: state.statsItems.userActivity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    updateAdmin: (reqData) => dispatch(updateAdmin(reqData)),
    detailAdmin: (reqData) => dispatch(detailAdmin(reqData)),

    fetchUserActivity: (reqData) => dispatch(fetchUserActivity(reqData)),
    saveUserActivity: (reqData) => dispatch(saveUserActivity(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierUser);
