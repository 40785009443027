import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

function pagination(c, m) {
  let current = c,
    last = m,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}

function generatePagination(totalNoOfPages, props) {
  const pages = pagination(props.pageNumber, totalNoOfPages);

  // let items = [];
  // let numPages = totalNoOfPages;
  // if (totalNoOfPages > 10) {
  //   numPages = 10;
  // }
  // for (let i = 1; i <= numPages; i++) {
  //   items.push(
  //     <PaginationItem
  //       key={i}
  //       className={props.pageNumber === i ? "active" : ""}
  //     >
  //       <PaginationLink onClick={props.changePage(i)}>{i}</PaginationLink>
  //     </PaginationItem>
  //   );
  // }
  // return items;

  let items = [];
  pages &&
    pages.map((i, index) => {
      return items.push(
        <PaginationItem key={index} className={props.pageNumber === i ? "active" : ""}>
          <PaginationLink onClick={props.changePage(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    });
  return items;
}

const CustomPagination = function (props) {
  return (
    <Pagination size="md" className="pull-right mb-2 mb-sm-0">
      <PaginationItem>
        <PaginationLink
          previous
          disabled={!props.hasPrevious}
          onClick={props.changePage(props.pageNumber > 1 ? Number(props.pageNumber) - 1 : 1)}
        >
          &laquo; <span className="d-none d-md-inline">Prev</span>
        </PaginationLink>
      </PaginationItem>

      {generatePagination(props.totalNoOfPages, props)}

      <PaginationItem>
        <PaginationLink next disabled={!props.hasNext} onClick={props.changePage(Number(props.pageNumber) + 1)}>
          <span className="d-none d-md-inline">Next</span> &raquo;
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default CustomPagination;
