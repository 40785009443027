import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav, Button } from "reactstrap";

import PerfectScrollbar from "perfect-scrollbar";
import { store } from "../../config/store/index";

import {
  SUPPLIER_BASE,
  SUPPLIER_PROFILE,
  SUPPLIER_SKU_LIST,
  SUPPLIER_SKU_ADD,
  SUPPLIER_REQUEST_LIST,
  SUPPLIER_ORDERS_LIST,
  SUPPLIER_SAMPLE_LIST,
  SUPPLIER_CUSTOM_LIST,
  SUPPLIER_BILL_LIST,
  SUPPLIER_USERS_LIST,
  SUPPLIER_ORDER_REQUEST_LIST,
  ADMIN_BASE,
  ADMIN_SKU_LIST,
  ADMIN_PRODUCT_LIST,
  ADMIN_SUPPLIER_LIST,
  ADMIN_CATEGORIES,
  ADMIN_CAMPAIGN_LIST,
  ADMIN_COUPON_LIST,
  ADMIN_BRAND_LIST,
  ADMIN_REQUEST_LIST,
  ADMIN_ORDERS_LIST,
  ADMIN_CUSTOMER_LIST,
  ADMIN_ATTRIBUTES_LIST,
  ADMIN_TREND_LIST,
  ADMIN_MEGAMENU,
  ADMIN_SAMPLE_LIST,
  ADMIN_CUSTOM_LIST,
  ADMIN_BILL_LIST,
  ADMIN_OPS_USERS_LIST,
  ADMIN_LD_USERS_LIST,
  ADMIN_ORDER_REQUEST_LIST,
  ADMIN_TEMP_SKU_LIST,
} from "../../utils/constants/clientUrls";

import { isRouteAllowed } from "../../utils/helpers/HelperFunctions";

import logo from "../../assets/img/logo-slickpals.png";
import logoWhite from "../../assets/img/slickpals-white.png";
let ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components") !== "" ? true : false,
      openForms: this.activeRoute("/forms") !== "" ? true : false,
      openTables: this.activeRoute("/tables") !== "" ? true : false,
      openMaps: this.activeRoute("/maps") !== "" ? true : false,
      openPages: this.activeRoute("/pages") !== "" ? true : false,
    };
    this.activeRoute.bind(this);
    this.minimizeSidebar = this.minimizeSidebar.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  minimizeSidebar() {
    document.body.classList.toggle("sidebar-mini");
  }

  componentDidMount() {
    //this.minimizeSidebar();
    document.body.classList.add("sidebar-mini");
    //if (navigator.platform.indexOf("Win") > -1) {
    ps = new PerfectScrollbar(this.refs.sidebar, {
      suppressScrollX: true,
      suppressScrollY: false,
    });
    // }
  }

  componentWillUnmount() {
    //if (navigator.platform.indexOf("Win") > -1) {
    ps.destroy();
    // }
    // to stop the warning of calling setState of unmounted component
    let id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  render() {
    const {
      auth: { userType },
    } = store.getState();

    const baseRoute = userType === "SUPPLIER" ? SUPPLIER_BASE : ADMIN_BASE;
    return (
      <div>
        <div className="sidebar" data-color="custom">
          <div className="logo">
            <Link to={baseRoute} className="simple-text logo-mini">
              <div className="logo-img">
                <img src={logo} alt="logo" />
              </div>
            </Link>
            <Link to={baseRoute} className="simple-text logo-normal">
              <img className="main-logo-inner" src={logoWhite} alt="" />
            </Link>
            <div className="navbar-minimize">
              <Button
                style={{ border: 0 }}
                id="minimizeSidebar"
                className="btn-round btn-icon btn-simple btn-neutral"
                onClick={this.minimizeSidebar}
              >
                <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
              </Button>
            </div>
          </div>

          <div className="sidebar-wrapper" ref="sidebar">
            {userType === "SUPPLIER" ? (
              <Nav>
                <li>
                  <NavLink to={baseRoute} className="nav-link dash">
                    <i className="now-ui-icons shopping_shop" />
                    <p>DASHBOARD</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={SUPPLIER_PROFILE} className="nav-link">
                    <i className="now-ui-icons users_circle-08" />
                    <p>MY ACCOUNT</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={SUPPLIER_ORDERS_LIST} className="nav-link">
                    <i className="now-ui-icons shopping_bag-16" />
                    <p>ORDERS</p>
                  </NavLink>
                </li>

                {isRouteAllowed("SUPPLIER_ORDER_REQUEST_LIST") && (
                  <li>
                    <NavLink to={SUPPLIER_ORDER_REQUEST_LIST} className="nav-link">
                      <i className="now-ui-icons users_circle-08" />
                      <p>ORDER REQUESTS</p>
                    </NavLink>
                  </li>
                )}

                <li>
                  <NavLink to={SUPPLIER_BILL_LIST} className="nav-link">
                    <i className="now-ui-icons files_single-copy-04" />
                    <p>BILL BOOK</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={SUPPLIER_SAMPLE_LIST} className="nav-link">
                    <i className="now-ui-icons shopping_basket" />
                    <p>SAMPLE REQUESTS</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={SUPPLIER_CUSTOM_LIST} className="nav-link">
                    <i className="now-ui-icons gestures_tap-01" />
                    <p>BARGAIN REQUESTS</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={SUPPLIER_SKU_LIST} className="nav-link">
                    <i className="now-ui-icons design_bullet-list-67" />
                    <p>MY SKU LIST</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={SUPPLIER_SKU_ADD} className="nav-link">
                    <i className="now-ui-icons ui-1_simple-add" />
                    <p>ADD NEW SKU</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={SUPPLIER_REQUEST_LIST} className="nav-link">
                    <i className="now-ui-icons ui-1_email-85" />
                    <p>TICKETS</p>
                  </NavLink>
                </li>
                {isRouteAllowed("SUPPLIER_USERS_LIST") && (
                  <li>
                    <NavLink to={SUPPLIER_USERS_LIST} className="nav-link">
                      <i className="now-ui-icons users_single-02" />
                      <p>OPS USERS</p>
                    </NavLink>
                  </li>
                )}
              </Nav>
            ) : null}

            {userType === "OPS" || userType === "ADMIN" ? (
              <Nav>
                <li>
                  <NavLink to={baseRoute} className="nav-link dash">
                    <i className="now-ui-icons shopping_shop" />
                    <p>DASHBOARD</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ADMIN_ORDERS_LIST} className="nav-link">
                    <i className="now-ui-icons shopping_bag-16" />
                    <p>ORDERS</p>
                  </NavLink>
                </li>

                {isRouteAllowed("ADMIN_ORDER_REQUEST_LIST") && (
                  <li>
                    <NavLink to={ADMIN_ORDER_REQUEST_LIST} className="nav-link">
                      <i className="now-ui-icons files_box" />
                      <p>ORDER REQUESTS</p>
                    </NavLink>
                  </li>
                )}

                <li>
                  <NavLink to={ADMIN_SKU_LIST} className="nav-link">
                    <i className="now-ui-icons transportation_air-baloon" />
                    <p>SKUS</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ADMIN_PRODUCT_LIST} className="nav-link">
                    <i className="now-ui-icons design_app" />
                    <p>PRODUCTS</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ADMIN_ATTRIBUTES_LIST} className="nav-link">
                    <i className="now-ui-icons clothes_tie-bow" />
                    <p>ATTRIBUTES</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ADMIN_BRAND_LIST} className="nav-link">
                    <i className="now-ui-icons education_hat" />
                    <p>BRANDS</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ADMIN_CUSTOM_LIST} className="nav-link">
                    <i className="now-ui-icons gestures_tap-01" />
                    <p>BARGAIN REQUESTS</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ADMIN_BILL_LIST} className="nav-link">
                    <i className="now-ui-icons files_single-copy-04" />
                    <p>BILL BOOK</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ADMIN_CAMPAIGN_LIST} className="nav-link">
                    <i className="now-ui-icons business_bulb-63" />
                    <p>CAMPAIGNS</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ADMIN_CATEGORIES} className="nav-link">
                    <i className="now-ui-icons text_align-left" />
                    <p>CATEGORIES</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ADMIN_COUPON_LIST} className="nav-link">
                    <i className="now-ui-icons shopping_tag-content" />
                    <p>COUPONS</p>
                  </NavLink>
                </li>

                {isRouteAllowed("ADMIN_CUSTOMER_LIST") && (
                  <li>
                    <NavLink to={ADMIN_CUSTOMER_LIST} className="nav-link">
                      <i className="now-ui-icons users_circle-08" />
                      <p>CUSTOMERS</p>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink to={ADMIN_MEGAMENU} className="nav-link">
                    <i className="now-ui-icons design_vector" />
                    <p>MEGA MENU</p>
                  </NavLink>
                </li>

                {userType === "ADMIN" && (
                  <li>
                    <NavLink to={ADMIN_OPS_USERS_LIST} className="nav-link">
                      <i className="now-ui-icons users_circle-08" />
                      <p>OPS USERS</p>
                    </NavLink>
                  </li>
                )}

                <li>
                  <NavLink to={ADMIN_SAMPLE_LIST} className="nav-link">
                    <i className="now-ui-icons shopping_basket" />
                    <p>SAMPLE REQUESTS</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ADMIN_SUPPLIER_LIST} className="nav-link">
                    <i className="now-ui-icons business_badge" />
                    <p>SUPPLIERS</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ADMIN_REQUEST_LIST} className="nav-link">
                    <i className="now-ui-icons ui-1_email-85" />
                    <p>TICKETS</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ADMIN_TREND_LIST} className="nav-link">
                    <i className="now-ui-icons objects_spaceship" />
                    <p>TRENDS</p>
                  </NavLink>
                </li>

                {userType === "ADMIN" && (
                  <li>
                    <NavLink to={ADMIN_LD_USERS_LIST} className="nav-link">
                      <i className="now-ui-icons users_single-02" />
                      <p>LD USERS</p>
                    </NavLink>
                  </li>
                )}

                {isRouteAllowed("ADMIN_TEMP_SKU_LIST") && (
                  <li>
                    <NavLink to={ADMIN_TEMP_SKU_LIST} className="nav-link">
                      <i className="now-ui-icons transportation_air-baloon" />
                      <p>TEMP SKUS</p>
                    </NavLink>
                  </li>
                )}
              </Nav>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
