import { START_UI_LOADER, STOP_UI_LOADER } from "./actionTypes";

export const startUILoading = () => {
  return {
    type: START_UI_LOADER,
  };
};

export const stopUILoading = () => {
  return {
    type: STOP_UI_LOADER,
  };
};
