import { CLEAR_SAMPLE_LIST, SAVE_SAMPLE_LIST, SAVE_SAMPLE_DETAIL, CLEAR_SAMPLE_DETAIL } from "../actions/actionTypes";

const initialState = {
  sampleList: null,
  sampleDetail: null,
};

const sampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SAMPLE_LIST:
      return {
        ...state,
        sampleList: action.data,
      };
    case SAVE_SAMPLE_DETAIL:
      return {
        ...state,
        sampleDetail: action.data,
      };
    case CLEAR_SAMPLE_DETAIL:
      return {
        ...state,
        sampleDetail: null,
      };

    case CLEAR_SAMPLE_LIST:
      return {
        sampleList: null,
        sampleDetail: null,
      };

    default:
      return state;
  }
};

export default sampleReducer;
