import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { ADMIN_BASE, SUPPLIER_BASE } from "../../utils/constants/clientUrls";
import { isUserLoggedIn } from "../../utils/helpers/HelperFunctions";
import { connect } from "react-redux";

function NotAuthorised(props) {
  const homeLink = props.userType === "SUPPLIER" ? SUPPLIER_BASE : ADMIN_BASE;
  return isUserLoggedIn() ? (
    <div>
      <PanelHeader />
      <div className="content">
        <Row className="justify-content-center align-middle mt-5">
          <Col className="text-center  mt-5">
            <h4>The page you are looking for does not exist</h4>
            <Link to={homeLink} className="btn btn-danger">
              Go to Dashboard
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <div>
      <PanelHeader />
      {(window.location.href = "/")}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
  };
};

export default connect(mapStateToProps)(NotAuthorised);
