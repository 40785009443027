import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "reactstrap";

import defaultImage from "../../assets/img/image_placeholder.jpg";
import defaultAvatar from "../../assets/img/placeholder.jpg";
import { MEDIA_GET_API } from "../../utils/constants/apiUrls";
import ImageZoom from "../../components/ImageZoom";
import pdf from "../../assets/img/pdf.svg";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      availableFile: "",
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidMount() {
    if (this.props.existingImage) {
      const existingImage = this.props.existingImage ? MEDIA_GET_API + this.props.existingImage : defaultImage;
      const fileExt = existingImage.substr(existingImage.lastIndexOf(".") + 1, existingImage.length);
      this.setState({ availableFile: existingImage, fileExt });
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      const uploadedExt = file.name.split(".").pop().toLowerCase();

      // console.log("uploadedExt", uploadedExt);
      reader.onloadend = () => {
        this.setState(
          { uploadedExt, file: file, imagePreviewUrl: uploadedExt === "pdf" ? pdf : reader.result },
          this.props.handleUpload(file)
        );
      };
      reader.readAsDataURL(file);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    });
    this.refs.fileInput.value = null;
    this.props.handleUpload(null);
  }
  render() {
    const { availableFile, fileExt } = this.state;
    return (
      <Row>
        <Col sm="6">
          <div className="fileinput text-center">
            <input
              type="file"
              onChange={this.handleImageChange}
              ref="fileInput"
              accept="application/pdf, image/jpg, image/jpeg, image/png"
            />
            <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
              {this.state.uploadedExt === "pdf" ? (
                <img src={pdf} alt="alt" style={{ width: "100%" }} />
              ) : (
                <img src={this.state.imagePreviewUrl} alt="..." />
              )}
            </div>

            <div>
              {this.state.file === null ? (
                <Button size="sm" onClick={() => this.handleClick()}>
                  {this.props.avatar ? "Add Photo" : "Select file"}
                </Button>
              ) : (
                <span>
                  <Button size="sm" onClick={() => this.handleClick()}>
                    Change
                  </Button>
                  {this.props.avatar ? <br /> : null}
                  <Button size="sm" color="danger" onClick={() => this.handleRemove()}>
                    <i className="fa fa-times" /> Remove
                  </Button>
                </span>
              )}
            </div>
          </div>
        </Col>
        <Col sm="6">
          <div>
            <p>Uploaded File</p>
            {availableFile ? (
              fileExt === "pdf" ? (
                <a href={availableFile} target="_blank" rel="noopener noreferrer">
                  <img src={pdf} alt="alt" style={{ maxHeight: 80 }} />
                </a>
              ) : (
                <ImageZoom src={availableFile} alt="" className="img-fluid" />
              )
            ) : null}
          </div>
        </Col>
      </Row>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default ImageUpload;
