import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import {
  ADMIN_PRODUCT_LIST,
  ADMIN_SUPPLIER_LIST,
  ADMIN_SKU_LIST,
  ADMIN_CATEGORIES,
  ADMIN_CAMPAIGN_LIST,
  ADMIN_COUPON_LIST,
  ADMIN_BRAND_LIST,
  ADMIN_REQUEST_LIST,
  ADMIN_ORDERS_LIST,
} from "../../utils/constants/clientUrls";
import Statistics from "../../components/Statistics/Statistics";
import { fetchHomeStats, fetchCountStats } from "../../datautils/actions/statsActions";
import { Line, Bar, Pie } from "react-chartjs-2";

// const dataBar = {
//   labels: ["January", "February", "March", "April", "May", "June", "July"],
//   datasets: [
//     {
//       label: "Sku",
//       backgroundColor: "rgba(255,99,132,0.2)",
//       borderColor: "rgba(255,99,132,1)",
//       borderWidth: 1,
//       hoverBackgroundColor: "rgba(255,99,132,0.4)",
//       hoverBorderColor: "rgba(255,99,132,1)",
//       data: [65, 59, 80, 81, 56, 55, 40],
//     },
//   ],
// };

// const dataLine = {
//   labels: ["January", "February", "March", "April", "May", "June", "July"],
//   datasets: [
//     {
//       label: "Orders",
//       fill: false,
//       lineTension: 0.1,
//       backgroundColor: "rgba(75,192,192,0.4)",
//       borderColor: "rgba(75,192,192,1)",
//       borderCapStyle: "butt",
//       borderDash: [],
//       borderDashOffset: 0.0,
//       borderJoinStyle: "miter",
//       pointBorderColor: "rgba(75,192,192,1)",
//       pointBackgroundColor: "#fff",
//       pointBorderWidth: 1,
//       pointHoverRadius: 5,
//       pointHoverBackgroundColor: "rgba(75,192,192,1)",
//       pointHoverBorderColor: "rgba(220,220,220,1)",
//       pointHoverBorderWidth: 2,
//       pointRadius: 1,
//       pointHitRadius: 10,
//       data: [25, 39, 30, 51, 46, 65, 60],
//     },
//   ],
// };

// const dataPie = {
//   labels: ["Red", "Green", "Yellow"],
//   datasets: [
//     {
//       data: [300, 50, 100],
//       backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//       hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//     },
//   ],
// };

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    /**
     * bucketsCount - 7, 10
     * statsCategory - ORDER,SUPPLIER,SKU
     * statsType - TOTAL_COUNT,HISTOGRAM_DAY,HISTOGRAM_WEEK,HISTOGRAM_MONTH,HISTOGRAM_YEAR
     **/

    this.props.fetchHomeStats({
      statsType: "HISTOGRAM_WEEK",
      statsCategory: "ORDER",
    });

    this.props.fetchHomeStats({
      statsType: "HISTOGRAM_WEEK",
      statsCategory: "SKU",
    });

    this.props.fetchCountStats({});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  generateOrderGraph = () => {
    const {
      statsItems: { graphStats },
    } = this.props;
    const json = graphStats?.ORDER && JSON.parse(graphStats.ORDER);
    const orderStats = json?.order_stats && json.order_stats.buckets;

    let labels = [];
    let data = [];

    let totalDefault = 0;
    let totalSample = 0;
    let totalBargain = 0;

    orderStats?.forEach((o) => {
      labels.push(o.key_as_string);
      data.push(o.doc_count);

      o.orderTypes?.buckets?.forEach((b) => {
        if (b.key === "DEFAULT") {
          totalDefault = totalDefault + b.doc_count;
        }
        if (b.key === "SAMPLE") {
          totalSample = totalSample + b.doc_count;
        }
        if (b.key === "BARGAIN") {
          totalBargain = totalBargain + b.doc_count;
        }
      });
    });

    const dataOrder = {
      labels: labels,
      datasets: [
        {
          label: "Orders",
          fill: "origin",
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: data,
        },
      ],
    };

    const dataPie = {
      labels: [
        "Default Order (" + (totalDefault || 0) + ")",
        "Sample Order (" + (totalSample || 0) + ")",
        "Bargain Order (" + (totalBargain || 0) + ")",
      ],
      datasets: [
        {
          data: [totalDefault || 0, totalSample || 0, totalBargain || 0],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    };

    return { dataOrder, dataPie };
  };

  generateSKUGraph = () => {
    const {
      statsItems: { graphStats },
    } = this.props;
    const json = graphStats?.SKU && JSON.parse(graphStats.SKU);
    const orderStats = json?.sku_stats && json.sku_stats.buckets;

    let labels = [];
    let data = [];

    orderStats?.forEach((o) => {
      labels.push(o.key_as_string);
      data.push(o.doc_count);
    });

    // orderStats &&
    //   orderStats.map((o) => {
    //     o.suppliers &&
    //       o.suppliers.buckets &&
    //       o.suppliers.buckets.map((b) => {
    //         labels.push(b.key);
    //         data.push(b.doc_count);

    //         return true;
    //       });
    //     return true;
    //   });

    let colors = ["#E91E63", "#FF9800", "#FF6384", "#36A2EB", "#FFCE56"];
    const dataSku = {
      labels,
      datasets: [
        {
          label: "Sku",
          borderColor: "transparent",
          borderWidth: 1,
          data,
          backgroundColor: [...colors],
          hoverBackgroundColor: [...colors],
          hoverBorderColor: [...colors],
        },
      ],
    };

    return { dataSku };
  };

  getLink = (type) => {
    switch (type) {
      case "ORDERS":
        return ADMIN_ORDERS_LIST;
      case "PRODUCTS":
        return ADMIN_PRODUCT_LIST;
      case "SKUS":
        return ADMIN_SKU_LIST;
      case "SUPPLIER":
        return ADMIN_SUPPLIER_LIST;
      case "TICKETS":
        return ADMIN_REQUEST_LIST;
      case "CATEGORIES":
        return ADMIN_CATEGORIES;
      case "CAMPAIGNS":
        return ADMIN_CAMPAIGN_LIST;
      case "COUPONS":
        return ADMIN_COUPON_LIST;
      case "BRANDS":
        return ADMIN_BRAND_LIST;

      default:
        return "/admin";
    }
  };

  render() {
    const { statsItems } = this.props;
    const dataOrderGraph = this.generateOrderGraph();
    const dataSkuGraph = this.generateSKUGraph();

    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row className="mt-3">
            {statsItems?.countStats?.map((s) => {
              return (
                <Col key={"box" + s.id} sm={3} md={2} xs={6} className="psmall d-flex aflx">
                  <Link to={this.getLink(s.id)}>
                    <Statistics title={s.displayName} subtitle={s.activeCount} />
                  </Link>
                </Col>
              );
            })}
          </Row>

          <Row className="mt-1">
            <Col md={4} xs={12} className="mb-3 psmall">
              <Card className="card-chart">
                <div className="card-header">
                  <h5 className="card-category">Orders per week</h5>
                </div>
                <CardBody>
                  <Line
                    data={dataOrderGraph?.dataOrder}
                    options={{
                      legend: {
                        display: false,
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              max: 6,
                              min: 0,
                              stepSize: 2,
                            },
                          },
                        ],
                      },
                    }}
                  />
                </CardBody>
                <div className="card-footer">
                  <div className="stats">
                    <i className="now-ui-icons ui-2_time-alarm" /> Last 7 Weeks
                  </div>
                </div>
              </Card>
            </Col>
            <Col md={4} xs={12} className="mb-3 psmall">
              <Card className="card-chart">
                <div className="card-header">
                  <h5 className="card-category">Type of Order</h5>
                </div>
                <CardBody>
                  <Pie
                    data={dataOrderGraph?.dataPie}
                    options={{
                      legend: {
                        position: "right",
                      },
                    }}
                  />
                </CardBody>
                <div className="card-footer">
                  <div className="stats">
                    <i className="now-ui-icons ui-2_time-alarm" /> Last 7 Weeks
                  </div>
                </div>
              </Card>
            </Col>
            <Col md={4} xs={12} className="mb-3 psmall">
              <Card className="card-chart">
                <div className="card-header">
                  <h5 className="card-category">SKU added per week by Suppliers</h5>
                </div>
                <CardBody>
                  <Bar
                    data={dataSkuGraph?.dataSku}
                    options={{
                      legend: {
                        display: false,
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              max: 6,
                              min: 0,
                              stepSize: 2,
                            },
                          },
                        ],
                      },
                    }}
                  />
                </CardBody>
                <div className="card-footer">
                  <div className="stats">
                    <i className="now-ui-icons ui-2_time-alarm" /> Last 7 Weeks
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          {this.props.isLoading ? <div className="loader" /> : ""}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    statsItems: state.statsItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHomeStats: (reqData) => dispatch(fetchHomeStats(reqData)),
    fetchCountStats: (reqData) => dispatch(fetchCountStats(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
