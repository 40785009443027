import RegisterPage from "../../views/Common/Registration/RegistrationBase";
import LoginPage from "../../views/Common/Login/LoginPage";
import LoginPageAdmin from "../../views/Common/LoginAdmin/LoginPage";
import ForgotPasswordPage from "../../views/Common/ForgotPassword/ForgotPasswordBase";
import Supplier from "../../views/Layouts/Supplier/Dashboard";
import Admin from "../../views/Layouts/Admin/Dashboard";

import Privacy from "../../views/Common/PrivacyPage";
import Terms from "../../views/Common/TermsPage";
import Cookies from "../../views/Common/CookiesPage";
import About from "../../views/Common/AboutPage";
import Contact from "../../views/Common/ContactPage";

import AppTerms from "../../components/StaticPages/Terms";
import AppPrivacy from "../../components/StaticPages/Privacy";
import AppAbout from "../../components/StaticPages/About";

let indexRoutes = [
  {
    path: "/register",
    name: "RegisterPage",
    component: RegisterPage,
    exact: true,
  },

  { path: "/admin/login", name: "LoginPageAdmin", component: LoginPageAdmin, exact: true },
  { path: "/login", name: "LoginPage", component: LoginPage, exact: true },
  {
    path: "/forgot",
    name: "ForgotPasswordPage",
    component: ForgotPasswordPage,
    exact: true,
  },
  { path: "/supplier", name: "Supplier", component: Supplier },
  { path: "/admin", name: "Admin", component: Admin },
  { path: "/privacy-policy", name: "Privacy", component: Privacy },
  { path: "/terms-of-service", name: "Terms", component: Terms },
  { path: "/contact", name: "Contact", component: Contact },
  { path: "/cookies", name: "Cookies", component: Cookies },
  { path: "/about", name: "About", component: About },
  { path: "/app/terms", name: "AppTerms", component: AppTerms },
  { path: "/app/privacy", name: "AppPrivacy", component: AppPrivacy },
  { path: "/app/about", name: "AppAbout", component: AppAbout },
];

export default indexRoutes;
