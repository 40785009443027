import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  ButtonToolbar,
  // FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import PanelHeader from "../../components/PanelHeader/PanelHeader";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import {
  SUPPLIER_ORDER_REQUEST_VIEW,
  ADMIN_ORDER_REQUEST_VIEW,
  ADMIN_CUSTOMER_VIEW,
} from "../../utils/constants/clientUrls";

//import { sortIcon } from "../../utils/constants/icons";
import { PER_PAGE, unitOfMeasures } from "../../utils/constants/index";
import { searchOrderRequest, clearRequest } from "../../datautils/actions/orderRequestActions";
import { formatDateTime } from "../../utils/helpers/HelperFunctions";

class OrderRequestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "2",
      currentFilter: "status",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "updatedOn",
      ascending: false,
      toggle: false,
      filterArray: ["REQUESTED"],
      filterString: "REQUESTED",
    };
  }
  componentDidMount() {
    this.searchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchData();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.searchData()
      );
    }
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchData()
    );
  };

  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.searchData()
    );
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  searchData = () => {
    const {
      globalSearchKeyword,
      pageNumber,
      pageSize,
      sortField,
      ascending,
      filterArray,
      filterString,
      //currentTab,
    } = this.state;

    const fieldNameKeyword = filterArray
      ? filterArray.map((t) => {
          return {
            key: "status",
            operation: "EQUAL_TO",
            value: t,
          };
        })
      : {};

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [...fieldNameKeyword],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };

    if (this.props.userType === "SUPPLIER") {
      delete reqData["fieldNameKeyword"];
      reqData["supplierProposalStatus"] = filterString;
    }

    this.props.searchOrderRequest(reqData);
  };

  handleChange = (input) => () => {
    this.props.userType === "SUPPLIER"
      ? this.props.history.push(SUPPLIER_ORDER_REQUEST_VIEW + input)
      : this.props.history.push(ADMIN_ORDER_REQUEST_VIEW + input);
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  filterData = (e) => {
    let fArray = [];
    let filterString = "REQUESTED";
    if (e.target.value === "3") {
      fArray.push("CLOSED");
      filterString = "CLOSED";
    } else if (e.target.value === "2") {
      fArray.push("REQUESTED");
      filterString = "REQUESTED";
    } else if (e.target.value === "1") {
      fArray.push("PROCESSING");
      filterString = "PROCESSING";
    }

    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
        currentFilter: e.target.dataset.filter,
        filterArray: [...fArray],
        filterString,
      },
      () => this.searchData()
    );
  };

  renderPagination = () => {
    const { requestsList } = this.props;
    const mainData = requestsList?.demands;

    return mainData?.totalNoOfPages > 1 ? (
      <CustomPagination
        pageNumber={this.state.pageNumber}
        totalNoOfPages={mainData.totalNoOfPages}
        changePage={this.changePage}
        hasPrevious={mainData.hasPrevious}
        hasNext={mainData.hasNext}
      />
    ) : null;
  };

  render() {
    const { requestsList, userType } = this.props;
    const {
      // globalSearchKeyword,
      // ascending,
      currentTab,
      pageNumber,
      pageSize,
      //filterString,
    } = this.state;

    const responseData = requestsList?.demands;

    return (
      <div>
        <PanelHeader />
        <div className="content mt-0">
          <Row>
            <Col xs={12}>
              {responseData?.responseStatus ? <ErrorBlock errors={responseData.responseStatus.errors} /> : null}
            </Col>

            <Col xs={12}>
              <React.Fragment>
                <ButtonToolbar style={{ display: "block" }}>
                  <Button size="sm" value="3" onClick={this.filterData} color={currentTab === "3" ? "primary" : ""}>
                    Closed Requests
                  </Button>
                  <Button size="sm" value="1" onClick={this.filterData} color={currentTab === "1" ? "primary" : ""}>
                    Processing Requests
                  </Button>
                  <Button size="sm" value="2" onClick={this.filterData} color={currentTab === "2" ? "primary" : ""}>
                    New Requests
                  </Button>
                </ButtonToolbar>
                <Card>
                  <CardBody className="listings">
                    <Row>
                      <Col md="4">
                        {/* <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search Requests..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button
                                  color="primary"
                                  onClick={this.searchButton}
                                >
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup> */}
                      </Col>
                      <Col md="8">{this.renderPagination()}</Col>
                    </Row>
                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>
                          <th>Request Id</th>
                          <th>Required By</th>
                          <th>Subject</th>
                          <th>Category</th>
                          <th>Quantity</th>
                          <th>Total Budget</th>
                          <th>Customer Id</th>
                          {userType === "ADMIN" || userType === "OPS" ? <th>Status</th> : null}

                          <th>Update Date</th>

                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {responseData ? (
                          responseData.length ? (
                            responseData.map((row, index) => {
                              const unitOfMeasureLabel = unitOfMeasures.find((u) => u.value === row.unitOfMeasure);

                              const cname = row.status === "CLOSED" ? "success" : "primary";
                              return (
                                <tr key={index}>
                                  <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>

                                  <td>{row.id}</td>
                                  <td>{formatDateTime(row.requiredOn)}</td>
                                  <td>{row.subject}</td>

                                  <td>{row.catRefIdentifierNames?.join(" » ")}</td>

                                  <td>
                                    {row.quantity} {unitOfMeasureLabel?.label}
                                  </td>
                                  <td>
                                    &#x20b9;
                                    {row.totalOfferAmount && Math.round(row.totalOfferAmount).toLocaleString()}
                                  </td>
                                  <td>
                                    {userType === "SUPPLIER" ? (
                                      row.customerId
                                    ) : (
                                      <Link to={ADMIN_CUSTOMER_VIEW + row.customerId}>{row.customerId}</Link>
                                    )}
                                  </td>

                                  {userType === "ADMIN" || userType === "OPS" ? <td>{row.status}</td> : null}

                                  <td>{formatDateTime(row.updatedOn)}</td>

                                  <td className="text-right">
                                    <Button
                                      className={`btn-simple btn-light btn-primary btn-${cname}`}
                                      size="xs"
                                      onClick={this.handleChange(row.id)}
                                    >
                                      View
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="15" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="14" />
                          </tr>
                        )}
                      </tbody>
                    </Table>{" "}
                    {this.props.isLoading ? <div className="loader" /> : null}
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    requestsList: state.orderRequestItems.requestsList,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchOrderRequest: (reqParam) => dispatch(searchOrderRequest(reqParam)),
    clearRequest: () => dispatch(clearRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderRequestList);
