import React, { Component } from "react";
import { Row, Col, Table, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import CustomPagination from "../components/CustomPagination/CustomPagination";
import { formatDateTime } from "../utils/helpers/HelperFunctions";

class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = { pageNumber: 1 };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.props.fetchActivity(this.state.pageNumber);
    }
  }

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  renderPagination = () => {
    const mainData = this.props.userActivities;

    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={(p) => this.changePage(p)}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  render() {
    const { userActivities } = this.props;

    return userActivities ? (
      <>
        <Row>
          {/* <Col sm={3}>
            <p className="mb-2 mt-1 pl-2" style={{ clear: "both" }}>
              Click on the row to view Request Params
            </p>
          </Col> */}
          <Col sm={12}>
            <div className="pt-2 pb-1 pr-1">
              {userActivities && userActivities.stats ? this.renderPagination() : ""}
            </div>
          </Col>
        </Row>
        <div className="p-1">
          <Table responsive className="w-100 table-striped table-bordered">
            <thead>
              <tr className="text-primary">
                <th className="text-center">#</th>

                <th>Activity</th>
                <th>App Version</th>
                <th>Device</th>
                <th>IP + Time Zone</th>
                <th>Activity Time</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {userActivities.stats &&
                userActivities.stats.map((row, index) => {
                  let param = null;
                  if (row.reqData) {
                    param = row.reqData;
                    delete param["jwtToken"];
                  }

                  const ip = row.ipAddress && row.ipAddress.split(",")[0];

                  return (
                    <React.Fragment key={index}>
                      <tr className="cursor">
                        <td className="text-center">{this.state.pageNumber * 200 + (index + 1) - 200}</td>
                        <td>{row.opType}</td>

                        <td>
                          {row.mobileBrand}, {row.platformType} ({row.clientVersion})
                        </td>
                        <td>
                          {row.mobileModel}, {row.platformVersion}
                        </td>
                        <td>
                          {ip && ip.split(":")[0]}, {row.timeZone}
                        </td>
                        <td>{formatDateTime(row.createdOn)}</td>
                        <td id={"PopoverFocus" + index}>{param ? <span>&bull;</span> : null}</td>
                      </tr>
                      {param ? (
                        <UncontrolledPopover
                          style={{ maxWidth: "100%" }}
                          trigger="hover"
                          placement="right"
                          target={"PopoverFocus" + index}
                        >
                          <PopoverHeader>Request Params</PopoverHeader>
                          <PopoverBody>
                            <pre>{JSON.stringify(param, null, 2)}</pre>
                          </PopoverBody>
                        </UncontrolledPopover>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </>
    ) : null;
  }
}

export default Activities;
