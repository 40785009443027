import {
  SET_VALUES,
  RESET_VALUES,
  RESET_OBJECT,
  SAVE_MEDIA_LIST,
  CLEAR_MEDIA_LIST,
  REMOVE_MEDIA_LIST,
  SAVE_MEDIA_OBJECT,
  CLEAR_MEDIA_OBJECT,
  REMOVE_MEDIA_OBJECT,
} from "../actions/actionTypes";

const initialState = {
  status: null,
  data: null,
  dObjectData: {},
  errors: [],
  media: [],
  mediaObject: [],
};

const responseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VALUES:
      return {
        ...state,
        status: action.status,
        data: action.objectType ? null : action.data,
        dObjectData: action.objectType
          ? { ...state.dObjectData, [action.objectType]: action.data }
          : { ...state.dObjectData },
        errors: action.errors,
        /*errorObj: action.errors && action.errors.length > 0 ? action.errors[0] : {}*/
      };
    case RESET_VALUES:
      return {
        ...state,
        status: null,
        data: null,
        errors: [],
      };

    case RESET_OBJECT:
      return {
        ...state,
        dObjectData: action.objectType ? { ...state.dObjectData, [action.objectType]: {} } : { ...state.dObjectData },
      };

    case SAVE_MEDIA_LIST:
      return {
        ...state,
        media: [...state.media, action.data],
      };

    case CLEAR_MEDIA_LIST:
      return {
        ...state,
        media: [],
      };

    case REMOVE_MEDIA_LIST:
      return {
        ...state,
        media: state.media.filter((fileId) => fileId !== action.data),
      };

    case SAVE_MEDIA_OBJECT:
      const key = Object.keys(action.data);
      return {
        ...state,
        mediaObject: [
          ...state.mediaObject,
          {
            [key]: action.data[key],
          },
        ],
      };
    case CLEAR_MEDIA_OBJECT:
      return {
        ...state,
        mediaObject: [],
      };

    case REMOVE_MEDIA_OBJECT:
      console.log(action);
      return {
        ...state,
        mediaObject: state.mediaObject.filter((fileObj) => Object.keys(fileObj)[0] !== action.data),
      };

    default:
      return state;
  }
};

export default responseReducer;
