import React from "react";
import { Row, Col, Label, Button, Form, FormGroup, Input, CustomInput } from "reactstrap";

import Select from "react-select";
import {
  fulfillmentTypes,
  unitOfMeasures,
  colorOptions,
  //variantTypes
} from "../../utils/constants";

import { countriesOptions } from "../../utils/constants/countries";

import TooltipItem from "../../components/Tooltip/Tooltip";
import helpIcon from "../../assets/img/question-mark.svg";
import { formatOptionLabel } from "../../utils/helpers/HelperFunctions";

class SkuForm extends React.Component {
  state = { hasExtraValue: false };
  discardInput = (e) => {
    return;
  };

  filterOption = (option, inputValue) => {
    if (option.label === "Others") {
      const result = colorOptions.filter((opt) => opt.label.toLowerCase().includes(inputValue.toLowerCase()));
      //this.setState({ hasExtraValue: !result.length });
      return !result.length;
    }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  render() {
    const {
      skuId,
      title,
      variant,
      description,
      description1,
      description2,
      description3,
      vendorStyleCode,
      petStyleCode,
      packagingWeightKg,
      supplierReferenceId,
      fulfillmentType,
      qcStatus,
      unitOfMeasure,
      warranty,
      attributes,
      eanCode,
      brandName,
      skuDetailsUploadedFinal,
      categoryRefTreeIdentifierLabel,
      skuDetailWorkFlowStatus,
      skuDetailFinal,
      packagingDimensionsL,
      packagingDimensionsB,
      packagingDimensionsH,
      variantTypes,
      variantType,
      primaryCatFields,
      colorOption,
      selectedColor,
      otherColor,
      countryOfOrigin,
      placeOfOrigin,
    } = this.props.form;

    let catArray = [];
    if (categoryRefTreeIdentifierLabel) {
      catArray = JSON.parse(categoryRefTreeIdentifierLabel);
    }

    const {
      handleCheckExisting,
      handleChange,
      handleChangeSelect,
      handleChangeSelectPrimaryColor,
      form,
      handleCheckedFinal,
      addSku,
      markCompleted,
      userType,
      // masterSku,
    } = this.props;

    const notesMessages = form && form.qcMessage ? JSON.parse(form.qcMessage) : "";

    const dynamicVariantTypes =
      variantTypes &&
      variantTypes.map((vt) => {
        return { value: vt.trim(), label: vt.trim() };
      });

    return (
      <div style={{ fontSize: "100%" }}>
        <Col sm="12">
          <Form autoComplete="off" className="form-horizontal">
            <fieldset disabled={qcStatus === "INPROGRESS" && userType === "SUPPLIER"}>
              {skuId ? (
                <>
                  <Row>
                    <Col>
                      <h5 className={"qcStatus " + skuDetailWorkFlowStatus}>
                        {skuDetailWorkFlowStatus === "COMPLETE" ? (
                          <i className="now-ui-icons ui-1_check" />
                        ) : skuDetailWorkFlowStatus === "SUBMITTED" ||
                          skuDetailWorkFlowStatus === "PROCESSING" ||
                          skuDetailWorkFlowStatus === "ON_HOLD" ? (
                          <i className="now-ui-icons tech_watch-time" />
                        ) : skuDetailWorkFlowStatus === "ONHOLD" ? (
                          <i className="now-ui-icons ui-1_lock-circle-open" />
                        ) : (
                          <i className="now-ui-icons ui-1_lock-circle-open" />
                        )}

                        {notesMessages && notesMessages.sectionMessage ? (
                          <>
                            <p className="pt-0 pb-0 m-0">
                              {notesMessages.sectionMessage.SKU_DETAILS
                                ? notesMessages.sectionMessage.SKU_DETAILS.message
                                : null}
                            </p>
                            <p className="pt-0 pb-0 m-0">
                              {notesMessages.sectionMessage.SKU_DETAILS
                                ? notesMessages.sectionMessage.SKU_DETAILS.notes
                                : null}
                            </p>
                          </>
                        ) : null}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {userType === "SUPPLIER" && !skuDetailsUploadedFinal ? (
                        <div className="d-flex align-items-center justify-content-end py-3">
                          <Label check className="mr-2">
                            <Input type="checkbox" name="skuDetailFinal" onChange={handleCheckedFinal} />
                            <span className="form-check-sign" />I have completed all data
                          </Label>{" "}
                          <Button
                            size="sm"
                            name="skuDetailsUploadedFinal"
                            className="btn-danger mt-0 mb-0"
                            onClick={markCompleted}
                            disabled={!skuDetailFinal}
                          >
                            Mark as Complete
                          </Button>
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <FormGroup row>
                    <Label for="title" sm={3}>
                      SKU ID<span className="star">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input type="text" onChange={this.discardInput} disabled={skuId} value={skuId || ""} />
                    </Col>
                  </FormGroup>
                </>
              ) : null}

              {!skuId ? (
                <p className="text-right">
                  <small className="text-danger">
                    Enter Ean Code and/or Pet Style Code and check if the sku data is existing in system
                  </small>
                </p>
              ) : null}

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Ean Code{" "}
                  <span className="help-icon">
                    <img alt="help" src={helpIcon} id={"Tooltip-help-ean"} />
                    <TooltipItem placement="right" text="Branded Product Style Code" id={"help-ean"} />
                  </span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="eanCode"
                    placeholder="Enter ean code"
                    value={eanCode || ""}
                    onChange={skuId ? this.discardInput : handleChange}
                    disabled={skuId}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Pet Style Code <span className="star">*</span>{" "}
                  <span className="help-icon">
                    <img alt="help" src={helpIcon} id={"Tooltip-help-pet"} />
                    <TooltipItem placement="right" text="Sku Unique Style Code" id={"help-pet"} />
                  </span>
                </Label>
                <Col sm={9}>
                  <Row>
                    <Col sm={skuId ? 12 : 7}>
                      <Input
                        type="text"
                        name="petStyleCode"
                        placeholder="Enter pet style code"
                        value={petStyleCode || ""}
                        onChange={skuId ? this.discardInput : handleChange}
                        disabled={skuId}
                      />
                    </Col>
                    {!skuId ? (
                      <Col sm={5}>
                        <Button
                          disabled={!petStyleCode}
                          style={{ height: 39, whiteSpace: "nowrap" }}
                          className="m-0 btn-block"
                          color="info"
                          size="md"
                          onClick={handleCheckExisting}
                        >
                          Check Sku
                        </Button>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Custom Style Code <span className="star">*</span>
                  <span className="help-icon">
                    <img alt="help" src={helpIcon} id={"Tooltip-help-own"} />
                    <TooltipItem
                      placement="right"
                      text="Ener same as Pet Style Code or your Custom Style Code"
                      id={"help-own"}
                    />
                  </span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="vendorStyleCode"
                    placeholder="Enter your own style code"
                    value={vendorStyleCode || ""}
                    onChange={skuId ? this.discardInput : handleChange}
                    disabled={skuId}
                  />
                </Col>
              </FormGroup>

              {catArray ? (
                <FormGroup row>
                  <Label for="title" sm={3}>
                    Category <span className="star">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input type="textarea" onChange={this.discardInput} disabled value={catArray.join(" / ") || ""} />
                  </Col>
                </FormGroup>
              ) : null}

              <FormGroup row>
                <Label for="title" sm={3}>
                  Brand<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input type="text" onChange={this.discardInput} disabled value={brandName || ""} />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="title" sm={3}>
                  Title <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="title"
                    placeholder="Enter title"
                    value={title || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              {dynamicVariantTypes ? (
                <FormGroup row>
                  <Label for="variantType" sm={3}>
                    Variant Type
                    <span className="star">*</span>
                  </Label>
                  <Col sm={9}>
                    <Select
                      isSearchable={false}
                      isDisabled={skuId}
                      placeholder="Select Variant Type"
                      options={dynamicVariantTypes}
                      value={dynamicVariantTypes.filter((option) => option.value === variantType)}
                      onChange={(opt) => handleChangeSelect("variantType", opt)}
                    />
                  </Col>
                </FormGroup>
              ) : null}

              {variantType !== "NONE" && variantType !== "NA" ? (
                <FormGroup row>
                  <Label for="variant" sm={3}>
                    Variant<span className="star">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      name="variant"
                      placeholder="Enter variant"
                      value={variant || ""}
                      onChange={handleChange}
                      disabled={skuId}
                    />
                  </Col>
                </FormGroup>
              ) : null}

              <FormGroup row>
                <Label for="CountryOfOrigin" sm={3}>
                  Country of Origin
                  <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Select
                    isSearchable={true}
                    placeholder="Select Country of Origin"
                    options={countriesOptions}
                    value={countriesOptions.filter((option) => option.value === countryOfOrigin)}
                    onChange={(opt) => handleChangeSelect("countryOfOrigin", opt)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="placeOfOrigin" sm={3}>
                  Place of Origin
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="placeOfOrigin"
                    placeholder="Enter City of Origin"
                    value={placeOfOrigin || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              {/** Primary Key fields */}

              {primaryCatFields
                ? Object.keys(primaryCatFields).map((index) => {
                    const attribute = primaryCatFields[index];
                    const options =
                      attribute.applicableOptions &&
                      attribute.applicableOptions.length &&
                      attribute.applicableOptions.map((idx) => ({
                        value: idx,
                        label: idx,
                        type: "primaryCatFields",
                      }));

                    const helpTxt =
                      attribute.helpText &&
                      attribute.helpText.length > 0 &&
                      attribute.helpText.map((text, indx) => {
                        return (
                          <p key={"ttext-" + indx} className="tool-list">
                            {text}
                          </p>
                        );
                      });

                    const inputProps = {
                      type: "text",
                      ...(attribute.type === "Textarea" && {
                        type: "textarea",
                      }),
                      ...(attribute.type === "Integer" && {
                        step: 1,
                        type: "number",
                        min: 0,
                      }),
                      ...((attribute.type === "Float" || attribute.type === "Long" || attribute.type === "Double") && {
                        step: 0.01,
                        type: "number",
                        min: 0,
                      }),
                    };

                    return attribute && attribute.name ? (
                      attribute.name === "COLOUR" ? (
                        <React.Fragment key={index}>
                          <FormGroup row>
                            <Label for="color" sm={3}>
                              Colour<span className="star">*</span>
                            </Label>
                            <Col sm={9}>
                              {skuId ? (
                                <Input
                                  type="text"
                                  onChange={this.discardInput}
                                  disabled
                                  value={attribute.value || ""}
                                />
                              ) : (
                                <Select
                                  name={index}
                                  data-object="primaryCatFields"
                                  placeholder={
                                    attribute.placeholder
                                      ? attribute.placeholder.toLowerCase()
                                      : "Enter " + attribute.name.toLowerCase()
                                  }
                                  filterOption={this.filterOption}
                                  options={colorOptions}
                                  value={colorOptions.filter((option) => option.value === attribute.value)}
                                  formatOptionLabel={formatOptionLabel}
                                  onChange={(opt) => handleChangeSelectPrimaryColor(index, opt)}
                                />
                              )}
                            </Col>
                          </FormGroup>

                          {colorOption === "Others" ? (
                            <FormGroup row>
                              <Label for="colorr" sm={3}>
                                Your Color <span className="star">*</span>
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  name="otherColor"
                                  placeholder="Enter color"
                                  value={otherColor || ""}
                                  onChange={handleChange}
                                />
                              </Col>
                            </FormGroup>
                          ) : null}

                          {skuId && selectedColor ? (
                            <FormGroup row key={"pallate" + index}>
                              <Label for="pallete" sm={3}></Label>
                              <Col sm={9}>
                                <div
                                  style={{
                                    height: 20,
                                    width: "100%",
                                    backgroundColor: "#" + selectedColor,
                                  }}
                                ></div>
                              </Col>
                            </FormGroup>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        <FormGroup row key={index}>
                          <Label for="variant" sm={3}>
                            {attribute.name.toLowerCase()}{" "}
                            {attribute.mandatory ? <span className="star">*</span> : null}
                            {attribute.helpText && attribute.helpText.length > 0 ? (
                              <span className="help-icon">
                                <img
                                  alt="help"
                                  src={helpIcon}
                                  id={"Tooltip-help-" + attribute.name.replace(" ", "_")}
                                />
                                <TooltipItem
                                  key={"tkey-" + attribute.name}
                                  placement="right"
                                  text={helpTxt}
                                  id={"help-" + attribute.name.replace(" ", "_")}
                                />
                              </span>
                            ) : null}
                          </Label>
                          <Col sm={9}>
                            {attribute.applicableOptions && attribute.applicableOptions.length > 0 ? (
                              <Select
                                isSearchable={false}
                                options={options}
                                placeholder={
                                  attribute.placeholder
                                    ? attribute.placeholder
                                    : "Select " + attribute.name.toLowerCase()
                                }
                                value={options.filter((option) => {
                                  return option.value === attribute.value;
                                })}
                                onChange={(opt) => handleChangeSelect(index, opt)}
                                disabled={skuId}
                              />
                            ) : (
                              <Input
                                type={["Integer", "Float", "Double"].includes(attribute.type) ? "number" : "text"}
                                {...inputProps}
                                name={index}
                                data-object="primaryCatFields"
                                placeholder={
                                  attribute.placeholder
                                    ? attribute.placeholder.toLowerCase()
                                    : "Enter " + attribute.name.toLowerCase()
                                }
                                value={attribute.value || ""}
                                onChange={this.props.handleChange}
                                disabled={skuId}
                              />
                            )}
                          </Col>
                        </FormGroup>
                      )
                    ) : null;
                  }, this)
                : null}

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Fulfillment Type <span className="star">*</span>{" "}
                  <span className="help-icon">
                    <img alt="help" src={helpIcon} id={"Tooltip-help-fulfill"} />
                    <TooltipItem
                      placement="right"
                      text="Delivery directly from seller warehouse or platform managed warehouse"
                      id={"help-fulfill"}
                    />
                  </span>
                </Label>
                <Col sm={9}>
                  <Select
                    isSearchable={false}
                    name="fulfillmentTypes"
                    options={fulfillmentTypes}
                    value={fulfillmentTypes.filter((option) => option.value === fulfillmentType)}
                    onChange={(opt) => handleChangeSelect("fulfillmentType", opt)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Unit of Measure<span className="star">*</span>{" "}
                  <span className="help-icon">
                    <img alt="help" src={helpIcon} id={"Tooltip-help-measure"} />
                    <TooltipItem
                      placement="right"
                      text="Eg: Sell in number of piece or by weight or by length"
                      id={"help-measure"}
                    />
                  </span>
                </Label>
                <Col sm={9}>
                  <Select
                    isSearchable={false}
                    options={unitOfMeasures}
                    value={unitOfMeasures.filter((option) => option.value === unitOfMeasure)}
                    onChange={(opt) => handleChangeSelect("unitOfMeasure", opt)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Reference Number <span className="star">*</span>{" "}
                  <span className="help-icon">
                    <img alt="help" src={helpIcon} id={"Tooltip-help-refid"} />
                    <TooltipItem placement="right" text="Seller Warehouse Refrence Number" id={"help-refid"} />
                  </span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="supplierReferenceId"
                    placeholder="Enter supplier reference id"
                    value={supplierReferenceId || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Warranty <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="warranty"
                    placeholder="Enter warranty details"
                    value={warranty || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Package Dimension (LxBxH) (cm)
                  <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Row>
                    <Col sm={4}>
                      {" "}
                      <Input
                        type="number"
                        min="0"
                        name="packagingDimensionsL"
                        placeholder="Length"
                        value={packagingDimensionsL || ""}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm={4}>
                      {" "}
                      <Input
                        type="number"
                        min="0"
                        name="packagingDimensionsB"
                        placeholder="Breadth"
                        value={packagingDimensionsB || ""}
                        onChange={handleChange}
                      />
                    </Col>

                    <Col sm={4}>
                      {" "}
                      <Input
                        type="number"
                        min="0"
                        name="packagingDimensionsH"
                        placeholder="Width"
                        value={packagingDimensionsH || ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="variant" sm={3}>
                  Package Weight (kg) <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="number"
                    name="packagingWeightKg"
                    placeholder="Enter package weight in KG"
                    value={packagingWeightKg || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              {attributes
                ? Object.keys(attributes).map((index) => {
                    const attribute = attributes[index];
                    const options =
                      attribute.applicableOptions &&
                      attribute.applicableOptions.length &&
                      attribute.applicableOptions.map((idx) => ({
                        value: idx,
                        label: idx,
                        type: "attribute",
                      }));

                    const helpTxt =
                      attribute.helpText &&
                      attribute.helpText.length > 0 &&
                      attribute.helpText.map((text, indx) => {
                        return (
                          <p key={"ttext-" + indx} className="tool-list">
                            {text}
                          </p>
                        );
                      });

                    const inputProps = {
                      type: "text",
                      ...(attribute.type === "Textarea" && {
                        type: "textarea",
                      }),
                      ...(attribute.type === "Integer" && {
                        step: 1,
                        type: "number",
                        min: 0,
                      }),
                      ...((attribute.type === "Float" || attribute.type === "Long" || attribute.type === "Double") && {
                        step: 0.01,
                        type: "number",
                        min: 0,
                      }),
                    };

                    return attribute && attribute.name ? (
                      <FormGroup row key={index}>
                        <Label for="variant" sm={3}>
                          {attribute.name.toLowerCase()} {attribute.mandatory ? <span className="star">*</span> : null}
                          {attribute.helpText && attribute.helpText.length > 0 ? (
                            <span className="help-icon">
                              <img alt="help" src={helpIcon} id={"Tooltip-help-" + attribute.name.replace(/ /g, "_")} />
                              <TooltipItem
                                key={"tkey-" + attribute.name}
                                placement="right"
                                text={helpTxt}
                                id={"help-" + attribute.name.replace(/ /g, "_")}
                              />
                            </span>
                          ) : null}
                        </Label>
                        <Col sm={9}>
                          {attribute.applicableOptions && attribute.applicableOptions.length > 0 ? (
                            <Select
                              isSearchable={false}
                              options={options}
                              placeholder={
                                attribute.placeholder ? attribute.placeholder : "Select " + attribute.name.toLowerCase()
                              }
                              value={options.filter((option) => {
                                return option.value === attribute.value;
                              })}
                              onChange={(opt) => handleChangeSelect(index, opt)}
                            />
                          ) : (
                            <Input
                              type={["Integer", "Float", "Double"].includes(attribute.type) ? "number" : "text"}
                              {...inputProps}
                              name={index}
                              data-object="attributes"
                              placeholder={
                                attribute.placeholder ? attribute.placeholder : "Enter " + attribute.name.toLowerCase()
                              }
                              value={attribute.value || ""}
                              onChange={this.props.handleChange}
                            />
                          )}
                        </Col>
                      </FormGroup>
                    ) : null;
                  }, this)
                : null}

              <FormGroup row>
                <Label for="description" sm={3}>
                  Description<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="description"
                    placeholder="Enter Description"
                    value={description || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="description1" sm={3}>
                  Description 1
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="description1"
                    placeholder="Enter Description 1"
                    value={description1 || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="description2" sm={3}>
                  Description 2
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="description2"
                    placeholder="Enter Description 2"
                    value={description2 || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="description3" sm={3}>
                  Description 3
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="description3"
                    placeholder="Enter Description 3"
                    value={description3 || ""}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              {userType === "SUPPLIER" ? (
                <FormGroup row>
                  <Label for="descriptionfinal3" sm={3}>
                    &nbsp;
                  </Label>
                  <Col sm={9}>
                    <FormGroup check>
                      <CustomInput
                        type="switch"
                        id="skuDetailsUploadedFinal"
                        name="skuDetailsUploadedFinal"
                        label="I have completed all data"
                        onChange={handleCheckedFinal}
                        checked={skuDetailsUploadedFinal}
                      />{" "}
                    </FormGroup>
                    <small>
                      Once you have completed all data, checkmark above checkbox and submit. Then only your data will be
                      considered for QC check.
                    </small>
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup row>
                <Label for="btnn" sm={3} />
                <Col sm={9}>
                  <Button
                    block
                    color="primary"
                    size="md"
                    onClick={addSku}
                    disabled={
                      !title ||
                      (!variant && variantType !== "NONE") ||
                      !description ||
                      !petStyleCode ||
                      !vendorStyleCode ||
                      !supplierReferenceId ||
                      !warranty ||
                      (colorOption === "Others" && !otherColor) ||
                      (qcStatus === "INPROGRESS" && userType === "SUPPLIER")
                    }
                  >
                    {skuId ? "Update" : "Add"} SKU
                  </Button>
                </Col>
              </FormGroup>
            </fieldset>
          </Form>
        </Col>
      </div>
    );
  }
}

export default SkuForm;
