import React from "react";
import { Tooltip } from "reactstrap";

class TooltipItem extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return (
      <Tooltip
        key={"tipper-" + this.props.id}
        placement={this.props.placement}
        isOpen={this.state.tooltipOpen}
        target={"Tooltip-" + this.props.id}
        toggle={this.toggle}
      >
        {this.props.text}
      </Tooltip>
    );
  }
}

export default TooltipItem;
