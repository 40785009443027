import React from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";

import { connect } from "react-redux";
import { changePassword } from "../../datautils/actions/userActions";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { getFilteredAuthData } from "../../utils/helpers/HelperFunctions";

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      newpassword: "",
      confirmPassword: "",
      passwordState: "",
      newpasswordState: "",
      confirmPasswordState: "",
      passwordChanged: false,
    };
  }
  componentDidMount() {
    //document.body.classList.add("bg-white");
    this.props.resetResponseValues();
  }

  componentDidUpdate() {
    if (this.props.responseValStatus === "success" && !this.state.passwordChanged) {
      this.setState({
        password: "",
        newpassword: "",
        confirmPassword: "",
        passwordState: "",
        newpasswordState: "",
        confirmPasswordState: "",
        passwordChanged: true,
      });
    }
  }

  handleChange = (input) => (event) => {
    if (input === "password") {
      let password = event.target.value;
      let passwordState = this.state.passwordState;
      if (password.length > 5) {
        passwordState = "is-valid";
      } else {
        passwordState = "is-invalid";
      }
      this.setState({ password, passwordState });
    }
    if (input === "newpassword") {
      let newpassword = event.target.value;
      let newpasswordState = this.state.newpasswordState;

      if (newpassword.length > 5) {
        newpasswordState = "is-valid";
      } else {
        newpasswordState = "is-invalid";
      }
      this.setState({ newpassword, newpasswordState });
    }
    if (input === "confirmPassword") {
      let confirmPassword = event.target.value;
      let confirmPasswordState = this.state.confirmPasswordState;
      if (confirmPassword.length > 5) {
        confirmPasswordState = "is-valid";
      } else {
        confirmPasswordState = "is-invalid";
      }
      this.setState({ confirmPassword, confirmPasswordState });
    }
  };

  savePassword = (e) => {
    e.preventDefault();
    const authData = getFilteredAuthData(this.props.authData);
    const reqData = {
      ...authData,
      currentPassword: this.state.password,
      newPassword: this.state.newpassword,
    };
    this.props.changePassword(reqData);
  };

  render() {
    const {
      password,
      newpassword,
      confirmPassword,
      passwordState,
      confirmPasswordState,
      newpasswordState,
    } = this.state;

    const { responseVal } = this.props;

    const isEnabled =
      passwordState === "is-valid" && confirmPasswordState === "is-valid" && newpasswordState === "is-valid";

    return (
      <div>
        <PanelHeader />
        <div className="content mt-4">
          <Row>
            <Col md={5} xs={12} className="mx-auto">
              <Card className="card-signup" style={{ boxShadow: "none" }}>
                <CardHeader className="text-center">
                  <CardTitle tag="h4">Change Password</CardTitle>
                </CardHeader>
                <CardBody>
                  {responseVal.status === "failure" ? <ErrorBlock errors={responseVal.errors} /> : null}

                  {responseVal.status === "success" ? <SuccessBlock msg={responseVal.data} /> : null}
                  <Form autoComplete="off">
                    <FormGroup>
                      <Input
                        autoComplete="off"
                        className={passwordState}
                        type="password"
                        name="password"
                        placeholder="Current Password"
                        onChange={this.handleChange("password")}
                        value={password}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        autoComplete="off"
                        className={newpasswordState}
                        type="password"
                        name="newpassword"
                        placeholder="New Password"
                        onChange={this.handleChange("newpassword")}
                        value={newpassword}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Input
                        autoComplete="off"
                        className={confirmPasswordState}
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        onChange={this.handleChange("confirmPassword")}
                        value={confirmPassword}
                      />
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    block
                    color="primary"
                    size="md"
                    onClick={this.savePassword}
                    disabled={this.props.isLoading || !isEnabled}
                  >
                    Change
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    authData: state.auth,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (authData) => dispatch(changePassword(authData)),
    resetResponseValues: () => dispatch(resetResponseValues()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
