import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Button,
  Table,
  ButtonToolbar,
} from "reactstrap";

import { connect } from "react-redux";
import { listAdmin } from "../../datautils/actions/adminActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";

import { sortIcon } from "../../utils/constants/icons";
import { PER_PAGE } from "../../utils/constants/index";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import { ADMIN_ACTIVITY_VIEW } from "../../utils/constants/clientUrls";
import moment from "moment";

class AdminList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      pageNumber: 1,
      userAppId: "2",
      userTypeId: "2",
      pageSize: PER_PAGE,
      sortField: "userId",
      ascending: false,
      currentTab: "",
      modal: false,
    };

    // userTypeId = 1 for SUPPLIER
    // userTypeId = 2 for CUSTOMER
    // userTypeId = 3 for ADMIN
    // userTypeId = 4 for OPS

    // userAppId = 1  for SP
    // userAppId =  2 for LD
  }

  componentDidMount() {
    this.listAdmin();
  }

  componentDidUpdate(prevProps, prevState) {
    const { pageNumber, modal, isUpdated } = this.state;

    if (prevState.pageNumber !== pageNumber) {
      this.listAdmin();
    }
    if (this.props.responseVal.status === "success" && isUpdated && modal) {
      this.setState({ modal: false });
      this.listAdmin();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.listAdmin()
      );
    }
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.listAdmin()
    );
  };
  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.listAdmin()
    );
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  filterData = (e) => {
    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
      },
      () => this.listAdmin()
    );
  };

  listAdmin = () => {
    const {
      globalSearchKeyword,

      pageNumber,
      pageSize,
      sortField,
      ascending,
      userAppId,
      userTypeId,
    } = this.state;

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [
        { key: "userAppId", operation: "EQUAL_TO", value: userAppId },
        { key: "userTypeId", operation: "EQUAL_TO", value: userTypeId },
      ],

      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.props.listAdmin(reqData);
  };

  changePage = (page) => () => {
    this.setState({ pageNumber: page });
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.adminList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  render() {
    const { responseVal } = this.props;
    const { globalSearchKeyword, ascending, pageNumber, pageSize } = this.state;

    const dataLoaded =
      responseVal.dObjectData &&
      responseVal.dObjectData.adminList &&
      responseVal.dObjectData.adminList.data &&
      responseVal.dObjectData.adminList.data.users;

    return (
      <div>
        <PanelHeader />

        <div className="content">
          <Row>
            <Col xs={12}>
              <MessageBlock screenName="admin" />
            </Col>
            <Col xs={12}>
              <ButtonToolbar> </ButtonToolbar>
            </Col>
            <Col xs={12} className="mt-3">
              <Card>
                <CardBody className="listings">
                  <React.Fragment>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search User..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button
                                  color="primary"
                                  onClick={this.searchButton}
                                >
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="8">
                        {dataLoaded ? this.renderPagination() : ""}
                      </Col>
                    </Row>

                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>
                          <th>UID </th>
                          <th
                            onClick={this.sortButton(
                              "mobileNumber",
                              ascending ? false : true
                            )}
                          >
                            Mobile{" "}
                            <img
                              alt="sort"
                              className="sort-icon"
                              src={sortIcon}
                            />
                          </th>
                          <th>Registered On</th>
                          {/* <th>User Type </th>
                          <th>User Role </th>
                          <th>Title</th>
                          <th
                            onClick={this.sortButton(
                              "firstName",
                              ascending ? false : true
                            )}
                          >
                            First Name{" "}
                            <img
                              alt="sort"
                              className="sort-icon"
                              src={sortIcon}
                            />
                          </th>

                          <th
                            onClick={this.sortButton(
                              "lastName",
                              ascending ? false : true
                            )}
                          >
                            Last Name{" "}
                            <img
                              alt="sort"
                              className="sort-icon"
                              src={sortIcon}
                            />
                          </th>

                          <th>Email </th> */}

                          <th>Verified</th>
                          <th>Active</th>

                          <th>Updated On</th>
                          <th>APP Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLoaded ? (
                          dataLoaded.length ? (
                            dataLoaded.map((row, index) => {
                              return (
                                <tr className="ccursor" key={index}>
                                  <td className="text-center">
                                    {pageNumber * pageSize +
                                      (index + 1) -
                                      pageSize}
                                  </td>
                                  <td>{row.userId}</td>
                                  <td>
                                    {row.mobileCountryCode}-{row.mobileNumber}
                                  </td>
                                  <td>
                                    {moment(row.dateCreated).format(
                                      "DD MMM, YYYY, hh:mm a"
                                    )}
                                  </td>
                                  {/* <td>{row.userType}</td>
                                  <td>{row.userRole}</td>
                                  <td>{row.title} </td>
                                  <td>{row.firstName} </td>

                                  <td>{row.lastName}</td>

                                  <td>{row.email}</td> */}

                                  <td>
                                    {row.verified ? (
                                      <span className="text-success">YES</span>
                                    ) : (
                                      <span className="text-danger">NO</span>
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {row.active ? (
                                      <span className="text-success">YES</span>
                                    ) : (
                                      <span className="text-danger">NO</span>
                                    )}
                                  </td>
                                  <td>
                                    {moment(row.dateModified).format(
                                      "DD MMM, YYYY, hh:mm a"
                                    )}
                                  </td>

                                  <td>
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={ADMIN_ACTIVITY_VIEW + row.userId}
                                    >
                                      Activity
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="15" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="15" />
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {this.props.isLoading ? <div className="loader" /> : null}
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAdmin: (reqData) => dispatch(listAdmin(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminList);
