import React from "react";
import TooltipItem from "../../components/Tooltip/Tooltip";
//import { format } from "date-fns";

class ContactBlock extends React.Component {
  render() {
    const { type, content, header, workflow } = this.props;
    const data = content?.contactDetailsMap[type];

    if (data) {
      return (
        <React.Fragment>
          {header && data?.status ? (
            <div className="border-btm mb-2">
              <h5 className="text-capitalize">
                <span className={`status ${workflow?.currentState}`} id={"Tooltip-y" + data.id}>
                  <i className="now-ui-icons ui-1_check" />
                </span>

                {type.replace(/_/g, " ").toLowerCase()}
              </h5>
              <TooltipItem placement="right" text={workflow?.currentState} id={"y" + data.id} />
            </div>
          ) : null}
          <div className="mt-0 mb-0 d-flex">
            <p className="mb-0 text-muted" style={{ minWidth: 80 }}>
              Name
            </p>
            <p className="mt-0 mb-0 ml-3">{`${data.title} ${data.firstName} ${data.lastName}` || "N/A"}</p>
          </div>

          <div className="mt-1 mb-0 d-flex">
            <p className="mb-0 text-muted" style={{ minWidth: 80 }}>
              Gender
            </p>
            <p className="mb-0 mt-0 ml-3">{data.gender}</p>
          </div>

          {/* <div className="mt-1 mb-0 d-flex">
            <p className="mb-0 text-muted" style={{ minWidth: 80 }}>
              Date of Birth
            </p>
            <p className="mb-0 mt-0 ml-3">{data.dob ? format(new Date(data.dob), "dd MMM’ yyyy") : "N/A"}</p>
          </div> */}

          <div className="mt-1 mb-0 d-flex">
            <p className="mb-0 text-muted" style={{ minWidth: 80 }}>
              Designation
            </p>
            <p className="mb-0 mt-0 ml-3">{data.designation || "N/A"}</p>
          </div>

          <div className="mt-1 mb-0 d-flex">
            <p className="mb-0 text-muted" style={{ minWidth: 80 }}>
              Email
            </p>
            <p className="mt-0  ml-3">{data.email || "N/A"}</p>
          </div>
          <div className="mt-1 mb-0 d-flex">
            <p className="mb-0 text-muted" style={{ minWidth: 80 }}>
              Phone
            </p>
            <p className="mt-0 ml-3">{data.phone || "N/A"}</p>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h4 className="align-middle text-center">Not Available</h4>
        </React.Fragment>
      );
    }
  }
}

export default ContactBlock;
