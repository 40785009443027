import React from "react";
import { Container, Col, Row, Button } from "reactstrap";

import HomeHeader from "../../components/Headers/HomeHeader";
import Footer from "../../views/Common/Footer";
import forCustomer from "../../assets/video/404.mp4";

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.body.classList.add("colo");
  }

  render() {
    return (
      <div>
        <HomeHeader {...this.props} />
        <div className="full-page--content">
          <div className="not-found-page video">
            <Container>
              {" "}
              <Row>
                <Col sm={5} className="d-flex h-100">
                  <div className="align-self-center">
                    <h1 className="four-o-4-text">404</h1>

                    <p>
                      The page you are looking for does not exist. <br />
                      You have landed in a 404 zone. Please go home
                    </p>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.props.history.push("/");
                      }}
                    >
                      Go Home
                    </Button>
                  </div>
                </Col>
                <Col sm={7}>
                  <video
                    playsInline
                    autoPlay
                    muted
                    loop
                    poster=""
                    id="back-video"
                  >
                    <source src={forCustomer} type="video/mp4" />
                  </video>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default NotFoundPage;
