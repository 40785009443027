import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";

import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import { searchAttribute, addMasterAttribute } from "../../datautils/actions/categoryActions";
import { getFilteredAuthData } from "../../utils/helpers/HelperFunctions";
import { PER_PAGE } from "../../utils/constants/index";
import CustomPagination from "../../components/CustomPagination/CustomPagination";

const options = [
  { value: "String", label: "String" },
  { value: "Textarea", label: "Textarea" },
  { value: "Integer", label: "Integer" },
  { value: "Float", label: "Float" },
  { value: "Long", label: "Long" },
  { value: "Double", label: "Double" },
];

class Attributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributeName: "",
      attributeType: "String",
      label: "",
      modal: false,
      isUpdated: false,
      attributeKey: "",
      placeholder: "",
      applicableOptions: "",
      helpText: "",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "name",
      ascending: false,
      attribute: "",
    };
  }

  componentDidMount() {
    this.props.resetResponseValues();
    this.searchAttr();
  }

  componentDidUpdate(prevProps, prevState) {
    const { responseVal } = this.props;
    const { pageNumber, modal, isUpdated } = this.state;

    if (prevState.pageNumber !== pageNumber) {
      this.searchAttr();
    }

    if (responseVal.status === "success" && modal && isUpdated) {
      this.setState({ modal: false, isUpdated: false });
      this.searchAttribute();
    }
  }

  changePage = (page) => () => {
    this.setState({ pageNumber: page });
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData?.searchAttribute?.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  searchAttr = () => {
    const { pageNumber, pageSize, sortField, attribute, ascending } = this.state;
    const reqData = {
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
      attribute,
    };
    this.props.searchAttribute(reqData);
  };

  toggle = () => {
    if (!this.state.modal) {
      this.props.resetResponseValues();
    }
    this.setState({
      modal: !this.state.modal,
    });
  };
  handleChangeSelect = (obj, opt) => {
    this.setState({ [obj]: opt.value });
    console.log(`Option selected:`, opt.value);
  };
  handleKeyDown = (e) => {
    this.setState({ isUpdated: false });
    if (e.key === "Enter") {
      this.props.searchAttribute({ attribute: this.state.attributeName });
    }
  };

  addNewAttribute = (e) => {
    if (this.state.modal) {
      const { attributeName, attributeType, label, placeholder, applicableOptions, helpText } = this.state;

      let applicOptions = applicableOptions.trim().split("\n");
      let heText = helpText.trim().split("\n");

      applicOptions = applicOptions?.filter(Boolean);
      applicOptions = applicOptions?.map((e) => e.trim());

      heText = heText?.filter(Boolean);
      heText = heText?.map((e) => e.trim());

      const authData = getFilteredAuthData(this.props.authData);

      const reqData = {
        attributeName: attributeName.trim(),
        attributeType: attributeType.trim(),
        placeholder: placeholder.trim(),
        applicableOptions: applicOptions,
        helpText: heText,
        label: label.trim(),
        ...authData,
      };
      //console.log("reqData", reqData);
      this.props.addMasterAttribute(reqData);
      this.setState({ isUpdated: true });
    } else {
      this.setState(
        {
          attributeKey: "",
          attributeName: "",
          attributeType: "",
          placeholder: "",
          label: "",
          applicableOptions: "",
          helpText: "",
        },
        () => this.toggle()
      );
    }
  };

  showUpdateModal = (attr) => {
    const applicOptions = attr.applicableOptions?.join("\n");
    const heText = attr.helpText?.join("\n");

    this.setState(
      {
        attributeKey: attr.attributeKey,
        attributeName: attr.attributeName,
        label: attr.label,
        attributeType: attr.attributeType,
        placeholder: attr.placeholder,
        applicableOptions: applicOptions,
        helpText: heText,
      },
      () => this.toggle()
    );
  };

  updateAttribute = (attr) => {
    if (this.state.modal) {
      const {
        attributeName,
        attributeType,
        attributeKey,
        label,
        placeholder,
        applicableOptions,
        helpText,
      } = this.state;

      let applicOptions = applicableOptions?.trim().split("\n");
      let heText = helpText?.trim().split("\n");

      applicOptions = applicOptions?.filter(Boolean);
      applicOptions = applicOptions?.map((e) => e.trim());

      heText = heText?.filter(Boolean);
      heText = heText?.map((e) => e.trim());

      const authData = getFilteredAuthData(this.props.authData);

      const reqData = {
        attributeName,
        attributeType,
        attributeKey,
        placeholder,
        applicableOptions: applicOptions || [],
        helpText: heText || [],
        label,
        ...authData,
      };

      this.props.addMasterAttribute(reqData);
      this.setState({ isUpdated: true });
    }
  };

  searchAttribute = () => {
    this.props.searchAttribute({ attribute: this.state.attributeName });
    this.setState({ modal: false, isUpdated: false });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  renderModal = () => {
    const { attributeName, label, attributeType, attributeKey, placeholder, applicableOptions, helpText } = this.state;

    return (
      <Modal backdrop="static" isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Attribute Action</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <Form>
                    <FormGroup row>
                      <Label for="type" sm={3}>
                        Type <span className="star">*</span>
                      </Label>
                      <Col sm={9}>
                        <Select
                          isSearchable={false}
                          className="react-select"
                          name="attributeType"
                          options={options}
                          value={options.filter((option) => option.value === attributeType)}
                          onChange={(opt) => this.handleChangeSelect("attributeType", opt)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="name" sm={3}>
                        Name <span className="star">*</span>
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          disabled={attributeKey.length > 0}
                          placeholder="Attribute name"
                          onChange={() => this.handleChange("attributeName")}
                          value={attributeName}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="label" sm={3}>
                        Label <span className="star">*</span>
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          placeholder="Attribute Label"
                          onChange={() => this.handleChange("label")}
                          value={label}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="label" sm={3}>
                        Placeholder Text
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          placeholder="Placeholder Text"
                          onChange={() => this.handleChange("placeholder")}
                          value={placeholder}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="label" sm={3}>
                        Applicable Options
                      </Label>
                      <Col sm={9}>
                        <Input
                          className="mb-0"
                          type="textarea"
                          placeholder="Enter each option in new line"
                          onChange={() => this.handleChange("applicableOptions")}
                          value={applicableOptions}
                        />
                        <small className="text-danger">*Please enter each option in new line</small>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="label" sm={3}>
                        Help Text
                      </Label>
                      <Col sm={9}>
                        <Input
                          className="mb-0"
                          type="textarea"
                          placeholder="Enter each sentence in new line"
                          onChange={() => this.handleChange("helpText")}
                          value={helpText}
                        />
                        <small className="text-danger">*Please enter each sentence in new line</small>
                      </Col>
                    </FormGroup>

                    {this.state.attributeKey ? (
                      <Button
                        className="btn-block btn-success"
                        onClick={() => this.updateAttribute(this.state)}
                        disabled={this.state.attributeName.length < 1 || this.state.label.length < 1}
                      >
                        Update Attribute
                      </Button>
                    ) : (
                      <Button
                        className="btn-block btn-success"
                        onClick={this.addNewAttribute}
                        disabled={this.state.attributeName.length < 1 || this.state.label.length < 1}
                      >
                        Add Attribute
                      </Button>
                    )}
                  </Form>
                </div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { attributeName, modal } = this.state;
    const { responseVal, existingAttributes, standAlone } = this.props;

    const attributes = responseVal.dObjectData?.searchAttribute?.data;

    return (
      <div className="attributes-form-box">
        {modal ? this.renderModal() : null}

        <h5>{standAlone ? null : "Search master attribute and attach to category"}</h5>
        <Row>
          <Col md="12">
            <Row>
              <Col className="mb-2">{attributes ? this.renderPagination() : null}</Col>
            </Row>
            <React.Fragment>
              <FormGroup>
                <div className="has-search">
                  <div className="input-group">
                    <Input
                      type="search"
                      placeholder="Search attribute name"
                      onChange={() => this.handleChange("attributeName")}
                      value={attributeName}
                      onKeyDown={this.handleKeyDown}
                    />
                    <div className="input-group-append">
                      <Button color="primary" onClick={this.searchAttribute} disabled={attributeName.length < 1}>
                        <i className="now-ui-icons ui-1_zoom-bold" />
                      </Button>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div style={{ minHeight: 390, display: "flex" }}>
                {attributes?.total > 0 ? (
                  <ul className="brand-suggest" style={{ minHeight: 390, width: "100%" }}>
                    {Object.keys(attributes.attributes).map((keyName, keyIndex) => {
                      const attr = attributes.attributes[keyName];

                      return (
                        <li key={"atts-" + keyIndex}>
                          {attr.attributeName} <span>( {attr.attributeType})</span>
                          <span className="badge badge-info pull-right" onClick={() => this.showUpdateModal(attr)}>
                            Edit Master
                          </span>
                          {standAlone ? null : (
                            <>
                              {attr.attributeKey in existingAttributes ? (
                                <span className="badge badge-warning pull-right">Selected</span>
                              ) : (
                                <span
                                  className="badge badge-success pull-right"
                                  onClick={this.props.handleAttribute(attr)}
                                >
                                  Select
                                </span>
                              )}
                            </>
                          )}
                        </li>
                      );
                    }, this)}
                    {/* {window.scroll({
                      top: 600,
                      left: 600,
                      behavior: "smooth"
                    })} */}
                  </ul>
                ) : null}
              </div>
              <FormGroup>
                <Button
                  className="btn-block btn-primary"
                  onClick={this.addNewAttribute}
                  disabled={attributeName.length < 1}
                >
                  Add new attribute
                </Button>
              </FormGroup>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    authData: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    searchAttribute: (reqParam) => dispatch(searchAttribute(reqParam)),
    addMasterAttribute: (reqParam) => dispatch(addMasterAttribute(reqParam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attributes);
