import React from "react";
import { Row, Col, Button, FormGroup, Input } from "reactstrap";
import { connect } from "react-redux";
import { resetResponseValues } from "../../../datautils/actions/responseHandlerActions";
import { searchBrand } from "../../../datautils/actions/skuActions";

class Brand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      page: "brand",
      newList: []
    };
  }

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.props.searchBrand({ globalSearchKeyword: this.state.term });
    }
  };

  searchData = () => {
    this.props.searchBrand({ globalSearchKeyword: this.state.term });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { term, page } = this.state;
    const { dObjectData } = this.props;

    const data =
      dObjectData.brands &&
      dObjectData.brands.data &&
      dObjectData.brands.data.brands
        ? dObjectData.brands.data.brands
        : null;

    return (
      <div className="attributes-form-box">
        <h5>Search {page} and attach</h5>
        <Row>
          <Col md="12">
            <React.Fragment>
              <FormGroup>
                <div className="has-search">
                  <div className="input-group">
                    <Input
                      type="search"
                      name="term"
                      placeholder={`Search ${page}`}
                      onChange={this.handleChange}
                      value={term}
                      onKeyDown={this.handleKeyDown}
                    />
                    <div className="input-group-append">
                      <Button
                        color="primary"
                        onClick={this.searchData}
                        disabled={term.length < 1}
                      >
                        <i className="now-ui-icons ui-1_zoom-bold" />
                      </Button>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div style={{ minHeight: 390, display: "flex" }}>
                {data ? (
                  <ul
                    className="brand-suggest"
                    style={{ minHeight: 390, width: "100%" }}
                  >
                    {Object.keys(data).map(function(keyName, keyIndex) {
                      const row = data[keyName];

                      return (
                        <li key={"atts-" + keyIndex}>
                          {row.name}
                          <span
                            className="badge badge-success pull-right"
                            onClick={this.props.handleAttachment({
                              entityLabel: row.name,
                              entityType: "BRAND",
                              entityIdentifierField: "id",
                              entityIdentifierFieldValue: row.id,
                              status: "ACTIVE",
                              weight: 2
                            })}
                          >
                            Select
                          </span>
                        </li>
                      );
                    }, this)}
                  </ul>
                ) : null}
              </div>
              <FormGroup />
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.ui.isLoading,
    dObjectData: state.resHandlerVal.dObjectData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    searchBrand: reqParam => dispatch(searchBrand(reqParam))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Brand);
