import React from "react";
import { Switch, Route } from "react-router-dom";

import Header from "../../../components/Headers/DashboardHeader";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { LOGIN } from "../../../utils/constants/clientUrls";
import { isUserLoggedIn, isValidRoute } from "../../../utils/helpers/HelperFunctions";

import supplierRoutes from "../../../config/routes/supplier";
import { connect } from "react-redux";

import NotAuthorised from "../../../views/Common/NotAuthorised";

class Dashboard extends React.Component {
  componentDidMount() {
    if (!isUserLoggedIn()) {
      this.props.history.push(LOGIN);
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  render() {
    const {
      authData: { userType, userRole },
    } = this.props;

    const availableRoutes = [];
    supplierRoutes.forEach((prop) => {
      if (isValidRoute(prop.componentName, userType, userRole)) {
        availableRoutes.push(prop);
      }
    });

    return (
      <div className="wrapper">
        <Sidebar {...this.props} />
        <div className="main-panel" ref="mainPanel">
          <Header {...this.props} />
          <Switch>
            {availableRoutes?.map((prop, key) => {
              return <Route exact path={prop.path} component={prop.component} key={"supplier-" + key} />;
            })}
            <Route component={NotAuthorised} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authData: state.auth,
  };
};

export default connect(mapStateToProps)(Dashboard);
