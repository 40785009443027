import React from "react";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

const goBack = (props) => {
  const prevPath = props.history?.location?.state?.prevPath;

  let str = props.text;
  if (str && str.length > 40) str = str.substring(0, 40) + "...";
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb mt-0 pt-0">
        <li className="breadcrumb-item">
          <span onClick={() => (prevPath ? props.history.push(prevPath) : history.goBack())}>&laquo; Go Back</span>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {str}
        </li>
      </ol>
    </nav>
  );
};

export default goBack;
