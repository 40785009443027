import React from "react";
import { Container, Col, Row } from "reactstrap";

import HomeHeader from "../../components/Headers/HomeHeader";
import Footer from "../../views/Common/Footer";
import About from "../../components/StaticPages/About";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.body.classList.add("colo");
  }

  render() {
    return (
      <div>
        <HomeHeader {...this.props} />
        <div className="full-page--content">
          <div className="not-found-page video">
            <Container>
              <Row>
                <Col sm={10} className="mx-auto">
                  <About />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AboutPage;
