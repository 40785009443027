import { startUILoading, stopUILoading } from "./uiActions";
import { setResponseValues, resetResponseValues } from "./responseHandlerActions";

import axios from "axios";

import { MEDIA_UPLOAD_API } from "../../utils/constants/apiUrls";
import { FAILURE } from "../../utils/constants";
import { getRequestHeaders } from "./authActions";

export const uploadMedia = (dataFile, fileId) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    try {
      const config = await dispatch(getRequestHeaders());
      const URL = MEDIA_UPLOAD_API + "?mtype=image&mcategory=supplier";
      console.log("RR", URL, dataFile);
      if (dataFile) {
        const formData = new FormData();

        formData.append("uploadedfile", dataFile);
        const response = await axios.post(URL, formData, config);
        return response.data;
      } else {
        return "";
      }
    } catch (error) {
      console.log("Media Upload Error", error);
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const uploadBase64Media = (dataFile, fileId) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    try {
      const config = await dispatch(getRequestHeaders());
      const URL = MEDIA_UPLOAD_API + "?mtype=image&mcategory=supplier";
      if (dataFile) {
        const response = await axios.post(URL, dataFile?.split(",")[1], config);
        return response.data;
      } else {
        return "";
      }
    } catch (error) {
      console.log("Media Upload Error", error);
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};
