import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import HomeHeader from "../../components/Headers/HomeHeader";
import Footer from "../../views/Common/Footer";
import Terms from "../../components/StaticPages/Terms";
import { APP_NAME } from "../../utils/constants";

class TermsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.body.classList.add("colo");
  }

  render() {
    return (
      <div>
        <HomeHeader {...this.props} />
        <Helmet>
          <title>Terms of Service - {APP_NAME}</title>
        </Helmet>
        <div className="full-page--content">
          <div className="not-found-page video">
            <Container>
              <Row>
                <Col sm={12} className="d-flex h-100">
                  <div className="col-sm-10 mx-auto">
                    <Row>
                      <Terms />
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TermsPage;
