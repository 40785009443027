import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateSample,
  getSampleDetail,
} from "../../datautils/actions/sampleRequestsActions";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";

import PanelHeader from "../../components/PanelHeader/PanelHeader";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import {
  SUPPLIER_SKU_VIEW,
  ADMIN_SKU_VIEW,
} from "../../utils/constants/clientUrls";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";

import { sampleRequestStatusTypes } from "../../utils/constants";

class Sample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      updatedOn: null,
      formData: {},
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getSampleDetail({ skuSampleRequestId: id });
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;

    const { sampleItems } = this.props;

    // if (this.state.id !== id) {
    //   this.setState({ id, formData: { ...formData, skuSampleRequestId: id } });
    //   this.props.getSampleDetail({ skuSampleRequestId: id });
    // }

    if (
      sampleItems.sampleDetail &&
      sampleItems.sampleDetail.updatedOn !== prevState.updatedOn
    ) {
      const data = sampleItems.sampleDetail;

      let future = new Date();
      future.setDate(future.getDate() + 10);

      let endDateTime = data.offerExpiryDate
        ? new Date(data.offerExpiryDate)
        : future;

      this.setState({
        ...this.state,
        id,
        formData: {
          ...data,
          sampleOrderExpiryDate: data.sampleOrderExpiryDate || endDateTime,
        },
        updatedOn: data.updatedOn,
      });
    }
  }

  handleChange = (event) => {
    let eTargetValue = event.target.value;

    if (
      event.target.name === "sampleOrderQuantity" ||
      event.target.name === "sampleDeliveryCharge"
    ) {
      eTargetValue = Math.round(eTargetValue);
    }

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        [event.target.name]: eTargetValue,
      },
    });
  };

  formatDate = (date) => {
    const now = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const first = now.substr(0, now.length - 3);
    const last = now.substr(-3);
    return first + last.replace(":", "");
  };

  handleChangeEndDate = (date) => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, sampleOrderExpiryDate: date },
    });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [obj]: opt.value },
    });
  };

  updateSample = () => {
    this.props.updateSample({ ...this.state.formData });
  };

  render() {
    const { sampleItems, isLoading, userType } = this.props;
    const { formData } = this.state;

    const responseData = sampleItems.sampleDetail;

    let future = new Date();
    future.setDate(future.getDate() + 10);

    let endDateTime = formData.sampleOrderExpiryDate
      ? new Date(formData.sampleOrderExpiryDate)
      : future;

    return (
      <div>
        <PanelHeader />

        <div className="content mt-3">
          <Row>
            <Col xs={12}>
              <MessageBlock screenName="sampleoffer" />
            </Col>

            <Col xs={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-0 pt-0">
                  <li className="breadcrumb-item">
                    <span onClick={() => this.props.history.goBack()}>
                      &laquo; Go Back
                    </span>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sample Details
                  </li>
                </ol>
              </nav>
              <Row>
                <Col sm={8}>
                  {responseData ? (
                    <>
                      <Card className="card--default">
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <h5 className="float--left">
                                Sample Details{" "}
                                {responseData.ordered ? (
                                  <span className="text-success float-right">
                                    Sample Ordered By Customer
                                  </span>
                                ) : null}
                              </h5>

                              <div className="clearfix" />
                              <p style={{ borderBottom: "2px solid #eee" }}>
                                <span className="item-ord">
                                  Requested on{" "}
                                  {moment(responseData.createdOn).format(
                                    "ddd, Do MMM 'YY, h:mm a"
                                  )}{" "}
                                </span>
                                <span className="pipe" />
                                <span className="item-ord">
                                  Request# {responseData.skuSampleRequestId}
                                </span>
                                <span className="float-right">
                                  Status: {responseData.sampleStatus}
                                </span>
                              </p>
                            </Col>

                            <Col sm={8}>
                              <Row>
                                <Col sm={6}>
                                  <p>
                                    Sample MRP:{" "}
                                    <span className="float-right">
                                      &#x20b9;
                                      {responseData.sampleUnitMrp &&
                                        responseData.sampleUnitMrp
                                          .toFixed(2)
                                          .toLocaleString()}
                                    </span>
                                    <br />
                                    Sample Selling Price:{" "}
                                    <span className="float-right">
                                      &#x20b9;
                                      {responseData.sampleUnitSellingPrice &&
                                        responseData.sampleUnitSellingPrice
                                          .toFixed(2)
                                          .toLocaleString()}
                                    </span>
                                    <br />
                                    Sample Offer Price:{" "}
                                    <span className="float-right">
                                      &#x20b9;
                                      {responseData.sampleUnitOfferPrice &&
                                        responseData.sampleUnitOfferPrice
                                          .toFixed(2)
                                          .toLocaleString()}
                                    </span>
                                    <br /> <br />
                                    Sample Final Offer price:{" "}
                                    <span className="float-right">
                                      &#x20b9;
                                      {responseData.sampleUnitFinalOfferPrice &&
                                        responseData.sampleUnitFinalOfferPrice
                                          .toFixed(2)
                                          .toLocaleString()}
                                    </span>
                                    <br />
                                    Sample Delivery Charge:{" "}
                                    <span className="float-right">
                                      &#x20b9;
                                      {responseData.sampleDeliveryCharge &&
                                        responseData.sampleDeliveryCharge.toLocaleString()}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                              {userType === "SUPPLIER" ? (
                                <Row>
                                  <Col sm={12}>
                                    <div className="price-invent sku-form-box samplepage">
                                      <h5 className="card-header">
                                        Update Sample Request
                                      </h5>

                                      <div className="card-body">
                                        {formData ? (
                                          <Form>
                                            <fieldset
                                              disabled={
                                                responseData.sampleStatus ===
                                                  "DELETED" ||
                                                responseData.ordered
                                              }
                                            >
                                              <FormGroup row>
                                                <Label
                                                  for="description1"
                                                  sm={6}
                                                >
                                                  Sample Status
                                                </Label>
                                                <Col sm={6}>
                                                  <Select
                                                    placeholder="Select Action"
                                                    isSearchable={false}
                                                    options={
                                                      sampleRequestStatusTypes
                                                    }
                                                    value={sampleRequestStatusTypes.filter(
                                                      (option) =>
                                                        option.value ===
                                                        formData.sampleStatus
                                                    )}
                                                    onChange={(opt) =>
                                                      this.handleChangeSelect(
                                                        "sampleStatus",
                                                        opt
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              </FormGroup>

                                              {formData.sampleStatus !==
                                              "REJECTED" ? (
                                                <>
                                                  <FormGroup row>
                                                    <Label
                                                      for="description1"
                                                      sm={6}
                                                    >
                                                      Sample Final Price
                                                    </Label>
                                                    <Col sm={6}>
                                                      <Input
                                                        type="number"
                                                        min="1"
                                                        step="0.01"
                                                        name="sampleUnitFinalOfferPrice"
                                                        placeholder="Final price"
                                                        value={
                                                          formData.sampleUnitFinalOfferPrice
                                                            ? formData.sampleUnitFinalOfferPrice
                                                            : ""
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                      />
                                                    </Col>
                                                  </FormGroup>

                                                  <FormGroup row>
                                                    <Label
                                                      for="description1"
                                                      sm={6}
                                                    >
                                                      Sample Quantity
                                                    </Label>
                                                    <Col sm={6}>
                                                      <Input
                                                        type="text"
                                                        min="1"
                                                        step="1"
                                                        pattern="[0-9]+"
                                                        name="sampleOrderQuantity"
                                                        placeholder="Quantity"
                                                        value={
                                                          formData.sampleOrderQuantity ||
                                                          ""
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                      />
                                                    </Col>
                                                  </FormGroup>
                                                  <FormGroup row>
                                                    <Label
                                                      for="description1"
                                                      sm={6}
                                                    >
                                                      Sample Delivery Charge
                                                    </Label>
                                                    <Col sm={6}>
                                                      <Input
                                                        type="text"
                                                        min="1"
                                                        step="1"
                                                        pattern="[0-9]+"
                                                        name="sampleDeliveryCharge"
                                                        placeholder="Delivery charge"
                                                        value={
                                                          formData.sampleDeliveryCharge >
                                                          0
                                                            ? formData.sampleDeliveryCharge
                                                            : 0
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                      />
                                                    </Col>
                                                  </FormGroup>
                                                  <FormGroup row>
                                                    <Label for="title" sm={6}>
                                                      Sample Expiry Date{" "}
                                                      <span className="star">
                                                        *
                                                      </span>
                                                    </Label>
                                                    <Col sm={6}>
                                                      <DatePicker
                                                        className="form-control"
                                                        placeholderText="Click to select expiry date"
                                                        showTimeSelect
                                                        selected={endDateTime}
                                                        onChange={
                                                          this
                                                            .handleChangeEndDate
                                                        }
                                                        timeFormat="HH:mm"
                                                        timeIntervals={60}
                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                        timeCaption="Time"
                                                        minDate={new Date()}
                                                        withPortal
                                                      />
                                                    </Col>
                                                  </FormGroup>
                                                </>
                                              ) : null}

                                              <FormGroup row>
                                                <Col sm={12}>
                                                  <Button
                                                    block
                                                    color="primary"
                                                    size="md"
                                                    onClick={this.updateSample}
                                                    disabled={
                                                      formData.sampleStatus !==
                                                        "ACCEPTED" &&
                                                      formData.sampleStatus !==
                                                        "REJECTED"
                                                    }
                                                  >
                                                    {formData.sampleStatus ===
                                                    "REJECTED"
                                                      ? "Reject"
                                                      : "Accept"}{" "}
                                                    Sample Request
                                                  </Button>
                                                </Col>
                                              </FormGroup>
                                            </fieldset>
                                          </Form>
                                        ) : null}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : null}
                            </Col>
                            <Col sm={4}>
                              <h6
                                style={{
                                  textTransform: "none",
                                  marginBottom: "5px",
                                }}
                              >
                                <Link
                                  to={
                                    (userType === "SUPPLIER"
                                      ? SUPPLIER_SKU_VIEW
                                      : ADMIN_SKU_VIEW) + responseData.skuId
                                  }
                                >
                                  {responseData.productName}
                                </Link>
                              </h6>

                              <p>
                                {responseData.variantType +
                                  ": " +
                                  responseData.variantName}
                                <br />
                              </p>
                              <p>
                                {responseData.productImageFileId ? (
                                  <ImageZoom
                                    src={getMediaPath(
                                      responseData.productImageFileId,
                                      responseData.productImageFileExt
                                    )}
                                  />
                                ) : null}
                              </p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    profile: state.profile,
    sampleItems: state.sampleItems,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    getSampleDetail: (reqData) => dispatch(getSampleDetail(reqData)),
    updateSample: (reqData) => dispatch(updateSample(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sample);
