import React from "react";
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateOrder, fetchOrder, generateInvoice, updateTracking } from "../../datautils/actions/orderActions";
import { resetResponseValues, resetObjectValues } from "../../datautils/actions/responseHandlerActions";
import moment from "moment";

import PanelHeader from "../../components/PanelHeader/PanelHeader";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import { ADMIN_PRODUCT_VIEW, ADMIN_ORDER_VIEW, ADMIN_SUPPLIERR_VIEW } from "../../utils/constants/clientUrls";
import { getMediaPath, formatDate } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";
import {
  orderActions,
  deliveryPartners,
  trackingOptions,
  pickUpAddressChangeOptions,
  returnAddressChangeOptions,
  shippingInfoChangeOptions,
} from "../../utils/constants/index";
import Select from "react-select";
import externl from "../../assets/img/external.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      modal2: false,
      notes: "",
      currentAction: "",
      currentActionType: "",
      currentTitle: "",
      supplierPickUpAddressId: 0,
      supplierReturnAddressId: 0,
      packageDeliveryPartnerRefNumber: null,
      packageDeliveryPartnerCode: null,
      actionTime: new Date(),
      location: "",
      updatedOn: "",
      trackingAction: "",
      currentOrder: {},
    };
  }

  toggle2 = (requestData) => {
    const { modal2, currentOrder } = this.state;
    if (modal2) {
      this.setState({
        modal2: false,
        notes: "",
        currentTitle: "",
        currentAction: "",
        currentActionType: "",
        supplierPickUpAddressId: currentOrder && currentOrder.supplierPickUpAddressId,
        supplierReturnAddressId: currentOrder && currentOrder.supplierReturnAddressId,
        packageDeliveryPartnerRefNumber: currentOrder && currentOrder.packageDeliveryPartnerRefNumber,
        packageDeliveryPartnerCode: currentOrder && currentOrder.packageDeliveryPartnerCode,
      });
    } else {
      this.setState({
        modal2: true,
        notes: "",
        currentTitle: requestData.title,
        currentAction: requestData.action,
        currentActionType: requestData.actionType,
        isUpdated: false,
      });
      this.props.resetResponseValues();
    }
  };

  componentDidMount() {
    this.props.resetResponseValues();
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    const { responseVal } = this.props;
    const { isUpdated, modal2 } = this.state;
    if (this.state.id !== id) {
      this.setState({ id, isUpdated: false });
      this.props.fetchOrder({ order: { orderLineItemId: id } });
    }

    if (responseVal.status === "success" && isUpdated && modal2) {
      this.setState({ modal2: false });
    }

    const orderObject = responseVal?.dObjectData?.orderDetail?.data?.order?.defaultOrderLineItem;

    if (orderObject && orderObject.updatedOn !== prevState.updatedOn) {
      this.setState({
        currentOrder: orderObject,
        supplierPickUpAddressId: orderObject.supplierPickUpAddressId,
        supplierReturnAddressId: orderObject.supplierReturnAddressId,
        packageDeliveryPartnerRefNumber: orderObject.packageDeliveryPartnerRefNumber,
        packageDeliveryPartnerCode: orderObject.packageDeliveryPartnerCode,
        updatedOn: orderObject.updatedOn,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetObjectValues("orderDetail");
  }

  handleChangeStartDate = (date) => {
    this.setState({ actionTime: date });
  };

  handleChangeNotes = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState({ [obj]: opt.value });
  };

  updateOrder = (actionType) => (e) => {
    e.preventDefault();

    const {
      notes,
      modal2,
      id,
      supplierPickUpAddressId,
      supplierReturnAddressId,
      packageDeliveryPartnerRefNumber,
      packageDeliveryPartnerCode,
      actionTime,
      location,
    } = this.state;

    const postData = {
      updateType: "status",
      notes,
      order: {
        orderLineItemId: id,
        status: actionType,
        supplierPickUpAddressId,
        supplierReturnAddressId,
        packageDeliveryPartnerRefNumber,
        packageDeliveryPartnerCode,
        deliveryStatusLog: {
          actionType: "SHIPPED",
          actionDate: formatDate(actionTime),
          location,
        },
      },
    };

    if (modal2) {
      this.setState({ isUpdated: true });
      this.props.updateOrder(postData);
    } else {
      this.toggle2({
        actionType,
        action: "update",
        title: "Update Order",
      });
    }
  };

  updateTracking = (actionType) => (e) => {
    e.preventDefault();
    const { notes, modal2, id, trackingAction, actionTime, location } = this.state;

    const postData = {
      updateType: "status",
      notes,
      order: {
        orderLineItemId: id,
        deliveryStatusLog: {
          actionType: trackingAction,
          actionDate: formatDate(actionTime),
          location,
        },
      },
    };

    if (modal2) {
      this.setState({ isUpdated: true });
      this.props.updateTracking(postData);
    } else {
      this.toggle2({
        actionType,
        action: "update",
        title: "Update Tracking",
      });
    }
  };

  renderModal2 = () => {
    const {
      notes,
      currentAction,
      currentActionType,
      currentTitle,
      supplierPickUpAddressId,
      supplierReturnAddressId,
      packageDeliveryPartnerRefNumber,
      packageDeliveryPartnerCode,
      actionTime,
      location,
      trackingAction,
      currentOrder,
    } = this.state;

    const {
      profile: { address },
    } = this.props;

    let pickupOptions = [];

    const pickUpAddress = address && address.addressDetailListMap && address.addressDetailListMap["PICKUP_ADDRESS"];

    pickUpAddress &&
      pickUpAddress.map((p) => {
        return pickupOptions.push({
          value: p.id,
          label: (p.status !== "ACTIVE" ? p.status + " - " : "") + p.fullAddress,
          isDisabled: p.status === "ACTIVE" ? false : true,
        });
      });

    let returnOptions = [];

    const returnAddress = address && address.addressDetailListMap && address.addressDetailListMap["RETURN_ADDRESS"];

    returnAddress &&
      returnAddress.map((p) => {
        return returnOptions.push({
          value: p.id,
          label: (p.status !== "ACTIVE" ? p.status + " - " : "") + p.fullAddress,
          isDisabled: p.status === "ACTIVE" ? false : true,
        });
      });

    let pickUpAddressChangeAllowed = false;
    let returnAddressChangeAllowed = false;

    if (currentOrder && currentOrder.status) {
      pickUpAddressChangeAllowed = pickUpAddressChangeOptions["SUPPLIER"][currentOrder.status];
      returnAddressChangeAllowed = returnAddressChangeOptions["SUPPLIER"][currentOrder.status];
    }

    return (
      <Modal backdrop="static" isOpen={this.state.modal2} toggle={this.toggle2}>
        <ModalHeader toggle={this.toggle2}>{currentTitle.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    <Form autoComplete="off">
                      {currentActionType === "ORDER_APPROVED" || currentActionType === "UPDATE_WAREHOUSE" ? (
                        <>
                          {(currentActionType === "ORDER_APPROVED" || pickUpAddressChangeAllowed) && (
                            <FormGroup>
                              <label>Select Pickup Address</label>
                              <Select
                                isSearchable={false}
                                className="react-select"
                                name="supplierPickUpAddressId"
                                options={pickupOptions}
                                value={pickupOptions.filter(
                                  (option) => parseInt(option.value) === parseInt(supplierPickUpAddressId)
                                )}
                                onChange={(opt) => this.handleChangeSelect("supplierPickUpAddressId", opt)}
                              />
                            </FormGroup>
                          )}
                          {(currentActionType === "ORDER_APPROVED" || returnAddressChangeAllowed) && (
                            <FormGroup>
                              <label>Select Return Address</label>
                              <Select
                                isSearchable={false}
                                className="react-select"
                                name="supplierReturnAddressId"
                                options={returnOptions}
                                value={returnOptions.filter(
                                  (option) => parseInt(option.value) === parseInt(supplierReturnAddressId)
                                )}
                                onChange={(opt) => this.handleChangeSelect("supplierReturnAddressId", opt)}
                              />
                            </FormGroup>
                          )}
                        </>
                      ) : null}

                      {currentActionType === "UPDATE_TRACKING" && (
                        <>
                          <FormGroup>
                            <label>
                              Select Action
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              isSearchable={false}
                              className="react-select"
                              name="trackingAction"
                              options={trackingOptions}
                              value={trackingOptions.filter((option) => option.value === trackingAction)}
                              onChange={(opt) => this.handleChangeSelect("trackingAction", opt)}
                            />
                          </FormGroup>
                        </>
                      )}

                      {currentActionType === "ORDER_SHIPPED" || currentActionType === "UPDATE_DELIVERY_INFO" ? (
                        <>
                          <FormGroup>
                            <label>
                              Select Delivery Partner
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              isSearchable={false}
                              className="react-select"
                              name="packageDeliveryPartnerCode"
                              options={deliveryPartners}
                              value={deliveryPartners.filter((option) => option.value === packageDeliveryPartnerCode)}
                              onChange={(opt) => this.handleChangeSelect("packageDeliveryPartnerCode", opt)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label>
                              Enter Ref Number
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              type="text"
                              placeholder="Add Ref Number"
                              onChange={this.handleChangeNotes("packageDeliveryPartnerRefNumber")}
                              value={packageDeliveryPartnerRefNumber}
                            />
                          </FormGroup>
                        </>
                      ) : null}

                      {(currentActionType === "ORDER_SHIPPED" || currentActionType === "UPDATE_TRACKING") && (
                        <>
                          <FormGroup>
                            <label>
                              Location<span className="text-danger">*</span>
                            </label>
                            <Input
                              type="text"
                              placeholder="Add Location"
                              onChange={this.handleChangeNotes("location")}
                              value={location}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label>
                              Enter Date<span className="text-danger">*</span>
                            </label>
                            <DatePicker
                              className="form-control"
                              placeholderText="Click to select start date"
                              showTimeSelect
                              selected={actionTime}
                              onChange={this.handleChangeStartDate}
                              timeFormat="HH:mm"
                              timeIntervals={30}
                              dateFormat="yyyy-MM-dd HH:mm:ss"
                              timeCaption="Time"
                              maxDate={new Date()}
                              withPortal
                            />
                          </FormGroup>{" "}
                        </>
                      )}

                      <FormGroup>
                        <label>Add notes or comments</label>
                        <Input
                          type="textarea"
                          placeholder="Add notes/reason"
                          onChange={this.handleChangeNotes("notes")}
                          value={notes}
                        />
                      </FormGroup>
                    </Form>
                    {this.renderSwitch(currentActionType, currentAction)}
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  renderSwitch(currentActionType, action) {
    const {
      supplierPickUpAddressId,
      supplierReturnAddressId,
      packageDeliveryPartnerRefNumber,
      packageDeliveryPartnerCode,
      location,
      trackingAction,
      notes,
    } = this.state;
    if (action === "update") {
      switch (currentActionType) {
        case "UPDATE_TRACKING":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateTracking("UPDATE_TRACKING")}
              disabled={!location || !trackingAction || notes?.replace(/\s+/g, "")?.length < 1}
            >
              Update Tracking
            </Button>
          );

        case "UPDATE_WAREHOUSE":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateOrder("UPDATE_WAREHOUSE")}
              disabled={!supplierPickUpAddressId || !supplierReturnAddressId || notes?.replace(/\s+/g, "")?.length < 1}
            >
              Update Address
            </Button>
          );
        case "UPDATE_DELIVERY_INFO":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateOrder("UPDATE_DELIVERY_INFO")}
              disabled={
                !packageDeliveryPartnerRefNumber ||
                !packageDeliveryPartnerCode ||
                notes?.replace(/\s+/g, "")?.length < 1
              }
            >
              Update Delivey Info
            </Button>
          );
        case "ORDER_APPROVED":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateOrder("ORDER_APPROVED")}
              disabled={!supplierPickUpAddressId || !supplierReturnAddressId || notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark Order as Approved
            </Button>
          );

        case "ORDER_ON_HOLD":
          return (
            <Button
              className="btn-block btn-warning"
              onClick={this.updateOrder("ORDER_ON_HOLD")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark as OnHold
            </Button>
          );
        case "ORDER_REJECTED":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateOrder("ORDER_REJECTED")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark as Rejected
            </Button>
          );
        case "ORDER_PACKED":
          return (
            <Button
              color="success"
              className="btn-block"
              onClick={this.updateOrder("ORDER_PACKED")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark as Order Packed
            </Button>
          );
        case "ORDER_SHIPPED":
          return (
            <Button
              color="success"
              className="btn-block"
              onClick={this.updateOrder("ORDER_SHIPPED")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark as Order Packed
            </Button>
          );
        case "ORDER_CANCELLED":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateOrder("ORDER_CANCELLED")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark as Order Cancelled
            </Button>
          );
        case "ORDER_DELIVERY_CANCELLED":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateOrder("ORDER_DELIVERY_CANCELLED")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark Order as Delivery Cancelled
            </Button>
          );
        case "ORDER_DELIVERED":
          return (
            <Button
              color="success"
              className="btn-block"
              onClick={this.updateOrder("ORDER_DELIVERED")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark as Order Delivered
            </Button>
          );

        case "ORDER_RETURNED":
          return (
            <Button
              color="success"
              className="btn-block"
              onClick={this.updateOrder("ORDER_RETURNED")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark as Order Returned
            </Button>
          );
        case "ORDER_RETURNED_REQUESTED":
        case "ORDER_RETURNED_REQUEST_APPROVED":
        case "ORDER_RETURNED_REQUEST_REJECTED":
        case "ORDER_RETURNED_REQUEST_SHIPPED":
        case "ORDER_RETURNED_REQUEST_COMPLETE":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateOrder(currentActionType)}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark Order as{" "}
              {currentActionType &&
                currentActionType.replace("ORDER_", "").replace("ED_REQUEST_", " ").replace(/_/g, " ").toLowerCase()}
            </Button>
          );
        case "ORDER_CLOSED":
          return (
            <Button
              color="success"
              className="btn-block"
              onClick={this.updateOrder("ORDER_CLOSED")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark as Order Closed
            </Button>
          );

        default:
          return null;
      }
    }
  }

  render() {
    const {
      responseVal,
      isLoading,
      profile: { address },
    } = this.props;
    const { isUpdated, modal2 } = this.state;

    const dataLoaded =
      responseVal.dObjectData.orderDetail &&
      responseVal.dObjectData.orderDetail.data &&
      responseVal.dObjectData.orderDetail.data.order
        ? responseVal.dObjectData.orderDetail.data.order
        : null;

    const currentOrder = dataLoaded ? dataLoaded.defaultOrderLineItem : null;
    const otherOrders = dataLoaded ? dataLoaded.otherOrderLineItems : null;

    const currentPickUpAddressId = currentOrder && currentOrder.supplierPickUpAddressId;

    const pickUpAddress = address && address.addressDetailListMap && address.addressDetailListMap["PICKUP_ADDRESS"];

    const returnAddress = address && address.addressDetailListMap && address.addressDetailListMap["RETURN_ADDRESS"];

    const currentPickUpAddress =
      currentPickUpAddressId &&
      pickUpAddress &&
      pickUpAddress.find((p) => parseInt(p.id) === parseInt(currentPickUpAddressId));

    const currentReturnAddressId = currentOrder && currentOrder.supplierReturnAddressId;

    const currentReturnAddress =
      currentReturnAddressId &&
      returnAddress &&
      returnAddress.find((p) => parseInt(p.id) === parseInt(currentReturnAddressId));

    const productLoaded =
      responseVal.dObjectData.productInfo &&
      responseVal.dObjectData.productInfo.data &&
      responseVal.dObjectData.productInfo.data.product
        ? responseVal.dObjectData.productInfo.data.product
        : null;

    const profileInfo =
      responseVal.dObjectData.supplierDetail &&
      responseVal.dObjectData.supplierDetail.data &&
      responseVal.dObjectData.supplierDetail.data.profileInfo
        ? responseVal.dObjectData.supplierDetail.data.profileInfo
        : null;

    const statusLog = currentOrder && currentOrder.statusChangeLog && currentOrder.statusChangeLog.statusLog;

    const deliveryStatusLog =
      currentOrder && currentOrder.statusChangeLog && currentOrder.statusChangeLog.deliveryStatusLogs;

    let orderMap = [];
    let pickUpAddressChangeAllowed = false;
    let returnAddressChangeAllowed = false;
    let shippingInfoChangeAllowed = false;

    if (currentOrder && currentOrder.status) {
      orderMap = [...orderActions["ADMIN"][currentOrder.status]];
      pickUpAddressChangeAllowed = pickUpAddressChangeOptions["ADMIN"][currentOrder.status];
      returnAddressChangeAllowed = returnAddressChangeOptions["ADMIN"][currentOrder.status];
      shippingInfoChangeAllowed = shippingInfoChangeOptions["ADMIN"][currentOrder.status];
    }

    const isDelivered = deliveryStatusLog && deliveryStatusLog.find((c) => c.actionType === "DELIVERED");

    return (
      <div>
        <PanelHeader />
        {modal2 ? this.renderModal2() : null}
        <div className="content mt-3">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}

              {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}
            </Col>

            <Col xs={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-0 pt-0">
                  <li className="breadcrumb-item">
                    <span onClick={() => this.props.history.goBack()}>&laquo; Go Back</span>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Order Line Item Details
                  </li>
                </ol>
              </nav>
              <Row>
                <Col sm={8}>
                  {currentOrder ? (
                    <>
                      <Card className="card--default">
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <h5 className="float-left">Order Line Item Details </h5>

                              <div className="clearfix" />
                              <p style={{ borderBottom: "2px solid #eee" }}>
                                <span className="item-ord">
                                  Ordered on {moment(dataLoaded.orderedOn).format("DD MMM YYYY, hh:mm a")}{" "}
                                </span>
                                <span className="pipe" />
                                <span className="item-ord">Order# {currentOrder.orderRefNumber}</span>
                                <span className="float-right">
                                  Order Type: {currentOrder.orderType && currentOrder.orderType.replace("_", " ")}
                                </span>
                              </p>
                            </Col>

                            <Col sm={4}>
                              {currentOrder.deliveryAddress ? (
                                <>
                                  <h6
                                    style={{
                                      textTransform: "none",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    Shipping Address
                                  </h6>
                                  <p>
                                    <b>{currentOrder.deliveryAddress.addressContactPersonName}</b>
                                    <br />
                                    {currentOrder.deliveryAddress.line1Address},{" "}
                                    {currentOrder.deliveryAddress.line2Address}
                                    <br />
                                    {currentOrder.deliveryAddress.localityName}, {currentOrder.deliveryAddress.landmark}
                                    <br />
                                    {currentOrder.deliveryAddress.cityName}
                                  </p>
                                  <p>
                                    {currentOrder.deliveryAddress.stateName} -{currentOrder.deliveryAddress.pincode}
                                    <br />
                                    {currentOrder.deliveryAddress.countryName}
                                  </p>
                                  <p>{currentOrder.deliveryAddress.addressContactPhoneNumber}</p>
                                </>
                              ) : null}
                            </Col>
                            <Col sm={4}>
                              {currentOrder.billingAddress ? (
                                <>
                                  {" "}
                                  <h6
                                    style={{
                                      textTransform: "none",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    Billing Address
                                  </h6>
                                  <p>
                                    <b>{currentOrder.billingAddress.addressContactPersonName}</b>
                                    <br />
                                    {currentOrder.billingAddress.line1Address},
                                    {currentOrder.billingAddress.line2Address}
                                    <br />
                                    {currentOrder.billingAddress.localityName}, {currentOrder.billingAddress.landmark}
                                    <br />
                                    {currentOrder.billingAddress.cityName},{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    {currentOrder.billingAddress.stateName} {currentOrder.billingAddress.pincode} <br />
                                    {currentOrder.billingAddress.countryName}
                                  </p>{" "}
                                  <p>{currentOrder.billingAddress.addressContactPhoneNumber}</p>
                                </>
                              ) : null}
                            </Col>
                            <Col sm={4}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  height: "100%",
                                }}
                              >
                                <div>
                                  <h6
                                    style={{
                                      textTransform: "none",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    Order Summary
                                  </h6>
                                  <p>
                                    Total Price:{" "}
                                    <span className="float-right">
                                      &#x20b9;
                                      {currentOrder.totalFinalOfferPrice &&
                                        currentOrder.totalFinalOfferPrice.toFixed(2).toLocaleString()}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  {currentOrder.invoiceFileId ? (
                                    <>
                                      <div>
                                        <a
                                          className="btn btn-sm btn-info"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={getMediaPath(currentOrder.invoiceFileId, currentOrder.invoiceFileExt)}
                                        >
                                          Download Invoice
                                        </a>
                                      </div>
                                      <div>
                                        <a
                                          className="btn btn-sm btn-info"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={getMediaPath(
                                            currentOrder.packageDeliveryFileId,
                                            currentOrder.packageDeliveryFileExt
                                          )}
                                        >
                                          Download Delivery Label
                                        </a>
                                      </div>
                                    </>
                                  ) : currentPickUpAddress || currentReturnAddress ? (
                                    <Button
                                      className="btn btn-sm btn-info"
                                      disabled={false}
                                      onClick={() =>
                                        this.props.generateInvoice({
                                          order: {
                                            orderLineItemId: this.state.id,
                                          },
                                        })
                                      }
                                    >
                                      Generate Invoice
                                    </Button>
                                  ) : null}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <Card className="card--default">
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <h5 className="float-left">Line Item Pricing Details </h5>

                              <div className="clearfix" />
                              <p style={{ borderBottom: "2px solid #eee" }} />
                            </Col>

                            <Col sm={4}>
                              <dl className="row">
                                <dt className="col-sm-8">Quantity</dt>
                                <dd className="col-sm-4">{currentOrder.quantity}</dd>

                                <dt className="col-sm-8">Total Price</dt>
                                <dd className="col-sm-4">
                                  &#x20b9;
                                  {currentOrder.totalFinalOfferPrice &&
                                    currentOrder.totalFinalOfferPrice.toFixed(2).toLocaleString()}
                                </dd>

                                <dt className="col-sm-8">Delivery Charge</dt>
                                <dd className="col-sm-4">
                                  &#x20b9;
                                  {currentOrder.deliveryCharge &&
                                    currentOrder.deliveryCharge.toFixed(2).toLocaleString()}
                                </dd>

                                <dt className="col-sm-8">Total Tax</dt>
                                <dd className="col-sm-4">
                                  &#x20b9;
                                  {currentOrder.totalTax && currentOrder.totalTax.toFixed(2).toLocaleString()}
                                </dd>

                                <dt className="col-sm-8" style={{ borderTop: "1px solid #eee" }}>
                                  Total Price
                                </dt>
                                <dd className="col-sm-4" style={{ borderTop: "1px solid #eee" }}>
                                  &#x20b9;
                                  {currentOrder.totalFinalOfferPrice &&
                                    currentOrder.totalFinalOfferPrice.toFixed(2).toLocaleString()}
                                </dd>
                              </dl>
                            </Col>
                            <Col sm={4}>
                              {profileInfo ? (
                                <>
                                  <h6
                                    style={{
                                      textTransform: "none",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Supplier Details
                                  </h6>
                                  <div className="bg-light p-1">
                                    <p
                                      style={{
                                        textTransform: "none",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <Link to={ADMIN_SUPPLIERR_VIEW + currentOrder.supplierId}>
                                        {profileInfo.businessName}{" "}
                                        <img src={externl} alt="go" style={{ width: 10, display: "none" }} />
                                      </Link>
                                    </p>
                                    <p className="mb-0">
                                      {profileInfo.mobileCountryCode}-{profileInfo.mobileNumber}
                                    </p>
                                  </div>
                                </>
                              ) : null}
                              {/* <dl className="row">
                                <dt className="col-sm-8">Promo type</dt>
                                <dd className="col-sm-4">
                                  {currentOrder.promotionType}
                                </dd>
                                <dt className="col-sm-8">Promo code</dt>
                                <dd className="col-sm-4">
                                  {currentOrder.promotionCode}
                                </dd>
                                <dt className="col-sm-8">
                                  Unit promo discount
                                </dt>
                                <dd className="col-sm-4">
                                  {currentOrder.unitPromotionalDiscount}
                                </dd>
                                <dt className="col-sm-8">
                                  Total promo discount
                                </dt>
                                <dd className="col-sm-4">
                                  {currentOrder.totalPromotionDiscount}
                                </dd>
                              </dl>*/}
                            </Col>
                            <Col sm={4}>
                              {productLoaded ? (
                                <>
                                  <h6
                                    style={{
                                      textTransform: "none",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Link
                                      to={
                                        ADMIN_PRODUCT_VIEW +
                                        currentOrder.productId +
                                        "/variant/" +
                                        currentOrder.productVariantPrimaryId
                                      }
                                    >
                                      {productLoaded.title}
                                    </Link>
                                  </h6>
                                  <p>
                                    <ImageZoom
                                      src={getMediaPath(productLoaded.defaultFileId, productLoaded.defaultFileExt)}
                                      style={{ width: 80 }}
                                      alt=""
                                      className="img-fluid img-thumbnail"
                                    />
                                  </p>
                                </>
                              ) : null}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <Card className="card--default">
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <h5 className="float-left">Line Item Shipment Details </h5>

                              <div className="clearfix" />
                              <p style={{ borderBottom: "2px solid #eee" }}>
                                <span className="item-ord">
                                  Last updated on {moment(currentOrder.updatedOn).format("DD MMM YYYY, hh:mm a")}{" "}
                                </span>
                                <span className="pipe" />
                                <span className="item-ord">Line Item# {currentOrder.orderLineItemId}</span>
                                <span className="pipe" />
                                <span
                                  className={
                                    currentOrder.status &&
                                    (currentOrder.status.includes("CANCEL") ||
                                      currentOrder.status.includes("REJECT") ||
                                      currentOrder.status.includes("RETURN"))
                                      ? "float-right text-danger"
                                      : "float-right"
                                  }
                                >
                                  Current Status: {currentOrder.status && currentOrder.status.replace(/_/g, " ")}
                                </span>
                              </p>
                            </Col>

                            <Col sm={4}>
                              <div>
                                <h6
                                  style={{
                                    textTransform: "none",
                                    marginBottom: "20px",
                                  }}
                                >
                                  Next Possible Actions
                                </h6>
                                {orderMap &&
                                  orderMap.map((object, index) => {
                                    const text = object.replace(/_/g, " ");
                                    return (
                                      <Button
                                        key={"btns-" + index}
                                        size="sm"
                                        color="info"
                                        className="btn-block"
                                        onClick={this.updateOrder(object)}
                                      >
                                        Mark as{" "}
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {text.toLowerCase()}
                                        </span>
                                      </Button>
                                    );
                                  })}
                              </div>
                            </Col>

                            <Col sm={4}>
                              {currentOrder.packageDeliveryPartnerCode ? (
                                <>
                                  <h6
                                    style={{
                                      textTransform: "none",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Shipping Info{" "}
                                    {shippingInfoChangeAllowed && (
                                      <Button
                                        size="xs"
                                        color="info"
                                        className="m-0 float-right"
                                        disabled={false}
                                        onClick={this.updateOrder("UPDATE_DELIVERY_INFO")}
                                      >
                                        Change
                                      </Button>
                                    )}
                                  </h6>
                                  <div className="bg-light p-1">
                                    <p>Delivery Partner: {currentOrder.packageDeliveryPartnerCode}</p>
                                    <p className="mb-0">
                                      Tracking Code: {currentOrder.packageDeliveryPartnerRefNumber}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <h6
                                    style={{
                                      textTransform: "none",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Shipping Info
                                  </h6>
                                  <p>Order not shipped yet</p>
                                </>
                              )}

                              {currentPickUpAddress ? (
                                <div className="mt-3">
                                  <h6
                                    style={{
                                      textTransform: "none",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Pickup Address{" "}
                                    {pickUpAddressChangeAllowed && (
                                      <Button
                                        size="xs"
                                        color="info"
                                        className="m-0 float-right"
                                        disabled={false}
                                        onClick={this.updateOrder("UPDATE_WAREHOUSE")}
                                      >
                                        Change
                                      </Button>
                                    )}
                                  </h6>
                                  <div className="bg-light p-1">
                                    <p className="mb-0">{currentPickUpAddress && currentPickUpAddress.fullAddress}</p>
                                  </div>
                                </div>
                              ) : null}

                              {currentReturnAddress ? (
                                <div className="mt-3">
                                  <h6
                                    style={{
                                      textTransform: "none",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Return Address
                                    {returnAddressChangeAllowed && (
                                      <Button
                                        size="xs"
                                        color="info"
                                        className="m-0 float-right"
                                        disabled={false}
                                        onClick={this.updateOrder("UPDATE_WAREHOUSE")}
                                      >
                                        Change
                                      </Button>
                                    )}
                                  </h6>
                                  <div className="bg-light p-1">
                                    {" "}
                                    <p className="mb-0">{currentReturnAddress && currentReturnAddress.fullAddress}</p>
                                  </div>
                                </div>
                              ) : null}
                            </Col>

                            <Col sm={4} className="ml-auto">
                              <h6
                                style={{
                                  textTransform: "none",
                                  marginBottom: "10px",
                                }}
                              >
                                Order Progress
                              </h6>
                              {statusLog ? (
                                <div className="bs-vertical-wizard">
                                  <ul>
                                    {Object.keys(statusLog).map((index) => {
                                      const element = statusLog[index];

                                      return (
                                        <React.Fragment key={"statustop-" + index}>
                                          <li className="current">
                                            <span>
                                              {element.newStatusDefaultLabel &&
                                                element.newStatusDefaultLabel.replace("ORDER_", "")}
                                              <br />
                                              <small>
                                                ({moment(element.updatedOn).format("DD MMM YYYY, hh:mm a")})
                                              </small>
                                            </span>
                                            {element.newStatusDefaultLabel === "ORDER_RETURNED_REQUESTED" && (
                                              <span className="text-danger">
                                                Reason:{" "}
                                                {currentOrder.returnRequestReasonType +
                                                  " (" +
                                                  currentOrder.returnRequestReasonNotes +
                                                  ")"}
                                              </span>
                                            )}
                                          </li>

                                          {deliveryStatusLog && element.newStatusDefaultLabel === "ORDER_SHIPPED" ? (
                                            <div className="bs-vertical-wizard ml-4 pb-0">
                                              <ul>
                                                {deliveryStatusLog.map((element, index) => {
                                                  let preposition = " from ";
                                                  if (element.actionType === "RECEIVED") {
                                                    preposition = " at ";
                                                  }
                                                  return (
                                                    <li key={"statusdels-" + index} className="current">
                                                      <span
                                                        style={{
                                                          fontSize: 10,
                                                        }}
                                                      >
                                                        {element.actionType + preposition + element.location}
                                                        <br />(
                                                        <small>
                                                          {moment(element.actionDate).format("DD MMM YYYY, hh:mm a")})
                                                        </small>
                                                      </span>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                              {!isDelivered && currentOrder.packageDeliveryPartnerCode ? (
                                                <Button
                                                  size="sm"
                                                  color="info"
                                                  className="btn-block mt-4"
                                                  disabled={false}
                                                  onClick={this.updateOrder("UPDATE_TRACKING")}
                                                >
                                                  Update Tracking Info
                                                </Button>
                                              ) : null}
                                            </div>
                                          ) : null}
                                        </React.Fragment>
                                      );
                                    })}
                                  </ul>
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : null}
                </Col>
                <Col sm="4">
                  {dataLoaded ? (
                    <div>
                      <h4 className="mt-0" style={{ fontSize: "1.2rem" }}>
                        Order Pricing Details
                      </h4>
                      <div className="card pt-3 px-3">
                        <div className="row">
                          <div className="col-sm-6">
                            <p>Gross Total</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-right">
                              &#x20b9;
                              {dataLoaded.grossTotal && dataLoaded.grossTotal.toFixed(2).toLocaleString()}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-6">
                            <p>Total Discount</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-right" style={{ color: "green" }}>
                              -&#x20b9;
                              {dataLoaded.totalDiscount && dataLoaded.totalDiscount.toFixed(2).toLocaleString()}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-6">
                            <p>Delivery Charge</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-right">
                              &#x20b9;
                              {dataLoaded.totalDeliveryCharge &&
                                dataLoaded.totalDeliveryCharge.toFixed(2).toLocaleString()}
                            </p>
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{
                            borderTop: "1px solid #eee",
                            paddingTop: 10,
                          }}
                        >
                          <div className="col-sm-6">
                            <p>Gross Total</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-right">
                              &#x20b9;
                              {dataLoaded.totalPayableAmount &&
                                dataLoaded.totalPayableAmount.toFixed(2).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {otherOrders ? (
                    <React.Fragment>
                      <h4 className="mt-0" style={{ fontSize: "1.2rem" }}>
                        Other items in this order
                      </h4>
                      <ListGroup>
                        {otherOrders.map((otherOrder) => {
                          return (
                            <ListGroupItem key={"kpk" + otherOrder.orderLineItemId}>
                              <Link to={ADMIN_ORDER_VIEW + otherOrder.orderLineItemId}>
                                {otherOrder.orderLineItemId} - {otherOrder.orderRefNumber}
                              </Link>
                            </ListGroupItem>
                          );
                        })}
                      </ListGroup>
                    </React.Fragment>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    updateTracking: (reqData) => dispatch(updateTracking(reqData)),
    updateOrder: (reqData) => dispatch(updateOrder(reqData)),
    fetchOrder: (reqData) => dispatch(fetchOrder(reqData)),
    generateInvoice: (reqData) => dispatch(generateInvoice(reqData)),
    updateDeliveryInfo: (reqData) => dispatch(generateInvoice(reqData)),
    resetObjectValues: (object) => dispatch(resetObjectValues(object)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
