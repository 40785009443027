import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateCustomOffer,
  getCustomOfferDetail,
} from "../../datautils/actions/customRequestsActions";

import PanelHeader from "../../components/PanelHeader/PanelHeader";
import MessageBlock from "../../components/MessageBlock/MessageBlock";

import {
  SUPPLIER_SKU_VIEW,
  ADMIN_SKU_VIEW,
} from "../../utils/constants/clientUrls";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";

import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { customOfferStatusTypes } from "../../utils/constants";

class CustomOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: { supplierOfferDeliveryCharge: 0 },
      updatedOn: null,
    };
    this.frameRef = React.createRef();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getCustomOfferDetail({ skuCustomOfferRequestId: id });
    this.setState({ id });
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;

    const { sampleItems } = this.props;

    if (this.state.id !== id) {
      this.setState({ id });
      this.props.getCustomOfferDetail({ skuCustomOfferRequestId: id });
    }

    if (
      sampleItems.sampleDetail &&
      sampleItems.sampleDetail.updatedOn !== prevState.updatedOn
    ) {
      const data = sampleItems.sampleDetail;

      let future = new Date();
      future.setDate(future.getDate() + 10);

      let endDateTime = data.offerExpiryDate
        ? new Date(data.offerExpiryDate)
        : future;

      this.setState({
        ...this.state,
        id,
        formData: {
          ...data,
          offerExpiryDate: data.offerExpiryDate || endDateTime,
        },
        updatedOn: data.updatedOn,
      });
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    if (this.frameRef.current) {
      this.frameRef.current.scrollTop = this.frameRef.current.scrollHeight;
    }
  };

  handleChange = (event) => {
    let eTargetValue = event.target.value;

    if (
      event.target.name === "supplierOfferDeliveryCharge" ||
      event.target.name === "supplierOfferOrderQuantity"
    ) {
      eTargetValue = Math.round(eTargetValue);
    }

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        [event.target.name]: eTargetValue,
      },
    });
  };

  formatDate = (date) => {
    const now = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const first = now.substr(0, now.length - 3);
    const last = now.substr(-3);
    return first + last.replace(":", "");
  };

  handleChangeEndDate = (date) => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, offerExpiryDate: date },
    });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [obj]: opt.value },
    });
  };

  updateCustomOffer = () => {
    this.props.updateCustomOffer({ ...this.state.formData });
  };

  render() {
    const {
      sampleItems,
      isLoading,
      authData: { userId, userType },
    } = this.props;
    const { formData } = this.state;

    const responseData = sampleItems.sampleDetail;

    const mesageLog =
      responseData &&
      responseData.suggestedPriceMessageLog &&
      responseData.suggestedPriceMessageLog.logs;

    let future = new Date();
    future.setDate(future.getDate() + 10);

    let endDateTime = formData.offerExpiryDate
      ? new Date(formData.offerExpiryDate)
      : future;

    return (
      <div>
        <PanelHeader />

        <div className="content mt-3">
          <Row>
            <MessageBlock screenName="bargain" />
            <Col xs={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-0 pt-0">
                  <li className="breadcrumb-item">
                    <span onClick={() => this.props.history.goBack()}>
                      &laquo; Go Back
                    </span>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Bargain Offer Details
                  </li>
                </ol>
              </nav>
              <Row>
                <Col sm={8}>
                  {responseData ? (
                    <>
                      <Card className="card--default">
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <h5 className="float--left">
                                Bargain Offer Details{" "}
                                {responseData.ordered ? (
                                  <span className="text-success float-right">
                                    Product Ordered By Customer
                                  </span>
                                ) : null}
                              </h5>

                              <div className="clearfix" />
                              <p style={{ borderBottom: "2px solid #eee" }}>
                                <span className="item-ord">
                                  Requested on{" "}
                                  {moment(responseData.createdOn).format(
                                    "ddd, Do MMM 'YY, h:mm a"
                                  )}{" "}
                                </span>
                                <span className="pipe" />
                                <span className="item-ord">
                                  Request#{" "}
                                  {responseData.skuCustomOfferRequestId}
                                </span>
                                <span className="float-right">
                                  Status: {responseData.status}
                                </span>
                              </p>
                            </Col>

                            <Col sm={8}>
                              <Row>
                                <Col sm={6}>
                                  <p>
                                    SKU MRP:{" "}
                                    <span className="float-right">
                                      &#x20b9;
                                      {responseData.skuMrp &&
                                        responseData.skuMrp
                                          .toFixed(2)
                                          .toLocaleString()}
                                    </span>
                                    <br />
                                    SKU Selling Price:{" "}
                                    <span className="float-right">
                                      &#x20b9;
                                      {responseData.skuSellingPrice &&
                                        responseData.skuSellingPrice
                                          .toFixed(2)
                                          .toLocaleString()}
                                    </span>
                                  </p>
                                </Col>
                                <Col sm={6}>
                                  <p>
                                    Customer Quantity:{" "}
                                    <span className="float-right">
                                      {
                                        responseData.customerOfferRequestedOrderQuantity
                                      }
                                    </span>
                                    <br />
                                    Customer Price/Quantity:{" "}
                                    <span className="float-right">
                                      &#x20b9;
                                      {responseData.customerOfferRequestedUnitPrice &&
                                        responseData.customerOfferRequestedUnitPrice
                                          .toFixed(2)
                                          .toLocaleString()}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <p>
                                    <span style={{ fontWeight: "bold" }}>
                                      Customer Message:
                                    </span>
                                    <br /> {responseData.customerMessage}
                                  </p>
                                </Col>
                              </Row>
                              {responseData.supplierOfferUnitPrice > 0 ? (
                                <Row>
                                  <Col>
                                    <div
                                      style={{
                                        backgroundColor: "#f2f2f2",
                                        padding: 10,
                                        marginBottom: 10,
                                      }}
                                    >
                                      <p style={{ fontWeight: "bold" }}>
                                        Current Accepted Offer
                                      </p>
                                      <p className="row">
                                        <span className="col-sm-4">
                                          Quantity:
                                        </span>
                                        <span className="col-sm-8">
                                          {
                                            responseData.supplierOfferOrderQuantity
                                          }
                                        </span>
                                        <br />
                                        <span className="col-sm-4">
                                          Selling Price:
                                        </span>
                                        <span className="col-sm-8">
                                          &#x20b9;
                                          {responseData.supplierOfferUnitPrice &&
                                            responseData.supplierOfferUnitPrice.toFixed(
                                              2
                                            )}
                                        </span>
                                        <br />

                                        <span className="col-sm-4">
                                          Delivery Charge:
                                        </span>
                                        <span className="col-sm-8">
                                          &#x20b9;
                                          {
                                            responseData.supplierOfferDeliveryCharge
                                          }
                                        </span>
                                        <br />
                                      </p>
                                      <p>
                                        <span style={{ fontWeight: 500 }}>
                                          Message:
                                        </span>
                                        <br /> {responseData.supplierMessage}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              ) : null}

                              {userType === "SUPPLIER" ? (
                                <Row>
                                  <Col sm={12}>
                                    <Row>
                                      <Col>
                                        <div className="price-invent sku-form-box samplepage">
                                          <h5 className="card-header">
                                            Update Bargain Offer Request
                                          </h5>

                                          <div className="card-body">
                                            {formData ? (
                                              <Form>
                                                <fieldset
                                                  disabled={
                                                    responseData.status ===
                                                      "DELETED" ||
                                                    responseData.ordered
                                                  }
                                                >
                                                  <FormGroup row>
                                                    <Label
                                                      for="offerstatus"
                                                      sm={6}
                                                    >
                                                      Offer Status{" "}
                                                      <span className="star">
                                                        *
                                                      </span>
                                                    </Label>
                                                    <Col sm={6}>
                                                      <Select
                                                        placeholder="Select Action"
                                                        isSearchable={false}
                                                        options={
                                                          customOfferStatusTypes
                                                        }
                                                        value={customOfferStatusTypes.filter(
                                                          (option) =>
                                                            option.value ===
                                                            formData.status
                                                        )}
                                                        onChange={(opt) =>
                                                          this.handleChangeSelect(
                                                            "status",
                                                            opt
                                                          )
                                                        }
                                                      />
                                                    </Col>
                                                  </FormGroup>

                                                  {formData.status !==
                                                  "SUPPLIER_REJECTED" ? (
                                                    <>
                                                      <FormGroup row>
                                                        <Label
                                                          for="description1"
                                                          sm={6}
                                                        >
                                                          Total Quantity{" "}
                                                          <span className="star">
                                                            *
                                                          </span>
                                                        </Label>
                                                        <Col sm={6}>
                                                          <Input
                                                            type="text"
                                                            min="1"
                                                            step="1"
                                                            pattern="\d*"
                                                            name="supplierOfferOrderQuantity"
                                                            placeholder="Quantity"
                                                            value={
                                                              formData.supplierOfferOrderQuantity >
                                                              0
                                                                ? formData.supplierOfferOrderQuantity
                                                                : ""
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                          />
                                                        </Col>
                                                      </FormGroup>
                                                      <FormGroup row>
                                                        <Label
                                                          for="description1"
                                                          sm={6}
                                                        >
                                                          Price Per Quantity{" "}
                                                          <span className="star">
                                                            *
                                                          </span>
                                                        </Label>
                                                        <Col sm={6}>
                                                          <Input
                                                            type="number"
                                                            min="1"
                                                            step="0.01"
                                                            name="supplierOfferUnitPrice"
                                                            placeholder="Final price"
                                                            value={
                                                              formData.supplierOfferUnitPrice >
                                                              0
                                                                ? formData.supplierOfferUnitPrice
                                                                : ""
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                          />
                                                        </Col>
                                                      </FormGroup>

                                                      <FormGroup row>
                                                        <Label
                                                          for="delivery"
                                                          sm={6}
                                                        >
                                                          Delivery Charge{" "}
                                                          <span className="star">
                                                            *
                                                          </span>
                                                        </Label>
                                                        <Col sm={6}>
                                                          <Input
                                                            type="text"
                                                            min="1"
                                                            step="1"
                                                            pattern="\d*"
                                                            name="supplierOfferDeliveryCharge"
                                                            placeholder="Delivery charge"
                                                            value={
                                                              formData.supplierOfferDeliveryCharge >
                                                              -2
                                                                ? formData.supplierOfferDeliveryCharge
                                                                : 0
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                          />
                                                        </Col>
                                                      </FormGroup>

                                                      <FormGroup row>
                                                        <Label
                                                          for="title"
                                                          sm={6}
                                                        >
                                                          Offer Expiry Date{" "}
                                                          <span className="star">
                                                            *
                                                          </span>
                                                        </Label>
                                                        <Col sm={6}>
                                                          <DatePicker
                                                            className="form-control"
                                                            placeholderText="Click to select expiry date"
                                                            showTimeSelect
                                                            selected={
                                                              endDateTime
                                                            }
                                                            onChange={
                                                              this
                                                                .handleChangeEndDate
                                                            }
                                                            timeFormat="HH:mm"
                                                            timeIntervals={60}
                                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                                            timeCaption="Time"
                                                            minDate={new Date()}
                                                            withPortal
                                                          />
                                                        </Col>
                                                      </FormGroup>
                                                    </>
                                                  ) : null}

                                                  <FormGroup row>
                                                    <Col sm={12}>
                                                      <Input
                                                        type="textarea"
                                                        min="1"
                                                        name="supplierMessage"
                                                        placeholder="Any message for customer?"
                                                        value={
                                                          formData.supplierMessage ||
                                                          ""
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                      />
                                                    </Col>
                                                  </FormGroup>

                                                  <FormGroup row>
                                                    <Col sm={12}>
                                                      <Button
                                                        block
                                                        color="primary"
                                                        size="md"
                                                        onClick={
                                                          this.updateCustomOffer
                                                        }
                                                        disabled={
                                                          !formData.supplierMessage ||
                                                          ((formData.supplierOfferUnitPrice <
                                                            1 ||
                                                            formData.supplierOfferOrderQuantity <
                                                              1) &&
                                                            formData.status ===
                                                              "SUPPLIER_QUOTE")
                                                        }
                                                      >
                                                        {formData.status ===
                                                        "SUPPLIER_QUOTE"
                                                          ? "Accept"
                                                          : "Reject"}{" "}
                                                        Bargain Offer Request
                                                      </Button>
                                                    </Col>
                                                  </FormGroup>
                                                </fieldset>
                                              </Form>
                                            ) : null}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              ) : null}
                            </Col>
                            <Col sm={4}>
                              <h6
                                style={{
                                  textTransform: "none",
                                  marginBottom: "5px",
                                }}
                              >
                                <Link
                                  to={
                                    (userType === "SUPPLIER"
                                      ? SUPPLIER_SKU_VIEW
                                      : ADMIN_SKU_VIEW) + responseData.skuId
                                  }
                                >
                                  {responseData.productName}
                                </Link>
                              </h6>

                              <p>
                                {responseData.variantType +
                                  ": " +
                                  responseData.variantName}
                                <br />
                              </p>

                              {responseData.productImageFileId ? (
                                <ImageZoom
                                  src={getMediaPath(
                                    responseData.productImageFileId,
                                    responseData.productImageFileExt
                                  )}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : null}
                </Col>
                <Col sm="4">
                  <h5>Message history log</h5>

                  <div id="frame">
                    <div className="content">
                      <div className="messages">
                        <ul
                          style={{
                            paddingTop: 20,
                            maxHeight: 666,
                            overflow: "auto",
                          }}
                        >
                          {mesageLog &&
                            mesageLog.map((message, index) => {
                              return (
                                <li
                                  className={
                                    userId === message.userId
                                      ? "replies"
                                      : "sent"
                                  }
                                  key={"mess-" + index}
                                >
                                  <p
                                    style={{
                                      position: "relative",
                                      minWidth: 220,
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: -20,
                                        left: 0,
                                        color: "black",
                                      }}
                                    >
                                      {message.userType &&
                                        message.userType.toLowerCase()}
                                      :{" "}
                                      {moment(message.createdOn).format(
                                        "ddd, Do MMM 'YY, h:mm a"
                                      )}
                                    </span>

                                    <span>
                                      Offer Quantity:{" "}
                                      {message.suggestedQuantity}
                                    </span>
                                    <br />
                                    <span>
                                      Offer Price: &#x20b9;
                                      {message.suggestedUnitPrice &&
                                        message.suggestedUnitPrice.toFixed(2)}
                                    </span>
                                    <br />
                                    <span>
                                      Delivery Charge: &#x20b9;
                                      {message.suggestedDeliveryCharge}
                                    </span>
                                    <br />
                                    <span>Message: {message.message}</span>
                                  </p>
                                </li>
                              );
                            })}
                        </ul>
                        <div ref={this.frameRef}></div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,

    sampleItems: state.customOfferItems,
    authData: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomOfferDetail: (reqData) => dispatch(getCustomOfferDetail(reqData)),
    updateCustomOffer: (reqData) => dispatch(updateCustomOffer(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomOffer);
