import React from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { values } = this.props;
    return (
      <>
        <CardBody>
          <Form autoComplete="off">
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>+91</InputGroupText>
                </InputGroupAddon>
                <Input
                  className={this.props.mobileState}
                  type="text"
                  name="mobile"
                  placeholder="Mobile Number"
                  maxLength="10"
                  onChange={this.props.handleChange("mobile")}
                  value={values.mobile}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </CardBody>

        <CardFooter className="text-center">
          <Button
            block
            color="primary"
            size="md"
            onClick={this.props.saveAndContinue}
            disabled={this.props.isLoading || !this.props.isEnabled}
          >
            Recover Password
          </Button>
          <div className="clearfix" />
          <p className="text-center pt-4">
            <Link to="/login" className="link footer-link">
              Go to Login
            </Link>
          </p>
        </CardFooter>
      </>
    );
  }
}

export default ForgotPassword;
