import React from "react";
import { Row, Col, FormGroup, Card, CardBody, Button, Table, ButtonToolbar } from "reactstrap";

import { connect } from "react-redux";
import { getCampaignList } from "../../datautils/actions/adminActions";
import { resetResponseValues, resetObjectValues } from "../../datautils/actions/responseHandlerActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";

import { ADMIN_CAMPAIGN_VIEW } from "../../utils/constants/clientUrls";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { sortIcon } from "../../utils/constants/icons";
import { PER_PAGE } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import { formatDateTime } from "../../utils/helpers/HelperFunctions";

class Campaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "1",
      currentFilter: "status",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "name",
      ascending: false,

      show: false,
    };
  }
  componentDidMount() {
    this.searchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchData();
    }
  }

  searchButton = (e) => {
    this.setState({ pageNumber: 1 }, () => this.searchData());
  };

  sortButton = (field, order) => (e) => {
    e.preventDefault();
    this.setState({ sortField: field, ascending: order }, () => this.searchData());
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState({ pageNumber: 1 }, () => this.searchData());
    }
  };

  generateSearchParams = () => {
    return {
      globalSearchKeyword: this.state.globalSearchKeyword,
      fieldNameKeyword: [
        {
          key: this.state.currentFilter,
          operation: "EQUAL_TO",
          value: this.state.currentTab,
        },
      ],
      paginationOptions: {
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOptions: [{ sortField: this.state.sortField, ascending: this.state.ascending }],
      },
    };
  };

  searchData = () => {
    const reqData = this.generateSearchParams();
    this.props.resetObjectValues("campaign");
    this.props.getCampaignList(reqData);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangePage = (id, code) => {
    const { location, history } = this.props;
    let pathname = ADMIN_CAMPAIGN_VIEW + id;
    if (code) {
      pathname = `${pathname}/${code}`;
    }
    history.push({ pathname, state: { prevPath: location.pathname, searchParams: this.generateSearchParams() } });
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  filterData = (e) => {
    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
        currentFilter: e.target.dataset.filter,
      },
      () => this.searchData()
    );
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.campaignList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  render() {
    const { responseVal, isLoading } = this.props;
    const { globalSearchKeyword, currentTab, pageNumber, pageSize, ascending } = this.state;
    const dataLoaded = responseVal.dObjectData?.campaignList?.data?.campaigns;

    return (
      <div>
        <PanelHeader />

        <div className="content">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" && <ErrorBlock errors={responseVal.errors} />}
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="8">
                  <ButtonToolbar>
                    <Button
                      size="sm"
                      value="1"
                      data-filter="status"
                      onClick={this.filterData}
                      color={currentTab === "1" ? "primary" : ""}
                    >
                      ACTIVE
                    </Button>
                    <Button
                      size="sm"
                      value="0"
                      data-filter="status"
                      onClick={this.filterData}
                      color={currentTab === "0" ? "primary" : ""}
                    >
                      INACTIVE
                    </Button>
                    <Button
                      size="sm"
                      value=""
                      data-filter="status"
                      onClick={this.filterData}
                      color={currentTab === "" ? "primary" : ""}
                    >
                      ALL
                    </Button>
                  </ButtonToolbar>
                </Col>
                <Col xs="4">
                  <Button
                    size="sm"
                    color="success"
                    className="pull-right"
                    onClick={() => this.props.history.push(`${ADMIN_CAMPAIGN_VIEW}new`)}
                  >
                    Create New Campaign
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col xs={12}>
              <Card>
                <CardBody className="listings">
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <div className="has-search">
                          <div className="input-group">
                            <input
                              type="search"
                              value={globalSearchKeyword}
                              name="globalSearchKeyword"
                              className="form-control"
                              placeholder="Search Campaigns..."
                              onChange={this.searchHandler}
                              onKeyDown={this.handleKeyDown}
                              style={{ background: "#f5f5f5" }}
                              autoComplete="off"
                            />
                            <div className="input-group-append">
                              <Button color="primary" onClick={this.searchButton}>
                                <i className="now-ui-icons ui-1_zoom-bold" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="8">{dataLoaded ? this.renderPagination() : null}</Col>
                  </Row>
                  <Table responsive className="table-striped table-bordered">
                    <thead>
                      <tr className="text-primary">
                        <th className="text-center">#</th>
                        <th onClick={this.sortButton("name", ascending ? false : true)}>
                          Campaign Name <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th onClick={this.sortButton("platformType", ascending ? false : true)}>
                          Platform Type <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th onClick={this.sortButton("campaignType", ascending ? false : true)}>
                          Campaign Type <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th>Status</th>
                        <th onClick={this.sortButton("startDateTime", ascending ? false : true)}>
                          Start Date <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th onClick={this.sortButton("endDateTime", ascending ? false : true)}>
                          End Date <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th>Created On</th>
                        <th>Updated On</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataLoaded ? (
                        dataLoaded.length ? (
                          dataLoaded.map((row, index) => {
                            const cname =
                              row.status === "ACTIVE"
                                ? "success"
                                : row.status === "INACTIVE"
                                ? "danger"
                                : row.status === "ONHOLD"
                                ? "warning"
                                : "primary";
                            return (
                              <tr key={index}>
                                <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>
                                <td onClick={() => this.handleChangePage(row.id, row.code)}>{row.name}</td>
                                <td>{row.platformType}</td>
                                <td>{row.campaignType}</td>
                                <td>{row.status}</td>
                                <td>{formatDateTime(row.startDateTime)}</td>
                                <td>{formatDateTime(row.endDateTime)}</td>
                                <td>{formatDateTime(row.createdOn)}</td>
                                <td>{formatDateTime(row.updatedOn)}</td>
                                <td className="text-right">
                                  <Button
                                    className={`mb-1 btn-simple btn-primary btn-light btn-${cname}`}
                                    size="xs"
                                    onClick={() => this.handleChangePage(row.id, null)}
                                  >
                                    Edit
                                  </Button>

                                  <Button
                                    className={`mb-1 ml-2 btn-simple btn-light btn-${cname}`}
                                    size="xs"
                                    onClick={() => this.handleChangePage(row.id, row.code)}
                                  >
                                    Mapping
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10" className="no-data">
                              No data available
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan="10" />
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    resetObjectValues: (object) => dispatch(resetObjectValues(object)),
    getCampaignList: (reqData) => dispatch(getCampaignList(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
