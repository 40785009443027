import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import { Helmet } from "react-helmet";
import HomeHeader from "../../components/Headers/HomeHeader";
import Footer from "../../views/Common/Footer";
import appstore from "../../assets/img/app-store.svg";
import video1 from "../../assets/video/place-order.mp4";
import video2 from "../../assets/video/deliver-product.mp4";
import video3 from "../../assets/video/get-paid.mp4";
import forCustomer from "../../assets/video/for-customer.mp4";
import forSupplier from "../../assets/video/for-supplier.mp4";

import shop from "../../assets/img/shopping.svg";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comingSoon: false,
    };
  }

  componentDidMount() {}

  goTo = () => {
    window.scroll({
      top: 0,
      left: 600,
      behavior: "smooth",
    });
  };

  render() {
    return this.state.comingSoon ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1>Coming Soon</h1>
      </div>
    ) : (
      <div>
        <HomeHeader {...this.props} />
        <Helmet>
          <title>Slickpals</title>
        </Helmet>
        <div className="full-page--content">
          <div className="bg-darken main-box">
            <div className="container">
              <Row>
                <Col sm={5}>
                  <h1>
                    Enjoy the convenience <br />
                    of shopping with <br />
                    our new service
                  </h1>
                  <p>
                    Our new service makes it easy for you to shop, chose form different trending items and all of those
                    in wholesale price
                  </p>
                  <p>
                    <img src={appstore} alt="" />
                  </p>
                </Col>
                <Col sm={7}>
                  <img src={shop} alt="" className="main-img" />
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <section className="video bg-video for-block" style={{ borderTop: "1px solid #eee" }}>
          <Container>
            <Row>
              <Col sm="6" className="bl-0">
                <p className="for">For Customers</p>
                <Row>
                  <Col sm={7}>
                    <video playsInline autoPlay muted loop poster="" id="back-video">
                      <source src={forCustomer} type="video/mp4" />
                    </video>
                  </Col>
                  <Col sm={5}>
                    <div className="mini-container">
                      <p>Download the app. Choose from thousands of products at wholesale price</p>
                      <p>
                        <img src={appstore} alt="" />
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm="6" className="br-0">
                <p className="for">For Suppliers</p>
                <Row>
                  <Col sm={7}>
                    <video playsInline autoPlay muted loop poster="" id="back-video">
                      <source src={forSupplier} type="video/mp4" />
                    </video>
                  </Col>{" "}
                  <Col sm={5}>
                    <div className="mini-container">
                      <p>Become a seller and list your products. After approval you can start selling on Slickpals</p>
                      <p>
                        <Link to="/register" className="become-seller" onClick={this.goTo}>
                          Become a Seller
                        </Link>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="video bg-video how-it-works">
          <Container>
            <Row>
              <div className="col-sm-12">
                <h3>How selling works on Slickpals</h3>
              </div>
            </Row>
            <div className="row justy-content-center">
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="text-center feature-block">
                  <div className="img-icon-block mb-4">
                    <video playsInline autoPlay muted loop poster="" id="back-video">
                      <source src={video1} type="video/mp4" />
                    </video>
                  </div>
                  <h4 className="mb-2">Customer places order</h4>
                  <p>Our system matching with the right provider</p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="text-center feature-block">
                  <div className="img-icon-block mb-4">
                    <video playsInline autoPlay muted loop poster="" id="back-video">
                      <source src={video2} type="video/mp4" />
                    </video>
                  </div>
                  <h4 className="mb-2">We deliver your product to customer</h4>
                  <p>We've been awarded for our high rate of customer satisfaction</p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="text-center feature-block">
                  <div className="img-icon-block mb-4">
                    <video playsInline autoPlay muted loop poster="" id="back-video">
                      <source src={video3} type="video/mp4" />
                    </video>
                  </div>
                  <h4 className="mb-2">You get paid for your sales</h4>
                  <p>We only compare market leaders with a reputation</p>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <Footer />
      </div>
    );
  }
}

export default HomePage;
