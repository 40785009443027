import React from "react";
import { Row, Col, Card, CardBody, Button, Table, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import PanelHeader from "../../components/PanelHeader/PanelHeader";
import CustomPagination from "../../components/CustomPagination/CustomPagination";

import {
  SUPPLIER_BILL_VIEW,
  ADMIN_BILL_VIEW,
  ADMIN_CUSTOMER_VIEW,
  //ADMIN_SUPPLIERR_VIEW
} from "../../utils/constants/clientUrls";

//import { sortIcon } from "../../utils/constants/icons";
//import { PER_PAGE } from "../../utils/constants/index";
import { getBillBook } from "../../datautils/actions/billBookActions";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import { formatDateTime } from "../../utils/helpers/HelperFunctions";

class BillBookList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "3",
      currentFilter: "status",
      pageNumber: 1,
      pageSize: 50,
      sortField: "",
      ascending: false,
      toggle: false,
      filterArray: [""],
    };
  }

  componentDidMount() {
    this.searchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchData();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState({ pageNumber: 1 }, () => this.searchData());
    }
  };

  searchButton = (e) => {
    this.setState({ pageNumber: 1 }, () => this.searchData());
  };

  sortButton = (field, order) => (e) => {
    this.setState({ sortField: field, ascending: order }, () => this.searchData());
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  searchData = () => {
    const {
      // globalSearchKeyword,
      // currentTab,

      // sortField,
      // ascending,
      // filterArray,
      pageNumber,
      pageSize,
    } = this.state;

    // const fieldNameKeyword = filterArray
    //   ? filterArray.map(t => {
    //       return {
    //         key: "status",
    //         operation: "EQUAL_TO",
    //         value: t
    //       };
    //     })
    //   : {};

    // let filterVal = {};

    // if (currentTab === "3") {
    //   filterVal = {
    //     key: "ordered",
    //     value: [true]
    //   };
    // }

    const reqData = {
      // globalSearchKeyword,
      // fieldNameKeyword: [...fieldNameKeyword],
      // filterOptions: [{ ...filterVal }],
      paginationOptions: {
        pageNumber,
        pageSize,
        //sortOptions: [{ sortField, ascending }]
      },
    };

    this.props.getBillBook(reqData);
  };

  handleChange = (input) => () => {
    this.props.userType === "SUPPLIER"
      ? this.props.history.push(SUPPLIER_BILL_VIEW + input)
      : this.props.history.push(ADMIN_BILL_VIEW + input);
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  filterData = (e) => {
    let fArray = [];
    if (e.target.value === "0") {
      fArray.push("DELETED", "CUSTOMER_CANCELLED", "SUPPLIER_REJECTED");
    } else if (e.target.value === "1") {
      fArray.push("SUPPLIER_QUOTE");
    } else if (e.target.value === "2") {
      fArray.push("CUSTOMER_QUOTE");
    }

    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
        currentFilter: e.target.dataset.filter,
        filterArray: [...fArray],
      },
      () => this.searchData()
    );
  };

  renderPagination = () => {
    const { billBook } = this.props;
    const mainData = billBook;

    return mainData?.totalNoOfPages > 1 ? (
      <CustomPagination
        pageNumber={this.state.pageNumber}
        totalNoOfPages={mainData.totalNoOfPages}
        changePage={this.changePage}
        hasPrevious={mainData.hasPrevious}
        hasNext={mainData.hasNext}
      />
    ) : null;
  };

  render() {
    const { billBook, userType } = this.props;
    const {
      // globalSearchKeyword,
      // ascending,
      //currentTab,
      pageNumber,
      pageSize,
    } = this.state;

    const billSummary = billBook?.billSummary;
    const responseData = billBook?.billSubSummary;

    return (
      <div>
        <PanelHeader />
        <div className="content mt-0">
          <Row>
            <Col xs={12}>
              <MessageBlock screenName="billbook" />
            </Col>

            <Col xs={12}>
              <React.Fragment>
                <ButtonToolbar style={{ display: "block" }}>
                  {/* <Button
                    size="sm"
                    value="3"
                    //onClick={this.filterData}
                    onClick={null}
                    color={currentTab === "3" ? "primary" : ""}
                  >
                    ALL
                  </Button>
                   <Button
                    size="sm"
                    value="-1"
                    onClick={this.filterData}
                    color={currentTab === "-1" ? "primary" : ""}
                  >
                    All Requests
                  </Button>
                  <Button
                    size="sm"
                    value="0"
                    onClick={this.filterData}
                    color={currentTab === "0" ? "primary" : ""}
                  >
                    Deleted Requests
                  </Button>
                  <Button
                    size="sm"
                    value="1"
                    onClick={this.filterData}
                    color={currentTab === "1" ? "primary" : ""}
                  >
                    Accepted Requests
                  </Button>
                  <Button
                    size="sm"
                    value="2"
                    onClick={this.filterData}
                    color={currentTab === "2" ? "primary" : ""}
                  >
                    New Requests
                  </Button> */}
                </ButtonToolbar>
                <p style={{ padding: 10, margin: 0 }}>
                  <span className="text-danger" style={{ paddingRight: 30 }}>
                    <strong>
                      Total Amount to Pay: &#x20b9;
                      {billSummary?.totalAmountToPay?.toFixed(2).toLocaleString()}
                    </strong>
                  </span>
                  <span className="text-success">
                    <strong>
                      Total Amount to Get: &#x20b9;
                      {billSummary?.totalAmountToGet?.toFixed(2).toLocaleString()}
                    </strong>
                  </span>
                </p>
                <Card>
                  <CardBody className="listings">
                    <Row>
                      <Col md="4">
                        {/* <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search Requests..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button
                                  color="primary"
                                  onClick={this.searchButton}
                                >
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup> */}
                      </Col>
                      <Col md="8">
                        <div
                          style={{
                            fontSize: "70%",
                            marginBottom: 10,
                            overflow: "hidden",
                          }}
                        >
                          {this.renderPagination()}
                        </div>
                      </Col>
                    </Row>
                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>
                          <th>User Id</th>
                          <th>User Name</th>
                          <th>User Mobile</th>
                          <th>User Type</th>
                          <th>Amount To Pay</th>
                          <th>Amount To Get</th>

                          <th>Created Date</th>

                          <th>Update Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {responseData ? (
                          responseData.length ? (
                            responseData.map((item, index) => {
                              const row = item;

                              const cname = "primary";
                              return (
                                <tr key={index}>
                                  <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>
                                  <td>
                                    {userType === "SUPPLIER" ? (
                                      row.toUserId
                                    ) : (
                                      <Link to={ADMIN_CUSTOMER_VIEW + row.toUserId}>{row.toUserId}</Link>
                                    )}
                                  </td>
                                  <td>{row.toUserName}</td>
                                  <td>
                                    {userType === "SUPPLIER" ? (
                                      <>{row.toUserMobileNumber?.replace(/(\d{2})\d{6}(\d{2})/, "$1*******$2")}</>
                                    ) : (
                                      row.toUserMobileNumber
                                    )}
                                  </td>
                                  <td>{row.toUserType}</td>
                                  <td>
                                    <span className="text-danger">
                                      &#x20b9;
                                      {row.totalAmountToPay?.toFixed(2).toLocaleString()}
                                    </span>
                                  </td>

                                  <td>
                                    <span className="text-success">
                                      &#x20b9;
                                      {row.totalAmountToGet?.toFixed(2).toLocaleString()}
                                    </span>
                                  </td>

                                  <td>{formatDateTime(row.createdOn)}</td>
                                  <td>{formatDateTime(row.updatedOn)}</td>

                                  <td className="text-right">
                                    <Button
                                      className={`btn-simple btn-light btn-primary btn-${cname}`}
                                      size="xs"
                                      onClick={this.handleChange(row.toUserId)}
                                    >
                                      View
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="14" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="15" />
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {this.props.isLoading ? <div className="loader" /> : null}
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    billBook: state.billBookItems.billBook,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBillBook: (reqParam) => dispatch(getBillBook(reqParam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillBookList);
