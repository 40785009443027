import React from "react";
import { Row, Col, Card, CardBody, Badge } from "reactstrap";

import { connect } from "react-redux";
import { getProfile } from "../../datautils/actions/userActions";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import moment from "moment";

import PanelHeader from "../../components/PanelHeader/PanelHeader";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import { PER_PAGE } from "../../utils/constants/index";

class Supplier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal2: false,
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "orderId",
      ascending: false,

      id: 0,
    };
  }

  componentDidMount() {
    this.props.resetResponseValues();
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    if (this.state.id !== id) {
      this.setState({ id, isUpdated: false });
      this.props.getProfile({ sUserId: id });
    }
  }

  render() {
    const { responseVal, isLoading } = this.props;
    const { isUpdated } = this.state;

    const dataLoaded = responseVal.dObjectData?.supplierDetail?.data;

    const profileInfo = dataLoaded?.profileInfo;
    const workflow = dataLoaded?.workflow;

    return (
      <div>
        <PanelHeader />

        <div className="content mt-3">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}

              {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}
            </Col>

            <Col xs={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-0 pt-0">
                  <li className="breadcrumb-item">
                    <span onClick={() => this.props.history.goBack()}>&laquo; Go Back</span>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Supplier Details
                  </li>
                </ol>
              </nav>
              <Row>
                <Col sm={7}>
                  {profileInfo ? (
                    <>
                      <Card className="card--default">
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <h5 className="float-left"> {profileInfo.businessName}</h5>
                              <span className="float-right">
                                <Badge
                                  color="info"
                                  pill
                                  style={{
                                    textTransform: "lowercase",
                                    display: "none",
                                  }}
                                >
                                  {profileInfo.status.replace("_", " ")}
                                </Badge>
                              </span>
                              <div className="clearfix" />

                              <p style={{ borderBottom: "2px solid #eee" }}>
                                <span className="item-ord">
                                  Updated on {moment(profileInfo.updatedOn).format("DD MMM YYYY, hh:mm a")}{" "}
                                </span>
                                <span className="pipe" />
                                <span className="item-ord">Supplier# {profileInfo.userId}</span>
                              </p>
                            </Col>

                            <Col sm={8}>
                              <dl className="row">
                                <dt className="col-sm-5">Business Name</dt>
                                <dd className="col-sm-7">{profileInfo.businessName}</dd>
                                <dt className="col-sm-5">Business Type</dt>
                                <dd className="col-sm-7">{profileInfo.businessType}</dd>
                                <dt className="col-sm-5">Verified</dt>
                                <dd className="col-sm-7">
                                  {profileInfo.verified ? (
                                    <span className="text-success">YES</span>
                                  ) : (
                                    <span className="text-danger">NO</span>
                                  )}
                                </dd>
                                <dt className="col-sm-5">Workflow Complete</dt>
                                <dd className="col-sm-7">
                                  {dataLoaded.workFlowComplete ? (
                                    <span className="text-success">YES</span>
                                  ) : (
                                    <span className="text-danger">NO</span>
                                  )}
                                </dd>
                                <dt className="col-sm-5">New Supplier</dt>
                                <dd className="col-sm-7">
                                  {dataLoaded.newSupplier ? (
                                    <span className="text-danger">YES</span>
                                  ) : (
                                    <span className="text-success">NO</span>
                                  )}
                                </dd>
                                <dt className="col-sm-5">Status</dt>
                                <dd className="col-sm-7">{profileInfo.status}</dd> <dd className="col-sm-12">&nbsp;</dd>
                                <dd className="col-sm-12">{profileInfo.businessDescription}</dd>
                              </dl>
                            </Col>
                            <Col sm="4" />
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : null}
                </Col>
                <Col sm="5">
                  <React.Fragment>
                    {workflow ? (
                      <div className="card">
                        <div className="card-body">
                          <h5 className="mt-0" style={{ fontSize: "1rem", cursor: "pointer" }}>
                            Workflow status ({workflow ? workflow.overAllState : null})
                          </h5>
                          {Object.keys(workflow.workflowStatusMap).map((index) => {
                            const object = workflow.workflowStatusMap[index];

                            return (
                              <div key={"kppk" + index}>
                                {Object.keys(object).map((index2) => {
                                  const object2 = object[index2];
                                  return (
                                    <div key={"pkl" + index2}>
                                      {Object.keys(object2.subSections).map((index3) => {
                                        const object3 = object2.subSections[index3];
                                        return (
                                          <p
                                            key={"kspk" + index3}
                                            style={{
                                              marginBottom: "5px",
                                              borderBottom: "1px solid #eee",
                                              textTransform: "lowercase",
                                            }}
                                          >
                                            {index3}
                                            <span
                                              style={{
                                                float: "right",
                                              }}
                                            >
                                              {object3.currentState}
                                            </span>
                                          </p>
                                        );
                                      })}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                </Col>
              </Row>
            </Col>
            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    getProfile: (reqData) => dispatch(getProfile(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
