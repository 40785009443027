import React from "react";
import { Row, Col, Button } from "reactstrap";

import { connect } from "react-redux";
import Dropzone from "../../components/Dropzone/Dropzone";
import GoBack from "../../components/GoBack/GoBack";
import TrendForm from "./TrendForm";
import { uploadImages } from "../../datautils/actions/skuActions";
import {
  addUpdateTrend,
  fetchTrend,
} from "../../datautils/actions/adminActions";
import { getCategoryLevel } from "../../datautils/actions/categoryActions";
import { getMediaPath, getMediaUrl } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";

import {
  resetResponseValues,
  resetObjectValues,
  removeMediaObject,
} from "../../datautils/actions/responseHandlerActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";

class TempSku extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCatId: "",
      activeTab: "1",
      id: "",
      formData: {
        tags: "",
        summary: "",
        description: "",
        userGroupType: "",
        displayPriorityStatus: "",
        authorDisplayName: "",
        medias: {},
        status: "",
        likesCount: 0,
      },
      files: [],
      chain: [],
      currentObject: null,
      isUpdated: false,
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    this.props.resetResponseValues();
    this.props.resetObjectValues("trendDetail");
    const { id } = this.props.match.params;

    if (id) {
      this.setState({ id });
      this.props.fetchTrend({ trendId: id });
    } else {
      this.props.getCategoryLevel({});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { responseVal } = this.props;

    if (
      responseVal.dObjectData.trendDetail &&
      responseVal.dObjectData.trendDetail.data &&
      prevState.lastUpdated !==
        responseVal.dObjectData.trendDetail.data.updatedOn
    ) {
      const trendData = responseVal.dObjectData.trendDetail.data;
      const tags = trendData.tags && trendData.tags.join(",");

      this.setState({
        id: trendData.trendId,
        formData: {
          ...trendData,
          tags,
        },
        chain: trendData.catIdsIdentifierLabel,
        selectedCatId: trendData.catIdsIdentifier,
        files: [],
        lastUpdated: trendData.updatedOn,
      });
    }
  }

  enableDisableImage = (key, status) => (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const tagsArray = formData.tags.split(",").map((element) => element.trim());
    const mediasToPost = {};

    Object.keys(formData.medias).forEach((index) => {
      const obj = formData.medias[index];
      mediasToPost[index] = {
        fileId: index,
        status: key === index ? status : obj.status,
        primary: obj.primary,
      };
    });

    const trend = {
      ...formData,
      tags: tagsArray,
      medias: mediasToPost,
    };

    this.props.addUpdateTrend({ trend });
    this.setState({ isUpdated: true });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        formData: { ...prevState.formData, [obj]: opt.value },
      }),
      () => console.log("aaa", this.state)
    );
  };

  handleChange = (e) => {
    e.persist();

    this.setState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, [e.target.name]: e.target.value },
    }));
  };

  removeFromState = (row) => (e) => {
    let filteredArray = this.state.files.filter(
      (element) => element.name !== row.name
    );
    this.setState({ files: [...filteredArray] });
    this.props.removeMediaObject(row.name);
  };

  enableTrend = (status) => (e) => {
    this.props.addUpdateTrend({
      type: "status",
      trend: {
        trendId: this.state.formData.trendId,
        status,
      },
    });

    this.setState({ isUpdated: true });
  };

  addUpdateTrend = (e) => {
    e.preventDefault();
    const { formData, selectedCatId } = this.state;
    const { responseVal, userId } = this.props;
    const tagsArray = formData.tags.split(",").map((element) => element.trim());

    const mediasToPost = {};
    responseVal.mediaObject.forEach((obj) => {
      mediasToPost[obj[Object.keys(obj)]] = {
        fileId: obj[Object.keys(obj)],
        status: "ACTIVE",
        primary: true,
      };
    });

    Object.keys(formData.medias).forEach((index) => {
      const obj = formData.medias[index];
      mediasToPost[index] = {
        fileId: index,
        status: obj.status,
        primary: obj.primary,
      };
    });

    const trend = {
      ...formData,
      catIdsIdentifier: selectedCatId,
      tags: tagsArray,
      authorUserId: userId,
      status: formData.status ? formData.status : "INACTIVE",
      medias: mediasToPost,
    };

    this.props.addUpdateTrend({ trend });
    this.setState({ isUpdated: true });
  };

  handleClick = (level, current) => (e) => {
    const stepId = "level" + (level + 1) + "Id";

    /** Reset next cat level */
    let obj = {};
    if (level > 0) {
      for (let i = level + 1; i <= 6; i++) {
        let innerStepId = "level" + i + "Id";
        obj[innerStepId] = "";
        this.props.resetObjectValues("categoryLevel_" + i);
      }
    }
    /** ** ** ** ** ** ** **  */

    let chainArray = [...this.state.chain];

    chainArray = chainArray.slice(0, level - 1);
    if (current.name) {
      chainArray.push(current.name);
    }

    this.setState({
      ...obj,
      chain: chainArray,
      selectedCatId: current.leaf ? current.iRefId : "",
      [stepId]: current.id,
      currentObject: current,
      isUpdated: false,
    });

    if (current.leaf) {
      window.scroll({
        top: 650,
        left: 650,
        behavior: "smooth",
      });
    } else {
      this.props.getCategoryLevel({ iRefId: current.iRefId, level });
    }
  };

  onDrop = (acceptedFiles) => {
    this.setState({ files: [...this.state.files, ...acceptedFiles] });

    acceptedFiles.map((acceptedFile) => {
      return this.props.uploadImages(acceptedFile);
    });
  };

  render() {
    const { formData, chain, selectedCatId, isUpdated, files, id } = this.state;
    const { responseVal } = this.props;

    const level1 =
      responseVal.dObjectData &&
      responseVal.dObjectData.categoryLevel_0 &&
      responseVal.dObjectData.categoryLevel_0.data &&
      responseVal.dObjectData.categoryLevel_0.data.categories
        ? responseVal.dObjectData.categoryLevel_0.data.categories
        : null;

    const level2 =
      responseVal.dObjectData &&
      responseVal.dObjectData.categoryLevel_1 &&
      responseVal.dObjectData.categoryLevel_1.data &&
      responseVal.dObjectData.categoryLevel_1.data.categories
        ? responseVal.dObjectData.categoryLevel_1.data.categories
        : null;

    const level3 =
      responseVal.dObjectData &&
      responseVal.dObjectData.categoryLevel_2 &&
      responseVal.dObjectData.categoryLevel_2.data &&
      responseVal.dObjectData.categoryLevel_2.data.categories
        ? responseVal.dObjectData.categoryLevel_2.data.categories
        : null;

    const level4 =
      responseVal.dObjectData &&
      responseVal.dObjectData.categoryLevel_3 &&
      responseVal.dObjectData.categoryLevel_3.data &&
      responseVal.dObjectData.categoryLevel_3.data.categories
        ? responseVal.dObjectData.categoryLevel_3.data.categories
        : null;

    const level5 =
      responseVal.dObjectData &&
      responseVal.dObjectData.categoryLevel_4 &&
      responseVal.dObjectData.categoryLevel_4.data &&
      responseVal.dObjectData.categoryLevel_4.data.categories
        ? responseVal.dObjectData.categoryLevel_4.data.categories
        : null;

    const level6 =
      responseVal.dObjectData &&
      responseVal.dObjectData.categoryLevel_5 &&
      responseVal.dObjectData.categoryLevel_5.data &&
      responseVal.dObjectData.categoryLevel_5.data.categories
        ? responseVal.dObjectData.categoryLevel_5.data.categories
        : null;

    return (
      <div>
        <PanelHeader />
        <div className="content mt-3 ">
          {responseVal.status === "failure" ? (
            <ErrorBlock errors={responseVal.errors} />
          ) : null}
          {responseVal.status === "success" && isUpdated ? (
            <SuccessBlock msg={responseVal.data} />
          ) : null}

          <Row className="equal">
            <Col md={12} sm={12}>
              <GoBack text={id > 0 ? "Update Trend" : "Add Trend"} />
              {id > 0 ? null : (
                <React.Fragment>
                  <Row className="mb-4">
                    <Col md={2} sm={3} xs={6}>
                      <ul className="add-cat">
                        {level1
                          ? Object.keys(level1).map(function (keyName) {
                              const inner = level1[keyName];
                              return (
                                <li
                                  key={"one-" + inner.id}
                                  onClick={this.handleClick(1, inner)}
                                  className={
                                    this.state.level2Id === inner.id
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {inner.name}
                                </li>
                              );
                            }, this)
                          : null}
                      </ul>
                    </Col>
                    {level2 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat left-arrow">
                          {Object.keys(level2).map(function (keyName) {
                            const inner = level2[keyName];
                            return (
                              <li
                                key={inner.id}
                                onClick={this.handleClick(2, inner)}
                                className={
                                  this.state.level3Id === inner.id
                                    ? "active"
                                    : ""
                                }
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level3 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level3).map(function (keyName) {
                            const inner = level3[keyName];
                            return (
                              <li
                                key={inner.id}
                                onClick={this.handleClick(3, inner)}
                                className={
                                  this.state.level4Id === inner.id
                                    ? "active"
                                    : ""
                                }
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)}{" "}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level4 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level4).map(function (keyName) {
                            const inner = level4[keyName];
                            return (
                              <li
                                key={inner.id}
                                onClick={this.handleClick(4, inner)}
                                className={
                                  this.state.level5Id === inner.id
                                    ? "active"
                                    : ""
                                }
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level5 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level5).map(function (keyName) {
                            const inner = level5[keyName];
                            return (
                              <li
                                className={
                                  selectedCatId === inner.iRefId ||
                                  this.state.level6Id === inner.id
                                    ? "active"
                                    : ""
                                }
                                key={inner.id}
                                onClick={this.handleClick(5, inner)}
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level6 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level6).map(function (keyName) {
                            const inner = level6[keyName];
                            return (
                              <li
                                className={
                                  selectedCatId === inner.iRefId ? "active" : ""
                                }
                                key={inner.id}
                                onClick={this.handleClick(6, inner)}
                              >
                                {inner.name}
                              </li>
                            );
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}
                  </Row>
                </React.Fragment>
              )}

              <Col>
                {selectedCatId ? (
                  <React.Fragment>
                    <h5 className="row">
                      {id > 0 ? "Update" : "Add"} Trending Info and Images
                    </h5>

                    <Row className="product-images">
                      <Col sm="7">
                        <TrendForm
                          form={formData}
                          handleChange={this.handleChange}
                          addUpdateTrend={this.addUpdateTrend}
                          enableTrend={this.enableTrend}
                          handleChangeSelect={this.handleChangeSelect}
                          handleCheckedFinal={this.handleCheckedFinal}
                          categoryChain={chain}
                          files={files}
                        />
                      </Col>
                      <Col sm="5">
                        <div className="mt-3">
                          <Row className="equal">
                            <Col sm={12}>
                              <Dropzone
                                onFilesAdded={(opt) => this.onDrop(opt)}
                              />
                            </Col>
                          </Row>

                          {formData && files ? (
                            <>
                              {" "}
                              <Row className="mt-3 equal">
                                {files.length > 0 &&
                                  files.map((acceptedFile) => (
                                    <Col
                                      sm="3"
                                      className=""
                                      key={acceptedFile.name}
                                    >
                                      <img
                                        alt={acceptedFile.name}
                                        src={URL.createObjectURL(acceptedFile)}
                                        className="img-thumbnail rounded"
                                      />
                                      <p className="text-center">
                                        {acceptedFile.name}
                                      </p>
                                      <button
                                        onClick={this.removeFromState(
                                          acceptedFile
                                        )}
                                        type="button"
                                        className="close img-remove"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </Col>
                                  ))}
                              </Row>
                              <Row>
                                {files.length > 0 && formData.trendId ? (
                                  <Button
                                    color="primary"
                                    size="md"
                                    onClick={this.addUpdateTrend}
                                    disabled={
                                      !formData.summary ||
                                      !formData.userGroupType ||
                                      !formData.authorDisplayName ||
                                      !formData.displayPriorityStatus
                                    }
                                  >
                                    Update Images
                                  </Button>
                                ) : null}
                              </Row>
                            </>
                          ) : null}

                          {formData.medias ? (
                            <Row className="mt-2 equal">
                              <Col sm={12}>
                                <h5>Available Images</h5>
                              </Col>
                              {Object.keys(formData.medias).map((index) => {
                                return (
                                  <Col md="4" key={index}>
                                    <div className="bg-white p-2 mb-3">
                                      {" "}
                                      <ImageZoom
                                        src={getMediaPath(
                                          formData.medias[index].fileId,
                                          formData.medias[index].fileExt
                                        )}
                                        alt=""
                                      />
                                      {formData.medias[index].status ===
                                      "ACTIVE" ? (
                                        <Button
                                          size="sm"
                                          className="btn-block"
                                          color="success"
                                          onClick={this.enableDisableImage(
                                            index,
                                            "INACTIVE"
                                          )}
                                        >
                                          Deactivate
                                        </Button>
                                      ) : (
                                        <Button
                                          size="sm"
                                          className="btn-block"
                                          color="danger"
                                          onClick={this.enableDisableImage(
                                            index,
                                            "ACTIVE"
                                          )}
                                        >
                                          Activate
                                        </Button>
                                      )}
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          ) : null}

                          <div
                            className="price-invent"
                            style={{ padding: 0, minHeight: "auto" }}
                          >
                            <div className="card-header">Converted Images</div>
                            <div className="card-body">
                              <Row>
                                {formData.medias &&
                                  Object.keys(formData.medias).map((index) => {
                                    const path = getMediaUrl({
                                      fileId: formData.medias[index].fileId,
                                      fileExt: formData.medias[index].fileExt,
                                      width: 500,
                                      height: 300,
                                    });
                                    return (
                                      <Col md="6" key={index}>
                                        <div className="bg-white p-2 mb-3">
                                          <ImageZoom src={path} alt="" />
                                          <a
                                            className="btn btn-primary btn-block"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={path}
                                          >
                                            Converted 500x300
                                          </a>
                                        </div>
                                      </Col>
                                    );
                                  })}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : null}
                {this.props.isLoading ? <div className="loader" /> : null}
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    userId: state.auth.userId,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUpdateTrend: (reqParam) => dispatch(addUpdateTrend(reqParam)),
    fetchTrend: (reqParam) => dispatch(fetchTrend(reqParam)),
    uploadImages: (acceptedFile) => dispatch(uploadImages(acceptedFile)),
    getCategoryLevel: (reqParam) => dispatch(getCategoryLevel(reqParam)),
    resetObjectValues: (reqParam) => dispatch(resetObjectValues(reqParam)),
    removeMediaObject: (reqParam) => dispatch(removeMediaObject(reqParam)),
    resetResponseValues: () => dispatch(resetResponseValues()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TempSku);
