import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Button,
  Table,
  ButtonToolbar,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { connect } from "react-redux";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import { listAdmin, createAdmin } from "../../datautils/actions/adminActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { SUPPLIER_USERS_DETAIL } from "../../utils/constants/clientUrls";

import { sortIcon } from "../../utils/constants/icons";
import { PER_PAGE, supplierUserRoles, defaultParams } from "../../utils/constants/index";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import { formatDateTime } from "../../utils/helpers/HelperFunctions";
import Select from "react-select";

class AdminList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      pageNumber: 1,
      userAppId: "1",
      userTypeId: "1",
      pageSize: PER_PAGE,
      sortField: "userId",
      ascending: false,
      currentTab: "",
      modal: false,
      uType: "SUPPLIER",
      uRole: "",
      mobileCountryCode: "+91",
      mobileNumber: "",
      password: "",
    };

    // userTypeId = 1 for SUPPLIER
    // userTypeId = 2 for CUSTOMER
    // userTypeId = 3 for ADMIN
    // userTypeId = 4 for OPS

    // userAppId = 1  for SP
    // userAppId = 2 for LD
  }

  componentDidMount() {
    this.listAdmin();
  }

  componentDidUpdate(prevProps, prevState) {
    const { pageNumber, modal, isUpdated } = this.state;

    if (prevState.pageNumber !== pageNumber) {
      this.listAdmin();
    }
    if (this.props.responseVal.status === "success" && isUpdated && modal) {
      this.setState({ modal: false });
      this.listAdmin();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.listAdmin()
      );
    }
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.listAdmin()
    );
  };
  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.listAdmin()
    );
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  filterData = (e) => {
    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
      },
      () => this.listAdmin()
    );
  };

  listAdmin = () => {
    const {
      globalSearchKeyword,
      //currentTab,
      pageNumber,
      pageSize,
      sortField,
      ascending,
      userAppId,
      userTypeId,
    } = this.state;

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [
        { key: "userAppId", operation: "EQUAL_TO", value: userAppId },
        { key: "userTypeId", operation: "EQUAL_TO", value: userTypeId },
      ],

      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.props.listAdmin(reqData);
  };

  handleChange = (input) => () => {
    this.props.history.push(SUPPLIER_USERS_DETAIL + input);
  };

  changePage = (page) => () => {
    this.setState({ pageNumber: page });
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.adminList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  toggle = (requestData) => {
    const { modal } = this.state;
    if (modal) {
      this.setState({
        modal: false,
        notes: "",
        currentTitle: "",
        currentAction: "",
        currentActionType: "",
      });
    } else {
      this.setState({
        modal: true,
        notes: "",
        currentTitle: requestData.title,
        currentAction: requestData.action,
        currentActionType: requestData.actionType,
        isUpdated: false,
      });
      this.props.resetResponseValues();
    }
  };

  handleChangeInput = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState({ [obj]: opt.value });
  };

  renderModal = () => {
    const {
      // notes,
      // currentAction,
      // currentActionType,
      currentTitle,
      password,
      mobileNumber,
      uType,
      uRole,
    } = this.state;

    return (
      <Modal backdrop="static" isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{currentTitle.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    <Form autoComplete="off">
                      <FormGroup>
                        <label>
                          Select User Role
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          isSearchable={false}
                          className="react-select"
                          name="trackingAction"
                          options={supplierUserRoles}
                          value={supplierUserRoles.filter((option) => option.value === uRole)}
                          onChange={(opt) => this.handleChangeSelect("uRole", opt)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <label>
                          Enter Mobile Number
                          <span className="text-danger">*</span>
                        </label>
                        <Input
                          type="text"
                          placeholder="Enter Mobile Number"
                          onChange={this.handleChangeInput("mobileNumber")}
                          value={mobileNumber}
                        />
                      </FormGroup>

                      <FormGroup>
                        <label>
                          Enter Password
                          <span className="text-danger">*</span>
                        </label>
                        <Input
                          type="text"
                          placeholder="Enter Password"
                          onChange={this.handleChangeInput("password")}
                          value={password}
                        />
                      </FormGroup>
                    </Form>

                    <Button
                      className="btn-block btn-success"
                      onClick={this.addUpdateOps("ADD_OPS")}
                      disabled={!password || !mobileNumber || !uRole || !uType}
                    >
                      Create User
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  addUpdateOps = (actionType) => (e) => {
    e.preventDefault();

    const { modal, uType, uRole, mobileCountryCode, mobileNumber, password } = this.state;

    const reqData = {
      userType: uType,
      userRole: uRole,
      mobileCountryCode,
      mobileNumber,
      password,
      ...defaultParams(),
    };

    if (modal) {
      this.setState({ isUpdated: true });
      this.props.createAdmin(reqData);
    } else {
      this.toggle({
        actionType,
        action: "update",
        title: "Update Ops",
      });
    }
  };

  render() {
    const { responseVal, userId } = this.props;
    const {
      globalSearchKeyword,
      ascending,
      pageNumber,
      pageSize,
      // currentTab,
      modal,
    } = this.state;

    const dataLoaded = responseVal.dObjectData?.adminList?.data?.users;

    return (
      <div>
        <PanelHeader />
        {modal ? this.renderModal() : null}
        <div className="content">
          <Row>
            <Col xs={12}>
              <MessageBlock screenName="admin" />
            </Col>
            <Col xs={12}>
              <ButtonToolbar>
                {" "}
                <Button
                  size="sm"
                  className="btn-primary ml-auto"
                  onClick={() =>
                    this.toggle({
                      actionType: "status",
                      action: "update",
                      title: "Add OPS",
                    })
                  }
                >
                  Add New User
                </Button>
              </ButtonToolbar>
            </Col>
            <Col xs={12}>
              <Card>
                <CardBody className="listings">
                  <React.Fragment>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search User..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button color="primary" onClick={this.searchButton}>
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="8">{dataLoaded ? this.renderPagination() : ""}</Col>
                    </Row>

                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>
                          <th>UID </th>
                          <th>User Type </th>
                          <th>User Role </th>
                          <th onClick={this.sortButton("mobileNumber", ascending ? false : true)}>
                            Mobile <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>
                          <th>Title</th>
                          <th onClick={this.sortButton("firstName", ascending ? false : true)}>
                            First Name <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>

                          <th onClick={this.sortButton("lastName", ascending ? false : true)}>
                            Last Name <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>

                          <th>Email </th>
                          <th>Registered On</th>
                          <th>Updated On</th>
                          <th>Active</th>
                          <th>Verified</th>
                          <th>APP Type</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLoaded ? (
                          dataLoaded.length ? (
                            dataLoaded.map((row, index) => {
                              const cname =
                                row.status === "ACTIVE"
                                  ? "success"
                                  : row.status === "INACTIVE"
                                  ? "danger"
                                  : row.status === "ONHOLD"
                                  ? "warning"
                                  : "primary";
                              return userId !== row.userId ? (
                                <tr className="cursor" key={index} onClick={this.handleChange(row.userId)}>
                                  <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>
                                  <td>{row.userId}</td>
                                  <td>{row.userType}</td>
                                  <td>{row.userRole}</td>
                                  <td>{row.mobileNumber}</td>
                                  <td>{row.title} </td>
                                  <td>{row.firstName} </td>
                                  <td>{row.lastName}</td>
                                  <td>{row.email}</td>
                                  <td>{formatDateTime(row.dateCreated)}</td>
                                  <td>{formatDateTime(row.dateModified)}</td>
                                  <td>
                                    {row.active ? (
                                      <span className="text-success">YES</span>
                                    ) : (
                                      <span className="text-danger">NO</span>
                                    )}
                                  </td>
                                  <td>
                                    {row.verified ? (
                                      <span className="text-success">YES</span>
                                    ) : (
                                      <span className="text-danger">NO</span>
                                    )}
                                  </td>
                                  <td>{row.appCode}</td>
                                  <td className="text-right">
                                    <Button className={`btn-simple btn-light btn-${cname}`} size="xs">
                                      View
                                    </Button>{" "}
                                  </td>
                                </tr>
                              ) : null;
                            })
                          ) : (
                            <tr>
                              <td colSpan="15" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="15" />
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {this.props.isLoading ? <div className="loader" /> : null}
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    listAdmin: (reqData) => dispatch(listAdmin(reqData)),
    createAdmin: (reqData) => dispatch(createAdmin(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminList);
