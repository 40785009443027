import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  ButtonToolbar,
  // FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import PanelHeader from "../../components/PanelHeader/PanelHeader";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import {
  SUPPLIER_SAMPLE_VIEW,
  SUPPLIER_SKU_VIEW,
  ADMIN_SAMPLE_VIEW,
  ADMIN_CUSTOMER_VIEW,
  ADMIN_SKU_VIEW,
  ADMIN_SUPPLIERR_VIEW,
  ADMIN_PRODUCT_VIEW,
} from "../../utils/constants/clientUrls";

//import { sortIcon } from "../../utils/constants/icons";
import { PER_PAGE } from "../../utils/constants/index";
import { getSampleList } from "../../datautils/actions/sampleRequestsActions";
import { formatDateTime } from "../../utils/helpers/HelperFunctions";

class SampleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "2",
      currentFilter: "sampleStatus",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "updatedOn",
      ascending: false,
      toggle: false,
      filterArray: ["REQUESTED"],
    };
  }
  componentDidMount() {
    this.searchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchData();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.searchData()
      );
    }
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchData()
    );
  };

  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.searchData()
    );
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  searchData = () => {
    const { globalSearchKeyword, pageNumber, pageSize, sortField, ascending, filterArray, currentTab } = this.state;

    const fieldNameKeyword = filterArray
      ? filterArray.map((t) => {
          return {
            key: "sampleStatus",
            operation: "EQUAL_TO",
            value: t,
          };
        })
      : {};

    let filterVal = {};

    if (currentTab === "3") {
      filterVal = {
        key: "ordered",
        value: [true],
      };
    }

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [...fieldNameKeyword],
      filterOptions: [{ ...filterVal }],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };

    this.props.getSampleList(reqData);
  };

  handleChange = (input) => () => {
    this.props.userType === "SUPPLIER"
      ? this.props.history.push(SUPPLIER_SAMPLE_VIEW + input)
      : this.props.history.push(ADMIN_SAMPLE_VIEW + input);
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  filterData = (e) => {
    let fArray = [];
    if (e.target.value === "0") {
      fArray.push("DELETED", "REJECTED");
    } else if (e.target.value === "1") {
      fArray.push("ACCEPTED");
    } else if (e.target.value === "2") {
      fArray.push("REQUESTED");
    }

    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
        currentFilter: e.target.dataset.filter,
        filterArray: [...fArray],
      },
      () => this.searchData()
    );
  };

  renderPagination = () => {
    const { sampleItems } = this.props;
    const mainData = sampleItems.sampleList;

    return mainData && mainData.totalNoOfPages > 1 ? (
      <CustomPagination
        pageNumber={this.state.pageNumber}
        totalNoOfPages={mainData.totalNoOfPages}
        changePage={this.changePage}
        hasPrevious={mainData.hasPrevious}
        hasNext={mainData.hasNext}
      />
    ) : null;
  };

  render() {
    const { sampleItems } = this.props;
    const {
      // globalSearchKeyword,
      // ascending,
      currentTab,
      pageNumber,
      pageSize,
    } = this.state;

    const responseData = sampleItems.sampleList;

    return (
      <div>
        <PanelHeader />
        <div className="content mt-0">
          <Row>
            <Col xs={12}>
              {responseData && responseData.responseStatus ? (
                <ErrorBlock errors={responseData.responseStatus.errors} />
              ) : null}
            </Col>

            <Col xs={12}>
              <React.Fragment>
                <ButtonToolbar style={{ display: "block" }}>
                  <Button size="sm" value="3" onClick={this.filterData} color={currentTab === "3" ? "primary" : ""}>
                    Ordered Requests
                  </Button>
                  <Button size="sm" value="-1" onClick={this.filterData} color={currentTab === "-1" ? "primary" : ""}>
                    All Requests
                  </Button>
                  <Button size="sm" value="0" onClick={this.filterData} color={currentTab === "0" ? "primary" : ""}>
                    Deleted Requests
                  </Button>
                  <Button size="sm" value="1" onClick={this.filterData} color={currentTab === "1" ? "primary" : ""}>
                    Accepted Requests
                  </Button>
                  <Button size="sm" value="2" onClick={this.filterData} color={currentTab === "2" ? "primary" : ""}>
                    New Requests
                  </Button>
                </ButtonToolbar>
                <Card>
                  <CardBody className="listings">
                    <Row>
                      <Col md="4">
                        {/* <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search Requests..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button
                                  color="primary"
                                  onClick={this.searchButton}
                                >
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup> */}
                      </Col>
                      <Col md="8">{this.renderPagination()}</Col>
                    </Row>
                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>

                          {/* <th
                            onClick={this.sortButton(
                              "skuSampleRequestId",
                              ascending ? false : true
                            )}
                          >
                            Request Id{" "}
                            <img
                              alt="sort"
                              className="sort-icon"
                              src={sortIcon}
                            />
                          </th> */}

                          <th>Request Id</th>
                          <th>Product Name</th>
                          <th>Supplier Id</th>
                          <th>Product Id</th>
                          <th>Variant</th>

                          <th>Sku Id</th>
                          <th>Qty</th>
                          <th>Total Price</th>
                          <th>Customer Id</th>
                          <th>Status</th>
                          <th>Ordered</th>
                          <th>Update Date</th>

                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {responseData ? (
                          responseData.sampleRequests && responseData.sampleRequests.length ? (
                            responseData.sampleRequests.map((item, index) => {
                              const row = item.source;

                              const cname =
                                row.status === "ACTIVE"
                                  ? "success"
                                  : row.status === "INACTIVE"
                                  ? "danger"
                                  : row.status === "ONHOLD"
                                  ? "warning"
                                  : "primary";
                              return (
                                <tr key={index}>
                                  <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>

                                  <td>{row.skuSampleRequestId}</td>
                                  <td>{row.productName}</td>

                                  <td>
                                    {this.props.userType === "SUPPLIER" ? (
                                      row.supplierId
                                    ) : (
                                      <Link to={ADMIN_SUPPLIERR_VIEW + row.supplierId}>{row.supplierId}</Link>
                                    )}
                                  </td>
                                  <td>
                                    {this.props.userType === "SUPPLIER" ? (
                                      row.productIdentifier
                                    ) : (
                                      <Link
                                        to={
                                          ADMIN_PRODUCT_VIEW +
                                          row.productIdentifier +
                                          "/variant/" +
                                          row.productVariantIdentifier
                                        }
                                      >
                                        {row.productIdentifier}
                                      </Link>
                                    )}
                                  </td>
                                  <td>{row.variantType + ":" + row.variantName}</td>

                                  <td>
                                    <Link
                                      to={
                                        this.props.userType === "SUPPLIER"
                                          ? SUPPLIER_SKU_VIEW + row.skuId
                                          : ADMIN_SKU_VIEW + row.skuId
                                      }
                                    >
                                      {row.skuId}
                                    </Link>
                                  </td>
                                  <td>{row.sampleOrderQuantity}</td>
                                  <td>
                                    &#x20b9;
                                    {row.sampleUnitOfferPrice && Math.round(row.sampleUnitOfferPrice).toLocaleString()}
                                  </td>
                                  <td>
                                    {this.props.userType === "SUPPLIER" ? (
                                      row.customerId
                                    ) : (
                                      <Link to={ADMIN_CUSTOMER_VIEW + row.customerId}>{row.customerId}</Link>
                                    )}
                                  </td>
                                  <td>{row.sampleStatus}</td>
                                  <td>
                                    {row.ordered ? (
                                      <span className="text-success">YES</span>
                                    ) : (
                                      <span className="text-danger">NO</span>
                                    )}
                                  </td>
                                  <td>{formatDateTime(row.updatedOn)}</td>

                                  <td className="text-right">
                                    <Button
                                      className={`btn-simple btn-light btn-primary btn-${cname}`}
                                      size="xs"
                                      onClick={this.handleChange(row.skuSampleRequestId)}
                                    >
                                      View
                                    </Button>{" "}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="15" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="14" />
                          </tr>
                        )}
                      </tbody>
                    </Table>{" "}
                    {this.props.isLoading ? <div className="loader" /> : null}
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    sampleItems: state.sampleItems,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSampleList: (reqParam) => dispatch(getSampleList(reqParam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleList);
