import React, { Component } from "react";
class Guidelines extends Component {
  state = { guidelines: false };
  render() {
    const { guidelines } = this.state;
    return (
      <React.Fragment>
        <div
          className={!guidelines ? "guidelines closed" : "guidelines opened"}
        >
          <p
            style={{ float: "left" }}
            onClick={() => this.setState({ guidelines: !guidelines })}
          >
            <span>{!guidelines ? "View" : "Hide"} SKU Guidelines / Steps</span>{" "}
            <i
              className={
                !guidelines
                  ? "now-ui-icons arrows-1_minimal-down"
                  : "now-ui-icons arrows-1_minimal-up"
              }
            />
          </p>

          <div className="clearfix"></div>
          <ol>
            <li>1) Fill valid data for all mandatory fields.</li>
            <li>2) Mark details as completed.</li>
            <li>3) Upload the good quality product images.</li>
            <li>
              4) After uploading all the product images, mark image section as
              completed.
            </li>
            <li>
              5) Sku will be moved to QC Inprogress state, wait for admin to
              approve the sku.
            </li>
            <li>
              6) If there is any mistake, sku will be rejected. Make changes to
              details and resubmit for approval.
            </li>
            <li>
              7) Once approved, fill inventory detail, price details and sample
              pricing details if applicable.
            </li>
            <li>8) Make sure pricing details are right.</li>
            <li>
              9) Publish the sku and wait for 6 hour. If everything goes well,
              product will be published on app.
            </li>
            <li>
              10) If there is any problem publishing the sku on app, Open help
              tickets or contact admin.
            </li>
          </ol>
        </div>
      </React.Fragment>
    );
  }
}

export default Guidelines;
