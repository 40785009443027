import { startUILoading, stopUILoading } from "./uiActions";
import { CLEAR_SAMPLE_LIST, SAVE_SAMPLE_LIST, SAVE_SAMPLE_DETAIL, CLEAR_SAMPLE_DETAIL } from "./actionTypes";
import { FETCH_SAMPLE_LIST, FETCH_SAMPLE_DETAIL, UPDATE_SAMPLE_DETAIL } from "../../utils/constants/apiUrls";
import { getRequestHeaders } from "./authActions";
import { saveResponseMessage, clearMessage } from "./responseMessageActions";
import axios from "axios";

/** Get list of all sample offer requests */
export const getSampleList = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());

    try {
      const config = await dispatch(getRequestHeaders());

      const { userType } = getState().auth;
      const postData = { ...reqParam };

      const URL = FETCH_SAMPLE_LIST + "/" + userType.toLowerCase() + "/search/sku/samples";
      const response = await axios.post(URL, postData, config);

      console.log("Get Sample List Success Executed", response.data);
      dispatch(saveSampleList(response.data.data));
    } catch (error) {
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Get detail of a specific sample offer request */
export const getSampleDetail = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearSampleDetail());
    try {
      const config = await dispatch(getRequestHeaders());

      const { userType } = getState().auth;
      const postData = { ...reqParam };

      const URL = FETCH_SAMPLE_DETAIL + userType.toLowerCase() + "/fetch/sku/sample/" + reqParam.skuSampleRequestId;

      // const URL =
      //   FETCH_SAMPLE_DETAIL + userType.toLowerCase() + "/fetch/sku/sample";

      const response = await axios.post(URL, postData, config);

      console.log("Get Sample List Success Executed", response.data);
      dispatch(saveSampleDetail(response.data.data));
    } catch (error) {
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update sample offer requested by customer */
export const updateSample = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("sampleoffer"));
    try {
      const config = await dispatch(getRequestHeaders());

      const { userType } = getState().auth;
      const postData = { ...reqParam };

      const URL = UPDATE_SAMPLE_DETAIL + userType.toLowerCase() + "/request/sku/sample";
      const response = await axios.post(URL, postData, config);

      dispatch(saveSampleDetail(response.data?.data?.sample));
      dispatch(saveResponseMessage("sampleoffer", { hasErrors: false }));
    } catch (error) {
      /** Save eror to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage("sampleoffer", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Below are the Redux actions to save and delete data from store*/
export const saveSampleList = (data) => {
  return {
    type: SAVE_SAMPLE_LIST,
    data,
  };
};

export const clearSampleInfo = (data) => {
  return {
    type: CLEAR_SAMPLE_LIST,
    data,
  };
};

export const saveSampleDetail = (data) => {
  return {
    type: SAVE_SAMPLE_DETAIL,
    data: data,
  };
};

export const clearSampleDetail = () => {
  return {
    type: CLEAR_SAMPLE_DETAIL,
  };
};
