import React from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardBody,
  CardFooter,
  Button,
} from "reactstrap";
import OtpCountdown from "../../../components/OtpCountdown/OtpCountdown";
import { OTP_TIMER } from "../../../utils/constants/";

class PostRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { values } = this.props;
    return (
      <>
        <CardBody>
          <Form autoComplete="off">
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>+91</InputGroupText>
                </InputGroupAddon>

                <Input
                  className={this.props.mobileState}
                  type="text"
                  name="mobile"
                  placeholder="Mobile Number"
                  maxLength="10"
                  onChange={this.props.handleChange("mobile")}
                  value={values.mobile}
                  disabled
                />
              </InputGroup>
              <small className="form-text text-muted text-right change-number" onClick={this.props.handleBack}>
                Change
              </small>
            </FormGroup>
            <FormGroup>
              <Input
                className={this.props.passwordState}
                type="password"
                name="password"
                placeholder="Password"
                onChange={this.props.handleChange("password")}
                value={values.password}
              />
            </FormGroup>
            <FormGroup>
              <Input
                className={this.props.otpState}
                type="text"
                name="otp"
                placeholder="OTP"
                maxLength="4"
                onChange={this.props.handleChange("otp")}
                value={values.otp}
              />
            </FormGroup>
          </Form>
          <div className="clearfix" />
          <div className="resend-otp">
            {this.props.showSendOtpOption ? (
              <span onClick={this.props.saveAndContinue}>resend otp</span>
            ) : (
              <OtpCountdown time={OTP_TIMER} onStop={this.props.onStop} />
            )}
          </div>
        </CardBody>
        <CardFooter className="text-center">
          <Button
            block
            color="primary"
            size="md"
            onClick={this.props.sendOTP}
            disabled={this.props.isLoading || !this.props.isEnabled}
          >
            Submit
          </Button>
        </CardFooter>
      </>
    );
  }
}

export default PostRegistration;
