export const WEB_URL = "https://slickpals.com";
const API_BASE = "https://api.ledgerdiary.com";

//////////////////// Services BaseURL ////////////////////

const MEDIA_SERVICE = API_BASE + "/media-service";
const AUTH_SERVICE = API_BASE + "/auth-service";
const ORDER_SERVICE = API_BASE + "/order-service";
const SUPPLIER_SERVICE = API_BASE + "/supplier-service";
const SEARCH_SERVICE = API_BASE + "/search-service";
const PAYMENT_SERVICE = API_BASE + "/payment-service";

const SUPPLIER_API_BASE = SUPPLIER_SERVICE + "/supplier";
const ADMIN_API_BASE = SUPPLIER_SERVICE + "/admin";

//////////////////// Send Contactus Api ////////////////////

export const SEND_CONTACT_US = "https://api.ledgerdiary.com/payment-service/send/email/ld";

//////////////////// Auth Apis ////////////////////

export const REGISTER_API_URL = AUTH_SERVICE + "/register/supplier";
export const VERIFY_AUTH_CODE_API_URL = AUTH_SERVICE + "/register/supplier";
export const LOGIN_API_URL = AUTH_SERVICE + "/login/supplier";
export const REFRESH_TOKEN = AUTH_SERVICE + "/refreshtoken";
export const FORGOT_PASSWORD_API_URL = AUTH_SERVICE + "/forgotpasswordrecover";
export const FORGOT_PASSWORD_VERIFY_API_URL = AUTH_SERVICE + "/forgotpasswordverify";
export const CHANGE_PASSWORD_API_URL = AUTH_SERVICE + "/changepassword";
export const SUPPLIER_GET_PROFILE_API_URL = SUPPLIER_API_BASE + "/fetch/profile/";
export const SUPPLIER_UPDATE_PROFILE_API_URL = SUPPLIER_API_BASE + "/update/profile/";

//////////////////// KYC and Supplier Apis ////////////////////

export const SUPPLIER_GET_KYC_API_URL = SUPPLIER_API_BASE + "/fetchall/kyc?userId=";
export const SUPPLIER_UPDATE_KYC_API_URL = SUPPLIER_API_BASE + "/update/kyc/";
export const SUPPLIER_GET_BANK_INFO_API_URL = SUPPLIER_API_BASE + "/fetch/bankinfo?userId=";
export const SUPPLIER_UPDATE_BANK_INFO_API_URL = SUPPLIER_API_BASE + "/update/bankinfo";

export const MEDIA_GET_API = MEDIA_SERVICE + "/get/";
export const MEDIA_CONVERTGET_API = MEDIA_SERVICE + "/convertget/";
export const MEDIA_UPLOAD_API = MEDIA_SERVICE + "/post/";
export const GET_STATES_API_URL = SUPPLIER_SERVICE + "/states";
export const GET_CITIES_API_URL = SUPPLIER_SERVICE + "/cities?stateId=";
export const SUPPLIER_GET_ADDRESS_API_URL = SUPPLIER_API_BASE + "/fetchall/address?userId="; //{supplierUserId}
export const SUPPLIER_UPDATE_ADDRESS_API_URL = SUPPLIER_API_BASE + "/update/address";
export const SUPPLIER_GET_CONTACT_API_URL = SUPPLIER_API_BASE + "/fetchall/contact?userId=";
export const SUPPLIER_UPDATE_CONTACT_API_URL = SUPPLIER_API_BASE + "/update/contact";
export const SUPPLIER_GET_PICKUP_ADDRESS_API_URL =
  SUPPLIER_API_BASE + "/fetch/address?addressType=PICKUP_ADDRESS&userId="; //{supplierUserId}

///supplier/fetch/address?userId=9&addressType=REGISTERED_ADDRESS
///supplier/fetch/contact?userId=9&contactType=PRIMARY_CONTACT
///supplier/fetch/kyc?userId=9&kycDocumentType=PAN

export const SUPPLIERS_LIST_API_URL = ADMIN_API_BASE + "/search/supplier/";
export const SUPPLIERS_PROFILE_API_URL = ADMIN_API_BASE + "/search/supplier/";

export const ADMIN_UPDATE_SUPPLIER = ADMIN_API_BASE + "/supplier/update/profile/";
export const ADMIN_UPDATE_BANK = ADMIN_API_BASE + "/supplier/update/bankinfo";
export const ADMIN_UPDATE_KYC = ADMIN_API_BASE + "/supplier/update/kyc";
export const ADMIN_UPDATE_ADDRESS = ADMIN_API_BASE + "/supplier/update/address";
export const ADMIN_ACTIVATE_SUPPLIER = ADMIN_API_BASE + "/a";
export const ADMIN_VERIFY_SUPPLIER = ADMIN_API_BASE + "/v";

//////////////////// Category Apis ////////////////////

export const GET_CATEGORIES_LEVEL = SUPPLIER_SERVICE + "/categories/label?catrefid="; //1_19_28
export const GET_CATEGORIES = SUPPLIER_SERVICE + "/categorytree";
export const SEARCH_CATEGORY = SUPPLIER_SERVICE + "/search/categoryname?term="; //{term}
export const ADD_CATEGORY = ADMIN_API_BASE + "/addupdate/category?value=";
export const ADD_UPDATE_GST = ADMIN_API_BASE + "/category/update/gst";
export const ADD_UPDATE_CONFIG = ADMIN_API_BASE + "/category/update/config";
export const ADD_UPDATE_COMMISSION = ADMIN_API_BASE + "/category/update/commission";
export const ACTIVATE_DEACTIVATE_CATEGORY = ADMIN_API_BASE + "/category/"; //{status}/{catRefId}

//////////////////// Category Attributes APIs ////////////////////

export const SEARCH_ATTRIBUTES = SUPPLIER_SERVICE + "/search/category/attribute?term="; //{term}
export const ADMIN_EXISTING_ATTRIBUTES = ADMIN_API_BASE + "/category/"; //{catIdentifier}/attributes
export const ADMIN_ADD_UPDATE_MASTER_ATTRIBUTES = ADMIN_API_BASE + "/addupdate/categoryattributemaster";
export const ADMIN_ATTACH_ATTRIBUTE_TO_CATEGORY = ADMIN_API_BASE + "/addupdate/category/"; //{catIdentifier}/attribute";
export const ADMIN_ADD_UPDATE_CATEGORY_MEDIA = ADMIN_API_BASE + "/addupdate/category/media";

//////////////////// Brand Apis ////////////////////

export const ADD_BRAND = ADMIN_API_BASE + "/addupdate/brand";
export const FETCH_BRANDS = SUPPLIER_SERVICE + "/fetch/brand/by/"; //{type}
//export const SEARCH_BRAND = SUPPLIER_SERVICE + "/search/brand?term="; //{term}
export const SEARCH_BRAND = SUPPLIER_SERVICE + "/search/brand/new";
export const FETCH_ALL_BRANDS = SUPPLIER_SERVICE + "/fetch/all/brand";
export const ADMIN_UPDATE_BRAND_MEDIA = ADMIN_API_BASE + "/update/brand/media";

//////////////////// SKU APIs ////////////////////

export const GET_SKU_FORM = SUPPLIER_API_BASE + "/form/sku/"; //?catRefTree=4924_4923&brandCode=NO_BRAND
export const ADD_UPDATE_SKU = SUPPLIER_API_BASE + "/update/sku";
export const ADD_UPDATE_SKU_MEDIA = SUPPLIER_API_BASE + "/update/skumedia";
export const SEARCH_SKU = SUPPLIER_SERVICE + "/search/sku/";
export const FETCH_SKU = SUPPLIER_SERVICE + "/fetch/sku/";
export const UPDATE_SKU_PRICE = SUPPLIER_API_BASE + "/update/priceinfo/sku";
export const UPDATE_SKU_INVENTORY = SUPPLIER_API_BASE + "/update/inventoryinfo/sku";
export const ACTIVE_DEACTIVE_SKU = SUPPLIER_API_BASE + "/update/status/sku";
export const ADMIN_FETCH_SKU = SUPPLIER_SERVICE + "/admin/fetch/sku/"; //{id}
export const ADMIN_UPDATE_SKU = ADMIN_API_BASE + "/update/sku";
export const ADMIN_UPDATE_MEDIA = ADMIN_API_BASE + "/update/skumedia";
export const DELETE_SKU_MEDIA = SUPPLIER_API_BASE + "/delete/skumedia";
export const SUPPLIER_SIMILAR_SKU = SUPPLIER_SERVICE + "/fetch/similar/variant/sku/"; //{skuId}
export const ADMIN_SIMILAR_SKU = ADMIN_API_BASE + "/fetch/similar/variant/sku/"; //{skuId}
export const UPDATE_STATUS_SKU_MEDIA = SUPPLIER_API_BASE + "/update/status/sku/media";

//////////////////// Admin Product APIs ////////////////////

export const ADMIN_FETCH_PRODUCT = ADMIN_API_BASE + "/fetch/product/"; //{id}
export const ADMIN_FETCH_VARIANT = ADMIN_API_BASE + "/fetch/product/"; //{id}/varaint/{vid}
export const ADMIN_PRODUCT_STATUS = ADMIN_API_BASE + "/statusupdate/product";
export const ADMIN_SEARCH_PRODUCT = SUPPLIER_SERVICE + "/search/product/";
export const ADMIN_UPDATE_PRODUCT = ADMIN_API_BASE + "/update/product";
export const ADMIN_UPDATE_PRODUCT_MEDIA = ADMIN_API_BASE + "/mediaupdate/product";
export const ADMIN_MARK_DEFAULT_PRODUCT = ADMIN_API_BASE + "/update/default/product/variant";
export const ADMIN_PUBLISH_PRODUCT = ADMIN_API_BASE + "/publish/product/?status="; //true/false
export const ADMIN_SIMILAR_PRODUCT = ADMIN_API_BASE + "/fetch/similar/product/"; //{productId} //{productId}/variant/{productVariant}
export const MARK_DEFAULT_VARIANT_SKU = ADMIN_API_BASE + "/update/default/product/variant/sku";

//////////////////// Admin Campaign APIs ////////////////////

export const ADMIN_SEARCH_CAMPAIGN = ADMIN_API_BASE + "/search/campaign";
export const ADMIN_CREATE_CAMPAIGN = ADMIN_API_BASE + "/create/campaign";
export const ADMIN_FETCH_CAMPAIGN_BY_ID = SUPPLIER_SERVICE + "/fetch/campaign/id/"; //{id}
export const ADMIN_FETCH_CAMPAIGN_BY_CODE = SUPPLIER_SERVICE + "/fetch/campaign/code/"; //{code}
export const ADMIN_UPDATE_CAMPAIGN = ADMIN_API_BASE + "/update/campaign";
export const ADMIN_UPDATE_CAMPAIGN_STATUS = ADMIN_API_BASE + "/update/campaign/status";
export const ADMIN_UPDATE_CAMPAIGN_MEDIA = ADMIN_API_BASE + "/update/campaign/media";
export const ADMIN_CAMPAIGN_ENTITY_MAPPING = ADMIN_API_BASE + "/update/campaign/entitymapping";

///////////////////// Admin Coupon APIs ////////////////////

export const ADMIN_SEARCH_COUPON = ADMIN_API_BASE + "/search/coupon";
export const ADMIN_CREATE_COUPON = ADMIN_API_BASE + "/create/coupon";
export const ADMIN_FETCH_COUPON_BY_ID = SUPPLIER_SERVICE + "/fetch/coupon/id/"; //{id}
export const ADMIN_FETCH_COUPON_BY_CODE = SUPPLIER_SERVICE + "/fetch/coupon/code/"; //{code}
export const ADMIN_UPDATE_COUPON = ADMIN_API_BASE + "/update/coupon";
export const ADMIN_UPDATE_COUPON_STATUS = ADMIN_API_BASE + "/update/coupon/status";
export const ADMIN_UPDATE_COUPON_MEDIA = ADMIN_API_BASE + "/update/coupon/media";
export const ADMIN_COUPON_ENTITY_MAPPING = ADMIN_API_BASE + "/update/coupon/entitymapping";
export const ADMIN_CATEGORY_TREE_SEARCH = SUPPLIER_SERVICE + "/fetch/category/names/"; //{catIdentifier}

//////////////////// Create Request APIs ////////////////////

export const CREATE_REQUEST = SUPPLIER_SERVICE + "/createupdate/requestqueue";
export const FETCH_REQUEST = SUPPLIER_SERVICE + "/fetch/requestqueue/by/"; //{type}
export const SEARCH_REQUEST = SUPPLIER_SERVICE + "/search/requestqueue/";
export const ADD_UPDATE_REQUEST_MEDIA = SUPPLIER_SERVICE + "/update/requestqueue/media";

//////////////////// Orders APIs ////////////////////

export const SEARCH_ORDERS = ORDER_SERVICE + "/search/order";
export const FETCH_ORDER = ORDER_SERVICE + "/fetch/order";
export const UPDATE_ORDER = ORDER_SERVICE + "/update/order";
export const UPDATE_ORDER_STATUS = ORDER_SERVICE + "/update/order/status";
export const UPDATE_ORDER_WAREHOUSE = ORDER_SERVICE + "/update/order/warehouse";

export const GENERATE_INVOICE = ORDER_SERVICE + "/generate/order/invoice";

export const UPDATE_DELIVERY_INFO = ORDER_SERVICE + "/update/order/delivery/info";
export const UPDATE_TRACKING_INFO = ORDER_SERVICE + "/update/order/delivery/tracking/info";

//////////////////// Customer Apis ////////////////////

export const SEARCH_CUSTOMER = ORDER_SERVICE + "/search/customer";
export const FETCH_CUSTOMER = ORDER_SERVICE + "/customer/fetch/profile/id/"; //{customerId}
export const UPDATE_CUSTOMER = SUPPLIER_SERVICE + "/customer/update/profile/";
export const SUPPLIER_GET_PROFILE_BY_USER_ID = SUPPLIER_API_BASE + "/fetch/profile/bysupplierid/";

/** (type = v/a), status ( v= VERIFY/UNVERIFY, a=ACTIVATE/DEACTIVATE) */
export const UPDATE_CUSTOMER_STATUS = ORDER_SERVICE + "/admin/"; // {type}/{status}/customer

//////////////////// Trending APIs ////////////////////

export const ADD_UPDATE_TRENDS = SUPPLIER_SERVICE + "/createupdate/cattrend";
export const SEARCH_TRENDS = SUPPLIER_SERVICE + "/search/cattrends";
export const FETCH_TRENDS = SUPPLIER_SERVICE + "/fetch/cattrend/"; //{trendId}
export const UPDATE_TRENDS_STATUS = SUPPLIER_SERVICE + "/update/cattrend/status";
export const UPDATE_TRENDS_DISPLAY_PRIORITY = SUPPLIER_SERVICE + "/update/cattrend/displaypriority/status";

//////////////////// Mega Menu APIs ////////////////////

export const MEGAMENU_TREE = SUPPLIER_SERVICE + "/megamenutree";
export const MEGAMENU_LEVEL = SUPPLIER_SERVICE + "/megamenu/label";
export const MEGAMENU_SEARCH = SUPPLIER_SERVICE + "/search/megamenuname?term=";
export const MEGAMENU_ADD_UPDATE = SUPPLIER_SERVICE + "/admin/addupdate/megamenu?value=";
export const MEGAMENU_ACTIONS = SUPPLIER_SERVICE + "/admin/megamenu/"; //{actionType}/{megaMenuRefId}
export const MEGAMENU_MEDIA_ADD_UPDATE = SUPPLIER_SERVICE + "/admin/addupdate/megamenu/media";
export const MEGAMENU_FETCH = SUPPLIER_SERVICE + "/fetch/megamenu/names/"; //{megaMenuLabelIdentifier}
export const MEGAMENU_UPDATE_MAPPING = SUPPLIER_SERVICE + "/admin/update/megamenu/entitymapping";
export const MEGAMENU_FETCH_MAPPING = SUPPLIER_SERVICE + "/admin/fetch/megamenu/entitymapping";

//////////////////// SAMPLE SKU APIS ////////////////////

export const SUPPLIER_FETCH_PRODUCT = SUPPLIER_API_BASE + "/fetch/product/";
export const SUPPLIER_UPDATE_SKU_SAMPLE = SUPPLIER_API_BASE + "/update/sampleinfo/sku";
export const FETCH_SAMPLE_LIST = SEARCH_SERVICE + "/"; //{userType}/search/sku/samples";
export const FETCH_SAMPLE_DETAIL = SEARCH_SERVICE + "/"; //{userType}/fetch/sku/sample/"; //{skuSampleRequestId}
export const UPDATE_SAMPLE_DETAIL = ORDER_SERVICE + "/"; //{userType}/request/sku/sample
export const FETCH_SAMPLE_DETAIL_UPDATED = ORDER_SERVICE + "/"; //{userType}/fetch/sku/sample

export const FETCH_CUSTOM_OFFER_LIST = SEARCH_SERVICE + "/"; //{userType}/search/sku/customoffer";
export const FETCH_CUSTOM_OFFER_DETAIL = SEARCH_SERVICE + "/"; //{userType}/fetch/sku/customoffer/"; //{skuCustomOfferRequestId}
export const UPDATE_CUSTOM_OFFER_DETAIL = ORDER_SERVICE + "/"; //{userType}/request/sku/customoffer

//////////////////// PAYMENT SERVICE APIS (Ledger) ////////////////////

export const FETCH_BILL_BOOK = PAYMENT_SERVICE + "/search/payment";
export const FETCH_BILL_DETAIL = PAYMENT_SERVICE + "/search/payment/to/"; //{toUserId}
export const CREATE_BILL_ENTRY = PAYMENT_SERVICE + "/create/entry/billbook";
export const REJECT_BILL_ENTRY = PAYMENT_SERVICE + "/reject/entry/billbook";
export const DELETE_BILL_ENTRY = PAYMENT_SERVICE + "/delete/entry/billbook";
export const MESSAGE_BILL_ENTRY = PAYMENT_SERVICE + "/add/message/billbook";
export const FETCH_BILL_ENTRY = PAYMENT_SERVICE + "/fetch/entry/billbook";
export const APPROVE_BILL_ENTRY = PAYMENT_SERVICE + "/approve/entry/billbook";

//////////////////// Stats Apis ////////////////////
export const FETCH_HOME_STATS = SEARCH_SERVICE + "/fetch/stats";
export const FETCH_COUNT_STATS = SEARCH_SERVICE + "/admin/fetch/stats/count";
export const FETCH_COUNT_STATS_SUPPLIER = SEARCH_SERVICE + "/supplier/fetch/stats/count";
export const USER_ACTIVITY = SEARCH_SERVICE + "/admin/fetch/user/"; //{userId}/stats

////////////////////  Admin user creation ////////////////////
export const CREATE_ADMIN = AUTH_SERVICE + "/admin/create/internal/user";
export const LIST_ADMIN = AUTH_SERVICE + "/search/user";
export const FETCH_ADMIN = AUTH_SERVICE + "/admin/fetch/internal/SP/user/"; //{id};
export const UPDATE_ADMIN_CHANGE_PASSWORD = AUTH_SERVICE + "/admin/changepassword/internal/user";
export const UPDATE_ADMIN_ACTIVATE = AUTH_SERVICE + "/admin/activate/internal/user";
export const UPDATE_ADMIN_DEACTIVATE = AUTH_SERVICE + "/admin/deactivate/internal/user";

////////////////////  Supplier user creation ////////////////////
export const CREATE_USER = AUTH_SERVICE + "/supplier/create/sub/user";
export const LIST_USER = AUTH_SERVICE + "/supplier/search/sub/user";
export const FETCH_USER = AUTH_SERVICE + "/supplier/fetch/sub/user/"; //{id}
export const UPDATE_USER_CHANGE_PASSWORD = AUTH_SERVICE + "/supplier/changepassword/sub/user";
export const UPDATE_USER_ACTIVATE = AUTH_SERVICE + "/supplier/activate/sub/user";
export const UPDATE_USER_DEACTIVATE = AUTH_SERVICE + "/supplier/activate/sub/user";
export const SUPPLIER_USER_ACTIVITY = SEARCH_SERVICE + "/supplier/fetch/user/"; //{userId}/stats

/** Order Request APIs */
export const CREATE_UPDATE_ORDER_REQUEST = ORDER_SERVICE + "/update/product/requirement/request";
export const SEARCH_ORDER_REQUEST_ADMIN = SEARCH_SERVICE + "/admin/search/product/requirement";
export const FETCH_ORDER_REQUEST_ADMIN = SEARCH_SERVICE + "/admin/fetch/product/requirement/"; //{requestId}
export const SEARCH_ORDER_REQUEST_SUPPLIER = SEARCH_SERVICE + "/supplier/search/product/requirement";
export const FETCH_ORDER_REQUEST_SUPPLIER = SEARCH_SERVICE + "/supplier/fetch/product/requirement/"; //{requestId}
export const REPLY_ORDER_REQUEST = ORDER_SERVICE + "/add/reply/product/requirement/request";
export const UPDATE_ORDER_REQUEST_REPLY_STATUS = ORDER_SERVICE + "/update/status/reply/product/requirement/request";
export const UPDATE_ORDER_REQUEST_STATUS = ORDER_SERVICE + "/update/status/product/requirement/request";
export const MESSAGE_ORDER_REQUEST = ORDER_SERVICE + "/add/message/reply/product/requirement/request";
export const FETCH_ORDER_REQUEST_REPLY = SEARCH_SERVICE + "/supplier/fetch/product/requirement/"; //{requestId}/{replyId}
export const ADD_ORDER_REQUEST_REPLY_MESSAGE = ORDER_SERVICE + "/add/message/reply/product/requirement/request";

export const SEARCH_SUPPLIER_SKUS = SEARCH_SERVICE + "/search/supplier/skus";

export const ORDER_REQUEST_PROPOSE_SKU = ORDER_SERVICE + "/add/sku/reply/product/requirement/request";
export const ORDER_REQUEST_CREATE_BARGAIN = ORDER_SERVICE + "/request/proposed/customoffer";
export const ORDER_REQUEST_ADD_SUPPLIER = ORDER_SERVICE + "/add/supplier/product/requirement/request";
export const ORDER_REQUEST_REMOVE_SUPPLIER = ORDER_SERVICE + "/remove/supplier/product/requirement/request";
export const SEARCH_MASTER_SKU = SEARCH_SERVICE + "/search/master/sku";
