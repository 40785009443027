import React from "react";
import { Container, Col, Row } from "reactstrap";

import HomeHeader from "../../components/Headers/HomeHeader";
import Footer from "../../views/Common/Footer";
import Privacy from "../../components/StaticPages/Privacy";

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.body.classList.add("colo");
  }

  render() {
    return (
      <div>
        <HomeHeader {...this.props} />
        <div className="full-page--content">
          <div className="not-found-page video">
            <Container>
              {" "}
              <Row>
                <Col sm={12} className="d-flex h-100">
                  <div className="col-sm-10 mx-auto">
                    <Privacy />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default NotFoundPage;
