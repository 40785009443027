import React from "react";
import { Button, Row, Col } from "reactstrap";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";
import pdfIcon from "../../assets/img/pdf.svg";
import TooltipItem from "../../components/Tooltip/Tooltip";
import { documentIdentifierNumberMapping } from "../../utils/constants";

class KycBlock extends React.Component {
  render() {
    const { type, content, header, updateKyc, userType, workflow } = this.props;

    let documentType = "";
    if (type === "BUSINESS_ADDRESS_INFO") {
      documentType = "BUSINESS_REGISTERED_ADDRESS";
    } else {
      documentType = type.replace(/_INFO/gi, "");
    }

    const data = content?.kycDetailsMap[documentType];

    if (data) {
      const logo = getMediaPath(data.fileId, data.fileExt);
      return (
        <React.Fragment>
          {header && data.status ? (
            <>
              <h5 className="text-capitalize">
                <span className={`status ${workflow?.currentState}`} id={"Tooltip-" + data.documentId}>
                  <i className="now-ui-icons ui-1_check" />
                </span>

                {documentType.replace(/_/g, " ").toLowerCase()}
              </h5>
              <TooltipItem placement="right" text={workflow?.currentState} id={data.documentId} />
            </>
          ) : null}

          {workflow?.currentState === "SUBMITTED" ||
          workflow?.currentState === "REJECTED" ||
          workflow?.currentState === "ON_HOLD" ? (
            <div className="admin-action p-2 mt-2" style={{ background: "#f5f5f5" }}>
              <div className="mt-0 mb-0 d-flex justify-content-between">
                <p className="mb-0 text-muted">Current Status</p>
                <p className="mb-0 mt-0">
                  <span className={`text-${workflow.currentState}`}>{workflow.currentState?.replace(/_/g, "")}</span>
                </p>
              </div>

              <div className="mt-1 mb-0 d-flex justify-content-between">
                <p className="mb-0 text-muted">Status Notes</p>
                <p className="mb-0 mt-0">
                  <span>{workflow.statusMessage}</span>
                </p>
              </div>
              {data.adminWorkflowMessage?.length ? (
                <div className="mt-2 mb-0">
                  <p className="mb-0 text-muted">Admin Notes</p>
                  <p className="mt-0 mb-0">{data.adminWorkflowMessage}</p>
                </div>
              ) : null}
            </div>
          ) : null}

          {updateKyc && userType && (userType === "ADMIN" || userType === "OPS") ? (
            <div className="mb-2 border-btm">
              <Button
                className="d-none"
                size="xs"
                color="primary"
                onClick={this.props.updateKyc({
                  actionType: "ADMIN_UPDATE",
                  documentId: data.documentId,
                  documentType: documentType,
                })}
              >
                Update
              </Button>
              <Button
                size="xs"
                className="mt-2 mb-2 btn-simple btn-light btn-success"
                onClick={this.props.updateKyc({
                  actionType: "ACCEPT",
                  documentId: data.documentId,
                  documentType: documentType,
                })}
              >
                Accept
              </Button>

              <Button
                size="xs"
                className="mt-2 mb-2 btn-simple btn-light btn-warning"
                onClick={this.props.updateKyc({
                  actionType: "ONHOLD",
                  documentId: data.documentId,
                  documentType: documentType,
                })}
              >
                OnHold
              </Button>

              <Button
                size="xs"
                className="mt-2 mb-2 btn-simple btn-light btn-danger"
                onClick={this.props.updateKyc({
                  actionType: "REJECT",
                  documentId: data.documentId,
                  documentType: documentType,
                })}
              >
                Reject
              </Button>
            </div>
          ) : null}

          <>
            <Row>
              <Col sm={userType === "SUPPLIER" ? 9 : 12}>
                {userType !== "SUPPLIER" ? (
                  <div className="mt-0 mb-0">
                    <p className="mb-0 text-muted">Current Status</p>
                    <p className="mt-0 mb-0">
                      <span className={`text-${data.status}`}>{`${data.status}`}</span>
                    </p>
                  </div>
                ) : null}

                {type !== "BOARD_RESOLUTION_INFO" &&
                type !== "BOARD_RESOLUTION" &&
                type !== "SIGNATURE_SAMPLE" &&
                type !== "BUSINESS_REGISTERED_ADDRESS" ? (
                  <div className="mt-2 mb-0">
                    <p className="mb-0 text-muted">{documentIdentifierNumberMapping[type]}</p>
                    <p className="mt-0 mb-0">{`${data.documentIdentitierNo}` || "N/A"}</p>
                  </div>
                ) : null}
              </Col>
              <Col sm={userType === "SUPPLIER" ? 3 : 12} style={{ minHeight: 120 }}>
                <p className="mt-2 mb-2 text-muted">Attached File</p>
                {data.fileExt === "pdf" ? (
                  <a href={logo} target="_blank" rel="noopener noreferrer">
                    <img src={pdfIcon} alt="alt" style={{ maxHeight: 100 }} />
                  </a>
                ) : (
                  <span style={{ border: "1px solid #eee", display: "inline-block", padding: 5 }}>
                    <ImageZoom src={logo} alt="kyc" style={{ maxHeight: 80 }} />
                  </span>
                )}
              </Col>
            </Row>

            {/* <div className="mt-2 mb-0">
              <p className="mb-0 text-muted">Document Type</p>
              <p className="mt-0 mb-0">{`${data.documentType}` || "N/A"}</p>
            </div> */}

            {data.documentAdditionalInfo?.length > 0 ? (
              <div className="mt-2 mb-0">
                <p className="mb-0 text-muted">
                  {type === "BUSINESS_REGISTERED_ADDRESS" ? "Registered Address" : "Notes"}
                </p>
                <p className="mt-0 mb-0">{`${data.documentAdditionalInfo}`}</p>
              </div>
            ) : null}

            {/* {data.notes?.length ? (
              <div className="mt-2 mb-0">
                <p className="mb-0 text-muted">Admin Notes</p>
                <p className="mt-0">{data.notes}</p>
              </div>
            ) : null} */}
          </>
        </React.Fragment>
      );
    } else {
      return <h4 className="align-middle text-center">Not Available</h4>;
    }
  }
}

export default KycBlock;
