import { startUILoading, stopUILoading } from "./uiActions";
import {
  CLEAR_CUSTOM_OFFER_LIST,
  SAVE_CUSTOM_OFFER_LIST,
  SAVE_CUSTOM_OFFER_DETAIL,
  CLEAR_CUSTOM_OFFER_DETAIL,
} from "./actionTypes";
import {
  FETCH_CUSTOM_OFFER_LIST,
  FETCH_CUSTOM_OFFER_DETAIL,
  UPDATE_CUSTOM_OFFER_DETAIL,
} from "../../utils/constants/apiUrls";
import { saveResponseMessage, clearMessage } from "./responseMessageActions";
import { getRequestHeaders } from "./authActions";
import axios from "axios";

/** Get list of all bargain requests */
export const getCustomOfferList = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());

    try {
      const config = await dispatch(getRequestHeaders());

      const { userType } = getState().auth;
      const postData = { ...reqParam };

      const URL = FETCH_CUSTOM_OFFER_LIST + userType.toLowerCase() + "/search/sku/customoffer";
      const response = await axios.post(URL, postData, config);

      console.log("Get CustomOffer List Success Executed", response.data);
      dispatch(saveCustomOfferList(response.data.data));
    } catch (error) {
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Get detail of a specific bargain request */
export const getCustomOfferDetail = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearCustomOfferDetail());

    try {
      const config = await dispatch(getRequestHeaders());

      const { userType } = getState().auth;
      const postData = { ...reqParam };

      const URL =
        FETCH_CUSTOM_OFFER_DETAIL +
        userType.toLowerCase() +
        "/fetch/sku/customoffer/" +
        reqParam.skuCustomOfferRequestId;

      const response = await axios.post(URL, postData, config);

      console.log("Get CustomOffer List Success Executed", response.data);
      dispatch(saveCustomOfferDetail(response.data.data));
    } catch (error) {
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Update bargain offer requested by customer */
export const updateCustomOffer = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage("sampleoffer"));
    try {
      const config = await dispatch(getRequestHeaders());

      const { userType } = getState().auth;
      const postData = { ...reqParam };

      const URL = UPDATE_CUSTOM_OFFER_DETAIL + userType.toLowerCase() + "/request/sku/customoffer";
      const response = await axios.post(URL, postData, config);
      dispatch(saveCustomOfferDetail(response.data?.data?.customOffer));
      dispatch(saveResponseMessage("bargain", { hasErrors: false }));
    } catch (error) {
      /** Save eror to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage("bargain", error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Below are the Redux actions to save and delete data from store*/
export const saveCustomOfferList = (data) => {
  return {
    type: SAVE_CUSTOM_OFFER_LIST,
    data,
  };
};

export const saveCustomOfferDetail = (data) => {
  return {
    type: SAVE_CUSTOM_OFFER_DETAIL,
    data: data,
  };
};
export const clearCustomOfferDetail = () => {
  return {
    type: CLEAR_CUSTOM_OFFER_DETAIL,
  };
};

export const clearCustomOfferInfo = (data) => {
  return {
    type: CLEAR_CUSTOM_OFFER_LIST,
    data,
  };
};
