import React from "react";
import { Row, Col, FormGroup, Card, CardBody, Button, Table, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { searchOrder } from "../../datautils/actions/orderActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
//import { sortIcon } from "../../utils/constants/icons";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import { PER_PAGE, orderFilters } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import { getUrlParams } from "../../utils/helpers/HelperFunctions";
import {
  ADMIN_ORDERS_LIST,
  ADMIN_ORDER_VIEW,
  ADMIN_PRODUCT_VIEW,
  ADMIN_SUPPLIERR_VIEW,
  ADMIN_SKU_VIEW,
  ADMIN_CUSTOMER_VIEW,
  SUPPLIER_ORDERS_LIST,
  SUPPLIER_ORDER_VIEW,
} from "../../utils/constants/clientUrls";
import { formatDateTime } from "../../utils/helpers/HelperFunctions";

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "21",
      currentFilter: "status",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "orderedOn",
      ascending: false,
      toggle: false,
    };
  }

  componentDidMount() {
    const params = getUrlParams(this.props.location.search);
    const currentTab = params["tab"] || this.state.currentTab;
    const pageNumber = params["page"] || this.state.pageNumber;

    this.setState(
      {
        isUpdated: false,
        pageNumber: pageNumber && parseInt(pageNumber),
        currentTab: currentTab?.toString(),
      },
      () => this.searchData()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchData();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.searchData()
      );
    }
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchData()
    );
  };

  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.searchData()
    );
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  searchData = () => {
    const { globalSearchKeyword, currentFilter, currentTab, pageNumber, pageSize, sortField, ascending } = this.state;

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [
        {
          key: currentFilter,
          operation: "EQUAL_TO",
          value: currentTab,
        },
      ],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.setState({ isUpdated: false });
    this.props.searchOrder(reqData);
  };

  handleChange = (input) => () => {
    this.props.userType === "SUPPLIER"
      ? this.props.history.push(SUPPLIER_ORDER_VIEW + input)
      : this.props.history.push(ADMIN_ORDER_VIEW + input);
  };

  changePage = (page) => (e) => {
    const fragment = "?tab=" + this.state.currentTab + "&page=" + page;
    this.props.userType === "SUPPLIER"
      ? this.props.history.push(SUPPLIER_ORDERS_LIST + fragment)
      : this.props.history.push(ADMIN_ORDERS_LIST + fragment);
    this.setState({ pageNumber: page });
  };

  filterData = (e) => {
    const fragment = "?tab=" + e.target.value;
    this.props.userType === "SUPPLIER"
      ? this.props.history.push(SUPPLIER_ORDERS_LIST + fragment)
      : this.props.history.push(ADMIN_ORDERS_LIST + fragment);

    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
        currentFilter: e.target.dataset.filter,
      },
      () => this.searchData()
    );
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.orderList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  render() {
    const { responseVal } = this.props;
    const {
      isUpdated,
      globalSearchKeyword,
      toggle,
      currentTab,
      currentFilter,
      // ascending,
      pageNumber,
      pageSize,
    } = this.state;

    const dataLoaded = responseVal.dObjectData?.orderList?.data?.orders;

    return (
      <div>
        <PanelHeader />
        <div className="content mt-0">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}

              {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="12">
                  {!toggle ? (
                    <ButtonToolbar>
                      {orderFilters.map((filter) => {
                        return (
                          <Button
                            style={{ textTransform: "capitalize" }}
                            size="xs"
                            key={`filter-${filter.value}`}
                            value={filter.value}
                            data-filter="status"
                            onClick={this.filterData}
                            color={currentTab === filter.value && currentFilter === "status" ? "primary" : ""}
                          >
                            {filter.label?.toLowerCase()}
                          </Button>
                        );
                      })}
                    </ButtonToolbar>
                  ) : null}
                </Col>
              </Row>
            </Col>

            <Col xs={12}>
              <React.Fragment>
                <Card>
                  <CardBody className="listings">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search Orders..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button color="primary" onClick={this.searchButton}>
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="8">{dataLoaded ? this.renderPagination() : null}</Col>
                    </Row>
                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>

                          <th>Line Item Id </th>
                          <th
                          // onClick={this.sortButton(
                          //   "customerOrderId",
                          //   ascending ? false : true
                          // )}
                          >
                            Order Id{" "}
                            {/* <img
                              alt="sort"
                              className="sort-icon"
                              src={sortIcon}
                            /> */}
                          </th>
                          <th>Order Ref No </th>
                          <th>Supplier Id </th>
                          <th>Product Id </th>
                          <th>Variant</th>

                          <th>Sku Id</th>
                          <th>Qty</th>
                          <th>Total Price</th>
                          <th>Customer Id</th>
                          <th>Order Type</th>
                          <th>Status</th>
                          <th>Order Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLoaded ? (
                          dataLoaded.length ? (
                            dataLoaded.map((row, index) => {
                              // const variantId =
                              //   row.productVariantId &&
                              //   row.productVariantId.split("_")[0];

                              const cname =
                                row.status === "ACTIVE"
                                  ? "success"
                                  : row.status === "INACTIVE"
                                  ? "danger"
                                  : row.status === "ONHOLD"
                                  ? "warning"
                                  : "primary";
                              return (
                                <tr key={index}>
                                  <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>
                                  <td style={{ cursor: "pointer" }} onClick={this.handleChange(row.orderLineItemId)}>
                                    {row.orderLineItemId}
                                  </td>
                                  <td>{row.customerOrderId}</td>
                                  <td>{row.orderRefNumber}</td>

                                  <td>
                                    {this.props.userType === "SUPPLIER" ? (
                                      row.supplierId
                                    ) : (
                                      <Link to={ADMIN_SUPPLIERR_VIEW + row.supplierId}>{row.supplierId}</Link>
                                    )}
                                  </td>
                                  <td>
                                    {this.props.userType === "SUPPLIER" ? (
                                      row.productId
                                    ) : (
                                      <Link
                                        to={
                                          ADMIN_PRODUCT_VIEW + row.productId + "/variant/" + row.productVariantPrimaryId
                                        }
                                      >
                                        {row.productId}
                                      </Link>
                                    )}
                                  </td>
                                  <td>{row.variantType + ":" + row.variantName}</td>

                                  <td>
                                    {this.props.userType === "SUPPLIER" ? (
                                      row.skuId
                                    ) : (
                                      <Link to={ADMIN_SKU_VIEW + row.skuId}>{row.skuId}</Link>
                                    )}
                                  </td>
                                  <td>{row.quantity}</td>
                                  <td>
                                    &#x20b9;
                                    {row.totalFinalOfferPrice && Math.round(row.totalFinalOfferPrice).toLocaleString()}
                                  </td>
                                  <td>
                                    {this.props.userType === "SUPPLIER" ? (
                                      row.customerId
                                    ) : (
                                      <Link to={ADMIN_CUSTOMER_VIEW + row.customerId}>{row.customerId}</Link>
                                    )}
                                  </td>
                                  <td>
                                    <span className="text-success" style={{ fontWeight: "bold" }}>
                                      {row.orderType?.replace(/_/g, " ")}
                                    </span>
                                  </td>
                                  <td>{row.status?.replace(/_/g, " ")}</td>
                                  <td>{formatDateTime(row.orderedOn)}</td>

                                  <td className="text-right">
                                    <Button
                                      className={`btn-simple btn-light btn-primary btn-${cname}`}
                                      size="xs"
                                      onClick={this.handleChange(row.orderLineItemId)}
                                    >
                                      View
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="15" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="15" />
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {this.props.isLoading ? <div className="loader" /> : null}
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    searchOrder: (reqData) => dispatch(searchOrder(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
