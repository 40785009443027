import React from "react";
import { Row, Col, Button, ButtonToolbar } from "reactstrap";

import { connect } from "react-redux";
import { addUpdateRequest, getRequestDetail, addUpdateRequestMedia } from "../../datautils/actions/adminActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import GoBack from "../../components/GoBack/GoBack";

import {
  resetResponseValues,
  resetObjectValues,
  removeMediaObject,
} from "../../datautils/actions/responseHandlerActions";
import { PER_PAGE } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import "../../assets/css/chat.css";
import ImageZoom from "../../components/ImageZoom";
import Dropzone from "../../components/Dropzone/Dropzone";
import { uploadImages } from "../../datautils/actions/skuActions";
class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "0",
      currentFilter: "requestedTicketStatus",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "id",
      ascending: false,
      toggle: false,

      id: 0,
      userComment: "",
      subject: "",
      details: "",
      approvalStatus: "",
      requestTicketStatus: "OPEN",
      messages: "",
      files: [],
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ isUpdated: false, id });
    this.props.getRequestDetail({ id });
  }

  componentDidUpdate(prevProps, prevState) {
    const { responseVal } = this.props;

    if (
      responseVal.dObjectData.requestDetail &&
      responseVal.dObjectData.requestDetail.data &&
      prevState.lastUpdated !== responseVal.dObjectData.requestDetail.data.updatedOn
    ) {
      const data = responseVal.dObjectData.requestDetail.data;

      this.setState({
        id: data.requestId,
        userComment: data.userComment,
        subject: data.subject,
        details: data.details,
        approvalStatus: data.approvalStatus,
        requestTicketStatus: data.requestTicketStatus,
        messages: data.messages,
        lastUpdated: data.updatedOn,
        files: [],
      });
    }
  }

  onDrop = (acceptedFiles) => {
    this.setState({ files: [...this.state.files, ...acceptedFiles] });

    acceptedFiles.map((acceptedFile) => {
      return this.props.uploadImages(acceptedFile);
    });
  };

  removeFromState = (row) => (e) => {
    let filteredArray = this.state.files.filter((element) => element.name !== row.name);
    this.setState({ files: [...filteredArray] });
    this.props.removeMediaObject(row.name);
  };

  saveMedia = () => {
    const { responseVal } = this.props;
    if (responseVal.mediaObject && responseVal.mediaObject.length > 0) {
      this.setState({ isUpdated: true });

      let mediasToPost = {};

      responseVal.mediaObject.forEach((obj) => {
        mediasToPost[obj[Object.keys(obj)]] = {
          fileId: obj[Object.keys(obj)],
        };
      });

      // responseVal.media.forEach(fileId => {
      //   mediasToPost[fileId] = { fileId: fileId };
      // });

      const reqData = {
        requestQueueVO: {
          requestId: this.state.id,
          medias: mediasToPost,
        },
      };
      this.props.addUpdateRequestMedia(reqData);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  markRequestTicketStatus = (requestTicketStatus) => (e) => {
    const { id, userComment, subject, details, approvalStatus } = this.state;
    this.setState({ isUpdated: true });

    const reqParam = {
      requestId: id,
      userComment,
      subject,
      details,
      approvalStatus,
      requestTicketStatus,
    };

    this.props.addUpdateRequest({ requestQueueVO: reqParam });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.addUpdateRequest(e);
    }
  };

  addUpdateRequest = (e) => {
    e.preventDefault();
    const { id, userComment, subject, details, approvalStatus, requestTicketStatus } = this.state;

    const reqParam = {
      requestId: id,
      userComment: { message: userComment },
      subject,
      details,
      approvalStatus,
      requestTicketStatus,
    };

    this.setState({ isUpdated: true });
    this.props.addUpdateRequest({ requestQueueVO: reqParam });
  };

  render() {
    const {
      responseVal,
      authData: { userId },
    } = this.props;
    const { isUpdated, userComment, requestTicketStatus, messages, files } = this.state;

    const dataLoaded = responseVal.dObjectData.requestDetail?.data;

    return (
      <div>
        <PanelHeader />
        <div className="content mt-3">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}

              {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}
            </Col>

            <Col xs={12}>
              {dataLoaded ? (
                <React.Fragment>
                  <Row>
                    <Col xs="7">
                      <Row>
                        <Col xs="4">
                          <GoBack text="Ticket" />
                        </Col>
                        <Col xs="8">
                          <ButtonToolbar className="tool-right">
                            {requestTicketStatus === "CLOSED" || requestTicketStatus === "ONHOLD" ? (
                              <Button
                                size="sm"
                                className="btn-danger mt-0 pull-right"
                                onClick={this.markRequestTicketStatus("OPEN")}
                              >
                                OPEN TICKET
                              </Button>
                            ) : null}
                            {requestTicketStatus === "OPEN" || requestTicketStatus === "ONHOLD" ? (
                              <Button
                                size="sm"
                                className="btn-success mt-0 pull-right"
                                onClick={this.markRequestTicketStatus("CLOSED")}
                              >
                                CLOSE TICKET
                              </Button>
                            ) : null}
                          </ButtonToolbar>
                        </Col>
                      </Row>
                      <div className="chat-area">
                        <div id="frame">
                          <div className="content">
                            <div className="contact-profile">
                              <dl className="row">
                                <dt className="col-sm-3">Ticket ID</dt>
                                <dd className="col-sm-9">{dataLoaded.requestId}</dd>
                                <dt className="col-sm-3">Ticket Status</dt>
                                <dd className="col-sm-9">{dataLoaded.requestTicketStatus}</dd>

                                {/* <dt className="col-sm-3">Approval Status</dt>
                                <dd className="col-sm-9">
                                  {dataLoaded.approvalStatus}
                                </dd> */}

                                <dt className="col-sm-3">Ticket Type</dt>
                                <dd className="col-sm-9">{dataLoaded.requestType}</dd>

                                <dt className="col-sm-3">Subject</dt>
                                <dd className="col-sm-9">{dataLoaded.subject}</dd>

                                <dt className="col-sm-3">Description</dt>
                                <dd className="col-sm-9">{dataLoaded.details}</dd>
                              </dl>
                            </div>
                            <div className="messages">
                              <ul>
                                {messages &&
                                  messages.map((message, index) => {
                                    return (
                                      <li
                                        className={userId === message.userId ? "replies" : "sent"}
                                        key={"mess-" + index}
                                      >
                                        {/* <img
                                          src={`https://via.placeholder.com/30/000000/FFFFFF?text=${message.userType}`}
                                          alt={message.userType}
                                        /> */}
                                        <p>{message.message}</p>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            <div className="message-input">
                              <div className="wrap">
                                <input
                                  type="text"
                                  name="userComment"
                                  placeholder="Write your message..."
                                  value={userComment || ""}
                                  onChange={this.handleChange}
                                  onKeyDown={this.handleKeyDown}
                                />

                                <button className="submit" onClick={this.addUpdateRequest} disabled={!userComment}>
                                  <i className="now-ui-icons ui-1_send" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs="5">
                      <h5>Attach new files with the ticket</h5>
                      <Dropzone onFilesAdded={(opt) => this.onDrop(opt)} />
                      {files ? (
                        <>
                          <Row className="mt-2 equal">
                            {files.length > 0 ? (
                              <>
                                {files.map((acceptedFile) => (
                                  <Col sm="6" className="" key={acceptedFile.name}>
                                    <img
                                      alt={acceptedFile.name}
                                      src={URL.createObjectURL(acceptedFile)}
                                      className="img-thumbnail rounded"
                                    />
                                    <p className="text-center">{acceptedFile.name}</p>
                                    <button
                                      onClick={this.removeFromState(acceptedFile)}
                                      type="button"
                                      className="close img-remove"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </Col>
                                ))}
                              </>
                            ) : null}
                          </Row>

                          {files.length > 0 ? (
                            <Button size="md" color="primary" onClick={this.saveMedia}>
                              Upload All Images
                            </Button>
                          ) : null}
                        </>
                      ) : null}

                      {dataLoaded.medias ? (
                        <Row className="mt-4 equal">
                          <Col md="12">
                            <h5>Attached files with the ticket</h5>
                          </Col>
                          {Object.keys(dataLoaded.medias).map((index) => {
                            return (
                              <Col md="6" key={index}>
                                <div className="bg-white p-2 mb-3">
                                  <ImageZoom
                                    src={getMediaPath(
                                      dataLoaded.medias[index].fileId,
                                      dataLoaded.medias[index].fileExt
                                    )}
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      ) : null}
                    </Col>
                  </Row>
                </React.Fragment>
              ) : null}
            </Col>
            {this.props.isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    authData: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    resetObjectValues: (object) => dispatch(resetObjectValues(object)),
    addUpdateRequest: (reqData) => dispatch(addUpdateRequest(reqData)),
    getRequestDetail: (reqData) => dispatch(getRequestDetail(reqData)),
    uploadImages: (acceptedFile) => dispatch(uploadImages(acceptedFile)),
    addUpdateRequestMedia: (reqParam) => dispatch(addUpdateRequestMedia(reqParam)),
    removeMediaObject: (reqParam) => dispatch(removeMediaObject(reqParam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Request);
