import Dashboard from "../../views/Admin/Dashboard";
import Password from "../../views/Supplier/Password";
import Suppliers from "../../views/Admin/SuppliersList";
import SuppliersProfile from "../../views/Admin/SuppliersProfile";
import SkuList from "../../views/Supplier/SkuList";
import UpdateSku from "../../views/Supplier/UpdateSku";
import ProductList from "../../views/Admin/ProductList";
import UpdateProduct from "../../views/Admin/UpdateProduct";
import Categories from "../../views/Admin/Categories";
import Campaigns from "../../views/Admin/CampaignList";
import CampaignDetail from "../../views/Admin/CampaignDetail";
import Campaign from "../../views/Admin/Campaign";
import Coupons from "../../views/Admin/CouponList";
import Coupon from "../../views/Admin/Coupon";
import Brands from "../../views/Admin/BrandList";
import Requests from "../../views/Admin/RequestList";
import Request from "../../views/Admin/Request";
import Orders from "../../views/Admin/OrderList";
import Order from "../../views/Admin/Order";
import Customers from "../../views/Admin/CustomerList";
import Customer from "../../views/Admin/Customer";
import Supplier from "../../views/Admin/Supplier";
import AttributeList from "../../views/Admin/AttributeList";
import TrendList from "../../views/Admin/TrendList";
import Trend from "../../views/Admin/Trend";
import Menu from "../../views/Admin/Menu";
import SampleList from "../../views/Supplier/SampleList";
import Sample from "../../views/Supplier/Sample";
import CustomOfferList from "../../views/Supplier/CustomOfferList";
import CustomOffer from "../../views/Supplier/CustomOffer";
import BillBookList from "../../views/Supplier/BillBookList";
import BillBookDetail from "../../views/Supplier/BillBookDetail";
import AdminList from "../../views/Admin/AdminList";
import OpsUser from "../../views/Admin/OpsUser";
import UserActivity from "../../views/Admin/UserActivity";
import LedgerUsers from "../../views/Admin/LedgerUsers";
import OrderRequestList from "../../views/Supplier/OrderRequestList";
import OrderRequest from "../../views/Supplier/OrderRequest";
import TempSkuList from "../../views/Admin/TempSkusList";
import TempSku from "../../views/Admin/TempSku";

import { ADMIN_BASE } from "../../utils/constants/clientUrls.js";

let adminRoutes = [
  {
    path: ADMIN_BASE,
    name: "Dashboard",
    icon: "design_app",
    component: Dashboard,
    componentName: "adminDashboard",
  },
  {
    path: ADMIN_BASE + "/password",
    name: "Password",
    icon: "design_app",
    component: Password,
    componentName: "adminPassword",
  },
  {
    path: ADMIN_BASE + "/suppliers",
    name: "Suppliers List",
    icon: "design_app",
    component: Suppliers,
    componentName: "adminSuppliers",
  },
  {
    path: ADMIN_BASE + "/suppliers/:id",
    name: "Supplier",
    icon: "design_app",
    component: SuppliersProfile,
    componentName: "adminSuppliersProfile",
  },
  {
    path: ADMIN_BASE + "/supplier/:id",
    name: "Supplier",
    icon: "design_app",
    component: Supplier,
    componentName: "adminSuppliersProfile",
  },
  {
    path: ADMIN_BASE + "/skus",
    name: "Sku List",
    icon: "design_app",
    component: SkuList,
    componentName: "adminSkuList",
  },
  {
    path: ADMIN_BASE + "/sku/:id",
    name: "Sku Details",
    icon: "design_app",
    component: UpdateSku,
    componentName: "adminUpdateSku",
  },
  {
    path: ADMIN_BASE + "/products",
    name: "Products List",
    icon: "design_app",
    component: ProductList,
    componentName: "adminProductList",
  },
  {
    path: ADMIN_BASE + "/product/:id",
    name: "Product Details",
    icon: "design_app",
    component: UpdateProduct,
    componentName: "adminUpdateProduct",
  },
  {
    path: ADMIN_BASE + "/product/:id/variant/:vid",
    name: "Product Details",
    icon: "design_app",
    component: UpdateProduct,
    componentName: "adminUpdateProduct",
  },
  {
    path: ADMIN_BASE + "/categories",
    name: "Categories",
    icon: "design_app",
    component: Categories,
    componentName: "adminCategories",
  },
  {
    path: ADMIN_BASE + "/campaigns",
    name: "Campaigns",
    icon: "design_app",
    component: Campaigns,
    componentName: "adminCampaigns",
  },
  {
    path: ADMIN_BASE + "/campaign/:id",
    name: "Campaign Details",
    icon: "design_app",
    component: CampaignDetail,
    componentName: "adminCampaigns",
  },
  {
    path: ADMIN_BASE + "/campaign/:id/:code",
    name: "Campaign Mapping",
    icon: "design_app",
    component: Campaign,
    componentName: "adminCampaigns",
  },
  {
    path: ADMIN_BASE + "/coupons",
    name: "Coupons",
    icon: "design_app",
    component: Coupons,
    componentName: "adminCoupons",
  },
  {
    path: ADMIN_BASE + "/coupon/:id/:code",
    name: "Coupon Details",
    icon: "design_app",
    component: Coupon,
    componentName: "adminCoupons",
  },
  {
    path: ADMIN_BASE + "/brands",
    name: "Brands",
    icon: "design_app",
    component: Brands,
    componentName: "adminBrands",
  },
  {
    path: ADMIN_BASE + "/requests",
    name: "Tickets",
    icon: "design_app",
    component: Requests,
    componentName: "adminRequests",
  },
  {
    path: ADMIN_BASE + "/request/:id",
    name: "Ticket",
    icon: "design_app",
    component: Request,
    componentName: "adminRequests",
  },
  {
    path: ADMIN_BASE + "/orders",
    name: "Orders",
    icon: "design_app",
    component: Orders,
    componentName: "adminOrders",
  },
  {
    path: ADMIN_BASE + "/order/:id",
    name: "Order",
    icon: "design_app",
    component: Order,
    componentName: "adminOrders",
  },
  {
    path: ADMIN_BASE + "/customers",
    name: "Customers",
    icon: "design_app",
    component: Customers,
    componentName: "adminCustomers",
  },
  {
    path: ADMIN_BASE + "/customer/:id",
    name: "Customer",
    icon: "design_app",
    component: Customer,
    componentName: "adminCustomers",
  },
  {
    path: ADMIN_BASE + "/attributes",
    name: "Attributes",
    icon: "design_app",
    component: AttributeList,
    componentName: "adminCustomers",
  },
  {
    path: ADMIN_BASE + "/trends",
    name: "Trends",
    icon: "design_app",
    component: TrendList,
    componentName: "adminTrending",
  },
  {
    path: ADMIN_BASE + "/trend",
    name: "Trend",
    icon: "design_app",
    component: Trend,
    componentName: "adminTrending",
  },
  {
    path: ADMIN_BASE + "/trend/:id",
    name: "Trend",
    icon: "design_app",
    component: Trend,
    componentName: "adminTrending",
  },
  {
    path: ADMIN_BASE + "/menu",
    name: "Mega Menu",
    icon: "design_app",
    component: Menu,
    componentName: "adminMenu",
  },
  {
    path: ADMIN_BASE + "/menu/:platform",
    name: "Mega Menu",
    icon: "design_app",
    component: Menu,
    componentName: "adminMenu",
  },
  {
    path: ADMIN_BASE + "/samples",
    name: "Sample Requests",
    icon: "design_app",
    component: SampleList,
    componentName: "adminSamples",
  },
  {
    path: ADMIN_BASE + "/sample/:id",
    name: "Sample Request",
    icon: "design_app",
    component: Sample,
    componentName: "adminSamples",
  },
  {
    path: ADMIN_BASE + "/customoffers",
    name: "Bargain Requests",
    icon: "design_app",
    component: CustomOfferList,
    componentName: "adminCustomOffers",
  },
  {
    path: ADMIN_BASE + "/customoffer/:id",
    name: "Bargain Request",
    icon: "design_app",
    component: CustomOffer,
    componentName: "adminCustomOffers",
  },
  {
    path: ADMIN_BASE + "/billbook",
    name: "Bill Book",
    icon: "design_app",
    component: BillBookList,
    componentName: "adminBillBook",
  },
  {
    path: ADMIN_BASE + "/billbook/:id",
    name: "Bill Detail",
    icon: "design_app",
    component: BillBookDetail,
    componentName: "adminBillBook",
  },
  {
    path: ADMIN_BASE + "/users",
    name: "User List",
    icon: "design_app",
    component: AdminList,
    componentName: "adminList",
  },
  {
    path: ADMIN_BASE + "/users/:id",
    name: "User Detail",
    icon: "design_app",
    component: OpsUser,
    componentName: "adminList",
  },
  {
    path: ADMIN_BASE + "/ldusers",
    name: "LD User List",
    icon: "design_app",
    component: LedgerUsers,
    componentName: "adminList",
  },
  {
    path: ADMIN_BASE + "/activity/:id",
    name: "User Activity",
    icon: "design_app",
    component: UserActivity,
    componentName: "adminList",
  },
  {
    path: ADMIN_BASE + "/order-requests",
    name: "Order Requirements",
    icon: "design_app",
    component: OrderRequestList,
    componentName: "adminOrderRequestList",
  },
  {
    path: ADMIN_BASE + "/order-request/:id",
    name: "Order Requirement",
    icon: "design_app",
    component: OrderRequest,
    componentName: "adminOrderRequestList",
  },
  {
    path: ADMIN_BASE + "/temp-skus",
    name: "Temp Skus",
    icon: "design_app",
    component: TempSkuList,
    componentName: "adminTempSkuList",
  },
  {
    path: ADMIN_BASE + "/temp-sku/:id",
    name: "Temp sku",
    icon: "design_app",
    component: TempSku,
    componentName: "adminTempSkuList",
  },
];
export default adminRoutes;
