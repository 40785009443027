import { startUILoading, stopUILoading } from "./uiActions";
import { resetResponseValues, setResponseValues } from "./responseHandlerActions";

import axios from "axios";
import { GET_CITIES_API_URL, GET_STATES_API_URL } from "../../utils/constants/apiUrls";

import { FAILURE, SUCCESS } from "../../utils/constants";
import { SAVE_CITIES, SAVE_STATES } from "./actionTypes";

const getRequestHeadersJson = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

export const getStates = () => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    const config = getRequestHeadersJson();
    try {
      const response = await axios.post(GET_STATES_API_URL, {}, config);
      console.log("Get States Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null));
      dispatch(storeStateInfo(response.data));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const getCities = (stateId) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    const config = getRequestHeadersJson();
    const URL = GET_CITIES_API_URL + stateId;
    try {
      const response = await axios.post(URL, {}, config);
      console.log("Get Cities Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null));
      dispatch(storeCityInfo(response.data));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const storeStateInfo = (data) => {
  return {
    type: SAVE_STATES,
    data: data.data,
  };
};

export const storeCityInfo = (data) => {
  return {
    type: SAVE_CITIES,
    data: data.data,
  };
};
