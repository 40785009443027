import { SAVE_HOME_STATS, SAVE_COUNT_STATS, SAVE_USER_ACTIVITY } from "../actions/actionTypes";

const initialState = {
  graphStats: null,
  countStats: null,
  userActivity: null,
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_HOME_STATS:
      return {
        ...state,
        graphStats: {
          ...state.graphStats,
          [action.statsCategory]: action.data,
        },
      };

    case SAVE_COUNT_STATS:
      return {
        ...state,
        countStats: action.data,
      };

    case SAVE_USER_ACTIVITY:
      return {
        ...state,
        userActivity: action.data,
      };

    default:
      return state;
  }
};

export default statsReducer;
