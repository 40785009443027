import React from "react";
import { CardBody, Card } from "reactstrap";

class Statistics extends React.Component {
  render() {
    const { title, subtitle } = this.props;
    return (
      <Card className="card-stats">
        <CardBody>
          <div className="row">
            <div className="col">
              <h5 className="card-title mb-0">{title}</h5>
              <span className="h2 font-weight-bold mb-0">{subtitle}</span>
            </div>
            {/* <div className="col-auto">
              <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                <i className="fas fa-chart-bar" />
              </div>
            </div> */}
          </div>
          {/* <p className="mt-3 mb-0 text-muted text-sm">
             <span className="text-success mr-2">
              <i className="fa fa-arrow-up" /> 3.48%
            </span> 
            <span className="text-nowrap">{duration} &nbsp;</span>
          </p> */}
        </CardBody>
      </Card>
    );
  }
}
export default Statistics;
