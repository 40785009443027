import React from "react";
import { Row, Col, FormGroup, Card, CardBody, Button, Table, ButtonToolbar } from "reactstrap";

import { connect } from "react-redux";
import { getSuppliersList } from "../../datautils/actions/adminActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";

import { ADMIN_SUPPLIER_VIEW, ADMIN_ACTIVITY_VIEW } from "../../utils/constants/clientUrls";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { sortIcon } from "../../utils/constants/icons";
import { PER_PAGE } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";

class Suppliers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "0",
      currentFilter: "verified",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "businessName",
      ascending: false,
    };
  }
  componentDidMount() {
    this.searchSupplier();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchSupplier();
    }
  }

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchSupplier()
    );
  };

  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.searchSupplier()
    );
  };
  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.searchSupplier()
      );
    }
  };

  searchSupplier = () => {
    const { globalSearchKeyword, currentFilter, currentTab, pageNumber, pageSize, sortField, ascending } = this.state;

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [
        {
          key: currentFilter,
          operation: "EQUAL_TO",
          value: currentTab,
        },
      ],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.props.getSuppliersList(reqData);
  };

  handleChange = (input) => (e) => {
    this.props.history.push(ADMIN_SUPPLIER_VIEW + input);
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  filterSupplier = (e) => {
    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
        currentFilter: e.target.dataset.filter,
      },
      () => this.searchSupplier()
    );
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.supplierList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  render() {
    const { responseVal, isLoading } = this.props;
    const { globalSearchKeyword, currentTab, currentFilter, pageNumber, pageSize, ascending } = this.state;

    const dataLoaded = responseVal.dObjectData?.supplierList?.data?.suppliers;

    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}
            </Col>
            <Col xs={12}>
              <React.Fragment>
                <ButtonToolbar>
                  {" "}
                  <Button
                    size="sm"
                    value="1"
                    data-filter="status"
                    onClick={this.filterSupplier}
                    color={currentTab === "1" && currentFilter === "status" ? "primary" : ""}
                  >
                    ACTIVE
                  </Button>
                  <Button
                    size="sm"
                    value="0"
                    data-filter="status"
                    onClick={this.filterSupplier}
                    color={currentTab === "0" && currentFilter === "status" ? "primary" : ""}
                  >
                    INACTIVE
                  </Button>
                  <Button
                    size="sm"
                    value="1"
                    data-filter="verified"
                    onClick={this.filterSupplier}
                    color={currentTab === "1" && currentFilter === "verified" ? "primary" : ""}
                  >
                    VERIFIED
                  </Button>
                  <Button
                    size="sm"
                    value="0"
                    data-filter="verified"
                    onClick={this.filterSupplier}
                    color={currentTab === "0" && currentFilter === "verified" ? "primary" : ""}
                  >
                    NOT VERIFIED
                  </Button>
                  <Button
                    size="sm"
                    value=""
                    data-filter="status"
                    onClick={this.filterSupplier}
                    color={currentTab === "" && currentFilter === "status" ? "primary" : ""}
                  >
                    ALL
                  </Button>
                </ButtonToolbar>
                <Card>
                  <CardBody className="listings">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search Suppliers..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button color="primary" onClick={this.searchButton}>
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="8">{dataLoaded ? this.renderPagination() : ""}</Col>
                    </Row>
                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>

                          <th onClick={this.sortButton("businessName", ascending ? false : true)}>
                            Business Name <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>
                          <th onClick={this.sortButton("businessType", ascending ? false : true)}>
                            Business Type <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>
                          <th onClick={this.sortButton("mobileNumber", ascending ? false : true)}>
                            Mobile <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>
                          <th>Status</th>
                          <th>Verified</th>
                          <th>Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLoaded ? (
                          dataLoaded.length ? (
                            dataLoaded.map((business, index) => {
                              const cname =
                                business.status === "ACTIVE"
                                  ? "success"
                                  : business.status === "INACTIVE"
                                  ? "danger"
                                  : business.status === "ONHOLD"
                                  ? "warning"
                                  : "primary";
                              return (
                                <tr key={index} className="cursor">
                                  <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>
                                  <td>{business.businessName}</td>
                                  <td>{business.businessType}</td>
                                  <td>{business.mobileCountryCode + " " + business.mobileNumber}</td>
                                  <td>{business.status}</td>
                                  <td>
                                    {business.verified ? (
                                      <span className="text-success">YES</span>
                                    ) : (
                                      <span className="text-danger">NO</span>
                                    )}
                                  </td>
                                  <td>{business.businessDescription}</td>
                                  <td className="text-right">
                                    <Button
                                      className={`btn-simple btn-light btn-${cname}`}
                                      size="xs"
                                      onClick={this.handleChange(business.supplierUserId)}
                                    >
                                      View Details
                                    </Button>{" "}
                                    <Button
                                      className={`btn-simple btn-light btn-${cname}`}
                                      size="xs"
                                      onClick={() =>
                                        this.props.history.push(ADMIN_ACTIVITY_VIEW + business.supplierUserId)
                                      }
                                    >
                                      View Activity
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="11" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="11" />
                          </tr>
                        )}
                      </tbody>
                    </Table>{" "}
                    {isLoading ? <div className="loader" /> : null}
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSuppliersList: (reqData) => dispatch(getSuppliersList(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
