import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import { connect } from "react-redux";
import {
  fetchUserActivity,
  saveUserActivity,
} from "../../datautils/actions/statsActions";
import { PER_PAGE } from "../../utils/constants/index";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import Activities from "../../components/Activities";

class UserActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const reqData = {
      userId: id,
      paginationOptions: {
        pageNumber: 1,
        pageSize: PER_PAGE,
        sortOptions: [{ sortField: "createdOn", ascending: false }],
      },
    };
    console.log("reqData", reqData);
    this.props.fetchUserActivity({ ...reqData });
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this.props.saveUserActivity(null);
  }

  fetchActivity = (p) => {
    const reqData = {
      userId: this.state.userId,
      paginationOptions: {
        pageNumber: p,
        pageSize: PER_PAGE,
        sortOptions: [{ sortField: "createdOn", ascending: false }],
      },
    };
    this.props.fetchUserActivity({ ...reqData });
  };

  render() {
    const { responseVal, isLoading, userActivity } = this.props;

    return (
      <div>
        <PanelHeader />

        <div className="content mt-3">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}
            </Col>

            <Col xs={12}>
              <MessageBlock screenName="customer" />
            </Col>

            <Col xs={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-0 pt-0">
                  <li className="breadcrumb-item">
                    <span onClick={() => this.props.history.goBack()}>
                      &laquo; Go Back
                    </span>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    User Activity
                  </li>
                </ol>
              </nav>
              <Row>
                <Col sm={12}>
                  <Card>
                    <CardBody className="listings">
                      <h5 className="float-left">User Activities</h5>

                      {userActivity ? (
                        <Activities
                          userActivities={userActivity}
                          fetchActivity={(p) => this.fetchActivity(p)}
                        />
                      ) : null}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    responseVal: state.resHandlerVal,
    isLoading: state.ui.isLoading,
    userActivity: state.statsItems.userActivity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserActivity: (reqData) => dispatch(fetchUserActivity(reqData)),
    saveUserActivity: (reqData) => dispatch(saveUserActivity(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActivity);
