import React from "react";
import { Alert } from "reactstrap";

class ErrorBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  componentDidMount() {
    /*setTimeout(() => {
      this.setState({ visible: false });
    }, 5000);*/
  }

  onDismiss = () => {
    this.setState({ visible: false });
  };

  render() {
    const { errors, position } = this.props;

    return errors && errors.length > 0 ? (
      <Alert color="danger" isOpen={this.state.visible} toggle={this.onDismiss} className={position ? position : ""}>
        <React.Fragment>
          {errors.map((error, index) => {
            let eStringMessage = "Something went wrong, Try again";
            if (error.userDisplayMsg) {
              eStringMessage = error.userDisplayMsg;
            } else if (error.detailMessage) {
              eStringMessage = error.detailMessage;
            } else if (error.message) {
              eStringMessage = error.message;
            }
            return (
              <div key={"outer-extreme-" + index}>
                {error.fvErrorMap && Object.keys(error.fvErrorMap).length > 0 ? (
                  Object.keys(error.fvErrorMap).map((key) => {
                    return <div key={"fvErrorMap-" + key}>{error.fvErrorMap[key].message}</div>;
                  })
                ) : (
                  <div key={"outer-" + index}>{eStringMessage}</div>
                )}

                {/* {error.fvError && error.fvError.length > 0
                    ? error.fvError.map((ierror, index) => {
                        return (
                          <div key={"inner-" + index}>{ierror.message}</div>
                        );
                      })
                    : null} */}
              </div>
            );
          })}
        </React.Fragment>
      </Alert>
    ) : null;
  }
}

export default ErrorBlock;
