import React from "react";
import {
  Card,
  CardBody,
  Label,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  sellerDicountTypes,
  inventoryTypes,
  deliveryChargeTypes,
  sampleFulfillmentTypes,
  sampleStatusTypes,
  colorOptions,
  bargainOptions,
  orderTypes,
  stockActions,
} from "../../utils/constants";
import {
  getCategories,
  getSkuForm,
  getBrandList,
  addUpdateSku,
  uploadImages,
  saveImages,
  deleteImages,
  fetchSku,
  markAdminSku,
  markAdminSkuMedia,
  updateSkuPrice,
  updateSkuInventory,
  activeDeactiveSku,
  updateSkuSample,
  fetchSimilarSku,
  uploadAllImages,
} from "../../datautils/actions/skuActions";
import {
  resetResponseValues,
  clearMediaList,
  removeMediaObject,
  clearMediaObject,
} from "../../datautils/actions/responseHandlerActions";
import SkuForm from "./SkuForm";
import Dropzone from "../../components/Dropzone/Dropzone";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import uniqid from "uniqid";
import Select from "react-select";
import {
  SUPPLIER_SKU_ADD,
  SUPPLIER_SKU_VIEW,
  ADMIN_SKU_VIEW,
  ADMIN_PRODUCT_VIEW,
  ADMIN_SUPPLIER_VIEW,
} from "../../utils/constants/clientUrls";
import CropperModal from "../../components/ImageCropper/CropperModal";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";
import GoBack from "../../components/GoBack/GoBack";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { validTextColour } from "../../utils/helpers/HelperFunctions";

import TooltipItem from "../../components/Tooltip/Tooltip";
import helpIcon from "../../assets/img/question-mark.svg";

class UpdateSku extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      modal: false,
      modal2: false,
      currentTitle: "",
      currentAction: "",
      currentActionType: "",
      notes: "",
      files: {},
      deleteMedia: [],
      selectedFiles: [],
      selectedFiles2: [],
      copyMediaFiles: [],
      isAuthorized: false,
      isUpdated: false,
      activeTab: "1",
      formFetched: false,
      skuCreated: false,
      formData: {},
      skuSampleInfo: {},
      guidelines: false,
    };
  }

  cloneSku = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        skuId: "",
        variant: "",
        qcStatus: "",
        skuDetailWorkFlowStatus: "",
        skuMediaWorkFlowStatus: "",
        skuDetailsUploadedFinal: false,
        skuMediaUploadedFinal: false,
        medias: [],
        //mrp: "",
        // salePrice: "",
        // finalSellerPrice: "",
        // saleDiscount: "",
        totalQuantity: "",
        updatedQuantityDiff: "",
        quantityAvailable: "",
      },
    });
  };

  formatDate = (date) => {
    const now = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const first = now.substr(0, now.length - 3);
    const last = now.substr(-3);
    return first + last.replace(":", "");
  };
  handleChangeStartDate = (date) => {
    this.setState({
      ...this.state,
      skuSampleInfo: {
        ...this.state.skuSampleInfo,
        samplePromotionStartDate: date,
      },
    });
  };

  handleChangeEndDate = (date) => {
    this.setState({
      ...this.state,
      skuSampleInfo: {
        ...this.state.skuSampleInfo,
        samplePromotionEndDate: date,
      },
    });
  };

  removeFromState = (key) => {
    const tempObj = { ...this.state.files };
    delete tempObj[key];
    this.setState({ ...this.state, files: tempObj });
  };

  enableDisable = (e) => {
    const { formData } = this.state;
    if (formData.mrp < 1 || formData.salePrice < 1 || formData.totalQuantity < 1) {
      alert("Update Price and Inventory to use this action");
      return false;
    } else {
      const reqData = {
        sku: { skuId: this.state.formData.skuId },
        actionType: e.target.value,
      };
      this.props.activeDeactiveSku(reqData);
    }
  };

  toggle = () => {
    const media = this.state.modal ? [] : this.state.deleteMedia;
    this.setState((prevState) => ({
      modal: !prevState.modal,
      deleteMedia: media,
      isUpdated: false,
    }));
    this.props.resetResponseValues();
  };

  toggle2 = (requestData) => {
    //this.props.resetResponseValues();
    if (this.state.modal2) {
      this.setState({
        modal2: false,
        notes: "",
        currentTitle: "",
        currentAction: "",
        currentActionType: "",
      });
    } else {
      this.setState({
        modal2: true,
        notes: "",
        currentTitle: requestData.title,
        currentAction: requestData.action,
        currentActionType: requestData.actionType,
        isUpdated: false,
      });
      this.props.resetResponseValues();
    }
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  handleCheckBox = (e) => {
    e.persist();

    const checked = e.target.dataset.fileid;

    if (e.target.checked) {
      if (e.target.name === "copyMediaFiles") {
        this.setState({
          copyMediaFiles: [...this.state.copyMediaFiles, checked],
        });
      } else {
        this.setState({ [e.target.name]: [...this.state[e.target.name], checked] });
      }
    } else {
      if (e.target.name === "copyMediaFiles") {
        this.setState((prevState) => ({
          copyMediaFiles: prevState.copyMediaFiles.filter((file) => file !== checked),
        }));
      } else {
        this.setState((prevState) => ({
          [e.target.name]: prevState[[e.target.name]].filter((file) => file !== checked),
        }));
      }
    }
  };

  handleCheckedFinal = (e) => {
    if (e.target.checked) {
      this.setState({
        formData: { ...this.state.formData, [e.target.name]: true },
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, [e.target.name]: false },
      });
    }
  };

  deleteHandler = (e) => {
    const fileId = e.target.value;
    this.setState({
      deleteMedia: [...this.state.deleteMedia, fileId],
      modal: true,
      isUpdated: false,
    });
  };

  copyHandler = () => {
    const { copyMediaFiles, formData } = this.state;
    if (copyMediaFiles && copyMediaFiles.length > 0) {
      const mediaIds = [];
      copyMediaFiles.map((fileId) => {
        return mediaIds.push({ fileId: fileId });
      });
      const reqData = {
        sku: {
          skuId: formData.skuId,
          skuMediaUploadedFinal: false,
          medias: mediaIds,
        },
        supplierUserId: formData.supplierUserId,
      };
      this.props.saveImages(reqData);
    }
  };

  deleteNow = () => {
    if (this.state.deleteMedia && this.state.deleteMedia.length > 0) {
      const skuId = this.state.formData.skuId;
      const mediaIds = [];
      this.state.deleteMedia.map((fileId) => {
        return mediaIds.push({ fileId: fileId });
      });

      if (mediaIds.length > 0) {
        const sku = { skuId: skuId, medias: mediaIds };
        this.props.deleteImages(sku);
      }
    }
  };

  handleChecked = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  componentDidMount() {
    this.props.resetResponseValues();
    const { id } = this.props.match.params;

    if (id > 0) {
      this.setState({ id });
      this.props.fetchSku({ skuId: id });
      this.props.fetchSimilarSku({ skuId: id });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { responseVal } = this.props;
    const { isUpdated, modal, modal2, activeTab } = this.state;
    const { id } = this.props.match.params;

    const skuData = responseVal.dObjectData?.skuForm?.data?.sku;

    if (skuData) {
      const pDimensions = skuData.packagingDimensions?.toLowerCase().split("x");
      const packagingDimensionsL = pDimensions?.[0];
      const packagingDimensionsB = pDimensions?.[1];
      const packagingDimensionsH = pDimensions?.[2];

      if (prevState.lastUpdated !== skuData.updatedOn || prevState.id !== skuData.skuId) {
        let today = new Date();
        let future = new Date();
        future.setFullYear(future.getFullYear() + 1);

        let start = skuData.skuSampleInfo?.samplePromotionStartDate
          ? new Date(skuData.skuSampleInfo.samplePromotionStartDate)
          : today;

        let end = skuData.skuSampleInfo?.samplePromotionEndDate
          ? new Date(skuData.skuSampleInfo.samplePromotionEndDate)
          : future;

        const selectedColorString =
          skuData?.primaryCatFields &&
          Object.keys(skuData.primaryCatFields).map((x) => {
            const obj = skuData.primaryCatFields[x];
            return x === "COLOUR" && obj.value;
          });

        const selectedColorObj = colorOptions.find((c) => {
          return c.value === selectedColorString?.[0];
        });

        const selectedColor = selectedColorObj?.color;
        const totalMediaCount = skuData.medias?.length;

        this.setState({
          id: skuData.skuId,
          modal: false,
          modal2: false,
          formFetched: true,
          formData: {
            ...skuData,
            packagingDimensionsL,
            packagingDimensionsB,
            packagingDimensionsH,
            stockAction: "add",
            selectedColor,
          },
          lastUpdated: skuData.updatedOn,
          selectedFiles: [],
          selectedFiles2: [],
          copyMediaFiles: [],
          files: [],
          skuSampleInfo: {
            ...skuData.skuSampleInfo,
            samplePromotionStartDate: start,
            samplePromotionEndDate: end,
          },
          activeTab: totalMediaCount ? activeTab : "2",
        });
        this.props.clearMediaList();
        this.props.clearMediaObject();
      }
    }

    if (prevProps.match.params.id !== id) {
      this.props.resetResponseValues();
      this.props.clearMediaList();
      this.props.clearMediaObject();

      this.setState(
        {
          formFetched: false,
          isUpdated: false,
          files: [],
          selectedFiles: [],
          selectedFiles2: [],
          copyMediaFiles: [],
        },
        () => {
          this.props.fetchSku({ skuId: id });
          this.props.fetchSimilarSku({ skuId: id });
        }
      );
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }

    if (responseVal.status === "success" && isUpdated && (modal || modal2)) {
      this.setState({ modal: false, modal2: false });
    }
  }

  handleChangeNotes = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleChangeSelectPrimaryColor = (obj, opt) => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        primaryCatFields: {
          ...prevState.formData.primaryCatFields,
          [obj]: {
            ...prevState.formData.primaryCatFields[obj],
            value: opt.value,
          },
        },
        selectedColor: opt.color,
        colorOption: opt.value,
      },
    }));
  };

  handleChangeSelect = (obj, opt) => {
    let otherObj = {};
    if (obj === "variantType" && (opt.value === "NONE" || opt.value === "NA")) {
      otherObj = { variant: "NA" };
    }

    if (opt.type === "attribute") {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          attributes: {
            ...prevState.formData.attributes,
            [obj]: {
              ...prevState.formData.attributes[obj],
              value: opt.value,
            },
          },
        },
      }));
    } else if (opt.type === "primaryCatFields") {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          primaryCatFields: {
            ...prevState.formData.primaryCatFields,
            [obj]: {
              ...prevState.formData.primaryCatFields[obj],
              value: opt.value,
            },
          },
        },
      }));
    } else if (opt.type === "skuSampleInfo") {
      this.setState((prevState) => ({
        ...prevState,
        skuSampleInfo: {
          ...prevState.skuSampleInfo,
          [obj]: opt.value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        formData: { ...prevState.formData, [obj]: opt.value, ...otherObj },
      }));
    }
  };

  handleChange = (e) => {
    e.persist();

    let eTargetValue = e.target.value;

    const roundedArray = [
      "totalQuantity",
      "quantityBlocked",
      "quantityPerLot",
      "maxQuantityOrder",
      "deliveryCharge",
      "sampleMaxOrderQuantity",
      "sampleMinOrderQuantity",
      "sampleTotalQuantity",
      "sampleDeliveryCharge",
      "updatedQuantityDiff",
    ];

    const absArray = ["saleDiscount", "mrp", "salePrice"];

    if (roundedArray.includes(e.target.name)) {
      eTargetValue = Math.abs(Math.round(parseInt(eTargetValue)));
    }

    if (absArray.includes(e.target.name)) {
      eTargetValue = Math.abs(eTargetValue);
    }

    if (e.target.dataset?.object === "attributes") {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          attributes: {
            ...prevState.formData.attributes,
            [e.target.name]: {
              ...prevState.formData.attributes[e.target.name],
              value: eTargetValue,
            },
          },
        },
      }));
    } else if (e.target.dataset?.object === "primaryCatFields") {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          primaryCatFields: {
            ...prevState.formData.primaryCatFields,
            [e.target.name]: {
              ...prevState.formData.primaryCatFields[e.target.name],
              value: e.target.value,
            },
          },
        },
      }));
    } else if (e.target.dataset?.object === "skuSampleInfo") {
      this.setState((prevState) => ({
        ...prevState,
        skuSampleInfo: {
          ...prevState.skuSampleInfo,
          [e.target.name]: eTargetValue,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        formData: { ...prevState.formData, [e.target.name]: eTargetValue },
      }));
    }
  };

  addSku = (e) => {
    e.preventDefault();

    const { formData } = this.state;
    const packagingDimensions = `
      ${formData.packagingDimensionsL}x${formData.packagingDimensionsB}x${formData.packagingDimensionsH}`;

    const reqData = {
      sku: {
        ...formData,
        packagingDimensions,
        variant: formData.variantType === "NONE" ? "NA" : formData.variant,
      },
    };

    this.props.addUpdateSku(reqData);
  };

  onDrop = (acceptedFiles) => {
    const newObj = {};
    acceptedFiles.forEach((file) => {
      newObj[uniqid()] = { original: file, base64: null, cropped: null, uploaded: null };
    });

    this.setState({ ...this.state, show: true, files: { ...this.state.files, ...newObj } });

    // this.setState({ files: [...this.state.files, ...acceptedFiles] });
    // acceptedFiles.map((acceptedFile) => {
    //   return this.props.uploadImages(acceptedFile);
    // });
  };

  markCompleted = (e) => {
    const reqData = {
      sku: {
        ...this.state.formData,
        [e.target.name]: true,
      },
      completedType: e.target.name,
    };
    this.props.addUpdateSku(reqData);
  };

  /** Update SKU (Admin Action) */
  updateAdminSku = (actionType) => (e) => {
    e.preventDefault();

    const { formData, notes, modal2 } = this.state;
    const authData = {
      skuId: formData.skuId,
      supplierUserId: formData.supplierUserId,
      actionType: actionType,
      notes,
      sku: formData,
    };

    if (modal2) {
      this.props.markAdminSku(authData);
    } else {
      this.toggle2({
        actionType: actionType,
        action: "updateSku",
        title: "Sku Action",
      });
    }
  };

  /** Update SKU Media (Admin Action Only) */
  updateAdminSkuMedia = (actionType) => (e) => {
    e.preventDefault();
    const { selectedFiles, selectedFiles2, formData, modal2, notes } = this.state;

    if (modal2) {
      const toSendActionType = actionType === "ACCEPT" ? "ACCEPTED" : "REJECTED";
      const mediaIds = [];
      if (actionType === "ACCEPT") {
        selectedFiles2.forEach((fileId) => {
          mediaIds.push({ fileId, status: toSendActionType });
        });
      } else {
        selectedFiles.forEach((fileId) => {
          mediaIds.push({ fileId: fileId, status: toSendActionType });
        });
      }

      let reqData = {
        actionType: actionType,
        supplierUserId: formData.supplierUserId,
        sku: {
          skuId: formData.skuId,
          medias: mediaIds,
        },
        notes,
      };

      if (e.target.name === "overallAcceptMedia") {
        reqData = { ...reqData, status: "ACCEPTED" };
      }

      this.props.markAdminSkuMedia(reqData);
    } else {
      this.toggle2({
        actionType: actionType,
        action: "updateSkuMedia",
        title: "Sku Media Action",
      });
    }
  };

  /** Update SKU Sample Offer Options */
  updateSample = () => {
    const { skuSampleInfo, formData } = this.state;

    const reqData = {
      sku: {
        skuId: formData.skuId,
        skuSampleInfo: { skuId: formData.skuId, ...skuSampleInfo },
      },
      supplierUserId: formData.supplierUserId,
    };
    this.props.updateSkuSample(reqData);
  };

  /** Update SKU Price */
  updatePrice = () => {
    const reqData = {
      sku: this.state.formData,
      supplierUserId: this.state.formData.supplierUserId,
    };
    this.props.updateSkuPrice(reqData);
  };

  /** Update SKU Inventory */
  updateInventory = () => {
    const { formData } = this.state;

    const updatedQuantityDiff =
      formData.stockAction === "add" ? formData.updatedQuantityDiff : -Math.abs(formData.updatedQuantityDiff);

    const reqData = {
      sku: { ...formData, updatedQuantityDiff },
      supplierUserId: formData.supplierUserId,
    };
    this.props.updateSkuInventory(reqData);
  };

  setShow = (show) => {
    this.setState({ ...this.state, show: !show });
  };

  setFiles = (files) => {
    this.setState({ ...this.state, files: { ...files } });
  };

  /** Save uploaded media to SKU */
  saveMediaWithSKU = (mediaList) => {
    const medias = [];

    mediaList?.forEach((fileId) => {
      medias.push({ fileId });
    });

    console.log("ss", medias);

    if (medias?.length > 0) {
      const reqData = {
        sku: {
          skuId: this.state.formData.skuId,
          skuMediaUploadedFinal: false,
          medias,
        },
        supplierUserId: this.state.formData.supplierUserId,
      };
      this.props.saveImages(reqData);
    }
  };

  /** Upload cropped image to server and attach with SKU */
  uploadCroppedImage = async () => {
    try {
      const fileIds = await this.props.uploadAllImages(this.state.files);
      this.saveMediaWithSKU(fileIds);
    } catch (error) {
      console.log("uploadCroppedImage", error);
    }
  };

  renderModal = () => {
    return (
      <Modal
        centered
        backdrop="static"
        isOpen={this.state.modal}
        fade={false}
        toggle={this.toggle}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggle}>Delete Image</ModalHeader>
        <ModalBody style={{ minHeight: 100 }}>
          Are you sure you want to delete? You will not be able to recover this file.
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.deleteNow}>
            Yes, Delete
          </Button>{" "}
          <Button color="secondary" onClick={this.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  renderModal2 = () => {
    const { notes, currentAction, currentActionType, currentTitle } = this.state;

    return (
      <Modal backdrop="static" centered isOpen={this.state.modal2} toggle={this.toggle2}>
        <ModalHeader toggle={this.toggle2}>{currentTitle.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    <Form autoComplete="off">
                      <FormGroup>
                        <Input
                          type="textarea"
                          placeholder="Add notes/reason"
                          onChange={this.handleChangeNotes("notes")}
                          value={notes}
                        />
                      </FormGroup>
                    </Form>
                    {this.props.userType === "SUPPLIER" ? null : (
                      <React.Fragment>{this.renderSwitch(currentActionType, currentAction)}</React.Fragment>
                    )}
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  renderSwitch(currentActionType, action) {
    if (action === "updateSku") {
      switch (currentActionType) {
        case "ACCEPT":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateAdminSku("ACCEPT")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Accept
            </Button>
          );

        case "ONHOLD":
          return (
            <Button
              className="btn-block btn-warning"
              onClick={this.updateAdminSku("ONHOLD")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              OnHold
            </Button>
          );
        case "REJECT":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateAdminSku("REJECT")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Reject
            </Button>
          );
        case "DEACTIVATE":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateAdminSku("DEACTIVATE")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Delist
            </Button>
          );
        case "ACTIVATE":
          return (
            <Button
              color="success"
              className="btn-block"
              onClick={this.updateAdminSku("ACTIVATE")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Activate
            </Button>
          );
        default:
          return null;
      }
    }

    if (action === "updateSkuMedia") {
      switch (currentActionType) {
        case "ACCEPT":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateAdminSkuMedia("ACCEPT")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Accept
            </Button>
          );

        case "REJECT":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateAdminSkuMedia("REJECT")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Reject
            </Button>
          );
        default:
          return null;
      }
    }
  }

  render() {
    const {
      formData,
      modal,
      modal2,
      activeTab,
      files,
      selectedFiles,
      selectedFiles2,
      copyMediaFiles,
      id,
      skuSampleInfo,
      guidelines,
      show,
    } = this.state;

    const { userType, isLoading, responseVal } = this.props;
    const notesMessages = formData?.qcMessage ? JSON.parse(formData.qcMessage) : "";
    const totalMediaCount = formData.medias?.length;

    const acceptedSkuImages = formData.medias?.filter((media) => media.status === "ACCEPTED");
    const rejectedSkuImages = formData.medias?.filter((media) => media.status === "REJECTED");
    const pendingSkuImages = formData.medias?.filter((media) => media.status === "SUBMITTED");
    const similarVariantSkus = responseVal.dObjectData?.similarSkus?.data?.similarVariantSkus;

    let ovLabel = "";
    formData &&
      formData.primaryCatFields &&
      Object.keys(formData.primaryCatFields).map((o) => {
        const k = formData.primaryCatFields[o];
        return (ovLabel = ovLabel + k.name + ": " + k.value + ", ");
      });

    const totalFiles = Object.keys(files).length;
    const totalCropped = Object.keys(files).filter((key) => files[key].cropped);

    return (
      <div>
        <PanelHeader />
        {modal ? this.renderModal() : null}
        {modal2 ? this.renderModal2() : null}
        {show && (
          <CropperModal
            show={show}
            setShow={(show) => this.setShow(show)}
            files={files}
            setFiles={(files) => this.setFiles(files)}
            aspect={4 / 5}
          />
        )}
        {id > 0 ? (
          <div className="content mt-3 ">
            <Row className="equal">
              <Col xs={12}>
                <MessageBlock screenName="sku" />
              </Col>

              <Col sm={12}>
                {formData?.supplierUserId ? (
                  <React.Fragment>
                    <Row>
                      <div className="col-sm-8">
                        <Row>
                          <Col sm={8}>
                            <GoBack text={formData.title} />
                          </Col>
                          <Col sm={4}>
                            {userType === "OPS" || userType === "ADMIN" ? (
                              <div className="text-right">
                                {formData.productMasterId && formData.productVariantMasterId ? (
                                  <>
                                    <Link
                                      to={
                                        ADMIN_PRODUCT_VIEW +
                                        formData.productMasterId +
                                        "/variant/" +
                                        formData.productVariantMasterId
                                      }
                                    >
                                      View Product
                                    </Link>
                                    <span
                                      style={{
                                        marginRight: 10,
                                        marginLeft: 10,
                                      }}
                                    >
                                      |
                                    </span>
                                  </>
                                ) : null}

                                <Link to={ADMIN_SUPPLIER_VIEW + formData.supplierUserId}>View Supplier</Link>
                              </div>
                            ) : null}
                          </Col>
                          <Col sm={12}>
                            {formData.qcStatus === "APPROVED" && formData.skuId ? (
                              <React.Fragment>
                                {formData.status === "ACTIVE" ? (
                                  <Row>
                                    {userType === "OPS" || userType === "ADMIN" ? (
                                      <Col sm={12} className="text-right">
                                        <Button
                                          size="xs"
                                          value="DEACTIVATE"
                                          className="btn-simple btn-success mt-0 float-right"
                                          onClick={this.updateAdminSku("DEACTIVATE")}
                                        >
                                          Delist SKU
                                        </Button>

                                        <small style={{ marginRight: 10 }}>
                                          If you want your product to be removed from the APP Click Delist SKU
                                        </small>
                                      </Col>
                                    ) : (
                                      <Col sm={12} className="text-right">
                                        <Button
                                          size="xs"
                                          value="DEACTIVATE"
                                          className="btn-simple btn-success mt-0 float-right"
                                          onClick={this.enableDisable}
                                        >
                                          Deactivate SKU
                                        </Button>

                                        <small style={{ marginRight: 10 }}>
                                          If you want your product to be removed from the APP Click Deactivate SKU
                                        </small>
                                      </Col>
                                    )}
                                  </Row>
                                ) : (
                                  <Row>
                                    {userType === "OPS" || userType === "ADMIN" ? (
                                      <Col sm={12} className="text-right">
                                        <Button
                                          size="xs"
                                          value="ACTIVATE"
                                          className="btn-simple btn-danger mt-0 float-right"
                                          onClick={this.updateAdminSku("ACTIVATE")}
                                        >
                                          Activate SKU
                                        </Button>

                                        <small style={{ marginRight: 10 }}>
                                          If you want your product to be displayed on the APP Click Activate button
                                        </small>
                                      </Col>
                                    ) : (
                                      <Col sm={12} className="text-right">
                                        <Button
                                          size="xs"
                                          value="ACTIVATE"
                                          className="btn-simple btn-danger mt-0 float-right"
                                          onClick={this.enableDisable}
                                        >
                                          Activate SKU
                                        </Button>

                                        <small style={{ marginRight: 10 }}>
                                          If you want your product to be displayed on the APP Click Activate button
                                        </small>
                                      </Col>
                                    )}
                                  </Row>
                                )}{" "}
                              </React.Fragment>
                            ) : null}
                          </Col>
                          <Col md="12">
                            <div className={!guidelines ? "guidelines closed" : "guidelines opened"}>
                              <p style={{ float: "left" }} onClick={() => this.setState({ guidelines: !guidelines })}>
                                <span>{!guidelines ? "View" : "Hide"} SKU Guidelines / Steps</span>{" "}
                                <i
                                  className={
                                    !guidelines
                                      ? "now-ui-icons arrows-1_minimal-down"
                                      : "now-ui-icons arrows-1_minimal-up"
                                  }
                                />
                              </p>
                              <p className="float-right">
                                <span className="float-right">
                                  {formData.productVariantStatus ? (
                                    <span className="text-success">Product Variant Published</span>
                                  ) : (
                                    <span className="text-danger">Product Variant Not Published</span>
                                  )}
                                </span>
                              </p>
                              <div className="clearfix"></div>
                              <ol>
                                <li>1) Fill valid data for all mandatory fields.</li>
                                <li>2) Mark details as completed.</li>
                                <li>3) Upload the good quality product images.</li>
                                <li>4) After uploading all the product images, mark image section as completed.</li>
                                <li>5) Sku will be moved to QC Inprogress state, wait for admin to approve the sku.</li>
                                <li>
                                  6) If there is any mistake, sku will be rejected. Make changes to details and resubmit
                                  for approval.
                                </li>
                                <li>
                                  7) Once approved, fill inventory detail, price details and sample pricing details if
                                  applicable.
                                </li>
                                <li>8) Make sure pricing details are right.</li>
                                <li>
                                  9) Publish the sku and wait for 6 hour. If everything goes well, product will be
                                  published on app.
                                </li>
                                <li>
                                  10) If there is any problem publishing the sku on app, Open help tickets or contact
                                  admin.
                                </li>
                              </ol>
                            </div>

                            {formData.skuId && totalMediaCount === 0 ? (
                              <SuccessBlock
                                msg={{
                                  userDisplayMsg: "Upload images and complete your SKU Data",
                                }}
                                color="danger"
                                position="alert-relative"
                              />
                            ) : null}

                            {formData.qcStatus === "INPROGRESS" && userType === "SUPPLIER" ? (
                              <SuccessBlock
                                msg={{
                                  userDisplayMsg: "SKU can't be updated when QC in Process",
                                }}
                                color="danger"
                                position="alert-relative"
                              />
                            ) : null}

                            {formData.qcStatus === "APPROVED" &&
                            (formData.mrp < 1 || formData.salePrice < 1 || formData.totalQuantity < 1) ? (
                              <SuccessBlock
                                msg={{
                                  userDisplayMsg: "SKU Price or Inventory is not updated",
                                }}
                                color="danger"
                                position="alert-relative"
                              />
                            ) : null}
                          </Col>
                          {formData.skuId ? (
                            <Col md={12}>
                              <h5 className={`qcStatus ${formData.qcStatus}`}>
                                {formData.qcStatus === "APPROVED" ? (
                                  <i className="now-ui-icons ui-1_check" />
                                ) : formData.qcStatus === "INACTIVE" ? (
                                  <i className="now-ui-icons tech_watch-time" />
                                ) : formData.qcStatus === "SUBMITTED" ? (
                                  <i className="now-ui-icons tech_watch-time" />
                                ) : formData.qcStatus === "ONHOLD" ? (
                                  <i className="now-ui-icons ui-1_lock-circle-open" />
                                ) : (
                                  <i className="now-ui-icons ui-1_lock-circle-open" />
                                )}
                                {/* <span className="text-neutral">
                                  Overall SKU Status:
                                </span>{" "} */}
                                {notesMessages?.message}
                              </h5>
                            </Col>
                          ) : null}
                        </Row>
                      </div>
                      <div className="col-sm-4 text-right">
                        {userType === "SUPPLIER" ? (
                          <React.Fragment>
                            <Button
                              size="sm"
                              className="btn-simple btn-primary mt-0"
                              onClick={() => (window.location.href = SUPPLIER_SKU_ADD)}
                            >
                              Create New SKU
                            </Button>
                          </React.Fragment>
                        ) : null}
                        {userType === "SUPPLIER" ? (
                          <React.Fragment>
                            <Button size="sm" className="btn-simple btn-primary mt-0" onClick={this.cloneSku}>
                              Clone this SKU
                            </Button>
                          </React.Fragment>
                        ) : null}
                      </div>
                    </Row>
                    <Row>
                      <Col sm="8">
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={`${this.state.activeTab === "1" ? "active" : ""}`}
                              onClick={() => {
                                this.toggleTab("1");
                              }}
                            >
                              SKU INFO
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`${this.state.activeTab === "2" ? "active" : ""}`}
                              onClick={() => {
                                this.toggleTab("2");
                              }}
                            >
                              SKU IMAGES
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`${this.state.activeTab === "3" ? "active" : ""}`}
                              onClick={() => {
                                this.toggleTab("3");
                              }}
                            >
                              SKU PRICE &amp; INVENTORY
                            </NavLink>
                          </NavItem>

                          <NavItem>
                            <NavLink
                              className={`${this.state.activeTab === "4" ? "active" : ""}`}
                              onClick={() => {
                                this.toggleTab("4");
                              }}
                            >
                              SKU SAMPLE PIECE
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1" className="sku-form-box">
                            <Row>
                              {userType === "OPS" || userType === "ADMIN" ? (
                                <Col>
                                  <div className="pb-0 px-3" style={{ overflow: "hidden" }}>
                                    <div className="float-right">
                                      <Button
                                        size="sm"
                                        className="btn-simple btn-success mt-0"
                                        onClick={this.updateAdminSku("ACCEPT")}
                                      >
                                        Accept
                                      </Button>

                                      <Button
                                        size="sm"
                                        className="btn-simple btn-warning mt-0"
                                        onClick={this.updateAdminSku("ONHOLD")}
                                      >
                                        OnHold
                                      </Button>

                                      <Button
                                        size="sm"
                                        className="btn-simple btn-danger mt-0"
                                        onClick={this.updateAdminSku("REJECT")}
                                      >
                                        Reject
                                      </Button>
                                    </div>
                                  </div>
                                </Col>
                              ) : null}
                              <Col sm="12">
                                <SkuForm
                                  form={formData}
                                  handleChange={this.handleChange}
                                  addSku={this.addSku}
                                  handleChangeSelect={this.handleChangeSelect}
                                  handleChangeSelectPrimaryColor={this.handleChangeSelectPrimaryColor}
                                  handleCheckedFinal={this.handleCheckedFinal}
                                  markCompleted={this.markCompleted}
                                  userType={userType}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2" className="sku-form-box">
                            <Row>
                              {formData && formData.skuId ? (
                                <Col sm="12">
                                  <h5 className={`mx-3 qcStatus ${formData.skuMediaWorkFlowStatus}`}>
                                    {formData.skuMediaWorkFlowStatus === "COMPLETE" ? (
                                      <i className="now-ui-icons ui-1_check" />
                                    ) : formData.skuMediaWorkFlowStatus === "SUBMITTED" ||
                                      formData.skuMediaWorkFlowStatus === "PROCESSING" ||
                                      formData.skuMediaWorkFlowStatus === "ON_HOLD" ? (
                                      <i className="now-ui-icons tech_watch-time" />
                                    ) : formData.skuMediaWorkFlowStatus === "ONHOLD" ? (
                                      <i className="now-ui-icons ui-1_lock-circle-open" />
                                    ) : (
                                      <i className="now-ui-icons ui-1_lock-circle-open" />
                                    )}

                                    {notesMessages?.sectionMessage?.SKU_MEDIA ? (
                                      <>
                                        <p className="pt-0 pb-0 m-0">
                                          {notesMessages.sectionMessage.SKU_MEDIA.message}
                                        </p>
                                        <p className="pt-0 pb-0 m-0">{notesMessages.sectionMessage.SKU_MEDIA.notes}</p>
                                      </>
                                    ) : null}
                                  </h5>
                                </Col>
                              ) : null}
                              <Col sm="12">
                                <div className="product-images pt-0">
                                  {formData && formData.skuId ? (
                                    <Row className="equal">
                                      {userType === "OPS" || userType === "ADMIN" ? (
                                        <Col>
                                          <div className="pt-0 pr-0" style={{ overflow: "hidden" }}>
                                            <div className="d-flex flex-row-reverse">
                                              {acceptedSkuImages && acceptedSkuImages.length > 0 ? (
                                                <>
                                                  <Button
                                                    size="sm"
                                                    name="overallAcceptMedia"
                                                    className="btn-simple btn-success mt-0"
                                                    onClick={this.updateAdminSkuMedia("ACCEPT")}
                                                  >
                                                    Mark Completed
                                                  </Button>
                                                  <p className="mr-2">
                                                    <small>
                                                      If Image Section work has been completed, Notify by clicking Mark
                                                      Completed
                                                    </small>
                                                  </p>
                                                </>
                                              ) : (
                                                <p>
                                                  <small className="text-danger">
                                                    *Accept or Reject SKU images below
                                                  </small>{" "}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                      ) : null}

                                      <Col sm={12}>
                                        {userType === "SUPPLIER" &&
                                        formData?.medias?.length > 0 &&
                                        !formData.skuMediaUploadedFinal ? (
                                          <div className="d-flex align-items-center justify-content-end py-3 pl-4">
                                            <Label check className="mr-2">
                                              <Input
                                                checked={formData.skuMediaFinal}
                                                className="mt-1"
                                                type="checkbox"
                                                name="skuMediaFinal"
                                                onChange={this.handleCheckedFinal}
                                              />
                                              I have completed uploading medias
                                            </Label>

                                            <Button
                                              size="sm"
                                              name="skuMediaUploadedFinal"
                                              className="btn-danger my-0"
                                              onClick={this.markCompleted}
                                              disabled={!formData.skuMediaFinal}
                                            >
                                              Mark as Complete
                                            </Button>
                                          </div>
                                        ) : null}
                                      </Col>

                                      <Col sm={12}>
                                        {formData.qcStatus === "INPROGRESS" && userType === "SUPPLIER" ? null : (
                                          <Dropzone
                                            style={{ minHeight: 100 }}
                                            onFilesAdded={(opt) => this.onDrop(opt)}
                                          />
                                        )}
                                      </Col>
                                    </Row>
                                  ) : (
                                    <div className="text-center mt-5 col">
                                      <h5>Your SKU INFO is not Complete</h5>
                                      <p>Please add all sku info to upload images </p>
                                    </div>
                                  )}

                                  {formData.medias?.length > 0 ? (
                                    <React.Fragment>
                                      <Row className="p-2">
                                        <div className="p-2">
                                          {Object.keys(files).map((key) => {
                                            const file = files[key];
                                            return file.cropped ? (
                                              <div key={`cropped-${key}`}>
                                                <ImageZoom
                                                  src={file.cropped}
                                                  alt={file.cropped}
                                                  className="img-thumbnail rounded"
                                                  style={{ maxHeight: 100 }}
                                                />
                                              </div>
                                            ) : null;
                                          })}
                                        </div>
                                      </Row>

                                      {Object.keys(files).length > 0 ? (
                                        <Button size="sm" color="light" onClick={() => this.setShow(false)}>
                                          Edit Images
                                        </Button>
                                      ) : null}

                                      {totalFiles > 0 && totalFiles === totalCropped?.length ? (
                                        <Button size={"sm"} color="success" onClick={() => this.uploadCroppedImage()}>
                                          Save Images
                                        </Button>
                                      ) : null}

                                      <div className="image-box mt-2">
                                        <h5 className="card-header d-flex justify-content-between">
                                          Accepted Images{" "}
                                          {(userType === "OPS" || userType === "ADMIN") &&
                                            acceptedSkuImages?.length > 0 && (
                                              <Button
                                                size="sm"
                                                className="btn-simple btn-danger my-0"
                                                onClick={this.updateAdminSkuMedia("REJECT")}
                                                disabled={selectedFiles?.length > 0 ? false : true}
                                              >
                                                Reject
                                              </Button>
                                            )}
                                        </h5>
                                        <div className="card-body">
                                          {acceptedSkuImages?.length > 0 ? (
                                            <Row>
                                              {acceptedSkuImages.map((file) => {
                                                return (
                                                  <Col sm="2" className="" key={file.fileId}>
                                                    <ImageZoom
                                                      src={getMediaPath(file.fileId, file.fileExt)}
                                                      alt={file.name}
                                                      className="img-thumbnail rounded"
                                                    />
                                                    {userType === "SUPPLIER" ? (
                                                      <Button
                                                        color="danger"
                                                        value={file.fileId}
                                                        size="xs"
                                                        onClick={this.deleteHandler}
                                                        disabled={formData.qcStatus === "INPROGRESS"}
                                                      >
                                                        Delete
                                                      </Button>
                                                    ) : (
                                                      <FormGroup check>
                                                        <Label check>
                                                          <Input
                                                            checked={selectedFiles.includes(file.fileId)}
                                                            type="checkbox"
                                                            name="selectedFiles"
                                                            data-fileid={file.fileId}
                                                            onChange={this.handleCheckBox}
                                                          />
                                                          <span className="form-check-sign" />
                                                          Select
                                                        </Label>
                                                      </FormGroup>
                                                    )}
                                                  </Col>
                                                );
                                              })}
                                            </Row>
                                          ) : (
                                            <small className="ml-2">No Image has been Accepted</small>
                                          )}
                                        </div>
                                      </div>

                                      <div className="image-box mt-2">
                                        <h5 className="card-header d-flex justify-content-between">
                                          <span>Pending Images</span>
                                          {(userType === "OPS" || userType === "ADMIN") &&
                                            pendingSkuImages?.length > 0 && (
                                              <Button
                                                size="sm"
                                                className="btn-simple btn-success my-0"
                                                onClick={this.updateAdminSkuMedia("ACCEPT")}
                                                disabled={selectedFiles2?.length > 0 ? false : true}
                                              >
                                                Accept
                                              </Button>
                                            )}
                                        </h5>
                                        <div className="card-body">
                                          {pendingSkuImages?.length > 0 ? (
                                            <Row>
                                              {pendingSkuImages.map((file) => {
                                                return (
                                                  <Col sm="2" key={file.fileId}>
                                                    <ImageZoom
                                                      src={getMediaPath(file.fileId, file.fileExt)}
                                                      alt={file.name}
                                                      className="img-thumbnail rounded"
                                                    />

                                                    {userType === "SUPPLIER" ? (
                                                      <Button
                                                        color="danger"
                                                        value={file.fileId}
                                                        size="xs"
                                                        onClick={this.deleteHandler}
                                                        disabled={formData.qcStatus === "INPROGRESS"}
                                                      >
                                                        Delete
                                                      </Button>
                                                    ) : (
                                                      <FormGroup check>
                                                        <Label check>
                                                          <Input
                                                            checked={selectedFiles2.includes(file.fileId)}
                                                            type="checkbox"
                                                            name="selectedFiles2"
                                                            data-fileid={file.fileId}
                                                            onChange={this.handleCheckBox}
                                                          />
                                                          <span className="form-check-sign" />
                                                          Select
                                                        </Label>
                                                      </FormGroup>
                                                    )}
                                                  </Col>
                                                );
                                              })}
                                            </Row>
                                          ) : (
                                            <small className="ml-2">No Image is Pending</small>
                                          )}
                                        </div>
                                      </div>

                                      <div className="image-box mt-2">
                                        <h5 className="card-header d-flex justify-content-between">
                                          <span>Rejected Images</span>
                                          {(userType === "OPS" || userType === "ADMIN") &&
                                            rejectedSkuImages?.length > 0 && (
                                              <Button
                                                size="sm"
                                                className="btn-simple btn-success my-0"
                                                onClick={this.updateAdminSkuMedia("ACCEPT")}
                                                disabled={selectedFiles2?.length > 0 ? false : true}
                                              >
                                                Accept
                                              </Button>
                                            )}
                                        </h5>
                                        <div className="card-body">
                                          {rejectedSkuImages?.length > 0 ? (
                                            <Row>
                                              {rejectedSkuImages.map((file) => {
                                                return (
                                                  <Col sm="2" className="" key={file.fileId}>
                                                    <ImageZoom
                                                      src={getMediaPath(file.fileId, file.fileExt)}
                                                      alt={file.name}
                                                      className="img-thumbnail rounded"
                                                    />

                                                    {this.props.userType === "SUPPLIER" ? (
                                                      <Button
                                                        color="danger"
                                                        value={file.fileId}
                                                        size="xs"
                                                        onClick={this.deleteHandler}
                                                        disabled={formData.qcStatus === "INPROGRESS"}
                                                      >
                                                        Delete
                                                      </Button>
                                                    ) : (
                                                      <FormGroup check>
                                                        <Label check>
                                                          <Input
                                                            checked={selectedFiles2.includes(file.fileId)}
                                                            type="checkbox"
                                                            name="selectedFiles2"
                                                            data-fileid={file.fileId}
                                                            onChange={this.handleCheckBox}
                                                          />
                                                          <span className="form-check-sign" />
                                                          Select
                                                        </Label>
                                                      </FormGroup>
                                                    )}
                                                  </Col>
                                                );
                                              })}
                                            </Row>
                                          ) : (
                                            <small className="ml-2">No Image has been Rejected</small>
                                          )}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  ) : null}

                                  {formData.similarMedias?.length > 0 ? (
                                    <div className="image-box mt-2">
                                      <h5 className="card-header">
                                        Other Similar Images (You can copy below images to current SKU)
                                      </h5>
                                      <div className="card-body">
                                        <Button
                                          size="xs"
                                          color="info"
                                          onClick={this.copyHandler}
                                          disabled={!copyMediaFiles.length}
                                        >
                                          Move to SKU
                                        </Button>

                                        <Row>
                                          {formData.similarMedias.map((m) => {
                                            return m.fileId ? (
                                              <Col sm="2" key={"simsim" + m.fileId}>
                                                <ImageZoom
                                                  src={getMediaPath(m.fileId, m.fileExt)}
                                                  alt={m.name}
                                                  className="img-thumbnail rounded"
                                                />

                                                <FormGroup check>
                                                  <Label check>
                                                    <Input
                                                      checked={copyMediaFiles.includes(m.fileId)}
                                                      type="checkbox"
                                                      name="copyMediaFiles"
                                                      data-fileid={m.fileId}
                                                      onChange={this.handleCheckBox}
                                                    />
                                                    <span className="form-check-sign" />
                                                    Select
                                                  </Label>
                                                </FormGroup>
                                              </Col>
                                            ) : null;
                                          })}
                                        </Row>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="3">
                            <div className="price-invent sku-form-box">
                              {formData.qcStatus === "APPROVED" ? (
                                <Row>
                                  <Col sm="6">
                                    <div className="price-box  mt-2">
                                      <h5 className="card-header">SKU PRICE</h5>
                                      <div className="card-body">
                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Is Bargain Available{" "}
                                            <span className="help-icon">
                                              <img alt="help" src={helpIcon} id={"Tooltip-help-isbargain"} />
                                              <TooltipItem
                                                placement="right"
                                                text="It will give an option to user to bargain for products"
                                                id={"help-isbargain"}
                                              />
                                            </span>
                                          </Label>
                                          <Col sm={6}>
                                            <Select
                                              options={bargainOptions}
                                              value={bargainOptions.filter(
                                                (option) => option.value === formData.bargainAvailable
                                              )}
                                              onChange={(opt) => this.handleChangeSelect("bargainAvailable", opt)}
                                            />
                                          </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            MRP<span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Input
                                              type="number"
                                              min="0"
                                              step="0.01"
                                              name="mrp"
                                              placeholder="Enter mrp"
                                              value={formData.mrp || ""}
                                              onChange={this.handleChange}
                                            />
                                          </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Sale Price
                                            <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Input
                                              type="number"
                                              min="0"
                                              step="0.01"
                                              name="salePrice"
                                              placeholder="Enter sale price"
                                              value={formData.salePrice || ""}
                                              onChange={this.handleChange}
                                            />
                                          </Col>
                                        </FormGroup>

                                        {/* <FormGroup row>
                                        <Label for="variant" sm={5}>
                                          Final Seller Price
                                          <span className="star">*</span>
                                        </Label>
                                        <Col sm={7}>
                                          <Input
                                            type="text"
                                            disabled
                                            value={formData.finalSellerPrice}
                                          />
                                        </Col>
                                      </FormGroup> */}

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Discount Type
                                            <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Select
                                              options={sellerDicountTypes}
                                              value={sellerDicountTypes.filter(
                                                (option) => option.value === formData.supplierDiscountType
                                              )}
                                              onChange={(opt) => this.handleChangeSelect("supplierDiscountType", opt)}
                                            />
                                          </Col>
                                        </FormGroup>
                                        {formData.supplierDiscountType !== "NONE" ? (
                                          <FormGroup row>
                                            <Label for="variant" sm={6}>
                                              Sale Discount
                                              <span className="star">*</span>
                                            </Label>
                                            <Col sm={6}>
                                              <Input
                                                type="number"
                                                min="0"
                                                step="0.01"
                                                name="saleDiscount"
                                                placeholder="Enter sale discount"
                                                value={formData.saleDiscount || ""}
                                                onChange={this.handleChange}
                                              />
                                            </Col>
                                          </FormGroup>
                                        ) : null}

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Delivery Charge Type <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Select
                                              options={deliveryChargeTypes}
                                              value={deliveryChargeTypes.filter(
                                                (option) => option.value === formData.deliveryChargeType
                                              )}
                                              onChange={(opt) => this.handleChangeSelect("deliveryChargeType", opt)}
                                            />
                                          </Col>
                                        </FormGroup>
                                        {formData.deliveryChargeType !== "NONE" ? (
                                          <FormGroup row>
                                            <Label for="variant" sm={6}>
                                              Delivery Charge
                                              <span className="star"></span>
                                            </Label>
                                            <Col sm={6}>
                                              <Input
                                                type="number"
                                                min="0"
                                                step="0.01"
                                                name="deliveryCharge"
                                                placeholder="Enter delivery charge"
                                                value={formData.deliveryCharge || ""}
                                                onChange={this.handleChange}
                                              />
                                            </Col>
                                          </FormGroup>
                                        ) : null}

                                        <FormGroup row>
                                          <Label for="description3" sm={6} />
                                          <Col sm={6}>
                                            <Button
                                              block
                                              color="primary"
                                              size="sm"
                                              onClick={this.updatePrice}
                                              disabled={formData.mrp < 1 || formData.salePrice < 1}
                                            >
                                              Update Price
                                            </Button>
                                          </Col>
                                        </FormGroup>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="inventory-box mt-2">
                                      <h5 className="card-header">SKU INVENTORY</h5>
                                      <div className="card-body">
                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Inventory Type <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Select
                                              options={inventoryTypes}
                                              value={inventoryTypes.filter(
                                                (option) => option.value === formData.inventoryType
                                              )}
                                              onChange={(opt) => this.handleChangeSelect("inventoryType", opt)}
                                            />
                                          </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Order Type <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Select
                                              options={orderTypes}
                                              value={orderTypes.filter(
                                                (option) => option.value === formData.activateLotOnly
                                              )}
                                              onChange={(opt) => this.handleChangeSelect("activateLotOnly", opt)}
                                            />
                                          </Col>
                                        </FormGroup>

                                        {formData.activateLotOnly === "YES" ? (
                                          <>
                                            <FormGroup row>
                                              <Label for="variant" sm={6}>
                                                Min Lot Size
                                                <span className="star">*</span>
                                              </Label>
                                              <Col sm={6}>
                                                <Input
                                                  type="number"
                                                  min="0"
                                                  name="minLotSize"
                                                  placeholder="Min lot size"
                                                  value={formData.minLotSize || ""}
                                                  onChange={this.handleChange}
                                                />
                                              </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                              <Label for="variant" sm={6}>
                                                Quantity Per Lot
                                                <span className="star">*</span>
                                              </Label>
                                              <Col sm={6}>
                                                <Input
                                                  type="number"
                                                  min="0"
                                                  step="1"
                                                  name="quantityPerLot"
                                                  placeholder="Quantity per lot"
                                                  value={formData.quantityPerLot || ""}
                                                  onChange={this.handleChange}
                                                />
                                              </Col>
                                            </FormGroup>
                                          </>
                                        ) : null}

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Quantity Blocked
                                            <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Input
                                              type="number"
                                              min="0"
                                              step="1"
                                              name="quantityBlocked"
                                              placeholder="Quantity blocked"
                                              value={formData.quantityBlocked}
                                              onChange={this.handleChange}
                                            />
                                          </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Max Quantity Per Order
                                            <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Input
                                              type="number"
                                              min="0"
                                              name="maxQuantityOrder"
                                              placeholder="Max quantity order"
                                              value={formData.maxQuantityOrder || ""}
                                              onChange={this.handleChange}
                                            />
                                          </Col>
                                        </FormGroup>

                                        <div
                                          style={{
                                            background: "paleturquoise",
                                            padding: 10,
                                            marginBottom: 10,
                                          }}
                                        >
                                          <p className="mb-1 text-success">
                                            Total Uploaded Quantity till Date: {formData.totalQuantity}
                                          </p>
                                          <p className="text-success">
                                            Current Available Quantity: {formData.quantityAvailable}
                                          </p>
                                          <small>Select action if you want to add or remove quantity from stock</small>
                                          <FormGroup row style={{ paddingTop: 10 }}>
                                            <Label for="variant" sm={6}>
                                              Stock Action
                                              <span className="star">*</span>
                                            </Label>
                                            <Col sm={6}>
                                              <Select
                                                options={stockActions}
                                                value={stockActions.filter(
                                                  (option) => option.value === formData.stockAction
                                                )}
                                                onChange={(opt) => this.handleChangeSelect("stockAction", opt)}
                                              />
                                            </Col>
                                          </FormGroup>

                                          <FormGroup row>
                                            <Label for="variant" sm={6}>
                                              Stock Quantity
                                              <span className="star">*</span>
                                            </Label>
                                            <Col sm={6}>
                                              <Input
                                                type="number"
                                                min={0}
                                                max={
                                                  formData.stockAction === "remov"
                                                    ? formData.quantityAvailable
                                                    : 9999999999
                                                }
                                                step={1}
                                                name="updatedQuantityDiff"
                                                placeholder="Quantity"
                                                value={formData.updatedQuantityDiff}
                                                onChange={this.handleChange}
                                              />
                                            </Col>
                                          </FormGroup>

                                          <small>
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 16,
                                                color: formData.stockAction === "add" ? "green" : "red",
                                              }}
                                            >
                                              {formData.updatedQuantityDiff}
                                            </span>{" "}
                                            Quantity will be{" "}
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 16,
                                                color: formData.stockAction === "add" ? "green" : "red",
                                              }}
                                            >
                                              {formData.stockAction}ed
                                            </span>{" "}
                                            {formData.stockAction === "add" ? " to" : " from"} Stock
                                          </small>
                                        </div>

                                        <FormGroup row>
                                          <Label for="description3" sm={6} />
                                          <Col sm={6}>
                                            <Button
                                              block
                                              color="primary"
                                              size="sm"
                                              onClick={this.updateInventory}
                                              disabled={formData.updatedQuantityDiff + formData.quantityAvailable < 1}
                                            >
                                              Update Inventory
                                            </Button>
                                          </Col>
                                        </FormGroup>
                                        <small>
                                          To update inventory your stock quantity should be greater than{" "}
                                          {formData.stockAction === "add" ? "" : "equal to"} 0
                                        </small>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col className="text-center mt-5">
                                    <h5>Your SKU is not Approved</h5>{" "}
                                    <p>You can add price and inventory info after sku approval</p>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          </TabPane>

                          <TabPane tabId="4">
                            <div className="price-invent sku-form-box">
                              {formData.qcStatus === "APPROVED" ? (
                                <Row>
                                  <Col sm="12">
                                    <div className="price-box  mt-2">
                                      <h5 className="card-header">SKU SAMPLE PIECE</h5>
                                      <div className="card-body">
                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Sample Status
                                            <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Select
                                              options={sampleStatusTypes}
                                              value={sampleStatusTypes.filter(
                                                (option) => option.value === skuSampleInfo.sampleStatus
                                              )}
                                              onChange={(opt) =>
                                                this.handleChangeSelect("sampleStatus", {
                                                  ...opt,
                                                  type: "skuSampleInfo",
                                                })
                                              }
                                            />
                                          </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Sample Fulfillment Type
                                            <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Select
                                              options={sampleFulfillmentTypes}
                                              value={sampleFulfillmentTypes.filter(
                                                (option) => option.value === skuSampleInfo.sampleFulfillmentType
                                              )}
                                              onChange={(opt) =>
                                                this.handleChangeSelect("sampleFulfillmentType", {
                                                  ...opt,
                                                  type: "skuSampleInfo",
                                                })
                                              }
                                            />
                                          </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Minimum Quantity Per Order
                                            <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Input
                                              data-object="skuSampleInfo"
                                              type="number"
                                              min="1"
                                              step="1"
                                              name="sampleMinOrderQuantity"
                                              placeholder="Enter min quantity"
                                              value={skuSampleInfo.sampleMinOrderQuantity}
                                              onChange={this.handleChange}
                                            />
                                          </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Maximum Quantity Per Order
                                            <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Input
                                              data-object="skuSampleInfo"
                                              type="number"
                                              min="1"
                                              step="1"
                                              name="sampleMaxOrderQuantity"
                                              placeholder="Enter max quantity"
                                              value={skuSampleInfo.sampleMaxOrderQuantity}
                                              onChange={this.handleChange}
                                            />
                                          </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Sample Total Quantity
                                            <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Input
                                              data-object="skuSampleInfo"
                                              type="number"
                                              min="0"
                                              step="1"
                                              name="sampleTotalQuantity"
                                              placeholder="Enter total quantity"
                                              value={skuSampleInfo.sampleTotalQuantity}
                                              onChange={this.handleChange}
                                            />
                                          </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Sample Discount Type
                                            <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Select
                                              options={sellerDicountTypes}
                                              value={sellerDicountTypes.filter(
                                                (option) => option.value === skuSampleInfo.sampleDiscountType
                                              )}
                                              onChange={(opt) =>
                                                this.handleChangeSelect("sampleDiscountType", {
                                                  ...opt,
                                                  type: "skuSampleInfo",
                                                })
                                              }
                                            />
                                          </Col>
                                        </FormGroup>
                                        {skuSampleInfo.sampleDiscountType !== "NONE" ? (
                                          <FormGroup row>
                                            <Label for="variant" sm={6}>
                                              Sample Discount
                                              <span className="star">*</span>
                                            </Label>
                                            <Col sm={6}>
                                              <Input
                                                data-object="skuSampleInfo"
                                                type="number"
                                                min="0"
                                                step="0.01"
                                                name="sampleDiscount"
                                                placeholder="Enter sample discount"
                                                value={skuSampleInfo.sampleDiscount || ""}
                                                onChange={this.handleChange}
                                              />
                                            </Col>
                                          </FormGroup>
                                        ) : null}

                                        <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Sample Delivery Charge Type <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <Select
                                              options={deliveryChargeTypes}
                                              value={deliveryChargeTypes.filter(
                                                (option) => option.value === skuSampleInfo.sampleDeliveryChargeType
                                              )}
                                              onChange={(opt) =>
                                                this.handleChangeSelect("sampleDeliveryChargeType", {
                                                  ...opt,
                                                  type: "skuSampleInfo",
                                                })
                                              }
                                            />
                                          </Col>
                                        </FormGroup>

                                        {skuSampleInfo.sampleDeliveryChargeType !== "NONE" ? (
                                          <FormGroup row>
                                            <Label for="variant" sm={6}>
                                              Sample Delivery Charge
                                              <span className="star"></span>
                                            </Label>
                                            <Col sm={6}>
                                              <Input
                                                data-object="skuSampleInfo"
                                                type="number"
                                                min="0"
                                                step="1"
                                                name="sampleDeliveryCharge"
                                                placeholder="Enter delivery charge"
                                                value={skuSampleInfo.sampleDeliveryCharge}
                                                onChange={this.handleChange}
                                              />
                                            </Col>
                                          </FormGroup>
                                        ) : null}

                                        <FormGroup row>
                                          <Label for="title" sm={6}>
                                            Start Date <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <DatePicker
                                              className="form-control"
                                              placeholderText="Click to select start date"
                                              showTimeSelect
                                              selected={skuSampleInfo.samplePromotionStartDate}
                                              onChange={this.handleChangeStartDate}
                                              timeFormat="HH:mm"
                                              timeIntervals={60}
                                              dateFormat="yyyy-MM-dd HH:mm:ss"
                                              timeCaption="Time"
                                              minDate={new Date()}
                                              withPortal
                                            />
                                          </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                          <Label for="title" sm={6}>
                                            End Date <span className="star">*</span>
                                          </Label>
                                          <Col sm={6}>
                                            <DatePicker
                                              className="form-control"
                                              placeholderText="Click to select end date"
                                              showTimeSelect
                                              selected={skuSampleInfo.samplePromotionEndDate}
                                              onChange={this.handleChangeEndDate}
                                              timeFormat="HH:mm"
                                              timeIntervals={60}
                                              dateFormat="yyyy-MM-dd HH:mm:ss"
                                              timeCaption="Time"
                                              minDate={skuSampleInfo.samplePromotionStartDate}
                                              withPortal
                                            />
                                          </Col>
                                        </FormGroup>

                                        {/* <FormGroup row>
                                          <Label for="variant" sm={6}>
                                            Additional Info
                                            <span className="star"></span>
                                          </Label>
                                          <Col sm={6}>
                                            <Input
                                              data-object="skuSampleInfo"
                                              type="textarea"
                                              name="sampleAdditionalInfo"
                                              placeholder="Enter additional info"
                                              value={
                                                skuSampleInfo.sampleAdditionalInfo ||
                                                ""
                                              }
                                              onChange={this.handleChange}
                                            />
                                          </Col>
                                        </FormGroup> */}

                                        <FormGroup row>
                                          <Label for="description3" sm={6} />
                                          <Col sm={6}>
                                            <Button
                                              block
                                              color="primary"
                                              size="md"
                                              onClick={this.updateSample}
                                              disabled={
                                                skuSampleInfo.sampleMinOrderQuantity < 1 ||
                                                skuSampleInfo.sampleMaxOrderQuantity < 1 ||
                                                skuSampleInfo.sampleTotalQuantity < 1 ||
                                                !skuSampleInfo.sampleStatus
                                              }
                                            >
                                              Update Sample Info
                                            </Button>
                                          </Col>
                                        </FormGroup>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col className="text-center mt-5">
                                    <h5>Your SKU is not Approved</h5>{" "}
                                    <p>You can add sample sku info after sku approval</p>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          </TabPane>
                        </TabContent>
                      </Col>
                      <Col sm="4">
                        {formData.variantSkus?.length ? (
                          <React.Fragment>
                            <h4>
                              Other Variants{" "}
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  fontSize: 14,
                                }}
                              >
                                ({ovLabel?.slice(0, -2)})
                              </span>
                            </h4>
                            <ListGroup>
                              {formData.variantSkus.map((product) => {
                                return (
                                  <ListGroupItem key={"kk" + product.skuId}>
                                    <Link
                                      to={
                                        this.props.userType === "SUPPLIER"
                                          ? SUPPLIER_SKU_VIEW + product.skuId
                                          : ADMIN_SKU_VIEW + product.skuId
                                      }
                                    >
                                      SKU ID: {product.skuId} (
                                      {product.variantType !== "NONE" ? product.variantType : null}: {product.variant})
                                      <span className="float-right">
                                        {product.status === "ACTIVE" ? (
                                          <span className="text-success">Active</span>
                                        ) : (
                                          <span className="text-danger">Inactive</span>
                                        )}
                                      </span>
                                    </Link>
                                  </ListGroupItem>
                                );
                              })}
                            </ListGroup>
                          </React.Fragment>
                        ) : null}

                        {similarVariantSkus && Object.keys(similarVariantSkus).length ? (
                          <React.Fragment>
                            {Object.keys(similarVariantSkus).map((k, index) => {
                              const obj = similarVariantSkus[k];
                              const skus = obj.skus;

                              let kvLabel = "";
                              obj.keyValues &&
                                Object.keys(obj.keyValues).map((o) => {
                                  const k = obj.keyValues[o];
                                  return (kvLabel = kvLabel + k.name + ": " + k.value + ", ");
                                });
                              return (
                                <React.Fragment key={"topp" + index}>
                                  <h4 className="mt-3">
                                    Similar Variants{" "}
                                    <span
                                      style={{
                                        textTransform: "uppercase",
                                        fontSize: 14,
                                      }}
                                    >
                                      ({kvLabel?.slice(0, -2)})
                                    </span>
                                  </h4>
                                  <ListGroup>
                                    {skus.map((product) => {
                                      return (
                                        <ListGroupItem key={"kkpp" + product.skuId}>
                                          <Link
                                            to={
                                              this.props.userType === "SUPPLIER"
                                                ? SUPPLIER_SKU_VIEW + product.skuId
                                                : ADMIN_SKU_VIEW + product.skuId
                                            }
                                          >
                                            SKU ID: {product.skuId} (
                                            {product.variantType !== "NONE" ? product.variantType : null}:{" "}
                                            {product.variant})
                                            <span className="float-right">
                                              {product.status === "ACTIVE" ? (
                                                <span className="text-success">Active</span>
                                              ) : (
                                                <span className="text-danger">Inactive</span>
                                              )}
                                            </span>
                                          </Link>
                                        </ListGroupItem>
                                      );
                                    })}
                                  </ListGroup>
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        ) : null}
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : null}
              </Col>
              {isLoading ? <div className="loader" /> : null}
            </Row>
          </div>
        ) : (
          <p className="invalid-id">Invalid SKU ID</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state.resHandlerVal.data);
  return {
    isLoading: state.ui.isLoading,
    userType: state.auth.userType,
    responseVal: state.resHandlerVal,
    categories: state.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBrandList: (reqParam) => dispatch(getBrandList(reqParam)),
    getSkuForm: (reqParam) => dispatch(getSkuForm(reqParam)),
    fetchSku: (reqParam) => dispatch(fetchSku(reqParam)),
    activeDeactiveSku: (reqParam) => dispatch(activeDeactiveSku(reqParam)),
    updateSkuSample: (reqParam) => dispatch(updateSkuSample(reqParam)),
    updateSkuPrice: (reqParam) => dispatch(updateSkuPrice(reqParam)),
    updateSkuInventory: (reqParam) => dispatch(updateSkuInventory(reqParam)),
    addUpdateSku: (reqParam) => dispatch(addUpdateSku(reqParam)),
    uploadImages: (files) => dispatch(uploadImages(files)),
    saveImages: (reqParam) => dispatch(saveImages(reqParam)),
    deleteImages: (reqParam) => dispatch(deleteImages(reqParam)),
    markAdminSku: (reqParam) => dispatch(markAdminSku(reqParam)),
    markAdminSkuMedia: (reqParam) => dispatch(markAdminSkuMedia(reqParam)),
    getCategories: (reqParam) => dispatch(getCategories(reqParam)),
    removeMediaObject: (reqParam) => dispatch(removeMediaObject(reqParam)),
    resetResponseValues: () => dispatch(resetResponseValues()),
    clearMediaList: () => dispatch(clearMediaList()),
    clearMediaObject: () => dispatch(clearMediaObject()),
    fetchSimilarSku: (reqParam) => dispatch(fetchSimilarSku(reqParam)),
    uploadAllImages: (files) => dispatch(uploadAllImages(files)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSku);
