import { SAVE_TEMP_SKUS, SAVE_TEMP_SKU } from "../actions/actionTypes";

const initialState = {
  skuList: null,
  skuDetail: null,
};
const tempSkuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TEMP_SKUS:
      return {
        ...state,
        skuDetail: null,
        skuList: action.data,
      };
    case SAVE_TEMP_SKU:
      return {
        ...state,
        skuDetail: action.data,
      };
    default:
      return state;
  }
};

export default tempSkuReducer;
