import React from "react";
import MessageBlock from "../../components/MessageBlock/MessageBlock";

class PanelHeader extends React.Component {
  render() {
    return (
      <div className={`panel-header panel-header-${this.props.size ?? ""}`}>
        <MessageBlock screenName="commonerror" />
        {this.props.content}
      </div>
    );
  }
}

export default PanelHeader;
