import React from "react";
import { Row, Col, Form, FormGroup, Input, Card, CardBody, Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import { connect } from "react-redux";
import { clearProfile } from "../../datautils/actions/userActions";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import {
  getSuppliersProfile,
  updateSupplier,
  updateBank,
  updateKyc,
  updateAddress,
  activateSupplier,
} from "../../datautils/actions/adminActions";
import { businessTypes } from "../../utils/constants";
import Select from "react-select";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import KycBlock from "../../components/Profile/KycBlock";
import BankBlock from "../../components/Profile/BankBlock";
import AddressBlock from "../../components/Profile/AddressBlock";
import ContactBlock from "../../components/Profile/ContactBlock";
import ContentEditable from "react-contenteditable";
import GoBack from "../../components/GoBack/GoBack";
import TooltipItem from "../../components/Tooltip/Tooltip";

class SuppliersProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      currentTitle: "",
      currentAction: "",
      currentActionType: "",
      currentDocumentId: "",
      currentDocumentType: "",
      currentAddressType: "",

      search: "",
      id: "",
      notes: "",

      businessName: "",
      businessDescription: "",
      businessType: "",
      mobileNumber: "",
      profileLoaded: false,

      accountNumber: "",
      accountName: "",
      bankName: "",
      city: "",
      branchName: "",
      ifscCode: "",
      micrCode: "",
      branchAddress: "",
      branchAddInfo: "",

      currentTab: "0",
      mainTitle: "Supplier's Profile",

      isUpdated: false,
    };
  }

  componentDidMount() {
    this.props.resetResponseValues();

    const { id } = this.props.match.params;

    if (id > 0) {
      this.setState({ id });
      const requestData = { supplierUserId: id };
      this.props.getSuppliersProfile(requestData);
    }
  }

  componentDidUpdate() {
    const { profile, responseVal } = this.props;

    if (profile.profileInfo?.mobileNumber && this.state.mobileNumber !== profile.profileInfo.mobileNumber) {
      this.setState({
        ...this.state,
        profileLoaded: true,
        mobileNumber: profile.profileInfo.mobileNumber,
        businessName: profile.profileInfo.businessName,
        businessDescription: profile.profileInfo.businessDescription,
        businessType: profile.profileInfo.businessType,
        modal: false,
      });
    }

    if (profile.bank?.accountNumber && this.state.accountNumber !== profile.bank.accountNumber) {
      this.setState({
        ...this.state,
        bankLoaded: true,
        accountNumber: profile.bank.accountNumber,
        accountName: profile.bank.accountName,
        bankName: profile.bank.bankName,
        city: profile.bank.city,
        branchName: profile.bank.branchName,
        ifscCode: profile.bank.ifscCode,
        micrCode: profile.bank.micrCode,
        branchAddress: profile.bank.branchAddress,
        branchAddInfo: profile.bank.branchAddInfo,
        modal: false,
      });
    }

    if (responseVal.status === "success" && this.state.modal) {
      this.setState({ modal: false });
    }
  }

  componentWillUnmount() {
    console.log("unnn");
    this.props.clearProfile();
  }

  tabActions = (e) => {
    this.setState({
      currentTab: e.target.dataset.value,
      mainTitle: e.target.dataset.title,
    });
  };

  refreshData = (e) => {
    const requestData = { supplierUserId: this.state.id };
    this.props.getSuppliersProfile(requestData);
  };

  toggle = (requestData) => {
    if (this.state.modal) {
      this.setState({
        modal: false,
        notes: "",
        currentTitle: "",
        currentAction: "",
        currentActionType: "",
        currentDocumentId: "",
        currentDocumentType: "",
        currentAddressType: "",
        gstNumber: "",
        gstFileId: "",
        gstAdditionalInfo: "",
      });
    } else {
      this.setState({
        modal: true,
        notes: "",
        currentTitle: requestData.title,
        currentAction: requestData.action,
        currentActionType: requestData.actionType,
        currentDocumentId: requestData.documentId,
        currentDocumentType: requestData.documentType,
        currentAddressType: requestData.addressType,
        gstNumber: requestData.gstNumber,
        gstFileId: requestData.gstFileId,
        gstAdditionalInfo: requestData.gstAdditionalInfo,
      });
      this.props.resetResponseValues();
    }
  };

  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState({ [obj]: opt.value });
    console.log(`Option selected:`, opt.value);
  };

  updateProfile = (actionType) => (e) => {
    e.preventDefault();

    const reqData = {
      userId: this.props.authData.userId,
      supplierUserId: this.state.id,
      userType: this.props.authData.userType,
      actionType: actionType,
      businessName: this.state.businessName,
      businessDescription: this.state.businessDescription,
      businessType: this.state.businessType,
      adminNotes: this.state.notes,
    };

    if (this.state.modal) {
      this.props.updateSupplier(reqData);
      this.setState({ isUpdated: true });
    } else {
      this.toggle({
        actionType: actionType,
        action: "updateSupplier",
        title: "Basic Profile",
      });
    }

    //this.props.updateSupplier(reqData);
  };

  updateKyc = (requestData) => (e) => {
    e.preventDefault();
    const reqData = {
      supplierUserId: this.state.id,
      documentId: requestData.documentId,
      documentType: requestData.documentType,
      actionType: requestData.actionType,
      userId: this.props.authData.userId,
      userType: this.props.authData.userType,
      adminNotes: this.state.notes,
    };

    if (this.state.modal) {
      this.props.updateKyc(reqData, this.state.file);
      this.setState({ isUpdated: true });
    } else {
      this.toggle({
        action: "updateKyc",
        actionType: requestData.actionType,
        documentId: requestData.documentId,
        documentType: requestData.documentType,
        title: requestData.documentType,
      });
    }
    //this.props.updateKyc(reqData, this.state.file);
  };

  updateBank = (actionType) => (e) => {
    e.preventDefault();

    const reqData = {
      userId: this.props.authData.userId,
      supplierUserId: this.state.id,
      userType: this.props.authData.userType,
      actionType: actionType,
      adminNotes: this.state.notes,

      /*accountNumber: this.state.accountNumber,
      accountName: this.state.accountName,
      bankName: this.state.bankName,
      city: this.state.city,
      branchName: this.state.branchName,
      ifscCode: this.state.ifscCode,
      micrCode: this.state.micrCode,
      branchAddress: this.state.branchAddress,
      branchAddInfo: this.state.branchAddInfo */
    };

    if (this.state.modal) {
      this.props.updateBank(reqData);
      this.setState({ isUpdated: true });
    } else {
      this.toggle({
        action: "updateBank",
        title: "BANK INFO",
        actionType: actionType,
      });
    }
    //this.props.updateBank(reqData);
  };

  activateSupplier = (actionType) => (e) => {
    if (this.state.modal) {
      const reqData = {
        supplierUserId: this.state.id,
        actionType: actionType,
        status: actionType,
        userId: this.props.authData.userId,
        userType: this.props.authData.userType,
        adminNotes: this.state.notes,
      };

      this.props.activateSupplier(reqData);
      this.setState({ isUpdated: true });
    } else {
      this.toggle({
        actionType: actionType,
        action: "updateSupplier",
        title: actionType + "Supplier",
      });
    }
  };

  verifySupplier = (status, actionType) => (e) => {
    if (this.state.modal) {
      const reqData = {
        verifySupplier: true,
        actionType,
        status,
        supplierUserId: this.state.id,
        userId: this.props.authData.userId,
        userType: this.props.authData.userType,
        adminNotes: this.state.notes,
      };

      this.setState({ isUpdated: true });
      this.props.activateSupplier(reqData);
    } else {
      this.toggle({
        actionType: actionType,
        action: "verifySupplier",
        title: actionType + " Supplier",
      });
    }
  };

  updateAddress = (requestData) => {
    const reqData = {
      ...requestData,
      supplierUserId: this.state.id,
      adminNotes: this.state.notes,
      userId: this.props.authData.userId,
      userType: this.props.authData.userType,

      addressType: requestData.addressType,
      id: requestData.documentId,
      actionType: requestData.actionType,
    };

    if (this.state.modal) {
      this.props.updateAddress(reqData);
      this.setState({ isUpdated: true });
    } else {
      this.toggle({
        ...requestData,
        action: "updateAddress",
        actionType: requestData.actionType,
        documentId: requestData.documentId,
        addressType: requestData.addressType,
        title: requestData.addressType,
      });
    }
  };

  renderSwitch(currentActionType, action) {
    const { notes } = this.state;

    if (action === "verifySupplier") {
      switch (currentActionType) {
        case "VERIFY":
          return (
            <Button
              color="success"
              className="btn-block"
              onClick={this.verifySupplier(currentActionType, currentActionType)}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Verify Supplier
            </Button>
          );
        case "UNVERIFY":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.verifySupplier(currentActionType, currentActionType)}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              UnVerify Supplier
            </Button>
          );
        default:
          return null;
      }
    }

    if (action === "updateSupplier") {
      switch (currentActionType) {
        case "ADMIN_UPDATE":
          return (
            <Button
              className="btn-block btn-primary"
              onClick={this.updateProfile("ADMIN_UPDATE")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Update
            </Button>
          );

        case "ACCEPT":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateProfile("ACCEPT")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Accept
            </Button>
          );

        case "ONHOLD":
          return (
            <Button
              className="btn-block btn-warning"
              onClick={this.updateProfile("ONHOLD")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              OnHold
            </Button>
          );
        case "REJECT":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateProfile("REJECT")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Reject
            </Button>
          );
        default:
          return null;
      }
    }

    if (action === "updateBank") {
      switch (currentActionType) {
        case "ADMIN_UPDATE":
          return (
            <Button
              className="btn-block btn-primary"
              onClick={this.updateBank("ADMIN_UPDATE")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Update
            </Button>
          );

        case "ACCEPT":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateBank("ACCEPT")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Accept
            </Button>
          );

        case "ONHOLD":
          return (
            <Button
              className="btn-block btn-warning"
              onClick={this.updateBank("ONHOLD")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              OnHold
            </Button>
          );
        case "REJECT":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateBank("REJECT")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Reject
            </Button>
          );
        default:
          return null;
      }
    }

    if (action === "updateKyc") {
      switch (currentActionType) {
        case "ACCEPT":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateKyc({
                actionType: "ACCEPT",
                documentId: this.state.currentDocumentId,
                documentType: this.state.currentDocumentType,
              })}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Accept
            </Button>
          );

        case "ONHOLD":
          return (
            <Button
              className="btn-block btn-warning"
              onClick={this.updateKyc({
                actionType: "ONHOLD",
                documentId: this.state.currentDocumentId,
                documentType: this.state.currentDocumentType,
              })}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              OnHold
            </Button>
          );
        case "REJECT":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateKyc({
                actionType: "REJECT",
                documentId: this.state.currentDocumentId,
                documentType: this.state.currentDocumentType,
              })}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Reject
            </Button>
          );
        default:
          return null;
      }
    }

    if (action === "updateAddress") {
      switch (currentActionType) {
        case "ACCEPT":
          return (
            <Button
              className="btn-block btn-success"
              onClick={() =>
                this.updateAddress({
                  actionType: "ACCEPT",
                  documentId: this.state.currentDocumentId,
                  addressType: this.state.currentAddressType,
                  gstNumber: this.state.gstNumber,
                  gstFileId: this.state.gstFileId,
                  gstAdditionalInfo: this.state.gstAdditionalInfo,
                })
              }
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Accept
            </Button>
          );

        case "ONHOLD":
          return (
            <Button
              className="btn-block btn-warning"
              onClick={() =>
                this.updateAddress({
                  actionType: "ONHOLD",
                  documentId: this.state.currentDocumentId,
                  addressType: this.state.currentAddressType,
                  gstNumber: this.state.gstNumber,
                  gstFileId: this.state.gstFileId,
                  gstAdditionalInfo: this.state.gstAdditionalInfo,
                })
              }
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              OnHold
            </Button>
          );
        case "REJECT":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={() =>
                this.updateAddress({
                  actionType: "REJECT",
                  documentId: this.state.currentDocumentId,
                  addressType: this.state.currentAddressType,
                  gstNumber: this.state.gstNumber,
                  gstFileId: this.state.gstFileId,
                  gstAdditionalInfo: this.state.gstAdditionalInfo,
                })
              }
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Reject
            </Button>
          );
        default:
          return null;
      }
    }
  }

  renderModal = () => {
    const { notes, currentAction, currentActionType, currentTitle } = this.state;

    return (
      <Modal backdrop="static" centered isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{currentTitle.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    <Form autoComplete="off">
                      <FormGroup>
                        <Input
                          type="textarea"
                          placeholder="Add proper message/reason/notes here"
                          onChange={this.handleChange("notes")}
                          value={notes}
                        />
                      </FormGroup>
                    </Form>
                    {this.props.authData.userType === "SUPPLIER" ? null : (
                      <>{this.renderSwitch(currentActionType, currentAction)}</>
                    )}
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { businessName, businessDescription, businessType, profileLoaded, currentTab, isUpdated } = this.state;

    const { profile, authData, responseVal } = this.props;

    // const overAllStatus =
    //   profileLoaded &&
    //   profile.profileInfo &&
    //   profile.profileInfo.status === "ACTIVE"
    //     ? "success"
    //     : "danger";

    const mismatchedWorkflowKeyMap = {
      PAN: "PAN_INFO",
      LEGAL_ENTITY_CHEQUE: "LEGAL_ENTITY_CHEQUE_INFO",
      BOARD_RESOLUTION: "BOARD_RESOLUTION_INFO",
      COMPANY_INCORPORATION_CERTIFICATE: "COMPANY_INCORPORATION_CERTIFICATE_INFO",
    };

    const overAllVerifyStatus = profileLoaded && profile?.profileInfo?.verified ? "success" : "danger";

    const workflow = profileLoaded && profile?.workflow;
    let profileWorkflow = {};
    let notCompletedSections = [];
    let notCompletedItems = {};
    workflow &&
      Object.keys(workflow.workflowStatusMap).forEach((index) => {
        const object = workflow.workflowStatusMap[index];
        return Object.keys(object).forEach((index2) => {
          const object2 = object[index2];
          notCompletedItems[index2] = [];
          object2.subSections &&
            Object.keys(object2.subSections).forEach((index3) => {
              const object3 = object2.subSections[index3];

              profileWorkflow[index3] = object3;
              if (object3.currentState === "INCOMPLETE") {
                notCompletedSections.push(index3);
                notCompletedItems[index2].push(index3);
              }
            });
        });
      });

    const accountWorkflow = profileWorkflow["ACCOUNT_INFO"];

    let alertMsg = "";
    let color = "danger";

    if (workflow && workflow.overAllState === "COMPLETE" && profile.profileInfo && !profile.profileInfo.verified) {
      alertMsg = "Supplier's Account is complete and is waiting for verification approval";
      color = "warning";
    } else if (workflow && workflow.overAllState !== "COMPLETE") {
      alertMsg = "Supplier's Account is incomplete";
    } else if (
      workflow &&
      workflow.overAllState === "COMPLETE" &&
      profile.profileInfo &&
      profile.profileInfo.verified
    ) {
      alertMsg = "Supplier's Account is Active";
      color = "success";
    }

    return (
      <div>
        <PanelHeader />
        <div className="content mt-3">
          {this.state.modal ? this.renderModal() : null}

          {responseVal.status === "failure" ? <ErrorBlock errors={responseVal.errors} /> : null}
          {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}

          <Row>
            <Col xs={6}>
              <GoBack text={businessName} />
            </Col>

            <Col xs={6}>
              {/* {overAllStatus === "success" ? (
                <Button
                  title="Supplier is active"
                  className="float-right"
                  size="sm"
                  color={overAllStatus}
                  onClick={() => this.activateSupplier("DEACTIVATE")}
                >
                  Deactivate
                </Button>
              ) : (
                <Button
                  title="Supplier is not active"
                  className="float-right"
                  size="sm"
                  color={overAllStatus}
                  onClick={() => this.activateSupplier("ACTIVATE")}
                >
                  Activate
                </Button>
              )} */}

              {profile?.profileInfo ? (
                <>
                  {overAllVerifyStatus === "success" ? (
                    <Button
                      className="float-right mt-0"
                      size="sm"
                      color={overAllVerifyStatus}
                      onClick={this.verifySupplier("UNVERIFY", "UNVERIFY")}
                    >
                      Mark Unverified
                    </Button>
                  ) : (
                    <Button
                      className="float-right mt-0"
                      size="sm"
                      color={overAllVerifyStatus}
                      onClick={this.verifySupplier("VERIFY", "VERIFY")}
                    >
                      Mark Verified
                    </Button>
                  )}
                </>
              ) : null}
            </Col>
            <Col sm={12}>
              {notCompletedSections?.length > 999 && (
                <SuccessBlock
                  msg={{
                    userDisplayMsg: `Incomplete Sections: ${notCompletedSections
                      .join(", ")
                      .replace(/_/g, " ")
                      .toLowerCase()}`,
                  }}
                  color="danger"
                  position="alert-relative"
                />
              )}
            </Col>
            <Col xs={12}>
              {alertMsg?.length > 0 && (
                <SuccessBlock
                  msg={{
                    userDisplayMsg: alertMsg,
                  }}
                  color={color}
                  position="alert-relative"
                />
              )}

              {workflow &&
                Object.keys(workflow.workflowStatusMap).map((index) => {
                  const object = workflow.workflowStatusMap[index];

                  return (
                    <div key={"w-1" + index} className="aa">
                      {Object.keys(object).map((index2) => {
                        const object2 = object[index2];
                        const headText = index2 === "MY_ACCOUNT" ? "BASIC PROFILE" : index2?.replace(/_/g, " ");
                        let errorItem = "";
                        let errorColor = "danger";
                        if (object2.overAllState === "SUBMITTED") {
                          errorColor = "info";
                          errorItem = `${headText} is updated by user, Please verify`;
                        } else if (object2.overAllState === "INCOMPLETE") {
                          errorItem = `${headText} is incomplete`;
                          if (notCompletedItems[index2]?.length) {
                            errorItem += ` (${notCompletedItems[index2]
                              .join(", ")
                              .replace(/_/g, " ")
                              .replace(/INFO/g, "")
                              .toLowerCase()})`;
                          }
                        } else if (object2.overAllState === "REJECTED") {
                          errorItem = `${headText} is rejected`;
                        } else if (object2.overAllState === "ON_HOLD") {
                          errorItem = `${headText} is on hold`;
                        }

                        return errorItem.length ? (
                          <div key={"w-2-" + index2}>
                            <SuccessBlock
                              msg={{ userDisplayMsg: errorItem }}
                              color={errorColor}
                              position="alert-relative"
                            />
                          </div>
                        ) : null;
                      })}
                    </div>
                  );
                })}
            </Col>

            <Col xs={12}>
              <ul className="tabs">
                <li
                  data-title="Supplier's Profile"
                  data-value="0"
                  onClick={this.tabActions}
                  className={currentTab === "0" ? "bg-secondary text-white" : ""}
                >
                  BASIC PROFILE
                </li>
                <li
                  data-title="Supplier's Bank Details"
                  data-value="1"
                  onClick={this.tabActions}
                  className={currentTab === "1" ? "bg-secondary text-white" : ""}
                >
                  BANK DETAILS
                </li>
                <li
                  data-title="Supplier's KYC"
                  data-value="2"
                  onClick={this.tabActions}
                  className={currentTab === "2" ? "bg-secondary text-white" : ""}
                >
                  KYC
                </li>
                <li
                  data-title="Supplier's Address"
                  data-value="3"
                  onClick={this.tabActions}
                  className={currentTab === "3" ? "bg-secondary text-white" : ""}
                >
                  ADDRESS
                </li>
                <li
                  data-title="Supplier's Contacts"
                  data-value="4"
                  onClick={this.tabActions}
                  className={currentTab === "4" ? "bg-secondary text-white" : ""}
                >
                  CONTACTS
                </li>
                <li className="pull-right">
                  <i
                    title="Refresh Data"
                    className="now-ui-icons arrows-1_refresh-69"
                    data-title="refresh"
                    data-value={currentTab}
                    onClick={this.refreshData}
                  />
                </li>
              </ul>
            </Col>

            <Col xs={12}>
              <Row>
                <Col md={8} className={currentTab === "1" ? "d-block" : "d-none"}>
                  <Card>
                    <CardBody>
                      <h5 className="mb-0">
                        <span className={`status ${profile.bank?.workflowStatus}`} id="Tooltip-banx">
                          <i className="now-ui-icons ui-1_check" />
                        </span>{" "}
                        Bank Account
                      </h5>
                      <TooltipItem placement="right" text={profile.bank?.workflowStatusMessage} id="banx" />

                      {profile.bank?.accountNumber ? (
                        <BankBlock
                          type="bank"
                          content={profile.bank}
                          updateBank={this.updateBank}
                          userType={authData.userType}
                        />
                      ) : null}
                    </CardBody>
                  </Card>
                </Col>

                {profileLoaded && profile.profileInfo ? (
                  <Col lg={4} sm={6} xs={12} className={currentTab === "0" ? "d-block" : "d-none"}>
                    <Card className="card-stats">
                      <CardBody>
                        <div className="row">
                          <div className="col">
                            <h5 className="card-title mb-0">
                              <span className={`status ${accountWorkflow?.currentState}`} id="Tooltip-profilx">
                                <i className="now-ui-icons ui-1_check" />
                              </span>
                              {profile.profileInfo.sectionName}
                            </h5>
                            <TooltipItem placement="right" text={accountWorkflow?.currentState} id="profilx" />

                            <div className="admin-action p-2 mt-2" style={{ background: "#f5f5f5" }}>
                              <div className="mt-0 mb-0 d-flex justify-content-between">
                                <p className="mb-0 text-muted">Current Status</p>
                                <p className="mb-0 mt-0">
                                  <span className={`text-${accountWorkflow?.currentState}`}>
                                    {accountWorkflow?.currentState?.replace(/_/g, "")}
                                  </span>
                                </p>
                              </div>

                              <div className="mt-1 mb-0 d-flex justify-content-between">
                                <p className="mb-0 text-muted">Status Notes</p>
                                <p className="mb-0 mt-0">
                                  <span>{accountWorkflow?.statusMessage}</span>
                                </p>
                              </div>

                              {profile.profileInfo?.adminWorkflowMessage?.length > 0 ? (
                                <div className="mt-1 mb-0">
                                  <p className="mb-0 text-muted">Admin Notes</p>
                                  <p className="mt-0 mb-1">{profile.profileInfo.adminWorkflowMessage}</p>
                                </div>
                              ) : null}
                            </div>

                            <div className="mt-3">
                              <React.Fragment>
                                {/* <Button
                                  size="xs"
                                  className="btn-simple btn-light btn-primary"
                                  onClick={this.updateProfile("ADMIN_UPDATE")}
                                >
                                  Update
                                </Button> */}
                                <Button
                                  size="xs"
                                  className="btn-simple btn-light btn-success"
                                  onClick={this.updateProfile("ACCEPT")}
                                >
                                  Accept
                                </Button>

                                <Button
                                  size="xs"
                                  className="btn-simple btn-light btn-warning"
                                  onClick={this.updateProfile("ONHOLD")}
                                >
                                  OnHold
                                </Button>

                                <Button
                                  size="xs"
                                  className="btn-simple btn-light btn-danger"
                                  onClick={this.updateProfile("REJECT")}
                                >
                                  Reject
                                </Button>
                              </React.Fragment>
                            </div>

                            <div className="border-btm"></div>
                            <div className="mt-2 mb-0">
                              <p className="mb-0 text-muted">Current Status</p>
                              <p className="mt-0">
                                <span className={`text-${profile.profileInfo.status}`}>
                                  {profile.profileInfo.status}
                                </span>
                              </p>
                            </div>

                            <div className="mt-2 mb-0">
                              <p className="mb-0 text-muted">Business Name</p>

                              <ContentEditable
                                html={businessName}
                                disabled={true}
                                onChange={this.handleChange("businessName")}
                              />
                            </div>

                            <div className="mt-2 mb-0">
                              <p className="mb-0 text-muted">Mobile No.</p>
                              <p className="mt-0">
                                {profile.profileInfo.mobileCountryCode}-{profile.profileInfo.mobileNumber}
                              </p>
                            </div>

                            <div className="mt-2 mb-0">
                              <p className="mb-0 text-muted">Is Verified</p>
                              <p className="mt-0">
                                {profile.profileInfo.verified ? (
                                  <span className="text-success">YES</span>
                                ) : (
                                  <span className="text-danger">No</span>
                                )}
                              </p>
                            </div>

                            <div className="mt-2 mb-0">
                              <p className="mb-0 text-muted">Business Type</p>

                              <FormGroup>
                                <Select
                                  placeholder="Business Type"
                                  name="businessType"
                                  options={businessTypes}
                                  value={businessTypes.filter((option) => option.value === businessType)}
                                  onChange={(opt) => this.handleChangeSelect("businessType", opt)}
                                />
                              </FormGroup>
                            </div>

                            <div className="mt-2 mb-3">
                              <p className="mb-0 text-muted">Business Description</p>

                              <ContentEditable
                                html={businessDescription}
                                disabled={true}
                                onChange={this.handleChange("businessDescription")}
                              />
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ) : null}
              </Row>
            </Col>

            <Col xs={12} className={currentTab === "2" ? "d-block" : "d-none"}>
              <Row>
                {profile.kyc?.kycDetailsMap
                  ? Object.keys(profile.kyc.kycDetailsMap).map((index) => {
                      const workflowIndex = index in mismatchedWorkflowKeyMap ? mismatchedWorkflowKeyMap[index] : index;

                      return (
                        <Col className="d-flex psmall" lg={3} sm={6} xs={12} key={"KY-" + index}>
                          <Card>
                            <CardBody>
                              <KycBlock
                                workflow={profileWorkflow[workflowIndex]}
                                type={index}
                                content={profile.kyc}
                                header="true"
                                updateKyc={this.updateKyc}
                                userType={authData.userType}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </Col>

            <Col xs={12} className={currentTab === "3" ? "d-block" : "d-none"}>
              <Row>
                {profile.address?.addressDetailListMap
                  ? Object.keys(profile.address.addressDetailListMap).map((index) => {
                      const currentAddresses = profile.address.addressDetailListMap[index];
                      const workFlowData = profileWorkflow[index];
                      return currentAddresses.map((currentAddress, index) => {
                        return currentAddress.id ? (
                          <Col className="d-flex psmall" lg={3} sm={6} xs={12} key={"c" + currentAddress.id}>
                            <Card>
                              <CardBody>
                                <AddressBlock
                                  workflow={workFlowData}
                                  type={currentAddress.addressType}
                                  content={currentAddress}
                                  header="true"
                                  updateAddress={this.updateAddress}
                                  userType={authData.userType}
                                />
                              </CardBody>
                            </Card>
                          </Col>
                        ) : null;
                      });
                    })
                  : null}
              </Row>
            </Col>

            <Col xs={12} className={currentTab === "4" ? "d-block" : "d-none"}>
              <Row>
                {profile.contact?.contactDetailsMap
                  ? Object.keys(profile.contact.contactDetailsMap).map((index) => {
                      return (
                        <Col lg={3} sm={6} xs={12} key={index} className="d-flex psmall">
                          <Card>
                            <CardBody>
                              <ContactBlock
                                workflow={profileWorkflow[index]}
                                type={index}
                                content={profile.contact}
                                header="true"
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </Col>
            {this.props.isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    authData: state.auth,
    responseVal: state.resHandlerVal,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSuppliersProfile: (reqData) => dispatch(getSuppliersProfile(reqData)),
    updateSupplier: (reqData) => dispatch(updateSupplier(reqData)),
    activateSupplier: (reqData) => dispatch(activateSupplier(reqData)),
    updateBank: (reqData) => dispatch(updateBank(reqData)),
    updateAddress: (reqData) => dispatch(updateAddress(reqData)),
    updateKyc: (reqData, file) => dispatch(updateKyc(reqData, file)),
    resetResponseValues: () => dispatch(resetResponseValues()),
    clearProfile: () => dispatch(clearProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersProfile);
