import React from "react";
import { Row, Col, Button, ButtonToolbar } from "reactstrap";

import { connect } from "react-redux";
import { addUpdateRequest, getRequestDetail } from "../../datautils/actions/adminActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";

import { resetResponseValues, resetObjectValues } from "../../datautils/actions/responseHandlerActions";
import { PER_PAGE } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";
import GoBack from "../../components/GoBack/GoBack";
import "../../assets/css/chat.css";

class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "0",
      currentFilter: "requestedTicketStatus",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "id",
      ascending: false,
      toggle: false,

      id: 0,
      userComment: "",
      subject: "",
      details: "",
      approvalStatus: "",
      requestTicketStatus: "OPEN",
      messages: [],
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ isUpdated: false, id });
    this.props.getRequestDetail({ id });
  }

  componentDidUpdate(prevProps, prevState) {
    const { responseVal } = this.props;

    if (
      responseVal.dObjectData.requestDetail &&
      responseVal.dObjectData.requestDetail.data &&
      prevState.lastUpdated !== responseVal.dObjectData.requestDetail.data.updatedOn
    ) {
      const data = responseVal.dObjectData.requestDetail.data;

      this.setState({
        userComment: data.userComment,
        subject: data.subject,
        details: data.details,
        approvalStatus: data.approvalStatus,
        requestTicketStatus: data.requestTicketStatus,
        messages: data.messages,
        lastUpdated: data.updatedOn,
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  markRequestTicketStatus = (requestTicketStatus) => (e) => {
    const { id, userComment, subject, details, approvalStatus } = this.state;
    this.setState({ isUpdated: true });

    const reqParam = {
      requestId: id,
      userComment,
      subject,
      details,
      approvalStatus,
      requestTicketStatus,
    };

    this.props.addUpdateRequest({ requestQueueVO: reqParam });
  };

  markApprovalStatus = (approvalStatus) => (e) => {
    const { id, userComment, subject, details, requestTicketStatus } = this.state;

    this.setState({ isUpdated: true });

    const reqParam = {
      requestId: id,
      userComment,
      subject,
      details,
      approvalStatus,
      requestTicketStatus,
    };

    this.props.addUpdateRequest({ requestQueueVO: reqParam });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.addUpdateRequest(e);
    }
  };

  addUpdateRequest = (e) => {
    e.preventDefault();

    const { id, userComment, subject, details, approvalStatus, requestTicketStatus } = this.state;

    const reqParam = {
      requestId: id,
      userComment: { message: userComment },
      subject,
      details,
      approvalStatus,
      requestTicketStatus,
    };

    this.setState({ isUpdated: true });
    this.props.addUpdateRequest({ requestQueueVO: reqParam });
  };

  render() {
    const {
      responseVal,
      authData: { userId },
    } = this.props;
    const {
      isUpdated,
      userComment,
      // approvalStatus,
      requestTicketStatus,
      messages,
    } = this.state;

    const dataLoaded = responseVal.dObjectData.requestDetail?.data;

    return (
      <div>
        <PanelHeader />
        <div className="content mt-3">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}

              {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}
            </Col>

            <Col xs={12}>
              {dataLoaded ? (
                <React.Fragment>
                  <Row>
                    <Col md="7">
                      <Row>
                        <Col xs="4">
                          <GoBack text="Ticket" />

                          {/* <ButtonToolbar className="tool-right">
                      {["SUBMITTED", "ACCEPTED", "ON_HOLD"].indexOf(
                        approvalStatus
                      ) > -1 ? (
                        <Button
                          size="sm"
                          className="btn-danger mt-0 pull-right"
                          onClick={this.markApprovalStatus("REJECTED")}
                        >
                          REJECT
                        </Button>
                      ) : null}
                      {["SUBMITTED", "REJECTED", "ON_HOLD"].indexOf(
                        approvalStatus
                      ) > -1 ? (
                        <Button
                          size="sm"
                          className="btn-success mt-0 pull-right"
                          onClick={this.markApprovalStatus("ACCEPTED")}
                        >
                          ACCEPT
                        </Button>
                      ) : null}

                      {approvalStatus !== "ON_HOLD" ? (
                        <Button
                          size="sm"
                          className="btn-warning mt-0 pull-right"
                          onClick={this.markApprovalStatus("ON_HOLD")}
                        >
                          ONHOLD
                        </Button>
                      ) : null}
                    </ButtonToolbar> */}
                        </Col>
                        <Col xs="8">
                          <ButtonToolbar className="tool-right mt-0">
                            {requestTicketStatus === "CLOSED" || requestTicketStatus === "ONHOLD" ? (
                              <Button
                                size="sm"
                                className="btn-danger mt-0 pull-right"
                                onClick={this.markRequestTicketStatus("OPEN")}
                              >
                                OPEN TICKET
                              </Button>
                            ) : null}
                            {requestTicketStatus === "OPEN" || requestTicketStatus === "ONHOLD" ? (
                              <Button
                                size="sm"
                                className="btn-success mt-0 pull-right"
                                onClick={this.markRequestTicketStatus("CLOSED")}
                              >
                                CLOSE TICKET
                              </Button>
                            ) : null}

                            {requestTicketStatus !== "ONHOLD" && requestTicketStatus !== "CLOSED" ? (
                              <Button
                                size="sm"
                                className="btn-warning mt-0 pull-right"
                                onClick={this.markRequestTicketStatus("ONHOLD")}
                              >
                                ONHOLD TICKET
                              </Button>
                            ) : null}
                          </ButtonToolbar>
                        </Col>
                      </Row>
                      <div className="chat-area">
                        <div id="frame">
                          <div className="content">
                            <div className="contact-profile">
                              <dl className="row">
                                <dt className="col-sm-3">Ticket ID</dt>
                                <dd className="col-sm-9">{dataLoaded.requestId}</dd>
                                <dt className="col-sm-3">Ticket Status</dt>
                                <dd className="col-sm-9">{dataLoaded.requestTicketStatus}</dd>

                                {/* <dt className="col-sm-3">Approval Status</dt>
                                <dd className="col-sm-9">
                                  {dataLoaded.approvalStatus}
                                </dd> */}

                                <dt className="col-sm-3">Ticket Type</dt>
                                <dd className="col-sm-9">{dataLoaded.requestType}</dd>

                                <dt className="col-sm-3">Subject</dt>
                                <dd className="col-sm-9">{dataLoaded.subject}</dd>

                                <dt className="col-sm-3">Description</dt>
                                <dd className="col-sm-9">{dataLoaded.details}</dd>
                              </dl>
                            </div>
                            <div className="messages">
                              <ul>
                                {messages?.map((message, index) => {
                                  return (
                                    <li
                                      className={userId === message.userId ? "replies" : "sent"}
                                      key={"mess-" + index}
                                    >
                                      <p>{message.message}</p>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>

                            {requestTicketStatus !== "CLOSED" && (
                              <div className="message-input">
                                <div className="wrap">
                                  <input
                                    type="text"
                                    name="userComment"
                                    placeholder="Write your message..."
                                    value={userComment || ""}
                                    onChange={this.handleChange}
                                    onKeyDown={this.handleKeyDown}
                                  />

                                  <button className="submit" onClick={this.addUpdateRequest} disabled={!userComment}>
                                    <i className="now-ui-icons ui-1_send" aria-hidden="true" />
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="5">
                      <h5>Attached files with the ticket</h5>
                      {dataLoaded.medias ? (
                        <Row className="mt-2 equal">
                          {Object.keys(dataLoaded.medias).map((index) => {
                            return (
                              <Col md="6" key={index}>
                                <div className="bg-white p-2 mb-3">
                                  {" "}
                                  <ImageZoom
                                    src={getMediaPath(
                                      dataLoaded.medias[index].fileId,
                                      dataLoaded.medias[index].fileExt
                                    )}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      ) : null}
                    </Col>
                  </Row>
                </React.Fragment>
              ) : null}
              {this.props.isLoading ? <div className="loader" /> : null}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    authData: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    resetObjectValues: (object) => dispatch(resetObjectValues(object)),
    addUpdateRequest: (reqData) => dispatch(addUpdateRequest(reqData)),
    getRequestDetail: (reqData) => dispatch(getRequestDetail(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Request);
