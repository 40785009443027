import React, { Component } from "react";
import logo from "../../assets/img/slickpals-white.png";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { APP_NAME } from "../../utils/constants";
class Footer extends Component {
  state = {};

  componentDidUpdate(prevProps) {}

  goTo = () => {
    window.scroll({
      top: 0,
      left: 600,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <footer className="footer-classic context-dark bg-image">
        <div className="container">
          <div className="row top-foot">
            <div className="col-sm-3">
              <div className="pr-xl-4">
                <Link className="brand" to="/" onClick={this.goTo}>
                  <img className="brand-logo-light" src={logo} alt="" width="100" />
                </Link>
                <p className="mt-1">A wholesale marketplace providing platform for customers and sellers</p>
              </div>
            </div>
            <div className="col-sm-3">
              <h5>Contacts</h5>
              <dl className="contact-list">
                <dd>info@slickpals.com</dd>
              </dl>
            </div>
            <div className="col-sm-3">
              <h5>Other Info</h5>
              <dl className="contact-list">
                <dd>Hello other</dd>
              </dl>
            </div>{" "}
            <div className="col-sm-3 no-bdr">
              <h5>Social</h5>
              <dl className="contact-list">
                <dd>Social icons</dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="copy-bar">
          <div className="container">
            <Row className="py-3 py-sm-0">
              <Col sm="6">
                <p className="lefts text-center text-md-left">
                  <Link to="/about" onClick={this.goTo}>
                    About
                  </Link>
                  <Link to="/privacy-policy" onClick={this.goTo}>
                    Privacy
                  </Link>
                  <Link to="/terms-of-service" onClick={this.goTo}>
                    Terms
                  </Link>
                  <Link to="/contact" onClick={this.goTo}>
                    Contact
                  </Link>
                  <Link to="/cookies" onClick={this.goTo}>
                    Cookies
                  </Link>
                </p>
              </Col>
              <Col sm="6">
                <p className="rights text-center text-md-right pt-3 pt-sm-0">
                  <span>{APP_NAME} </span>
                  <span> © </span>
                  <span className="copyright-year">{new Date().getFullYear()}</span>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
