import React from "react";
import { Row, Col, FormGroup, Card, CardBody, Button, Table, ButtonToolbar } from "reactstrap";

import { connect } from "react-redux";
import { searchCustomer } from "../../datautils/actions/orderActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { ADMIN_CUSTOMER_VIEW } from "../../utils/constants/clientUrls";

import { sortIcon } from "../../utils/constants/icons";
import { PER_PAGE } from "../../utils/constants/index";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import moment from "moment";

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "customerUserId",
      ascending: false,
      currentTab: "",
    };
  }

  componentDidMount() {
    this.searchCustomer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchCustomer();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.searchCustomer()
      );
    }
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchCustomer()
    );
  };
  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.searchCustomer()
    );
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  filterData = (e) => {
    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
      },
      () => this.searchCustomer()
    );
  };

  searchCustomer = () => {
    const { globalSearchKeyword, currentTab, pageNumber, pageSize, sortField, ascending } = this.state;

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [{ key: "status", operation: "EQUAL_TO", value: currentTab }],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.props.searchCustomer(reqData);
  };

  handleChange = (input) => () => {
    this.props.history.push(ADMIN_CUSTOMER_VIEW + input);
  };

  changePage = (page) => () => {
    this.setState({ pageNumber: page });
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.customerList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  render() {
    const { responseVal } = this.props;
    const { globalSearchKeyword, ascending, pageNumber, pageSize, currentTab } = this.state;

    const dataLoaded = responseVal.dObjectData?.customerList?.data?.customers;

    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}
            </Col>
            <Col xs={12}>
              <ButtonToolbar>
                {" "}
                <Button size="sm" value="1" onClick={this.filterData} color={currentTab === "1" ? "primary" : ""}>
                  ACTIVE
                </Button>
                <Button size="sm" value="0" onClick={this.filterData} color={currentTab === "0" ? "primary" : ""}>
                  INACTIVE
                </Button>
                <Button size="sm" value="" onClick={this.filterData} color={currentTab === "" ? "primary" : ""}>
                  ALL
                </Button>
              </ButtonToolbar>
            </Col>
            <Col xs={12}>
              <Card>
                <CardBody className="listings">
                  <React.Fragment>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search Customer..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button color="primary" onClick={this.searchButton}>
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="8">{dataLoaded ? this.renderPagination() : ""}</Col>
                    </Row>

                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>
                          <th onClick={this.sortButton("customerUserId", ascending ? false : true)}>
                            CID <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>
                          <th>Title</th>
                          <th onClick={this.sortButton("firstName", ascending ? false : true)}>
                            First Name <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>

                          <th onClick={this.sortButton("lastName", ascending ? false : true)}>
                            Last Name <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>
                          <th onClick={this.sortButton("mobileNumber", ascending ? false : true)}>
                            Mobile <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>
                          <th>Email </th>
                          <th>Registered On</th>
                          <th>Updated On</th>
                          <th>Status</th>
                          <th>Verified</th>

                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLoaded ? (
                          dataLoaded.length ? (
                            dataLoaded.map((row, index) => {
                              const cname =
                                row.status === "ACTIVE"
                                  ? "success"
                                  : row.status === "INACTIVE"
                                  ? "danger"
                                  : row.status === "ONHOLD"
                                  ? "warning"
                                  : "primary";
                              return (
                                <tr className="cursor" key={index} onClick={this.handleChange(row.customerUserId)}>
                                  <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>
                                  <td>{row.customerUserId}</td>
                                  <td>{row.title} </td>
                                  <td>{row.firstName} </td>

                                  <td>{row.lastName}</td>
                                  <td>{row.mobileNumber}</td>
                                  <td>{row.email}</td>

                                  <td>{moment(row.createdOn).format("DD MMM, YYYY, hh:mm a")}</td>
                                  <td>{moment(row.updatedOn).format("DD MMM, YYYY, hh:mm a")}</td>
                                  <td>{row.status}</td>
                                  <td>
                                    {row.verified ? (
                                      <span className="text-success">YES</span>
                                    ) : (
                                      <span className="text-danger">NO</span>
                                    )}
                                  </td>

                                  <td className="text-right">
                                    <Button className={`btn-simple btn-light btn-${cname}`} size="xs">
                                      View
                                    </Button>{" "}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="12" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="12" />
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {this.props.isLoading ? <div className="loader" /> : null}
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchCustomer: (reqData) => dispatch(searchCustomer(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
