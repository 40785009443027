import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getProfile } from "../../datautils/actions/userActions";
import { fetchHomeStats, fetchCountStats } from "../../datautils/actions/statsActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import {
  SUPPLIER_PROFILE,
  SUPPLIER_ORDERS_LIST,
  SUPPLIER_SKU_LIST,
  SUPPLIER_REQUEST_LIST,
} from "../../utils/constants/clientUrls";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import Statistics from "../../components/Statistics/Statistics";
import { Line, Pie } from "react-chartjs-2";

// const dataBar = {
//   labels: ["January", "February", "March", "April", "May", "June", "July"],
//   datasets: [
//     {
//       label: "Sku",
//       backgroundColor: "rgba(255,99,132,0.2)",
//       borderColor: "rgba(255,99,132,1)",
//       borderWidth: 1,
//       hoverBackgroundColor: "rgba(255,99,132,0.4)",
//       hoverBorderColor: "rgba(255,99,132,1)",
//       data: [65, 59, 80, 81, 56, 55, 40],
//     },
//   ],
// };

// const dataLine = {
//   labels: ["January", "February", "March", "April", "May", "June", "July"],
//   datasets: [
//     {
//       label: "Orders",
//       fill: false,
//       lineTension: 0.1,
//       backgroundColor: "rgba(75,192,192,0.4)",
//       borderColor: "rgba(75,192,192,1)",
//       borderCapStyle: "butt",
//       borderDash: [],
//       borderDashOffset: 0.0,
//       borderJoinStyle: "miter",
//       pointBorderColor: "rgba(75,192,192,1)",
//       pointBackgroundColor: "#fff",
//       pointBorderWidth: 1,
//       pointHoverRadius: 5,
//       pointHoverBackgroundColor: "rgba(75,192,192,1)",
//       pointHoverBorderColor: "rgba(220,220,220,1)",
//       pointHoverBorderWidth: 2,
//       pointRadius: 1,
//       pointHitRadius: 10,
//       data: [25, 39, 30, 51, 46, 65, 60],
//     },
//   ],
// };

// const dataPie = {
//   labels: ["Default Order", "Sample Order", "Bargain Order"],
//   datasets: [
//     {
//       data: [300, 50, 10],
//       backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//       hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//     },
//   ],
// };

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    this.props.getProfile(this.props.authData);

    //bucketsCount - 7
    //statsCategory - ORDER,SUPPLIER,SKU
    //statsType - TOTAL_COUNT,HISTOGRAM_WEEK,HISTOGRAM_MONTH,HISTOGRAM_DAY,HISTOGRAM_YEAR

    this.props.fetchHomeStats({
      statsType: "HISTOGRAM_WEEK",
      statsCategory: "ORDER",
    });

    this.props.fetchCountStats({});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.newSupplier) {
      this.props.history.push(SUPPLIER_PROFILE);
    }
  }

  generateOrderGraph = () => {
    const {
      statsItems: { graphStats },
    } = this.props;
    const json = graphStats?.ORDER && JSON.parse(graphStats.ORDER);
    const orderStats = json?.order_stats && json.order_stats.buckets;

    let labels = [];
    let data = [];

    let totalDefault = 0;
    let totalSample = 0;
    let totalBargain = 0;

    orderStats?.forEach((o) => {
      labels.push(o.key_as_string);
      data.push(o.doc_count);

      o.orderTypes?.buckets?.forEach((b) => {
        if (b.key === "DEFAULT") {
          totalDefault = totalDefault + b.doc_count;
        }
        if (b.key === "SAMPLE") {
          totalSample = totalSample + b.doc_count;
        }
        if (b.key === "BARGAIN") {
          totalBargain = totalBargain + b.doc_count;
        }
      });
    });

    const dataOrder = {
      labels: labels,
      datasets: [
        {
          label: "Orders",
          fill: "origin",
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: data,
        },
      ],
    };

    const dataPie = {
      labels: [
        "Default Order (" + (totalDefault || 0) + ")",
        "Sample Order (" + (totalSample || 0) + ")",
        "Bargain Order (" + (totalBargain || 0) + ")",
      ],
      datasets: [
        {
          data: [totalDefault || 0, totalSample || 0, totalBargain || 0],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    };

    return { dataOrder, dataPie };
  };

  getLink = (type) => {
    switch (type) {
      case "ORDERS":
        return SUPPLIER_ORDERS_LIST;
      case "SKUS":
        return SUPPLIER_SKU_LIST;
      case "REQUESTS":
        return SUPPLIER_REQUEST_LIST;
      default:
        return "/supplier";
    }
  };

  render() {
    const { profile, statsItems } = this.props;
    const workflow = profile && profile.workflow;

    const dataOrderGraph = this.generateOrderGraph();
    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row className="mt-3"></Row>

          <div style={{ padding: 5 }}>
            <Row className="mt-0">
              {statsItems?.countStats?.map((s) => {
                return (
                  <Col key={"box" + s.id} sm={3} md={2} xs={6} className="psmall d-flex aflx">
                    <Link to={this.getLink(s.id)}>
                      <Statistics title={s.displayName} subtitle={s.activeCount} />
                    </Link>
                  </Col>
                );
              })}
            </Row>
            <Row>
              {workflow?.overAllState !== "COMPLETE" ? (
                <Col md={4} xs={12} className="mb-1 psmall">
                  <Card className="card-chart  h-100">
                    <div className="card-header">
                      <h5 className="card-category">Account Health</h5>
                    </div>
                    <CardBody>
                      <Row>
                        <Col sm={12}>
                          {workflow &&
                            Object.keys(workflow.workflowStatusMap).map((index) => {
                              const object = workflow.workflowStatusMap[index];

                              return (
                                <div key={"w-1" + index} className="aa">
                                  {Object.keys(object).map((index2) => {
                                    const object2 = object[index2];

                                    return (
                                      <div key={"w-2-" + index2}>
                                        {object2.overAllState === "SUBMITTED" ? (
                                          <SuccessBlock
                                            msg={{
                                              userDisplayMsg:
                                                (index2 === "MY_ACCOUNT"
                                                  ? "BASIC PROFILE"
                                                  : index2?.replace(/_/g, " ")) + " is submitted for verification",
                                            }}
                                            color="warning"
                                            position="alert-relative"
                                          />
                                        ) : object2.overAllState === "INCOMPLETE" ? (
                                          <SuccessBlock
                                            msg={{
                                              userDisplayMsg:
                                                (index2 === "MY_ACCOUNT"
                                                  ? "BASIC PROFILE"
                                                  : index2?.replace(/_/g, " ")) +
                                                " is incomplete, Please complete it for your account approval",
                                            }}
                                            color="danger"
                                            position="alert-relative"
                                          />
                                        ) : object2.overAllState === "REJECTED" ? (
                                          <SuccessBlock
                                            msg={{
                                              userDisplayMsg:
                                                (index2 === "MY_ACCOUNT"
                                                  ? "BASIC PROFILE"
                                                  : index2?.replace(/_/g, " ")) +
                                                " is rejected. Please update and resubmit",
                                            }}
                                            color="danger"
                                            position="alert-relative"
                                          />
                                        ) : object2.overAllState === "ON_HOLD" ? (
                                          <SuccessBlock
                                            msg={{
                                              userDisplayMsg:
                                                (index2 === "MY_ACCOUNT"
                                                  ? "BASIC PROFILE"
                                                  : index2?.replace(/_/g, " ")) + " is on hold",
                                            }}
                                            color="danger"
                                            position="alert-relative"
                                          />
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          {profile.profileInfo && !profile.profileInfo.verified ? (
                            <SuccessBlock
                              msg={{
                                userDisplayMsg: "Your Account is incomplete or not verified",
                              }}
                              color="danger"
                              position="alert-relative"
                            />
                          ) : null}
                        </Col>
                      </Row>{" "}
                    </CardBody>
                    <div className="card-footer">
                      <div className="stats">&nbsp;</div>
                    </div>
                  </Card>
                </Col>
              ) : null}
              <Col md={4} xs={12} className="mb-1 psmall">
                <Card className="card-chart h-100">
                  <div className="card-header">
                    <h5 className="card-category">Type of Order</h5>
                  </div>
                  <CardBody>
                    <Pie
                      data={dataOrderGraph?.dataPie}
                      options={{
                        legend: {
                          position: "right",
                        },
                      }}
                    />
                  </CardBody>
                  <div className="card-footer">
                    <div className="stats">
                      <i className="now-ui-icons ui-2_time-alarm" /> Last 7 Weeks
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md={4} xs={12} className="mb-1 psmall">
                <Card className="card-chart h-100">
                  <div className="card-header">
                    <h5 className="card-category">Orders per week</h5>
                  </div>
                  <CardBody>
                    <Line
                      data={dataOrderGraph?.dataOrder}
                      options={{
                        legend: {
                          display: false,
                        },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                max: 6,
                                min: 0,
                                stepSize: 2,
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </CardBody>
                  <div className="card-footer">
                    <div className="stats">
                      <i className="now-ui-icons ui-2_time-alarm" /> Last 7 Weeks
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          {this.props.isLoading ? <div className="loader" /> : ""}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newSupplier: state.profile.newSupplier,
    isLoading: state.ui.isLoading,
    authData: state.auth,
    profile: state.profile,
    statsItems: state.statsItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (reqData) => dispatch(getProfile(reqData)),
    fetchHomeStats: (reqData) => dispatch(fetchHomeStats(reqData)),
    fetchCountStats: (reqData) => dispatch(fetchCountStats(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
