import {
  SET_VALUES,
  RESET_VALUES,
  RESET_OBJECT,
  SAVE_MEDIA_LIST,
  CLEAR_MEDIA_LIST,
  REMOVE_MEDIA_LIST,
  SAVE_MEDIA_OBJECT,
  CLEAR_MEDIA_OBJECT,
  REMOVE_MEDIA_OBJECT,
} from "./actionTypes";

export const resetResponseValues = () => {
  return {
    type: RESET_VALUES,
  };
};

export const resetObjectValues = (objectType) => {
  return {
    type: RESET_OBJECT,
    objectType: objectType,
  };
};

export const setResponseValues = (status, data, errors, objectType) => {
  data = { ...data, lastUpdated: new Date().getTime() };

  if (status === "failure" && !errors[0]) {
    errors = [{ userDisplayMsg: "Something went wrong, Try again" }];
  }
  return {
    type: SET_VALUES,
    status: status,
    data: data,
    objectType: objectType,
    errors: errors,
  };
};

export const storeMediaList = (data) => {
  return {
    type: SAVE_MEDIA_LIST,
    data: data,
  };
};

export const removeMediaList = (data) => {
  return {
    type: REMOVE_MEDIA_LIST,
    data: data,
  };
};

export const clearMediaList = () => {
  return {
    type: CLEAR_MEDIA_LIST,
  };
};

export const storeMediaObject = (data) => {
  return {
    type: SAVE_MEDIA_OBJECT,
    data: data,
  };
};

export const removeMediaObject = (data) => {
  return {
    type: REMOVE_MEDIA_OBJECT,
    data: data,
  };
};

export const clearMediaObject = () => {
  return {
    type: CLEAR_MEDIA_OBJECT,
  };
};
