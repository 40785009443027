import { SAVE_RESPONSE_MESSAGE, CLEAR_RESPONSE_MESSAGE, CLEAR_RESPONSE_MESSAGES } from "../actions/actionTypes";

const initialState = {
  data: {},
};

const responseMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_RESPONSE_MESSAGE:
      return {
        ...state,
        data: action.objectType ? { ...state.data, [action.objectType]: action.data } : { ...state.data },
      };
    case CLEAR_RESPONSE_MESSAGE:
      // const filtered = Object.keys(state.data).filter(
      //   (obj) => obj !== action.objectType || obj !== "commonerror"
      // );
      delete state.data[action.objectType];

      return {
        ...state,
        //data: action.objectType ? { ...filtered } : { ...state.data },
        data: { ...state.data },
      };
    case CLEAR_RESPONSE_MESSAGES:
      return {
        data: {},
      };
    default:
      return state;
  }
};

export default responseMessageReducer;
