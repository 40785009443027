export const SUPPLIER_BASE = "/supplier";
export const ADMIN_BASE = "/admin";

export const NOT_AUTHORISED = "/not-authorized";
export const LOGIN = "/login";
export const REGISTER = "/register";

export const SUPPLIER_SKU_LIST = SUPPLIER_BASE + "/skus";
export const SUPPLIER_SKU_ADD = SUPPLIER_BASE + "/sku/new";
export const SUPPLIER_SKU_VIEW = SUPPLIER_BASE + "/sku/";
export const SUPPLIER_PROFILE = SUPPLIER_BASE + "/profile";
export const SUPPLIER_PASSWORD = SUPPLIER_BASE + "/password";
export const SUPPLIER_REQUEST_LIST = SUPPLIER_BASE + "/requests";
export const SUPPLIER_REQUEST_VIEW = SUPPLIER_BASE + "/request/";
export const SUPPLIER_ORDERS_LIST = SUPPLIER_BASE + "/orders";
export const SUPPLIER_ORDER_VIEW = SUPPLIER_BASE + "/order/";
export const SUPPLIER_PRODUCT_VIEW = SUPPLIER_BASE + "/product/";
export const SUPPLIER_SAMPLE_LIST = SUPPLIER_BASE + "/samples";
export const SUPPLIER_SAMPLE_VIEW = SUPPLIER_BASE + "/sample/";
export const SUPPLIER_CUSTOM_LIST = SUPPLIER_BASE + "/customoffers";
export const SUPPLIER_CUSTOM_VIEW = SUPPLIER_BASE + "/customoffer/";
export const SUPPLIER_BILL_LIST = SUPPLIER_BASE + "/billbook";
export const SUPPLIER_BILL_VIEW = SUPPLIER_BASE + "/billbook/";
export const SUPPLIER_USERS_LIST = SUPPLIER_BASE + "/users";
export const SUPPLIER_USERS_DETAIL = SUPPLIER_BASE + "/users/";
export const SUPPLIER_ACTIVITY_VIEW = SUPPLIER_BASE + "/activity/";
export const SUPPLIER_KYC = SUPPLIER_PROFILE + "/kyc";
export const SUPPLIER_ADDRESS = SUPPLIER_PROFILE + "/address";
export const SUPPLIER_CONTACT = SUPPLIER_PROFILE + "/contact";
export const SUPPLIER_ORDER_REQUEST_LIST = SUPPLIER_BASE + "/order-requests";
export const SUPPLIER_ORDER_REQUEST_VIEW = SUPPLIER_BASE + "/order-request/";

export const ADMIN_PASSWORD = ADMIN_BASE + "/password";
export const ADMIN_CATEGORIES = ADMIN_BASE + "/categories";
export const ADMIN_SKU_LIST = ADMIN_BASE + "/skus";
export const ADMIN_SKU_VIEW = ADMIN_BASE + "/sku/";
export const ADMIN_PRODUCT_LIST = ADMIN_BASE + "/products";
export const ADMIN_PRODUCT_VIEW = ADMIN_BASE + "/product/";
export const ADMIN_SUPPLIER_LIST = ADMIN_BASE + "/suppliers";
export const ADMIN_SUPPLIER_VIEW = ADMIN_BASE + "/suppliers/";
export const ADMIN_CAMPAIGN_LIST = ADMIN_BASE + "/campaigns";
export const ADMIN_CAMPAIGN_VIEW = ADMIN_BASE + "/campaign/";
export const ADMIN_COUPON_LIST = ADMIN_BASE + "/coupons";
export const ADMIN_COUPON_VIEW = ADMIN_BASE + "/coupon/";
export const ADMIN_BRAND_LIST = ADMIN_BASE + "/brands";
export const ADMIN_REQUEST_LIST = ADMIN_BASE + "/requests";
export const ADMIN_REQUEST_VIEW = ADMIN_BASE + "/request/";
export const ADMIN_ORDERS_LIST = ADMIN_BASE + "/orders";
export const ADMIN_ORDER_VIEW = ADMIN_BASE + "/order/";
export const ADMIN_CUSTOMER_LIST = ADMIN_BASE + "/customers";
export const ADMIN_CUSTOMER_VIEW = ADMIN_BASE + "/customer/";
export const ADMIN_SUPPLIERR_VIEW = ADMIN_BASE + "/supplier/";
export const ADMIN_ATTRIBUTES_LIST = ADMIN_BASE + "/attributes/";
export const ADMIN_TREND_LIST = ADMIN_BASE + "/trends";
export const ADMIN_TREND_VIEW = ADMIN_BASE + "/trend";
export const ADMIN_MEGAMENU = ADMIN_BASE + "/menu";
export const ADMIN_SAMPLE_LIST = ADMIN_BASE + "/samples";
export const ADMIN_SAMPLE_VIEW = ADMIN_BASE + "/sample/";
export const ADMIN_CUSTOM_LIST = ADMIN_BASE + "/customoffers";
export const ADMIN_CUSTOM_VIEW = ADMIN_BASE + "/customoffer/";
export const ADMIN_BILL_LIST = ADMIN_BASE + "/billbook";
export const ADMIN_BILL_VIEW = ADMIN_BASE + "/billbook/";
export const ADMIN_OPS_USERS_LIST = ADMIN_BASE + "/users";
export const ADMIN_OPS_USERS_DETAIL = ADMIN_BASE + "/users/";
export const ADMIN_ACTIVITY_VIEW = ADMIN_BASE + "/activity/";
export const ADMIN_LD_USERS_LIST = ADMIN_BASE + "/ldusers";
export const ADMIN_ORDER_REQUEST_LIST = ADMIN_BASE + "/order-requests";
export const ADMIN_ORDER_REQUEST_VIEW = ADMIN_BASE + "/order-request/";
export const ADMIN_TEMP_SKU_LIST = ADMIN_BASE + "/temp-skus";
export const ADMIN_TEMP_SKU_VIEW = ADMIN_BASE + "/temp-sku/";

export const routeAllowed = {
  ADMIN_CUSTOMER_LIST: ["OPS_ADMIN", "OPS_FINANCE", "OPS_CATALOG_MANAGER", "OPS_ORDER_MANAGER", "OPS_CUSTOMER_CARE"],
  ADMIN_ORDER_REQUEST_LIST: [
    "OPS_ADMIN",
    "OPS_FINANCE",
    "OPS_CATALOG_MANAGER",
    "OPS_ORDER_MANAGER",
    "OPS_CUSTOMER_CARE",
  ],
  ADMIN_TEMP_SKU_LIST: ["OPS_ADMIN", "OPS_FINANCE", "OPS_CATALOG_MANAGER", "OPS_ORDER_MANAGER", "OPS_CUSTOMER_CARE"],
  SUPPLIER_USERS_LIST: ["SUPPLIER_SUPPLIER_ADMIN"],
  SUPPLIER_ORDER_REQUEST_LIST: ["SUPPLIER_SUPPLIER_ADMIN", "SUPPLIER_SUPPLIER_MANAGER", "SUPPLIER_SUPPLIER_EMPLOYEE"],
};
