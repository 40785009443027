import React from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  CustomInput,
} from "reactstrap";
import { connect } from "react-redux";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { getCategories, uploadImages } from "../../datautils/actions/skuActions";
import CSVReader from "react-csv-reader";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import { getMediaPath, getFilteredAuthData } from "../../utils/helpers/HelperFunctions";
import Select from "react-select";
import Dropzone from "../../components/Dropzone/Dropzone";

import {
  addCategory,
  addBulkCategory,
  searchCategory,
  addGST,
  addConfig,
  addCommission,
  activateDeactivate,
  getExistingAttribute,
  attachCategoryAttribute,
  addUpdateCategoryMedia,
} from "../../datautils/actions/categoryActions";
import Attributes from "./Attributes";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import { variantOptions } from "../../utils/constants/index";
const options = [
  { value: "PERCENTAGE", label: "PERCENTAGE" },
  { value: "ABSOLUTE", label: "ABSOLUTE" },
];

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal2: false,
      currentTitle: "",
      currentAction: "",
      currentActionType: "",
      notes: "",
      level1: "",
      level2: "",
      level3: "",
      level4: "",
      level5: "",
      level6: "",
      category: "",
      selectedCatId: "",
      lastCatId: "",
      chain: [],
      currentTab: "0",
      isUpdated: false,
      mainTitle: "Add new category",
      commissionType: "PERCENTAGE",
      currentObject: null,
      setAttributes: false,
      newAttributes: [],
      files: [],
      variantTypes: [],
      uniquePrimaryFieldKeys: [],
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggle2 = (requestData) => {
    if (this.state.modal2) {
      this.setState({
        modal2: false,
        notes: "",
        currentTitle: "",
        currentAction: "",
        currentActionType: "",
        currentAttrKey: "",
      });
    } else {
      this.setState({
        modal2: true,
        notes: "",
        currentTitle: requestData.title,
        currentAction: requestData.action,
        currentActionType: requestData.actionType,
        currentAttrKey: requestData.key,
        isUpdated: false,
      });
      this.props.resetResponseValues();
    }
  };

  componentDidMount() {
    this.props.getCategories({});
  }

  componentDidUpdate(prevProps, prevState) {
    const { categories, responseVal } = this.props;
    const { modal, modal2, level1, isUpdated, level2Id, level3Id, level4Id, level5Id, level6Id, level7Id } = this.state;

    if (responseVal.status === "success" && isUpdated && (modal || modal2)) {
      this.setState({ modal: false, modal2: false });
    }

    if (categories && categories.categories && !level1) {
      this.setState({
        level1: categories.categories,
        lastUpdated: categories.lastUpdated,
      });
    }

    if (prevState.lastUpdated !== categories.lastUpdated) {
      /** Update selected category chain if any */
      let dynamicObject = {};

      if (level2Id && level2Id.length > 0) {
        let l2Object = categories && categories?.categories.find((c) => c.id === level2Id);

        dynamicObject = {
          ...dynamicObject,
          level2: l2Object && l2Object.subs,
          currentObject: l2Object,
        };

        if (level3Id && level3Id.length > 0) {
          let l3Object = dynamicObject["level2"] && dynamicObject["level2"].find((c) => c.id === level3Id);

          dynamicObject = {
            ...dynamicObject,
            level3: l3Object && l3Object.subs,
            currentObject: l3Object,
          };

          if (level4Id && level4Id.length > 0) {
            let l4Object = dynamicObject["level3"] && dynamicObject["level3"].find((c) => c.id === level4Id);

            dynamicObject = {
              ...dynamicObject,
              level4: l4Object && l4Object.subs,
              currentObject: l4Object,
            };
          }

          if (level5Id && level5Id.length > 0) {
            let l5Object = dynamicObject["level4"] && dynamicObject["level4"].find((c) => c.id === level5Id);

            dynamicObject = {
              ...dynamicObject,
              level5: l5Object && l5Object.subs,
              currentObject: l5Object,
            };

            if (level6Id && level6Id.length > 0) {
              let l6Object = dynamicObject["level5"] && dynamicObject["level5"].find((c) => c.id === level6Id);

              dynamicObject = {
                ...dynamicObject,
                level6: l6Object && l6Object.subs,
                currentObject: l6Object,
              };

              if (level7Id && level7Id.length > 0) {
                let l7Object = dynamicObject["level6"] && dynamicObject["level6"].find((c) => c.id === level7Id);

                dynamicObject = {
                  ...dynamicObject,
                  level7: l7Object && l7Object.subs,
                  currentObject: l7Object,
                };
              }
            }
          }
        }
      }

      /** *** *** *** *** *** *** *** *** *** **/

      this.setState({
        level1: categories.categories,
        lastUpdated: categories.lastUpdated,
        category: "",
        modal: false,
        modal2: false,
        ...dynamicObject,
      });
    }

    if (prevProps.responseVal.dObjectData.existingAttributes && responseVal.dObjectData.existingAttributes) {
      if (
        prevProps.responseVal.dObjectData.existingAttributes?.data &&
        responseVal.dObjectData.existingAttributes?.data &&
        Object.keys(prevProps.responseVal.dObjectData.existingAttributes.data).length !==
          Object.keys(responseVal.dObjectData.existingAttributes.data).length
      ) {
        this.setState({ newAttributes: [], modal: false, modal2: false });
      }
    }

    if (this.state.latestEntry) {
      setTimeout(() => {
        this.setState({ latestEntry: "" });
      }, 1000);
    }
  }

  attachAttribute = () => {
    this.setState({ isUpdated: true });
    this.props.attachCategoryAttribute({
      iRefId: this.state.currentObject.iRefId,
      attributes: this.state.newAttributes,
    });
  };

  handleAttribute = (object) => (e) => {
    const { newAttributes } = this.state;

    const index = newAttributes.findIndex((k) => k.key === object.attributeKey);

    if (index === -1) {
      this.setState({
        latestEntry: object.attributeKey,
        newAttributes: [
          ...newAttributes,
          {
            key: object.attributeKey,
            name: object.attributeName,
            mandatory: false,
          },
        ],
      });
    }
  };

  handleChangeSelect = (obj, opt) => {
    this.setState({ [obj]: opt.value });
  };

  handleChangeNotes = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleChangeMulti = (obj, opt) => {
    const extract = opt?.map((o) => o.label);
    this.setState({ [obj]: extract });
  };

  tabActions = (e) => {
    this.props.resetResponseValues();
    if (e.target.dataset.action === "refresh") {
      this.props.getCategories({});
    }

    this.setState({
      currentTab: e.target.dataset.value,
      mainTitle: e.target.dataset.title,
      // level1: "",
      // level2: "",
      // level3: "",
      // level4: "",
      // level5: "",
      // level6: "",
      // level1Id: "",
      // level2Id: "",
      // level3Id: "",
      // level4Id: "",
      // level5Id: "",
      // level6Id: "",
      // chain: [],
      // category: "",
      isUpdated: false,
      setAttributes: false,
      newAttributes: [],
      files: [],
    });
  };

  handleCheckbox = (key) => (e) => {
    let checked = e.target.checked;
    const newAttributes = this.state.newAttributes.map((el) => (el.key === key ? { ...el, mandatory: checked } : el));
    this.setState({ newAttributes });
  };

  handleCheckboxUpdate = (key) => (e) => {
    let checked = e.target.checked;
    const reqParam = [
      {
        key,
        mandatory: checked,
      },
    ];
    this.props.attachCategoryAttribute({
      iRefId: this.state.currentObject.iRefId,
      attributes: reqParam,
    });
    this.setState({ isUpdated: true });
  };

  viewAttributes = () => {
    this.props.getExistingAttribute({
      iRefId: this.state.currentObject.iRefId,
    });

    this.setState({ setAttributes: true, modal: false, isUpdated: false });
  };

  handleCats = (input) => (e) => {
    this.setState({ category: input });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.props.searchCategory({ category: this.state.category });
    }
  };

  searchCat = () => {
    this.props.searchCategory({ category: this.state.category });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  chooseCategory = (e) => {
    this.setState({
      chain: [...this.state.chain, this.state.category],
      modal: false,
      isUpdated: false,
    });
  };

  renderModal2 = () => {
    const {
      notes,
      currentAction,
      currentActionType,
      currentTitle,
      currentObject,
      commissionType,
      variantTypes,
      uniquePrimaryFieldKeys,
    } = this.state;

    return (
      <Modal backdrop="static" centered isOpen={this.state.modal2} toggle={this.toggle2}>
        <ModalHeader toggle={this.toggle2}>{currentTitle.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <Form>
                    {currentObject && currentAction === "addGST" ? (
                      <p className="mt-3 mb-5">
                        Existing GST Rate for {currentObject.name}: <strong>{currentObject.gstRate}</strong>
                      </p>
                    ) : currentObject && currentAction === "addComm" ? (
                      <>
                        <p className="mt-3 mb-5">
                          Existing Commission for {currentObject.name}: <strong>{currentObject.commissionValue}</strong>
                          <br />
                          Commission Type: <strong>{currentObject.commissionType}</strong>
                        </p>
                        <FormGroup className="mb-4">
                          <Select
                            isSearchable={false}
                            className="react-select"
                            name="commissionType"
                            options={options}
                            value={options.filter((option) => option.value === commissionType)}
                            onChange={(opt) => this.handleChangeSelect("commissionType", opt)}
                          />
                        </FormGroup>
                      </>
                    ) : null}
                    <FormGroup className="mb-5">
                      {currentAction === "removeAttr" ? (
                        <p>To mark the attribute {currentActionType} click the button below</p>
                      ) : currentAction === "activateDeactivateCategory" ? (
                        <Input
                          type="textarea"
                          placeholder="Add notes/reason"
                          onChange={this.handleChangeNotes("notes")}
                          value={notes}
                        />
                      ) : currentAction === "enableDisableImage" ? (
                        <Input
                          type="textarea"
                          placeholder="Add notes/reason"
                          onChange={this.handleChangeNotes("notes")}
                          value={notes}
                        />
                      ) : currentAction === "addConfig" ? (
                        <>
                          <small>Add variant types (Multiple variants can be selected)</small>
                          <Select
                            placeholder="Add variant types"
                            defaultValue={variantOptions.filter((option) => variantTypes?.includes(option.value))}
                            isMulti
                            isSearchable={false}
                            isClearable={false}
                            name="variantTypes"
                            classNamePrefix="select"
                            onChange={(opt) => this.handleChangeMulti("variantTypes", opt)}
                            options={variantOptions}
                          />
                          {/* <Input
                            type="textarea"
                            placeholder="Add variant type"
                            onChange={this.handleChangeNotes("variantTypes")}
                            value={variantTypes}
                          /> */}
                          <p></p>
                          <small>Add unique primary key fields (Multiple primary fields can be selected)</small>

                          <Select
                            placeholder="Add unique primary field keys"
                            defaultValue={variantOptions.filter(
                              (option) => uniquePrimaryFieldKeys && uniquePrimaryFieldKeys.includes(option.value)
                            )}
                            isMulti
                            isSearchable={false}
                            isClearable={false}
                            name="uniquePrimaryFieldKeys"
                            classNamePrefix="select"
                            onChange={(opt) => this.handleChangeMulti("uniquePrimaryFieldKeys", opt)}
                            options={variantOptions}
                          />
                          {/* <Input
                            type="textarea"
                            placeholder="Add unique primary field keys"
                            onChange={this.handleChangeNotes(
                              "uniquePrimaryFieldKeys"
                            )}
                            value={uniquePrimaryFieldKeys}
                          /> */}
                        </>
                      ) : (
                        <Input
                          step="0.01"
                          type="number"
                          min="0"
                          placeholder={currentTitle}
                          onChange={this.handleChangeNotes("notes")}
                          value={notes}
                        />
                      )}
                    </FormGroup>

                    {this.renderSwitch(currentActionType, currentAction)}
                  </Form>
                </div>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  };

  renderSwitch(currentActionType, action) {
    const { notes, currentAttrKey } = this.state;

    if (action === "enableDisableImage") {
      switch (currentActionType) {
        case "ACTIVE":
          return (
            <Button
              className="btn-block btn-success"
              data-action="ACTIVE"
              onClick={this.enableDisableImage(currentAttrKey, "ACTIVE")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Activate
            </Button>
          );

        case "INACTIVE":
          return (
            <Button
              className="btn-block btn-danger"
              data-action="INACTIVE"
              onClick={this.enableDisableImage(currentAttrKey, "INACTIVE")}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Deactivate
            </Button>
          );

        default:
          return null;
      }
    } else if (action === "activateDeactivateCategory") {
      switch (currentActionType) {
        case "ACTIVATE":
          return (
            <Button
              className="btn-block btn-success"
              data-action="ACTIVATE"
              onClick={this.activateDeactivate}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Activate
            </Button>
          );

        case "DEACTIVATE":
          return (
            <Button
              className="btn-block btn-danger"
              data-action="DEACTIVATE"
              onClick={this.activateDeactivate}
              disabled={notes?.replace(/\s+/g, "")?.length < 1}
            >
              Deactivate
            </Button>
          );

        default:
          return null;
      }
    } else if (action === "removeAttr") {
      switch (currentActionType) {
        case "ACTIVE":
          return (
            <Button
              className="btn-block btn-success"
              data-action="ACTIVE"
              onClick={this.activateDeactivateAttribute(currentAttrKey, "ACTIVE")}
            >
              Activate
            </Button>
          );

        case "INACTIVE":
          return (
            <Button
              className="btn-block btn-danger"
              data-action="INACTIVE"
              onClick={this.activateDeactivateAttribute(currentAttrKey, "INACTIVE")}
            >
              Deactivate
            </Button>
          );

        default:
          return null;
      }
    } else if (action === "addGST") {
      return (
        <>
          <Button
            className="btn-block btn-success"
            onClick={this.addGST}
            disabled={notes?.replace(/\s+/g, "")?.length < 1}
          >
            Add GST Rate
          </Button>
          <p>Notes: Only numbers allowed</p>
        </>
      );
    } else if (action === "addConfig") {
      return (
        <>
          <Button className="btn-block btn-success" onClick={this.addConfig}>
            Add Category Config
          </Button>
        </>
      );
    } else if (action === "addComm") {
      return (
        <>
          <Button
            className="btn-block btn-success"
            onClick={this.addComm}
            disabled={notes?.replace(/\s+/g, "")?.length < 1}
          >
            Add Commission
          </Button>
          <p>Notes: Only numbers allowed</p>
        </>
      );
    }
  }

  addGST = (e) => {
    if (this.state.modal2) {
      const reqParam = {
        iRefId: this.state.currentObject.iRefId,
        gstRate: this.state.notes,
      };
      this.props.addGST(reqParam);
    } else {
      this.toggle2({
        actionType: "addGST",
        action: "addGST",
        title: "Add GST Rate",
      });
    }
    this.setState({ isUpdated: true });
  };

  /** Add category config */
  addConfig = () => {
    const { variantTypes, uniquePrimaryFieldKeys, modal2, currentObject } = this.state;

    if (modal2) {
      // const variantTypesArray =
      //   variantTypes &&
      //   variantTypes.split(",").filter(element => element.trim().length > 0);

      // const variantTypesArray =
      //   variantTypes && variantTypes.map(element => element.label);

      // const uniquePrimaryFieldKeysArray =
      //   uniquePrimaryFieldKeys &&
      //   uniquePrimaryFieldKeys
      //     .split(",")
      //     .filter(element => element.trim().length > 0);

      // const uniquePrimaryFieldKeysArray =
      //   uniquePrimaryFieldKeys &&
      //   uniquePrimaryFieldKeys.map(element => element.label);

      const reqParam = {
        iRefId: currentObject.iRefId,
        primaryCategoryConfig: {
          variantTypes: variantTypes && [...variantTypes],
          uniquePrimaryFieldKeys: uniquePrimaryFieldKeys && [...uniquePrimaryFieldKeys],
        },
      };
      this.props.addConfig(reqParam);
    } else {
      this.toggle2({
        actionType: "addConfig",
        action: "addConfig",
        title: "Add Category Config",
      });
    }
  };

  addComm = (e) => {
    console.log("add COMMISSION");
    if (this.state.modal2) {
      const reqParam = {
        iRefId: this.state.currentObject.iRefId,
        commissionValue: this.state.notes,
        commissionType: this.state.commissionType,
      };
      this.props.addCommission(reqParam);
    } else {
      this.toggle2({
        actionType: "addComm",
        action: "addComm",
        title: "Add Commission",
      });
    }
    this.setState({ isUpdated: true });
  };

  activateDeactivateAttribute = (key, status) => (e) => {
    if (this.state.modal2) {
      const reqParam = [
        {
          key,
          status,
        },
      ];
      this.props.attachCategoryAttribute({
        iRefId: this.state.currentObject.iRefId,
        attributes: reqParam,
      });
    } else {
      this.toggle2({
        actionType: status,
        action: "removeAttr",
        key: key,
        title: "Mark Active/Inactive Attribute",
      });
    }
    this.setState({ isUpdated: true });
  };

  enableDisableImage = (key, status) => (e) => {
    if (this.state.modal2) {
      const reqParam = {
        [key]: { fileId: key, status },
      };

      this.props.addUpdateCategoryMedia({
        iRefId: this.state.currentObject.iRefId,
        medias: reqParam,
      });
    } else {
      this.toggle2({
        actionType: status,
        action: "enableDisableImage",
        key: key,
        title: "Mark Active/Inactive Category Image",
      });
    }
    this.setState({ isUpdated: true });
  };

  activateDeactivate = (e) => {
    console.log(" activate Deactivate");
    if (this.state.modal2) {
      const reqParam = {
        iRefId: this.state.currentObject.iRefId,
        notes: this.state.notes,
        action: e.target.dataset.action,
      };
      this.props.activateDeactivate(reqParam);
    } else {
      this.toggle2({
        actionType: e.target.dataset.action,
        action: "activateDeactivateCategory",
        title: e.target.dataset.action + " Category",
      });
    }
    this.setState({ isUpdated: true });
  };

  addCategory = (e) => {
    const authData = getFilteredAuthData(this.props.authData);
    const catTree = this.state.chain.join("||");

    const reqData = {
      catTree,
      ...authData,
    };
    //console.log("request", reqData);

    this.props.addCategory(reqData);
    this.setState({ isUpdated: true });
  };

  handleForce = (data, fileInfo) => {
    const authData = getFilteredAuthData(this.props.authData);

    const reqData = {
      data,
      authData,
    };
    this.props.addBulkCategory(reqData);
  };

  handleClick = (level, subs) => (e) => {
    //console.log("level", level, e.target.dataset.value);
    //console.log("subs", level, subs);

    /** Add new category popup  */
    if (e.target.dataset.level) {
      this.toggle();
    }

    const currentObject = { ...subs };

    if (currentObject.subs) delete currentObject.subs;

    let array = [...this.state.chain];
    let index = level - 1;

    //console.log("state array", array, index, subs, e.target.dataset.value);
    array = array.slice(0, index);
    //console.log("before array", array);
    if (subs.name) {
      array.push(subs.name);
    }
    //console.log("after array", array);
    this.setState({
      chain: array,
      newAttributes: [],
      files: [],
      isUpdated: false,
    });

    const step = "level" + (level + 1);
    const stepId = step + "Id";

    /** Clean the chain if intermediate category selected */
    if (level > 0) {
      let obj = { selectedCatId: "" };

      for (let i = level + 1; i <= 7; i++) {
        let innerStep = "level" + i;
        let innerStepId = "level" + i + "Id";
        obj[innerStep] = "";
        obj[innerStepId] = "";
        this.setState(obj);
      }
    }
    /** End cleaning of chain */

    // const variantTypes =
    //   currentObject.primaryCategoryConfig &&
    //   currentObject.primaryCategoryConfig.variantTypes &&
    //   currentObject.primaryCategoryConfig.variantTypes.join(",");
    // const uniquePrimaryFieldKeys =
    //   currentObject.primaryCategoryConfig &&
    //   currentObject.primaryCategoryConfig.uniquePrimaryFieldKeys &&
    //   currentObject.primaryCategoryConfig.uniquePrimaryFieldKeys.join(",");

    const variantTypes = currentObject.primaryCategoryConfig?.variantTypes;
    const uniquePrimaryFieldKeys = currentObject.primaryCategoryConfig?.uniquePrimaryFieldKeys;

    if (subs.subs && subs.subs.length > 0) {
      this.setState({
        [step]: subs.subs,
        [stepId]: subs.id,
        lastCatId: subs.id,
        currentObject,
        commissionType: currentObject.commissionType,
        setAttributes: false,
        uniquePrimaryFieldKeys,
        variantTypes,
      });
    } else {
      this.setState({
        selectedCatId: subs.catRef,
        [stepId]: subs.id,
        lastCatId: subs.id,
        currentObject,
        commissionType: currentObject.commissionType,
        setAttributes: false,
        uniquePrimaryFieldKeys,
        variantTypes,
      });
    }
  };

  renderModal = () => {
    const { category } = this.state;
    const { responseVal } = this.props;

    const categories = responseVal.dObjectData?.searchCategory?.data;

    return (
      <Modal backdrop="static" isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
        <ModalHeader toggle={this.toggle}>Search Category</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    <FormGroup>
                      <div className="has-search">
                        <div className="input-group">
                          <Input
                            type="search"
                            placeholder="Search category name"
                            onChange={this.handleChange("category")}
                            value={category}
                            onKeyDown={this.handleKeyDown}
                          />
                          <div className="input-group-append">
                            <Button color="primary" onClick={this.searchCat} disabled={category.length < 1}>
                              <i className="now-ui-icons ui-1_zoom-bold" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </FormGroup>

                    <div style={{ minHeight: 300 }}>
                      {categories && categories.total > 0 ? (
                        <ul className="brand-suggest">
                          {categories.cats.map((searchedItem) => {
                            return (
                              <li key={"cats-" + searchedItem.id} onClick={this.handleCats(searchedItem.name)}>
                                {searchedItem.name}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                    <FormGroup>
                      <Button
                        className="btn-block btn-primary"
                        onClick={this.chooseCategory}
                        disabled={category.length < 1}
                      >
                        Create new category <strong>{category}</strong>
                      </Button>
                    </FormGroup>
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  onDrop = (acceptedFiles) => {
    this.setState({ files: [...this.state.files, ...acceptedFiles] });

    acceptedFiles.map((acceptedFile) => {
      return this.props.uploadImages(acceptedFile);
    });
  };

  addUpdateMedia = () => {
    const { responseVal } = this.props;
    if (responseVal.media?.length > 0) {
      let mediasToPost = {};
      responseVal.media.forEach((fileId) => {
        mediasToPost[fileId] = { fileId: fileId };
      });

      if (Object.keys(mediasToPost).length > 0) {
        const reqData = {
          iRefId: this.state.currentObject.iRefId,
          medias: mediasToPost,
        };
        this.props.addUpdateCategoryMedia({ ...reqData });
      }
    }
    this.setState({ isUpdated: true });
  };

  removeFromState = (row) => (e) => {
    let filteredArray = this.state.newAttributes.filter((element) => element.key !== row.key);
    this.setState({ newAttributes: [...filteredArray] });
  };

  render() {
    const {
      level1,
      level2,
      level3,
      level4,
      level5,
      level6,

      level2Id,
      level3Id,
      level4Id,
      level5Id,
      level6Id,
      category,
      chain,
      currentTab,
      currentObject,
      // isUpdated,
      mainTitle,
      newAttributes,
      files,
      modal,
      modal2,
      latestEntry,
      setAttributes,
    } = this.state;

    const { responseVal } = this.props;

    const existingAttributes = responseVal.dObjectData?.existingAttributes?.data;

    const papaparseOptions = {
      header: false,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    };

    //console.log("CCCC", chain);

    return (
      <div>
        <PanelHeader />
        {modal ? this.renderModal() : null}
        {modal2 ? this.renderModal2() : null}
        <div className="content mt-2 ">
          {/* {responseVal.status === "failure" ? (
            <ErrorBlock errors={responseVal.errors} />
          ) : null}

          {responseVal.status === "success" && isUpdated ? (
            <SuccessBlock
              msg={{
                userDisplayMsg: "Data added/updated successfully"
              }}
            />
          ) : null} */}

          <Col xs={12}>
            <MessageBlock screenName="category" />
          </Col>

          <ul className="tabs">
            <li
              data-title="Add new category"
              data-value="0"
              onClick={this.tabActions}
              className={currentTab === "0" ? "bg-secondary text-white" : ""}
            >
              Add New Category
            </li>
            <li
              data-title="Add GST rate for respective category"
              data-value="1"
              onClick={this.tabActions}
              className={currentTab === "1" ? "bg-secondary text-white" : ""}
            >
              Add GST Rate
            </li>
            <li
              data-title="Add respective category commission"
              data-value="2"
              onClick={this.tabActions}
              className={currentTab === "2" ? "bg-secondary text-white" : ""}
            >
              Add Commission
            </li>
            <li
              data-title="Activate or Deactivate particular category"
              data-value="3"
              onClick={this.tabActions}
              className={currentTab === "3" ? "bg-secondary text-white" : ""}
            >
              Activate/Deactivate
            </li>
            <li
              data-title="Add category attributes"
              data-value="4"
              onClick={this.tabActions}
              className={currentTab === "4" ? "bg-secondary text-white" : ""}
            >
              Attributes
            </li>
            {/* <li
              data-title="Add Category Image"
              data-value="5"
              onClick={this.tabActions}
              className={currentTab === "5" ? "bg-secondary text-white" : ""}
            >
              Additional Info
            </li> */}
            <li
              data-title="Update Category Config"
              data-value="6"
              onClick={this.tabActions}
              className={currentTab === "6" ? "bg-secondary text-white" : ""}
            >
              Config Settings
            </li>

            {/* {this.props.authData && this.props.authData.userType === "ADMIN" ? (
              <li
                data-title="Add Category from CSV"
                data-value="7"
                onClick={this.tabActions}
                className={currentTab === "7" ? "bg-secondary text-white" : ""}
              >
                Bulk Upload
              </li>
            ) : null} */}

            <li className="pull-right">
              <i
                title="Refresh Categories"
                className="now-ui-icons arrows-1_refresh-69"
                data-action="refresh"
                data-title={this.state.mainTitle}
                data-value={this.state.currentTab}
                onClick={this.tabActions}
              />
            </li>
          </ul>

          {currentTab === "7" ? (
            <div className="p-5">
              <CSVReader
                cssClass="react-csv-input"
                label="Select CSV with category items"
                onFileLoaded={this.handleForce}
                parserOptions={papaparseOptions}
              />
            </div>
          ) : (
            <React.Fragment>
              <p>
                * Notes - <span className="text-success">Green (Active)</span>,{" "}
                <span className="text-danger">Red (Inactive)</span>
              </p>
              <p className="mb-3 d-none">{mainTitle}</p>
              <Row className="mt-3 cat-box">
                <Col md={2} sm={3} xs={6}>
                  <ul className="add-cat">
                    {currentTab === "0" ? (
                      <li>
                        <Button size="sm" color="primary" data-level="1" onClick={this.handleClick(1, {})}>
                          Add category here
                        </Button>
                      </li>
                    ) : null}
                    {level1
                      ? Object.keys(level1).map(function (keyName, keyIndex) {
                          const inner = level1[keyName];
                          return (
                            <li
                              key={inner.id}
                              data-value={inner.name}
                              onClick={this.handleClick(1, inner)}
                              className={level2Id === inner.id ? "active" : ""}
                            >
                              <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                {inner.name}
                              </span>
                            </li>
                          );
                        }, this)
                      : null}
                  </ul>
                </Col>
                {level2Id ? (
                  <Col md={2} sm={3} xs={6}>
                    <ul className="add-cat left-arrow">
                      {currentTab === "0" ? (
                        <li>
                          <Button size="sm" color="primary" data-level="2" onClick={this.handleClick(2, {})}>
                            Add category here
                          </Button>
                        </li>
                      ) : null}
                      {level2 &&
                        Object.keys(level2).map(function (keyName, keyIndex) {
                          const inner = level2[keyName];
                          return (
                            <li
                              key={inner.id}
                              data-value={inner.name}
                              onClick={this.handleClick(2, inner)}
                              className={level3Id === inner.id ? "active" : ""}
                            >
                              <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                {inner.name}
                              </span>
                            </li>
                          );
                        }, this)}
                    </ul>
                  </Col>
                ) : null}{" "}
                {level3Id ? (
                  <Col md={2} sm={3} xs={6}>
                    <ul className="add-cat">
                      {currentTab === "0" ? (
                        <li>
                          <Button size="sm" color="primary" data-level="3" onClick={this.handleClick(3, {})}>
                            Add category here
                          </Button>
                        </li>
                      ) : null}
                      {level3 &&
                        Object.keys(level3).map(function (keyName, keyIndex) {
                          const inner = level3[keyName];
                          return (
                            <li
                              key={inner.id}
                              data-value={inner.name}
                              onClick={this.handleClick(3, inner)}
                              className={level4Id === inner.id ? "active" : ""}
                            >
                              <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                {inner.name}
                              </span>
                            </li>
                          );
                        }, this)}{" "}
                    </ul>
                  </Col>
                ) : null}{" "}
                {level4Id ? (
                  <Col md={2} sm={3} xs={6}>
                    <ul className="add-cat">
                      {currentTab === "0" ? (
                        <li>
                          <Button size="sm" color="primary" data-level="4" onClick={this.handleClick(4, {})}>
                            Add category here
                          </Button>
                        </li>
                      ) : null}
                      {level4 &&
                        Object.keys(level4).map(function (keyName, keyIndex) {
                          const inner = level4[keyName];
                          return (
                            <li
                              key={inner.id}
                              data-value={inner.name}
                              onClick={this.handleClick(4, inner)}
                              className={level5Id === inner.id ? "active" : ""}
                            >
                              <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                {inner.name}
                              </span>
                            </li>
                          );
                        }, this)}
                    </ul>
                  </Col>
                ) : null}{" "}
                {level5Id ? (
                  <Col md={2} sm={3} xs={6}>
                    <ul className="add-cat">
                      {currentTab === "0" ? (
                        <li>
                          <Button size="sm" color="primary" data-level="5" onClick={this.handleClick(5, {})}>
                            Add category here
                          </Button>
                        </li>
                      ) : null}
                      {level5 &&
                        Object.keys(level5).map(function (keyName, keyIndex) {
                          const inner = level5[keyName];
                          return (
                            <li
                              className={
                                this.state.selectedCatId === inner.catRef || level6Id === inner.id ? "active" : ""
                              }
                              key={inner.id}
                              data-value={inner.name}
                              onClick={this.handleClick(5, inner)}
                            >
                              <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                {inner.name}
                              </span>
                            </li>
                          );
                        }, this)}
                    </ul>
                  </Col>
                ) : null}{" "}
                {level6Id ? (
                  <Col md={2} sm={3} xs={6}>
                    <ul className="add-cat">
                      {currentTab === "0" ? (
                        <li>
                          <Button size="sm" color="primary" data-level="6" onClick={this.handleClick(6, {})}>
                            Add category here
                          </Button>
                        </li>
                      ) : null}
                      {level6 &&
                        Object.keys(level6).map(function (keyName, keyIndex) {
                          const inner = level6[keyName];
                          return (
                            <li
                              className={this.state.selectedCatId === inner.catRef ? "active" : ""}
                              key={inner.id}
                              data-value={inner.name}
                              onClick={this.handleClick(6, inner)}
                            >
                              <span className={inner.status === "INACTIVE" ? "text-danger" : "text-success"}>
                                {inner.name}
                              </span>
                            </li>
                          );
                        }, this)}
                    </ul>
                  </Col>
                ) : null}{" "}
              </Row>

              <Row>
                <Col>
                  <div className="selected-chain">
                    <Breadcrumb>
                      <BreadcrumbItem key={"bread-s"}>
                        <span className="text-primary">
                          <strong>Selected Category</strong>
                        </span>
                      </BreadcrumbItem>
                      {chain.length > 0
                        ? chain.map((item, index) => {
                            return <BreadcrumbItem key={"bread-" + index}>{item}</BreadcrumbItem>;
                          })
                        : null}
                    </Breadcrumb>
                    {chain.length > 0 ? (
                      <React.Fragment>
                        {category.length > 0 && currentTab === "0" && chain.includes(category) ? (
                          <Button size="md" color="danger" onClick={this.addCategory}>
                            Generate final category tree
                          </Button>
                        ) : null}

                        {currentTab === "1" && currentObject ? (
                          <>
                            <p className="">
                              Existing GST Rate for {currentObject.name}: <strong>{currentObject.gstRate}</strong>
                            </p>
                            <Button size="sm" color="primary" onClick={this.addGST}>
                              Update GST Rate
                            </Button>
                          </>
                        ) : null}

                        {currentTab === "2" && currentObject ? (
                          <>
                            <p className="">
                              Existing Commission for {currentObject.name}:{" "}
                              <strong>{currentObject.commissionValue}</strong>
                              <br />
                              Commission Type: <strong>{currentObject.commissionType}</strong>
                            </p>
                            <Button size="sm" color="primary" onClick={this.addComm}>
                              Update Commission
                            </Button>
                          </>
                        ) : null}

                        {currentTab === "3" && currentObject && currentObject.status ? (
                          currentObject.status === "ACTIVE" ? (
                            <>
                              <p className="mb-0">
                                <small>Selected Category Tree is currently ACTIVE</small>
                              </p>
                              <Button
                                size="sm"
                                color="danger"
                                data-action="DEACTIVATE"
                                onClick={this.activateDeactivate}
                              >
                                Deactivate
                              </Button>
                            </>
                          ) : (
                            <>
                              <p className="mb-0">
                                <small>Selected Category Tree is currently INACTIVE</small>
                              </p>
                              <Button
                                size="sm"
                                color="success"
                                data-action="ACTIVATE"
                                onClick={this.activateDeactivate}
                              >
                                Activate
                              </Button>
                            </>
                          )
                        ) : currentTab === "4" && currentObject ? (
                          <Button size="sm" color="primary" onClick={this.viewAttributes}>
                            View or Add Attributes
                          </Button>
                        ) : null}

                        {currentTab === "5" && currentObject ? (
                          <Row>
                            <Col sm={4}>
                              <Dropzone onFilesAdded={(opt) => this.onDrop(opt)} />
                              {this.props.responseVal?.media?.length > 0 ? (
                                <Button size="md" color="primary" onClick={this.addUpdateMedia}>
                                  Upload Images
                                </Button>
                              ) : null}
                              {files ? (
                                <Row className="mt-2 equal">
                                  {files.length > 0 &&
                                    files.map((acceptedFile) => (
                                      <Col sm="6" className="" key={acceptedFile.name}>
                                        <img
                                          alt={acceptedFile.name}
                                          src={URL.createObjectURL(acceptedFile)}
                                          className="img-thumbnail rounded"
                                        />
                                        <p className="text-center">{acceptedFile.name}</p>
                                      </Col>
                                    ))}
                                </Row>
                              ) : null}
                            </Col>
                            <Col sm="8">
                              <h5>Existing Category Images</h5>
                              <Row>
                                {Object.keys(currentObject.medias).map((index) => {
                                  return (
                                    <Col md="4" key={index}>
                                      <div className="bg-white p-2 mb-3">
                                        {" "}
                                        <img
                                          alt=""
                                          src={getMediaPath(
                                            currentObject.medias[index].fileId,
                                            currentObject.medias[index].fileExt
                                          )}
                                        />
                                        {currentObject.medias[index].status === "ACTIVE" ? (
                                          <Button
                                            size="sm"
                                            className="btn-block"
                                            color="success"
                                            onClick={this.enableDisableImage(index, "INACTIVE")}
                                          >
                                            Deactivate
                                          </Button>
                                        ) : (
                                          <Button
                                            size="sm"
                                            className="btn-block"
                                            color="danger"
                                            onClick={this.enableDisableImage(index, "ACTIVE")}
                                          >
                                            Activate
                                          </Button>
                                        )}
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                            {window.scroll({
                              top: 600,
                              left: 600,
                              behavior: "smooth",
                            })}
                          </Row>
                        ) : null}

                        {currentTab === "6" && currentObject ? (
                          <>
                            <Button size="sm" color="primary" onClick={this.addConfig}>
                              Update Config
                            </Button>
                          </>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                  </div>
                </Col>
              </Row>
              {setAttributes ? (
                <Row>
                  <Col md="6">
                    <div className="new-items">
                      <h5>New Attributes </h5>
                      {newAttributes && newAttributes.length > 0 ? (
                        <>
                          <ul className="brand-suggest auto">
                            {newAttributes.map((newAttr) => {
                              return (
                                <li
                                  key={`new-${newAttr.key}`}
                                  className={latestEntry === newAttr.key ? "highlight" : ""}
                                >
                                  <span className="pull-left mr-3">
                                    <CustomInput
                                      style={{ display: "inline" }}
                                      type="switch"
                                      id={`custom-${newAttr.key}`}
                                      label="mandatory"
                                      onClick={this.handleCheckbox(newAttr.key)}
                                    />
                                  </span>{" "}
                                  {newAttr.name}{" "}
                                  <span className="pull-right" onClick={this.removeFromState(newAttr)}>
                                    <i className="now-ui-icons ui-1_simple-remove" />
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                          <div className="text-center">
                            <Button size="md" color="danger" className="pull--right" onClick={this.attachAttribute}>
                              Add above attributes to category
                            </Button>
                          </div>

                          <div className="clearfix" />
                        </>
                      ) : (
                        <p>New attributes will be available here when you select items from right side box</p>
                      )}
                    </div>
                    <div className="bg-white p-2  mb-3" style={{ minHeight: 500 }}>
                      <h5>Existing Attributes</h5>
                      {existingAttributes ? (
                        <ul className="brand-suggest auto">
                          {Object.keys(existingAttributes).map(function (keyName, keyIndex) {
                            const attr = existingAttributes[keyName];
                            return (
                              <li key={"atts-" + keyName} onClick={this.handleCats(keyName)}>
                                <span className="pull-left mr-3">
                                  <CustomInput
                                    style={{ display: "inline" }}
                                    type="switch"
                                    id={`custom-existing-${attr.key}`}
                                    label=""
                                    defaultChecked={attr.mandatory}
                                    onClick={this.handleCheckboxUpdate(attr.key)}
                                  />
                                </span>
                                {attr.name} ({" "}
                                {attr.mandatory ? (
                                  <>
                                    <span className="star" title="Mandatory">
                                      Mandatory
                                    </span>
                                    ,
                                  </>
                                ) : null}{" "}
                                {attr.type}, {attr.status} )
                                {attr.status === "ACTIVE" ? (
                                  <span
                                    className="badge badge-success pull-right"
                                    onClick={this.activateDeactivateAttribute(attr.key, "INACTIVE")}
                                  >
                                    Disable
                                  </span>
                                ) : (
                                  <span
                                    className="badge badge-danger pull-right"
                                    onClick={this.activateDeactivateAttribute(attr.key, "ACTIVE")}
                                  >
                                    Enable
                                  </span>
                                )}
                              </li>
                            );
                          }, this)}
                          {window.scroll({
                            top: 600,
                            left: 600,
                            behavior: "smooth",
                          })}
                        </ul>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="bg-white p-2" style={{ minHeight: 585 }}>
                      <Attributes
                        handleAttribute={this.handleAttribute}
                        existingAttributes={existingAttributes ? existingAttributes : {}}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {this.props.isLoading ? <div className="loader" /> : null}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    categories: state.categories,
    responseVal: state.resHandlerVal,
    authData: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    getCategories: (reqParam) => dispatch(getCategories(reqParam)),
    addCategory: (reqParam) => dispatch(addCategory(reqParam)),
    addBulkCategory: (reqParam) => dispatch(addBulkCategory(reqParam)),
    addGST: (reqParam) => dispatch(addGST(reqParam)),
    addConfig: (reqParam) => dispatch(addConfig(reqParam)),
    addCommission: (reqParam) => dispatch(addCommission(reqParam)),
    activateDeactivate: (reqParam) => dispatch(activateDeactivate(reqParam)),
    searchCategory: (reqParam) => dispatch(searchCategory(reqParam)),
    getExistingAttribute: (reqParam) => dispatch(getExistingAttribute(reqParam)),
    uploadImages: (acceptedFile) => dispatch(uploadImages(acceptedFile)),
    addUpdateCategoryMedia: (reqParam) => dispatch(addUpdateCategoryMedia(reqParam)),
    attachCategoryAttribute: (reqParam) => dispatch(attachCategoryAttribute(reqParam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
