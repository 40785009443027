import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input } from "reactstrap";
import { connect } from "react-redux";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { uploadAllImages } from "../../datautils/actions/skuActions";
import ImageZoom from "../../components/ImageZoom";
function CropperModal(props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [mediaSize, setMediaSize] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  //const [imageSizes, setImageSizes] = useState({});
  const [aspect, setAspect] = useState(props.aspect || 5 / 3);
  const { files } = props;
  const minZoom = 0.4;
  //const aspect = props.aspect || "394 / 552";

  useEffect(() => {
    const tempObj = { ...files };
    Object.keys(tempObj).forEach(async (key, index) => {
      const file = tempObj[key];
      const fileObj = await transformBase64(file.original);
      const orgDimension = await getImageDimensions(fileObj);
      tempObj[key] = { ...file, base64: fileObj, orgDimension };
      if (index + 1 === Object.keys(tempObj).length) {
        props.setFiles({ ...tempObj });
      }
    });
    const firstKey = Object.keys(files)[0];
    setSelectedImage({ key: firstKey, file: files[firstKey] });
  }, []);

  const removeFromState = (key) => {
    const tempObj = { ...files };
    delete tempObj[key];
    props.setFiles({ ...tempObj });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    console.log(croppedArea, croppedAreaPixels);
    setMediaSize(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const selectedFile = selectedImage.file;
      const croppedImage = await getCroppedImg(selectedFile, croppedAreaPixels, rotation);
      const crpDimension = await getImageDimensions(croppedImage);
      // props.setFiles((state) => ({
      //   ...state,
      //   [selectedImage.key]: { ...selectedFile, cropped: croppedImage, crpDimension },
      // }));

      props.setFiles({ ...files, [selectedImage.key]: { ...selectedFile, cropped: croppedImage, crpDimension } });

      setSelectedImage({ key: selectedImage.key, file: null });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  // const uploadCroppedImage = async () => {
  //   try {
  //     const fileIds = await props.uploadAllImages(files);
  //     console.log("fileIds", fileIds);
  //     props.setShow(true);
  //     props.setUploadedFiles(fileIds);

  //     const tempObj = { ...files };
  //     Object.keys(tempObj).forEach(async (key, index) => {
  //       const file = tempObj[key];
  //       tempObj[key] = { ...file, uploaded: fileIds[index] };
  //       if (index + 1 === Object.keys(tempObj).length) {
  //         props.setFiles({ ...tempObj });
  //       }
  //     });

  //     // props.setFiles({});
  //   } catch (error) {
  //     console.log("uploadCroppedImage", error);
  //   }
  // };

  const transformBase64 = async (inputFile) => {
    return new Promise((resolve, reject) => {
      var fileReader = new FileReader();

      // If error occurs, reject the promise
      fileReader.onerror = () => {
        //reject("Error reading file");
        console.log("Error reading file onError");
      };

      // Define an onload handler that's called when file loaded
      fileReader.onload = () => {
        // File data loaded, so proceed to call setState
        if (fileReader.result !== undefined) {
          // console.log(fileReader.result);
          return resolve(fileReader.result);
        } else {
          //reject("Error reading file");
          console.log("Error reading file onLoad");
        }
      };

      fileReader.readAsDataURL(inputFile);
    });
  };

  const croppedImages = Object.keys(files).filter((key) => {
    return files[key].cropped;
  });

  function getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image();
      i.onload = function () {
        resolved({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  }

  return (
    <Modal
      isOpen={props.show}
      toggle={props.setShow}
      size="xl"
      backdrop="static"
      keyboard={false}
      fade={false}
      scrollable
      centered
      className={`full-screen-modal ${props.isLoading ? "processing" : ""}`}
    >
      <ModalHeader className="mb-2" toggle={props.setShow}>
        Image Editor <small>(Only cropped images will be used with SKU)</small>
      </ModalHeader>
      <ModalBody className="py-0" style={{ borderTop: "2px solid #f1f1f1", borderBottom: "2px solid #f1f1f1" }}>
        {props.isLoading ? <div className="loader" /> : null}
        <Row>
          <Col sm={7} style={{ borderRight: "2px solid #f1f1f1" }}>
            <div className="p-3">
              <Row>
                {Object.keys(files).map((key) => {
                  const file = files[key];
                  return file.base64 ? (
                    <Col md={6} className="d-flex" key={`top-${key}`}>
                      <Row className={`row-images my-3 mx-1 border ${selectedImage?.key === key ? "selected" : ""}`}>
                        <span
                          onClick={() => removeFromState(key)}
                          type="button"
                          className="close img-remove"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </span>
                        <Col md={6} className="text-center">
                          <p className="mb-1" style={{ fontSize: 11 }}>
                            Original Image{" "}
                            {file?.orgDimension ? (
                              <span>({`${file.orgDimension?.w}x${file.orgDimension?.h}`})</span>
                            ) : null}
                          </p>
                          <div style={{ position: "relative" }}>
                            {file.base64 ? (
                              <ImageZoom style={{ maxHeight: 100 }} src={file.base64} alt={file.key} />
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6} className="text-center">
                          <p className="mb-1" style={{ fontSize: 11 }}>
                            Cropped Image{" "}
                            {file?.crpDimension ? (
                              <span>({`${file.crpDimension?.w}x${file.crpDimension?.h}`})</span>
                            ) : null}
                          </p>
                          <div>
                            {file.cropped ? (
                              <ImageZoom style={{ maxHeight: 100 }} src={file.cropped} alt={file.key} />
                            ) : null}
                          </div>
                        </Col>
                        <div className="mx-auto">
                          {props.cropOptions ? (
                            props.cropOptions.map((item) => (
                              <Button
                                size="xs"
                                className="btn btn-success-outline"
                                onClick={() => {
                                  setAspect(item.aspect);
                                  setSelectedImage({ key, file });
                                  document.getElementById("cropBtn").scrollIntoView({ behavior: "smooth" });
                                }}
                              >
                                {item.text}
                              </Button>
                            ))
                          ) : (
                            <Button
                              size="xs"
                              className="btn btn-success-outline"
                              onClick={() => {
                                setSelectedImage({ key, file });
                                document.getElementById("cropBtn").scrollIntoView({ behavior: "smooth" });
                              }}
                            >
                              Select to Crop
                            </Button>
                          )}
                        </div>
                      </Row>
                    </Col>
                  ) : null;
                })}
              </Row>
            </div>
          </Col>
          <Col sm={5}>
            <h5 className="mt-3 d-flex align-items-center justify-content-between" style={{ fontSize: 14 }}>
              <p className="d-flex mb-0">
                {mediaSize && selectedImage?.file ? (
                  <span>Cropped Image (wxh): {`${mediaSize?.width}x${mediaSize?.height}`}</span>
                ) : null}
              </p>

              <Button
                id="cropBtn"
                disabled={props.isLoading || !(selectedImage?.key in files && selectedImage?.file?.base64)}
                className="my-0"
                size={"sm"}
                color="primary"
                onClick={() => showCroppedImage()}
              >
                Crop Image
              </Button>
            </h5>

            <>
              <div className="m-2">
                {selectedImage?.key in files && selectedImage?.file?.base64 ? (
                  <>
                    <FormGroup className="mb-2">
                      <Input
                        type="range"
                        name="range"
                        id="exampleRange"
                        value={zoom}
                        min={minZoom}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e) => {
                          setZoom(e.target.value);
                        }}
                      />
                    </FormGroup>
                    <div style={{ height: 300, position: "relative" }}>
                      <Cropper
                        restrictPosition={false}
                        showGrid={true}
                        image={selectedImage.file.base64}
                        crop={crop}
                        zoom={zoom}
                        minZoom={minZoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        // onMediaLoaded={(mediaSize) => {
                        //   setMediaSize(mediaSize);
                        //   console.log("mediaSize", mediaSize);
                        // }}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center h-100 mt-4"
                    style={{ background: "#f8f8f8", minHeight: 300 }}
                  >
                    <p style={{ color: "#b1b1b1" }}>Select Uploaded Image from left and Crop</p>
                  </div>
                )}
              </div>
            </>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="pt-3">
        {croppedImages?.length > 0 && croppedImages?.length === Object.keys(files).length ? (
          // <Button size={"sm"} disabled={props.isLoading} color="primary" onClick={() => uploadCroppedImage()}>
          //   Upload Cropped Images
          // </Button>
          <Button size={"sm"} disabled={props.isLoading} color="primary" onClick={() => props.setShow(true)}>
            I am done with Cropping Images
          </Button>
        ) : (
          <p className="text-danger">*Note: Crop all uploaded images to save</p>
        )}
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadAllImages: (acceptedFile) => dispatch(uploadAllImages(acceptedFile)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CropperModal);
