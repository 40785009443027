import { SAVE_BILL_BOOK_LIST, SAVE_BILL_DETAIL, CLEAR_BILL_BOOK, SAVE_MESSAGE_LOG } from "./actionTypes";
import axios from "axios";
import {
  FETCH_BILL_BOOK,
  FETCH_BILL_DETAIL,
  CREATE_BILL_ENTRY,
  REJECT_BILL_ENTRY,
  DELETE_BILL_ENTRY,
  MESSAGE_BILL_ENTRY,
  APPROVE_BILL_ENTRY,
} from "../../utils/constants/apiUrls";
import { getRequestHeaders } from "./authActions";
import { startUILoading, stopUILoading } from "./uiActions";
import { saveResponseMessage, clearMessage } from "./responseMessageActions";
import { uploadMedia } from "./mediaActions";
//import { PER_PAGE } from "../../utils/constants/index";
const messageKey = "billbook";

/** Get bill book list */
export const getBillBook = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(messageKey));
    try {
      const config = await dispatch(getRequestHeaders());

      const response = await axios.post(FETCH_BILL_BOOK, reqParam, config);

      dispatch(saveList(response.data.data));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage(messageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Get a single bill detail */
export const getBillDetail = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(messageKey));
    try {
      const config = await dispatch(getRequestHeaders());

      const URL = FETCH_BILL_DETAIL + reqParam.toUserId;

      const response = await axios.post(URL, reqParam, config);
      dispatch(saveDetail(response.data.data));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage(messageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** add or update detail of a single bill */
export const addUpdateBill = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(clearMessage(messageKey));
    dispatch(startUILoading());

    try {
      const config = await dispatch(getRequestHeaders());

      let media = [];

      async function processArray(fileData) {
        await Promise.all(
          fileData.map(async (item) => {
            const fileId = await dispatch(uploadMedia(item, { messageKey: "billbook" }));
            if (fileId) {
              media.push({ fileId: fileId, icon: false });
            }
          })
        );
      }

      if (reqParam.fileData?.length) {
        await processArray(reqParam.fileData);
      }

      if (reqParam.fileData?.length === media.length) {
        const paramToSend = {
          ...reqParam,
          fileData: null,
          message: { ...reqParam.message, medias: [...media] },
        };

        await axios.post(CREATE_BILL_ENTRY, paramToSend, config);
        dispatch(saveResponseMessage(messageKey, { hasErrors: false }));
        /** Refresh billbook Detail */
        dispatch(getBillDetail({ ...reqParam.refreshObj }));
      }
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage(messageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** add or update detail of a single entry */
export const addUpdateEntry = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(clearMessage(messageKey));
    dispatch(startUILoading());

    try {
      const config = await dispatch(getRequestHeaders());

      let URL =
        reqParam.action === "REJECT"
          ? REJECT_BILL_ENTRY
          : reqParam.action === "APPROVE"
          ? APPROVE_BILL_ENTRY
          : DELETE_BILL_ENTRY;

      await axios.post(URL, reqParam, config);
      dispatch(saveResponseMessage(messageKey, { hasErrors: false }));

      /** Refresh billbook Detail */
      dispatch(getBillDetail({ ...reqParam.refreshObj }));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage(messageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** message on single bill entry */
export const messageBillEntry = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(clearMessage(messageKey));
    dispatch(startUILoading());

    try {
      const config = await dispatch(getRequestHeaders());

      let media = [];

      async function processArray(fileData) {
        await Promise.all(
          fileData.map(async (item) => {
            const fileId = await dispatch(uploadMedia(item, { messageKey: "billbook" }));
            if (fileId) {
              media.push({ fileId: fileId, icon: false });
            }
          })
        );
      }

      if (reqParam.fileData?.length) {
        await processArray(reqParam.fileData);
      }

      if (reqParam.fileData?.length === media.length) {
        const paramToSend = {
          ...reqParam,
          fileData: null,
          message: { ...reqParam.message, medias: [...media] },
        };

        let URL = MESSAGE_BILL_ENTRY;

        const response = await axios.post(URL, paramToSend, config);

        const existingList = getState().billBookItems?.billDetail?.billItems;

        let newArray = [];

        if (response.data.data) {
          newArray = existingList.map((obj) => (obj.paymentRefId === reqParam.paymentRefId ? response.data.data : obj));
        }
        dispatch(saveMessageLog(newArray));
        dispatch(saveResponseMessage(messageKey, { hasErrors: false }));
      }
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage(messageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const saveList = (data) => {
  return {
    type: SAVE_BILL_BOOK_LIST,
    data: data,
  };
};

export const saveDetail = (data) => {
  return {
    type: SAVE_BILL_DETAIL,
    data: data,
  };
};

export const clearBillBook = () => {
  return {
    type: CLEAR_BILL_BOOK,
  };
};

export const saveMessageLog = (data) => {
  return {
    type: SAVE_MESSAGE_LOG,
    data: data,
  };
};
