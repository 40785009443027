import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Form,
  FormGroup,
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
//import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { loginUser } from "../../../datautils/actions/authActions";
import ErrorBlock from "../../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../../components/SuccessBlock/SuccessBlock";
import HomeHeader from "../../../components/Headers/HomeHeader";
import Footer from "../../../views/Common/Footer";
import { isUserLoggedIn } from "../../../utils/helpers/HelperFunctions";
import { APP_NAME, userTypes } from "../../../utils/constants";
import { ADMIN_BASE } from "../../../utils/constants/clientUrls";
import { WEB_URL } from "../../../utils/constants/apiUrls";
import Select from "react-select";
import adminIcon from "../../../assets/img/execution.png";

class AdminLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      mobileState: "",
      password: "",
      passwordState: "",
      userType: "OPS",
      hasError: false,
      errors: { mobile: "", password: "" },
    };
  }
  componentDidMount() {
    if (isUserLoggedIn()) {
      this.props.history.push(ADMIN_BASE);
    }
  }

  typeNumber(e) {
    let numberRex = new RegExp("^[0-9]+$");
    let mobile = this.state.mobile;
    let mobileState = this.state.mobileState;
    mobile = e.target.value;
    if (numberRex.test(mobile) && mobile.length === 10) {
      mobileState = "is-valid";
    } else {
      mobileState = "is-invalid";
    }
    this.setState({ mobile, mobileState });
  }

  rangeMinL(e) {
    let password = this.state.password;
    let passwordState = this.state.password;
    password = e.target.value;
    if (password.length > 5) {
      passwordState = "is-valid";
    } else {
      passwordState = "is-invalid";
    }
    this.setState({ password, passwordState });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeUserType = (e) => {
    this.setState({ userType: e.target.value });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState({ [obj]: opt.value });
    console.log(`Option selected:`, opt.value);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const reqData = {
      userType: this.state.userType,
      mobileCountryCode: "+91",
      mobileNumber: this.state.mobile,
      password: this.state.password,
    };
    this.props.loginUser(reqData);
  };

  componentDidUpdate(prevProps) {
    if (isUserLoggedIn()) {
      this.props.history.push(ADMIN_BASE);
    }
  }

  render() {
    const { responseVal } = this.props;
    const { mobileState, passwordState, userType } = this.state;
    const isEnabled = mobileState === "is-valid" && passwordState === "is-valid";

    return (
      <div>
        <Helmet>
          <title>{APP_NAME}</title>
        </Helmet>
        <HomeHeader page="admin" {...this.props} />
        <div className="full-page--content">
          <div className="login-page">
            <Container>
              <Row className="justify-content-center">
                <Col lg={8} md={8} xs={12} className="d-none d-lg-block">
                  <h2>Administration Login</h2>
                  <p>
                    This login is only intended for Admins of Slickpals. If you are not admin go to{" "}
                    <a href={WEB_URL}>homepage</a>
                  </p>
                  <div className="mt-5">
                    <img style={{ maxWidth: 256 }} src={adminIcon} alt="" />
                  </div>
                </Col>
                <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                  <h3 className="d-block d-lg-none">Administration Login</h3>

                  <Form onSubmit={this.handleSubmit} autoComplete="off">
                    <Card className="card-login">
                      <CardHeader className="text-center">
                        <CardTitle tag="h4">Login</CardTitle>
                        {responseVal.status === "failure" ? (
                          <ErrorBlock errors={responseVal.errors} position="alert-relative" />
                        ) : null}

                        {responseVal.status === "success" ? (
                          <SuccessBlock msg={responseVal.data} position="alert-relative" />
                        ) : null}
                      </CardHeader>
                      <CardBody>
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>+91</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className={this.state.mobileState}
                              type="text"
                              name="mobile"
                              placeholder="Mobile Number"
                              maxLength="10"
                              value={this.state.mobile}
                              onChange={(e) => this.typeNumber(e)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Input
                            className={this.state.passwordState}
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={(e) => this.rangeMinL(e)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Select
                            className="react-select"
                            name="userType"
                            options={userTypes}
                            value={userTypes.filter((option) => option.value === userType)}
                            onChange={(opt) => this.handleChangeSelect("userType", opt)}
                          />
                        </FormGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          color="primary"
                          size="md"
                          className="mb-3"
                          disabled={this.props.isLoading || !isEnabled}
                        >
                          Login
                        </Button>
                        {/* <p className="text-center">
                          <Link to="/forgot" className="link footer-link">
                            Forgot Password?
                          </Link>
                        </p> */}
                        <p className="text-center">
                          If you are unable to login, Ask your Administrator to reset password
                        </p>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    userType: state.auth.userType,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (reqData) => dispatch(loginUser(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
