import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchOrderRequest,
  replyOrderRequest,
  addOrderRequestReply,
  orderRequestProposeSku,
  addUpdateBargainOffer,
  orderRequestAttachSupplier,
  addUpdateOrderRequest,
  updateOrderRequestProposal,
} from "../../datautils/actions/orderRequestActions";
import { PER_PAGE, unitOfMeasures } from "../../utils/constants/index";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import { getProfile } from "../../datautils/actions/userActions";
import { searchSupplierSku } from "../../datautils/actions/skuActions";
import { getSuppliersList } from "../../datautils/actions/adminActions";
import {
  ADMIN_SUPPLIER_VIEW,
  ADMIN_SUPPLIERR_VIEW,
  SUPPLIER_SKU_VIEW,
  ADMIN_SKU_VIEW,
  ADMIN_CUSTOMER_VIEW,
  ADMIN_CUSTOM_VIEW,
  SUPPLIER_CUSTOM_VIEW,
} from "../../utils/constants/clientUrls";
import { getAddress } from "../../datautils/actions/userActions";
import ImageZoom from "../../components/ImageZoom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import Dropzone from "../../components/Dropzone/Dropzone";
import { getMediaUrl } from "../../utils/helpers/HelperFunctions";
//import { orderRequestStatusOptions } from "../../utils/constants";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
class OrderRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      files: [],
      files2: [],
      formData: {},
      demandResponse: {},
      selectedRow: -1,
      selectedRowData: [],
      updatedOn: null,
      repliedOn: null,
      userComment: "",

      globalSearchKeyword: "",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "skuId",
      ascending: false,

      modal: false,
      currentTitle: "",
      currentAction: "",
      currentActionType: "",
      formDataBargain: {},
    };
    this.frameRef = React.createRef();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const {
      authData: { userId },
    } = this.props;
    this.props.fetchOrderRequest({ requestId: id });
    this.props.getAddress({ userId: userId });
    this.props.getProfile({ supplierUserId: userId });
    this.setState({ id });
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;

    const { orderRequest } = this.props;

    if (this.state.id !== id) {
      this.setState({ id });
      this.props.fetchOrderRequest({ requestId: id });
    }

    if (
      (orderRequest &&
        orderRequest.demand &&
        orderRequest.demand.updatedOn !== prevState.updatedOn) ||
      (orderRequest &&
        orderRequest.demandResponse &&
        orderRequest.demandResponse.updatedOn !== prevState.repliedOn)
    ) {
      const data = orderRequest.demand;
      const demandResponse = orderRequest.demandResponse;

      let future = new Date();
      future.setDate(future.getDate() + 10);

      let endDateTime =
        data && data.requiredOn ? new Date(data.requiredOn) : future;

      this.setState({
        ...this.state,
        id,
        demandResponse: demandResponse,
        formData: {
          ...data,
          offerExpiryDate: (data && data.offerExpiryDate) || endDateTime,
        },
        updatedOn: data && data.updatedOn,
        repliedOn: demandResponse && demandResponse.updatedOn,
        userComment: "",
        files2: [],
        files: [],
        modal: false,
      });

      this.scrollToBottom();
    }
  }

  toggle = (requestData) => {
    const { modal } = this.state;
    if (modal) {
      this.setState({
        modal: false,
        notes: "",
        currentTitle: "",
        currentAction: "",
        currentActionType: "",
      });
    } else {
      this.setState({
        modal: true,
        notes: "",
        currentTitle: requestData.title,
        currentAction: requestData.action,
        currentActionType: requestData.actionType,
        isUpdated: false,
      });
      //this.props.resetResponseValues();
    }
  };

  openBargainPopup = (reqObj) => {
    const { demandResponse } = this.state;

    let pricePerItem = 0;
    if (demandResponse) {
      if (demandResponse.totalOfferAmount && demandResponse.offerQuantity)
        pricePerItem =
          demandResponse.totalOfferAmount / demandResponse.offerQuantity;
      pricePerItem = Math.round(pricePerItem);
    }

    let future = new Date();
    future.setDate(future.getDate() + 10);

    let endDateTime = demandResponse.availableBy
      ? new Date(demandResponse.availableBy)
      : future;

    this.setState({
      ...this.state,
      formDataBargain: {
        ...reqObj,
        offerExpiryDate: endDateTime,
        supplierOfferOrderQuantity: demandResponse.offerQuantity,
        supplierOfferUnitPrice: pricePerItem,
        supplierMessage: "",
      },
    });
    this.toggle({
      actionType: "CREATE_BARGAIN",
      action: "update",
      title: "Create Bargain",
    });
  };

  addUpdateBargain = async () => {
    const { formData, formDataBargain, demandResponse } = this.state;

    let future = new Date();
    future.setDate(future.getDate() + 10);

    let endDateTime = formDataBargain.offerExpiryDate
      ? new Date(formDataBargain.offerExpiryDate)
      : future;

    const reqData = {
      demandRequestReplyId: demandResponse.id,
      demandRequestId: demandResponse.demandId,
      proposedSkuId: formDataBargain.id,

      customerUserId: formData.customerUserId,
      customerId: formData.customerId,
      skuId: formDataBargain.id,

      skuSellingPrice: formDataBargain.sp,
      skuMrp: formDataBargain.mrp,
      productIdentifier: formDataBargain.productId,
      productVariantId: formDataBargain.productVariantId,

      variantType: formDataBargain.variantType,
      variantName: formDataBargain.variantName,
      supplierId: formDataBargain.supplierId,
      supplierUserId: formDataBargain.supplierUserId,
      productVariantIdentifier: formDataBargain.productVariantId,
      productName: formDataBargain.title,
      productImageFileId: formDataBargain.defaultImageFileId,
      productImageFileExt: formDataBargain.defaultImageFileExt,
      customerOfferRequestedOrderQuantity:
        formDataBargain.supplierOfferOrderQuantity,
      supplierOfferOrderQuantity: formDataBargain.supplierOfferOrderQuantity,
      customerOfferRequestedUnitPrice: formDataBargain.supplierOfferUnitPrice,
      supplierOfferUnitPrice: formDataBargain.supplierOfferUnitPrice,
      supplierMessage: formDataBargain.supplierMessage,
      supplierOfferDeliveryCharge: formDataBargain.supplierOfferDeliveryCharge,
      offerExpiryDate: endDateTime,
    };
    const response = await this.props.addUpdateBargainOffer(reqData);
    console.log("Bargained", response);
    if (response) {
      this.setState({ modal: false });
    }
  };

  searchHandler = (e) => {
    this.setState({
      globalSearchKeyword: e.target.value,
    });
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchSku()
    );
  };

  searchHandlerSupplier = (e) => {
    this.setState({
      globalSearchKeyword: e.target.value,
    });
  };

  searchButtonSupplier = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchSupplier()
    );
  };

  searchSupplier = () => {
    const { globalSearchKeyword, pageNumber, pageSize } = this.state;

    const reqData = {
      globalSearchKeyword,
      paginationOptions: {
        pageNumber,
        pageSize,
      },
    };
    this.props.getSuppliersList(reqData);
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.skuList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  searchSku = () => {
    const {
      globalSearchKeyword,
      currentTab,
      pageNumber,
      pageSize,
      sortField,
      ascending,
    } = this.state;

    // let tab4 = {};

    // if (currentTab === "4") {
    //   tab4 = ;
    // }

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [
        {
          key: "qcStatus",
          operation: "EQUAL_TO",
          value: currentTab === "4" ? "3" : currentTab,
        },
        {
          ...(currentTab === "4"
            ? { key: "status", operation: "EQUAL_TO", value: "0" }
            : {}),
        },
      ],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.props.searchSupplierSku(reqData);
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.searchSku()
      );
    }
  };

  handleKeyDownSupplier = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.searchSupplier()
      );
    }
  };

  scrollToBottom = () => {
    if (this.frameRef.current) {
      this.frameRef.current.scrollTop = this.frameRef.current.scrollHeight;
    }
  };

  removeFromState = (row) => (e) => {
    let filteredArray = this.state.files.filter(
      (element) => element.name !== row.name
    );
    this.setState({ files: [...filteredArray] });
  };

  onDrop = (acceptedFiles) => {
    this.setState({ files: [...this.state.files, ...acceptedFiles] });
  };

  removeFromState2 = (row) => (e) => {
    let filteredArray = this.state.files2.filter(
      (element) => element.name !== row.name
    );
    this.setState({ files2: [...filteredArray] });
    //this.props.removeMediaObject(row.name);
  };

  onDrop2 = (acceptedFiles) => {
    this.setState({ files2: [...this.state.files2, ...acceptedFiles] });

    // acceptedFiles.map(acceptedFile => {
    //   return this.props.uploadImages(acceptedFile);
    // });
  };

  handleChange = (event) => {
    let eTargetValue = event.target.value;

    if (event.target.name === "quantity") {
      eTargetValue = Math.round(eTargetValue);
    }

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        [event.target.name]: eTargetValue,
      },
    });
  };

  handleChangeBargain = (event) => {
    let eTargetValue = event.target.value;
    this.setState({
      ...this.state,
      formDataBargain: {
        ...this.state.formDataBargain,
        [event.target.name]: eTargetValue,
      },
    });
  };

  handleHistory = (selectedRow, selectedRowData) => {
    this.setState({ selectedRow, selectedRowData });
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  handleChangeData = (event) => {
    let eTargetValue = event.target.value;
    this.setState({ [event.target.name]: eTargetValue });
  };

  formatDate = (date) => {
    const now = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const first = now.substr(0, now.length - 3);
    const last = now.substr(-3);
    return first + last.replace(":", "");
  };

  handleChangeEndDate = (date) => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, offerExpiryDate: date },
    });
  };

  handleChangeExpiryDate = (date) => {
    this.setState({
      ...this.state,
      formDataBargain: { ...this.state.formDataBargain, offerExpiryDate: date },
    });
  };

  handleChangeSelect = (obj, opt) => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [obj]: opt.value },
    });
  };

  replyOrderRequest = () => {
    const {
      profile: { address },
      authData: { userId },
    } = this.props;
    const { formData, files } = this.state;

    const pickUpAddress =
      address &&
      address.addressDetailListMap &&
      address.addressDetailListMap["PICKUP_ADDRESS"];

    const currentPickUpAddress =
      formData.supplierPickUpAddressId &&
      pickUpAddress &&
      pickUpAddress.find(
        (p) => parseInt(p.id) === parseInt(formData.supplierPickUpAddressId)
      );

    const reqObj = {
      demandId: formData.id,
      supplierUserId: userId,
      reply: formData.supplierMessage,
      offerQuantity: formData.quantity,
      totalOfferAmount: formData.totalOfferAmount,
      unitOfMeasure: formData.unitOfMeasure,
      fileData: files,
      medias: [],
      wareHouseAddress: { ...currentPickUpAddress },
      status: formData.status,
      availableBy: formData.offerExpiryDate,
    };
    this.props.replyOrderRequest({ ...reqObj });
  };

  handleKeyDownReply = (e) => {
    if (e.key === "Enter") {
      this.addOrderRequestReply(e);
    }
  };

  addOrderRequestReply = (e) => {
    e.preventDefault();
    const { demandResponse, userComment, files2 } = this.state;

    if (demandResponse) {
      const reqParam = {
        userComment: { message: userComment },
        replyId: demandResponse.id,
        demandId: demandResponse.demandId,
        id: demandResponse.id,
        supplierUserId: demandResponse.supplierUserId,
        supplierId: demandResponse.supplierId,
        fileData: files2,
      };
      this.props.addOrderRequestReply(reqParam);
    }
  };

  updateOrderRequestStatus = (inputParam) => {
    const { formData } = this.state;
    const {
      authData: { userId, userType },
    } = this.props;
    const reqParam = {
      id: formData.id,
      requestId: formData.id,
      status: inputParam.status,
      userType: userType,
      userId: userId,
      action: "updateStatus",
    };
    this.props.addUpdateOrderRequest({ ...reqParam });
  };

  updateOrderRequestProposal = (inputParam) => {
    const { demandResponse } = this.state;
    if (demandResponse) {
      const reqParam = {
        reply: {
          demandId: demandResponse.demandId,
          id: demandResponse.id,
          replyId: demandResponse.id,
          status: inputParam.status,
          action: "updateStatus",
        },
      };
      this.props.updateOrderRequestProposal({ ...reqParam });
    }
  };

  proposeSku = (row) => {
    const { demandResponse } = this.state;
    let brand = {};
    if (row.brand) {
      brand = { ...row.brand };
    }

    if (demandResponse) {
      const reqParam = {
        reply: {
          replyId: demandResponse.id,
          demandId: demandResponse.demandId,
          id: demandResponse.id,
          supplierUserId: demandResponse.supplierUserId,
          supplierId: demandResponse.supplierId,
          proposedSku: { ...row, ...brand },
        },
      };
      this.props.orderRequestProposeSku(reqParam);
    }
  };

  sendProposal = (row) => {
    const { id, formData } = this.state;

    if (id && formData) {
      const reqParam = {
        action: row.action,
        demand: {
          customerUserId: formData.customerUserId,
          customerId: formData.customerId,
          id: id,
          demandId: id,
          supplierName: row.businessName,
          supplierUserId: row.supplierUserId,
        },
      };
      this.props.orderRequestAttachSupplier(reqParam);
    }
  };

  renderModal = () => {
    const {
      currentTitle,
      currentAction,
      //currentActionType,
      //formData,
      modal,
      formDataBargain,
    } = this.state;

    const {
      authData: { userType },
    } = this.props;

    return (
      <Modal
        backdrop="static"
        isOpen={modal}
        toggle={this.toggle}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.toggle}>{currentTitle}</ModalHeader>

        {currentAction === "update" ? (
          <ModalBody>
            <Card className="card-default">
              <CardBody>
                <div className="row">
                  <div className="col">
                    <p className="text-danger">
                      *Validate your entry before submitting. Once a bargain is
                      created, user will be able to order the product on the
                      price you filled here.
                    </p>
                    <Form>
                      <>
                        <FormGroup row>
                          <Label for="description1" sm={5}>
                            Total Quantity <span className="star">*</span>
                          </Label>
                          <Col sm={7}>
                            <Input
                              type="text"
                              min="1"
                              step="1"
                              pattern="\d*"
                              name="supplierOfferOrderQuantity"
                              placeholder="Quantity"
                              value={formDataBargain.supplierOfferOrderQuantity}
                              onChange={this.handleChangeBargain}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="description1" sm={5}>
                            Price Per Quantity <span className="star">*</span>
                          </Label>
                          <Col sm={7}>
                            <Input
                              type="number"
                              min="1"
                              step="0.01"
                              name="supplierOfferUnitPrice"
                              placeholder="Final price"
                              value={formDataBargain.supplierOfferUnitPrice}
                              onChange={this.handleChangeBargain}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="delivery" sm={5}>
                            Delivery Charge <span className="star">*</span>
                          </Label>
                          <Col sm={7}>
                            <Input
                              type="text"
                              min="1"
                              step="1"
                              pattern="\d*"
                              name="supplierOfferDeliveryCharge"
                              placeholder="Delivery charge"
                              value={
                                formDataBargain.supplierOfferDeliveryCharge
                              }
                              onChange={this.handleChangeBargain}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="title" sm={5}>
                            Offer Expiry Date <span className="star">*</span>
                          </Label>
                          <Col sm={7}>
                            <DatePicker
                              className="form-control"
                              placeholderText="Click to select expiry date"
                              selected={formDataBargain.offerExpiryDate}
                              onChange={this.handleChangeExpiryDate}
                              dateFormat="dd MMMM yyyy"
                              minDate={new Date()}
                              withPortal
                            />
                          </Col>
                        </FormGroup>
                      </>

                      <FormGroup row>
                        <Col sm={12}>
                          <Input
                            type="textarea"
                            min="1"
                            name="supplierMessage"
                            placeholder="Any message for customer?"
                            value={formDataBargain.supplierMessage || ""}
                            onChange={this.handleChangeBargain}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col sm={12}>
                          <Button
                            block
                            color="primary"
                            size="md"
                            onClick={this.addUpdateBargain}
                            disabled={
                              !formDataBargain.supplierMessage ||
                              formDataBargain.supplierOfferUnitPrice < 1 ||
                              formDataBargain.supplierOfferOrderQuantity < 1
                            }
                          >
                            Create Bargain Offer
                          </Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </CardBody>
            </Card>
          </ModalBody>
        ) : null}

        {currentAction === "confirm" ? (
          <>
            <ModalBody>
              <Card className="card-default">
                <CardBody>
                  <p>Are you sure you want to {currentTitle} ?</p>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                size="sm"
                onClick={() =>
                  userType === "SUPPLIER"
                    ? this.updateOrderRequestProposal({ status: "CLOSED" })
                    : this.updateOrderRequestStatus({ status: "CLOSED" })
                }
              >
                Yes
              </Button>
              <Button color="secondary" size="sm" onClick={this.toggle}>
                No
              </Button>
            </ModalFooter>
          </>
        ) : null}
      </Modal>
    );
  };

  render() {
    const {
      orderRequest,
      isLoading,
      profile: { address },
      authData: { userId, userType },
      responseVal,
    } = this.props;
    const {
      formData,
      files,
      files2,
      userComment,
      selectedRowData,
      globalSearchKeyword,
      pageNumber,
      pageSize,
    } = this.state;

    const dataLoaded =
      responseVal.dObjectData &&
      responseVal.dObjectData.skuList &&
      responseVal.dObjectData.skuList.data &&
      responseVal.dObjectData.skuList.data.skus
        ? responseVal.dObjectData.skuList.data.skus
        : false;

    const dataLoadedSupplier =
      responseVal.dObjectData &&
      responseVal.dObjectData.supplierList &&
      responseVal.dObjectData.supplierList.data &&
      responseVal.dObjectData.supplierList.data.suppliers;

    const responseData = orderRequest && orderRequest.demand;
    const responseReply = orderRequest && orderRequest.demandResponse;
    const responseProposals = orderRequest && orderRequest.demandResponses;

    let messageLog = responseReply && responseReply.messages;

    if (userType !== "SUPPLIER") {
      messageLog = selectedRowData && selectedRowData.messages;
    }

    let future = new Date();
    future.setDate(future.getDate() + 10);

    let endDateTime = formData.offerExpiryDate
      ? new Date(formData.offerExpiryDate)
      : future;

    let pickupOptions = [];

    const pickUpAddress =
      address &&
      address.addressDetailListMap &&
      address.addressDetailListMap["PICKUP_ADDRESS"];

    pickUpAddress &&
      pickUpAddress.map((p) => {
        return pickupOptions.push({
          value: p.id,
          label:
            (p.status !== "ACTIVE" ? p.status + " - " : "") + p.fullAddress,
          isDisabled: p.status === "ACTIVE" ? false : true,
        });
      });

    const unitOfMeasureLabel = unitOfMeasures.find(
      (u) => u.value === (responseData && responseData.unitOfMeasure)
    );

    console.log("UN", responseData, unitOfMeasureLabel);

    return (
      <div>
        <PanelHeader />
        {this.state.modal ? this.renderModal() : null}
        <div className="content mt-3">
          <Row>
            <MessageBlock screenName="orderrequest" />
            <Col xs={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-0 pt-0">
                  <li className="breadcrumb-item">
                    <span onClick={() => this.props.history.goBack()}>
                      &laquo; Go Back
                    </span>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Order Request Details
                  </li>
                </ol>
              </nav>
              {responseData ? (
                <Row>
                  <Col sm={8}>
                    <>
                      <Card className="card--default">
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <Row>
                                <Col sm={6}>
                                  <h5 className="float--left">
                                    Customer's Requirement
                                  </h5>
                                </Col>

                                <Col sm={6}>
                                  {(responseData.status !== "CLOSED" &&
                                    (userType === "ADMIN" ||
                                      userType === "OPS")) ||
                                  (userType === "SUPPLIER" &&
                                    responseData.status !== "CLOSED" &&
                                    responseReply &&
                                    responseReply.status !== "CLOSED") ? (
                                    <div className="float-right">
                                      <Button
                                        onClick={() =>
                                          this.toggle({
                                            actionType: "CLOSE",
                                            action: "confirm",
                                            title: "Close Request",
                                          })
                                        }
                                        color="danger"
                                        size="sm"
                                        className="m-0"
                                      >
                                        Close Request
                                      </Button>
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>

                              <div className="clearfix" />
                              <p style={{ borderBottom: "2px solid #eee" }}>
                                <span className="item-ord">
                                  Created on:{" "}
                                  {moment(responseData.createdOn).format(
                                    "ddd, Do MMM 'YY, h:mm a"
                                  )}{" "}
                                </span>
                                <span className="item-ord float-right">
                                  Updated on:{" "}
                                  {moment(responseData.updatedOn).format(
                                    "ddd, Do MMM 'YY, h:mm a"
                                  )}{" "}
                                </span>
                              </p>
                            </Col>

                            <Col sm={6}>
                              <Row>
                                <Col sm={11}>
                                  <p className="pb-0 mb-0">
                                    Required By:{" "}
                                    <span className="float-right">
                                      {moment(responseData.requiredOn).format(
                                        "ddd, Do MMM 'YY"
                                      )}
                                    </span>
                                    <br />
                                    Total Budget:{" "}
                                    <span className="float-right">
                                      &#x20b9;
                                      {responseData.totalOfferAmount &&
                                        responseData.totalOfferAmount.toLocaleString()}
                                    </span>
                                    <br />
                                    Quantity:{" "}
                                    <span className="float-right">
                                      {responseData.quantity}{" "}
                                      {unitOfMeasureLabel &&
                                        unitOfMeasureLabel.label}
                                    </span>
                                  </p>
                                  <p className="pt-2">
                                    <span style={{ fontWeight: "bold" }}>
                                      Category:{" "}
                                    </span>
                                    <br />

                                    <span className="float--right">
                                      {responseData.catRefIdentifierNames &&
                                        responseData.catRefIdentifierNames.join(
                                          " » "
                                        )}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Col sm={6}>
                              {userType === "ADMIN" || userType === "OPS" ? (
                                <>
                                  <Row>
                                    <Col sm={6}>Current Status:</Col>
                                    <Col sm={6}>{responseData.status}</Col>
                                  </Row>

                                  <Row>
                                    <Col sm={6}>Customer ID:</Col>
                                    <Col sm={6}>
                                      <a
                                        href={
                                          ADMIN_CUSTOMER_VIEW +
                                          responseData.customerId
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <span className="float---right">
                                          {responseData.customerId}{" "}
                                        </span>
                                      </a>
                                    </Col>
                                  </Row>
                                </>
                              ) : null}
                              <Row>
                                <Col sm={6}>Demand ID:</Col>
                                <Col sm={6}>{responseData.id}</Col>
                              </Row>

                              <p className="pt-2">
                                <span style={{ fontWeight: "bold" }}>
                                  Delivery Address:
                                </span>
                                <br />
                                {responseData.deliveryAddress &&
                                  responseData.deliveryAddress.fullAddress}
                              </p>
                            </Col>

                            <Col sm={12}>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Customer Subject:
                                </span>
                                <br /> {responseData.subject}
                              </p>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Customer Message:
                                </span>
                                <br /> {responseData.description}
                              </p>
                            </Col>
                            <Col sm={12}>
                              {responseData.medias &&
                              responseData.medias.length ? (
                                <>
                                  <h6
                                    style={{
                                      textTransform: "none",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Sample Images
                                  </h6>
                                  <Row>
                                    {responseData.medias.map((m) => {
                                      return (
                                        <Col sm={2} key={m.fileId}>
                                          <ImageZoom
                                            src={getMediaUrl({
                                              fileId: m.fileId,
                                              fileExt: m.fileExt,
                                            })}
                                            alt=""
                                            className="img-fluid img-thumbnail mb-2"
                                          />
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </>
                              ) : null}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      {userType === "SUPPLIER" ? (
                        <>
                          {responseReply && responseReply.id ? null : (
                            <Row>
                              <Col>
                                <div
                                  className="price-invent sku-form-box samplepage"
                                  style={{ background: "#fff" }}
                                >
                                  <Row>
                                    <Col sm={8}>
                                      <h5 className="card-header">
                                        Add Your Response
                                      </h5>

                                      <div className="card-body">
                                        {formData ? (
                                          <Form>
                                            <fieldset
                                              disabled={
                                                responseData.status ===
                                                  "DELETED" ||
                                                responseData.ordered
                                              }
                                            >
                                              {/* <FormGroup row>
                                                    <Label
                                                      for="offerstatus"
                                                      sm={6}
                                                    >
                                                      Offer Status{" "}
                                                      <span className="star">
                                                        *
                                                      </span>
                                                    </Label>
                                                    <Col sm={6}>
                                                      <Select
                                                        placeholder="Select Action"
                                                        isSearchable={false}
                                                        options={
                                                          orderRequestStatusOptions
                                                        }
                                                        value={orderRequestStatusOptions.filter(
                                                          (option) =>
                                                            option.value ===
                                                            formData.status
                                                        )}
                                                        onChange={(opt) =>
                                                          this.handleChangeSelect(
                                                            "status",
                                                            opt
                                                          )
                                                        }
                                                      />
                                                    </Col>
                                                  </FormGroup> */}

                                              <FormGroup row>
                                                <Label
                                                  for="pickupaddress"
                                                  sm={6}
                                                >
                                                  Pickup Address{" "}
                                                  <span className="star">
                                                    *
                                                  </span>
                                                </Label>
                                                <Col sm={6}>
                                                  <Select
                                                    isSearchable={false}
                                                    className="react-select"
                                                    name="supplierPickUpAddressId"
                                                    options={pickupOptions}
                                                    value={pickupOptions.filter(
                                                      (option) =>
                                                        parseInt(
                                                          option.value
                                                        ) ===
                                                        parseInt(
                                                          formData.supplierPickUpAddressId
                                                        )
                                                    )}
                                                    onChange={(opt) =>
                                                      this.handleChangeSelect(
                                                        "supplierPickUpAddressId",
                                                        opt
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              </FormGroup>

                                              {formData.status !==
                                              "SUPPLIER_REJECTED" ? (
                                                <>
                                                  <FormGroup row>
                                                    <Label
                                                      for="description1"
                                                      sm={6}
                                                    >
                                                      Total Quantity{" "}
                                                      <span className="star">
                                                        *
                                                      </span>
                                                    </Label>
                                                    <Col sm={6}>
                                                      <Input
                                                        type="text"
                                                        min="1"
                                                        step="1"
                                                        pattern="\d*"
                                                        name="quantity"
                                                        placeholder="Quantity"
                                                        value={
                                                          formData.quantity > 0
                                                            ? formData.quantity
                                                            : ""
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                      />
                                                    </Col>
                                                  </FormGroup>
                                                  <FormGroup row>
                                                    <Label
                                                      for="description1"
                                                      sm={6}
                                                    >
                                                      Total Budget{" "}
                                                      <span className="star">
                                                        *
                                                      </span>
                                                    </Label>
                                                    <Col sm={6}>
                                                      <Input
                                                        type="number"
                                                        min="1"
                                                        step="1"
                                                        name="totalOfferAmount"
                                                        placeholder="Final price"
                                                        value={
                                                          formData.totalOfferAmount >
                                                          0
                                                            ? formData.totalOfferAmount
                                                            : ""
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                      />
                                                    </Col>
                                                  </FormGroup>

                                                  <FormGroup row>
                                                    <Label for="title" sm={6}>
                                                      Delivery By Date{" "}
                                                      <span className="star">
                                                        *
                                                      </span>
                                                    </Label>
                                                    <Col sm={6}>
                                                      <DatePicker
                                                        className="form-control"
                                                        placeholderText="Click to select delivery date"
                                                        selected={endDateTime}
                                                        onChange={
                                                          this
                                                            .handleChangeEndDate
                                                        }
                                                        dateFormat="dd MMMM yyyy"
                                                        minDate={new Date()}
                                                        withPortal
                                                      />
                                                    </Col>
                                                  </FormGroup>
                                                </>
                                              ) : null}

                                              <FormGroup row>
                                                <Col sm={12}>
                                                  <Input
                                                    type="textarea"
                                                    min="1"
                                                    name="supplierMessage"
                                                    placeholder="Any message for customer?"
                                                    value={
                                                      formData.supplierMessage ||
                                                      ""
                                                    }
                                                    onChange={this.handleChange}
                                                  />
                                                </Col>
                                              </FormGroup>

                                              <FormGroup>
                                                <Row>
                                                  <Label for="notes" sm={12}>
                                                    Attach Product Sample Images
                                                    (if any)
                                                  </Label>
                                                  <Col sm={12}>
                                                    <div className="mesup">
                                                      <Dropzone
                                                        onFilesAdded={(opt) =>
                                                          this.onDrop(opt)
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </FormGroup>

                                              <Row className="mt-2 mb-2">
                                                <Col sm={9}>
                                                  <Row>
                                                    {files &&
                                                      files.length > 0 &&
                                                      files.map(
                                                        (acceptedFile) => (
                                                          <Col
                                                            sm="6"
                                                            className=""
                                                            key={
                                                              acceptedFile.name
                                                            }
                                                          >
                                                            <img
                                                              alt={
                                                                acceptedFile.name
                                                              }
                                                              src={URL.createObjectURL(
                                                                acceptedFile
                                                              )}
                                                              className="img-thumbnail rounded"
                                                            />
                                                            <p className="text-center">
                                                              {
                                                                acceptedFile.name
                                                              }
                                                            </p>
                                                            <button
                                                              onClick={this.removeFromState(
                                                                acceptedFile
                                                              )}
                                                              type="button"
                                                              className="close img-remove"
                                                              aria-label="Close"
                                                            >
                                                              <span aria-hidden="true">
                                                                ×
                                                              </span>
                                                            </button>
                                                          </Col>
                                                        )
                                                      )}
                                                  </Row>
                                                </Col>
                                              </Row>

                                              <FormGroup row>
                                                <Col sm={12}>
                                                  <Button
                                                    block
                                                    color="primary"
                                                    size="md"
                                                    onClick={
                                                      this.replyOrderRequest
                                                    }
                                                    disabled={
                                                      !formData.supplierMessage ||
                                                      ((formData.totalOfferAmount <
                                                        1 ||
                                                        formData.quantity <
                                                          1) &&
                                                        formData.status ===
                                                          "SUPPLIER_QUOTE")
                                                    }
                                                  >
                                                    Add Response
                                                  </Button>
                                                </Col>
                                              </FormGroup>
                                            </fieldset>
                                          </Form>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          )}

                          {responseReply && responseReply.id ? (
                            <Row>
                              <Col>
                                <div
                                  style={{
                                    backgroundColor: "#fff",
                                    padding: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <p
                                    style={{
                                      paddingBottom: 3,
                                      fontWeight: "bold",
                                      borderBottom: "2px solid #ddd",
                                    }}
                                  >
                                    Your Proposal &nbsp; (Proposal ID:{" "}
                                    {responseReply.id})
                                  </p>

                                  <Row>
                                    <Col sm={8}>
                                      <Row>
                                        <Col sm={8}>
                                          <p className="row">
                                            <span className="col-sm-4">
                                              Status:
                                            </span>
                                            <span className="col-sm-8 text-right">
                                              {responseReply.status}
                                            </span>
                                            <br />
                                            <span className="col-sm-4">
                                              Quantity:
                                            </span>
                                            <span className="col-sm-8 text-right">
                                              {responseReply.offerQuantity}
                                            </span>
                                            <br />
                                            <span className="col-sm-4">
                                              Total Budget:
                                            </span>
                                            <span className="col-sm-8 text-right">
                                              &#x20b9;
                                              {responseReply.totalOfferAmount &&
                                                responseReply.totalOfferAmount.toLocaleString()}
                                            </span>
                                            <br />
                                            <span className="col-sm-4">
                                              Available By:
                                            </span>
                                            <span className="col-sm-8 text-right">
                                              {moment(
                                                responseReply.availableBy
                                              ).format("ddd, Do MMM 'YYYY")}
                                            </span>
                                            <br />
                                          </p>
                                          <p>
                                            <span style={{ fontWeight: 500 }}>
                                              Message:
                                            </span>
                                            <br /> {responseReply.reply}
                                          </p>
                                        </Col>
                                      </Row>
                                    </Col>

                                    <Col sm={4}>
                                      {responseReply.medias &&
                                      responseReply.medias.length ? (
                                        <>
                                          <h6
                                            style={{
                                              textTransform: "none",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            Sample Images
                                          </h6>
                                          <Row>
                                            {responseReply.medias.map((m) => {
                                              return (
                                                <Col sm={6} key={m.fileId}>
                                                  <ImageZoom
                                                    src={getMediaUrl({
                                                      fileId: m.fileId,
                                                      fileExt: m.fileExt,
                                                    })}
                                                    alt=""
                                                    className="img-fluid img-thumbnail mb-2"
                                                  />
                                                </Col>
                                              );
                                            })}
                                          </Row>
                                        </>
                                      ) : null}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ) : null}

                          {responseReply &&
                          responseReply.proposedSkus &&
                          responseReply.proposedSkus.length > 0 ? (
                            <Row>
                              <Col>
                                <div
                                  style={{
                                    backgroundColor: "#fff",
                                    padding: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <p
                                    style={{
                                      paddingBottom: 3,
                                      fontWeight: "bold",
                                      borderBottom: "2px solid #ddd",
                                    }}
                                  >
                                    Your Suggested Skus
                                  </p>

                                  <Row>
                                    <Col sm={12}>
                                      <Row>
                                        {responseReply.proposedSkus.map((p) => {
                                          const mdia = getMediaUrl({
                                            fileId: p.defaultImageFileId,
                                            fileExt: p.defaultImageFileExt,
                                          });
                                          return (
                                            <Col
                                              sm={6}
                                              key={"sugestin-" + p.id}
                                            >
                                              <div
                                                style={{
                                                  background: "#f2f2f2",
                                                }}
                                                className="bg--white p-3 mb-2"
                                              >
                                                <a
                                                  href={
                                                    (userType === "SUPPLIER"
                                                      ? SUPPLIER_SKU_VIEW
                                                      : ADMIN_SKU_VIEW) + p.id
                                                  }
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <Row>
                                                    <Col sm={3}>
                                                      <ImageZoom
                                                        key={"zoom--"}
                                                        src={mdia}
                                                        alt=""
                                                        style={{
                                                          width: "auto",
                                                          height: 60,
                                                          borderRadius: 5,
                                                        }}
                                                      />
                                                    </Col>
                                                    <Col sm={9}>
                                                      <p className="mb-1">
                                                        {p.title}
                                                      </p>
                                                      <Row>
                                                        <Col sm={6}>
                                                          <p className="pt-2 ">
                                                            {p.variantType +
                                                              ": " +
                                                              p.variantName}
                                                          </p>
                                                        </Col>
                                                        <Col sm={6}>
                                                          <p
                                                            className={
                                                              "pt-2 text-right text-capitalize " +
                                                              (p.status ===
                                                                "SHORTLISTED" ||
                                                              p.status ===
                                                                "ACCEPTED"
                                                                ? "text-success"
                                                                : "text-danger")
                                                            }
                                                          >
                                                            {p.status &&
                                                              p.status.toLowerCase()}{" "}
                                                          </p>
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                  </Row>
                                                </a>

                                                {p.customOfferSkuRequestId ? (
                                                  <Row>
                                                    <Col>
                                                      <p>
                                                        <a
                                                          href={
                                                            (userType ===
                                                            "SUPPLIER"
                                                              ? SUPPLIER_CUSTOM_VIEW
                                                              : ADMIN_CUSTOM_VIEW) +
                                                            p.customOfferSkuRequestId
                                                          }
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          View Created Offer
                                                        </a>
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                ) : null}

                                                {responseData.status !==
                                                  "CLOSED" &&
                                                responseReply.status !==
                                                  "CLOSED" ? (
                                                  <Row>
                                                    <Col>
                                                      <Button
                                                        className=" btn-sm btn-success"
                                                        onClick={() => {
                                                          this.openBargainPopup(
                                                            p
                                                          );
                                                        }}
                                                      >
                                                        Create Bargain
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                ) : null}
                                              </div>
                                            </Col>
                                          );
                                        })}
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ) : null}

                          {responseData.status !== "CLOSED" &&
                          responseReply &&
                          responseReply.id ? (
                            <Card className="card--default">
                              <CardBody>
                                <Row>
                                  <Col md="12">
                                    <p
                                      style={{
                                        marginTop: 10,
                                        paddingBottom: 3,
                                        fontWeight: "bold",
                                        borderBottom: "2px solid #ddd",
                                      }}
                                    >
                                      Search SKU and suggest in your Proposal
                                    </p>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                      <div className="has-search">
                                        <div className="input-group">
                                          <input
                                            type="search"
                                            name="globalSearchKeyword"
                                            className="form-control"
                                            placeholder="Search SKU..."
                                            value={globalSearchKeyword}
                                            onChange={this.searchHandler}
                                            onKeyDown={this.handleKeyDown}
                                            style={{
                                              background: "#f5f5f5",
                                            }}
                                            autoComplete="off"
                                          />
                                          <div className="input-group-append">
                                            <Button
                                              color="primary"
                                              onClick={this.searchButton}
                                            >
                                              <i className="now-ui-icons ui-1_zoom-bold" />
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    {dataLoaded ? this.renderPagination() : ""}
                                  </Col>
                                </Row>

                                <Table
                                  responsive
                                  className="table-striped table-bordered"
                                >
                                  <thead>
                                    <tr className="text-primary">
                                      <th className="text-center">#</th>
                                      <th>SKU ID</th>
                                      <th>SKU Title</th>

                                      <th>MRP</th>
                                      <th>Offer Price</th>

                                      <th>Variant</th>
                                      <th>Available</th>
                                      <th className="text-right">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dataLoaded ? (
                                      dataLoaded.length > 0 ? (
                                        dataLoaded.map((el, index) => {
                                          const row = el.source;

                                          const cname =
                                            row.status === "ACTIVE"
                                              ? "success"
                                              : row.status === "INACTIVE"
                                              ? "danger"
                                              : row.status === "ONHOLD"
                                              ? "warning"
                                              : "primary";
                                          return row ? (
                                            <tr
                                              key={index}
                                              className={
                                                (!row.mrp ||
                                                  !row.quantityAvailable) &&
                                                row.qcStatus === "APPROVED"
                                                  ? "bg-red"
                                                  : ""
                                              }
                                            >
                                              <td className="text-center">
                                                {pageNumber * pageSize +
                                                  (index + 1) -
                                                  pageSize}
                                              </td>
                                              <td>{row.id}</td>
                                              <td>{row.title}</td>

                                              <td>
                                                &#x20b9;
                                                {row.mrp &&
                                                  Math.round(
                                                    row.mrp
                                                  ).toLocaleString()}
                                              </td>
                                              <td>
                                                &#x20b9;
                                                {row.offerPrice &&
                                                  Math.round(
                                                    row.offerPrice
                                                  ).toLocaleString()}
                                              </td>

                                              <td>
                                                {row.variantType +
                                                  ":" +
                                                  row.variantName}
                                              </td>

                                              <td>
                                                {row.avail ? "YES" : "NO"}
                                              </td>

                                              <td className="text-right">
                                                <Button
                                                  className={`btn-simple btn-light btn-${cname}`}
                                                  size="xs"
                                                  style={{
                                                    lineHeight: "13px",
                                                  }}
                                                  title={row.status}
                                                  onClick={() =>
                                                    this.proposeSku(row)
                                                  }
                                                >
                                                  Suggest
                                                </Button>
                                              </td>
                                            </tr>
                                          ) : null;
                                        })
                                      ) : (
                                        <tr>
                                          <td colSpan="8" className="no-data">
                                            No data available
                                          </td>
                                        </tr>
                                      )
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="8"
                                          className="no-data"
                                          style={{ height: 100 }}
                                        >
                                          Search to view SKUs
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </CardBody>
                            </Card>
                          ) : null}
                        </>
                      ) : null}

                      {userType === "ADMIN" || userType === "OPS" ? (
                        <>
                          {responseData && responseData.supplierUserIdNames ? (
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col md="12">
                                    <p
                                      style={{
                                        marginTop: 10,
                                        paddingBottom: 3,
                                        fontWeight: "bold",
                                        borderBottom: "2px solid #ddd",
                                      }}
                                    >
                                      Proposals Sent to Following Suppliers (
                                      {
                                        Object.keys(
                                          responseData.supplierUserIdNames
                                        ).length
                                      }
                                      )
                                    </p>
                                  </Col>
                                </Row>
                                <Table
                                  responsive
                                  className="table-striped table-bordered"
                                >
                                  <thead>
                                    <tr className="text-primary">
                                      <th className="text-center">#</th>
                                      <th>ID</th>
                                      <th title="">Supplier Name</th>

                                      <th className="text-right">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.keys(
                                      responseData.supplierUserIdNames
                                    ).map((k, index) => {
                                      const supplierName =
                                        responseData.supplierUserIdNames[k];
                                      return (
                                        <tr key={"cc-" + index}>
                                          <td className="text-center">
                                            {index + 1}
                                          </td>

                                          <td>{k}</td>
                                          <td>
                                            <a
                                              href={ADMIN_SUPPLIER_VIEW + k}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {supplierName}
                                            </a>
                                          </td>

                                          <td className="text-right">
                                            {responseData.status !==
                                            "CLOSED" ? (
                                              <Button
                                                className={`btn-simple btn-light btn-danger`}
                                                size="xs"
                                                onClick={() =>
                                                  this.sendProposal({
                                                    supplierUserId: k,
                                                    businessName: supplierName,
                                                    action: "remove",
                                                  })
                                                }
                                              >
                                                Withdraw Proposal
                                              </Button>
                                            ) : null}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </CardBody>
                            </Card>
                          ) : null}

                          {responseProposals && responseProposals.length ? (
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col md="12">
                                    <p
                                      style={{
                                        marginTop: 10,
                                        paddingBottom: 3,
                                        fontWeight: "bold",
                                        borderBottom: "2px solid #ddd",
                                      }}
                                    >
                                      Suppliers Who Have Accepted Proposal (
                                      {responseProposals.length})
                                    </p>
                                  </Col>
                                </Row>
                                <Table
                                  responsive
                                  className="table-striped table-bordered"
                                >
                                  <thead>
                                    <tr className="text-primary">
                                      <th className="text-center">#</th>
                                      <th>Ref No</th>
                                      <th title="">Supplier</th>
                                      <th title="">Subject</th>
                                      <th>Quantity</th>
                                      <th>Amount</th>
                                      <th>Created Date</th>
                                      <th>Update Date</th>
                                      <th className="text-right">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {responseProposals.map((row, index) => {
                                      return (
                                        <tr
                                          style={{ cursor: "pointer" }}
                                          key={index}
                                          onClick={() =>
                                            this.handleHistory(index, row)
                                          }
                                          className={
                                            this.state.selectedRow === index
                                              ? "active"
                                              : null
                                          }
                                        >
                                          <td className="text-center">
                                            {index + 1}
                                          </td>

                                          <td>{row.id}</td>
                                          <td>
                                            <a
                                              href={
                                                ADMIN_SUPPLIERR_VIEW +
                                                row.supplierId
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {row.supplierName}
                                            </a>
                                          </td>
                                          <td>{row.reply}</td>
                                          <td>{row.offerQuantity}</td>
                                          <td>
                                            &#x20b9;
                                            {row.totalOfferAmount &&
                                              row.totalOfferAmount.toLocaleString()}
                                          </td>

                                          <td
                                            title={moment(row.createdOn).format(
                                              "ddd, Do MMM 'YY, h:mm a"
                                            )}
                                          >
                                            {moment(row.createdOn).format(
                                              "Do MMM, h:mm a"
                                            )}
                                          </td>

                                          <td
                                            title={moment(row.updatedOn).format(
                                              "ddd, Do MMM 'YY, h:mm a"
                                            )}
                                          >
                                            {moment(row.updatedOn).format(
                                              "Do MMM, h:mm a"
                                            )}
                                          </td>
                                          <td className="text-right">
                                            <Button
                                              className={`btn-simple btn-light btn-primary`}
                                              size="xs"
                                              onClick={null}
                                            >
                                              View Messages
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </CardBody>
                            </Card>
                          ) : null}

                          {responseData.status !== "CLOSED" ? (
                            <Card className="card--default">
                              <CardBody>
                                <Row>
                                  <Col md="12">
                                    <p
                                      style={{
                                        marginTop: 10,
                                        paddingBottom: 3,
                                        fontWeight: "bold",
                                        borderBottom: "2px solid #ddd",
                                      }}
                                    >
                                      Search Supplier and Send Proposal
                                    </p>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                      <div className="has-search">
                                        <div className="input-group">
                                          <input
                                            type="search"
                                            name="globalSearchKeyword"
                                            className="form-control"
                                            placeholder="Search Supplier..."
                                            value={globalSearchKeyword}
                                            onChange={
                                              this.searchHandlerSupplier
                                            }
                                            onKeyDown={
                                              this.handleKeyDownSupplier
                                            }
                                            style={{ background: "#f5f5f5" }}
                                            autoComplete="off"
                                          />
                                          <div className="input-group-append">
                                            <Button
                                              color="primary"
                                              onClick={
                                                this.searchButtonSupplier
                                              }
                                            >
                                              <i className="now-ui-icons ui-1_zoom-bold" />
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    {/* {dataLoadedSupplier ? this.renderPagination() : ""} */}
                                  </Col>
                                </Row>

                                <Table
                                  responsive
                                  className="table-striped table-bordered"
                                >
                                  <thead>
                                    <tr className="text-primary">
                                      <th className="text-center">#</th>
                                      <th>ID</th>
                                      <th>Supplier Name</th>
                                      <th>Mobile</th>
                                      <th>Verified</th>
                                      <th>Status</th>
                                      <th>Registered On</th>
                                      <th className="text-right">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dataLoadedSupplier ? (
                                      dataLoadedSupplier.length > 0 ? (
                                        dataLoadedSupplier.map((row, index) => {
                                          const cname =
                                            row.status === "ACTIVE"
                                              ? "success"
                                              : row.status === "INACTIVE"
                                              ? "danger"
                                              : row.status === "ONHOLD"
                                              ? "warning"
                                              : "primary";
                                          return row ? (
                                            <tr key={index}>
                                              <td className="text-center">
                                                {pageNumber * pageSize +
                                                  (index + 1) -
                                                  pageSize}
                                              </td>
                                              <td>{row.supplierUserId}</td>
                                              <td>
                                                <a
                                                  href={
                                                    ADMIN_SUPPLIER_VIEW +
                                                    row.supplierUserId
                                                  }
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {row.businessName}
                                                </a>
                                              </td>

                                              <td>
                                                {row.mobileCountryCode +
                                                  row.mobileNumber}
                                              </td>

                                              <td>
                                                {row.verified ? "YES" : "NO"}
                                              </td>
                                              <td>{row.status}</td>
                                              <td
                                                title={moment(
                                                  row.createdOn
                                                ).format(
                                                  "ddd, Do MMM 'YY, h:mm a"
                                                )}
                                              >
                                                {moment(row.createdOn).format(
                                                  "Do MMM, h:mm a"
                                                )}
                                              </td>

                                              <td className="text-right">
                                                <Button
                                                  className={`btn-simple btn-light btn-${cname}`}
                                                  size="xs"
                                                  style={{
                                                    lineHeight: "13px",
                                                  }}
                                                  title={row.status}
                                                  onClick={() =>
                                                    this.sendProposal({
                                                      ...row,
                                                      action: "add",
                                                    })
                                                  }
                                                >
                                                  Send Proposal
                                                </Button>
                                              </td>
                                            </tr>
                                          ) : null;
                                        })
                                      ) : (
                                        <tr>
                                          <td colSpan="8" className="no-data">
                                            No data available
                                          </td>
                                        </tr>
                                      )
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="8"
                                          className="no-data"
                                          style={{ height: 100 }}
                                        >
                                          Search to view Suppliers
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </CardBody>
                            </Card>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  </Col>
                  <Col sm="4">
                    <h5>Proposal Message history log</h5>

                    <div id="frame">
                      <div className="content">
                        {selectedRowData && selectedRowData.supplierName ? (
                          <div className="bg-white p-2">
                            <p className="title">
                              {selectedRowData.supplierName}
                            </p>

                            <p className="mb-0">
                              Required By:{" "}
                              <span className="float-right">
                                {moment(selectedRowData.requiredOn).format(
                                  "ddd, Do MMM 'YY"
                                )}
                              </span>
                              <br />
                              Total Budget:{" "}
                              <span className="float-right">
                                &#x20b9;
                                {selectedRowData.totalOfferAmount &&
                                  selectedRowData.totalOfferAmount.toLocaleString()}
                              </span>
                              <br />
                              Quantity:{" "}
                              <span className="float-right">
                                {selectedRowData.offerQuantity}{" "}
                                {selectedRowData.unitOfMeasure}
                              </span>
                            </p>
                          </div>
                        ) : null}

                        <div className="messages">
                          <ul
                            style={{
                              paddingTop: 20,
                              maxHeight: 666,
                              overflow: "auto",
                            }}
                            ref={this.frameRef}
                          >
                            {selectedRowData && selectedRowData.reply ? (
                              <li className="replies">
                                <p
                                  style={{
                                    position: "relative",
                                    minWidth: 220,
                                  }}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: -20,
                                      right: 0,
                                      color: "black",
                                    }}
                                  >
                                    supplier:{" "}
                                    {moment(
                                      selectedRowData.createdOn
                                    ).fromNow()}
                                  </span>
                                  <span>{selectedRowData.reply}</span>{" "}
                                </p>
                              </li>
                            ) : null}
                            {messageLog &&
                              messageLog.map((message, index) => {
                                let float =
                                  userId === message.userId ? "right" : "left";
                                let mType =
                                  userId === message.userId
                                    ? "replies"
                                    : "sent";
                                const uName =
                                  userType === message.userType
                                    ? "You"
                                    : message.userType;

                                if (uName === "SUPPLIER") {
                                  float = "right";
                                  mType = "replies";
                                }

                                return (
                                  <li className={mType} key={"mess-" + index}>
                                    <p
                                      style={{
                                        position: "relative",
                                        minWidth: 220,
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: -20,
                                          [float]: 0,
                                          color: "black",
                                        }}
                                      >
                                        {uName && uName.toLowerCase()}:{" "}
                                        {moment(message.createdOn).fromNow()}
                                      </span>
                                      <span>{message.message}</span>
                                      {message.proposedSku ? (
                                        <>
                                          <span className="row">
                                            <span className="mb-0 p-2 pl-3 font-weight-bold">
                                              Suggested Product
                                            </span>
                                          </span>
                                          <span className="row">
                                            {message.proposedSku
                                              .defaultImageFileId &&
                                            message.proposedSku
                                              .defaultImageFileExt ? (
                                              <span className="col-sm-3">
                                                <ImageZoom
                                                  key={"zoom-"}
                                                  src={getMediaUrl({
                                                    fileId:
                                                      message.proposedSku
                                                        .defaultImageFileId,
                                                    fileExt:
                                                      message.proposedSku
                                                        .defaultImageFileExt,
                                                  })}
                                                  alt=""
                                                  style={{
                                                    width: "auto",
                                                    height: 60,
                                                    borderRadius: 5,
                                                  }}
                                                />
                                              </span>
                                            ) : null}
                                            <a
                                              className="col-sm-9"
                                              href={
                                                (userType === "SUPPLIER"
                                                  ? SUPPLIER_SKU_VIEW
                                                  : ADMIN_SKU_VIEW) +
                                                message.proposedSku.id
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {message.proposedSku.title}
                                            </a>
                                          </span>
                                        </>
                                      ) : null}
                                      <br />
                                      <span>
                                        {message.medias &&
                                          Object.keys(message.medias).map(
                                            (k, index) => {
                                              const m = message.medias[k];

                                              const path = getMediaUrl({
                                                fileId: m.fileId,
                                                fileExt: m.fileExt,
                                              });

                                              return (
                                                <ImageZoom
                                                  key={"zoom-" + index}
                                                  src={path}
                                                  alt=""
                                                  style={{
                                                    width: "auto",
                                                    height: 60,
                                                    borderRadius: 5,
                                                  }}
                                                />
                                              );
                                            }
                                          )}
                                      </span>{" "}
                                    </p>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        {userType === "SUPPLIER" &&
                        responseReply &&
                        responseData.status !== "CLOSED" ? (
                          // <div className="message-input">
                          //   <div className="wrap">
                          //     <input
                          //       type="text"
                          //       name="userComment"
                          //       placeholder="Write your message..."
                          //       value={userComment || ""}
                          //       onChange={this.handleChangeData}
                          //       onKeyDown={this.handleKeyDownReply}
                          //     />

                          //     <button
                          //       className="submit"
                          //       onClick={this.addOrderRequestReply}
                          //       disabled={!userComment}
                          //     >
                          //       <i
                          //         className="now-ui-icons ui-1_send"
                          //         aria-hidden="true"
                          //       />
                          //     </button>
                          //   </div>
                          // </div>

                          <div
                            className="price-invent sku-form-box"
                            style={{ minHeight: "auto" }}
                          >
                            <h5 className="card-header">
                              Reply to this Proposal
                            </h5>
                            <div className="card-body">
                              <Form>
                                <FormGroup row>
                                  <Col sm={12}>
                                    <Input
                                      style={{ background: "#fff" }}
                                      type="textarea"
                                      name="userComment"
                                      placeholder="Type your message here"
                                      value={userComment || ""}
                                      onChange={this.handleChangeData}
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Label for="notes" sm={3}>
                                      Attach Image
                                    </Label>
                                    <Col sm={9}>
                                      <div className="mesup">
                                        <Dropzone
                                          onFilesAdded={(opt) =>
                                            this.onDrop2(opt)
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <Row className="mt-2 mb-2">
                                  <Col sm={3}></Col>
                                  <Col sm={9}>
                                    <Row>
                                      {files2 &&
                                        files2.length > 0 &&
                                        files2.map((acceptedFile) => (
                                          <Col
                                            sm="6"
                                            className=""
                                            key={acceptedFile.name}
                                          >
                                            <img
                                              alt={acceptedFile.name}
                                              src={URL.createObjectURL(
                                                acceptedFile
                                              )}
                                              className="img-thumbnail rounded"
                                            />
                                            <p className="text-center">
                                              {acceptedFile.name}
                                            </p>
                                            <button
                                              onClick={this.removeFromState2(
                                                acceptedFile
                                              )}
                                              type="button"
                                              className="close img-remove"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </Col>
                                        ))}
                                    </Row>
                                  </Col>
                                </Row>

                                <FormGroup row>
                                  <Col sm={12}>
                                    <Button
                                      style={{ margin: 0, borderRadius: 0 }}
                                      block
                                      color="primary"
                                      size="md"
                                      onClick={this.addOrderRequestReply}
                                      disabled={!userComment}
                                    >
                                      Add Message
                                    </Button>
                                  </Col>
                                </FormGroup>
                              </Form>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Col>
            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    orderRequest: state.orderRequestItems.orderRequest,
    authData: state.auth,
    profile: state.profile,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAddress: (reqData) => dispatch(getAddress(reqData)),
    getProfile: (reqData) => dispatch(getProfile(reqData)),
    fetchOrderRequest: (reqData) => dispatch(fetchOrderRequest(reqData)),
    replyOrderRequest: (reqData) => dispatch(replyOrderRequest(reqData)),
    addOrderRequestReply: (reqData) => dispatch(addOrderRequestReply(reqData)),
    orderRequestProposeSku: (reqData) =>
      dispatch(orderRequestProposeSku(reqData)),
    getSuppliersList: (reqData) => dispatch(getSuppliersList(reqData)),
    orderRequestAttachSupplier: (reqData) =>
      dispatch(orderRequestAttachSupplier(reqData)),
    searchSupplierSku: (reqData) => dispatch(searchSupplierSku(reqData)),
    addUpdateBargainOffer: (reqData) =>
      dispatch(addUpdateBargainOffer(reqData)),
    addUpdateOrderRequest: (reqData) =>
      dispatch(addUpdateOrderRequest(reqData)),
    updateOrderRequestProposal: (reqData) =>
      dispatch(updateOrderRequestProposal(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderRequest);
