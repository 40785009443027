import React from "react";
import { Alert } from "reactstrap";

class SuccessBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  componentDidMount() {
    if (this.props.position !== "alert-relative") {
      this.timeout = setTimeout(() => {
        this.setState({ visible: false });
      }, 3000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onDismiss = () => {
    this.setState({ visible: false });
  };

  render() {
    const { msg, position, color } = this.props;
    const displayMsg =
      msg && msg.userDisplayMsg && msg.userDisplayMsg.length > 0 ? msg.userDisplayMsg : "Updated successfully";

    return displayMsg ? (
      <Alert
        color={color ? color : "success"}
        isOpen={this.state.visible}
        toggle={this.onDismiss}
        className={position ? position : ""}
      >
        {displayMsg.constructor === Array ? (
          displayMsg.map((index) => {
            return <div key={"msg" + index}>{index}</div>;
          })
        ) : (
          <div key="outer-extreme-msg">{displayMsg}</div>
        )}
      </Alert>
    ) : null;
  }
}

export default SuccessBlock;
