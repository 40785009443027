import { startUILoading, stopUILoading } from "./uiActions";
import {
  setResponseValues,
  resetResponseValues,
  resetObjectValues,
  //resetObjectValues
} from "./responseHandlerActions";

import axios from "axios";
import {
  SEARCH_ORDERS,
  FETCH_ORDER,
  UPDATE_ORDER,
  UPDATE_ORDER_STATUS,
  SEARCH_CUSTOMER,
  FETCH_CUSTOMER,
  UPDATE_ORDER_WAREHOUSE,
  GENERATE_INVOICE,
  UPDATE_DELIVERY_INFO,
  UPDATE_TRACKING_INFO,
} from "../../utils/constants/apiUrls";
import { fetchProduct } from "./adminActions";
import { fetchSku } from "./skuActions";
import { getProfile } from "./userActions";
import { getRequestHeaders } from "./authActions";
import { SUCCESS, FAILURE } from "../../utils/constants";

export const searchOrder = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };
      const postData = { ...reqParam, ...defaultParam };
      const response = await axios.post(SEARCH_ORDERS, postData, config);
      console.log("SEARCH ORDERS Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "orderList"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const fetchOrder = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(resetObjectValues("orderDetail"));

    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };
      const postData = { ...reqParam, ...defaultParam };

      const response = await axios.post(FETCH_ORDER, postData, config);
      const product = response.data?.data?.order?.defaultOrderLineItem;

      if (product) {
        let fetchPickupAddress = false;
        if (defaultParam.userType === "SUPPLIER") {
          dispatch(
            fetchSku({
              skuId: product.skuId,
            })
          );
        } else {
          dispatch(
            fetchProduct({
              productId: product.productId,
              variantId: product.variantId,
            })
          );
          fetchPickupAddress = true;
        }
        dispatch(getProfile({ sUserId: product.supplierId, fetchPickupAddress }));
      }
      dispatch(setResponseValues(SUCCESS, response.data, null, "orderDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const updateOrder = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };
      const postData = { ...defaultParam, order: reqParam.order };

      let URL = reqParam.updateType === "status" ? UPDATE_ORDER_STATUS : UPDATE_ORDER;

      if (reqParam.order?.status === "UPDATE_WAREHOUSE") {
        URL = UPDATE_ORDER_WAREHOUSE;
      } else if (reqParam.order?.status === "UPDATE_DELIVERY_INFO") {
        URL = UPDATE_DELIVERY_INFO;
      }

      const response = await axios.post(URL, postData, config);

      console.log("Update Order Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "orderDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const updateTracking = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());

    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };
      const postData = { ...defaultParam, order: reqParam.order };

      let URL = UPDATE_TRACKING_INFO;

      const response = await axios.post(URL, postData, config);

      console.log("Update Tracking Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "orderDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const searchCustomer = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    try {
      const config = await dispatch(getRequestHeaders());
      const URL = SEARCH_CUSTOMER;

      const { userId, userType } = getState().auth;
      const defaultParam = { userId, userType };

      const postData = { ...reqParam, ...defaultParam };

      const response = await axios.post(URL, postData, config);

      console.log("Get customers List Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "customerList"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const fetchCustomer = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(resetObjectValues("orderList"));

    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };
      const postData = { ...reqParam, ...defaultParam };
      const URL = FETCH_CUSTOMER + reqParam.customer.customerId;
      const response = await axios.post(URL, postData, config);

      console.log("FETCH ORDER Success Executed", response.data);
      const product = response.data?.data?.order?.defaultOrderLineItem;

      if (product) {
        dispatch(
          fetchProduct({
            productId: product.productId,
            variantId: product.variantId,
          })
        );
        dispatch(getProfile({ supplierUserId: product.supplierId }));
      }
      dispatch(setResponseValues(SUCCESS, response.data, null, "customerDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const updateCustomer = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());

    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };
      const postData = { ...defaultParam, order: reqParam.order };

      const URL = reqParam.updateType === "status" ? UPDATE_ORDER_STATUS : UPDATE_ORDER;

      const response = await axios.post(URL, postData, config);

      console.log("Update Order Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "orderDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const generateInvoice = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());

    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };
      const postData = { ...defaultParam, ...reqParam };
      const URL = GENERATE_INVOICE;
      const response = await axios.post(URL, postData, config);
      console.log("Generate Invoice Success Executed", response.data);
      //dispatch(setResponseValues(SUCCESS, response.data, null, "invoice"));
      dispatch(setResponseValues(SUCCESS, response.data, null, "orderDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const updateDeliveryInfo = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());

    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };
      const postData = { ...defaultParam, ...reqParam };
      const URL = UPDATE_DELIVERY_INFO;
      const response = await axios.post(URL, postData, config);
      console.log("Update delivery info Success Executed", response.data);
      //dispatch(setResponseValues(SUCCESS, response.data, null, "invoice"));
      //dispatch(setResponseValues(SUCCESS, response.data, null, "orderDetail"));
    } catch (error) {
      dispatch(setResponseValues(FAILURE, null, [error?.response?.data]));
    } finally {
      dispatch(stopUILoading());
    }
  };
};
