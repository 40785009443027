import React from "react";
import { Col, Label, Button, Form, FormGroup, Input } from "reactstrap";

import Select from "react-select";
import { userGroupTypes, catTrendsDisplayStatusTypes } from "../../utils/constants";
//import TooltipItem from "../../components/Tooltip/Tooltip";
//import helpIcon from "../../assets/img/question-mark.svg";

class TrendForm extends React.Component {
  state = {};
  discardInput = (e) => {
    return;
  };
  render() {
    const {
      trendId,
      description,
      userGroupType,
      displayPriorityStatus,
      authorDisplayName,
      tags,
      summary,
      status,
      medias,
    } = this.props.form;

    const { handleChange, handleChangeSelect, addUpdateTrend, categoryChain, enableTrend, files } = this.props;

    return (
      <div className="sku-form-box" style={{ fontSize: "100%" }}>
        <Form autoComplete="off" className="form-horizontal">
          {trendId ? (
            <>
              <FormGroup row>
                <Label for="title" sm={3} className="mt-1">
                  Trend Status <span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">{status}</p>
                    {status === "ACTIVE" ? (
                      <Button size="sm" className="btn-" color="success" onClick={enableTrend("INACTIVE")}>
                        Deactivate Trend
                      </Button>
                    ) : (
                      <Button size="sm" className="btn-" color="danger" onClick={enableTrend("ACTIVE")}>
                        Activate Trend
                      </Button>
                    )}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="title" sm={3}>
                  Trend ID<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input type="text" onChange={this.discardInput} disabled={trendId} value={trendId || ""} />
                </Col>
              </FormGroup>
            </>
          ) : null}

          {categoryChain ? (
            <FormGroup row>
              <Label for="title" sm={3}>
                Category <span className="star">*</span>
              </Label>
              <Col sm={9}>
                <Input type="textarea" onChange={this.discardInput} disabled value={categoryChain.join(" / ") || ""} />
              </Col>
            </FormGroup>
          ) : null}

          <FormGroup row>
            <Label for="title" sm={3}>
              Summary <span className="star">*</span>
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                name="summary"
                placeholder="Enter summary"
                value={summary || ""}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="variant" sm={3}>
              Display Status Type <span className="star">*</span>
            </Label>
            <Col sm={9}>
              <Select
                isSearchable={false}
                name="displayPriorityStatus"
                options={catTrendsDisplayStatusTypes}
                placeholder="Select display status type"
                value={catTrendsDisplayStatusTypes.filter((option) => option.value === displayPriorityStatus)}
                onChange={(opt) => handleChangeSelect("displayPriorityStatus", opt)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="variant" sm={3}>
              User Group Type<span className="star">*</span>
            </Label>
            <Col sm={9}>
              <Select
                isSearchable={false}
                options={userGroupTypes}
                placeholder="Select user group"
                value={userGroupTypes.filter((option) => option.value === userGroupType)}
                onChange={(opt) => handleChangeSelect("userGroupType", opt)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="variant" sm={3}>
              Author Display Name <span className="star">*</span>
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                name="authorDisplayName"
                placeholder="Enter author name"
                value={authorDisplayName || ""}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="description" sm={3}>
              Description
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                name="description"
                placeholder="Enter description"
                value={description || ""}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="description1" sm={3}>
              Tags
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                name="tags"
                placeholder="Enter tags separated by comma ,"
                value={tags || ""}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="btnn" sm={3} />
            <Col sm={9}>
              <Button
                block
                color="primary"
                size="md"
                onClick={addUpdateTrend}
                disabled={
                  !summary ||
                  !userGroupType ||
                  !authorDisplayName ||
                  !displayPriorityStatus ||
                  (Object.keys(medias).length < 1 && files.length < 1)
                }
              >
                {trendId ? "Update" : "Add"} Trend
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default TrendForm;
