import React from "react";
import { Row, Col, FormGroup, Card, CardBody, Button, ButtonToolbar, Table } from "reactstrap";

import { connect } from "react-redux";
import { searchSku } from "../../datautils/actions/skuActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";

import { SUPPLIER_SKU_VIEW, ADMIN_SKU_VIEW, SUPPLIER_SKU_ADD } from "../../utils/constants/clientUrls";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { sortIcon } from "../../utils/constants/icons";
import { PER_PAGE } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import MessageBlock from "../../components/MessageBlock/MessageBlock";

class SkuList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: this.props.userType === "SUPPLIER" ? "3" : "2",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "skuId",
      ascending: false,
    };
  }

  componentDidMount() {
    this.searchSku();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchSku();
    }
  }

  searchHandler = (e) => {
    this.setState({
      globalSearchKeyword: e.target.value,
    });
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchSku()
    );
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.searchSku()
      );
    }
  };

  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.searchSku()
    );
  };

  searchSku = () => {
    const { globalSearchKeyword, currentTab, pageNumber, pageSize, sortField, ascending } = this.state;

    // let tab4 = {};

    // if (currentTab === "4") {
    //   tab4 = ;
    // }

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [
        {
          key: "qcStatus",
          operation: "EQUAL_TO",
          value: currentTab === "4" ? "3" : currentTab,
        },
        {
          ...(currentTab === "4" ? { key: "status", operation: "EQUAL_TO", value: "0" } : {}),
        },
      ],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.props.searchSku(reqData);
  };

  handleChange = (input) => (e) => {
    if (this.props.userType === "SUPPLIER") {
      this.props.history.push(SUPPLIER_SKU_VIEW + input);
    } else {
      this.props.history.push(ADMIN_SKU_VIEW + input);
    }
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  filterSku = (e) => {
    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
      },
      () => this.searchSku()
    );
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.skuList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  render() {
    const { responseVal } = this.props;
    const { globalSearchKeyword, pageNumber, pageSize, currentTab, ascending } = this.state;

    const dataLoaded = responseVal.dObjectData?.skuList?.data?.skus;

    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs={12}>
              <MessageBlock screenName="sku" />
            </Col>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}
            </Col>
            <Col xs={12}>
              <ButtonToolbar style={{ display: "block" }}>
                <Button size="sm" value="-1" onClick={this.filterSku} color={currentTab === "-1" ? "primary" : ""}>
                  Rejected
                </Button>
                <Button size="sm" value="0" onClick={this.filterSku} color={currentTab === "0" ? "primary" : ""}>
                  Incomplete
                </Button>
                <Button size="sm" value="1" onClick={this.filterSku} color={currentTab === "1" ? "primary" : ""}>
                  EnQueued
                </Button>
                <Button size="sm" value="2" onClick={this.filterSku} color={currentTab === "2" ? "primary" : ""}>
                  IN Progress
                </Button>
                <Button size="sm" value="3" onClick={this.filterSku} color={currentTab === "3" ? "primary" : ""}>
                  Approved
                </Button>
                <Button size="sm" value="4" onClick={this.filterSku} color={currentTab === "4" ? "primary" : ""}>
                  Approved and InActive
                </Button>
                <Button size="sm" value="" onClick={this.filterSku} color={currentTab === "" ? "primary" : ""}>
                  ALL SKU
                </Button>
                {this.props.userType === "SUPPLIER" ? (
                  <Button
                    size="sm"
                    className="float-right"
                    onClick={() => this.props.history.push(SUPPLIER_SKU_ADD)}
                    color="secondary"
                  >
                    Add New SKU
                  </Button>
                ) : null}
              </ButtonToolbar>
              <Card>
                <CardBody className="listings">
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <div className="has-search">
                          <div className="input-group">
                            <input
                              type="search"
                              name="globalSearchKeyword"
                              className="form-control"
                              placeholder="Search SKU..."
                              value={globalSearchKeyword}
                              onChange={this.searchHandler}
                              onKeyDown={this.handleKeyDown}
                              style={{ background: "#f5f5f5" }}
                              autoComplete="off"
                            />
                            <div className="input-group-append">
                              <Button color="primary" onClick={this.searchButton}>
                                <i className="now-ui-icons ui-1_zoom-bold" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="8">{dataLoaded ? this.renderPagination() : ""}</Col>
                  </Row>

                  <Table responsive className="table-striped table-bordered">
                    <thead>
                      <tr className="text-primary">
                        <th className="text-center">#</th>
                        <th onClick={this.sortButton("skuId", ascending ? false : true)}>
                          SKU ID <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th onClick={this.sortButton("title", ascending ? false : true)}>
                          SKU Title <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th>Category</th>
                        <th onClick={this.sortButton("brandName", ascending ? false : true)}>
                          Brand <img alt="sort" className="sort-icon" src={sortIcon} />
                        </th>
                        <th>QC Status</th>
                        <th>MRP</th>
                        <th>Sale Price</th>
                        <th>Final Seller Price</th>
                        <th>Max Quantity</th>
                        <th>Avail Quantity</th>
                        <th>Variant</th>
                        <th>Petstyle Code</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataLoaded ? (
                        dataLoaded.length ? (
                          dataLoaded.map((row, index) => {
                            const cname =
                              row.status === "ACTIVE"
                                ? "success"
                                : row.status === "INACTIVE"
                                ? "danger"
                                : row.status === "ONHOLD"
                                ? "warning"
                                : "primary";
                            return (
                              <tr
                                key={index}
                                className={
                                  (!row.mrp || !row.quantityAvailable) && row.qcStatus === "APPROVED" ? "bg-red" : ""
                                }
                              >
                                <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>
                                <td onClick={this.handleChange(row.skuId)} className="cursor">
                                  {row.skuId}
                                </td>
                                <td onClick={this.handleChange(row.skuId)} className="cursor">
                                  {row.title}
                                </td>
                                <td>{row.categoryName}</td>
                                <td>{row.brandName}</td>
                                <td>{row.qcStatus}</td>
                                <td>
                                  &#x20b9;
                                  {row.mrp && Math.round(row.mrp).toLocaleString()}
                                </td>
                                <td>
                                  &#x20b9;
                                  {row.salePrice && Math.round(row.salePrice).toLocaleString()}
                                </td>
                                <td>
                                  &#x20b9;
                                  {row.finalSellerPrice && Math.round(row.finalSellerPrice).toLocaleString()}
                                </td>
                                <td>{row.maxQuantityOrder}</td>
                                <td>{row.quantityAvailable}</td>
                                <td>{row.variantType + ":" + row.variant}</td>
                                <td>{row.petStyleCode}</td>

                                <td className="text-right">
                                  <Button
                                    className={`btn-simple btn-light btn-${cname}`}
                                    size="xs"
                                    onClick={this.handleChange(row.skuId)}
                                    style={{ lineHeight: "13px" }}
                                  >
                                    {(!row.mrp || !row.quantityAvailable) && row.qcStatus === "APPROVED"
                                      ? "Update Price/Inventory"
                                      : "View"}
                                  </Button>{" "}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="14" className="no-data">
                              No data available
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan="14" />
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {this.props.isLoading ? <div className="loader" /> : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    userType: state.auth.userType,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchSku: (reqData) => dispatch(searchSku(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkuList);
