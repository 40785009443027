import { SAVE_BILL_BOOK_LIST, SAVE_BILL_DETAIL, CLEAR_BILL_BOOK, SAVE_MESSAGE_LOG } from "../actions/actionTypes";

const initialState = {
  billBook: null,
  billDetail: null,
  updatedOn: null,
};

const billBookReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BILL_BOOK_LIST:
      return {
        billBook: action.data,
        updatedOn: new Date(),
      };

    case SAVE_BILL_DETAIL:
      return {
        ...state,
        billDetail: { ...action.data, updatedOn: new Date() },
        updatedOn: new Date(),
      };

    case SAVE_MESSAGE_LOG:
      return {
        ...state,
        billDetail: {
          ...state.billDetail,
          billItems: [...action.data],
          updatedOn: new Date(),
        },
        updatedOn: new Date(),
      };

    case CLEAR_BILL_BOOK:
      return {
        billBook: null,
        billDetail: null,
        updatedOn: null,
      };

    default:
      return state;
  }
};

export default billBookReducer;
