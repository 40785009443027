import React from "react";
import {
  Label,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import SkuForm from "./SkuForm";
import { connect } from "react-redux";
import Dropzone from "../../components/Dropzone/Dropzone";
import GoBack from "../../components/GoBack/GoBack";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import { getMediaUrl } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";

import { SUPPLIER_REQUEST_LIST } from "../../utils/constants/clientUrls";

import {
  getCategories,
  getSkuForm,
  getBrandList,
  addUpdateSku,
  uploadImages,
  saveImages,
  checkSkuForm,
} from "../../datautils/actions/skuActions";
import {
  resetResponseValues,
  resetObjectValues,
  removeMediaObject,
  clearMediaObject,
  clearMediaList,
} from "../../datautils/actions/responseHandlerActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import Guidelines from "../../components/Guidelines";

//import Select from "react-select";

class AddSku extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthorized: false,
      level1: "",
      level2: "",
      level3: "",
      level4: "",
      level5: "",
      level6: "",
      selectedCatId: "",
      isBrand: false,
      brandCode: "",
      modal: false,
      activeTab: "1",
      filteredBrands: [],
      formFetched: false,
      skuCreated: false,
      formData: {},
      files: [],
      skuDetailsUploadFinal: false,
      skuMediaUploadFinal: false,
      currentObject: null,
      isUpdated: false,
      masterSku: {},
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  componentDidMount() {
    this.props.resetResponseValues();
    this.props.resetObjectValues("skuForm");
    this.props.getCategories({
      userId: this.props.userId,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { responseVal, categories } = this.props;
    const { brandCode, selectedCatId, formFetched, level1, skuCreated, filteredBrands } = this.state;

    if (categories && categories.categories && !level1) {
      this.setState({ level1: categories.categories });
    }

    if (
      !formFetched &&
      selectedCatId &&
      brandCode &&
      responseVal.dObjectData &&
      responseVal.dObjectData.skuForm &&
      responseVal.dObjectData.skuForm.data
    ) {
      const skuData = responseVal.dObjectData.skuForm.data;
      const pDimensions = skuData.packagingDimensions && skuData.packagingDimensions.toLowerCase().split("x");

      const packagingDimensionsL = pDimensions && pDimensions[0];
      const packagingDimensionsB = pDimensions && pDimensions[1];
      const packagingDimensionsH = pDimensions && pDimensions[2];

      this.setState(
        {
          formFetched: true,
          formData: {
            ...skuData,
            packagingDimensionsL,
            packagingDimensionsB,
            packagingDimensionsH,
          },
        },
        () =>
          window.scroll({
            top: 650,
            left: 650,
            behavior: "smooth",
          })
      );
    }

    if (
      !skuCreated &&
      selectedCatId &&
      brandCode &&
      responseVal.dObjectData.skuForm &&
      responseVal.dObjectData.skuForm.data &&
      responseVal.dObjectData.skuForm.data.sku &&
      responseVal.dObjectData.skuForm.data.sku.skuId
    ) {
      const skuData = responseVal.dObjectData.skuForm.data.sku;
      const pDimensions = skuData.packagingDimensions && skuData.packagingDimensions.toLowerCase().split("x");

      const packagingDimensionsL = pDimensions && pDimensions[0];
      const packagingDimensionsB = pDimensions && pDimensions[1];
      const packagingDimensionsH = pDimensions && pDimensions[2];

      this.setState({
        skuCreated: true,
        formData: {
          ...skuData,
          packagingDimensionsL,
          packagingDimensionsB,
          packagingDimensionsH,
        },
        activeTab: "2",
      });
    }

    if (
      !filteredBrands.length &&
      responseVal.dObjectData.brands &&
      responseVal.dObjectData.brands.data &&
      responseVal.dObjectData.brands.data.brands
    ) {
      const filterData = [];
      const allBrands = responseVal.dObjectData.brands.data.brands;

      allBrands.map((brand) =>
        filterData.push({
          id: brand.id,
          name: brand.name,
          code: brand.code,
        })
      );
      this.setState({
        filteredBrands: filterData,
      });
    }
  }

  handleChangeSelectPrimaryColor = (obj, opt) => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        primaryCatFields: {
          ...prevState.formData.primaryCatFields,
          [obj]: {
            ...prevState.formData.primaryCatFields[obj],
            value: opt.value,
          },
        },
        selectedColor: opt.color,
        colorOption: opt.value,
      },
    }));
  };

  handleChangeSelect = (obj, opt) => {
    let otherObj = {};
    if (obj === "variantType" && (opt.value === "NONE" || opt.value === "NA")) {
      otherObj = { variant: "NA" };
    }

    if (opt.type === "attribute") {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          attributes: {
            ...prevState.formData.attributes,
            [obj]: {
              ...prevState.formData.attributes[obj],
              value: opt.value,
            },
          },
        },
      }));
    } else if (opt.type === "primaryCatFields") {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          primaryCatFields: {
            ...prevState.formData.primaryCatFields,
            [obj]: {
              ...prevState.formData.primaryCatFields[obj],
              value: opt.value,
            },
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        formData: { ...prevState.formData, [obj]: opt.value, ...otherObj },
      }));
    }
  };

  handleChange = (e) => {
    e.persist();

    let targetName = e.target.name;
    let targetValue = e.target.value;

    if (e.target.dataset && e.target.dataset.object && e.target.dataset.object === "attributes") {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          attributes: {
            ...prevState.formData.attributes,
            [e.target.name]: {
              ...prevState.formData.attributes[e.target.name],
              value: e.target.value,
            },
          },
        },
      }));
    } else if (e.target.dataset && e.target.dataset.object && e.target.dataset.object === "primaryCatFields") {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          primaryCatFields: {
            ...prevState.formData.primaryCatFields,
            [e.target.name]: {
              ...prevState.formData.primaryCatFields[e.target.name],
              value: e.target.value,
            },
          },
        },
      }));
    } else {
      this.setState({
        ...this.state.formData,
        formData: {
          ...this.state.formData,
          [targetName]: targetValue,
        },
      });
    }
  };

  removeFromState = (row) => (e) => {
    let filteredArray = this.state.files.filter((element) => element.name !== row.name);
    this.setState({ files: [...filteredArray] });
    this.props.removeMediaObject(row.name);
  };

  filterBrands = (e) => {
    const { responseVal } = this.props;
    if (
      responseVal.dObjectData.brands &&
      responseVal.dObjectData.brands.data &&
      responseVal.dObjectData.brands.data.brands
    ) {
      const allBrands = responseVal.dObjectData.brands.data.brands;
      const term = e.target.value.toUpperCase();
      const filterData = allBrands.filter((player) => player.name.toUpperCase().includes(term));

      this.setState({
        filteredBrands: filterData,
      });
    }
  };

  handleChangeSelectBrand = (obj, opt) => {
    if (opt) {
      this.setState({
        isBrand: opt.value === "NO_BRAND" ? false : true,
        brandCode: opt.value,
        modal: false,
        formFetched: false,
        formData: {},
      });

      this.props.resetResponseValues();
      this.props.resetObjectValues("skuForm");
    }
  };

  handleBrand = (brand) => (e) => {
    this.setState({
      isBrand: brand === "NO_BRAND" ? false : true,
      brandCode: brand,
      formFetched: false,
      modal: false,
      formData: {},
    });

    this.props.resetResponseValues();
    this.props.resetObjectValues("skuForm");
  };

  handleBrandModal = () => {
    this.setState({
      isBrand: true,
      brandCode: "",
      formFetched: false,
      modal: false,
      formData: {},
    });
    this.props.getBrandList({});
    this.toggle();
  };

  fetchForm = (e) => {
    this.setState({ formFetched: false, masterSku: {} });
    const reqData = {
      catRefId: this.state.selectedCatId,
      brandCode: this.state.brandCode,
      iRefId: this.state.currentObject.iRefId,
    };

    this.props.getSkuForm(reqData);
  };

  handleCheckExisting = async () => {
    const reqData = {
      petStyleCode: this.state.formData.petStyleCode,
      eanCode: this.state.formData.eanCode,
      brandCode: this.state.brandCode,
    };
    const masterSku = await this.props.checkSkuForm(reqData);

    if (masterSku) {
      this.setState({ masterSku });
    }
  };

  setDefaultData = (row) => {
    const { formData } = this.state;

    let attributes = { ...formData.attributes };

    const pDimensions = row.packagingDimensions && row.packagingDimensions.toLowerCase().split("x");

    const packagingDimensionsL = pDimensions && pDimensions[0];
    const packagingDimensionsB = pDimensions && pDimensions[1];
    const packagingDimensionsH = pDimensions && pDimensions[2];

    if (row.attributes) {
      Object.keys(attributes).map((key) => {
        let tempAttr = row.attributes[key];
        if (attributes[key] && tempAttr) {
          attributes[key]["value"] = tempAttr.value;
        }
        return attributes;
      });
    }

    this.setState({
      ...this.state,
      formData: {
        ...formData,
        id: row.id,
        title: row.title,
        variant: row.variant,
        description: row.description,
        description1: row.description1,
        description2: row.description2,
        description3: row.description3,
        vendorStyleCode: row.vendorStyleCode,
        petStyleCode: row.petStyleCode,
        packagingWeightKg: row.packagingWeightKg,
        additionalInfo: row.additionalInfo,
        unitOfMeasure: row.unitOfMeasure,
        warranty: row.warranty,
        eanCode: row.eanCode,
        packagingDimensionsL: packagingDimensionsL,
        packagingDimensionsB: packagingDimensionsB,
        packagingDimensionsH: packagingDimensionsH,
        variantType: row.variantType,
        colorOption: row.colorOption,
        selectedColor: row.selectedColor,
        otherColor: row.otherColor,

        primaryCatFields: row.primaryCatFields,
        attributes: attributes,
        medias: row.medias,
      },
    });
  };

  addSku = (e) => {
    e.preventDefault();
    const { formData, selectedCatId, currentObject } = this.state;

    const packagingDimensions =
      formData.packagingDimensionsL + "x" + formData.packagingDimensionsB + "x" + formData.packagingDimensionsH;

    const categoryRefId = selectedCatId.split("_").pop();

    let filteredObj = {};
    if (formData.colorOption === "Others") {
      filteredObj = {
        primaryCatFields: {
          ...formData.primaryCatFields,
          COLOUR: {
            ...formData.primaryCatFields["COLOUR"],
            value: formData.otherColor,
          },
        },
      };
    }

    const sku = {
      ...formData,
      variant: formData.variantType === "NONE" ? "NA" : formData.variant,
      packagingDimensions,
      categoryRefId,
      categoryRefTreeIdentifier: selectedCatId,
      categoryIdTreeIdentifier: currentObject.iRefId,
      ...filteredObj,
    };

    this.props.addUpdateSku({ sku });
    this.setState({ isUpdated: true });
  };

  handleClick = (level, subs) => (e) => {
    const step = "level" + (level + 1);
    const stepId = step + "Id";

    const currentObject = { ...subs };
    if (currentObject.subs) delete currentObject.subs;

    if (level > 0) {
      let obj = { selectedCatId: "" };

      for (let i = level + 1; i <= 6; i++) {
        let innerStep = "level" + i;
        let innerStepId = "level" + i + "Id";
        obj[innerStep] = "";
        obj[innerStepId] = "";
        this.setState(obj);
      }
    }

    if (subs.subs && subs.subs.length > 0) {
      this.setState({
        [step]: subs.subs,
        [stepId]: subs.id,
        brandCode: "",
        isBrand: false,
        currentObject,
      });
    } else {
      this.setState({
        selectedCatId: subs.catRef,
        [stepId]: subs.id,
        brandCode: "",
        isBrand: false,
        currentObject,
      });
    }
  };

  onDrop = (acceptedFiles) => {
    this.setState({ files: [...this.state.files, ...acceptedFiles] });

    acceptedFiles.map((acceptedFile) => {
      return this.props.uploadImages(acceptedFile);
    });
  };

  saveMedia = () => {
    if (this.props.mediaList && this.props.mediaList.length > 0) {
      const skuId = this.state.formData.skuId;
      const mediaIds = [];

      this.props.mediaList.map((obj) => {
        return mediaIds.push({ fileId: obj[Object.keys(obj)] });
      });

      if (mediaIds.length > 0) {
        const sku = {
          skuId: skuId,
          medias: mediaIds,
          skuMediaUploadedFinal: this.state.formData.skuMediaUploadedFinal,
        };
        this.props.saveImages({ sku });
      }
    }
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChecked = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleCheckedFinal = (e) => {
    if (e.target.checked) {
      this.setState({
        formData: { ...this.state.formData, [e.target.name]: true },
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, [e.target.name]: false },
      });
    }
  };

  renderModal = () => {
    return (
      <Modal
        backdrop="static"
        centered
        isOpen={this.state.modal}
        fade={false}
        toggle={this.toggle}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggle}>Search Brand </ModalHeader>
        <ModalBody>
          {/* <Select
            className="basic-single"
            classNamePrefix="select"
            defaultValue="NO_BRAND"
            isClearable="true"
            isSearchable="true"
            name="brand"
            placeholder="Select a Brand"
            options={this.state.filteredBrands ? this.state.filteredBrands : {}}
            onChange={opt => this.handleChangeSelectBrand("brand", opt)}
          /> */}

          <FormGroup>
            <Input type="text" name="brand" placeholder="Type here..." onChange={this.filterBrands} />
          </FormGroup>

          <ul className="brand-suggest">
            {this.state.filteredBrands &&
              this.state.filteredBrands.map((searchedItem, index) => {
                return (
                  <li key={`list-` + index} onClick={this.handleBrand(searchedItem.code)}>
                    {searchedItem.name}
                  </li>
                );
              })}
          </ul>
          <div className="mx-auto mt-2">
            <small className="tex-danger">*If your brand does not exist</small>
            <Link to={SUPPLIER_REQUEST_LIST} className="btn btn-block mt-0 btn-danger">
              Request admin to create Brand
            </Link>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const {
      level1,
      level2,
      level3,
      level4,
      level5,
      level6,
      formData,
      brandCode,
      selectedCatId,
      //isUpdated
      masterSku,
    } = this.state;

    const { responseVal, userType } = this.props;

    return (
      <div>
        <PanelHeader />
        <div className="content mt-3 ">
          {this.renderModal()}

          <Col xs={12}>
            <MessageBlock screenName="sku" />
          </Col>

          <Row className="equal">
            <Col md={2} sm={3} className="d-sm-none d-md-none d-lg-block">
              <ul className="select-vertical">
                <li>
                  <span className="sku-form-step active">
                    <i className="now-ui-icons arrows-1_minimal-right" />
                  </span>
                  1. Select Vertical
                </li>
                <li>
                  <span className={selectedCatId ? "sku-form-step active" : "sku-form-step"}>
                    <i className="now-ui-icons  arrows-1_minimal-right" />
                  </span>
                  2. Select Brand
                </li>
                <li>
                  <span
                    className={
                      selectedCatId &&
                      brandCode &&
                      responseVal.dObjectData.skuForm &&
                      responseVal.dObjectData.skuForm.data
                        ? "sku-form-step active"
                        : "sku-form-step"
                    }
                  >
                    <i className="now-ui-icons arrows-1_minimal-right" />
                  </span>
                  3. Add SKU Info
                </li>
                <li>
                  <span className="sku-form-step">
                    <i className="now-ui-icons arrows-1_minimal-right" />
                  </span>
                  4. Add SKU Images
                </li>
              </ul>
            </Col>
            <Col md={10} sm={9}>
              {formData && formData.skuId ? null : (
                <React.Fragment>
                  <GoBack text="Add SKU" />

                  <Guidelines />

                  <h5>1. Select Vertical</h5>
                  <Row>
                    <Col md={2} sm={3} xs={6}>
                      <ul className="add-cat">
                        {level1
                          ? Object.keys(level1).map((keyName) => {
                              const inner = level1[keyName];
                              const activeCats = inner.subs && inner.subs.filter((o) => o.status !== "INACTIVE");

                              const innerSubs = {
                                ...inner,
                                subs: activeCats && [...activeCats],
                              };

                              return inner.status !== "INACTIVE" ? (
                                <li
                                  key={inner.id}
                                  onClick={this.handleClick(1, innerSubs)}
                                  className={this.state.level2Id === inner.id ? "active" : ""}
                                >
                                  {inner.name}
                                </li>
                              ) : null;
                            }, this)
                          : null}
                      </ul>
                    </Col>
                    {level2 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat left-arrow">
                          {Object.keys(level2).map((keyName) => {
                            const inner = level2[keyName];
                            const activeCats = inner.subs && inner.subs.filter((o) => o.status !== "INACTIVE");

                            const innerSubs = {
                              ...inner,
                              subs: activeCats && [...activeCats],
                            };

                            return inner.status !== "INACTIVE" ? (
                              <li
                                key={inner.id}
                                onClick={this.handleClick(2, innerSubs)}
                                className={this.state.level3Id === inner.id ? "active" : ""}
                              >
                                {inner.name}
                              </li>
                            ) : null;
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level3 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level3).map((keyName) => {
                            const inner = level3[keyName];

                            const activeCats = inner.subs && inner.subs.filter((o) => o.status !== "INACTIVE");

                            const innerSubs = {
                              ...inner,
                              subs: activeCats && [...activeCats],
                            };

                            return inner.status !== "INACTIVE" ? (
                              <li
                                key={inner.id}
                                onClick={this.handleClick(3, innerSubs)}
                                className={this.state.level4Id === inner.id ? "active" : ""}
                              >
                                {inner.name}
                              </li>
                            ) : null;
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}
                    {level4 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level4).map((keyName) => {
                            const inner = level4[keyName];
                            const activeCats = inner.subs && inner.subs.filter((o) => o.status !== "INACTIVE");

                            const innerSubs = {
                              ...inner,
                              subs: activeCats && [...activeCats],
                            };
                            return inner.status !== "INACTIVE" ? (
                              <li
                                key={inner.id}
                                onClick={this.handleClick(4, innerSubs)}
                                className={this.state.level5Id === inner.id ? "active" : ""}
                              >
                                {inner.name}
                              </li>
                            ) : null;
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level5 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level5).map((keyName) => {
                            const inner = level5[keyName];
                            const activeCats = inner.subs && inner.subs.filter((o) => o.status !== "INACTIVE");

                            const innerSubs = {
                              ...inner,
                              subs: activeCats && [...activeCats],
                            };
                            return inner.status !== "INACTIVE" ? (
                              <li
                                className={
                                  selectedCatId === inner.catRef || this.state.level6Id === inner.id ? "active" : ""
                                }
                                key={inner.id}
                                onClick={this.handleClick(5, innerSubs)}
                              >
                                {inner.name}
                              </li>
                            ) : null;
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}{" "}
                    {level6 ? (
                      <Col md={2} sm={3} xs={6}>
                        <ul className="add-cat">
                          {Object.keys(level6).map((keyName) => {
                            const inner = level6[keyName];
                            return inner.status !== "INACTIVE" ? (
                              <li
                                className={selectedCatId === inner.catRef ? "active" : ""}
                                key={inner.id}
                                onClick={this.handleClick(6, inner)}
                              >
                                {inner.name}
                              </li>
                            ) : null;
                          }, this)}
                        </ul>
                      </Col>
                    ) : null}{" "}
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      {selectedCatId ? (
                        <React.Fragment>
                          <h5>2. Select Brand</h5>
                          <div className="brand-box" style={{ padding: 10 }}>
                            <FormGroup check style={{ marginLeft: "-2rem" }}>
                              <Label check>
                                <Input type="radio" name="isBrand" onClick={this.handleBrandModal} /> Brand
                              </Label>
                              <Label check>
                                <Input type="radio" name="isBrand" onClick={this.handleBrand("NO_BRAND")} /> UnBranded
                              </Label>
                            </FormGroup>

                            {/* {this.state.isBrand ? (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={this.handleBrandModal}
                              >
                                Choose Brand
                              </Button>
                            ) : null} */}

                            {brandCode ? <p className="mb-2">Selected Brand : {brandCode} </p> : ""}

                            {selectedCatId && brandCode ? (
                              <Button className="m-0" color="info" size="sm" onClick={this.fetchForm}>
                                Create SKU
                              </Button>
                            ) : null}
                          </div>
                        </React.Fragment>
                      ) : null}
                    </Col>
                  </Row>
                </React.Fragment>
              )}

              <Row className="mt-3">
                <Col>
                  {selectedCatId &&
                  brandCode &&
                  responseVal.dObjectData &&
                  responseVal.dObjectData.skuForm &&
                  responseVal.dObjectData.skuForm.data ? (
                    <React.Fragment>
                      <h5>Add sku info and upload sku images</h5>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            style={{ margin: -1 }}
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                              this.toggleTab("1");
                            }}
                          >
                            3. ADD SKU INFO
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ margin: -1 }}
                            className={classnames({
                              active: this.state.activeTab === "2",
                            })}
                            onClick={() => {
                              this.toggleTab("2");
                            }}
                          >
                            4. ADD SKU IMAGES
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1" className="sku-form-box">
                          <Row>
                            <Col sm="7">
                              <SkuForm
                                form={formData}
                                handleChange={this.handleChange}
                                addSku={this.addSku}
                                handleChangeSelect={this.handleChangeSelect}
                                handleChangeSelectPrimaryColor={this.handleChangeSelectPrimaryColor}
                                handleCheckedFinal={this.handleCheckedFinal}
                                userType={userType}
                                handleCheckExisting={this.handleCheckExisting}
                                masterSku={this.state.masterSku}
                              />
                            </Col>
                            <Col sm="5">
                              {masterSku && masterSku.skus ? (
                                <>
                                  {masterSku.skus?.length > 0 ? (
                                    <>
                                      <div className="card-header p-2">
                                        <h5 className="mb-0">Suggestions</h5>
                                        <small>Click on the item to prefill the form</small>
                                      </div>
                                      {masterSku.skus.map((m) => {
                                        let media = {};
                                        if (m.medias) {
                                          media = m["medias"][0];
                                        }
                                        return (
                                          <div
                                            className={
                                              "d-flex p-2 " + (m.id === formData.id ? "bg-warning" : "bg-white")
                                            }
                                            key={m.id}
                                            style={{
                                              borderBottom: "1px solid #eee",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => this.setDefaultData(m)}
                                            id={"PopoverFocus" + m.id}
                                          >
                                            <UncontrolledPopover
                                              trigger="hover"
                                              placement="left"
                                              target={"PopoverFocus" + m.id}
                                            >
                                              <PopoverHeader>{m.title}</PopoverHeader>
                                              <PopoverBody>
                                                <p>MRP: &#x20b9;{m.mrp}</p>
                                                <p>
                                                  {m.variantType}:{m.variant}
                                                </p>
                                                <ul className="m-0 p-0">
                                                  {m.attributes &&
                                                    Object.keys(m.attributes).map((key) => {
                                                      const a = m.attributes[key];
                                                      return (
                                                        <li key={`suggestedattr-${key}`}>
                                                          <span
                                                            style={{
                                                              textTransform: "capitalize",
                                                            }}
                                                          >
                                                            {a.key && a.key.replace(/_/g, " ").toLowerCase()}
                                                          </span>
                                                          : {a.value}
                                                        </li>
                                                      );
                                                    })}
                                                </ul>
                                              </PopoverBody>
                                            </UncontrolledPopover>
                                            <div
                                              style={{
                                                width: 50,
                                                height: 50,
                                                marginRight: 5,
                                              }}
                                            >
                                              <ImageZoom
                                                src={getMediaUrl({
                                                  fileId: media.fileId,
                                                  fileExt: media.fileExt,
                                                })}
                                                alt=""
                                                className="img-fluid img-thumbnail"
                                                style={{
                                                  maxHeight: 50,
                                                  maxWidth: 50,
                                                }}
                                              />
                                            </div>
                                            <div>
                                              <p className="m-0" style={{ fontSize: 12 }}>
                                                {m.title}
                                              </p>
                                              <small>
                                                {m.variantType}:{m.variant}
                                              </small>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <p>No suggestions available</p>
                                  )}
                                </>
                              ) : null}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2" className="sku-form-box">
                          <Row>
                            <Col sm="12">
                              <div className="product-images">
                                {formData && formData.skuId ? (
                                  <Row className="equal">
                                    <Col sm={6}>
                                      <Dropzone onFilesAdded={(opt) => this.onDrop(opt)} />
                                    </Col>
                                    <Col sm={6}>
                                      {this.props.mediaList && this.props.mediaList.length > 0 ? (
                                        <div className="done-uploading">
                                          <FormGroup check>
                                            <Label check>
                                              <Input
                                                value={formData.skuMediaUploadedFinal}
                                                type="checkbox"
                                                name="skuMediaUploadedFinal"
                                                onChange={this.handleCheckedFinal}
                                              />
                                              <span className="form-check-sign" />I have completed uploading
                                            </Label>
                                          </FormGroup>
                                          <Button size="md" color="primary" onClick={this.saveMedia}>
                                            Save All Images
                                          </Button>
                                        </div>
                                      ) : null}
                                    </Col>
                                  </Row>
                                ) : (
                                  <p className="pt-5 text-center">Please add sku to upload images </p>
                                )}

                                {formData && formData.skuId && this.state.files ? (
                                  <Row className="mt-2 equal">
                                    {this.state.files.length > 0 &&
                                      this.state.files.map((acceptedFile) => (
                                        <Col sm="3" className="" key={acceptedFile.name}>
                                          <img
                                            alt={acceptedFile.name}
                                            src={URL.createObjectURL(acceptedFile)}
                                            className="img-thumbnail rounded"
                                          />
                                          <p className="text-center">{acceptedFile.name}</p>
                                          <button
                                            onClick={this.removeFromState(acceptedFile)}
                                            type="button"
                                            className="close img-remove"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </Col>
                                      ))}
                                  </Row>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </React.Fragment>
                  ) : null}
                  {this.props.isLoading ? <div className="loader" /> : ""}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    userId: state.auth.userId,
    userType: state.auth.userType,
    responseVal: state.resHandlerVal,
    categories: state.categories,
    mediaList: state.resHandlerVal.mediaObject,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBrandList: (reqParam) => dispatch(getBrandList(reqParam)),
    checkSkuForm: (reqParam) => dispatch(checkSkuForm(reqParam)),
    getSkuForm: (reqParam) => dispatch(getSkuForm(reqParam)),
    addUpdateSku: (reqParam) => dispatch(addUpdateSku(reqParam)),
    uploadImages: (acceptedFile) => dispatch(uploadImages(acceptedFile)),
    saveImages: (reqParam) => dispatch(saveImages(reqParam)),
    getCategories: (reqParam) => dispatch(getCategories(reqParam)),
    resetObjectValues: (reqParam) => dispatch(resetObjectValues(reqParam)),
    removeMediaObject: (reqParam) => dispatch(removeMediaObject(reqParam)),
    resetResponseValues: () => dispatch(resetResponseValues()),
    clearMediaList: () => dispatch(clearMediaList()),
    clearMediaObject: () => dispatch(clearMediaObject()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSku);
