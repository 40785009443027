import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";

import Select from "react-select";

import { connect } from "react-redux";

import { getProfile, updateProfile } from "../../datautils/actions/userActions";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import { getFilteredAuthData } from "../../utils/helpers/HelperFunctions";
import TooltipItem from "../../components/Tooltip/Tooltip";
import { businessTypes } from "../../utils/constants";

import { SUPPLIER_KYC, SUPPLIER_ADDRESS, SUPPLIER_CONTACT } from "../../utils/constants/clientUrls";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profileFetched: false,
      profileData: null,
      businessName: "",
      businessDescription: "",
      businessType: "",
      businessNameState: "",
      businessDescriptionState: "",
      businessTypeState: "",
      modal: false,
      stateMsg: "",
    };
  }

  toggle = () => {
    const { profile } = this.props;
    this.setState((prevState) => ({
      modal: !prevState.modal,
      businessName: profile.profileInfo.businessName,
      businessDescription: profile.profileInfo.businessDescription,
      businessType: profile.profileInfo.businessType,

      businessNameState: "",
      businessDescriptionState: "",
      businessTypeState: "",
    }));
    if (!this.state.modal) {
      this.props.resetResponseValues();
    }
  };

  componentDidMount() {
    const authData = getFilteredAuthData(this.props.authData);
    this.props.getProfile(authData);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { profileFetched, modal } = this.state;
    const { responseVal, profile } = this.props;

    if (!profileFetched) {
      this.setState({
        profileData: profile.profileInfo,
        profileFetched: true,
      });
    }

    if (profileFetched && profile.newSupplier) {
    }

    if (responseVal.status === "success" && modal) {
      this.toggle();
    }
  }

  handleChange = (input) => (event) => {
    if (input === "businessName") {
      let businessName = event.target.value;
      let businessNameState = this.state.businessNameState;
      let stateMsg = this.state.stateMsg;

      if (businessName.length > 0) {
        if (isNaN(businessName)) {
          stateMsg = "";
          businessNameState = "is-valid";
        } else {
          stateMsg = "Business name can't be number only";
          businessNameState = "is-invalid";
        }
      } else {
        businessNameState = "is-invalid";
        stateMsg = "";
      }
      this.setState({ businessName, businessNameState, stateMsg });
    }
    if (input === "businessDescription") {
      let businessDescription = event.target.value;
      let businessDescriptionState = this.state.businessDescriptionState;

      if (businessDescription.length > 5) {
        businessDescriptionState = "is-valid";
      } else {
        businessDescriptionState = "is-invalid";
      }
      this.setState({ businessDescription, businessDescriptionState });
    }

    if (input === "businessType") {
      let businessType = event.target.value;
      let businessTypeState = this.state.businessTypeState;

      if (businessType.length > 5) {
        businessTypeState = "is-valid";
      } else {
        businessTypeState = "is-invalid";
      }
      this.setState({ businessType, businessTypeState });
    }
  };

  handleChangeSelect = (obj, opt) => {
    this.setState({ [obj]: opt.value });
    console.log(`Option selected:`, opt.value);
  };

  routeMap = (key) => {
    let map = "/";
    if (key === "KYC_INFO") {
      map = SUPPLIER_KYC;
    } else if (key === "ADDRESS") {
      map = SUPPLIER_ADDRESS;
    } else if (key === "CONTACT") {
      map = SUPPLIER_CONTACT;
    }

    this.props.history.push(map);
  };

  updateProfile = (e) => {
    e.preventDefault();

    const {
      authData: { userType, userId, deviceId, deviceType },
    } = this.props;
    const { businessName, businessDescription, businessType } = this.state;

    const reqData = {
      userType,
      userId,
      deviceId,
      deviceType,
      businessName,
      businessDescription,
      businessType,
    };
    this.props.updateProfile(reqData);
    this.setState({ isUpdated: true });
  };

  renderModal = () => {
    const {
      businessName,
      businessDescription,
      businessType,
      businessNameState,
      businessDescriptionState,
      stateMsg,
      modal,
    } = this.state;

    let isEnabled = !this.props.isLoading && businessType && businessName && businessDescription;

    return (
      <Modal backdrop="static" centered isOpen={modal} toggle={this.toggle} className={this.props.className}>
        <ModalHeader toggle={this.toggle}>Update Basic Profile</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col">
              <Card className="card-default">
                <CardBody>
                  <Form autoComplete="off">
                    <FormGroup row>
                      <Label for="businessType" sm={3}>
                        Business Type<span className="star">*</span>
                      </Label>
                      <Col sm={9}>
                        <Select
                          placeholder="Business Type"
                          name="businessType"
                          options={businessTypes}
                          value={businessTypes.filter((option) => option.value === businessType)}
                          onChange={(opt) => this.handleChangeSelect("businessType", opt)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="businessName" sm={3}>
                        Business Name<span className="star">*</span>
                      </Label>
                      <Col sm={9}>
                        <Input
                          className={businessNameState}
                          type="text"
                          name="businessName"
                          placeholder="Business Name"
                          onChange={this.handleChange("businessName")}
                          value={businessName}
                        />
                        {stateMsg && stateMsg.length > 0 ? <p className="text-danger">{stateMsg}</p> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="businessDescription" sm={3}>
                        Business Description<span className="star">*</span>
                      </Label>
                      <Col sm={9}>
                        <Input
                          className={businessDescriptionState}
                          type="textarea"
                          name="businessDescription"
                          placeholder="Business Description"
                          onChange={this.handleChange("businessDescription")}
                          value={businessDescription}
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter className="text-center">
                  <Row>
                    <Col sm={3}></Col>
                    <Col sm={9}>
                      <Button block color="primary" size="md" onClick={this.updateProfile} disabled={!isEnabled}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const {
      businessType,
      businessName,
      businessDescription,
      businessNameState,
      businessDescriptionState,
      profileFetched,
      isUpdated,
      modal,
    } = this.state;

    const { responseVal, profile, workflow, authData } = this.props;
    const isEnabled = businessNameState === "is-valid" && businessDescriptionState === "is-valid";
    let profileWorkflow = {};
    let notCompletedSections = [];
    workflow &&
      Object.keys(workflow.workflowStatusMap).forEach((index) => {
        const object = workflow.workflowStatusMap[index];
        return Object.keys(object).forEach((index2) => {
          const object2 = object[index2];

          return (
            object2.subSections &&
            Object.keys(object2.subSections).forEach((index3) => {
              const object3 = object2.subSections[index3];
              if (index3 === "ACCOUNT_INFO") {
                profileWorkflow = object3;
              }
              if (object3.currentState === "INCOMPLETE") notCompletedSections.push(index3);
            })
          );
        });
      });

    let alertMsg = "";
    let color = "danger";

    if (workflow && workflow.overAllState === "COMPLETE" && profile.profileInfo && !profile.profileInfo.verified) {
      alertMsg = "Your Account is complete and is waiting for verification approval";
      color = "warning";
    } else if (workflow && workflow.overAllState !== "COMPLETE") {
      alertMsg = "Your Account is incomplete";
    } else if (
      workflow &&
      workflow.overAllState === "COMPLETE" &&
      profile.profileInfo &&
      profile.profileInfo.verified
    ) {
      alertMsg = "Your Account is Active";
      color = "success";
    }

    let profileStatus = profileWorkflow?.currentState;
    let profileStatusMsg = profileWorkflow.currentState?.replace(/_/g, "");
    let profileStatusColor = null;
    if (profileStatus === "COMPLETE" && profile.profileInfo && !profile.profileInfo.verified) {
      profileStatusMsg = "NOT VERIFIED";
      profileStatusColor = "SUBMITTED";
    }

    return (
      <div>
        <PanelHeader />
        <div className="content mt-3">
          {modal && this.renderModal()}
          <Row>
            {profileFetched && !profile.newSupplier ? (
              <Col xs={12}>
                {responseVal.status === "failure" ? <ErrorBlock errors={responseVal.errors} /> : null}
                {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}

                {alertMsg?.length > 1 ? (
                  <SuccessBlock
                    msg={{
                      userDisplayMsg: alertMsg,
                    }}
                    color={color}
                    position="alert-relative"
                  />
                ) : null}

                {/* <div className="d-none">
                  {workflow &&
                    workflow.workflowStatusMap &&
                    Object.keys(workflow.workflowStatusMap).map((index) => {
                      const object = workflow.workflowStatusMap[index];

                      return (
                        <div key={"w-1" + index} className="aa">
                          {Object.keys(object).map((index2) => {
                            console.log(index2);
                            const object2 = object[index2];
                         
                            return (
                              <div key={"w-2-" + index2}>
                                {object2.overAllState === "SUBMITTED" ? (
                                  <SuccessBlock
                                    msg={{
                                      userDisplayMsg:
                                        (index2 === "MY_ACCOUNT" ? "BASIC PROFILE" : index2?.replace(/_/g, " ")) +
                                        " is submitted for verification",
                                    }}
                                    color="warning"
                                    position="alert-relative"
                                  />
                                ) : object2.overAllState === "INCOMPLETE" ? (
                                  <SuccessBlock
                                    msg={{
                                      userDisplayMsg:
                                        (index2 === "MY_ACCOUNT" ? "BASIC PROFILE" : index2?.replace(/_/g, " ")) +
                                        " is incomplete, Please complete it for your account approval",
                                    }}
                                    color="danger"
                                    position="alert-relative"
                                  />
                                ) : object2.overAllState === "REJECTED" ? (
                                  <SuccessBlock
                                    msg={{
                                      userDisplayMsg:
                                        (index2 === "MY_ACCOUNT" ? "BASIC PROFILE" : index2?.replace(/_/g, " ")) +
                                        " is rejected. Please update and resubmit",
                                    }}
                                    color="danger"
                                    position="alert-relative"
                                  />
                                ) : object2.overAllState === "ON_HOLD" ? (
                                  <SuccessBlock
                                    msg={{
                                      userDisplayMsg:
                                        (index2 === "MY_ACCOUNT" ? "BASIC PROFILE" : index2?.replace(/_/g, " ")) +
                                        " is on hold",
                                    }}
                                    color="danger"
                                    position="alert-relative"
                                  />
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div> */}
              </Col>
            ) : null}

            {profileFetched ? (
              profile.newSupplier ? (
                <Col md={5} sm={6} xs={12} className="mx-auto">
                  <Card className="card-default">
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Update Profile</CardTitle>
                      <p className="text-right pt-2 d-none">
                        <span className="star">*</span> Mandatory fields
                      </p>
                    </CardHeader>
                    <CardBody>
                      <Form autoComplete="off">
                        <FormGroup row>
                          <Label for="title" sm={12}>
                            Business Type<span className="star">*</span>
                          </Label>
                          <Col sm={12}>
                            <Select
                              placeholder="Business Type"
                              name="businessType"
                              options={businessTypes}
                              value={businessTypes.filter((option) => option.value === businessType)}
                              onChange={(opt) => this.handleChangeSelect("businessType", opt)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="title" sm={12}>
                            Business Name<span className="star">*</span>
                          </Label>
                          <Col sm={12}>
                            <Input
                              className={businessNameState}
                              type="text"
                              name="businessName"
                              placeholder="Enter business name"
                              onChange={this.handleChange("businessName")}
                              value={businessName}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="title" sm={12}>
                            Business Description<span className="star">*</span>
                          </Label>
                          <Col sm={12}>
                            <Input
                              className={businessDescriptionState}
                              type="textarea"
                              name="businessDescription"
                              placeholder="Enter business description"
                              onChange={this.handleChange("businessDescription")}
                              value={businessDescription}
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        block
                        color="primary"
                        size="md"
                        onClick={this.updateProfile}
                        disabled={this.props.isLoading || !isEnabled}
                      >
                        Update
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              ) : profile.profileInfo ? (
                <React.Fragment>
                  <Col lg={3} sm={6} xs={12} className={"psmall mb-3"}>
                    <Card className="card-stats d-flex h-100">
                      <CardBody>
                        <div className="row">
                          <div className="col">
                            <h5 className="card-title mb-0 text-camelcase">
                              <span className={`status ${profileStatusColor || profileStatus}`} id="Tooltip-x">
                                <i className="now-ui-icons ui-1_check" />
                              </span>{" "}
                              BASIC PROFILE{" "}
                              {authData && (authData.userType === "ADMIN" || authData.userRole === "SUPPLIER_ADMIN") && (
                                <Button
                                  color="secondary"
                                  size="xs"
                                  className="mt-0 mb-o float-right btn-simple btn-primary"
                                  onClick={this.toggle}
                                >
                                  Update
                                </Button>
                              )}
                            </h5>
                            <TooltipItem key="x" placement="right" text={profileStatusMsg} id="x" />

                            {profileWorkflow.currentState === "REJECTED" ||
                            profileWorkflow.currentState === "ON_HOLD" ? (
                              <div className="admin-action p-2 mt-2" style={{ background: "#f5f5f5" }}>
                                <div className="mt-0 mb-0 d-flex justify-content-between">
                                  <p className="mb-0 text-muted">Current Status</p>
                                  <p className="mb-0 mt-0">
                                    <span className={`text-${profileWorkflow.currentState}`}>
                                      {profileWorkflow.currentState?.replace(/_/g, "")}
                                    </span>
                                  </p>
                                </div>

                                <div className="mt-1 mb-0 d-flex justify-content-between">
                                  <p className="mb-0 text-muted">Status Notes</p>
                                  <p className="mb-0 mt-0">
                                    <span>{profileWorkflow.statusMessage}</span>
                                  </p>
                                </div>

                                {profile.profileInfo?.adminWorkflowMessage?.length > 0 ? (
                                  <div className="mt-1 mb-0">
                                    <p className="mb-0 text-muted">Admin Notes</p>
                                    <p className="mt-0 mb-1">{profile.profileInfo.adminWorkflowMessage}</p>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}

                            <div className="mt-3 mb-0 d-flex justify-content-between">
                              <p className="mb-0 text-muted">Profile Status</p>
                              <p className="mb-0 mt-0">
                                <span className={`text-${profile.profileInfo.status}`}>
                                  {profile.profileInfo.status}
                                </span>
                              </p>
                            </div>
                            <div className="mb-0 d-flex justify-content-between border-btm">
                              <p className="mb-0 text-muted">Profile Verified</p>
                              <p className="mb-0 mt-0">
                                {profile.profileInfo.verified ? (
                                  <span className="text-success">YES</span>
                                ) : (
                                  <span className="text-danger">No</span>
                                )}
                              </p>
                            </div>

                            <div className="mt-2 mb-0 d-flex justify-content-between">
                              <p className="mb-0 text-muted">Business Type </p>
                              <p className="mb-0 mt-0 text-right">
                                <small>{profile.profileInfo.businessType.replace(/_/g, " ")}</small>
                              </p>
                            </div>
                            <div className="mb-3 d-flex justify-content-between border-btm">
                              <p className="mb-0 text-muted">Mobile No.</p>
                              <p className="mb-0 mt-0 text-right">
                                {profile.profileInfo.mobileCountryCode}-{profile.profileInfo.mobileNumber}
                              </p>
                            </div>

                            <div className="mb-0 ">
                              <p className="mb-0 text-muted">Business Name </p>
                              <p className="mt-0">{profile.profileInfo.businessName}</p>
                            </div>

                            <div className="mb-0 border-btm">
                              <p className="mb-0 text-muted">Business Description</p>
                              <p className="mb-0 mt-0">{profile.profileInfo.businessDescription}</p>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  {workflow?.workflowStatusMap &&
                    Object.keys(workflow.workflowStatusMap).map((index) => {
                      const topLevel = workflow.workflowStatusMap[index];

                      return index > 1 ? (
                        <Col
                          lg={3}
                          sm={6}
                          xs={12}
                          key={`top-${index}`}
                          className={"psmall mb-3 " + (authData && authData.userType)}
                        >
                          <Card className="card-stats d-flex h-100 mb-0">
                            <CardBody>
                              <div className="row">
                                <div className="col">
                                  {Object.keys(topLevel).map((index) => {
                                    const midLevel = topLevel[index];

                                    return (
                                      <React.Fragment key={index}>
                                        <h5 className="card-title text-camelcase mb-3">
                                          <span className={`status ${midLevel.overAllState}`} id={"Tooltip-" + index}>
                                            <i className="now-ui-icons ui-1_check" />
                                          </span>
                                          {index.replace(/_/g, " ")}

                                          {authData &&
                                            (authData.userType === "ADMIN" ||
                                              authData.userRole === "SUPPLIER_ADMIN") && (
                                              <Button
                                                color="secondary"
                                                size="xs"
                                                className="mt-0 mb-o float-right btn-simple btn-primary"
                                                onClick={() => this.routeMap(index)}
                                              >
                                                View
                                              </Button>
                                            )}
                                        </h5>
                                        <TooltipItem placement="right" text={midLevel.overAllState || ""} id={index} />
                                        {midLevel.subSections &&
                                          Object.keys(midLevel.subSections).map((index) => {
                                            const lowLevel = midLevel.subSections[index];
                                            return (
                                              <h6 key={index} className="d-flex justify-content-between border-btm">
                                                <p
                                                  className="text-camelcase text-muted mb-0 pb-1"
                                                  title={lowLevel.statusMessage}
                                                >
                                                  {index.replace(/_/g, " ").toLowerCase()}
                                                </p>
                                                <span className="pl-1 text-right">
                                                  <small className={`text-${lowLevel.currentState}`}>
                                                    {lowLevel.currentState}
                                                  </small>
                                                </span>
                                              </h6>
                                            );
                                          })}
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ) : null;
                    })}
                </React.Fragment>
              ) : null
            ) : null}

            {this.props.isLoading && <div className="loader" />}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    profile: state.profile,
    workflow: state.profile.workflow,
    responseVal: state.resHandlerVal,
    authData: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (reqParam) => dispatch(updateProfile(reqParam)),
    getProfile: (reqParam) => dispatch(getProfile(reqParam)),
    resetResponseValues: () => dispatch(resetResponseValues()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
