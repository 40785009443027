import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";

import supplierRoutes from "../../config/routes/supplier";
import adminRoutes from "../../config/routes/admin";
import { userLogout } from "../../datautils/actions/authActions";
import { connect } from "react-redux";
import {
  SUPPLIER_BASE,
  SUPPLIER_PROFILE,
  SUPPLIER_PASSWORD,
  ADMIN_BASE,
  ADMIN_PASSWORD,
} from "../../utils/constants/clientUrls";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
  }

  handleLogout = () => {
    this.props.userLogout();
  };

  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "white",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  getBrand() {
    const { pathname } = this.props.location;
    let name;

    const allRoutes = [...supplierRoutes, ...adminRoutes];

    allRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    /** Dynamic urls check */
    if (!name) {
      if (pathname.match(/\/product\//)) {
        name = "Product Details";
      } else if (pathname.match(/\/sku\//)) {
        name = "SKU Details";
      } else if (pathname.match(/\/suppliers\//)) {
        name = "Supplier Details";
      } else if (pathname.match(/\/campaign\//)) {
        name = "Campaign Details";
      } else if (pathname.match(/\/coupon\//)) {
        name = "Coupon Details";
      } else if (pathname.match(/\/request\//)) {
        name = "Ticket Details";
      } else if (pathname.match(/\/order\//)) {
        name = "Order Details";
      } else if (pathname.match(/\/customer\//)) {
        name = "Customer Details";
      } else if (pathname.match(/\/trend\//)) {
        name = "Trend Details";
      } else if (pathname.match(/\/billbook\//)) {
        name = "Bill Details";
      } else if (pathname.match(/\/sample\//)) {
        name = "Sample Request Details";
      } else if (pathname.match(/\/customoffer\//)) {
        name = "Bargain Offer Details";
      }
    }
    return name;
  }
  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.refs.sidebarToggle.classList.toggle("toggled");
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "white",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
  }

  componentDidUpdate(e) {
    const { pathname } = this.props.location;
    const { userType, profile } = this.props;
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.refs.sidebarToggle.classList.toggle("toggled");
    }

    if (
      userType === "SUPPLIER" &&
      profile &&
      profile.userId &&
      profile.status !== "ACTIVE" &&
      !profile.verified &&
      !pathname.match(/\/profile/) &&
      !pathname.match(/\/password/)
    ) {
      this.props.history.push(SUPPLIER_PROFILE);
    }
  }
  render() {
    const { userType, userRole, history } = this.props;

    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={this.props.location.pathname.indexOf("full-screen-maps") !== -1 ? "white" : this.state.color}
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute "
            : "navbar-absolute " + (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid className="px-0">
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button type="button" ref="sidebarToggle" className="navbar-toggler" onClick={() => this.openSidebar()}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand>
              {/* {this.props.history ? (
                <span
                  className="go-back-top"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <i className="now-ui-icons arrows-1_minimal-left" />
                  Back
                </span>
              ) : (
                ""
              )} */}

              <span className="brand-name">{this.getBrand()}</span>
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar className="justify-content-end">
            <Nav navbar>
              <Dropdown nav isOpen={this.state.dropdownOpen} toggle={(e) => this.dropdownToggle(e)}>
                <DropdownToggle caret nav>
                  <i className="now-ui-icons users_single-02" />
                  <p>
                    {userRole?.replace("_", " ").toLowerCase() ?? "Account"} &nbsp;
                    <span className="d-lg-none d-md-block">Actions</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  {userType === "SUPPLIER" ? (
                    <React.Fragment>
                      <DropdownItem onClick={() => history.push(SUPPLIER_BASE)}>Dashboard</DropdownItem>
                      <DropdownItem onClick={() => history.push(SUPPLIER_PROFILE)}>Profile</DropdownItem>
                      <DropdownItem onClick={() => history.push(SUPPLIER_PASSWORD)}>Change Password</DropdownItem>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <DropdownItem onClick={() => history.push(ADMIN_BASE)}>Dashboard</DropdownItem>
                      <DropdownItem onClick={() => history.push(ADMIN_PASSWORD)}>Change Password</DropdownItem>
                    </React.Fragment>
                  )}
                  <DropdownItem divider />
                  <DropdownItem className="logout" onClick={this.handleLogout}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
    profile: state.profile.profileInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => dispatch(userLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
