import {
  CLEAR_CUSTOM_OFFER_LIST,
  SAVE_CUSTOM_OFFER_LIST,
  SAVE_CUSTOM_OFFER_DETAIL,
  CLEAR_CUSTOM_OFFER_DETAIL,
} from "../actions/actionTypes";

const initialState = {
  sampleList: null,
  sampleDetail: null,
};

const sampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CUSTOM_OFFER_LIST:
      return {
        ...state,
        sampleList: action.data,
      };
    case SAVE_CUSTOM_OFFER_DETAIL:
      return {
        ...state,
        sampleDetail: action.data,
      };

    case CLEAR_CUSTOM_OFFER_DETAIL:
      return {
        ...state,
        sampleDetail: null,
      };
    case CLEAR_CUSTOM_OFFER_LIST:
      return {
        ...state,
        sampleList: null,
      };

    default:
      return state;
  }
};

export default sampleReducer;
