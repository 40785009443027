import React from "react";
import { Link } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, Container } from "reactstrap";
import { connect } from "react-redux";
import { userLogout } from "../../datautils/actions/authActions";
import { isUserLoggedIn } from "../../utils/helpers/HelperFunctions";

import { LOGIN, REGISTER, SUPPLIER_BASE, ADMIN_BASE } from "../../utils/constants/clientUrls";
import logo from "../../assets/img/slickpals-white.png";

class HomeHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isUserLoggedIn: false,
    };
    this.toggle = this.toggle.bind(this);
    this.activeRoute.bind(this);
  }

  componentDidMount() {
    if (isUserLoggedIn()) {
      this.setState({ isUserLoggedIn: true });
    }
  }

  handleLogout = (event) => {
    event.preventDefault();
    this.props.userLogout();
    this.intervalId = setInterval(() => {
      if (!isUserLoggedIn() && this.state.isUserLoggedIn) {
        this.setState({ isUserLoggedIn: false });
        this.props.history.push(LOGIN);
      }
    }, 100);
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  closeMenu = () => {
    if (this.state.isOpen) {
      this.setState({
        isOpen: false,
      });
    }
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  render() {
    const { isOpen, isUserLoggedIn } = this.state;
    const { userType, page } = this.props;

    const dashboardPage = userType === "SUPPLIER" ? SUPPLIER_BASE : ADMIN_BASE;
    return (
      <Navbar expand="lg" className={isOpen ? "home-page navbar-absolute" : "home-page navbar-absolute"}>
        <Container>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <NavbarToggler onClick={this.toggle}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <Link to="/" className="navbar-brand">
              <img className="main-logo" src={logo} alt="" />
            </Link>
          </div>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {isUserLoggedIn ? (
                <React.Fragment>
                  <NavItem key="dashboard" className="">
                    <Link to={dashboardPage} className="nav-link" onClick={this.closeMenu}>
                      Dashboard
                    </Link>
                  </NavItem>

                  <NavItem key="logout" className="">
                    <Link to="#" className="nav-link" onClick={this.handleLogout}>
                      Logout
                    </Link>
                  </NavItem>
                </React.Fragment>
              ) : page !== "admin" ? (
                <React.Fragment>
                  <NavItem key="registerPage" className="">
                    <Link to={REGISTER} className="nav-link" onClick={this.closeMenu}>
                      Register
                    </Link>
                  </NavItem>

                  <NavItem key="loginPage" className="">
                    <Link to={LOGIN} className="nav-link" onClick={this.closeMenu}>
                      Login
                    </Link>
                  </NavItem>
                </React.Fragment>
              ) : null}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => dispatch(userLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);
