import {
  SAVE_ORDER_REQUEST_LIST,
  SAVE_ORDER_REQUEST,
  CLEAR_ALL_ORDER_REQUESTS,
  CLEAR_ORDER_REQUEST,
  SAVE_ORDER_REQUEST_REPLY,
  CLEAR_ORDER_REQUEST_REPLY,
} from "../actions/actionTypes";

const initialState = {
  requestsList: null,
  orderRequest: null,
  orderRequestReply: null,
  updatedOn: null,
};

const orderRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ORDER_REQUEST_LIST:
      return {
        ...state,
        requestsList: action.data,
        updatedOn: new Date(),
      };

    case SAVE_ORDER_REQUEST:
      return {
        ...state,
        orderRequest: action.data,
        updatedOn: new Date(),
      };

    case SAVE_ORDER_REQUEST_REPLY:
      return {
        ...state,
        orderRequestReply: action.data,
        updatedOn: new Date(),
      };

    case CLEAR_ALL_ORDER_REQUESTS:
      return {
        orderRequestReply: null,
        requestsList: null,
        orderRequest: null,
        updatedOn: null,
      };

    case CLEAR_ORDER_REQUEST:
      return {
        ...state,
        orderRequest: null,
      };
    case CLEAR_ORDER_REQUEST_REPLY:
      return {
        ...state,
        orderRequestReply: null,
      };
    default:
      return state;
  }
};

export default orderRequestReducer;
