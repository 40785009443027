import React, { useState } from "react";
import axios from "axios";
import { SEND_CONTACT_US } from "../../utils/constants/apiUrls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    fromMobile: "",
    fromEmail: "",
    subject: "",
    message: "",
  });

  const setData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = async () => {
    let errors = {};
    let isValid = true;

    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(formData.fromEmail)) {
      isValid = false;
      errors["fromEmail"] = "Please enter valid email address.";
    }

    if (isValid) {
      setErrors({});
      const postData = { ...formData };
      try {
        setSending(true);
        const response = await axios.post(SEND_CONTACT_US, postData);
        console.log(response);
        setFormData({
          name: "",
          fromMobile: "",
          fromEmail: "",
          subject: "",
          message: "",
        });
        toast.success("Thanks, We will get back to you !");
      } catch (error) {
        toast.error("Something went wrong !");
        console.log(error);
      } finally {
        setSending(false);
      }
    } else {
      setErrors(errors);
    }
  };

  return (
    <div className="col-md-6">
      <div className="static-page">
        <h1 className="static-h1">Contact Us</h1>
        <p className="top-message">Please send your message below. We will get back to you at the earliest!</p>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <form
          method="post"
          id="contact-form"
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="row">
            <div className="col-sm-12 form-group">
              <label htmlFor="name">Your Name</label>
              <input
                placeholder="Enter your name"
                type="text"
                className="form-control"
                id="name"
                name="name"
                autoComplete="off"
                value={formData.name}
                onChange={(e) => setData(e)}
              />
              {errors["fromName"] ? <div className="invalid-feedback d-block">{errors["fromName"]}</div> : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <label htmlFor="email">Your mobile</label>
              <input
                placeholder="Enter your mobile"
                type="text"
                className="form-control"
                id="mobile"
                name="fromMobile"
                autoComplete="off"
                value={formData.fromMobile}
                onChange={(e) => setData(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <label htmlFor="name">
                Your Email <span className="star">*</span>
              </label>
              <input
                placeholder="Enter your email"
                type="text"
                className="form-control"
                id="email"
                name="fromEmail"
                autoComplete="off"
                value={formData.fromEmail}
                onChange={(e) => setData(e)}
              />
              {errors["fromEmail"] ? <div className="invalid-feedback d-block">{errors["fromEmail"]}</div> : null}
            </div>
          </div>

          {/* <div className="row d-none">
            <div className="col-sm-12 form-group">
              <label htmlFor="subjecct">
                Subject<span className="star">*</span>
              </label>
              <input
                placeholder="Enter subject"
                type="text"
                className="form-control"
                id="subjecct"
                name="subjecct"
              />
            </div>
          </div> */}

          <div className="row">
            <div className="col-sm-12 form-group">
              <label htmlFor="subject">
                Subject <span className="star">*</span>
              </label>
              <input
                placeholder="Enter subject"
                type="text"
                className="form-control"
                id="subject"
                name="subject"
                autoComplete="off"
                value={formData.subject}
                onChange={(e) => setData(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <label htmlFor="message">
                Message <span className="star">*</span>
              </label>
              <textarea
                placeholder="Enter message"
                style={{ border: "1px solid #e3e3e3", borderRadius: 5 }}
                className="form-control"
                type="textarea"
                id="message"
                name="message"
                maxLength="6000"
                rows="5"
                autoComplete="off"
                value={formData.message}
                onChange={(e) => setData(e)}
              ></textarea>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <button
                disabled={sending}
                type="submit"
                className="btn btn-lg btn-block btn-primary"
                onClick={() => submitForm()}
              >
                Send →
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Contact;
