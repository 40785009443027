import React from "react";
import {
  Card,
  CardBody,
  Label,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { ADMIN_PRODUCT_VIEW, ADMIN_SKU_VIEW } from "../../utils/constants/clientUrls";
import {
  addUpdateSku,
  uploadImages,
  saveImages,
  markAdminSku,
  markAdminSkuMedia,
  uploadAllImages,
} from "../../datautils/actions/skuActions";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";

import { fetchProduct, updateProduct, markDefaultSKU, fetchSimilarProduct } from "../../datautils/actions/adminActions";
import { resetResponseValues, removeMediaObject } from "../../datautils/actions/responseHandlerActions";
import uniqid from "uniqid";
import ProductForm from "./ProductForm";
import Dropzone from "../../components/Dropzone/Dropzone";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import CropperModal from "../../components/ImageCropper/CropperModal";

import ImageZoom from "../../components/ImageZoom";
import GoBack from "../../components/GoBack/GoBack";
import MessageBlock from "../../components/MessageBlock/MessageBlock";

class UpdateProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal2: false,
      currentTitle: "",
      currentAction: "",
      currentActionType: "",
      notes: "",
      show: false,
      modal: false,
      deleteMedia: [],
      selectedFiles: [],
      selectedFiles2: [],
      isAuthorized: false,
      isUpdated: false,
      activeTab: "1",

      skuCreated: false,
      formData: {},
      files: {},
      lastUpdated: null,
    };
  }

  componentDidMount() {
    this.props.resetResponseValues();

    const { id, vid } = this.props.match.params;

    if (id > 0) {
      this.setState({ id, vid });
      this.props.fetchProduct({ productId: id, variantId: vid });
      this.props.fetchSimilarProduct({ productId: id, variantId: vid });
    }
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { responseVal } = this.props;
    const { id, vid } = this.props.match.params;
    const { formData } = this.state;

    if (prevProps.match.params.vid !== vid) {
      this.props.resetResponseValues();
      this.setState({ id, vid, isUpdated: false }, () => {
        this.props.fetchProduct({ productId: id, variantId: vid });
        this.props.fetchSimilarProduct({ productId: id, variantId: vid });
      });
    }

    const productData = responseVal.dObjectData?.productInfo?.data?.product;

    if (
      productData &&
      (productData.updatedOn !== prevState.lastUpdated ||
        productData.defaultVariantKey !== prevState.formData.defaultVariantKey)
    ) {
      this.setState({
        modal2: false,
        formData: productData,
        lastUpdated: productData.updatedOn,
        selectedFiles: [],
        selectedFiles2: [],
        files: [],
      });
    }

    if (formData.variants && !formData.selectedProductVariantId) {
      this.setState({
        formData: {
          ...formData,
          selectedProductVariantId: formData.variants[formData.defaultVariantKey].productVariantId,
        },
      });
    }
  }

  toggle2 = (requestData) => {
    this.props.resetResponseValues();
    if (this.state.modal2) {
      this.setState({
        modal2: false,
        notes: "",
        currentTitle: "",
        currentAction: "",
        currentActionType: "",
      });
    } else {
      this.setState({
        modal2: true,
        notes: "",
        currentTitle: requestData.title,
        currentAction: requestData.action,
        currentActionType: requestData.actionType,
      });
    }
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  removeFromState = (row) => (e) => {
    let filteredArray = this.state.files.filter((element) => element.name !== row.name);
    this.setState({ files: [...filteredArray] });
    this.props.removeMediaObject(row.name);
  };

  handleCheckBox = (e) => {
    e.persist();
    const checked = e.target.dataset.fileid;

    if (e.target.checked) {
      this.setState({ [e.target.name]: [...this.state[e.target.name], checked] });
    } else {
      this.setState((prevState) => ({
        [e.target.name]: prevState[[e.target.name]].filter((file) => file !== checked),
      }));
    }
  };

  handleChecked = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleCheckedFinal = (e) => {
    if (e.target.checked) {
      this.setState({
        formData: { ...this.state.formData, [e.target.name]: true },
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, [e.target.name]: false },
      });
    }
  };

  handleChangeNotes = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleChangeSelect = (obj, opt) => {
    if (opt.type === "attribute") {
      const { defaultVariantKey } = this.state.formData;

      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          variants: {
            ...prevState.formData.variants,
            [defaultVariantKey]: {
              ...prevState.formData.variants[defaultVariantKey],
              attributes: {
                ...prevState.formData.variants[defaultVariantKey].attributes,
                [obj]: {
                  ...prevState.formData.variants[defaultVariantKey].attributes[obj],
                  value: opt.value,
                },
              },
            },
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        formData: { ...prevState.formData, [obj]: opt.value },
      }));
    }
  };

  handleChange = (e) => {
    e.persist();

    if (e.target.dataset && e.target.dataset.object) {
      /** Collect all dimesions and merge in one (required to send on server) */
      if (e.target.dataset.object === "dimension") {
        let oldDimension = e.target.dataset.dimension;
        const pDimensions = oldDimension?.toLowerCase().split("x");
        const packagingDimensionsL = e.target.name === "packagingDimensionsL" ? e.target.value : pDimensions?.[0];
        const packagingDimensionsB = e.target.name === "packagingDimensionsB" ? e.target.value : pDimensions?.[1];
        const packagingDimensionsH = e.target.name === "packagingDimensionsH" ? e.target.value : pDimensions?.[2];

        this.setState((prevState) => ({
          ...prevState,
          formData: {
            ...prevState.formData,
            variants: {
              ...prevState.formData.variants,
              [this.state.formData.defaultVariantKey]: {
                ...prevState.formData.variants[this.state.formData.defaultVariantKey],
                packagingDimensions: `${packagingDimensionsL}x${packagingDimensionsB}x${packagingDimensionsH}`,
              },
            },
          },
        }));
      } else if (e.target.dataset.object === "variant") {
        this.setState((prevState) => ({
          ...prevState,
          formData: {
            ...prevState.formData,
            variants: {
              ...prevState.formData.variants,
              [this.state.formData.defaultVariantKey]: {
                ...prevState.formData.variants[this.state.formData.defaultVariantKey],
                [e.target.name]: e.target.value,
              },
            },
          },
        }));
      } else if (e.target.dataset.object === "attributes") {
        const { defaultVariantKey } = this.state.formData;

        this.setState((prevState) => ({
          ...prevState,
          formData: {
            ...prevState.formData,
            variants: {
              ...prevState.formData.variants,
              [defaultVariantKey]: {
                ...prevState.formData.variants[defaultVariantKey],
                attributes: {
                  ...prevState.formData.variants[defaultVariantKey].attributes,
                  [e.target.name]: {
                    ...prevState.formData.variants[defaultVariantKey].attributes[e.target.name],
                    value: e.target.value,
                  },
                },
              },
            },
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        formData: { ...prevState.formData, [e.target.name]: e.target.value },
      }));
    }
  };

  /** Mark image as default in product */
  markDefaultImage = (e) => {
    const { formData } = this.state;
    const fileId = e.target.value;

    const variantData = formData.variants && formData.variants[formData.defaultVariantKey];

    const reqData = {
      isProductImage: true,
      product: {
        productId: formData.productId,
        selectedProductVariantId: formData.selectedProductVariantId,
        variants: {
          [formData.defaultVariantKey]: {
            productVariantId: variantData.productVariantId,
            medias: [{ fileId: fileId, status: "ACTIVE", primary: true }],
          },
        },
      },
    };

    this.props.markAdminSkuMedia(reqData);
  };

  addSku = (e) => {
    e.preventDefault();
    this.setState({ isUpdated: true });
    const reqData = {
      isProduct: true,
      product: this.state.formData,
    };
    this.props.addUpdateSku(reqData);
  };

  onDrop = (acceptedFiles) => {
    const newObj = {};
    acceptedFiles.forEach((file) => {
      newObj[uniqid()] = { original: file, base64: null, cropped: null, uploaded: null };
    });

    this.setState({ ...this.state, show: true, files: { ...this.state.files, ...newObj } });
  };

  updateAdminSku = (actionType) => (e) => {
    e.preventDefault();

    if (this.state.modal2) {
      const { formData, notes } = this.state;
      const product = {
        ...formData,
        variants: {
          ...formData.variants,
          [formData.defaultVariantKey]: {
            ...formData.variants[formData.defaultVariantKey],
            status: actionType,
          },
        },
      };
      const reqData = {
        isProduct: true,
        productId: formData.productId,
        supplierUserId: formData.supplierUserId,
        actionType: actionType,
        notes,
        product: product,
      };

      this.props.markAdminSku(reqData);
      this.setState({ isUpdated: true });
    } else {
      this.toggle2({
        actionType: actionType,
        action: "updateSku",
        title: "Product Action",
      });
    }
  };

  updateAdminSkuMedia = (actionType) => (e) => {
    e.preventDefault();

    const { modal2, selectedFiles, selectedFiles2, formData } = this.state;

    if (modal2) {
      const mediaIds = [];
      if (actionType === "ACTIVE") {
        selectedFiles2.forEach((fileId) => {
          mediaIds.push({ fileId: fileId, status: actionType });
        });
      } else {
        selectedFiles.forEach((fileId) => {
          mediaIds.push({ fileId: fileId, status: actionType });
        });
      }

      const variantData = formData.variants && formData.variants[formData.defaultVariantKey];

      let reqData = {};
      reqData = {
        isProductImage: true,
        supplierUserId: formData.supplierUserId,

        product: {
          productId: formData.productId,
          selectedProductVariantId: formData.selectedProductVariantId,
          variants: {
            [formData.defaultVariantKey]: {
              productVariantId: variantData.productVariantId,
              medias: mediaIds,
            },
          },
        },
      };

      this.props.markAdminSkuMedia(reqData);
    } else {
      this.toggle2({
        actionType: actionType,
        action: "updateSkuMedia",
        title: "Product Media Action",
      });
    }
  };

  updateProductInfo = (actionType) => (e) => {
    e.preventDefault();

    if (this.state.modal2) {
      const { formData, notes } = this.state;
      const variantData = formData.variants && formData.variants[formData.defaultVariantKey];

      const paramObj = actionType === "ACTIVE" ? null : { status: actionType };

      const reqData = {
        productId: variantData.productMasterId,
        selectedProductVariantId: formData.selectedProductVariantId,
        defaultProductVariantId: variantData.productVariantId,
        notes,
      };

      this.props.updateProduct({ ...paramObj, product: reqData });
      this.setState({ isUpdated: true });
    } else {
      this.toggle2({
        actionType: actionType,
        action: "updateProduct",
        title: "Product Action",
      });
    }
  };

  /** Mark default sku */
  markDefaultSku = (skuId) => {
    const { formData } = this.state;
    const variantData = formData.variants && formData.variants[formData.defaultVariantKey];

    const reqData = {
      productId: variantData.productMasterId,
      defaultProductVariantId: variantData.productVariantId,
      defaultProductVariantSkuId: skuId,
    };

    this.props.markDefaultSKU({ product: reqData });
  };

  /** Save uploaded media to SKU */
  saveMediaWithProduct = (mediaList) => {
    const { formData } = this.state;

    const medias = [];

    mediaList?.forEach((fileId) => {
      medias.push({ fileId });
    });

    const productId = formData.productId;

    if (medias.length > 0) {
      this.setState({ isUpdated: true });
      const variantData = formData.variants && formData.variants[formData.defaultVariantKey];

      if (variantData) {
        const reqData = {
          product: {
            productId: productId,
            selectedProductVariantId: formData.selectedProductVariantId,
            skuMediaUploadedFinal: formData.skuMediaUploadedFinal,

            variants: {
              [formData.defaultVariantKey]: {
                productVariantId: variantData.productVariantId,
                medias,
              },
            },
          },
          isProductImage: true,
        };
        this.props.saveImages(reqData);
      }
    }
  };

  /** Upload cropped image to server and attach with Product */
  uploadCroppedImage = async () => {
    try {
      const fileIds = await this.props.uploadAllImages(this.state.files);
      this.saveMediaWithProduct(fileIds);
    } catch (error) {
      console.log("uploadCroppedImage", error);
    }
  };

  renderModal2 = () => {
    const { notes, currentAction, currentActionType, currentTitle } = this.state;

    return (
      <Modal backdrop="static" centered isOpen={this.state.modal2} toggle={this.toggle2}>
        <ModalHeader toggle={this.toggle2}>{currentTitle.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    <Form autoComplete="off">
                      <FormGroup>
                        <Input
                          type="textarea"
                          placeholder="Add notes/reason"
                          onChange={this.handleChangeNotes("notes")}
                          value={notes}
                        />
                      </FormGroup>
                    </Form>
                    <React.Fragment>{this.renderSwitch(currentActionType, currentAction)}</React.Fragment>
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  };

  renderSwitch(currentActionType, action) {
    if (action === "updateProduct") {
      switch (currentActionType) {
        case "ACTIVE":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateProductInfo("ACTIVE")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Mark as Default
            </Button>
          );
        case "PUBLISH":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateProductInfo("PUBLISH")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Publish Product
            </Button>
          );
        case "UNPUBLISH":
          return (
            <Button
              className="btn-block btn-danger"
              onClick={this.updateProductInfo("UNPUBLISH")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              UnPublish Product
            </Button>
          );

        default:
          return null;
      }
    }

    if (action === "updateSku") {
      switch (currentActionType) {
        case "ACTIVE":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateAdminSku("ACTIVE")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Activate
            </Button>
          );

        case "INACTIVE":
          return (
            <Button
              className="btn-block btn-danger"
              onClick={this.updateAdminSku("INACTIVE")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Deactivate
            </Button>
          );
        case "ONHOLD":
          return (
            <Button
              color="warning"
              className="btn-block"
              onClick={this.updateAdminSku("ONHOLD")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              OnHold
            </Button>
          );

        case "DELETE":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateAdminSku("DELETED")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              Delete
            </Button>
          );
        default:
          return null;
      }
    }

    if (action === "updateSkuMedia") {
      switch (currentActionType) {
        case "ACTIVE":
          return (
            <Button
              className="btn-block btn-success"
              onClick={this.updateAdminSkuMedia("ACTIVE")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              MARK ACTIVE
            </Button>
          );

        case "INACTIVE":
          return (
            <Button
              color="danger"
              className="btn-block"
              onClick={this.updateAdminSkuMedia("INACTIVE")}
              disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
            >
              MARK INACTIVE
            </Button>
          );
        default:
          return null;
      }
    }
  }

  setShow = (show) => {
    this.setState({ ...this.state, show: !show });
  };

  setFiles = (files) => {
    console.log("files", files);
    this.setState({ ...this.state, files: { ...files } });
  };

  render() {
    const { formData, files, selectedFiles, selectedFiles2, modal2, show } = this.state;
    const { responseVal, isLoading } = this.props;

    const dataLoaded = responseVal.dObjectData?.productInfo?.data?.product;
    const variantData = dataLoaded?.variants?.[dataLoaded.defaultVariantKey];
    const productImages = variantData?.medias?.filter((media) => media.status === "ACTIVE");
    const pendingImages = variantData?.medias?.filter((media) => media.status === "INACTIVE");
    const similarVariants = responseVal.dObjectData?.similarProducts?.data?.similarVariantProducts;

    let ovLabel = "";

    dataLoaded?.primaryCatFields &&
      Object.keys(dataLoaded.primaryCatFields).map((o) => {
        const k = dataLoaded.primaryCatFields[o];
        return (ovLabel = ovLabel + k.name + ": " + k.value + ", ");
      });

    const totalFiles = Object.keys(files).length;
    const totalCropped = Object.keys(files).filter((key) => files[key].cropped);

    return (
      <div>
        <PanelHeader />
        {isLoading && <div className="loader" />}
        {modal2 && this.renderModal2()}
        {show && (
          <CropperModal
            show={show}
            setShow={(show) => this.setShow(show)}
            files={files}
            setFiles={(files) => this.setFiles(files)}
            aspect={4 / 5}
          />
        )}

        <div className="content mt-3 ">
          <Row className="equal">
            <Col xs={12}>
              <MessageBlock screenName="product" />
            </Col>
            <Col sm={12}>
              {dataLoaded && variantData ? (
                <React.Fragment>
                  <Row>
                    <div className="col-sm-8">
                      <Row>
                        <Col md={12}>
                          <GoBack text={variantData.title + " (" + variantData.variant + ")"} />
                        </Col>

                        <Col md={12}>
                          {variantData.status === "ACTIVE" ? (
                            <>
                              <Button
                                size="sm"
                                className="btn-simple btn-light float-right btn-success mt-0"
                                onClick={this.updateProductInfo("PUBLISH")}
                              >
                                RePublish to App
                              </Button>

                              <Button
                                size="sm"
                                className="btn-simple btn-light float-right btn-danger mt-0"
                                onClick={this.updateProductInfo("UNPUBLISH")}
                              >
                                UnPublish from App
                              </Button>
                            </>
                          ) : (
                            <Button
                              size="sm"
                              className="btn-simple btn-light float-right btn-success mt-0"
                              onClick={this.updateProductInfo("PUBLISH")}
                            >
                              Publish to App
                            </Button>
                          )}
                          {variantData.productVariantId === formData.defaultProductVariantId ? (
                            <Badge
                              color="info"
                              className="float-left"
                              style={{
                                padding: "6px 7px",
                                marginLeft: 0,
                                marginRight: "10px",
                              }}
                            >
                              Default Variant
                            </Badge>
                          ) : variantData.status && variantData.medias && variantData.medias.length > 0 ? (
                            <Button
                              size="sm"
                              className="btn-simple btn-light float-right btn-success mt-0"
                              onClick={this.updateProductInfo("ACTIVE")}
                            >
                              Mark as Default
                            </Button>
                          ) : null}
                        </Col>
                      </Row>

                      {variantData.medias && variantData.medias.length > 0 ? null : (
                        <SuccessBlock
                          msg={{
                            userDisplayMsg: "Product does not have media",
                          }}
                          color="danger"
                          position="alert-relative"
                        />
                      )}

                      {!productImages.length ? (
                        <SuccessBlock
                          msg={{
                            userDisplayMsg: "Product does not have active media. Activate from 'PRODUCT IMAGES' tab",
                          }}
                          color="danger"
                          position="alert-relative"
                        />
                      ) : null}

                      <h5 className={`qcStatus ${variantData.status}`}>
                        {variantData.status === "ACTIVE" ? (
                          <i className="now-ui-icons ui-1_check" />
                        ) : variantData.status === "ONHOLD" ? (
                          <i className="now-ui-icons tech_watch-time" />
                        ) : variantData.status === "INACTIVE" ? (
                          <i className="now-ui-icons ui-1_lock-circle-open" />
                        ) : (
                          <i className="now-ui-icons ui-1_simple-remove" />
                        )}
                        Product is currently {variantData.status}
                      </h5>
                    </div>
                    <div className="col-sm-4 text-right">
                      {variantData.medias && variantData.medias.length > 0 ? (
                        <React.Fragment>
                          {variantData.status !== "ACTIVE" ? (
                            <Button
                              size="sm"
                              className="btn-simple btn-light btn-success mt-0"
                              onClick={this.updateAdminSku("ACTIVE")}
                            >
                              Activate
                            </Button>
                          ) : null}

                          {variantData.status !== "INACTIVE" ? (
                            <Button
                              size="sm"
                              className="btn-simple btn-light btn-danger mt-0"
                              onClick={this.updateAdminSku("INACTIVE")}
                            >
                              Deactivate
                            </Button>
                          ) : null}

                          {variantData.status !== "ONHOLD" ? (
                            <Button
                              size="sm"
                              className="btn-simple btn-light btn-warning mt-0"
                              onClick={this.updateAdminSku("ONHOLD")}
                            >
                              OnHold
                            </Button>
                          ) : null}
                          {variantData.status !== "DELETED" ? (
                            <Button
                              size="sm"
                              className="btn-simple btn-light btn-danger mt-0"
                              onClick={this.updateAdminSku("DELETE")}
                            >
                              Delete
                            </Button>
                          ) : null}
                        </React.Fragment>
                      ) : null}
                    </div>
                  </Row>
                  <Row>
                    <Col sm="8">
                      <Nav tabs>
                        <NavItem style={{ marginLeft: -1 }}>
                          <NavLink
                            className={`${this.state.activeTab === "1" ? "active" : ""}`}
                            onClick={() => {
                              this.toggleTab("1");
                            }}
                          >
                            PRODUCT INFO
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${this.state.activeTab === "2" ? "active" : ""}`}
                            onClick={() => {
                              this.toggleTab("2");
                            }}
                          >
                            PRODUCT IMAGES
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <ProductForm
                                form={formData}
                                handleChange={this.handleChange}
                                addSku={this.addSku}
                                handleChangeSelect={this.handleChangeSelect}
                                handleCheckedFinal={this.handleCheckedFinal}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col sm="12">
                              <div className="product-images">
                                {formData && formData.productId ? (
                                  <Row>
                                    <Col sm={12}>
                                      <Dropzone onFilesAdded={(opt) => this.onDrop(opt)} />
                                    </Col>

                                    <Col sm={12}>
                                      <Row className="p-2">
                                        <div className="p-2">
                                          {Object.keys(files).map((key) => {
                                            const file = files[key];
                                            return file.cropped ? (
                                              <div key={`cropped-${key}`}>
                                                <ImageZoom
                                                  src={file.cropped}
                                                  alt={file.cropped}
                                                  className="img-thumbnail rounded"
                                                  style={{ maxHeight: 100 }}
                                                />
                                              </div>
                                            ) : null;
                                          })}
                                        </div>
                                      </Row>
                                    </Col>

                                    <Col sm={12}>
                                      {Object.keys(files).length > 0 ? (
                                        <Button size="sm" color="light" onClick={() => this.setShow(false)}>
                                          Edit Images
                                        </Button>
                                      ) : null}

                                      {totalFiles > 0 && totalFiles === totalCropped?.length ? (
                                        <Button size={"sm"} color="success" onClick={() => this.uploadCroppedImage()}>
                                          Save Images
                                        </Button>
                                      ) : null}
                                    </Col>
                                  </Row>
                                ) : (
                                  <p className="pt-5 text-center">Please add product to upload images </p>
                                )}

                                {files ? (
                                  <Row className="mt-2 equal">
                                    {files.length > 0 &&
                                      files.map((acceptedFile) => (
                                        <Col sm="3" className="" key={acceptedFile.name}>
                                          <img
                                            alt={acceptedFile.name}
                                            src={URL.createObjectURL(acceptedFile)}
                                            className="img-thumbnail rounded"
                                          />
                                          <p className="text-center">{acceptedFile.name}</p>
                                          <button
                                            onClick={this.removeFromState(acceptedFile)}
                                            type="button"
                                            className="close img-remove"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </Col>
                                      ))}
                                  </Row>
                                ) : null}

                                {variantData.medias && variantData.medias.length > 0 ? (
                                  <React.Fragment>
                                    <div className="image-box mt-2">
                                      <h5 className="card-header d-flex justify-content-between">
                                        <span>Published Product Images (These images are live on App)</span>
                                        <Button
                                          size="sm"
                                          className="btn-simple btn-light btn-danger my-0"
                                          onClick={this.updateAdminSkuMedia("INACTIVE")}
                                          disabled={selectedFiles.length < 1}
                                        >
                                          Remove from product
                                        </Button>
                                      </h5>
                                      <div className="card-body">
                                        {productImages?.length > 0 ? (
                                          <Row>
                                            {productImages?.map((acceptedFile) => {
                                              const filePath = getMediaPath(acceptedFile.fileId, acceptedFile.fileExt);
                                              return acceptedFile.status === "ACTIVE" ? (
                                                <Col sm="2" key={"p" + acceptedFile.mediaId}>
                                                  <div className="marker--relative">
                                                    <ImageZoom
                                                      src={filePath}
                                                      alt=""
                                                      className="img-thumbnail rounded"
                                                    />

                                                    {acceptedFile.fileId === formData.defaultFileId ? (
                                                      <Button color="success" size="xs" className="default--marker">
                                                        Default Image
                                                      </Button>
                                                    ) : (
                                                      <Button
                                                        color="info"
                                                        value={acceptedFile.fileId}
                                                        size="xs"
                                                        className="default--marker"
                                                        onClick={this.markDefaultImage}
                                                      >
                                                        Mark as Default
                                                      </Button>
                                                    )}
                                                  </div>
                                                  <FormGroup check>
                                                    <Label check>
                                                      <Input
                                                        checked={selectedFiles.includes(acceptedFile.fileId)}
                                                        type="checkbox"
                                                        name="selectedFiles"
                                                        data-fileid={acceptedFile.fileId}
                                                        onChange={this.handleCheckBox}
                                                      />
                                                      <span className="form-check-sign" />
                                                      Select
                                                    </Label>
                                                  </FormGroup>
                                                </Col>
                                              ) : null;
                                            })}
                                          </Row>
                                        ) : (
                                          <small className="ml-2">No Image has been published</small>
                                        )}
                                      </div>
                                    </div>

                                    <div className="image-box mt-2">
                                      <h5 className="card-header d-flex justify-content-between">
                                        <span>Available Pending Product Images</span>
                                        <Button
                                          size="sm"
                                          className="btn-simple btn-light btn-success my-0"
                                          onClick={this.updateAdminSkuMedia("ACTIVE")}
                                          disabled={selectedFiles2.length < 1}
                                        >
                                          Add to product
                                        </Button>
                                      </h5>
                                      <div className="card-body">
                                        {pendingImages && pendingImages.length > 0 ? (
                                          <Row>
                                            {pendingImages.map((acceptedFile) => {
                                              const filePath = getMediaPath(acceptedFile.fileId, acceptedFile.fileExt);
                                              return acceptedFile.status === "INACTIVE" ? (
                                                <Col sm="2" key={"a" + acceptedFile.mediaId}>
                                                  <div className="marker--relative">
                                                    <ImageZoom
                                                      src={filePath}
                                                      alt=""
                                                      className="img-thumbnail rounded"
                                                    />

                                                    {acceptedFile.fileId === formData.defaultFileId ? (
                                                      <Button color="success" size="xs" className="default--marker">
                                                        Default Image
                                                      </Button>
                                                    ) : (
                                                      <Button
                                                        color="info"
                                                        value={acceptedFile.fileId}
                                                        size="xs"
                                                        className="default--marker"
                                                        onClick={this.markDefaultImage}
                                                      >
                                                        Mark as Default
                                                      </Button>
                                                    )}
                                                  </div>
                                                  <FormGroup check>
                                                    <Label check>
                                                      <Input
                                                        checked={selectedFiles2.includes(acceptedFile.fileId)}
                                                        type="checkbox"
                                                        name="selectedFiles2"
                                                        data-fileid={acceptedFile.fileId}
                                                        onChange={this.handleCheckBox}
                                                      />
                                                      <span className="form-check-sign" />
                                                      Select
                                                    </Label>
                                                  </FormGroup>
                                                </Col>
                                              ) : null;
                                            })}
                                          </Row>
                                        ) : (
                                          <small className="ml-2">No Image is Pending</small>
                                        )}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ) : null}

                                {dataLoaded.similarMedias && dataLoaded.similarMedias.length > 0 ? (
                                  <div className="image-box mt-2">
                                    <h5 className="card-header">
                                      Other Similar Images (You can copy below images to current Product)
                                    </h5>
                                    <div className="card-body">
                                      <Row>
                                        {dataLoaded.similarMedias.map((m) => {
                                          const filePath = getMediaPath(m.fileId, m.fileExt);
                                          return m.mediaId ? (
                                            <Col sm="2" key={"simsim" + m.mediaId}>
                                              <div className="marker--relative">
                                                <ImageZoom src={filePath} alt="" className="img-thumbnail rounded" />
                                              </div>
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    checked={selectedFiles.includes(m.fileId)}
                                                    type="checkbox"
                                                    name="selectedFiles"
                                                    data-fileid={m.fileId}
                                                    onChange={this.handleCheckBox}
                                                  />
                                                  <span className="form-check-sign" />
                                                  Select
                                                </Label>
                                              </FormGroup>
                                            </Col>
                                          ) : null;
                                        })}
                                      </Row>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Col>
                    <Col sm="4">
                      {formData && formData.variants && Object.keys(formData.variants).length > 1 ? (
                        <React.Fragment>
                          <h4>
                            Other Variants{" "}
                            <span
                              style={{
                                textTransform: "uppercase",
                                fontSize: 14,
                              }}
                            >
                              ({ovLabel && ovLabel.slice(0, -2)})
                            </span>
                          </h4>
                          <ListGroup>
                            {Object.keys(formData.variants).map((key) => {
                              const product = formData.variants[key];

                              return (
                                <React.Fragment key={`list-it-${key}`}>
                                  {formData.defaultVariantKey !== key ? (
                                    <ListGroupItem
                                      className={product.variant === formData.defaultVariantKey ? "active" : ""}
                                      key={"kk" + product.productVariantId}
                                    >
                                      <Link
                                        style={{ display: "block" }}
                                        to={
                                          ADMIN_PRODUCT_VIEW +
                                          product.productMasterId +
                                          "/variant/" +
                                          product.productVariantId
                                        }
                                      >
                                        Variant ID: {product.productVariantId} (
                                        {product.variantType === "NONE" ? null : product.variantType + ":"}
                                        {key})
                                        <span className="float-right">
                                          {product.status === "ACTIVE" ? (
                                            <span className="text-success">Active</span>
                                          ) : (
                                            <span className="text-danger">Inactive</span>
                                          )}
                                        </span>
                                        {product.productVariantId === formData.defaultProductVariantId ? (
                                          <Badge color="info" className="float-right" style={{ marginRight: 10 }}>
                                            Default
                                          </Badge>
                                        ) : null}
                                      </Link>
                                    </ListGroupItem>
                                  ) : null}
                                </React.Fragment>
                              );
                            })}
                          </ListGroup>
                        </React.Fragment>
                      ) : null}

                      {similarVariants && Object.keys(similarVariants).length ? (
                        <React.Fragment>
                          {Object.keys(similarVariants).map((k, index) => {
                            const obj = similarVariants[k];
                            const products = obj.productVariants;
                            let kvLabel = "";
                            obj.keyValues &&
                              Object.keys(obj.keyValues).map((o) => {
                                const k = obj.keyValues[o];
                                return (kvLabel = kvLabel + k.name + ": " + k.value + ", ");
                              });

                            return (
                              <React.Fragment key={"topps" + index}>
                                <h4 className="mt-3">
                                  Similar Variants{" "}
                                  <span
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: 14,
                                    }}
                                  >
                                    ({kvLabel && kvLabel.slice(0, -2)})
                                  </span>
                                </h4>
                                <ListGroup>
                                  {products.map((product) => {
                                    return (
                                      <ListGroupItem key={"kssk" + product.productVariantId}>
                                        <Link
                                          style={{ display: "block" }}
                                          to={
                                            ADMIN_PRODUCT_VIEW +
                                            product.productMasterId +
                                            "/variant/" +
                                            product.productVariantId
                                          }
                                        >
                                          Variant ID: {product.productVariantId} (
                                          {product.variantType === "NONE"
                                            ? null
                                            : product.variantType + ":" + product.variant}
                                          )
                                          <span className="float-right">
                                            {product.status === "ACTIVE" ? (
                                              <span className="text-success">Active</span>
                                            ) : (
                                              <span className="text-danger">Inactive</span>
                                            )}
                                          </span>
                                        </Link>
                                      </ListGroupItem>
                                    );
                                  })}
                                </ListGroup>
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      ) : null}

                      {variantData && variantData.skuIds ? (
                        <React.Fragment>
                          <h4 className="mt-3">Attached SKUs</h4>
                          <ListGroup>
                            {Object.keys(variantData.skuIds).map((skuId) => {
                              const skuData = variantData.skuIds[skuId];
                              return (
                                <React.Fragment key={`attached-id-${skuId}`}>
                                  <ListGroupItem>
                                    <Link style={{ display: "inline-block" }} to={ADMIN_SKU_VIEW + skuId}>
                                      SKU ID: {skuId}
                                      <span className="ml-2">
                                        {skuData ? (
                                          <span className="text-success">(Active)</span>
                                        ) : (
                                          <span className="text-danger">(Inactive)</span>
                                        )}
                                      </span>
                                    </Link>

                                    <span className="float-right">
                                      {variantData.preferredSkuId === skuId ? (
                                        <span className="text-success">Default SKU</span>
                                      ) : (
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className="text-danger"
                                          onClick={() => this.markDefaultSku(skuId)}
                                        >
                                          Mark as Default
                                        </span>
                                      )}
                                    </span>
                                  </ListGroupItem>
                                </React.Fragment>
                              );
                            })}
                          </ListGroup>
                        </React.Fragment>
                      ) : null}
                    </Col>
                  </Row>
                </React.Fragment>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    categories: state.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProduct: (reqParam) => dispatch(fetchProduct(reqParam)),
    updateProduct: (reqParam) => dispatch(updateProduct(reqParam)),
    fetchSimilarProduct: (reqParam) => dispatch(fetchSimilarProduct(reqParam)),
    addUpdateSku: (reqParam) => dispatch(addUpdateSku(reqParam)),
    uploadImages: (acceptedFile) => dispatch(uploadImages(acceptedFile)),
    saveImages: (reqParam) => dispatch(saveImages(reqParam)),
    markAdminSku: (reqParam) => dispatch(markAdminSku(reqParam)),
    markAdminSkuMedia: (reqParam) => dispatch(markAdminSkuMedia(reqParam)),
    removeMediaObject: (reqParam) => dispatch(removeMediaObject(reqParam)),
    resetResponseValues: () => dispatch(resetResponseValues()),
    markDefaultSKU: (reqParam) => dispatch(markDefaultSKU(reqParam)),
    uploadAllImages: (files) => dispatch(uploadAllImages(files)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProduct);
