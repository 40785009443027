export const SET_VALUES = "SET_VALUES";
export const RESET_VALUES = "RESET_VALUES";
export const RESET_OBJECT = "RESET_OBJECT";
export const START_UI_LOADER = "START_UI_LOADER";
export const STOP_UI_LOADER = "STOP_LOADER_STOP";
export const SAVE_ERROR = "SAVE_ERROR";

export const SAVE_AUTH_INFO = "SAVE_AUTH_INFO";
export const REMOVE_AUTH_INFO = "REMOVE_AUTH_INFO";

export const UPDATE_AUTH_TOKEN_INFO = "UPDATE_AUTH_TOKEN_INFO";
export const CREATE_OR_UPDATE_USER_INFO = "CREATE_OR_UPDATE_USER_INFO";

export const USER_LOGOUT = "USER_LOGOUT";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const SAVE_PROFILE = "SAVE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const SAVE_WORKFLOW = "SAVE_WORKFLOW";

export const SAVE_KYC = "SAVE_KYC";
export const UPDATE_KYC = "UPDATE_KYC";

export const SAVE_BANK = "SAVE_BANK";
export const UPDATE_BANK = "UPDATE_BANK";

export const SAVE_ADDRESS = "SAVE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";

export const SAVE_CONTACT = "SAVE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";

export const SAVE_STATES = "SAVE_STATES";
export const SAVE_CITIES = "SAVE_CITIES";

export const CLEAR_STORE = "CLEAR_STORE";

export const SAVE_CATEGORIES = "SAVE_CATEGORIES";

export const SAVE_MEDIA_LIST = "SAVE_MEDIA_LIST";
export const CLEAR_MEDIA_LIST = "CLEAR_MEDIA_LIST";
export const REMOVE_MEDIA_LIST = "REMOVE_MEDIA_LIST";

export const SAVE_MEDIA_OBJECT = "SAVE_MEDIA_OBJECT";
export const CLEAR_MEDIA_OBJECT = "CLEAR_MEDIA_OBJECT";
export const REMOVE_MEDIA_OBJECT = "REMOVE_MEDIA_OBJECT";

export const CLEAR_SAMPLE_LIST = "CLEAR_SAMPLE_LIST";
export const SAVE_SAMPLE_LIST = "SAVE_SAMPLE_LIST";
export const SAVE_SAMPLE_DETAIL = "SAVE_SAMPLE_DETAIL";
export const CLEAR_SAMPLE_DETAIL = "CLEAR_SAMPLE_DETAIL";

export const CLEAR_CUSTOM_OFFER_LIST = "CLEAR_CUSTOM_OFFER_LIST";
export const SAVE_CUSTOM_OFFER_LIST = "SAVE_CUSTOM_OFFER_LIST";
export const SAVE_CUSTOM_OFFER_DETAIL = "SAVE_CUSTOM_OFFER_DETAIL";
export const CLEAR_CUSTOM_OFFER_DETAIL = "CLEAR_CUSTOM_OFFER_DETAIL";

export const SAVE_RESPONSE_MESSAGE = "SAVE_RESPONSE_MESSAGE";
export const CLEAR_RESPONSE_MESSAGE = "CLEAR_RESPONSE_MESSAGE";
export const CLEAR_RESPONSE_MESSAGES = "CLEAR_RESPONSE_MESSAGES";

export const SAVE_BILL_BOOK_LIST = "SAVE_BILL_BOOK_LIST";
export const SAVE_BILL_DETAIL = "SAVE_BILL_DETAIL";
export const CLEAR_BILL_BOOK = "CLEAR_BILL_BOOK";
export const SAVE_MESSAGE_LOG = "SAVE_MESSAGE_LOG";

export const SAVE_HOME_STATS = "SAVE_HOME_STATS";
export const SAVE_COUNT_STATS = "SAVE_COUNT_STATS";

export const SAVE_USER_ACTIVITY = "SAVE_USER_ACTIVITY";

export const SAVE_ORDER_REQUEST_LIST = "SAVE_ORDER_REQUEST_LIST";
export const SAVE_ORDER_REQUEST = "SAVE_ORDER_REQUEST";
export const CLEAR_ALL_ORDER_REQUESTS = "CLEAR_ALL_ORDER_REQUESTS";
export const CLEAR_ORDER_REQUEST = "CLEAR_ORDER_REQUEST";
export const SAVE_ORDER_REQUEST_REPLY = "SAVE_ORDER_REQUEST_REPLY";
export const CLEAR_ORDER_REQUEST_REPLY = "CLEAR_ORDER_REQUEST_REPLY";

export const SAVE_TEMP_SKUS = "SAVE_TEMP_SKUS";
export const SAVE_TEMP_SKU = "SAVE_TEMP_SKU";
