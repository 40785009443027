import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

//import Select from "react-select";

import { connect } from "react-redux";
import { getKyc, updateKyc, getBankInfo, updateBankInfo, getProfile } from "../../../datautils/actions/userActions";
import { resetResponseValues } from "../../../datautils/actions/responseHandlerActions";
import { uploadMedia } from "../../../datautils/actions/mediaActions";

import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import ErrorBlock from "../../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../../components/SuccessBlock/SuccessBlock";
import ImageUpload from "../../../components/CustomUpload/ImageUpload";
import KycBlock from "../../../components/Profile/KycBlock";
import BankBlock from "../../../components/Profile/BankBlock";
import GoBack from "../../../components/GoBack/GoBack";
import TooltipItem from "../../../components/Tooltip/Tooltip";
import { documentIdentifierNumberMapping } from "../../../utils/constants";

class Kyc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMode: false,
      apiDataFetched: false,
      apiData: null,
      modal: false,
      selectedBlock: null,
      documentId: "",
      fileId: "",
      fileExt: "",
      documentIdentitierNo: "",
      documentTypeName: "",
      documentAdditionalInfo: "",
      documentIdentitierNoState: "",
      documentTypeNameState: "",
      documentAdditionalInfoState: "",
      file: null,
      fileState: "",

      accountNumber: "",
      accountName: "",
      bankName: "",
      city: "",
      branchName: "",
      ifscCode: "",
      micrCode: "",
      branchAddress: "",
      branchAddInfo: "",

      workflow: null,
    };
  }

  toggle = () => {
    const { selectedBlock } = this.state;
    const { profile } = this.props;

    let documentType = "";
    if (selectedBlock === "BUSINESS_ADDRESS_INFO") {
      documentType = "BUSINESS_REGISTERED_ADDRESS";
    } else {
      documentType = selectedBlock.replace(/_INFO/gi, "");
    }

    let currentDoc;
    if (profile.kyc?.kycDetailsMap && documentType in profile.kyc.kycDetailsMap) {
      currentDoc = profile.kyc.kycDetailsMap[documentType];
    } else if (profile.bank && documentType === "BANK") {
      currentDoc = profile.bank;
    }

    const defaultNo =
      selectedBlock === "BOARD_RESOLUTION_INFO" ||
      selectedBlock === "SIGNATURE_SAMPLE" ||
      selectedBlock === "BUSINESS_REGISTERED_ADDRESS"
        ? selectedBlock
        : "";

    if (currentDoc) {
      if (selectedBlock === "BANK_INFO") {
        this.setState((prevState) => ({
          modal: !prevState.modal,
          documentId: currentDoc.documentId,
          accountNumber: currentDoc.accountNumber,
          accountName: currentDoc.accountName,
          bankName: currentDoc.bankName,
          city: currentDoc.city,
          branchName: currentDoc.branchName,
          ifscCode: currentDoc.ifscCode,
          micrCode: currentDoc.micrCode,
          branchAddress: currentDoc.branchAddress,
          branchAddInfo: currentDoc.branchAddInfo,
          fileId: currentDoc.cancelledChequeFileFileId,
          fileExt: currentDoc.cancelledChequeFileFileExt,
        }));
      } else {
        this.setState((prevState) => ({
          modal: !prevState.modal,
          documentId: currentDoc.documentId,
          fileId: currentDoc.fileId,
          fileExt: currentDoc.fileExt,
          documentIdentitierNo: currentDoc.documentIdentitierNo,
          documentTypeName: currentDoc.documentType,
          documentAdditionalInfo: currentDoc.documentAdditionalInfo,
          documentIdentitierNoState: "",
          documentTypeNameState: "",
          fileState: "",
        }));
      }
      //this.props.resetResponseValues();
    } else {
      this.setState((prevState) => ({
        modal: !prevState.modal,
        documentId: "",
        fileId: "",
        fileExt: "",
        documentIdentitierNo: defaultNo,
        documentIdentitierNoState: defaultNo ? "is-valid" : "",
        documentTypeName: documentType || "",
        documentTypeNameState: documentType ? "is-valid" : "",
        documentAdditionalInfo: "",
        documentAdditionalInfoState: "",
        file: null,
      }));
    }
    this.props.resetResponseValues();
  };

  setValue = (selectedBlock) => {
    this.setState({ selectedBlock, viewMode: false }, () => this.toggle());
  };

  setValueView = (selectedBlock, workflow) => {
    this.setState({ selectedBlock, viewMode: true, workflow }, () => this.toggle());
  };

  componentDidMount() {
    this.props.resetResponseValues();

    const authData = {
      userId: this.props.userId,
    };
    this.props.getKyc(authData);
    this.props.getBankInfo(authData);
    this.props.getProfile(authData);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.profile.newSupplier && !this.state.profileFetched) {
      this.setState({
        profileData: this.props.profile,
        profileFetched: true,
      });
    }
    if (this.props.responseVal.status === "success" && this.state.modal) {
      this.setState({ modal: false });
    }
  }

  handleChange = (input) => (event) => {
    if (input === "documentIdentitierNo") {
      let documentIdentitierNo = event.target.value;
      let documentIdentitierNoState = this.state.documentIdentitierNoState;
      if (documentIdentitierNo.length > 1) {
        documentIdentitierNoState = "is-valid";
      } else {
        documentIdentitierNoState = "is-invalid";
      }
      this.setState({ documentIdentitierNo, documentIdentitierNoState });
    }
    if (input === "documentTypeName") {
      let documentTypeName = event.target.value;
      let documentTypeNameState = this.state.documentTypeNameState;
      if (documentTypeName.length > 1) {
        documentTypeNameState = "is-valid";
      } else {
        documentTypeNameState = "is-invalid";
      }
      this.setState({ documentTypeName, documentTypeNameState });
    } else if (input === "documentAdditionalInfo") {
      let documentAdditionalInfo = event.target.value;
      let documentAdditionalInfoState = this.state.documentAdditionalInfoState;

      if (documentAdditionalInfo.length > 5) {
        documentAdditionalInfoState = "is-valid";
      } else {
        documentAdditionalInfoState = "is-invalid";
      }
      this.setState({ documentAdditionalInfo, documentAdditionalInfoState });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }

    //console.log(this.state);
  };

  updateKyc = (e) => {
    e.preventDefault();

    const {
      selectedBlock,
      documentId,
      documentIdentitierNo,
      documentTypeName,
      documentAdditionalInfo,
      fileId,
      file,
    } = this.state;

    let documentType = "";
    if (selectedBlock === "BUSINESS_ADDRESS_INFO") {
      documentType = "BUSINESS_REGISTERED_ADDRESS";
    } else {
      documentType = selectedBlock.replace(/_INFO/gi, "");
    }

    const reqData = {
      fileId,
      documentId,
      documentType,
      documentTypeName,
      documentIdentitierNo,
      documentAdditionalInfo,
      userId: this.props.userId,
      userType: "SUPPLIER",
    };

    this.props.updateKyc({ ...reqData }, file);
    this.setState({ isUpdated: true });
  };

  updateBankInfo = () => {
    const {
      selectedBlock,
      fileId,
      file,
      documentId,
      accountNumber,
      accountName,
      bankName,
      city,
      branchName,
      ifscCode,
      micrCode,
      branchAddress,
      branchAddInfo,
    } = this.state;

    const documentType = selectedBlock.replace(/_INFO/gi, "");

    const authData = {
      documentType,
      documentId,
      accountNumber,
      accountName,
      bankName,
      city,
      branchName,
      ifscCode,
      micrCode,
      branchAddress,
      branchAddInfo,
      userId: this.props.userId,
      userType: "SUPPLIER",
      fileId,
    };

    this.props.updateBankInfo(authData, file);
    this.setState({ isUpdated: true });
  };

  handleUpload = (file) => {
    if (file) {
      this.setState({ file: file, fileState: "is-valid" });
    } else {
      this.setState({ file: null, fileState: "is-invalid" });
    }
  };

  renderModal = () => {
    const {
      viewMode,
      // fileState,
      fileId,
      fileExt,
      documentIdentitierNo,
      documentIdentitierNoState,
      // documentTypeName,
      // documentTypeNameState,
      documentAdditionalInfo,

      selectedBlock,
      accountNumber,
      accountNumberState,
      accountName,
      accountNameState,
      bankName,
      bankNameState,
      city,
      cityState,
      branchName,
      branchNameState,
      ifscCode,
      ifscCodeState,
      micrCode,
      micrCodeState,
      branchAddress,
      branchAddressState,
      branchAddInfo,
    } = this.state;

    const { profile } = this.props;

    let formData = "";

    if (selectedBlock === "BANK_INFO") {
      if (viewMode) {
        formData = <BankBlock type={selectedBlock} content={profile.bank} />;
      } else {
        formData = (
          <React.Fragment>
            <Form autoComplete="off">
              <FormGroup row>
                <Label for="accountNumber" sm={3}>
                  Account Number<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={accountNumberState}
                    type="text"
                    name="accountNumber"
                    placeholder="Account Number"
                    onChange={this.handleChange("accountNumber")}
                    value={accountNumber}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="accountName" sm={3}>
                  Account Name<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={accountNameState}
                    type="text"
                    name="accountName"
                    placeholder="Account Name"
                    onChange={this.handleChange("accountName")}
                    value={accountName}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="bankName" sm={3}>
                  Bank Name<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={bankNameState}
                    type="text"
                    name="bankName"
                    placeholder="Bank Name"
                    onChange={this.handleChange("bankName")}
                    value={bankName}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="city" sm={3}>
                  City<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={cityState}
                    type="text"
                    name="city"
                    placeholder="City"
                    onChange={this.handleChange("city")}
                    value={city}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="branchName" sm={3}>
                  Branch Name<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={branchNameState}
                    type="text"
                    name="branchName"
                    placeholder="Branch Name"
                    onChange={this.handleChange("branchName")}
                    value={branchName}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="ifscCode" sm={3}>
                  IFSC Code<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={ifscCodeState}
                    type="text"
                    name="ifscCode"
                    placeholder="IFSC Code"
                    onChange={this.handleChange("ifscCode")}
                    value={ifscCode}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="micrCode" sm={3}>
                  MICR Code<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={micrCodeState}
                    type="text"
                    name="micrCode"
                    placeholder="MICR Code"
                    onChange={this.handleChange("micrCode")}
                    value={micrCode}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="branchAddress" sm={3}>
                  Branch Address<span className="star">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    className={branchAddressState}
                    type="text"
                    name="branchAddress"
                    placeholder="Branch Address"
                    onChange={this.handleChange("branchAddress")}
                    value={branchAddress}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="branchAddInfo" sm={3}>
                  Notes
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="branchAddInfo"
                    placeholder="Notes"
                    onChange={this.handleChange("branchAddInfo")}
                    value={branchAddInfo}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="branchAddInfo" sm={12}>
                  Cancelled Cheque Image<span className="star">*</span>
                </Label>
                <Col sm={12}>
                  <ImageUpload handleUpload={this.handleUpload} existingImage={fileId + "." + fileExt} />
                </Col>
              </FormGroup>
            </Form>

            <Button
              color="primary"
              className="btn-block"
              onClick={this.updateBankInfo}
              disabled={
                this.props.isLoading ||
                !accountName ||
                !accountNumber ||
                !branchName ||
                !branchAddress ||
                !city ||
                !micrCode ||
                !ifscCode
              }
            >
              Save Bank Info
            </Button>
          </React.Fragment>
        );
      }
    } else {
      if (viewMode) {
        formData = (
          <KycBlock
            workflow={this.state.workflow}
            userType={this.props.userType}
            type={selectedBlock}
            content={profile.kyc}
          />
        );
      } else {
        formData = (
          <React.Fragment>
            <Form autoComplete="off">
              {selectedBlock !== "BOARD_RESOLUTION_INFO" &&
              selectedBlock !== "SIGNATURE_SAMPLE" &&
              selectedBlock !== "BUSINESS_REGISTERED_ADDRESS" ? (
                <Row>
                  <Label md={12}>
                    {documentIdentifierNumberMapping[selectedBlock]} <span className="star">*</span>
                  </Label>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Input
                        className={documentIdentitierNoState}
                        type="text"
                        name="documentIdentitierNo"
                        placeholder={`Enter ${documentIdentifierNumberMapping[selectedBlock]}`}
                        onChange={this.handleChange("documentIdentitierNo")}
                        value={documentIdentitierNo}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ) : null}

              {/* <Row>
                <Label md={12}>
                  Document Type<span className="star">*</span>
                </Label>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <Input
                      className={documentTypeNameState}
                      type="text"
                      name="documentTypeName"
                      placeholder={"eg: " + selectedBlock.replace(/_INFO/g, " ")}
                      onChange={this.handleChange("documentTypeName")}
                      value={documentTypeName}
                    />
                  </FormGroup>
                </Col>
              </Row> */}

              <Row>
                <Label md={12}>
                  {selectedBlock === "BUSINESS_REGISTERED_ADDRESS" ? "Registered Address" : "Notes"}
                </Label>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="documentAdditionalInfo"
                      placeholder={
                        selectedBlock === "BUSINESS_REGISTERED_ADDRESS" ? "Enter Registered Address" : "Enter Notes"
                      }
                      onChange={this.handleChange("documentAdditionalInfo")}
                      value={documentAdditionalInfo}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <ImageUpload handleUpload={this.handleUpload} existingImage={fileId + "." + fileExt} />
            </Form>

            <Button
              color="primary"
              className="btn-block"
              onClick={this.updateKyc}
              disabled={this.props.isLoading || !documentIdentitierNo}
            >
              Save KYC
            </Button>
          </React.Fragment>
        );
      }
    }

    return (
      <Modal backdrop="static" centered isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
        <ModalHeader toggle={this.toggle}>{this.state.selectedBlock.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">{formData}</div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { profileFetched, isUpdated } = this.state;
    const { profile, responseVal } = this.props;

    return (
      <div>
        <PanelHeader />
        <div className="content mt-3">
          {this.state.modal ? this.renderModal() : null}

          {responseVal.status === "failure" ? <ErrorBlock errors={responseVal.errors} /> : null}
          {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}

          <Row>
            <Col sm="12">
              <GoBack text="KYC Detail" />
            </Col>
            {profileFetched && !profile.newSupplier && profile.workflow ? (
              <React.Fragment>
                {Object.keys(profile.workflow.workflowStatusMap).map((index) => {
                  const topLevel = profile.workflow.workflowStatusMap[index];

                  return (
                    <React.Fragment key={index}>
                      {Object.keys(topLevel).map((index) => {
                        const midLevel = topLevel[index];
                        const midLevelArray = midLevel.subSections;
                        return index === "KYC_INFO" ? (
                          <React.Fragment key={index}>
                            {Object.keys(midLevelArray).map((index) => {
                              const lowLevel = midLevelArray[index];

                              return (
                                <Col className="d-flex psmall" lg={3} sm={6} xs={12} key={`top-${index}`}>
                                  <Card className="card-stats">
                                    <CardBody>
                                      <div className="row">
                                        <div className="col">
                                          <h5 className="text-capitalize">
                                            <span className={`status ${lowLevel.currentState}`} id={"Tooltip-" + index}>
                                              <i className="now-ui-icons ui-1_check" />
                                            </span>
                                            {index.replace(/_/g, " ").toLowerCase()}
                                          </h5>
                                          <TooltipItem
                                            placement="right"
                                            text={lowLevel.currentState || ""}
                                            id={index}
                                          />

                                          <div className="admin-action p-2 mt-2 mb-3" style={{ background: "#f5f5f5" }}>
                                            <div className="mt-0 mb-0 d-flex justify-content-between">
                                              <p className="mb-0 text-muted">Current Status</p>
                                              <p className="mb-0 mt-0">
                                                <span className={`text-${lowLevel.currentState}`}>
                                                  {lowLevel.currentState?.replace(/_/g, "")}
                                                </span>
                                              </p>
                                            </div>

                                            <div className="mt-2 mb-0 d-flex justify-content-between">
                                              <p className="mb-0 text-muted">Status Notes</p>
                                              <p className="mb-0 mt-0 text-right">
                                                <span>{lowLevel.statusMessage}</span>
                                              </p>
                                            </div>

                                            {/* <div className="mt-2 mb-0 d-flex justify-content-between">
                                              <p className="mb-0 text-muted">Admin Notes</p>
                                              <p className="mb-0 mt-0 text-right">
                                                <span>{lowLevel.adminWorkflowMessage}</span>
                                              </p>
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12">
                                          <Button
                                            color="secondary"
                                            size="xs"
                                            className="mt-0 btn-simple btn-primary"
                                            onClick={() => {
                                              this.setValue(index);
                                            }}
                                          >
                                            Edit
                                          </Button>

                                          {lowLevel.currentState !== "INCOMPLETE" ? (
                                            <Button
                                              color="secondary"
                                              size="xs"
                                              className="mt-0 btn-simple btn-primary"
                                              onClick={() => {
                                                this.setValueView(index, lowLevel);
                                              }}
                                            >
                                              View
                                            </Button>
                                          ) : null}
                                        </div>{" "}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              );
                            })}{" "}
                          </React.Fragment>
                        ) : null;
                      })}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ) : null}
            {this.props.isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    userId: state.auth.userId,
    userType: state.auth.userType,
    responseVal: state.resHandlerVal,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadMedia: (data) => dispatch(uploadMedia(data)),
    getKyc: (authData) => dispatch(getKyc(authData)),
    updateKyc: (authData, file) => dispatch(updateKyc(authData, file)),
    getBankInfo: (authData) => dispatch(getBankInfo(authData)),
    updateBankInfo: (authData, file) => dispatch(updateBankInfo(authData, file)),
    getProfile: (authData) => dispatch(getProfile(authData)),
    resetResponseValues: () => dispatch(resetResponseValues()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kyc);
