import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { connect } from "react-redux";
import {
  getBillDetail,
  addUpdateBill,
  addUpdateEntry,
  messageBillEntry,
} from "../../datautils/actions/billBookActions";
import Dropzone from "../../components/Dropzone/Dropzone";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import MessageBlock from "../../components/MessageBlock/MessageBlock";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { getMediaUrl } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateTime } from "../../utils/helpers/HelperFunctions";

class BillBookDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 50,
      id: 0,

      updatedOn: null,
      selectedRowData: null,
      selectedRow: -1,
      modal: false,
      title: "",
      action: "",
      amount: "",
      message: "",
      refBillIds: "",
      billMessage: "",
      files: [],
      files2: [],
    };

    this.frameRef = React.createRef();
  }

  toggle = (obj) => {
    this.setState({ modal: !this.state.modal, ...obj });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getBillDetail({
      toUserId: id,
      paginationOptions: {
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
      },
    });
    this.setState({ id });
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    const { billDetail } = this.props;
    const { selectedRow, selectedRowData, action, pageNumber } = this.state;

    if (prevState.pageNumber !== pageNumber) {
      this.searchData();
    }

    if (this.state.id !== id) {
      this.setState({ id }, this.searchData());
    }

    if (billDetail && billDetail?.updatedOn !== prevState.updatedOn) {
      const data = billDetail;

      const sRowData =
        selectedRowData && billDetail.billItems?.filter((b) => b.paymentRefId === selectedRowData.paymentRefId);

      this.setState({
        ...this.state,
        updatedOn: data.updatedOn,
        modal: false,
        selectedRowData: action !== "CREATE" && sRowData ? sRowData[0] : null,
        selectedRow: action === "CREATE" ? -1 : selectedRow,
        amount: "",
        message: "",
        refBillIds: "",
        billMessage: "",
        files: [],
        files2: [],
      });
    }
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.frameRef.current) {
      this.frameRef.current.scrollTop = this.frameRef.current.scrollHeight;
    }
  };

  removeFromState = (row) => (e) => {
    let filteredArray = this.state.files.filter((element) => element.name !== row.name);
    this.setState({ files: [...filteredArray] });
    //this.props.removeMediaObject(row.name);
  };

  onDrop = (acceptedFiles) => {
    this.setState({ files: [...this.state.files, ...acceptedFiles] });

    // acceptedFiles.map(acceptedFile => {
    //   return this.props.uploadImages(acceptedFile);
    // });
  };

  removeFromState2 = (row) => (e) => {
    let filteredArray = this.state.files2.filter((element) => element.name !== row.name);
    this.setState({ files2: [...filteredArray] });
    //this.props.removeMediaObject(row.name);
  };

  onDrop2 = (acceptedFiles) => {
    this.setState({ files2: [...this.state.files2, ...acceptedFiles] });

    // acceptedFiles.map(acceptedFile => {
    //   return this.props.uploadImages(acceptedFile);
    // });
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  searchData = () => {
    const { id, pageNumber, pageSize } = this.state;
    this.props.getBillDetail({
      toUserId: id,
      paginationOptions: {
        pageNumber,
        pageSize,
      },
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleHistory = (selectedRow, selectedRowData) => {
    this.setState({ selectedRow, selectedRowData });
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  addUpdateBill = () => {
    const { amount, message, refBillIds, pageSize, files } = this.state;
    const { billDetail } = this.props;
    const billSummary = billDetail?.billSubSummary;
    if (billSummary) {
      const reqParam = {
        toUserId: billSummary.toUserId,
        clearingDue: true,
        amount,
        message: {
          message: message,
          //medias: [{fileId: '2222', fileExt: '.jpg', icon: false}],
        },
        refBillIds: refBillIds,
        toUserType: billSummary.toUserType,
        actionType: "add",
        refreshObj: {
          toUserId: billSummary.toUserId,
          paginationOptions: {
            pageNumber: 1,
            pageSize,
          },
        },
        fileData: files,
      };
      this.props.addUpdateBill(reqParam);
    }
  };

  addUpdateEntry = () => {
    const { selectedRowData, action, id, pageNumber, pageSize } = this.state;
    this.props.addUpdateEntry({
      paymentRefId: selectedRowData.paymentRefId,
      action,
      refreshObj: {
        toUserId: id,
        paginationOptions: {
          pageNumber,
          pageSize,
        },
      },
    });
  };

  messageBillEntry = () => {
    const { selectedRowData, billMessage, files2 } = this.state;
    this.props.messageBillEntry({
      paymentRefId: selectedRowData.paymentRefId,
      message: {
        message: billMessage,
        //medias: [{ fileId: "2222", fileExt: ".jpg", icon: false }]
      },
      fileData: files2,
    });
  };

  renderPagination = () => {
    const { billDetail } = this.props;
    const mainData = billDetail;

    return mainData?.totalNoOfPages > 1 ? (
      <CustomPagination
        pageNumber={this.state.pageNumber}
        totalNoOfPages={mainData.totalNoOfPages}
        changePage={this.changePage}
        hasPrevious={mainData.hasPrevious}
        hasNext={mainData.hasNext}
      />
    ) : null;
  };

  renderModal = () => {
    const { modal, title, action, amount, refBillIds, message, files } = this.state;
    return (
      <Modal backdrop="static" centered fade={false} isOpen={modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
        <ModalBody style={{ minHeight: 100 }}>
          {action === "CREATE" ? (
            <Card className="card-default">
              <Form autoComplete="off">
                <FormGroup row>
                  <Label for="variant" sm={3}>
                    Amount<span className="star">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="number"
                      min="0"
                      step="0.01"
                      name="amount"
                      placeholder="Enter Amount"
                      value={amount || ""}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="variant" sm={3}>
                    Ref Ids<span className="star">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      name="refBillIds"
                      placeholder="Enter reference bill Ids"
                      value={refBillIds || ""}
                      onChange={this.handleChange}
                    />
                    <small style={{ marginTop: -5, display: "block" }}>Enter Reference Ids separated by comma,</small>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="notes" sm={3}>
                    Enter Notes <span className="star">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="textarea"
                      placeholder="Add notes"
                      name="message"
                      onChange={this.handleChange}
                      value={message}
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Label for="notes" sm={3}>
                      Attach Bill
                    </Label>
                    <Col sm={9}>
                      <div className="mesup">
                        <Dropzone onFilesAdded={(opt) => this.onDrop(opt)} />
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                <Row className="mt-2 mb-2">
                  <Col sm={3}></Col>
                  <Col sm={9}>
                    <Row>
                      {files?.length > 0 &&
                        files.map((acceptedFile) => (
                          <Col sm="6" className="" key={acceptedFile.name}>
                            <img
                              alt={acceptedFile.name}
                              src={URL.createObjectURL(acceptedFile)}
                              className="img-thumbnail rounded"
                            />
                            <p className="text-center">{acceptedFile.name}</p>
                            <button
                              onClick={this.removeFromState(acceptedFile)}
                              type="button"
                              className="close img-remove"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                </Row>
                <FormGroup>
                  <Button
                    block
                    disabled={!refBillIds || !amount || !message || this.props.isLoading}
                    color="success"
                    onClick={this.addUpdateBill}
                  >
                    Submit
                  </Button>
                </FormGroup>
              </Form>
            </Card>
          ) : (
            <p>Are you sure you want to {title} ?</p>
          )}
        </ModalBody>
        <ModalFooter>
          {action === "CREATE" ? null : (
            <>
              <Button color="danger" size="sm" onClick={this.addUpdateEntry}>
                Yes {title}
              </Button>
              <Button color="secondary" size="sm" onClick={this.toggle}>
                No
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    const {
      billDetail,
      isLoading,
      authData: { userId, userType },
    } = this.props;

    const { selectedRow, selectedRowData, pageNumber, pageSize, billMessage, files2 } = this.state;

    const billSummary = billDetail?.billSubSummary;
    const responseData = billDetail?.billItems;

    return (
      <div>
        <PanelHeader />
        {this.renderModal()}
        <div className="content mt-3">
          <Row>
            <MessageBlock screenName="billbook" />
            <Col xs={12}>
              <Row>
                <Col sm={8}>
                  <div className="float-left">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mt-0 pt-0">
                        <li className="breadcrumb-item">
                          <span onClick={() => this.props.history.goBack()}>&laquo; Go Back</span>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          Bill Details
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="float-right">
                    <Button
                      className="mt-0"
                      color="primary"
                      size="xs"
                      onClick={() => {
                        this.toggle({
                          title: "Create Entry (Pay)",
                          action: "CREATE",
                        });
                      }}
                    >
                      Create New Entry (Pay)
                    </Button>
                  </div>
                </Col>
                <Col sm={4}></Col>
              </Row>

              <Row>
                <Col sm={8}>
                  {responseData && billSummary ? (
                    <>
                      <Card className="card--default" style={{ minHeight: 600 }}>
                        <CardBody>
                          <Row>
                            <Col
                              sm={12}
                              style={{
                                borderBottom: "2px solid #eee",
                                marginBottom: 10,
                              }}
                            >
                              <h5 className="float-left mb-1">Bill Details</h5>

                              <span className="float-right">{formatDateTime(billSummary.updatedOn)}</span>
                              <div className="clearfix" />
                              {billSummary.toUserName ? (
                                <>
                                  <span>User Name: </span>
                                  {billSummary.toUserName} {"   |   "}
                                </>
                              ) : null}

                              {userType === "SUPPLIER" ? (
                                <>
                                  <span>User Mobile: </span>
                                  {billSummary.toUserMobileNumber?.replace(/(\d{2})\d{6}(\d{2})/, "$1*******$2")}
                                </>
                              ) : (
                                billSummary.toUserMobileNumber
                              )}
                            </Col>

                            {billSummary ? (
                              <Col sm={12}>
                                <CardBody className="p-0 mb-3">
                                  <Row>
                                    <Col md={5}>
                                      <p style={{ fontWeight: "bold" }}>
                                        <span>Total Amount To Pay:</span>
                                        <span className="float-right">
                                          &#x20b9;
                                          {billSummary.totalAmountToPay?.toFixed(2).toLocaleString()}
                                        </span>
                                      </p>
                                    </Col>
                                    <Col md={2}></Col>
                                    <Col md={5}>
                                      <p style={{ fontWeight: "bold" }}>
                                        <span>Total Amount To Get:</span>
                                        <span className="float-right">
                                          &#x20b9;
                                          {billSummary.totalAmountToGet?.toFixed(2).toLocaleString()}
                                        </span>
                                      </p>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={5}>
                                      <span>Verified Amount To Pay:</span>
                                      <span className="float-right">
                                        &#x20b9;
                                        {billSummary.verifiedAmountToPay?.toFixed(2).toLocaleString()}
                                      </span>
                                    </Col>
                                    <Col md={2}></Col>
                                    <Col md={5}>
                                      <span>Verified Amount To Get:</span>
                                      <span className="float-right">
                                        &#x20b9;
                                        {billSummary.verifiedAmountToGet?.toFixed(2).toLocaleString()}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={5}>
                                      <span>UnVerified Amount To Pay:</span>
                                      <span className="float-right">
                                        &#x20b9;
                                        {billSummary.unverifiedAmountToPay?.toFixed(2).toLocaleString()}
                                      </span>
                                    </Col>
                                    <Col md={2}></Col>
                                    <Col md={5}>
                                      <span>UnVerified Amount To Get:</span>
                                      <span className="float-right">
                                        &#x20b9;
                                        {billSummary.unverifiedAmountToGet?.toFixed(2).toLocaleString()}
                                      </span>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Col>
                            ) : null}

                            <Col sm={12}>
                              <Row>
                                <Col md="4">
                                  <h5 className="float--left mb-1">Transaction History</h5>
                                </Col>
                                <Col md="8">
                                  <div
                                    style={{
                                      fontSize: "70%",
                                      marginBottom: 10,
                                      overflow: "hidden",
                                    }}
                                  >
                                    {this.renderPagination()}
                                  </div>
                                </Col>
                              </Row>

                              <Table responsive className="table-striped table-bordered">
                                <thead>
                                  <tr className="text-primary">
                                    <th className="text-center">#</th>
                                    <th>Ref No</th>
                                    <th title="Transaction Type">T. Type</th>
                                    <th title="Transaction Status">T. status</th>
                                    <th>Amount</th>
                                    <th>Created Date</th>
                                    <th>Update Date</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {responseData ? (
                                    responseData.length ? (
                                      responseData.map((row, index) => {
                                        return (
                                          <tr
                                            style={{ cursor: "pointer" }}
                                            key={index}
                                            onClick={() => this.handleHistory(index, row)}
                                            className={this.state.selectedRow === index ? "active" : null}
                                          >
                                            <td className="text-center">
                                              {pageNumber * pageSize + (index + 1) - pageSize}
                                            </td>

                                            <td>{row.paymentRefId}</td>
                                            <td>{row.transactionType}</td>
                                            <td>{row.transactionStatus}</td>
                                            <td>
                                              &#x20b9;
                                              {row.amount?.toFixed(2).toLocaleString()}
                                            </td>
                                            <td>{formatDateTime(row.createdOn)}</td>
                                            <td>{formatDateTime(row.updatedOn)}</td>
                                            <td className="text-right">
                                              <Button
                                                className={`btn-simple btn-light btn-primary`}
                                                size="xs"
                                                onClick={null}
                                              >
                                                View
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan="14" className="no-data">
                                          No data available
                                        </td>
                                      </tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="15" />
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : null}
                </Col>
                <Col sm="4">
                  <h5 className="m-0">Transaction Detail: {selectedRowData?.paymentRefId}</h5>

                  <div id="frame">
                    <div className="content">
                      <div>
                        {selectedRowData?.rejectAllowed ? (
                          <Button
                            color="danger"
                            size="xs"
                            onClick={() => {
                              this.toggle({
                                title: "Reject Entry",
                                action: "REJECT",
                              });
                            }}
                          >
                            Reject Entry
                          </Button>
                        ) : null}

                        {selectedRowData?.deleteAllowed ? (
                          <Button
                            color="danger"
                            size="xs"
                            onClick={() =>
                              this.toggle({
                                title: "Delete Entry",
                                action: "DELETE",
                              })
                            }
                          >
                            Delete Entry
                          </Button>
                        ) : null}

                        {selectedRowData?.approveAllowed ? (
                          <Button
                            color="danger"
                            size="xs"
                            onClick={() =>
                              this.toggle({
                                title: "Resubmit Entry",
                                action: "APPROVE",
                              })
                            }
                          >
                            Resubmit Entry
                          </Button>
                        ) : null}
                        {selectedRowData?.transactionStatus === "REJECTED" ? (
                          <p
                            style={{
                              marginBottom: 0,
                              opacity: 0.5,
                              color: "red",
                            }}
                          >
                            This transaction is disputed
                          </p>
                        ) : null}
                      </div>

                      <div className="messages">
                        {selectedRow < 0 ? <p className="sr-msg">Select row from left to view details </p> : null}

                        {selectedRowData ? (
                          <>
                            <ul
                              style={{
                                paddingTop: 20,
                                maxHeight: 400,
                                overflow: "auto",
                              }}
                              ref={this.frameRef}
                            >
                              {selectedRowData.messageLog?.length > 0 &&
                                selectedRowData.messageLog.map((message, index) => {
                                  return (
                                    <li
                                      className={userId === message.userId ? "replies" : "sent"}
                                      key={"mess-" + index}
                                    >
                                      <p
                                        style={{
                                          position: "relative",
                                          minWidth: 220,
                                        }}
                                      >
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: -20,
                                            left: 0,
                                            color: "black",
                                          }}
                                        >
                                          {message.userType?.toLowerCase()}: {formatDateTime(message.createdOn)}
                                        </span>
                                        <span>{message.message}</span>
                                        <br />
                                        <span>
                                          {message.medias?.map((m, index) => {
                                            const path = getMediaUrl({
                                              fileId: m.fileId,
                                              fileExt: m.fileExt,
                                              // width: 200,
                                              //height: 200
                                            });

                                            return (
                                              <ImageZoom
                                                key={"zoom-" + index}
                                                src={path}
                                                alt=""
                                                style={{
                                                  width: "auto",
                                                  height: 60,
                                                  borderRadius: 5,
                                                }}
                                              />
                                            );
                                          })}
                                        </span>{" "}
                                      </p>
                                    </li>
                                  );
                                })}
                            </ul>
                          </>
                        ) : null}
                      </div>

                      {selectedRowData ? (
                        <div className="price-invent sku-form-box" style={{ minHeight: "auto" }}>
                          <h5 className="card-header">Reply to this Entry</h5>
                          <div className="card-body">
                            <Form>
                              <FormGroup row>
                                <Col sm={12}>
                                  <Input
                                    style={{ background: "#fff" }}
                                    type="textarea"
                                    name="billMessage"
                                    placeholder="Type your message here"
                                    value={billMessage || ""}
                                    onChange={this.handleChange}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup>
                                <Row>
                                  <Label for="notes" sm={3}>
                                    Attach Bill
                                  </Label>
                                  <Col sm={9}>
                                    <div className="mesup">
                                      <Dropzone onFilesAdded={(opt) => this.onDrop2(opt)} />
                                    </div>
                                  </Col>
                                </Row>
                              </FormGroup>
                              <Row className="mt-2 mb-2">
                                <Col sm={3}></Col>
                                <Col sm={9}>
                                  <Row>
                                    {files2?.length > 0 &&
                                      files2.map((acceptedFile) => (
                                        <Col sm="6" className="" key={acceptedFile.name}>
                                          <img
                                            alt={acceptedFile.name}
                                            src={URL.createObjectURL(acceptedFile)}
                                            className="img-thumbnail rounded"
                                          />
                                          <p className="text-center">{acceptedFile.name}</p>
                                          <button
                                            onClick={this.removeFromState2(acceptedFile)}
                                            type="button"
                                            className="close img-remove"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </Col>
                                      ))}
                                  </Row>
                                </Col>
                              </Row>

                              <FormGroup row>
                                <Col sm={12}>
                                  <Button
                                    style={{ margin: 0, borderRadius: 0 }}
                                    block
                                    color="primary"
                                    size="md"
                                    onClick={this.messageBillEntry}
                                    disabled={!billMessage}
                                  >
                                    Add Message
                                  </Button>
                                </Col>
                              </FormGroup>
                            </Form>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    billDetail: state.billBookItems.billDetail,
    authData: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBillDetail: (reqParam) => dispatch(getBillDetail(reqParam)),
    addUpdateBill: (reqParam) => dispatch(addUpdateBill(reqParam)),
    addUpdateEntry: (reqParam) => dispatch(addUpdateEntry(reqParam)),
    messageBillEntry: (reqParam) => dispatch(messageBillEntry(reqParam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillBookDetail);
