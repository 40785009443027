import React from "react";
import { Row, Col, FormGroup, Card, CardBody, Button, Table, ButtonToolbar } from "reactstrap";

import { connect } from "react-redux";
import { searchRequest } from "../../datautils/actions/adminActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { formatDateTime } from "../../utils/helpers/HelperFunctions";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { sortIcon } from "../../utils/constants/icons";
import { resetResponseValues, resetObjectValues } from "../../datautils/actions/responseHandlerActions";
import { PER_PAGE } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";

import { ADMIN_REQUEST_VIEW } from "../../utils/constants/clientUrls";

class Requests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "0",
      currentFilter: "requestedTicketStatus",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "requestId",
      ascending: false,
    };
  }
  componentDidMount() {
    this.searchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchData();
    }
  }

  handleChangePage = (id) => {
    this.props.history.push(ADMIN_REQUEST_VIEW + id);
  };

  searchButton = () => {
    this.setState({ pageNumber: 1 }, () => this.searchData());
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState({ pageNumber: 1 }, () => this.searchData());
    }
  };

  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.searchData()
    );
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  searchData = () => {
    const { globalSearchKeyword, currentFilter, currentTab, sortField, ascending, pageNumber, pageSize } = this.state;

    const reqData = {
      globalSearchKeyword,
      fieldNameKeyword: [
        {
          key: currentFilter,
          operation: "EQUAL_TO",
          value: currentTab,
        },
      ],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.props.searchRequest(reqData);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changePage = (page) => (e) => {
    this.setState({ pageNumber: page });
  };

  filterData = (e) => {
    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
        currentFilter: e.target.dataset.filter,
      },
      () => this.searchData()
    );
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.requestList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  render() {
    const { responseVal } = this.props;
    const { globalSearchKeyword } = this.state;
    const dataLoaded = responseVal.dObjectData?.requestList?.data?.requestQueues;

    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="12">
                  {!this.state.toggle ? (
                    <ButtonToolbar>
                      {" "}
                      <Button
                        size="sm"
                        value="1"
                        data-filter="requestedTicketStatus"
                        onClick={this.filterData}
                        color={
                          this.state.currentTab === "1" && this.state.currentFilter === "requestedTicketStatus"
                            ? "primary"
                            : ""
                        }
                      >
                        ONHOLD
                      </Button>
                      <Button
                        size="sm"
                        value="2"
                        data-filter="requestedTicketStatus"
                        onClick={this.filterData}
                        color={
                          this.state.currentTab === "2" && this.state.currentFilter === "requestedTicketStatus"
                            ? "primary"
                            : ""
                        }
                      >
                        CLOSED
                      </Button>
                      <Button
                        size="sm"
                        value="0"
                        data-filter="requestedTicketStatus"
                        onClick={this.filterData}
                        color={
                          this.state.currentTab === "0" && this.state.currentFilter === "requestedTicketStatus"
                            ? "primary"
                            : ""
                        }
                      >
                        OPEN
                      </Button>
                      <Button
                        size="sm"
                        value=""
                        data-filter="requestedTicketStatus"
                        onClick={this.filterData}
                        color={
                          this.state.currentTab === "" && this.state.currentFilter === "requestedTicketStatus"
                            ? "primary"
                            : ""
                        }
                      >
                        ALL
                      </Button>
                    </ButtonToolbar>
                  ) : null}
                </Col>
              </Row>
            </Col>

            <Col xs={12}>
              <React.Fragment>
                <Card>
                  <CardBody className="listings">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search tickets..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button color="primary" onClick={this.searchButton}>
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="8">{dataLoaded ? this.renderPagination() : ""}</Col>
                    </Row>
                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>
                          <th onClick={this.sortButton("id", this.state.ascending ? false : true)}>
                            Ticket ID <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>
                          <th>Subject </th>
                          <th onClick={this.sortButton("requestType", this.state.ascending ? false : true)}>
                            Ticket Type <img alt="sort" className="sort-icon" src={sortIcon} />
                          </th>

                          <th>Approval Status</th>
                          <th>Ticket Status</th>

                          <th>Created On</th>
                          <th>Updated On</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLoaded ? (
                          dataLoaded.length ? (
                            dataLoaded.map((row, index) => {
                              const cname =
                                row.requestTicketStatus === "OPEN"
                                  ? "success"
                                  : row.requestTicketStatus === "CLOSED"
                                  ? "danger"
                                  : row.requestTicketStatus === "ONHOLD"
                                  ? "warning"
                                  : "primary";
                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    {this.state.pageNumber * this.state.pageSize + (index + 1) - this.state.pageSize}
                                  </td>
                                  <td onClick={() => this.handleChangePage(row.requestId)} className="cursor">
                                    {row.requestId}
                                  </td>
                                  <td onClick={() => this.handleChangePage(row.requestId)} className="cursor">
                                    {row.subject}
                                  </td>
                                  <td>{row.requestType}</td>
                                  <td>{row.approvalStatus}</td>
                                  <td>{row.requestTicketStatus}</td>
                                  <td>{formatDateTime(row.createdOn)}</td>
                                  <td>{formatDateTime(row.updatedOn)}</td>
                                  <td className="text-right">
                                    <Button
                                      className={`btn-simple btn-light btn-${cname}`}
                                      size="xs"
                                      onClick={() => this.handleChangePage(row.requestId)}
                                    >
                                      View
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="10" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="10" />
                          </tr>
                        )}
                      </tbody>
                    </Table>{" "}
                    {this.props.isLoading ? <div className="loader" /> : null}
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    resetObjectValues: (object) => dispatch(resetObjectValues(object)),
    searchRequest: (reqData) => dispatch(searchRequest(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
