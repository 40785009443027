import React from "react";
import { Button } from "reactstrap";
import TooltipItem from "../../components/Tooltip/Tooltip";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import ImageZoom from "../../components/ImageZoom";

class AddressBlock extends React.Component {
  render() {
    const { type, content, userType } = this.props;
    const data = content;
    let filePath = "";

    if (data) {
      if (data.gstFileId) {
        filePath = getMediaPath(data.gstFileId, data.gstFileExtension);
      }
      return (
        <React.Fragment>
          {this.props.header && data.status ? (
            <>
              <h5 className="text-capitalize">
                <span className={`status ${data.workflowStatus}`} id={"Tooltip-x" + data.id}>
                  <i className="now-ui-icons ui-1_check" />
                </span>

                {type.replace(/_/g, " ").toLowerCase()}
              </h5>
              <TooltipItem placement="right" text={data.workflowStatus} id={"x" + data.id} />
            </>
          ) : null}

          {data.workflowStatus === "SUBMITTED" ||
          data.workflowStatus === "REJECTED" ||
          data.workflowStatus === "ON_HOLD" ? (
            <div className="admin-action p-2 mt-2" style={{ background: "#f5f5f5" }}>
              <div className="mt-0 mb-0 d-flex justify-content-between">
                <p className="mb-0 text-muted">Current Status</p>
                <p className="mb-0 mt-0">
                  <span className={`text-${data.workflowStatus}`}>{data.workflowStatus?.replace(/_/g, "")}</span>
                </p>
              </div>

              <div className="mt-1 mb-0 d-flex justify-content-between">
                <p className="mb-0 text-muted">Status Notes</p>
                <p className="mb-0 mt-0">
                  <span>{data.workflowStatusMessage}</span>
                </p>
              </div>
              {data.adminWorkflowMessage ? (
                <div className="mt-2 mb-0">
                  <p className="mb-0 text-muted">Admin Notes</p>
                  <p className="mt-0 mb-0">{data.adminWorkflowMessage}</p>
                </div>
              ) : null}
            </div>
          ) : null}

          {this.props.updateAddress && userType && (userType === "ADMIN" || userType === "OPS") ? (
            <React.Fragment>
              <Button
                className="d-none"
                size="sm"
                color="primary"
                onClick={() =>
                  this.props.updateAddress({
                    gstNumber: data.gstNumber,
                    gstFileId: data.gstFileId,
                    gstAdditionalInfo: data.gstAdditionalInfo,
                    actionType: "ADMIN_UPDATE",
                    documentId: data.id,
                    addressType: type,
                  })
                }
              >
                Update
              </Button>
              <Button
                size="xs"
                className="btn-simple btn-light btn-success"
                onClick={() =>
                  this.props.updateAddress({
                    gstNumber: data.gstNumber,
                    gstFileId: data.gstFileId,
                    gstAdditionalInfo: data.gstAdditionalInfo,
                    actionType: "ACCEPT",
                    documentId: data.id,
                    addressType: type,
                  })
                }
              >
                Accept
              </Button>

              <Button
                size="xs"
                className="btn-simple btn-light btn-warning"
                onClick={() =>
                  this.props.updateAddress({
                    gstNumber: data.gstNumber,
                    gstFileId: data.gstFileId,
                    gstAdditionalInfo: data.gstAdditionalInfo,
                    actionType: "ONHOLD",
                    documentId: data.id,
                    addressType: type,
                  })
                }
              >
                OnHold
              </Button>

              <Button
                size="xs"
                className="btn-simple btn-light btn-danger"
                onClick={() =>
                  this.props.updateAddress({
                    gstNumber: data.gstNumber,
                    gstFileId: data.gstFileId,
                    gstAdditionalInfo: data.gstAdditionalInfo,
                    actionType: "REJECT",
                    documentId: data.id,
                    addressType: type,
                  })
                }
              >
                Reject
              </Button>
            </React.Fragment>
          ) : null}

          {data.gstNumber ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                padding: "5px",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <div className="mt-0 mb-0 d-flex">
                <div>
                  <ImageZoom src={filePath} alt="" style={{ width: 50 }} />
                </div>
                <div>
                  <p className="mb-0 ml-2">
                    <span className="text-muted d-inline-block" style={{ minWidth: 85 }}>
                      GST No
                    </span>
                    <strong>{data.gstNumber}</strong>
                  </p>

                  {data.gstAdditionalInfo?.length ? (
                    <p className="mb-1 ml-2">
                      <span className="text-muted d-inline-block" style={{ minWidth: 85 }}>
                        Notes
                      </span>
                      {data.gstAdditionalInfo}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className="border-btm"></div>

          <div className="mt-2 mb-0">
            <p className="mb-0">
              <span className="text-muted d-inline-block" style={{ minWidth: 110 }}>
                Current Status
              </span>
              <span className={`text-${data.status}`}>{`${data.status}` || "N/A"}</span>
            </p>
          </div>

          <div className="mt-1 mb-0">
            <p className="mb-0">
              <span className="text-muted d-inline-block" style={{ minWidth: 110 }}>
                Line1 Address
              </span>
              <span>{data.line1Address || "N/A"}</span>
            </p>
          </div>

          <div className="mt-1 mb-0">
            <p className="mb-0">
              <span className="text-muted d-inline-block" style={{ minWidth: 110 }}>
                Line2 Address
              </span>
              {data.line2Address || "N/A"}
            </p>
          </div>

          <div className="mt-1 mb-0">
            <p className="mb-0">
              <span className="text-muted d-inline-block" style={{ minWidth: 110 }}>
                Locality Name
              </span>
              {data.localityName || "N/A"}
            </p>
          </div>
          <div className="mt-1 mb-0">
            <p className="mb-0">
              <span className="text-muted d-inline-block" style={{ minWidth: 110 }}>
                City
              </span>
              {data.cityName || "N/A"}
            </p>
          </div>

          <div className="mt-1 mb-0">
            <p className="mb-0">
              <span className="text-muted d-inline-block" style={{ minWidth: 110 }}>
                Pincode
              </span>
              {data.pincode || "N/A"}
            </p>
          </div>

          <div className="mt-1 mb-0">
            <p className="mb-0">
              <span className="text-muted d-inline-block" style={{ minWidth: 110 }}>
                State
              </span>
              {data.stateName || "N/A"}
            </p>
          </div>

          {data?.notes?.length > 0 ? (
            <div className="mt-3 mb-0">
              <p className="mb-0 text-muted">Admin Notes</p>
              <p className="mt-0">{data.notes}</p>
            </div>
          ) : null}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h4 className="align-middle text-center">Not Available</h4>
        </React.Fragment>
      );
    }
  }
}

export default AddressBlock;
