import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./config/store/index";
import HomePage from "./views/Common/HomePage";
import HomePage2 from "./views/Common/HomePage2";
import NotFoundPage from "./views/Common/NotFoundPage";

import "./assets/scss/style.scss";

import indexRoutes from "./config/routes/index";

const history = createBrowserHistory();

function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <Switch>
          {indexRoutes.map((prop, key) => (
            <Route path={prop.path} key={`index-${key}`} component={prop.component} exact={prop.exact} />
          ))}
          <Route path="/" exact key="HomePage" component={HomePage} />
          <Route path="/indexx" exact key="HomePage2" component={HomePage2} />
          <Route path="**" key="NotFoundPage" component={NotFoundPage} />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
