import React from "react";
import {
  Row,
  Col,
  Table,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateCustomer, fetchCustomer, searchOrder } from "../../datautils/actions/orderActions";
import { updateCustomerStatus } from "../../datautils/actions/adminActions";
import { resetResponseValues } from "../../datautils/actions/responseHandlerActions";
import { fetchUserActivity, saveUserActivity } from "../../datautils/actions/statsActions";
import moment from "moment";

import PanelHeader from "../../components/PanelHeader/PanelHeader";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import { PER_PAGE } from "../../utils/constants/index";
import {
  ADMIN_ORDER_VIEW,
  ADMIN_PRODUCT_VIEW,
  ADMIN_SUPPLIERR_VIEW,
  ADMIN_SKU_VIEW,
} from "../../utils/constants/clientUrls";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import MessageBlock from "../../components/MessageBlock/MessageBlock";

import Activities from "../../components/Activities";

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal2: false,
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "orderedOn",
      ascending: false,
      id: 0,
      notes: "",
      currentAction: "",
      currentActionType: "",
      currentTitle: "",
      userId: null,
    };
  }

  componentDidMount() {
    this.props.resetResponseValues();
    document.body.classList.add("has-activities");
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    const { responseVal } = this.props;
    const { isUpdated, modal2, userId } = this.state;

    if (this.state.id !== id) {
      this.setState({ id, isUpdated: false }, () => {
        this.searchData();
        this.fetchActivity(1);
      });
      this.props.fetchCustomer({ customer: { customerId: id } });
    }

    const profileInfo = responseVal.dObjectData?.customerDetail?.data?.profileInfo;

    if (!userId && profileInfo) {
      this.setState({ userId: profileInfo.userId });
    }

    if (responseVal.status === "success" && isUpdated && modal2) {
      this.setState({ modal2: false });
    }

    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchData();
    }
  }

  componentWillUnmount() {
    this.props.saveUserActivity(null);
  }

  toggle2 = (requestData) => {
    if (this.state.modal2) {
      this.setState({
        modal2: false,
        notes: "",
        currentTitle: "",
        currentAction: "",
        currentActionType: "",
      });
    } else {
      this.setState({
        modal2: true,
        notes: "",
        currentTitle: requestData.title,
        currentAction: requestData.action,
        currentActionType: requestData.actionType,
        isUpdated: false,
      });
      this.props.resetResponseValues();
    }
  };

  handleChangeNotes = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchData()
    );
  };

  searchData = () => {
    const { id, pageNumber, pageSize, sortField, ascending } = this.state;
    const reqData = {
      globalSearchKeyword: "",
      fieldNameKeyword: [
        {
          key: "customerId",
          operation: "EQUAL_TO",
          value: id,
        },
      ],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.setState({ isUpdated: false });
    this.props.searchOrder(reqData);
  };

  fetchActivity = (p) => {
    const reqData = {
      userId: this.state.userId,
      paginationOptions: {
        pageNumber: p,
        pageSize: PER_PAGE,
        sortOptions: [{ sortField: "createdOn", ascending: false }],
      },
    };
    this.props.fetchUserActivity({ ...reqData });
  };

  enableDisable = (e) => {
    const { notes, modal2, id } = this.state;
    if (modal2) {
      const reqData = {
        type: e.target.dataset.type,
        status: e.target.value,
        customerId: id,
        notes,
      };
      this.props.updateCustomerStatus(reqData);
      this.setState({ isUpdated: true });
    } else {
      this.toggle2({
        actionType: e.target.dataset.type,
        action: e.target.value,
        title: e.target.value + " Customer",
      });
    }
  };

  renderModal2 = () => {
    const { notes, currentAction, currentActionType, currentTitle } = this.state;

    return (
      <Modal backdrop="static" centered isOpen={this.state.modal2} toggle={this.toggle2}>
        <ModalHeader toggle={this.toggle2}>{currentTitle.replace(/_/g, " ")}</ModalHeader>
        <ModalBody>
          <Card className="card-default">
            <CardBody>
              <div className="row">
                <div className="col">
                  <React.Fragment>
                    <Form autoComplete="off">
                      <FormGroup>
                        <Input
                          type="textarea"
                          placeholder="Add notes/reason"
                          onChange={this.handleChangeNotes("notes")}
                          value={notes}
                        />
                      </FormGroup>
                    </Form>
                    {this.props.userType === "SUPPLIER" ? null : (
                      <React.Fragment>{this.renderSwitch(currentActionType, currentAction)}</React.Fragment>
                    )}
                  </React.Fragment>
                </div>
              </div>
            </CardBody>
          </Card>{" "}
        </ModalBody>
      </Modal>
    );
  };

  changePage = (page) => (e) => {
    // const fragment = "?tab=" + this.state.currentTab + "&page=" + page;
    // this.props.userType === "SUPPLIER"
    //   ? this.props.history.push(SUPPLIER_ORDERS_LIST + fragment)
    //   : this.props.history.push(ADMIN_ORDERS_LIST + fragment);
    this.setState({ pageNumber: page });
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.orderList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  renderSwitch(currentActionType, currentAction) {
    return (
      <Button
        className="btn-block btn-primary"
        data-type={currentActionType}
        value={currentAction}
        onClick={this.enableDisable}
        disabled={this.state.notes?.replace(/\s+/g, "")?.length < 1}
      >
        {currentAction} Customer
      </Button>
    );
  }

  render() {
    const { responseVal, isLoading, userActivity } = this.props;
    const { isUpdated, modal2, pageNumber, pageSize } = this.state;

    const dataLoaded = responseVal.dObjectData?.customerDetail?.data?.profileInfo;
    const otherOrders = responseVal.dObjectData?.orderList?.data?.orders;
    const userActivities = userActivity;

    return (
      <div>
        <PanelHeader />
        {modal2 ? this.renderModal2() : null}
        <div className="content mt-3">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}

              {responseVal.status === "success" && isUpdated ? <SuccessBlock msg={responseVal.data} /> : null}
            </Col>

            <Col xs={12}>
              <MessageBlock screenName="customer" />
            </Col>

            <Col xs={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-0 pt-0">
                  <li className="breadcrumb-item">
                    <span onClick={() => this.props.history.goBack()}>&laquo; Go Back</span>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Customer Details
                  </li>
                </ol>
              </nav>
              <Row>
                <Col sm={12}>
                  {dataLoaded ? (
                    <>
                      <Card className="card--default">
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <h5 className="float-left">
                                {" "}
                                {dataLoaded.title} {dataLoaded.firstName} {dataLoaded.lastName}
                              </h5>
                              <span className="float-right"></span>
                              <div className="clearfix" />
                              <p style={{ borderBottom: "2px solid #eee" }}>
                                <span className="item-ord">
                                  Registered on {moment(dataLoaded.createdOn).format("DD MMM' YY, hh:mm a")}{" "}
                                </span>
                                <span className="pipe" />
                                <span className="item-ord">
                                  Updated on {moment(dataLoaded.updatedOn).format("DD MMM' YY, hh:mm a")}{" "}
                                </span>
                                <span className="pipe" />
                                <span className="item-ord">Customer# {dataLoaded.customerId}</span>
                                <span className="pipe" />
                                <span className="item-ord">User# {dataLoaded.userId}</span>
                              </p>
                            </Col>

                            <Col sm={6}>
                              <dl className="row">
                                <dt className="col-sm-3">Name</dt>
                                <dd className="col-sm-9">
                                  {dataLoaded.title} {dataLoaded.firstName} {dataLoaded.lastName}
                                </dd>
                                <dt className="col-sm-3">Gender</dt>
                                <dd className="col-sm-9">
                                  {dataLoaded.gender === "M"
                                    ? "Male"
                                    : dataLoaded.gender === "F"
                                    ? "Female"
                                    : dataLoaded.gender}
                                </dd>
                                <dt className="col-sm-3">Mobile</dt>
                                <dd className="col-sm-9">{dataLoaded.mobileNumber}</dd>
                                <dt className="col-sm-3">Email</dt>
                                <dd className="col-sm-9">{dataLoaded.email || "N/A"}</dd>
                              </dl>
                            </Col>
                            <Col
                              sm={3}
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="mb-4">
                                <span>Status: </span>
                                <span>
                                  <span className={"text-" + (dataLoaded.status === "ACTIVE" ? "success" : "danger")}>
                                    {dataLoaded.status}
                                  </span>
                                </span>
                              </div>

                              {dataLoaded.status === "ACTIVE" ? (
                                <Button
                                  size="xs"
                                  value="DEACTIVATE"
                                  data-type="a"
                                  className="btn-simple btn-success mt-0 float-right"
                                  onClick={this.enableDisable}
                                >
                                  Deactivate Customer
                                </Button>
                              ) : (
                                <Button
                                  size="xs"
                                  value="ACTIVATE"
                                  data-type="a"
                                  className="btn-simple btn-danger mt-0 float-right"
                                  onClick={this.enableDisable}
                                >
                                  Activate Customer
                                </Button>
                              )}
                            </Col>
                            <Col
                              sm={3}
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="mb-4">
                                <span>Verified: </span>
                                <span>
                                  {dataLoaded.verified ? (
                                    <span className="text-success">YES</span>
                                  ) : (
                                    <span className="text-danger">NO</span>
                                  )}
                                </span>
                              </div>
                              {dataLoaded.verified ? (
                                <Button
                                  size="xs"
                                  value="UNVERIFY"
                                  data-type="v"
                                  className="btn-simple btn-success mt-0 float-right"
                                  onClick={this.enableDisable}
                                >
                                  Unverify Customer
                                </Button>
                              ) : (
                                <Button
                                  size="xs"
                                  value="VERIFY"
                                  data-type="v"
                                  className="btn-simple btn-danger mt-0 float-right"
                                  onClick={this.enableDisable}
                                >
                                  Verify Customer
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : null}
                </Col>
                <Col sm={12}>
                  <React.Fragment>
                    <div className="price-invent mb-3" style={{ padding: 0, minHeight: "auto" }}>
                      <div className="card-header d-flex justify-content-between">
                        <span>User's Order</span>{" "}
                        {/* <Button size="xs" className="btn-simple btn-danger m-0 float-right" onClick={this.searchButton}>
                          View User Orders
                        </Button> */}
                      </div>
                      <div className="card-body p-0">
                        {otherOrders ? (
                          <div className="p-1">
                            <Row>
                              <Col className="mb-1">{this.renderPagination()}</Col>
                            </Row>
                            <Table responsive className="table-striped table-bordered w-100">
                              <thead>
                                <tr className="text-primary">
                                  <th className="text-center">#</th>

                                  <th>Line Item Id </th>
                                  <th>Order Id</th>
                                  <th>Order Ref No </th>
                                  <th>Supplier Id </th>
                                  <th>Product Id </th>
                                  <th>Variant</th>

                                  <th>Sku Id</th>
                                  <th>Qty</th>
                                  <th>Total Price</th>

                                  <th>Order Type</th>
                                  <th>Status</th>
                                  <th>Order Date</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {otherOrders.map((row, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text-center">{pageNumber * pageSize + (index + 1) - pageSize}</td>
                                      <td style={{ cursor: "pointer" }}>{row.orderLineItemId}</td>
                                      <td>{row.customerOrderId}</td>
                                      <td>{row.orderRefNumber}</td>

                                      <td>
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={ADMIN_SUPPLIERR_VIEW + row.supplierId}
                                        >
                                          {row.supplierId}
                                        </a>
                                      </td>
                                      <td>
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={
                                            ADMIN_PRODUCT_VIEW +
                                            row.productId +
                                            "/variant/" +
                                            row.productVariantPrimaryId
                                          }
                                        >
                                          {row.productId}
                                        </a>
                                      </td>
                                      <td>{row.variantType + ":" + row.variantName}</td>

                                      <td>
                                        <a target="_blank" rel="noopener noreferrer" href={ADMIN_SKU_VIEW + row.skuId}>
                                          {row.skuId}
                                        </a>
                                      </td>
                                      <td>{row.quantity}</td>
                                      <td>
                                        &#x20b9;
                                        {row.totalFinalOfferPrice &&
                                          Math.round(row.totalFinalOfferPrice).toLocaleString()}
                                      </td>

                                      <td>
                                        <span className="text-success" style={{ fontWeight: "bold" }}>
                                          {row.orderType && row.orderType.replace(/_/g, " ")}
                                        </span>
                                      </td>
                                      <td>{row.status && row.status.replace(/_/g, " ")}</td>
                                      <td>{moment(row.orderedOn).format("MMM DD YYYY, hh:mm a")}</td>

                                      <td className="text-center">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={ADMIN_ORDER_VIEW + row.orderLineItemId}
                                        >
                                          View
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </React.Fragment>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="price-invent mb-3" style={{ padding: 0, minHeight: "auto" }}>
                    <div className="card-header d-flex justify-content-between">
                      <span>User's Activity</span>{" "}
                      {/* <Button
                          size="xs"
                          className="btn-simple btn-danger m-0 float-right"
                          onClick={() => this.fetchActivity(1)}
                        >
                          Fetch User Activity
                        </Button> */}
                    </div>
                    <div className="card-body p-0">
                      {userActivities ? (
                        <Activities userActivities={userActivities} fetchActivity={(p) => this.fetchActivity(p)} />
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            {isLoading ? <div className="loader" /> : null}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
    userActivity: state.statsItems.userActivity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    updateCustomer: (reqData) => dispatch(updateCustomer(reqData)),
    updateCustomerStatus: (reqData) => dispatch(updateCustomerStatus(reqData)),
    fetchCustomer: (reqData) => dispatch(fetchCustomer(reqData)),
    searchOrder: (reqData) => dispatch(searchOrder(reqData)),
    fetchUserActivity: (reqData) => dispatch(fetchUserActivity(reqData)),
    saveUserActivity: (reqData) => dispatch(saveUserActivity(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
