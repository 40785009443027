import { startUILoading, stopUILoading } from "./uiActions";
import { SAVE_HOME_STATS, SAVE_COUNT_STATS, SAVE_USER_ACTIVITY } from "./actionTypes";
import axios from "axios";
import { getRequestHeaders } from "./authActions";
import {
  FETCH_HOME_STATS,
  FETCH_COUNT_STATS,
  FETCH_COUNT_STATS_SUPPLIER,
  USER_ACTIVITY,
} from "../../utils/constants/apiUrls";
import { saveResponseMessage, clearMessage } from "./responseMessageActions";

const messageKey = "stats";

export const fetchHomeStats = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(messageKey));
    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };
      const postData = { ...reqParam, ...defaultParam };
      const response = await axios.post(FETCH_HOME_STATS, postData, config);
      console.log("Stats Fetch Success Executed", response.data);
      dispatch(saveHomeStats(reqParam.statsCategory, response.data.data));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage(messageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const fetchCountStats = (reqParam) => {
  return async (dispatch, getState) => {
    dispatch(startUILoading());
    dispatch(clearMessage(messageKey));
    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {
        userId: getState().auth.userId,
        userType: getState().auth.userType,
      };
      const postData = { ...reqParam, ...defaultParam };
      const URL = defaultParam.userType === "SUPPLIER" ? FETCH_COUNT_STATS_SUPPLIER : FETCH_COUNT_STATS;
      const response = await axios.post(URL, postData, config);
      console.log("Stats Count Fetch Success Executed", response.data);
      dispatch(saveCountStats(response.data.data));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage(messageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

export const fetchUserActivity = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(clearMessage(messageKey));
    try {
      const config = await dispatch(getRequestHeaders());

      const current = reqParam.paginationOptions && reqParam.paginationOptions.pageNumber;

      const from = current ? current - 1 : 0;

      const postData = { ...reqParam };
      const URL = USER_ACTIVITY + reqParam.userId + "/stats?from=" + from;
      const response = await axios.post(URL, postData, config);

      dispatch(saveUserActivity(response.data));
      console.log("UAAA", response.data);
    } catch (error) {
      if (error?.response?.data) {
        dispatch(saveResponseMessage(messageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

const saveHomeStats = (statsCategory, data) => {
  return {
    type: SAVE_HOME_STATS,
    data,
    statsCategory,
  };
};

const saveCountStats = (data) => {
  return {
    type: SAVE_COUNT_STATS,
    data,
  };
};

export const saveUserActivity = (data) => {
  return {
    type: SAVE_USER_ACTIVITY,
    data,
  };
};
