import { startUILoading, stopUILoading } from "./uiActions";
import { setResponseValues, resetResponseValues, clearMediaList, clearMediaObject } from "./responseHandlerActions";

import axios from "axios";

import {
  ADD_CATEGORY,
  SEARCH_CATEGORY,
  ADD_UPDATE_GST,
  ADD_UPDATE_COMMISSION,
  ACTIVATE_DEACTIVATE_CATEGORY,
  ADMIN_EXISTING_ATTRIBUTES,
  SEARCH_ATTRIBUTES,
  ADMIN_ADD_UPDATE_MASTER_ATTRIBUTES,
  ADMIN_ATTACH_ATTRIBUTE_TO_CATEGORY,
  ADMIN_ADD_UPDATE_CATEGORY_MEDIA,
  GET_CATEGORIES_LEVEL,
  MEGAMENU_TREE,
  MEGAMENU_SEARCH,
  MEGAMENU_ADD_UPDATE,
  MEGAMENU_UPDATE_MAPPING,
  MEGAMENU_FETCH_MAPPING,
  MEGAMENU_ACTIONS,
  MEGAMENU_MEDIA_ADD_UPDATE,
  ADD_UPDATE_CONFIG,
} from "../../utils/constants/apiUrls";

import { SUCCESS } from "../../utils/constants";
import { getRequestHeaders } from "./authActions";
import { storeCategories } from "./skuActions";
import { saveResponseMessage, clearMessage } from "./responseMessageActions";
const categoryResponseMessageKey = "category";

////////// Category APIS //////////

/** Search for existing category in modal */
export const searchCategory = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };

      const URL = SEARCH_CATEGORY + reqParam.category;

      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "searchCategory"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add new category */
export const addCategory = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };

      const URL = ADD_CATEGORY + encodeURIComponent(reqParam.catTree);
      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null));
      dispatch(storeCategories(response.data));
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(saveResponseMessage(categoryResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add new category from csv */
export const addBulkCategory = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    try {
      const config = await dispatch(getRequestHeaders());

      reqParam.data &&
        Object.keys(reqParam.data).map(async (o) => {
          const a = reqParam.data[o].filter((n) => n && isNaN(n));
          if (a) {
            const tree = a.join("||").trim();
            const URL = ADD_CATEGORY + encodeURIComponent(tree);
            await axios.post(URL, {}, config);
          }
        });
    } catch (error) {
      /** Save error to common reducer to display to user */
      console.log("Error in bulk creation");
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add or update GST Info  */
export const addGST = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };

      const response = await axios.post(ADD_UPDATE_GST, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null));
      dispatch(storeCategories(response.data));
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(saveResponseMessage(categoryResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add or update category config  */
export const addConfig = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };

      const response = await axios.post(ADD_UPDATE_CONFIG, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null));
      dispatch(storeCategories(response.data));
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(saveResponseMessage(categoryResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add category commission */
export const addCommission = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };

      const response = await axios.post(ADD_UPDATE_COMMISSION, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null));
      dispatch(storeCategories(response.data));

      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(saveResponseMessage(categoryResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Activate or deactivate category*/
export const activateDeactivate = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };

      const URL = ACTIVATE_DEACTIVATE_CATEGORY + reqParam.action + "/" + encodeURIComponent(reqParam.iRefId);

      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null));
      dispatch(storeCategories(response.data));
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(saveResponseMessage(categoryResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add or update category images */
export const addUpdateCategoryMedia = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };
      const URL = ADMIN_ADD_UPDATE_CATEGORY_MEDIA;

      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null));
      dispatch(storeCategories(response.data));
      dispatch(clearMediaList());
      dispatch(clearMediaObject());
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(saveResponseMessage(categoryResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

///////// Attributes Actions //////////

/** Searh category attribute */
export const searchAttribute = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };
      const current = reqParam.paginationOptions?.pageNumber;

      const from = current ? current - 1 : 0;

      const URL = SEARCH_ATTRIBUTES + reqParam.attribute + "&page=" + from;

      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "searchAttribute"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Get list of existing category attributes */
export const getExistingAttribute = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };
      const URL = ADMIN_EXISTING_ATTRIBUTES + reqParam.iRefId + "/attributes";

      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "existingAttributes"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Ad or update master attribute */
export const addMasterAttribute = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const defaultParam = {};
      const postData = { ...reqParam, ...defaultParam };
      const URL = ADMIN_ADD_UPDATE_MASTER_ATTRIBUTES;
      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null));
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(
        saveResponseMessage(categoryResponseMessageKey, {
          hasErrors: false,
          userDisplayMsg: "Master attribute updated successfully",
        })
      );
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Attach attributes to category */
export const attachCategoryAttribute = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));

    try {
      const config = await dispatch(getRequestHeaders());

      const postData = { attributes: reqParam.attributes };
      const URL = ADMIN_ATTACH_ATTRIBUTE_TO_CATEGORY + reqParam.iRefId + "/attribute";
      const response = await axios.post(URL, postData, config);

      dispatch(setResponseValues(SUCCESS, response.data, null, "existingAttributes"));
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(
        saveResponseMessage(categoryResponseMessageKey, {
          hasErrors: false,
          userDisplayMsg: "Attribute attachment updated successfully",
        })
      );
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

//////////// Mega Menu APIS ///////////

/** Get category tree by level */
export const getCategoryLevel = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const URL = GET_CATEGORIES_LEVEL + (reqParam.iRefId ? reqParam.iRefId : "");
      const response = await axios.post(URL, {}, config);

      const reduxObject = "categoryLevel_" + (reqParam.level ? reqParam.level : "0");
      dispatch(setResponseValues(SUCCESS, response.data, null, reduxObject));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Get mega menu tree */
export const getMegaMenuTree = (reqParam) => {
  console.log("re", reqParam);
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const URL = MEGAMENU_TREE + "?pty=" + (reqParam.platform ? reqParam.platform.toUpperCase() : "IOS");
      const response = await axios.post(URL, {}, config);
      console.log("Mega Menu Tree Fetch Success Executed", response.data);

      dispatch(setResponseValues(SUCCESS, response.data, null, "megaMenu"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Search mega menu */
export const searchMenu = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const defaultParam = {};

      const postData = { ...reqParam, ...defaultParam };
      const URL =
        MEGAMENU_SEARCH + reqParam.menu + "&pty=" + (reqParam.platform ? reqParam.platform.toUpperCase() : "IOS");

      const response = await axios.post(URL, postData, config);
      console.log("Menu search Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "searchMenu"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add new mega menu */
export const addMenu = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const postData = { platformType: reqParam.platform };
      const URL =
        MEGAMENU_ADD_UPDATE +
        encodeURIComponent(reqParam.menuTree) +
        "&pty=" +
        (reqParam.platform ? reqParam.platform.toUpperCase() : "IOS");
      const response = await axios.post(URL, postData, config);
      console.log("Menu ADD Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "megaMenu"));
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(saveResponseMessage(categoryResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Map category to respective mega menu */
export const mapCategoryMenu = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const postData = { ...reqParam };
      const URL = MEGAMENU_UPDATE_MAPPING + "?pty=" + (reqParam.platform ? reqParam.platform.toUpperCase() : "IOS");
      const response = await axios.post(URL, postData, config);
      console.log("Menu Mapping Success Executed", response.data);
      if (reqParam.action === "activation") {
        dispatch(setResponseValues(SUCCESS, response.data, null, "menuAttachments"));
      } else {
        dispatch(setResponseValues(SUCCESS, response.data, null, "menuAttachments"));
      }
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(saveResponseMessage(categoryResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Fetch all attachments of a menu */
export const fetchMenuAttachments = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const postData = { ...reqParam };
      const URL = MEGAMENU_FETCH_MAPPING + "?pty=" + (reqParam.platform ? reqParam.platform.toUpperCase() : "IOS");
      const response = await axios.post(URL, postData, config);
      console.log("Menu Mapping fetch Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "menuAttachments"));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Activate Deactivate menu */
export const activateDeactivateMenu = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const postData = {};

      const URL =
        MEGAMENU_ACTIONS +
        reqParam.action +
        "/" +
        reqParam.iRefId +
        "?pty=" +
        (reqParam.platform ? reqParam.platform.toUpperCase() : "IOS");
      const response = await axios.post(URL, postData, config);
      console.log("Menu Activate deacivarte Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "megaMenu"));
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(saveResponseMessage(categoryResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};

/** Add update megamenu media */
export const addUpdateMenuMedia = (reqParam) => {
  return async (dispatch) => {
    dispatch(startUILoading());
    dispatch(resetResponseValues());
    dispatch(clearMessage(categoryResponseMessageKey));
    try {
      const config = await dispatch(getRequestHeaders());
      const postData = { ...reqParam };

      const URL = MEGAMENU_MEDIA_ADD_UPDATE + "?pty=" + (reqParam.platform ? reqParam.platform.toUpperCase() : "IOS");
      const response = await axios.post(URL, postData, config);
      console.log("Menu add/update media Success Executed", response.data);
      dispatch(setResponseValues(SUCCESS, response.data, null, "megaMenu"));
      dispatch(clearMediaList());
      dispatch(clearMediaObject());
      /** Clear error from common reducer so that a success message can be shown*/
      dispatch(saveResponseMessage(categoryResponseMessageKey, { hasErrors: false }));
    } catch (error) {
      /** Save error to common reducer to display to user */
      if (error?.response?.data) {
        dispatch(saveResponseMessage(categoryResponseMessageKey, error.response.data));
      }
    } finally {
      dispatch(stopUILoading());
    }
  };
};
