import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Button,
  Table,
  ButtonToolbar,
  Label,
  Form,
  Input,
} from "reactstrap";

import { connect } from "react-redux";
import {
  searchBrand,
  addUpdateBrand,
  getBrandDetail,
  addUpdateBrandMedia,
} from "../../datautils/actions/adminActions";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { sortIcon } from "../../utils/constants/icons";
import {
  resetResponseValues,
  resetObjectValues,
} from "../../datautils/actions/responseHandlerActions";
import { getMediaPath } from "../../utils/helpers/HelperFunctions";
import { PER_PAGE } from "../../utils/constants/index";
import ErrorBlock from "../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";

import Dropzone from "../../components/Dropzone/Dropzone";
import { uploadImages } from "../../datautils/actions/skuActions";
import ImageZoom from "../../components/ImageZoom";

class Brands extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSearchKeyword: "",
      currentTab: "1",
      currentFilter: "status",
      pageNumber: 1,
      pageSize: PER_PAGE,
      sortField: "name",
      ascending: false,
      toggle: false,
      isUpdated: false,
      files: [],
      name: "",
      code: "",
      status: "ACTIVE",
      description: "",

      id: 0,
      existingImages: {},
    };
  }
  componentDidMount() {
    this.searchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { responseVal } = this.props;

    if (prevState.pageNumber !== this.state.pageNumber) {
      this.searchData();
    }

    if (
      responseVal.dObjectData.brandDetail &&
      responseVal.dObjectData.brandDetail.data &&
      (prevState.lastUpdated !==
        responseVal.dObjectData.brandDetail.data.updatedOn ||
        prevState.id !== responseVal.dObjectData.brandDetail.data.id)
    ) {
      const data = responseVal.dObjectData.brandDetail.data;
      this.setState({
        id: data.id,
        name: data.name,
        code: data.code,
        status: data.status,
        description: data.description,
        existingImages: data.medias,
        lastUpdated: data.updatedOn,
        files: [],
      });
    }
  }

  toggleBox = () => {
    this.setState({ toggle: !this.state.toggle, isUpdated: false });
  };

  searchButton = (e) => {
    this.setState(
      {
        pageNumber: 1,
      },
      () => this.searchData()
    );
  };

  sortButton = (field, order) => (e) => {
    e.preventDefault();

    this.setState(
      {
        sortField: field,
        ascending: order,
      },
      () => this.searchData()
    );
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          pageNumber: 1,
        },
        () => this.searchData()
      );
    }
  };

  searchHandler = (e) => {
    this.setState({ globalSearchKeyword: e.target.value });
  };

  enableDisableImage = (key, status) => (e) => {
    const { id, code } = this.state;

    this.props.addUpdateBrandMedia({
      brandVO: {
        id,
        code,
        status,
        medias: {
          [key]: { fileId: key, status },
        },
      },
    });

    this.setState({ isUpdated: true });
  };

  enableDisableBrand = (status) => (e) => {
    const { id, code, name, description } = this.state;

    this.props.addUpdateBrand({
      brandVO: {
        id,
        code,
        name,
        description,
        status,
      },
    });

    this.setState({ isUpdated: true });
  };

  searchData = () => {
    const {
      globalSearchKeyword,
      currentFilter,
      currentTab,
      sortField,
      ascending,
      pageNumber,
      pageSize,
    } = this.state;

    const reqData = {
      globalSearchKeyword: globalSearchKeyword,
      fieldNameKeyword: [
        {
          key: currentFilter,
          operation: "EQUAL_TO",
          value: currentTab,
        },
      ],
      paginationOptions: {
        pageNumber,
        pageSize,
        sortOptions: [{ sortField, ascending }],
      },
    };
    this.props.resetObjectValues("brandDetail");
    this.setState({ isUpdated: false });
    this.props.searchBrand(reqData);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createBox = (i) => (e) => {
    this.props.resetResponseValues();
    this.props.resetObjectValues("brandDetail");

    this.setState({
      toggle: i === 1 ? false : true,

      files: [],
      id: 0,
      name: "",
      code: "",
      description: "",
      isUpdated: false,
      existingImages: {},
    });

    if (i === 1) {
      this.searchData();
    }
  };

  handleEditPage = (input) => (e) => {
    this.setState({ toggle: true, isUpdated: false }, () =>
      this.props.getBrandDetail({ id: input })
    );

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  changePage = (pageNumber) => (e) => {
    this.setState({ pageNumber });
  };

  onDrop = (acceptedFiles) => {
    this.setState({ files: [...this.state.files, ...acceptedFiles] });

    acceptedFiles.map((acceptedFile) => {
      return this.props.uploadImages(acceptedFile);
    });
  };

  filterData = (e) => {
    this.setState(
      {
        currentTab: e.target.value,
        globalSearchKeyword: "",
        pageNumber: 1,
        currentFilter: e.target.dataset.filter,
      },
      () => this.searchData()
    );
  };

  renderPagination = () => {
    const mainData = this.props.responseVal.dObjectData.brandList.data;
    if (mainData.totalNoOfPages > 1) {
      return (
        <CustomPagination
          pageNumber={this.state.pageNumber}
          totalNoOfPages={mainData.totalNoOfPages}
          changePage={this.changePage}
          hasPrevious={mainData.hasPrevious}
          hasNext={mainData.hasNext}
        />
      );
    }
  };

  addUpdateBrand = (e) => {
    e.preventDefault();

    const { responseVal } = this.props;
    const { id, code, name, description, status } = this.state;

    let mediasToPost = {};
    if (responseVal.media && responseVal.media.length > 0) {
      responseVal.media.forEach((fileId) => {
        mediasToPost[fileId] = { fileId: fileId };
      });
    }

    this.setState({ isUpdated: true });

    const reqParam = {
      id,
      code,
      name,
      description,
      status,
      medias: mediasToPost,
    };

    this.props.addUpdateBrand({ brandVO: reqParam });
  };

  render() {
    const { responseVal } = this.props;
    const {
      isUpdated,
      globalSearchKeyword,
      name,
      code,
      description,
      files,
      currentTab,
      currentFilter,
      existingImages,
      toggle,
      ascending,
      id,
    } = this.state;

    const dataLoaded =
      responseVal.dObjectData.brandList &&
      responseVal.dObjectData.brandList.data &&
      responseVal.dObjectData.brandList.data.brands
        ? responseVal.dObjectData.brandList.data.brands
        : false;

    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs={12}>
              {responseVal.errors && responseVal.status === "failure" ? (
                <ErrorBlock errors={responseVal.errors} />
              ) : null}

              {responseVal.status === "success" && isUpdated ? (
                <SuccessBlock msg={responseVal.data} />
              ) : null}
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="8">
                  {toggle ? (
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb pt-0">
                        <li className="breadcrumb-item">
                          <span onClick={this.createBox(1)}>
                            &laquo; Back to Brands
                          </span>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {name ? name : "Create Brand"}
                        </li>
                      </ol>
                    </nav>
                  ) : (
                    <ButtonToolbar>
                      {" "}
                      <Button
                        size="sm"
                        value="1"
                        data-filter="status"
                        onClick={this.filterData}
                        color={
                          currentTab === "1" && currentFilter === "status"
                            ? "primary"
                            : ""
                        }
                      >
                        ACTIVE
                      </Button>
                      <Button
                        size="sm"
                        value="0"
                        data-filter="status"
                        onClick={this.filterData}
                        color={
                          currentTab === "0" && currentFilter === "status"
                            ? "primary"
                            : ""
                        }
                      >
                        INACTIVE
                      </Button>
                      <Button
                        size="sm"
                        value=""
                        data-filter="status"
                        onClick={this.filterData}
                        color={
                          currentTab === "" && currentFilter === "status"
                            ? "primary"
                            : ""
                        }
                      >
                        ALL
                      </Button>
                    </ButtonToolbar>
                  )}
                </Col>
                <Col xs="4">
                  {!id && !toggle ? (
                    <Button
                      size="sm"
                      color="success"
                      className="pull-right"
                      onClick={this.createBox(0)}
                    >
                      Create New Brand
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {toggle ? (
              <Col xs="12">
                <div className="bg-white p-4 card-default mb-4">
                  <Row>
                    <span className="close-box" onClick={this.createBox(1)}>
                      <i className="now-ui-icons ui-1_simple-remove" />
                    </span>
                    <Col md="7">
                      <Form autoComplete="off" className="form--horizontal">
                        {id ? (
                          <FormGroup row>
                            <Label for="title" sm={3}>
                              Brand Status
                            </Label>
                            <Col sm={9}>
                              <h5>
                                {this.state.status === "ACTIVE" ? (
                                  <Button
                                    size="sm"
                                    className=""
                                    color="success"
                                    onClick={this.enableDisableBrand(
                                      "INACTIVE"
                                    )}
                                  >
                                    Deactivate Brand
                                  </Button>
                                ) : (
                                  <Button
                                    size="sm"
                                    className=""
                                    color="danger"
                                    onClick={this.enableDisableBrand("ACTIVE")}
                                  >
                                    Activate Brand
                                  </Button>
                                )}
                              </h5>
                            </Col>
                          </FormGroup>
                        ) : (
                          <FormGroup row>&nbsp;</FormGroup>
                        )}
                        <FormGroup row>
                          <Label for="title" sm={3}>
                            Brand Name<span className="star">*</span>
                          </Label>
                          <Col sm={9}>
                            <Input
                              type="text"
                              name="name"
                              placeholder="Brand name"
                              value={name || ""}
                              onChange={this.handleChange}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="title" sm={3}>
                            Brand Code<span className="star">*</span>
                          </Label>
                          <Col sm={9}>
                            <Input
                              type="text"
                              name="code"
                              disabled={id}
                              placeholder="Brand code"
                              value={code || ""}
                              onChange={this.handleChange}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="title" sm={3}>
                            Description
                          </Label>
                          <Col sm={9}>
                            <Input
                              type="textarea"
                              name="description"
                              placeholder="Brand description"
                              value={description || ""}
                              onChange={this.handleChange}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="button" sm={3} />
                          <Col sm={9}>
                            {id ? (
                              <Button
                                size="md"
                                color="primary"
                                onClick={this.addUpdateBrand}
                                disabled={!name || !code}
                              >
                                Update Brand
                              </Button>
                            ) : (
                              <Button
                                size="md"
                                color="primary"
                                onClick={this.addUpdateBrand}
                                disabled={!name || !code}
                              >
                                Create New Brand
                              </Button>
                            )}
                          </Col>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col md="5" className="mt-4">
                      {" "}
                      <Dropzone onFilesAdded={(opt) => this.onDrop(opt)} />
                      {files ? (
                        <Row className="mt-2 equal">
                          {files.length > 0 &&
                            files.map((acceptedFile) => (
                              <Col sm="6" className="" key={acceptedFile.name}>
                                <img
                                  alt={acceptedFile.name}
                                  src={URL.createObjectURL(acceptedFile)}
                                  className="img-thumbnail rounded"
                                />
                                <p className="text-center">
                                  {acceptedFile.name}
                                </p>
                              </Col>
                            ))}
                        </Row>
                      ) : null}
                      {existingImages ? (
                        <Row className="mt-2 equal">
                          {Object.keys(existingImages).map((index) => {
                            return (
                              <Col md="4" key={index}>
                                <div className="bg-white p-2 mb-3">
                                  {" "}
                                  <ImageZoom
                                    src={getMediaPath(
                                      existingImages[index].fileId,
                                      existingImages[index].fileExt
                                    )}
                                    alt=""
                                  />
                                  {existingImages[index].status === "ACTIVE" ? (
                                    <Button
                                      size="sm"
                                      className="btn-block"
                                      color="success"
                                      onClick={this.enableDisableImage(
                                        index,
                                        "INACTIVE"
                                      )}
                                    >
                                      Deactivate
                                    </Button>
                                  ) : (
                                    <Button
                                      size="sm"
                                      className="btn-block"
                                      color="danger"
                                      onClick={this.enableDisableImage(
                                        index,
                                        "ACTIVE"
                                      )}
                                    >
                                      Activate
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              </Col>
            ) : null}

            <Col xs={12}>
              <React.Fragment>
                <Card>
                  <CardBody className="listings">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="has-search">
                            <div className="input-group">
                              <input
                                type="search"
                                value={globalSearchKeyword}
                                name="globalSearchKeyword"
                                className="form-control"
                                placeholder="Search Brands..."
                                onChange={this.searchHandler}
                                onKeyDown={this.handleKeyDown}
                                style={{ background: "#f5f5f5" }}
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Button
                                  color="primary"
                                  onClick={this.searchButton}
                                >
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="8">
                        {dataLoaded ? this.renderPagination() : ""}
                      </Col>
                    </Row>
                    <Table responsive className="table-striped table-bordered">
                      <thead>
                        <tr className="text-primary">
                          <th className="text-center">#</th>
                          <th
                            onClick={this.sortButton(
                              "id",
                              ascending ? false : true
                            )}
                          >
                            Brand ID{" "}
                            <img
                              alt="sort"
                              className="sort-icon"
                              src={sortIcon}
                            />
                          </th>
                          <th
                            onClick={this.sortButton(
                              "name",
                              ascending ? false : true
                            )}
                          >
                            Brand Name{" "}
                            <img
                              alt="sort"
                              className="sort-icon"
                              src={sortIcon}
                            />
                          </th>

                          <th>Status</th>
                          <th>Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLoaded ? (
                          dataLoaded.length ? (
                            dataLoaded.map((row, index) => {
                              const cname =
                                row.status === "ACTIVE"
                                  ? "success"
                                  : row.status === "INACTIVE"
                                  ? "danger"
                                  : row.status === "ONHOLD"
                                  ? "warning"
                                  : "primary";
                              return (
                                <tr
                                  key={index}
                                  onClick={this.handleEditPage(row.id)}
                                  className="cursor"
                                >
                                  <td className="text-center">
                                    {this.state.pageNumber *
                                      this.state.pageSize +
                                      (index + 1) -
                                      this.state.pageSize}
                                  </td>
                                  <td>{row.id}</td>
                                  <td>{row.name}</td>
                                  <td>{row.status}</td>
                                  <td>{row.description}</td>
                                  <td className="text-right">
                                    <Button
                                      className={`btn-simple btn-light btn-primary btn-${cname}`}
                                      size="xs"
                                    >
                                      Edit
                                    </Button>{" "}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="6" className="no-data">
                                No data available
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="6" />
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {this.props.isLoading ? <div className="loader" /> : null}
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResponseValues: () => dispatch(resetResponseValues()),
    resetObjectValues: (object) => dispatch(resetObjectValues(object)),
    searchBrand: (reqData) => dispatch(searchBrand(reqData)),
    uploadImages: (acceptedFile) => dispatch(uploadImages(acceptedFile)),
    addUpdateBrand: (reqData) => dispatch(addUpdateBrand(reqData)),
    getBrandDetail: (reqData) => dispatch(getBrandDetail(reqData)),
    addUpdateBrandMedia: (reqParam) => dispatch(addUpdateBrandMedia(reqParam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
