import React from "react";
import { Container, Row, Col, Card, CardHeader, CardTitle } from "reactstrap";
import Footer from "../../../views/Common/Footer";
import HomeHeader from "../../../components/Headers/HomeHeader";
import PostRegistration from "./PostRegistration";
import PreRegistration from "./PreRegistration";
import ErrorBlock from "../../../components/ErrorBlock/ErrorBlock";
import SuccessBlock from "../../../components/SuccessBlock/SuccessBlock";
import InfoArea from "../../../components/InfoArea/InfoArea";
import { defaultParams } from "../../../utils/constants/";
import { registerUser, verifyAuthCode } from "../../../datautils/actions/authActions";
import { SUPPLIER_BASE } from "../../../utils/constants/clientUrls";
import { resetResponseValues } from "../../../datautils/actions/responseHandlerActions";
import { isUserLoggedIn } from "../../../utils/helpers/HelperFunctions";
import { connect } from "react-redux";

import ico1 from "../../../assets/img/laptop2@3x.png";
import ico2 from "../../../assets/img/screen3@3x.png";
import ico3 from "../../../assets/img/speaker2@3x.png";
import ico4 from "../../../assets/img/coin@3x.png";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      maxStep: 2,
      mobile: "",
      password: "",
      otp: "",
      mobileState: "",
      passwordState: "",
      otpState: "",
      isError: false,
      showSendOtpOption: false,
    };
  }
  componentDidMount() {
    this.props.resetResponseValues();
    if (isUserLoggedIn()) {
      this.props.history.push(SUPPLIER_BASE);
    }
  }
  nextStep = () => {
    let { step, maxStep } = this.state;
    step = step > maxStep ? maxStep : step;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    let { step } = this.state;
    step = step < 1 ? 1 : step;
    this.setState({
      step: step - 1,
    });
  };

  handleBack = () => {
    this.props.resetResponseValues();
    this.setState({
      step: 1,
    });
  };

  handleChange = (input) => (event) => {
    if (input === "mobile") {
      let numberRex = new RegExp("^[0-9]+$");
      let mobile = this.state.mobile;
      let mobileState = this.state.mobileState;
      mobile = event.target.value;
      if (numberRex.test(mobile) && mobile.length === 10) {
        mobileState = "is-valid";
      } else {
        mobileState = "is-invalid";
      }
      this.setState({ mobile, mobileState });
    } else if (input === "password") {
      let password = this.state.password;
      let passwordState = this.state.password;
      password = event.target.value;
      if (password.length > 5) {
        passwordState = "is-valid";
      } else {
        passwordState = "is-invalid";
      }
      this.setState({ password, passwordState });
    } else if (input === "otp") {
      let otp = this.state.otp;
      let otpState = this.state.otp;
      otp = event.target.value;
      if (otp.length === 4) {
        otpState = "is-valid";
      } else {
        otpState = "is-invalid";
      }
      this.setState({ otp, otpState });
    } else {
      this.setState({ [input]: event.target.value });
    }
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    const reqData = {
      reqType: "PRE_REGISTRATION",
      userType: "SUPPLIER",
      mobileCountryCode: "+91",
      mobileNumber: this.state.mobile,
      ...defaultParams(),
    };
    this.props.registerUser(reqData);
    this.setState({ showSendOtpOption: false });
  };

  sendOTP = (e) => {
    e.preventDefault();
    const reqData = {
      reqType: "REGISTRATION",
      userType: "SUPPLIER",
      mobileCountryCode: "+91",
      mobileNumber: this.state.mobile,
      password: this.state.password,
      otp: this.state.otp,
      ...defaultParams(),
    };
    this.props.verifyAuthCode(reqData);
  };

  onStop = () => {
    this.setState({ showSendOtpOption: true });
  };

  componentDidUpdate(prevProps) {
    if (this.props.responseVal.status === "success" && this.state.step === 1) {
      this.nextStep();
    }
    if (isUserLoggedIn()) {
      this.props.history.push(SUPPLIER_BASE);
    }
  }

  render() {
    const { step, mobile, password, otp, mobileState, passwordState, otpState } = this.state;
    const values = { mobile, password, otp };
    const { responseVal } = this.props;

    const isEnabled = mobileState === "is-valid";

    const isEnabledReg = mobileState === "is-valid" && passwordState === "is-valid" && otpState === "is-valid";

    return (
      <div>
        <HomeHeader {...this.props} />
        <div className="full-page--content">
          <div className="register-page">
            <Container>
              <Row className="justify-content-center">
                <Col lg={8} md={8} xs={12}>
                  <h1>Become a seller and sell your products on Slickpals</h1>
                  <Row>
                    <Col sm={6}>
                      <InfoArea
                        icon="now-ui-icons users_circle-08"
                        img={ico1}
                        iconColor="neutral"
                        title="Sign Up"
                        titleColor="info"
                        description="After signup complete your KYC"
                      />
                    </Col>
                    <Col>
                      {" "}
                      <InfoArea
                        icon="now-ui-icons shopping_tag-content"
                        img={ico2}
                        iconColor="neutral"
                        title="List your products"
                        titleColor="info"
                        description="List your products on the plaltform"
                      />
                    </Col>

                    <Col sm={6}>
                      {" "}
                      <InfoArea
                        icon="now-ui-icons objects_spaceship"
                        img={ico3}
                        iconColor="neutral"
                        title="Build your market"
                        titleColor="info"
                        description="Grow you business with us"
                      />
                    </Col>

                    <Col sm={6}>
                      {" "}
                      <InfoArea
                        icon="now-ui-icons business_money-coins"
                        img={ico4}
                        iconColor="neutral"
                        title="Get paid for sales"
                        titleColor="info"
                        description="Increase your revenue"
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={8} lg={4}>
                  <Card className="card-signup">
                    {(() => {
                      switch (step) {
                        case 1:
                          return (
                            <>
                              <CardHeader className="text-center">
                                <CardTitle tag="h4">Supplier Registration</CardTitle>
                                {responseVal.status === "failure" ? (
                                  <ErrorBlock errors={responseVal.errors} position="alert-relative" />
                                ) : null}

                                {responseVal.status === "success" ? (
                                  <SuccessBlock msg={responseVal.data} position="alert-relative" />
                                ) : null}
                              </CardHeader>
                              <PreRegistration
                                nextStep={this.nextStep}
                                values={values}
                                handleChange={this.handleChange}
                                saveAndContinue={this.saveAndContinue}
                                isEnabled={isEnabled}
                                mobileState={mobileState}
                              />
                            </>
                          );
                        case 2:
                          return (
                            <>
                              <CardHeader className="text-center">
                                <CardTitle tag="h4">Verify Mobile</CardTitle>
                                {responseVal.status === "failure" ? (
                                  <ErrorBlock errors={responseVal.errors} position="alert-relative" />
                                ) : null}

                                {responseVal.status === "success" ? (
                                  <SuccessBlock msg={responseVal.data} position="alert-relative" />
                                ) : null}
                              </CardHeader>
                              <PostRegistration
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                handleChange={this.handleChange}
                                values={values}
                                sendOTP={this.sendOTP}
                                passwordState={passwordState}
                                otpState={otpState}
                                isEnabled={isEnabledReg}
                                handleBack={this.handleBack}
                                onStop={this.onStop}
                                saveAndContinue={this.saveAndContinue}
                                showSendOtpOption={this.state.showSendOtpOption}
                              />
                            </>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </Card>
                </Col>
              </Row>
              <div className="strip d-none">
                <div className="container">
                  <Row>
                    <div className="col-sm-3">
                      {" "}
                      <img
                        src="https://image.flaticon.com/icons/svg/123/123400.svg"
                        alt=""
                        style={{ width: 40, marginRight: 10 }}
                      />
                      Order from your comfort
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      <img
                        src="https://image.flaticon.com/icons/svg/164/164414.svg"
                        alt=""
                        style={{ width: 40, marginRight: 10 }}
                      />
                      We will make it deliver
                    </div>

                    <div className="col-sm-3">
                      {" "}
                      <img
                        src="https://image.flaticon.com/icons/svg/1493/1493693.svg"
                        alt=""
                        style={{ width: 40, marginRight: 10 }}
                      />
                      Get your product
                    </div>

                    <div className="col-sm-3">
                      <img
                        src="https://image.flaticon.com/icons/svg/189/189093.svg"
                        alt=""
                        style={{ width: 40, marginRight: 10 }}
                      />{" "}
                      Increase your revenue
                    </div>
                  </Row>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
    responseVal: state.resHandlerVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (reqData) => dispatch(registerUser(reqData)),
    verifyAuthCode: (reqData) => dispatch(verifyAuthCode(reqData)),
    resetResponseValues: () => dispatch(resetResponseValues()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
